import { batch } from "react-redux"
import hub from "common/src/hub"
import store from "app/store"
import { data } from "common/src/store/user"
import * as userActions from "common/src/actions/user"
import user from "common/src/user"
import Auth from '@aws-amplify/auth'
import logger from "common/src/logger"
import api from "app/api"
import getUrl from "common/src/lib/url/get"
import routes from "app/routes"
import client from "common/src/api/hasura/client"
import getWsClient, { hasWsClient, invalidateWsClient } from "common/src/api/getWsClient"


// signed in via form
const onPoolUserSignIn = async () => {

    await userActions.checkCurrent().catch(err => console.log(err));

    const state = store.getState();
    const { /*authNotifyShopper,*/ authRedir } = state.user.session;

    hub.dispatch("app-auth", "common-auth-signIn", "done", true);

    /*if (authNotifyShopper) {
        await api.backend.post("/me/notify-shopper");
        store.dispatch(data.session({ authRedir: false }));
    }*/
    if (authRedir) {
        hub.dispatch("app", "redir", getUrl(routes[authRedir]));
    }
}

// signed out via Auth.signOut
const onPoolUserSignOut = (userData) => {
    hub.dispatch("app-auth", "stateChange", false);
    batch(() => {
        store.dispatch(data.loggedIn(false));
        store.dispatch(data.id(null));
        store.dispatch(data.current.reset());
    });
}

async function onAfterSignout() {
    //client.resetStore();
    //client.clearStore();
    client.clearStore().then(() => {
        client.resetStore();
        client.cache.reset();
    })

    if (hasWsClient()) {
        const wsClient = getWsClient();
        wsClient.clearStore && wsClient.clearStore();
        wsClient.resetStore && wsClient.resetStore();
        wsClient.cache && wsClient.cache.reset();
        
        invalidateWsClient();
    }
}

let expired = false;

async function checkExpiredToken() {
    if (user.loggedIn()) {
        const now = (new Date()).getTime() / 1000;
        const session = await Auth.currentSession();
        const s = session.accessToken.payload;
        const left = s.exp - now;
        
        if (left < 60) {
            if (left < 0) {
                if (!expired) {
                    process.env.REACT_APP_ENV === "devtwo" && console.log("Session expired");
                    logger.log(new Error("Session expired"), {
                        type: "session-expired"
                    });
                    expired = true;
                }
            }
            else {
                expired = false;
            }
        }
    }
}

function onSignoutRequired() {
    userActions.signOut();
}

const listeners = {
    init: () => {
        !hub.hasListener("auth", "signIn", onPoolUserSignIn) &&
            hub.listen("auth", "signIn", onPoolUserSignIn);
        
        !hub.hasListener("auth", "signOut", onPoolUserSignOut) &&
            hub.listen("auth", "signOut", onPoolUserSignOut);
        
        !hub.hasAsyncHandle("after-signout", onAfterSignout) && 
            hub.asyncHandle("after-signout", onAfterSignout);

        !hub.hasListener("app-auth", "signout-required", onSignoutRequired) &&
            hub.listen("app-auth", "signout-required", onSignoutRequired);

        !hub.hasAsyncHandle("accept-invitation", userActions.acceptInvitation) &&
            hub.asyncHandle("accept-invitation", userActions.acceptInvitation);

        setInterval(checkExpiredToken, 30000);
    }
}

export default listeners;