
import { createAction } from "@reduxjs/toolkit"

/**
 * 
 * createActionMap(["a", "b"]) -> {a: action "a", b: action "b"}
 * initial prefix goes as first param
 * createActionMap("pfx/", ["a", "b"]) -> {a: action "pfx/a", b: action "pfx/b"}
 * 
 * "." - single action
 * "*" - action triple start/success/error
 * createActionMap({a: ".", b: "*"}) -> 
 *  {a: action "a", b.start: action "b/start", b.success, b.error}
 * createActionMap({a: function(){}}) -> {a: function(){}}
 * 
 * optional prepare - prepare payload
 * optional fn - use as action
 * createActionMap([
 *  {name:"a", *prepare: function(){}, *fn: fuction(){}}
 * ])
 * optional type - create triplet
 * createActionMap([
 *  {name:"a", *type: "*"}
 * ])
 * 
 * createActionMap({
 *  a: {
 *      prepare: function() {},
 *      *fn: function() {}
 *  }
 * })
 * 
 * use "name/" as action subset
 * createActionMap({
 *  "a/": {
 *      b: ".",
 *      c: "*",
 *      "d/": {...}
 *  }
 * }) -> {a: {b: action, c: {start: action, ...}, d: {...}}}
 * 
 * 
 * createActionMap({"a/": ["b", "c", {name:"d"}]})
 * createActionMap({"a/": ["b", "c", {name:"d/", children: [] }]})
 * createActionMap({"a/": ["b", {name: "c"}, {name:"d/", children: {} }]})
 */

function isObject(o) {
    return typeof o === "object" && o !== null;
}

function makeTriplet(map, name, prefix) {
    map[name] = {
        start: createAction(prefix + "start"),
        success: createAction(prefix + "success"),
        error: createAction(prefix + "error")
    };
}

function walk(items, prefix, map) {

    let i, l, key, item, name, subkey = false;

    if (Array.isArray(items)) {
        for (i = 0, l = items.length; i < l; i++) {
            item = items[i];
            if (typeof item === "string") {
                map[item] = createAction(prefix + item);
            }
            else if (isObject(item)) {

                name = item.name || (item.fn ? item.fn.name : null)
                if (name === null) {
                    throw new Error("Action name must be specified")
                }
                subkey = name[name.length - 1] === "/";

                if (subkey) {
                    name = name.substring(0, name.length-1);
                    map[name] = {};

                    if (!item.children) {
                        throw new Error("item.children is empty");
                    }

                    walk(item.children, prefix + name + "/", map[name]);
                }
                else {
                    if (item.type && item.type === "*") {
                        makeTriplet(map, name, prefix + name + "/")
                    }
                    else {
                        map[name] = item.fn || 
                                    createAction(
                                        prefix + name,
                                        item.prepare || undefined
                                    );
                    }
                }
            }
        }
    }
    else {
        for (key in items) {
            item = items[key];
            subkey = key[key.length - 1] === "/";
            name = subkey ? key.substring(0, key.length-1) : key;

            if (subkey) {
                map[name] = {};
                walk(item, prefix + name + "/", map[name]);
            }
            else {
                if (item === ".") {
                    map[name] = createAction(prefix + name);
                }
                else if (item === "*") {
                    makeTriplet(map, name, prefix + name + "/");
                }
                else if (typeof item === "function") {
                    map[name] = item;
                }
                else if (typeof item === "object" && item !== null) {
                    if (item.type && item.type === "*") {
                        makeTriplet(map, name, prefix + name + "/")
                    }
                    else {
                        map[name] = item.fn || 
                                    createAction(
                                        prefix + name,
                                        item.prepare || undefined
                                    );
                    }
                }
            }
        }
    }
}


export default function createActionMap(prefix, items) {

    if (typeof prefix !== "string") {
        items = prefix;
        prefix = "";
    }

    let map = {};
    walk(items, prefix, map);
    return map;
}

export function map2builder(builder, map) {
    Object.keys(map).forEach(action => {
        builder.addCase(action, map[action]);
    })
}