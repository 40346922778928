const routes = {
    "home": "/",
    "signin": "/signin",
    "register": "/register",
    "profileContacts": "/profile/contacts",
    "profileBankDetails": "/profile/bank-details",
    "profile": "/profile",

    "consultations": "/consultations/:status?/:page?",
    "client": "/client/:clientId/:status?/:page?",
    "looks": "/looks/:status?/:page?",
    
    "consultation": "/c/:id",

    "look": "/l/:id/:consId?",
    "lookEdit": "/l/:id/edit",
    "lookCreate": "/l/create/:consId?",

    "people": "/people/:listName?/:page?",

    "tags": "/tags/:listName?/:page?",
    

    "builder": "/builder",
    "lookCreate2": "/l/create-v2/:consId?",
    "lookEdit2": "/l/edit-v2/:id/:consId?",

    "chat": "/chat/:contactId?",

    "friTerms": "/fri-terms",
    "terms": "/terms",
    "privacy": "/privacy",

    "catalogue": "/catalogue",
    "moodboards": "/moodboards/:page?",
    "moodboard": "/moodboard/:id/:page?"
}

export default routes