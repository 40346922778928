
import { Typography, Flex, Tooltip } from "antd"

import { ReactComponent as IconHelp } from "common/src/svg/help.svg"

function FormInput({ label, error, help, children }) {

    return (
        <Flex vertical className="form-input">
            { label && <Typography.Text className="form-input-label">
                        { label }
                        { help && 
                            <Tooltip title={ help }>
                                <span><IconHelp/></span>
                            </Tooltip> }
                        </Typography.Text> }
            { children }
            { error && <Typography.Text type="danger" className="form-input-error">
                        { error }
                        </Typography.Text> }
        </Flex>
    )
}

export default FormInput