export default function (cc) {

    if (!cc.__normalized) {

        if (cc.feedCharacteristics_aggregate) {
            cc.feedCharacteristicsCount = cc.feedCharacteristics_aggregate.aggregate.count;
        }
    }

    cc.__normalized = true;
    return cc;
}