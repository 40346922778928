import { Select } from "antd"
import { ReactComponent as Arrow } from "common/src/svg/select-arrow.svg"

function CustomizedSelect(props) {

    return <Select 
            suffixIcon={ <Arrow/> }
            {...props}/>
}

export default CustomizedSelect