export default function normalizeConsultationDraft(consDraft) {
    if (consDraft?.__normalized) {
        return consDraft;
    }

    if (consDraft?.data && typeof consDraft.data === "string") {
        consDraft.data = JSON.parse(consDraft.data);
    }

    consDraft.__normalized = true;

    return consDraft;
}