
import Auth from "@aws-amplify/auth"
import hub from "common/src/hub"
import singlePromise from "common/src/lib/js/singlePromise"
import store from "app/store"


export default {

    loggedIn: function() {
        const state = store.getState();
        return state.user.loggedIn;
    },

    id: function() {
        const state = store.getState();
        return state.user.id;
    },

    gwtToken: async function() {
        const u = await Auth.currentAuthenticatedUser();
        return u.signInUserSession.idToken.jwtToken;
    },

    current: function() {
        const state = store.getState();
        return state.user.current;
    },

    session: function() {
        const state = store.getState();
        return state.user.session;
    },

    geo: function() {
        const state = store.getState();
        return state.user.geo;
    },

    getFullName: function() {
        const current = this.current();
        if (current && current.givenName) {
            return current.givenName + " " + current.familyName;
        }
        return "";
    },

    getInitials: function() {
        const current = this.current();
        if (current && current.givenName) {
            return current.givenName[0].toUpperCase() +
                    current.familyName[0].toUpperCase();
        }
        return "";
    },

    getDetails: function() {
        const current = this.current();
        return current ? (current.details || {}) : {};
    },

    is: function(group) {
        let state = store.getState(),
            userGroups = state.user.groups,
            grps = Array.isArray(group) ? group : [group],
            i, l;

        for (i = 0, l = grps.length; i < l; i++) {
            if (userGroups.indexOf(grps[i]) !== -1) {
                return true;
            }
        }

        return false;
    },

    isOnly: function(group) {
        const state = store.getState();
        const userGroups = state.user.groups;

        if (userGroups.indexOf(group) === -1) {
            return false;
        }

        if (group === "User") {
            return userGroups.length === 1;
        }
        else {
            return userGroups.length === 2;
        }
    },

    hasGroups: function() {
        const state = store.getState(),
                user = state.user.current;
        return !!user && user.groups.length > 0;
    },

    hasAccessTo: function(key, exact = false) {
        const state = store.getState(),
            user = state.user.current;

        if (!user) {
            return false;
        }

        const keys = Array.isArray(key) ? key : [ key ];
        const access = user.details?.access || {};

        if (exact) {
            return access[key] === true;
        }

        for (let i = 0, l = keys.length; i < l; i++) {
            key = keys[i];
            const parts = key.split("/");

            while(parts.length > 0) {
                if (access[key] === true) {
                    return true;
                }
                let last = parts.pop();

                if (last === "*") {
                    let parent = parts.join("/");
                    if (Object.keys(access).find(k => k.indexOf(parent) === 0 && access[k] === true)) {
                        return true;
                    }
                }

                key = parts.join("/");
            }
        }

        return false;
    },

    isNot: function(group) {
        let state = store.getState(),
            user = state.user.current,
            grps = Array.isArray(group) ? group : [group],
            i, l;

        if (user) {
            for (i = 0, l = grps.length; i < l; i++) {
                if (user.groups.indexOf(grps[i]) !== -1) {
                    return false;
                }
            }
        }

        return true;
    },

    authPromise: singlePromise(function() {

        let state = store.getState();

        if (state.user.loggedIn !== null) {
            return Promise.resolve(state.user.loggedIn);
        }

        return new Promise(function(resolve, reject) {
            let listener = function(loggedIn) {
                resolve(loggedIn);
                hub.remove("app-auth", "stateChange", listener);
            }
            hub.listen("app-auth", "stateChange", listener);
        });
    }),

    infoPromise: singlePromise(function(){
        let state = store.getState();

        if (state.user.current) {
            return Promise.resolve(state.user.current);
        }

        return new Promise(function(resolve, reject) {
            let listener = function(info) {
                resolve(info);
                hub.remove("app-auth", "info-loaded", listener);
            }
            hub.listen("app-auth", "info-loaded", listener);
        });
    })
}