import { useContext, useRef, useState, useCallback, useEffect } from "react"
import { useOn } from "@kuindji/observable-react"
import { Spin } from "antd"

import Message from "./messages/Message"

import ChatContext from "common/src/lib/chat/ChatContext"
import useQueryParam from "common/src/hooks/useQueryParam"
import useDictRef from "common/src/hooks/useDictRef"
import async from "common/src/lib/js/async"
import { clearNotifications } from "common/src/actions/chat"
import Spacer from "../Spacer"

function scrollToMessage(mid) {
    if (mid) {
        const el = document.getElementById("chat-message-" + mid);
        el && el.scrollIntoView();
    }
};


function Messages() {

    const chat = useContext(ChatContext);
    const listEl = useRef(null);
    const mid = useQueryParam("mid");
    const [ messages, setMessages ] = useState(chat.getMessages());
    const [ loading, setLoading ] = useState(false);
    const [ loadingMore, setLoadingMore ] = useState(false);
    const [ endReached, setEndReached ] = useState(false);
    const [ atBottom, setAtBottom ] = useState(true);

    const ref = useDictRef({ loading, loadingMore, endReached, atBottom });

    const scrollToEnd = useCallback(
        () => listEl.current && (listEl.current.scrollTop = 0), 
        []
    );

    const onMessagesUpdate = useCallback(
        () => {
            setMessages(chat.getMessages());
            if (ref.atBottom) {
                async(scrollToEnd);
                async(() => chat.getChat() && clearNotifications(chat.getChat().id));
            }
        }, 
        []
    );

    const onContactChange = useCallback(
        () => { 
            setEndReached(false); 
            setAtBottom(true);
            async(scrollToEnd);
        }, 
        []
    );

    const onScroll = useCallback(
        (e) => {
            const el = e.target;
            const st = el.scrollTop * -1;

            const atBottom = st < 100;
            ref.atBottom !== atBottom && setAtBottom(atBottom);
            
            if (!ref.loadingMore && !ref.endReached) {
                if ((st + el.offsetHeight) / el.scrollHeight > 0.75) {
                    ref.loadingMore = true;
                    chat.loadMoreMessages();    
                }   
            }
        }, 
        []
    );


    useOn(chat, {
        messages: onMessagesUpdate,
        loadingMessages: setLoading,
        loadingMoreMessages: setLoadingMore,
        contact: onContactChange,
        endReached: setEndReached, // no more messages in db
        scrollTo: scrollToMessage
    });

    useEffect(
        () => { mid && async(() => scrollToMessage(mid), 1000) }, 
        [ mid ]
    );

    return (
        <div className="chat-messages" id="chat-messages" onScroll={ onScroll } ref={ listEl }>
            { loading && <Spin spinning className="chat-messages-loading"/> }
            { messages.map(m => (
                <Message key={ m.id } message={ m }/>
            ))}
            { (!loading && !endReached && !!messages.length) && <Spin spinning className="chat-messages-loading-previous"/> }
            <Spacer size="1rem"/>
        </div>
    )
}

export default Messages