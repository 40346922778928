

export default function(value = 0, currency, digits = 0) {

    if (!currency) {
        return "";
    }
    if (!value) {
        value = 0;
    }

    if (window.Intl && window.Intl.NumberFormat) {
        let f = new Intl.NumberFormat("en-US", {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: digits,
            maximumFractionDigits: digits
        });
        return f.format(value);
    }

    return value + " " + currency;
}