import { Fragment, useMemo, useState } from "react"

import Loader from "common/src/components/Loader"
import ImageSliderIncicator from "common/src/refactor/components/image-slider/ImageSliderIndicator";

import useImageLoading from "common/src/refactor/hooks/useImageLoading";

function Image({ product, indicator = false }) {


    const total = useMemo(
        () => {
            return (product.cdnImages || product.images || []).length;
        },
        []
    );

    const showIndicator = useMemo(
        () => indicator === "auto" ? total > 1 : indicator !== false,
        [ indicator, total ]
    )

    const indicatorProps = useMemo(
        () => {
            return typeof indicator === "object" ? indicator : {};
        },
        [ indicator ]
    );

    const image = useMemo(
        () => {
            let img = null;
            if (product.cdnImages && product.cdnImages.length > 0) {
                img = product.cdnImages[0];
            }
            if (product.images && product.images.length > 0) {
                img = product.images[0];
            }
            if (img && typeof img !== "string") {
                img = img.src;
            }
            return img;
        },
        [ product ]
    );

    const style = useMemo(
        () => image ? { backgroundImage: `url(${ image })` } : {},
        [ image ]
    );

    const loading = useImageLoading(image);

    return (
        <Fragment>
            <div 
                className="catalogue-product-card-image" 
                style={ style }
                children={ loading ? <Loader/> : null }/>
            { showIndicator && 
                <ImageSliderIncicator 
                    total={ total }
                    { ...indicatorProps }/> }
        </Fragment>
    )
}

export default Image