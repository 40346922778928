import { useEffect, useState } from "react"
import { useOn } from "@kuindji/observable-react"

import app from "app/appContext"

export function Portal({ host, children }) {

    //const app = useContext(AppContext);

    useEffect(
        () => {
            app.trigger(`portal-${ host }`, children);
            return () => {
                app.trigger(`portal-${ host }`, null);
            }
        },
        [ host, children, app ]
    );

    if (!host) {
        return children;
    }

    return null;
}

export function PortalHost({ name }) {
    //const app = useContext(AppContext);
    const [ children, setChildren ] = useState(null);

    useEffect(
        () => void app.createEvent(`portal-${ name }`, { autoTrigger: true }),
        // eslint-disable-next-line
        []
    );

    useOn(app, `portal-${ name }`, setChildren);

    return children;
}