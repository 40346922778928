

export default function normalizeCatalogueProduct(cp) {

    if (!cp.__normalized) {

        cp.images = JSON.parse(cp.images).map(src => typeof src === "string" ? { src } : src);
        cp.details = JSON.parse(cp.details);

        if (!cp.designers) {
            cp.designers = [{ name: cp.brand }];
        }

        cp.__normalized = true;
    }

    return cp;
}