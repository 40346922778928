import { batch } from "react-redux"
import api from "app/api"
import store from "app/store"
import hub from "common/src/hub"
import user from "common/src/user"
import { data as editorData, ui as editorUi, lookScheme } from "common/src/store/look/editor"
import { data as productData, ui as productUi } from "common/src/store/look/product"
import { remove as removeProduct, submit as submitProduct } from "./product"
import cloneDeep from "lodash/cloneDeep"
import preloadImages from "common/src/lib/look/preloadImages"
import throttle from "lodash/throttle"
import async from "common/src/lib/js/async"
import s3url from "common/src/lib/image/s3url"
//import * as draftActions from "common/src/actions/look/drafts"


const generatePath = function(styles) {
    const paths = styles.map(s => s.path);
    return paths.join("-");
};

const doSubmit = async function(look, consId, publish, submitForReview) {

    //console.log("look store", cloneDeep(look))

    let products = look.products,
        deletedProducts = look.deletedProducts,
        deletedStyles = look.deletedStyles,
        deletedSeasons = look.deletedSeasons,
        deletedLocations = look.deletedLocations,
        deletedOccasions = look.deletedOccasions,
        deletedHiddenTags = look.deletedHiddenTags,
        deletedImages = look.deletedImages,
        deletedUploads = look.deletedUploads,
        styleIds = [],
        seasonIds = [],
        locationIds = [],
        occasionIds = [],
        hiddenTagIds = [],
        styles = look.styles, 
        seasons = look.seasons, 
        locations = look.locations,
        occasions = look.occasions,
        hiddenTags = look.hiddenTags,
        now = (new Date()).toISOString(),
        editMode = !!look.id,
        lookData = {},
        initialData = { description: null, title: null, layouts: null, friId: null },
        key,
        imgMap = {},
        changeLayout = false,
        lookId = editMode ? look.id : null;

    for (key in lookScheme) {
        lookData[key] = null; // create writable property
        lookData[key] = look[key]; // assign value from immutable object
    }

    !editMode && (lookData.createdAt = now);
    editMode && (lookData.indexed = false);

    lookData.updatedAt = now;
    lookData.layouts = JSON.stringify(look.layouts);

    if (publish/* && user.is("Admin")*/) {
        lookData.published = true;
        lookData.submissionStage = "published";
        lookData.publishedAt = now;
        lookData.publishedBy = user.id();
    }

    if (!publish) {
        lookData.published = false;
        lookData.publishedAt = null;
        lookData.submissionStage = "hidden";
    }

    if (submitForReview && user.is("FRI")) {
        lookData.submitted = true;
        lookData.submittedAt = now;
        if (!lookData.submissionStage || lookData.submissionStage === "initial") {
            lookData.submissionStage = "submitted";
        }
    }

    if (!editMode) {
        lookData.friId = user.id();
    }
    if (consId && !editMode) {
        lookData.consultationId = consId;
    }

    delete lookData.products;
    delete lookData.styles;
    delete lookData.seasons;
    delete lookData.locations;
    delete lookData.occasions;
    delete lookData.hiddenTags;
    delete lookData.id;

    if (styles.length > 0 || 
        seasons.length > 0 || 
        locations.length > 0 || 
        occasions.length > 0 || 
        hiddenTags.length > 0) {
        /// CREATE TAGS
        const allTags = await api.backend.post("/tag/prepare", { body: {
            Style: styles,
            Season: seasons,
            Location: locations,
            Occasion: occasions,
            HiddenTag: hiddenTags
        }});

        styleIds = allTags.Style.map(t => t.id);
        seasonIds = allTags.Season.map(t => t.id);
        locationIds = allTags.Location.map(t => t.id);
        occasionIds = allTags.Occasion.map(t => t.id);
        hiddenTagIds = allTags.HiddenTag.map(t => t.id);

        lookData.path = generatePath(allTags.Style);
    }

    /// CREATE/UPDATE LOOK

    //console.log("look data", cloneDeep(lookData))

    if (editMode) {
        // this will be called later
        //await api.look.update(lookId, lookData);
    }
    else {
        initialData.description = lookData.description || "";
        initialData.title = lookData.title || "";
        initialData.layouts = lookData.layouts;
        initialData.friId = lookData.friId;
        lookId = await api.look.create(initialData).then(r => r.id);
    }

    /// COPY LOOK IMAGES

    // first step, copy images
    // second step later, in changeLayout
    if (look.layouts.images && look.layouts.images.length > 0) {
        
        for (const img of look.layouts.images) {
            if (!img.saved) {
                changeLayout = true;
                const { key } = await api.backend.post("/image/copy", {
                    body: {
                        key: img.key
                    }
                })
                imgMap[img.key] = key;
            }
        }
    }


    /// SUBMIT PRODUCTS

    let idMap = {},
        newIds = {},
        productId,
        //prevId,
        //product,
        i, l;

    let promises = [];
    for (i = 0, l = products.length; i < l; i++) {
        const product = products[i];
        const prevId = product.id;

        //if (look.layouts[product.id] || look.layouts.tags?.[product.id]) {

            promises.push(new Promise(async (resolve) => {
                //console.log("start product", product.id)
                productId = await submitProduct(product, lookId, i);
            
                if (prevId != productId) {
                    idMap[prevId] = productId;
                    changeLayout = true;
                }
                newIds[productId] = true;

                //console.log("end product", product.id)
                resolve();
            }));   
        //}
    }

    //console.log("awaiting all products", promises.length);
    await Promise.allSettled(promises);
    promises = [];
    //console.log("all products done");

    /// UPDATE LAYOUT IF NEEDED

    if (changeLayout) {
        let layouts = cloneDeep(look.layouts),
            order = layouts.order || [],
            prevId, newId,
            inx;

        for (prevId in idMap) {
            if (layouts[prevId]) {
                newId = idMap[prevId];
                layouts[newId] = { ...layouts[prevId] };
                delete layouts[prevId];
            }
            else if (layouts.tags?.[prevId]) {
                newId = idMap[prevId];
                layouts.tags[newId] = { ...layouts.tags[prevId] };
                delete layouts.tags[prevId];
            }
            inx = order.indexOf(prevId);
            if (inx !== -1) {
                order[inx] = idMap[prevId];
            }
        }

        for (prevId in layouts) {
            if (!newIds[prevId] && 
                prevId !== "template" && 
                prevId !== "order" &&
                prevId !== "images" &&
                prevId !== "tags") {
                delete layouts[prevId];
            }
        }

        if (layouts.images && layouts.images.length) {
            layouts.images.forEach(img => {
                if (!img.saved) {
                    img.key = imgMap[img.key];
                    img.src = s3url(img.key);
                    img.saved = true;
                }
            })
        }

        //console.log("update layouts", cloneDeep(layouts))
        //await 
        promises.push(
            api.look.update(lookId, {
                layouts: JSON.stringify(layouts)
            })
        );
        delete lookData.layouts;
    }

    // Now submit rest of look data
    //await 
    promises.push(
        api.look.update(lookId, lookData)
    );
    
    await Promise.allSettled(promises);
    promises = [];

    /// CREATE TAG CONNECTIONS


    if (styleIds.length > 0) {
        //await 
        promises.push(
            api.lookStyle.create(
                styleIds.map(styleId => ({styleId, lookId})),
                "returning { styleId }",
                {
                    constraint: "Look_Style_pkey",
                    update_columns: []
                }
            )
        );
    }

    if (seasonIds.length > 0) {
        //await 
        promises.push(
            api.lookSeason.create(
                seasonIds.map(seasonId => ({seasonId, lookId})),
                "returning { seasonId }",
                {
                    constraint: "Look_Season_pkey",
                    update_columns: []
                }
            )
        );
    }

    if (locationIds.length > 0) {
        //await 
        promises.push(
            api.lookLocation.create(
                locationIds.map(locationId => ({locationId, lookId})),
                "returning { locationId }",
                {
                    constraint: "Look_Location_pkey",
                    update_columns: []
                }
            )
        )
    }

    if (occasionIds.length > 0) {
        //await 
        promises.push(
            api.lookOccasion.create(
                occasionIds.map(occasionId => ({occasionId, lookId})),
                "returning { occasionId }",
                {
                    constraint: "Look_Occasion_pkey",
                    update_columns: []
                }
            )
        );
    }

    if (hiddenTagIds.length > 0 && api.lookHiddenTag && api.lookHiddenTag.create) {
        //await 
        promises.push(
            api.lookHiddenTag.create(
                hiddenTagIds.map(hiddenTagId => ({ hiddenTagId, lookId })),
                "returning { hiddenTagId }",
                {
                    constraint: "Look_HiddenTag_pkey",
                    update_columns: []
                }
            )
        );
    }



    /// REMOVE DISCARDED TAGS
    // (do not wait for execution)

    if (editMode) {
        deletedStyles && deletedStyles.forEach(s => 
            s.id.indexOf("tmp_") === -1 &&
                api.lookStyle.remove({
                    styleId: { _eq: s.id },
                    lookId: { _eq: lookId }
                })
        );
        deletedSeasons && deletedSeasons.forEach(s => 
            s.id.indexOf("tmp_") === -1 &&
                api.lookSeason.remove({
                    seasonId: { _eq: s.id },
                    lookId: { _eq: lookId }
                })
        );
        deletedLocations && deletedLocations.forEach(l => 
            l.id.indexOf("tmp_") === -1 &&
                api.lookLocation.remove({
                    locationId: { _eq: l.id },
                    lookId: { _eq: lookId }
                })
        );
        deletedOccasions && deletedOccasions.forEach(o =>
            o.id.indexOf("tmp_") === -1 && 
                api.lookOccasion.remove({
                    occasionId: { _eq: o.id },
                    lookId: { _eq: lookId }
                })
        );
        deletedHiddenTags && api.lookHiddenTag && api.lookHiddenTag.remove && 
            deletedHiddenTags.forEach(o =>
                o.id.indexOf("tmp_") === -1 && 
                    api.lookHiddenTag.remove({
                        hiddenTagId: { _eq: o.id },
                        lookId: { _eq: lookId }
                    })
        );

        //console.log("delete products", cloneDeep(deletedProducts))
        deletedProducts && deletedProducts.forEach(p => 
            p.id && removeProduct(p.id)
        );

        //console.log("delete images", cloneDeep(deletedImages))
        deletedImages && deletedImages.forEach(key => 
            api.backend.post("/image/remove", { body: { key }}));

        //console.log("delete uploads", cloneDeep(deletedUploads))
        deletedUploads && deletedUploads.forEach(uploadKey => 
            api.backend.post("/upload/delete", { body: { uploadKey }}));
    }


    return lookId;
}



export function submit(consultationId, publish, submitForReview) {

    if (publish) {
        store.dispatch(editorUi.publishing(true));    
    }
    else if (submitForReview) {
        store.dispatch(editorUi.submitting(true));    
    }
    else {
        store.dispatch(editorUi.saving(true));
    }
    

    let look = store.getState().lookEditor.look,
        editMode = !!look.id,
        p = doSubmit(look, consultationId, publish, submitForReview);

    p.then(id => {

        hub.dispatch("look", editMode ? "updated" : "created", {  
            id, 
            consultationId 
        });

        // if (look.id && (publish || submitForReview)) {
        //     api.lookAdminChat.update(
        //         {
        //             lookId: look.id,
        //             type: "decline"
        //         },
        //         { unread: false }
        //     );
        // }

        if (!look.id) {
            store.dispatch(editorData.reset());
            store.dispatch(productData.reset());
            resetDraft();
        }

        batch(() => {
            store.dispatch(editorUi.hasChanges(false));
            store.dispatch(productUi.hasChanges(false));
        })

        // we don't have to wait for this
        api.backend.post("/me/log-action", { body: {
            action: 
                consultationId ?
                    editMode ? "consultation/look/update" : "consultation/look/create" :
                    editMode ? "look/update" : "look/create"
        }});

        return id;
    })
    .catch(err => {
        hub.dispatch("error", editMode ? "look-update" : "look-create", err);
    })
    .finally(() => {
        batch(() => {
            store.dispatch(editorUi.submitting(false));
            store.dispatch(editorUi.publishing(false));
            store.dispatch(editorUi.saving(false));
        });
    });
    return p;
}

export const setSubmissionStage = function(id, stage) {
    return api.look.update(id, { submissionStage: stage, submitted: true })
            .then(() => {
                hub.dispatch("look", "submission-stage", { id, stage });
            });
};

export const setHidden = function(id) {
    return api.look.update(id, { submissionStage: "hidden", published: false })
            .then(() => {
                hub.dispatch("look", "submission-stage", { id, stage: "hidden" });
            });
};

export function decline(id, message, expiresAt) {

    store.dispatch(editorUi.declining(true));
    const promises = [];

    promises.push(api.look.update(id, {
            submitted: false,
            declinedBy: user.id(),
            submissionStage: "declined",
            expiresAt: expiresAt || null
        })
        .then(() => {
            hub.dispatch("look", "declined", { id });
        })
        .catch(err => {
            hub.dispatch("error", "look-decline", err);
        })
    );

    if (message) {
        promises.push(api.lookAdminChat.create({
            lookId: id,
            adminId: user.id(),
            type: "decline",
            message
        }));
    }

    return Promise.all(promises)
        .then(() => {
            store.dispatch(editorUi.declining(false));
        });
}


export function remove(id) {

    store.dispatch(editorUi.deleting.start(id));

    const params = { deleted: true, deletedAt: (new Date()).toISOString() };
    
    return api.look.update(id, params)
        .then(() => {
            hub.dispatch("look", "deleted", { id });
        })
        .catch(err => {
            hub.dispatch("error", "look-delete", err);
        })
        .finally(() => {
            store.dispatch(editorUi.deleting.stop(id)); 
        });
}

export function removeFromDb(id) {
    store.dispatch(editorUi.deleting.start(id));
    
    return api.look.remove(id)
        .then(() => {
            hub.dispatch("look", "deleted", { id });
        })
        .catch(err => {
            hub.dispatch("error", "look-delete", err);
        })
        .finally(() => {
            store.dispatch(editorUi.deleting.stop(id)); 
        });
}

export function restore(id, published, consultationId) {
    store.dispatch(editorUi.restoring.start(id));

    return api.look.update(id, { deleted: false, published: published })
        .then(() => {
            hub.dispatch("look", "restored", { id, consultationId });
        })
        .catch(err => {
            hub.dispatch("error", "look-restore", err);
        })
        .finally(() => {
            store.dispatch(editorUi.restoring.stop(id)); 
        });
}

export function load(id) {

    return api.look.get(id)
        .then(look => preloadImages(look))
        .then(look => {
            store.dispatch(productData.reset());
            store.dispatch(editorData.set(look));
            store.dispatch(editorUi.page("products"));
        })
        .catch(err => {
            hub.dispatch("error", "look-load-one", err);
        })
}


function _saveDraft() {
    return;
    let state = store.getState(),
        look = { ...state.lookEditor.look };

    const mode = look.productMode === "tagged" ? "tagged" : "layout";
    delete look.fri;

    if (!look.id) {
        api.lookDraft.update(
            {
                friId: {_eq: user.id() },
                mode: { _eq: mode }
            }, 
            {   data: JSON.stringify(look),
                mode,
                createdAt: look.createdAt || (new Date()).toISOString() }
        );
    }
}

export let saveDraft = 
    throttle(() => async(() => _saveDraft()), 10000, { trailing: true });


export function resetDraft(mode) {
    const where = {
        friId: { _eq: user.id() }
    };
    if (mode) {
        where.mode = { _eq: mode };
    }
    return api.lookDraft.update(where, { data: null });
}

export const unpublishLook = function(ids) {
    const returnSingle = !Array.isArray(ids);
    ids = Array.isArray(ids) ? ids : [ ids ];
    const promises = ids.map(id => api.look.update(id, { published: false, publishedAt: null }))
    return Promise.all(promises)
        .then(values => {
            hub.dispatch("look", "unpublished", { id: ids });
            return returnSingle ? values[0] : values;
        })
        .catch(err => {
            hub.dispatch("error", "unpublish-look", err)
        });
};

export const publishLook = function(ids) {
    const returnSingle = !Array.isArray(ids);
    ids = Array.isArray(ids) ? ids : [ ids ];
    const promises = ids.map(id => api.look.update(id, { 
                                        published: true, 
                                        publishedAt: (new Date()).toISOString() }));
    return Promise.all(promises)
        .then(values => {
            hub.dispatch("look", "published", { id: ids });
            return returnSingle ? values[0] : values;
        })
        .catch(err => {
            hub.dispatch("error", "publish-look", err)
        });
};