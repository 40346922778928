import { batch } from "react-redux"
import user from "common/src/user"
import api from "app/api"
import store from "app/store"
import hub from "common/src/hub"
import singlePromise from "common/src/lib/js/singlePromise"
import { data, ui } from "common/src/store/notifications"


const PER_PAGE = 50;
const unreadGraph = `
    id
    chatMessageNotificationId
    chatMessageNotification {
        message {
            hidden
        }
    }
    userNotificationId
`;


let subscription;
let needToCheckForExistence = true;

const checkForExistence = async () => {
    needToCheckForExistence = false;
    const count = await api.userNotificationCombined.count().then(res => res.count);
    store.dispatch(ui.setEnabled(count > 0));
};

const calcUnreadsFromList = (list) => {
    const unreads = { total: 0, chat: 0, general: 0 };
    list.forEach(n => {
        if (n.chatMessageNotificationId) {
            unreads.total++;
            if (!n.chatMessageNotification.message.hidden) {
                unreads.chat++;
            }
        }
        if (n.userNotificationId) {
            unreads.total++;
            unreads.general++;
        }
    });
    return unreads;
}

const subscribe = async () => {
    const where = {
        userId: { _eq: user.id() },
        _or: [
            {
                chatMessageNotification: {
                read: { _eq: false }        
            }},
            {
                userNotification: {
                read: { _eq: false }        
            }}
        ]
    }
    

    subscription = await api.userNotificationCombined.subscribeList(
        { where }, 
        unreadGraph, 
        async (res) => {
            const state = store.getState();
            const prev = state.notifications.ui.unreadCount;
            const list = state.notifications.data.list;
            const unreads = calcUnreadsFromList(res);
            store.dispatch(ui.setUnreadCount(unreads));
            if (unreads.total !== prev.total) {
                hub.dispatch("notifications", "unread-change", unreads.total);
            }
            if (unreads.chat !== prev.chat) {
                hub.dispatch("notifications", "unread-change-chat", unreads.chat);
            }
            if (unreads.general !== prev.general) {
                hub.dispatch("notifications", "unread-change-general", unreads.general);
            }

            //if (unreads.total !== prev.total) {
                loadNotifications(0);
            //}

            if (unreads.total > prev.total) {
                needToCheckForExistence = false;
                //list.length > 0 ? loadNotifications(-1) : loadNotifications(0);
            }
            else {
                if (needToCheckForExistence) {
                    //checkForExistence();
                }
            }
    });
}

const unsubscribe = async () => {
    if (subscription && subscription.subscription) {
        subscription.subscription.unsubscribe();
        subscription = null;
    }
}


const setRead = async (type, id, read = true) => {

    const payload = { read };
    const where = typeof id === "string" ? id : 
                    id.length === 1 ? id[0] : { id: { _in: id }};

    if (read === true) {
        payload.readAt = (new Date()).toISOString();
    }

    store.dispatch(data.list.setRead({ type, id, read }));

    if (type === "chat-message-notification") {
        await api.chatMessageNotification.update(where, payload);
    }
    if (type === "user-notification") {
        await api.userNotification.update(where, payload);
    }
}

const recalcUnread = () => {

}

const groupNotifications = (list) => {

    const grouped = [];
    const map = {};
    let prevKey = null;

    list.forEach(_n => {
        const n = { ..._n };
        let key, id, read = true;
        if (n.chatMessageNotificationId) {
            read = n.chatMessageNotification.read;
            let message = n.chatMessageNotification.message;
            let consId = message.action ?
                            message.look ? message.look.consultationId : "" :
                            "";
            if (!message.user) {
                return;
            }

            key = `chat-${ message.action ? message.id : "" }-${ message.user.id }-${ consId }`;
            id = n.chatMessageNotificationId;
        }
        else if (n.userNotificationId) {
            read = n.userNotification.read;
            if (
                n.userNotification.type === "network-order-approved" ||
                n.userNotification.type === "network-order-received"
            ) {
                const details = JSON.parse(n.userNotification.details);
                key = `user-${ n.userNotification.type }-${ details.orderId }`;
            }
            else {
                key = `user-${ n.userNotification.type }-${ n.userNotification.consultationId || "" }`;
            }
            id = n.userNotificationId;
        }

        if (prevKey === key) {
            map[key].group.push(id);

            if (!read) {
                map[key].unreadCount++;
            }
            return;
        }

        prevKey = key;
        n.group = [ id ];
        n.unreadCount = read ? 0 : 1;
        map[key] = n;
        grouped.push(n);
    })

    return grouped;
}

const loadNotifications = singlePromise({
    mode: singlePromise.ONE,
    pre: (offset = 0) => {
        const setup = { offset };
        return setup;
    },
    fn: (offset = 0) => {
        const where = {
            userId: { _eq: user.id() }
        }
        const order = {
            createdAt: "desc"
        }
        const limit = PER_PAGE;

        if (offset === -1) {
            const state = store.getState();
            const list = state.notifications.data.list;
            const last = list[0].createdAt;
            where.createdAt = { _gt: last };
            offset = 0;
        }
    
        store.dispatch(ui.list.loading(true));
    
        return api.userNotificationCombined.list({ where, order, offset, limit });        
    },
    resolve: (list, setup) => {

        if (setup.offset === 0) {
            batch(() => {
                store.dispatch(data.list.set(groupNotifications(list)));
                store.dispatch(ui.list.count(list.length));
                store.dispatch(ui.list.hasMore(list.length >= PER_PAGE));
                store.dispatch(ui.list.loading(false));
                store.dispatch(ui.setEnabled(list.length > 0));
            })
        }
        else if (setup.offset === -1) {
            const state = store.getState();
            const length = state.notifications.ui.list.count;
            
            batch(() => {
                list.reverse().forEach(_n => {
                    const n = { ..._n };
                    n.group = [ n.chatMessageNotificationId || n.userNotificationId  ];
                    n.unreadCount = n.read ? 0 : 1;
                    store.dispatch(data.list.unshift(n));
                })
                store.dispatch(ui.list.loading(false));
                store.dispatch(ui.list.count(length + list.length));
            })
        }
        else {
            batch(() => {
                store.dispatch(data.list.append(groupNotifications(list)));
                store.dispatch(ui.list.count(setup.offset + list.length));
                store.dispatch(ui.list.hasMore(list.length >= PER_PAGE));
                store.dispatch(ui.list.loading(false));
            })
        }
    },
    reject: (err) => {
        hub.dispatch("error", "notifications-load", err);
        store.dispatch(ui.list.loading(false));
    }
})


const reloadNotifications = async () => {
    
}

const loadMoreNotifications = async () => {
    console.log("load more notifications")
    const state = store.getState();
    const offset = state.notifications.ui.list.count;
    loadNotifications(offset);
}

export { 
    subscribe, unsubscribe, 
    setRead, recalcUnread,
    loadNotifications,
    reloadNotifications,
    loadMoreNotifications
 }