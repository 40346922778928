
import addListener from "common/src/lib/dom/addListener"
import settings from "common/src/settings"

const onCookieAcceptChange = () => {

    const gaId = settings.ga[process.env.REACT_APP_ENV];
    const gaKey = `ga-disable-${gaId}`;

    try {
        if (!window.Cookiebot) {
            window[gaKey] = true;
            return;
        }

        window[gaKey] = !window.Cookiebot.consent.statistics;
    }
    catch (err) {
        window[gaKey] = true;
    }
};


export default {
    init: () => {
        addListener(window, 'CookiebotOnAccept', onCookieAcceptChange);
        addListener(window, 'CookiebotOnDecline', onCookieAcceptChange);
        onCookieAcceptChange();
    }
}