import { useContext, useState, useCallback, useEffect, Fragment } from "react"

//import NullForm from "common/src/components/NullForm"
import Typing from "common/src/components/chat_v2/animations/Typing"
import Recording from "common/src/components/chat_v2/animations/Recording"
import UserAvatar from "common/src/refactor/components/UserAvatar"

import ChatContext from "common/src/lib/chat/ChatContext"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import useContactActivity from "common/src/lib/chat/useContactActivity"
import useIsOnline from "common/src/lib/chat/useIsOnline"

function ContactHeader({ onClose, dialogMode }) {

    const chatApi = useContext(ChatContext);
    const [ activeContact, setActiveContact ] = useState(chatApi.getContact());
    const activity = useContactActivity(activeContact);
    const isOnline = useIsOnline(activeContact);

    const onCloseClick = useSwallowEventCallback(() => onClose && onClose(), [ onClose ]);

    const onContactChange = useCallback(
        () => setActiveContact(chatApi.getContact()), 
        []
    );

    useEffect(() => {
        chatApi.on("contact", onContactChange);
        
        if (chatApi.getContact()) {
            onContactChange();
        }

        return () => {
            chatApi.un("contact", onContactChange);
        }
    }, []);

    if (!activeContact) {
        return null;
    }

    return (
        <div className="chat-main-header">
            
            <UserAvatar user={ activeContact }/>
            <div className="chat-main-header-user-info">
                <div className="chat-main-header-user-info-name">
                    { activeContact.givenName } { activeContact.familyName }
                </div>
                <div className="chat-main-header-user-info-status">
                    { activity ? 
                        activity === "typing" ? <Typing/> : <Recording/> :
                        isOnline ? "Online" : "Offline" }
                </div>
            </div>
        
            { dialogMode && 
                <a href="/#" 
                    className="close" 
                    onClick={ onCloseClick }
                    children={ <IconClose/> }/> }
        </div>
    )
}



export default ContactHeader