import { useEffect } from "react"
import hub from "common/src/hub"
import { useCallback } from "react";
import useDictRef from "./useDictRef";
import useUpdateEffect from "./useUpdateEffect";
//import useUpdateEffect from "./useUpdateEffect"


function toggle(method, channel, event, callback) {

    if (typeof channel === "string") {
        hub[method](channel, event, callback);
    }
    else {
        channel.forEach(([ channel, event, callback ]) => {
            hub[method](channel, event, callback);
        });
    }

}


function useHub(channel, event, callback) {

    const ref = useDictRef({ channel, event, callback });

    const listener = useCallback(
        (...args) => {
            ref.callback?.apply(null, args);
        },
        []
    );

    useEffect(
        () => {
            ref.previousEvent = event;
            ref.previousChannel = channel;
            toggle("listen", channel, event, listener);
            return () => {
                toggle("remove", ref.channel, ref.event, listener);
            }
        },
        []
    );

    useUpdateEffect(
        () => {
            if (ref.previousEvent !== event || ref.previousChannel !== channel) {
                toggle("remove", ref.previousChannel, ref.previousEvent, listener);
                toggle("listen", channel, event, listener);
                ref.previousEvent = event;
                ref.previousChannel = channel;
            }
        },
        [ event, channel ]
    );
}

export default useHub