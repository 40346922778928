import { useEffect, useState, useCallback } from "react"

import useInterval from "common/src/hooks/useInterval"
import useCurrentRef from "common/src/hooks/useCurrentRef"
import { isCurrentActivity } from "common/src/lib/chat/activity"



function useContactActivity(contact) {

    const activityRef = useCurrentRef(contact?.currentActivity);
    const [ activity, setActivity ] = useState(null);

    const updateActivity = useCallback(
        () => {
            if (isCurrentActivity(activityRef.current?.last)) {
                setActivity(activityRef.current.type);
            }
            else setActivity(null);
        },
        []
    );

    const [ startActivity, stopActivity ] = useInterval(
        updateActivity, 
        1000, 
        {
            immediateStart: true,
            trailing: true
        }
    );

    useEffect(
        () => activityRef.current?.last && isCurrentActivity(activityRef.current?.last) ? 
                startActivity() : 
                stopActivity(),
        [ contact?.currentActivity?.last, activity ]
    );

    return activity;
}

export default useContactActivity