import { useCallback, useMemo } from "react"
import { Tooltip } from "antd"
import { Link } from "react-router-dom"

import { ReactComponent as IconLockLocked } from "common/src/svg/lock_locked.svg"
import { ReactComponent as IconLockUnlocked } from "common/src/svg/lock_unlocked.svg"
import { ReactComponent as IconGallery } from "common/src/svg/image.svg"

import swallowEvent from "common/src/lib/dom/swallowEvent"
import user from "common/src/user"


function MoodboardCard({ moodboard, onClick, children, showToolbar = true, fromRecentlyDeleted = false }) {

    const deleted = moodboard.deleted;
    
    const coverImage = useMemo(
        () => {
            const img = moodboard.image;
            return img?.src;
        },
        [ moodboard ]
    );

    const onDefaultLinkClick = useCallback(
        (e) => {
            if (onClick === "default") {
                return;
            }
            swallowEvent(e);
            onClick && onClick(moodboard);
        },
        [ onClick, moodboard ]
    )

    if (deleted && !fromRecentlyDeleted) {
        return (
            <div className="moodboard-card">
                <div className="moodboard-card-cover-image">
                    <p className="moodboard-card-deleted">This moodboard has been removed</p>
                </div>
                <div className="moodboard-card-info">
                    <div className="moodboard-card-toolbar">
                        <p className="moodboard-card-count">&nbsp;</p>
                        <Tooltip title={ moodboard.public ? "Public" : "Private" }>
                            <span>{ moodboard.public ?  <IconLockUnlocked/> : <IconLockLocked/> }</span>
                        </Tooltip>
                    </div>
                    <h5 className="moodboard-card-name">&nbsp;</h5>
                </div>

            </div>
        )
    }

    return (
        <div className="moodboard-card" onClick={onDefaultLinkClick}>

            { coverImage ? 
                <div 
                    className="moodboard-card-cover-image"
                    style={{ backgroundImage: `url(${ coverImage })` }}/> :
                <div className="moodboard-card-image-empty">
                    <IconGallery/>
                </div> }

            <div className="moodboard-card-info">
                <div className="moodboard-card-toolbar">
                    <div className="moodboard-card-count">
                        { moodboard.productsCount } { moodboard.productsCount === 1 ? "product" : "products" }
                    </div>
                    <Tooltip title={ moodboard.public ? "Public" : "Private" }>
                        <span>{ moodboard.public ? <IconLockUnlocked/> : <IconLockLocked/> }</span>
                    </Tooltip>
                </div>
                { moodboard.name && 
                    <h5 className="moodboard-card-name">{ moodboard.name }</h5> }
                { moodboard?.friId !== user.id() && <p className="moodboard-card-note">Curated by the floorr</p> }
            </div>

            { onClick && 
                <Link 
                    className="moodboard-card-link"
                    to={ `/moodboards/${ moodboard.id }` } 
                    onClick={ onDefaultLinkClick }/> }

            { children }
        </div>
    )
}

export default MoodboardCard