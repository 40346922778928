import settings from "app/settings"

let defaultInstance;
let api;

class AnalyticsTiming {

    app = ""
    _log = []
    _itv = null

    constructor() {
        this.app = settings.app;
        this.flush = this.flush.bind(this);
    }

    log(action, duration, details = null) {    

        if (details) {
            if (typeof details !== "string") {
                details = JSON.stringify(details);
            }
        }
        else {
            details = null;
        }

        this._log.push({ app: this.app, action, duration, details });

        if (!this._itv) {
            this.start();
        }
    }

    trackSync(action, details) {

        let start = (new Date()).getTime();

        return (fn) => {

            let res;

            if (fn) {
                start = (new Date()).getTime();
                res = fn();
            }

            let end = (new Date()).getTime();
            if (action.indexOf("appAnalyticsTiming") === -1) {
                this.log(action, end - start, details);
            }

            return res;
        }
    }

    trackAsync(action, details) {
        let start = (new Date()).getTime();
        let res;
        return async (fn) => {
            if (fn) {
                start = (new Date()).getTime();
                res = await fn();
            }

            let end = (new Date()).getTime();
            if (action.indexOf("appAnalyticsTiming") === -1) {
                this.log(action, end - start, details);
            }
            return res;
        }
    }

    async flush() {
        if (this._log.length > 0 && api) {
            const log = [ ...this._log ];
            this._log = [];
            try {
                await api.appAnalyticsTiming.create(log, "affected_rows");
            }
            catch (err) {
                console.error(err);
            }
        }
    }

    start(interval = 10000) {
        if (!this._itv) {
            this._itv = setInterval(this.flush, interval);
        }
    }

    stop() {
        if (this._itv) {
            clearInterval(this._itv);
            this._itv = null;
        }
    }
}

AnalyticsTiming.init = function(apiModule) {
    api = apiModule;
}

AnalyticsTiming.get = function() {
    if (!defaultInstance) {
        defaultInstance = new AnalyticsTiming();
    }
    return defaultInstance;
}

export default AnalyticsTiming