import { useRef, useEffect } from "react"

function useUpdateEffect(fn, deps) {

    const callRef = useRef(0);
    const fnRef = useRef(null);
    fnRef.current = fn;

    useEffect(
        () => {

            if (callRef.current > 0) {
                fnRef.current();
            }

            callRef.current += 1;
        },
        deps
    );
}

export default useUpdateEffect