import { useEffect, useCallback, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Table, Input } from "antd"
import moment from "moment"
import { useNavigate } from "react-router-dom"

import Button from "common/src/refactor/components/button/Button"
import Page from "common/src/refactor/components/Page"
import Spacer from "common/src/refactor/components/Spacer"
import UserAvatar from "common/src/refactor/components/UserAvatar"
import ConsultationDialog from "app/components/consultation/Dialog"
import ConsultationStatusSelect from "app/components/consultation/StatusSelect"
import ConsultationContextMenu from "app/components/consultation/ContextMenu"

import { ReactComponent as IconSearch } from "common/src/svg/search.svg"
import { ReactComponent as IconAdd } from "common/src/svg/add.svg"

import * as actions from "app/actions/page/consultations"

import useDualState from "common/src/refactor/hooks/useDualState"
import useInputHander from "common/src/refactor/hooks/userInputHandler"
//import useHub from "common/src/hooks/useHub"
import swallowEvent from "common/src/lib/dom/swallowEvent"
//import useUpdateEffect from "common/src/hooks/useUpdateEffect"
import ConsultationCard from "common/src/refactor/components/consultation/Card"
import EmptyMessage from "common/src/refactor/components/EmptyMessage"
import useConnections from "common/src/refactor/hooks/useConnections"
// import { consultationLoader, subscribeToUserConsultations } from "common/src/actions/consultations"

//const PER_PAGE = settings.consultationsPerPage;

const params = [
    {
        name: "query"
    },
    /*{
        name: "page",
        default: 0,
        unserialize: p => p ? parseInt(p) : 0
    },*/
    {
        name: "status",
        default: "all",
        autoApply: true,
    },
    {
        name: "contact"
    },
    {
        name: "view",
        default: "grid",
        autoApply: true,
    }
]

function getFromHref(params) {
    return `/styling?status=${ params.status || "" }&` +
            `contact=${ params.contact || "" }&query=${ params.query || "" }`;
}

function getConsUrl(id, params) {
    const from = getFromHref(params);
    return `/styling/${ id }?from=${ encodeURIComponent(from) }`;
}

function consultationMatchesSearch(c, query) {

    const cons = (c.title || "") + " " + (c.description || "");
    

    return cons.toLowerCase().includes(query.toLowerCase());
}


function PageStylingSearchToolbar() {

    const consultations = useSelector(s => s.consultationsPage.data.list);
    const { query, contact, setQuery, applySearchParams } = useDualState({
        params,
        mode: "both"
    });

    const handleQueryChange = useInputHander(setQuery);
    const onSearch = useCallback(
        (value, e, { source }) => {
            //setPage(0);
            if (source === "clear") {
                setQuery("");
            }
            applySearchParams();
        },
        [ applySearchParams, setQuery ]
    );

    const onCreateConsultationClick = useCallback(
        async () => {
            const consultation = contact ? { customerId: contact } : null;
            const res = await ConsultationDialog.show({ consultation });
            res && actions.reloadList(true /* only mine */);
        },
        [ contact ]
    );

    return (
        <>
        <Input.Search 
            placeholder="Search styling sessions"
            size="large"
            disabled={ consultations.length === 0 }
            enterButton={
                <Button 
                    Component="a"
                    href="/#"
                    onClick={ e => e.preventDefault() }
                    disabled={ consultations.length === 0 }
                    size="large"
                    Icon={ IconSearch }
                    text="Search" 
                    type="primary"/>
            }
            allowClear
            onChange={ handleQueryChange }
            value={ query }
            onSearch={ onSearch }/>

        <Button 
            size="large"
            onClick={ onCreateConsultationClick }
            text="Create styling session" 
            type="primary"
            Icon={ IconAdd }
            iconPosition="after"/>
        </>
    )
}

function PageStylingToolbar() {

    const { status, contact, setStatus } = useDualState({
        params,
        mode: "both"
    });

    const connections = useConnections();
    const client = useMemo(
        () => contact ? connections.find(c => c.id === contact) : null,
        [ contact, connections ]
    );


    const onStatusClick = useCallback(
        (e) => {
            swallowEvent(e);
            setStatus(e.target.dataset.status);
        },
        [ setStatus ]
    );

    return (
        <>
        <div className="toolbar">

            { client ? 
                <div className="page-styling-client">
                    <UserAvatar user={ client }/>
                    { client.givenName } { client.familyName }
                </div> :
                <PageStylingSearchToolbar/> }

            <div className="page-styling-menu">
                <a href="/styling?status=all" 
                    data-status="all"
                    onClick={ onStatusClick }
                    className={ status === "all" || !status ? "active" : "" }>
                    All requests
                </a>
                <a href="/styling?status=new" 
                    data-status="new"
                    onClick={ onStatusClick }
                    className={ status === "new" ? "active" : "" }>
                    New requests
                </a>
                <a href="/styling?status=inprogress" 
                    data-status="inprogress"
                    onClick={ onStatusClick }
                    className={ status === "inprogress" ? "active" : "" }>
                    In progress
                </a>
                <a href="/styling?status=cancelled"
                    data-status="cancelled" 
                    onClick={ onStatusClick }
                    className={ status === "cancelled" ? "active" : "" }>
                    Deleted
                </a>
                <a href="/styling?status=completed" 
                    data-status="completed"
                    onClick={ onStatusClick }
                    className={ status === "completed" ? "active" : "" }>
                    Completed
                </a>
            </div>
        </div>
        { client && 
            <>
            <Spacer size="1rem"/>
            <div className="toolbar">
                <PageStylingSearchToolbar/>
            </div>
            </> }
        </>
    )
}


function ConsultationItem({ cons, href, fromHref, onEdited }) {

    return (
        <ConsultationCard 
            key={ cons.id }
            cons={ cons }
            showDate={ false }
            href={ href }>
            <ConsultationContextMenu 
                cons={ cons }
                fromHref={ fromHref }
                onEdited={ onEdited } 
                />
        </ConsultationCard>
    )
}


function PageStyling() {

    const { status, contact, all, view, setView, query } = useDualState({
        params,
        mode: "both"
    });

    const navigate = useNavigate();
    const loading = useSelector(s => s.consultationsPage.ui.list.loading);
    const consultations = useSelector(s => s.consultationsPage.data.list);
    const [ statusEditMode, setStatusEditMode ] = useState(false);

    const consList = useMemo(() => {
        return contact ? consultations.filter(c => c.customerId === contact) : consultations;
    }, [ consultations, contact ]);

    const data = useMemo(
        () => {
            return consList?.filter(c => {
                let match = true;

                if (status && status !== "all") {
                    match = c.status === status;
                }
                else if (status === "all" && (c.status === "new" || 
                c.status === "inprogress" ||
                c.status === "completed")) {
                    match = true
                }
                else {
                    match = false
                }

                if (match && query) {
                    match = consultationMatchesSearch(c, query);
                }

                return match;
            })
        },
        [ query, status, consList ]
    );

    const onViewClick = useCallback(
        (e) => {
            swallowEvent(e);
            const value = e.target.dataset.view;
            //console.log(value, e.target.dataset)
            if (value) {
                setView(value);
            }
        },
        [ setView ]
    );

    const onCreateConsultationClick = useCallback(
        async () => {
            const consultation = contact ? { customerId: contact } : null;
            const res = await ConsultationDialog.show({ consultation });
            res && actions.reloadList(true /* only mine */);
        },
        [ contact ]
    );

    const onConsultationEdited = useCallback(
        () => {
            actions.reloadList(true /* only mine */);
        },
        []
    );

    const reload = useCallback(
        () => {
            actions.reloadList(true /* only mine */);
        },
        []
    );

    const columns = useMemo(
        () => {
            const columns = [
                {
                    key: "title",
                    dataIndex: "title",
                    title: "Title"
                },
                {
                    key: "description",
                    dataIndex: "description",
                    title: "Description"
                },
                {
                    key: "date",
                    dataIndex: "createdAt",
                    title: "Request date",
                    className: "table-cell-nowrap",
                    render: createdAt => moment(createdAt).format("MMM Do YYYY")
                },
                {
                    key: "looks",
                    title: "Looks",
                    dataIndex: "looks",
                    render: looks => looks.filter(l => !l.deleted).length
                },
                {
                    key: "products",
                    title: "Products",
                    dataIndex: "looks",
                    render: looks => looks.map(l => l.deleted ? [] : l.products).flat().length
                },
            ];

            if (status !== "cancelled") {
                columns.push({
                    key: "status",
                    title: "Status",
                    dataIndex: "status",
                    className: "page-styling-cell-status",
                    render: (status, row) => (
                        <ConsultationStatusSelect 
                            size="small"
                            consultation={ row }
                            onStatusChange={ reload }
                            onToggle={ setStatusEditMode }/>
                    )
                })
            }

            if (status !== "cancelled" && status !== "completed") {
                columns.push({
                    key: "action",
                    title: "",
                    dataIndex: "id",
                    className: "table-cell-nowrap",
                    render: (id) => {
                        const from = `/styling?status=${ all.status || "" }&` +
                                    `contact=${ all.contact || "" }&query=${ all.query || "" }`;
                        const url = `/look-editor?consId=${ id }&from=${ encodeURIComponent(from) }`
                        return (
                            <Button
                                type="secondary"
                                size="small"
                                text="Add a look"
                                href={ url }/>
                        )
                    }
                })
            }
            
            if (!contact) {
                columns.unshift({
                    key: "client",
                    dataIndex: "id",
                    title: "Client",
                    render: (id, row) => {
                        if (row.customer) {
                            return (
                                <div className="page-styling-list-client">
                                    <UserAvatar user={ row.customer }/>
                                    { row.customer.givenName } { row.customer.familyName }
                                </div>
                            )
                        }
                    }
                })
            }

            return columns;
        },
        [ contact, status, all, reload ]
    );


    const getRowProps = useCallback(
        (row) => {
            if (statusEditMode) {
                return {};
            }
            return {
                onClick: (e) => {
                    if (e.target instanceof HTMLTableCellElement) {
                        const url = getConsUrl(row.id, all);
                        navigate(url);
                    }
                }
            }
        },
        [ all, navigate, statusEditMode ]
    );

    useEffect(
        () => { 
            actions.subscribeToUserConsultations({
                options: {},
            });

            return () => {
                actions.unsubscribeFromUserConsultations();
            }
        },
        // eslint-disable-next-line
        [ ]
    );

    return (
        <Page className="page-styling" loading={ loading }>
            <Spacer size="xlarge"/>
            <PageStylingToolbar/>

            { consList.length > 0 && 
                <>
                <Spacer size="3rem"/>
                <div className="toolbar">
                    <div className="page-styling-menu page-styling-view-menu">
                        <a href="/#"
                            data-view="grid"
                            onClick={ onViewClick }
                            className={ view === "grid" ? "active" : "" }>
                            Grid view
                        </a>
                        <a href="/#"
                            data-view="list"
                            onClick={ onViewClick }
                            className={ view === "list" ? "active" : "" }>
                            List view
                        </a>
                    </div>
                </div>
                </> }
            
            <Spacer size="xlarge"/>

            { (consList.length === 0 && status !== "cancelled" && status !== "completed" && !loading) && 
                <>
                <Spacer size="10rem"/>
                <EmptyMessage 
                    message={ "Create a styling session here" }
                    cta={ 
                        <Button 
                            type="primary"
                            size="xlarge"
                            shape="circle" 
                            onClick={ onCreateConsultationClick }
                            Icon={ IconAdd }/> 
                    }/>
                </> }

            { (data.length === 0 && !loading && consList.length !== 0 ) && 
                <EmptyMessage
                    message="No styling sessions matched your search"
                    size="small"
                />}

            { (view === "grid" && data.length > 0) && 
                <div className="page-styling-grid">
                    { data.map(cons => (
                        <ConsultationItem 
                            key={ cons.id }
                            cons={ cons }
                            href={ getConsUrl(cons.id, all) }
                            fromHref={ getFromHref(all) }
                            onEdited={ onConsultationEdited }/>
                    ))}
                </div> }

            { (view === "list" && data.length > 0) && 
                <Table
                    rowKey="id"
                    size="small"
                    onRow={ getRowProps }
                    loading={ loading }
                    className="page-styling-list"
                    dataSource={ data }
                    columns={ columns }
                    pagination={ false }/> }
        </Page>
    )
}

export default PageStyling