import { useCallback, useMemo, useRef, useContext } from "react"
import { useDispatch } from "react-redux"

import { ReactComponent as IconAdd } from "common/src/svg/plus.svg"
import { ReactComponent as IconClose } from "common/src/svg/close.svg"
import Dropzone from "common/src/components/Dropzone"
import LookBuilderSearch from "./Search"
//import LookBuilderProduct from "./ProductDialog"
import Loader from "common/src/components/Loader"
import Tag from "common/src/refactor/components/look/Tag"
import LookCard from "common/src/refactor/components/look/Card"

import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import { data, ui } from "common/src/store/look/editor"
import getOffset from "common/src/lib/dom/getOffset"
import useDictRef from "common/src/hooks/useDictRef"
import findParent from "common/src/lib/dom/findParent"
import LookBuilderContext from "./LookBuilderContext"
import useKey from "common/src/refactor/hooks/useKey"
import addListener from "common/src/lib/dom/addListener"
import removeListener from "common/src/lib/dom/removeListener"
import async from "common/src/lib/js/async"
//import upload from "common/src/lib/image/upload"
//import api from "app/api"
//import s3url from "common/src/lib/image/s3url"

import { TIP_SIZE, findPosition, mouse2position } from "common/src/lib/look/tag/position"
import { prepareLookImage } from "lib/look/prepareImage"


function calculateShift({ clientX, clientY, startX, startY,
                            lookWidth, lookHeight, tagWidth, tagHeight,
                            offset }) {

    
    let shiftX = clientX - startX;
    let shiftY = clientY - startY;
    
    
    if (offset.left + shiftX + tagWidth + TIP_SIZE > lookWidth) {
        shiftX = lookWidth - (offset.left + tagWidth + TIP_SIZE);
    }
    if (offset.left + shiftX < TIP_SIZE) {
        shiftX = -offset.left + TIP_SIZE;
    }
    if (offset.top + shiftY + tagHeight + TIP_SIZE > lookHeight) {
        shiftY = lookHeight - (offset.top + tagHeight + TIP_SIZE);
    }
    if (offset.top + shiftY < TIP_SIZE) {
        shiftY = -offset.top + TIP_SIZE;
    }

    return { shiftX, shiftY };
}


function TaggedLookTag({ point, product, look, layout, active = false }) {

    const dispatch = useDispatch();
    const tagRef = useRef(null);
    const builder = useContext(LookBuilderContext);
    //const [ layout, setLayout ] = useState(() => savedLayout || getDefaultTagLayout());
    const draggingTag = useKey("draggingTag", builder);
    const rotatingTag = useKey("rotatingTag", builder);

    const cls = useMemo(
        () => [
            "look-card-tagged-tag",
            active ? "active" : "",
            draggingTag ? "dragged" : "",
            rotatingTag ? "rotating" : ""
        ].join(" "),
        [ active, draggingTag, rotatingTag ]
    );

    const onCloseClick = useSwallowEventCallback(
        () => {
            if (!product || product.id.indexOf("tmp_") === 0) {
                builder.set("newTagPoint", null);
                const cm = builder.get("currentModal");
                if (cm === LookBuilderSearch.NAME) {
                    builder.set("currentModal", null);
                }
            }
            if (product) {
                dispatch(data.products.remove(product.id));
            }
        },
        [ builder, product ]
    );

    const ref = useDictRef({ point, product });

    const onClick = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            if (builder.get("movingTag")) {
                return;
            }
            // if (product) {
            //     const index = look.layouts.order.indexOf(product.id);
            //     builder.set("activeSlot", index);
            //     builder.set("productPage", "images");
            //     builder.set("currentModal", LookBuilderProduct.NAME);
            // }
        },
        [ builder ]
    );


    const _calculateTagPosition = useCallback(
        (opts) => {

            const { adjustedLayout } = tagRef.current.calculateTagPosition(opts);

            if (ref.product) {
                dispatch(data.productTags.update({
                    id: ref.product.id,
                    layout: {
                        point: ref.point,
                        layout: adjustedLayout
                    }
                }));
                dispatch(ui.hasChanges(true));
            }
        },
        // eslint-disable-next-line
        []
    );

    const onMouseMove = useCallback(
        (e) => {
            const tagEl = tagRef.current.el;
            const lookEl = findParent(tagEl, ".look-card-tagged");
            const lookWidth = lookEl.offsetWidth;
            const lookHeight = lookEl.offsetHeight;
            const tagWidth = tagEl.offsetWidth;
            const tagHeight = tagEl.offsetHeight;

            if (ref.startX !== e.clientX || ref.startY !== e.clientY) {
                builder.set("movingTag", true);
            }

            if (ref.pointMove) {

                if (ref.startX !== e.clientX || ref.startY !== e.clientY) {
                    builder.set("draggingTag", true);
                }

                const { shiftX, shiftY } = calculateShift({
                    clientX: e.clientX,
                    clientY: e.clientY,
                    startX: ref.startX,
                    startY: ref.startY,
                    lookWidth, lookHeight,
                    tagWidth, tagHeight,
                    offset: tagRef.current.layout.offset
                });

                //console.log("correct tag style")
                tagRef.current.el.style.transform = `translate(${ shiftX }px, ${ shiftY }px)`;
            }
            else {

                if (ref.startX !== e.clientX || ref.startY !== e.clientY) {
                    builder.set("rotatingTag", true);
                }
                
                const ofs = getOffset(lookEl);
                const mouseY = e.clientY - ofs.top + (window.scrollY || 0);
                const mouseX = e.clientX - ofs.left;
                
                const pointX = lookWidth * (ref.point.x / 100);
                const pointY = lookHeight * (ref.point.y / 100);
                const { tagPosition, tipPosition } = mouse2position({ 
                    pointX, pointY, 
                    mouseX, mouseY, 
                    tagWidth, tagHeight 
                });

                if (tagPosition && tipPosition && 
                    (tagRef.current.layout.tagPosition !== tagPosition || 
                    tagRef.current.layout.tipPosition !== tipPosition)) {

                    const pointOffset = {
                        left: lookWidth * (ref.point.x / 100),
                        top: lookHeight * (ref.point.y / 100)
                    };

                    const adjusted = findPosition({
                        point: pointOffset,
                        tagWidth, tagHeight,
                        lookWidth, lookHeight,
                        defaultTagPosition: tagPosition,
                        defaultTipPosition: tipPosition
                    });

                    if (adjusted.tagPosition === tagPosition && 
                        adjusted.tipPosition === tipPosition) {
                        _calculateTagPosition({
                            defaultTagPosition: tagPosition,
                            defaultTipPosition: tipPosition
                        });
                    }
                }
            }
        },
        // eslint-disable-next-line
        []
    );

    const onMouseUp = useCallback(
        (e) => {
            if (ref.pointMove) {

                const tagEl = tagRef.current.el;
                const lookEl = findParent(tagEl, ".look-card-tagged");
                const lookWidth = lookEl.offsetWidth;
                const lookHeight = lookEl.offsetHeight;
                const tagWidth = tagEl.offsetWidth;
                const tagHeight = tagEl.offsetHeight;

                const { shiftX, shiftY } = calculateShift({
                    clientX: e.clientX,
                    clientY: e.clientY,
                    startX: ref.startX,
                    startY: ref.startY,
                    lookWidth, lookHeight,
                    tagWidth, tagHeight,
                    offset: tagRef.current.layout.offset
                });

                const pointOffset = {
                    left: (lookWidth * (ref.point.x / 100)) + shiftX,
                    top: (lookHeight * (ref.point.y / 100)) + shiftY
                };
                const point = {
                    x: (pointOffset.left * 100) / lookWidth,
                    y: (pointOffset.top * 100) / lookHeight
                };
                ref.point = point;
                ref.startX = null;
                ref.startY = null;

                dispatch(data.productTags.update({
                    id: ref.product.id,
                    layout: {
                        point,
                        layout: tagRef.current.layout
                    }
                }));
                dispatch(ui.hasChanges(true));
                _calculateTagPosition();

                async(() => builder.set("draggingTag", false));           
            }
            else {
                async(() => builder.set("rotatingTag", false));           
            }
            
            removeListener(document.body, "mousemove", onMouseMove);
            removeListener(document.body, "mouseup", onMouseUp);
            e.preventDefault();
            e.stopPropagation();
            async(() => builder.set("movingTag", false));
        },
        // eslint-disable-next-line
        []
    );

    const onMouseDown = useCallback(
        (e) => {
            if (!ref.product) {
                return;
            }

            ref.pointMove = !e.altKey;
            ref.startX = e.clientX;
            ref.startY = e.clientY;
            tagRef.current.el.style.transform = 'translate(0, 0)';
            addListener(document.body, "mousemove", onMouseMove);
            addListener(document.body, "mouseup", onMouseUp);
            e.preventDefault();
            e.stopPropagation();
        },
        // eslint-disable-next-line
        []
    );

    const attrs = useMemo(
        () => {
            return {
                onMouseDown,
                onClick
            }
        },
        [ onMouseDown, onClick ]
    )

    return (
        <Tag
            ref={ tagRef }
            className={ cls }
            attrs={ attrs }
            point={ point }
            layout={ layout }
            product={ product }
            after={ 
                <div className="look-card-tagged-tag-control">
                    <a href="/#" 
                        className="look-card-tagged-tag-close"
                        onClick={ onCloseClick }>
                        <IconClose/>
                    </a>
                </div>
            }/>
    )

}

function TaggedLookCard({ look, className, style, children, onClick, attrs, ...rest }) {

    const builder = useContext(LookBuilderContext);
    const activeSlot = useKey("activeSlot", builder);

    const productProps = useCallback(
        (p, l, inx) => {
            return {
                active: activeSlot === inx,
                look: l
            }
        },
        [ activeSlot ]
    );

    return (
        <LookCard
            attrs={ attrs }
            TagComponent={ TaggedLookTag }
            look={ look }
            productProps={ productProps }
            onClick={ onClick }
            className={ className }
            children={ children }/>
    )
}

function LookBuiderTaggedCard({ look, uploadingImage }) {

    const builder = useContext(LookBuilderContext);
    const dispatch = useDispatch();
    //const [ newTagPosition, setNewTagPosition ] = useState(null);
    const newTagPoint = useKey("newTagPoint", builder);

    //console.log(look)

    const images = useMemo(
        () => {
            if (!look || !look.layouts?.images || look.layouts.images.length === 0) {
                return [];
            }
            return look.layouts.images;
        },
        [ look ]
    );


    const onFileChange = useCallback(
        async (file) => {
            
            builder.set("uploadingLookImage", true);

            const { src, info, key } = await prepareLookImage(file);

            dispatch(data.layouts.setImage({
                //mime,
                src,
                key,
                zoom: 0,
                hplus: 0,
                vplus: 0,
                ...info
            }))
            builder.set("uploadingLookImage", false);

        },
        [ builder, dispatch ]
    );

    const onImageClick = useCallback(
        (e) => {

            e.stopPropagation();

            if (images.length === 0) {
                return;
            }
            if (builder.get("movingTag")) {
                return;
            }

            const el = document.getElementById("look-builder-tagged-card");
            const ofs = getOffset(el);
            const y = e.clientY - ofs.top + (window.scrollY || 0);
            const x = e.clientX - ofs.left;

            const yofs = (y * 100) / el.offsetHeight;
            const xofs = (x * 100) / el.offsetWidth;

            builder.set("newTagPoint", {
                x: xofs,
                y: yofs
            });
            builder.openPage("catalogue");
            builder.set("currentModal", LookBuilderSearch.NAME);
        },
        [ images, builder ]
    );

    const attrs = useMemo(
        () => {
            return ({
                id: "look-builder-tagged-card",
                onClick: onImageClick
            })
        },
        [ onImageClick ]
    );

    const children = useMemo(
        () => {
            const children = [];
            if (images.length === 0 && !uploadingImage) {
                children.push((
                    <Dropzone 
                        key="dropzone"
                        onChange={ onFileChange } 
                        className="look-editor-empty-slot">
                        <div className="look-card-product-add">
                            <IconAdd/>
                        </div>
                    </Dropzone>
                ))
            }

            if (uploadingImage) {
                children.push(
                    <Loader key="loader" size={ 50 }/>
                )
            }

            if (newTagPoint) {
                children.push((
                    <TaggedLookTag 
                        key="new-tag"
                        active
                        look={ look }
                        point={ newTagPoint }/>
                ))
            }

            return children.length > 0 ? children : null;
        },
        [ images, newTagPoint, look, uploadingImage, onFileChange ]
    );

    return (
        <TaggedLookCard 
            look={ look }
            attrs={ attrs }
            children={ children }/>
    )
}

export default LookBuiderTaggedCard