
const getClickDomain = (options = {}) => {
    const stage = process.env.REACT_APP_ENV;

    if (stage === "live") {

        if (options.retailer) {
            if (options.retailer === "sturm") {
                return "drsturm.thefloorr.com";
            }
        }

        return "click.thefloorr.com";
    }
    else if (stage === "devtwo") {
        if (options.retailer) {
            if (options.retailer === "sturm") {
                return "drsturm-devtwo.thefloorr.com";
            }
        }
    }

    return `click-${ stage }.thefloorr.com`;
}

export default getClickDomain;