import StoreModule from "common/src/lib/store/StoreModule"

const chats = new StoreModule("chats");

chats.addEntity("notifications", []);
chats.data.children.notifications.add("remove", (s, a) => {
    const { chatId } = a.payload;
    s.data.notifications = s.data.notifications.filter(n => {
        return n.message.chatId === chatId ? false : true;
    });
})

chats.state.ui.notificationCount = 0;
chats.state.ui.openChat = null;

chats.ui.add("setNotificationCount", (s, a) => { 
    s.ui.notificationCount = a.payload;
});
chats.ui.add("setOpenChat", (s, a) => { 
    s.ui.openChat = a.payload;
});


export let { reducer, data, ui, initialState } = chats.export();