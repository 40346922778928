import api from "app/api"
import { lookLoader } from "./looks";
import { consultationsLoader } from "./consultations";
import hub from "common/src/hub"


export async function deletedLoader({
    userId,
}) {

    const now = (new Date()).getTime();
    // 30 days
    const before = (new Date(now - (60 * 60 * 24 * 30 * 1000))).toISOString();

    const where = {
        userId: { _eq: userId },
        deletedAt: { _gt: before }
    };

    const order = {
        deletedAt: "desc"
    };

    let items = await api.userRecentlyDeleted.list(
        { where, order }, 
        null );

    let looks = [], consultations = [], moodboards = [];
    const lookIds = items.filter(i => !!i.lookId).map(i => i.lookId);
    const consIds = items.filter(i => !!i.consultationId).map(i => i.consultationId);
    const moodboardsIds = items.filter(i => !!i.moodboardId).map(i => i.moodboardId);

    const promises = [];

    if (lookIds.length > 0) {
        promises.push(
            lookLoader({ where: { id: { _in: lookIds }}, limit: lookIds.length })
            .then(list => {
                looks = list
            })
        )
    }

    if (consIds.length > 0) {
        promises.push(
            consultationsLoader({ where: { id: { _in: consIds }}, withLooks: true, limit: consIds.length })
            .then(list => {
                consultations = list
            })
        )
    }

    if (moodboardsIds.length > 0) {
        promises.push(
            api.moodboard.list({where: { id: { _in: moodboardsIds }}}, null, false)
            .then(list => {
                moodboards = list;
            })
        )
    }

    await Promise.allSettled(promises);
    items = items.map(i => {
        if (i.lookId) {
            i.type = "look";
            i.look = looks.find(l => l.id === i.lookId)
        }
        if (i.consultationId) {
            i.type = "consultation";
            i.consultation = consultations.find(c => c.id === i.consultationId);
        }
        if (i.moodboardId) {
            i.type = "moodboard";
            i.moodboard = moodboards.find(m => m.id === i.moodboardId)
        }
        return i;
    });

    return items;
}

export async function restoreItem(item) {
    if (item.type === "moodboard") {
        const data = {
            deleted: false,
            deletedAt: null
        };

        await api.moodboard.update(item.moodboardId, data);
    }

    if (item.type === "look") {
        const data = {
            deleted: false,
            deletedAt: null
        };

        await api.look.update(item.lookId, data);
    }

    if (item.type === "consultation") {
        const data = {
            status: "new",
            cancelledAt: null
        };

        await api.consultation.update(item.consultationId, data);
    }

    const dataToDispatch = {
        type: item.type,
        id: item.type === "look" ? item.lookId : item.type === "moodboard" ? item.moodboardId : item.consultationId
    }

    hub.dispatch("recently-deleted", "updated", dataToDispatch)
}

export async function removeFromDb(item) {
    if (item.type === "moodboard") {
        await api.moodboard.remove(item.moodboardId);
        await api.moodboardCatalogueProduct.remove({ moodboardId: { _eq: item.moodboardId }});
    }

    if (item.type === "look") {
        await api.look.remove(item.lookId);
    }

    if (item.type === "consultation") {
        await api.consultation.remove(item.consultationId);
    }

    const dataToDispatch = {
        type: item.type,
        id: item.type === "look" ? item.lookId : item.type === "moodboard" ? item.moodboardId : item.consultationId
    }

    hub.dispatch("recently-deleted", "updated", dataToDispatch)
}
