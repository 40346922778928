import PostHog from "posthog-js"
//import Observable from "@kuindji/observable"
import { default as appSettings } from "app/settings"
import { default as commonSettings } from "common/src/settings"



const POSTHOG_API_KEY = commonSettings.posthog[appSettings.app][process.env.REACT_APP_ENV];

//export const posthogAutocapturePropsBus = new Observable();

export const posthogOptions = {
    enable: !!POSTHOG_API_KEY && process.env.REACT_APP_ENV === "live",
    api_host: "https://eu.posthog.com",
    autocapture: true,
};


export const posthog = PostHog.init(POSTHOG_API_KEY, posthogOptions);
export const posthogAsync = Promise.resolve(posthog);

export function getPosthogClient() {
    return posthog;
}

export function getPosthogClientAsync() {
    return posthogAsync;
}