import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import getQueryParam from "common/src/lib/url/getQueryParam"

function useQueryParam(name, restore, defaultValue = null) {

    const [ param, setParam ] = useState(() => getQueryParam(name) || defaultValue);
    const [ value, setValue ] = useState(() => restore ? restore(param) : param);
    const location = useLocation();

    useEffect(
        () => {
            const newParam = getQueryParam(name);
            if (param !== newParam) {
                setParam(newParam || defaultValue)
                setValue(restore ? restore(newParam) : newParam);
            }
        }, 
        [ location.search ]
    );

    return value;
}

export default useQueryParam