import NullForm from "common/src/components/NullForm"
import Link from "common/src/components/Link"
import { ReactComponent as Logo } from "common/src/svg/full_logo.svg"
import { ReactComponent as AppStoreIcon } from "common/src/svg/app-store-badge3.svg"
import { Typography } from "antd"
import settings from "app/settings"

function DownloadApp() {
    return (
        <NullForm className="page-signin-download">
            <Link to="/"><Logo/></Link>
            <Typography.Title>Download the app</Typography.Title>

            <Typography.Text>For the best experience, download THE FLOORR App or go to tools.thefloorr.com on desktop.</Typography.Text>

            <a href={ settings.appStoreUrl } target="_blank" rel="noreferrer"><AppStoreIcon/></a>
        </NullForm>
    )
}

export default DownloadApp