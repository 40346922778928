import { Image } from "antd"
import { useCallback, useMemo, useState } from "react"

import Loader from "common/src/components/Loader"

import useHub from "common/src/hooks/useHub"
import hub from "common/src/hub"

const imageStyle = { display: "none" };

function ImagePreview() {

    const [ src, setSrc ] = useState(null);

    const onVisibleChange = useCallback(
        (v) => {
            if (!v) {
                setSrc(null);
            }
        },
        []
    );

    const onViewImage = useCallback(
        (src) => {
            if (Array.isArray(src) && src.length === 1) {
                setSrc(src[0]);
            }
            else {
                setSrc(src);
            }
        },
        []
    );

    const preview = useMemo(
        () => {
            if (!src) {
                return;
            }
            const preview = {
                visible: true,
                onVisibleChange,
                toolbarRender: () => null,
                imageRender: (img, { current }) => {
                    return (
                        <Image 
                            src={ Array.isArray(src) ? src[current] : src } 
                            preview={ false }
                            placeholder={ <Loader size={ false }/> }/>
                    )
                }
            };

            if (!Array.isArray(src)) {
                preview.src = src;
            }

            return preview;
        },
        [ src, onVisibleChange ]
    )

    useHub("app", "view-image", onViewImage);

    if (!src) {
        return null;
    }

    if (Array.isArray(src)) {

        return (
            <Image.PreviewGroup 
                preview={ preview }>
                { src.map(s => (
                    <Image 
                        key={ s }
                        src={ s }
                        placeholder={ <Loader size={ false }/> } 
                        style={ imageStyle }
                        preview={{ src: s }}/>
                ))}
            </Image.PreviewGroup>
        )
    }

    return (
        <Image 
            src={ src } 
            placeholder={ <Loader size={ false }/> }
            style={ imageStyle }
            preview={ preview }/>
    )
}

ImagePreview.show = function(src) {
    hub.dispatch("app", "view-image", src);
}

export default ImagePreview