import { useMemo } from "react"

import cf from "common/src/lib/format/currency"
import useRetailers from "common/src/refactor/hooks/useRetailers";


function useLookProductData(product) {

    const retailers = useRetailers();
    const { price = 0, salePrice = 0, currency = "GBP", availability = "" } = useMemo(
        () => {
            if (!product) {
                return {};
            }
            const 
                p = product,
                stock = p.stock || {},
                price = stock.price || p.price,
                salePrice = stock.salePrice || p.salePrice,
                currency = stock.currency || p.currency,
                availability = stock.availability || p.availability;

            return { price, salePrice, currency, availability };
        },
        [ product?.id, product?.stock, product?.price, product?.currency, product?.salePrice ]
    );

    const brand = useMemo(
        () => product?.designers?.map(d => d.name).join(", ") || "", 
        [ product?.id, product?.designers ]
    );
    const isSale = useMemo(
        () => !!salePrice && salePrice !== price, 
        [ price, currency, salePrice ]
    );
    const isSold = useMemo(
        () => availability === 'out-of-stock', 
        [ availability ]
    );
    const priceStr = useMemo(
        () => price ? cf(price, currency) : "", 
        [ price, currency ]
    );
    const salePriceStr = useMemo(
        () => salePrice ? cf(salePrice, currency) : "", 
        [ salePrice, currency ]
    );
    const retailerName = useMemo(
        () => {
            if (product?.retailer) {
                return retailers.find(r => r.id === product.retailer)?.name ||  product.retailer;
            }
            return null;
        },
        [ product?.retailer, retailers ]
    );

    return {
        price, salePrice, currency, availability, 
        retailer: product?.retailer, retailerName,
        brand, isSale, isSold, priceStr, salePriceStr
    }
}

export default useLookProductData