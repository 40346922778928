
const opposites = { "t": "b", "b": "t", "l": "r", "r": "l" };



export function legacyAdjustPosition(startPosition, offset, change, img, container) {
    
    const vertical = startPosition === "b" || startPosition === "t";
    let mod = startPosition === "l" || startPosition === "t" ? 1 : -1;
    let position = startPosition;
    let diff;

    if (vertical && img.height > container.height) {
        mod = mod * -1;
        diff = img.height / container.height;
        if (diff < 1.5) {
            change += change * (2 - diff);
        }
    }
    else if (vertical && img.height < container.height) {
        diff = container.height / img.height;
        if (diff > 1.5) {
            change -= change * (diff - 1.5);
        }
    }
    else if (!vertical && img.width > container.width) {
        mod = mod * -1;   
        diff = img.width / container.width;
        if (diff < 1.5) {
            change += change * (2 - diff);
        }
    }
    else if (!vertical && container.width > img.width) {
        diff = container.width / img.width;
        if (diff > 1.5) {
            change -= change * (diff - 1.5);
        }
    }

    offset += change * mod;
    if (offset > 50) {
        offset = 50 - (offset - 50);
        position = opposites[ startPosition ];
    }

    return { position, offset };
}

function adjustPosition(startPosition, percOffset, change, img, container) {
    
    let position = startPosition;
    let pxOffset;

    if (startPosition === "l" || startPosition === "r") {
        
        if (startPosition === "r") {
            change *= -1;
        }

        if (container.width !== img.width) {
            pxOffset = (container.width - img.width) * (percOffset / 100);
            pxOffset += change;
            percOffset = (pxOffset / (container.width - img.width)) * 100;
        }
    }
    else {
        if ((startPosition === "b")) {
            change *= -1;
        }

        if (Math.abs(container.height - img.height) > 1) {
            pxOffset = (container.height - img.height) * (percOffset / 100);
            pxOffset += change;
            percOffset = (pxOffset / (container.height - img.height)) * 100;
        } 
        else {
            pxOffset = change; 
            percOffset = (pxOffset / container.height) * 100;
        }
    }
    
    if (percOffset > 50) {
        percOffset = 50 - (percOffset - 50);
        position = opposites[ startPosition ];
    }

    return { position, offset: percOffset };
}

export default adjustPosition