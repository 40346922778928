
export default {
    "1": [
        [ {col: 2, row: 2, cls: ["tl","tr","bl","br"]} ]
    ],
    "2": [
        [ {col: 1, row: 2, cls: ["tl","bl"]}, {col: 1, row: 2, cls: ["tr","br"]} ],

        [ {col: 2, row: 1, cls: ["tl","tr"]}, 
          {col: 2, row: 1, cls: ["bl","br"]} ]
    ],
    "3": [
        [ {col: 1, row: 2, cls: ["tl","bl"]}, {col: 1, row: 1, cls: ["tr"]}, 
                                              {col: 1, row: 1, cls: ["br"]} ],

        [ {col: 1, row: 1, cls: ["tl"]},    {col: 1, row: 2, cls: ["tr","br"]}, 
          {col: 1, row: 1, cls: ["bl"]} ],

        [ {col: 1, row: 3, cls: ["tl","bl"]},   {col: 1, row: 1, cls: ["tr"]},
                                                {col: 1, row: 2, cls: ["br"]} ],

        [   {col: 1, row: 1, cls: ["tl"]},   {col: 1, row: 3, cls: ["tr","br"]},
            {col: 1, row: 2, cls: ["bl"]} ],

        [   {col: 1, row: 3, cls: ["tl", "bl"]},    {col: 1, row: 2, cls: ["tr"]},
                                                    {col: 1, row: 1, cls: ["br"]} ],

        [   {col: 1, row: 2, cls: ["tl"]},   {col: 1, row: 3, cls: ["tr", "br"]},
            {col: 1, row: 1, cls: ["bl"]} ],

        [   {col: 1, row: 5, cls: ["tl", "bl"]},    {col: 1, row: 2, cls: ["tr"]},
                                                    {col: 1, row: 3, cls: ["br"]} ],

        [   {col: 1, row: 2, cls: ["tl"]},   {col: 1, row: 5, cls: ["tr", "br"]},
            {col: 1, row: 3, cls: ["bl"]} ],

        [   {col: 1, row: 5, cls: ["tl","bl"]},   {col: 1, row: 3, cls: ["tr"]},
                                                  {col: 1, row: 2, cls: ["br"]} ],

        [   {col: 1, row: 3, cls: ["tl"]},   {col: 1, row: 5, cls: ["tr","br"]},
            {col: 1, row: 2, cls: ["bl"]} ]


        //[ {col: 1, row: 1}, {col: 1, row: 1}, {col: 2, row: 1} ],
        //[ {col: 2, row: 1}, {col: 1, row: 1}, {col: 1, row: 1} ]
    ],
    "4": [
        [   {col: 1, row: 1, cls: ["tl"]},   {col: 1, row: 1, cls: ["tr"]}, 
            {col: 1, row: 1, cls: ["bl"]},   {col: 1, row: 1, cls: ["br"]}    ],

        [   {col: 1, row: 5, cls: ["tl"]},   {col: 1, row: 6, cls: ["tr"]},
            {col: 1, row: 6, cls: ["bl"]},   {col: 1, row: 5, cls: ["br"]}    ],

        [   {col: 1, row: 3, cls: ["tl","bl"]}, {col: 1, row: 1, cls: ["tr"]}, 
                                                {col: 1, row: 1}, 
                                                {col: 1, row: 1, cls: ["br"]}    ],

        [   {col: 1, row: 1, cls: ["tl"]},   {col: 1, row: 3, cls: ["tr","br"]}, 
            {col: 1, row: 1}, 
            {col: 1, row: 1, cls: ["bl"]}                        ],

        [   {col: 1, row: 2, cls: ["tl"]},   {col: 1, row: 1, cls: ["tr"]},
                                             {col: 1, row: 2, cls: ["br"]},
            {col: 1, row: 1, cls: ["bl"]}                        ],

        [   {col: 1, row: 1, cls: ["tl"]},   {col: 1, row: 2, cls: ["tr"]},
            {col: 1, row: 2, cls: ["bl"]},
                                             {col: 1, row: 1, cls: ["br"]}    ],

        [   {col: 1, row: 2, cls: ["tl"]},   {col: 1, row: 2, cls: ["tr"]},
            {col: 1, row: 1, cls: ["bl"]},   {col: 1, row: 1, cls: ["br"]}    ],

        [   {col: 1, row: 1, cls: ["tl"]},   {col: 1, row: 1, cls: ["tr"]},
            {col: 1, row: 2, cls: ["bl"]},   {col: 1, row: 2, cls: ["br"]}    ],

        

        /*[   {col: 2, row: 1}, 
            {col: 1, row: 1}, {col: 1, row: 2}, 
            {col: 1, row: 1} ],

        [   {col: 2, row: 1}, 
            {col: 1, row: 2}, {col: 1, row: 1}, 
                              {col: 1, row: 1} ],

        [   {col: 1, row: 1}, {col: 1, row: 2}, 
            {col: 1, row: 1},
            {col: 2, row: 1}  ],

        [   {col: 1, row: 2}, {col: 1, row: 1}, 
                              {col: 1, row: 1},
            {col: 2, row: 1}  ]*/
    ],

    "5": [
        [ {col: 1, row: 2, cls: ["tl"]}, {col: 1, row: 3, cls: ["tr"]}, 
          {col: 1, row: 2},              {col: 1, row: 3, cls: ["br"]},
          {col: 1, row: 2, cls: ["bl"]} ],

        [ {col: 1, row: 3, cls: ["tl"]}, {col: 1, row: 2, cls: ["tr"]}, 
                                         {col: 1, row: 2},              
          {col: 1, row: 3, cls: ["bl"]},
                                         {col: 1, row: 2, cls: ["br"]} ],

        [ {col: 1, row: 1, cls: ["tl"]}, {col: 1, row: 1, cls: ["tr"]}, 
          {col: 1, row: 1},              {col: 1, row: 2, cls: ["br"]},
          {col: 1, row: 1, cls: ["bl"]} ],

        [ {col: 1, row: 1, cls: ["tl"]},    {col: 1, row: 1, cls: ["tr"]}, 
          {col: 1, row: 2, cls: ["bl"]},    {col: 1, row: 1},
                                            {col: 1, row: 1, cls: ["br"]} ],

        [ {col: 1, row: 1, cls: ["tl"]},    {col: 1, row: 2, cls: ["tr"]}, 
          {col: 1, row: 1},                 {col: 1, row: 1, cls: ["br"]},
          {col: 1, row: 1, cls: ["bl"]} ],

        [ {col: 1, row: 2, cls: ["tl"]},    {col: 1, row: 1, cls: ["tr"]}, 
                                            {col: 1, row: 1}, 
          {col: 1, row: 1, cls: ["bl"]},    {col: 1, row: 1, cls: ["br"]} ],

        [   {col: 1, row: 2, cls: ["tl"]},   {col: 1, row: 1, cls: ["tr"]},
                                             {col: 1, row: 2},
            {col: 1, row: 2, cls: ["bl"]},   {col: 1, row: 1, cls: ["br"]}    ],

        [   {col: 1, row: 1, cls: ["tl"]},   {col: 1, row: 2, cls: ["tr"]},
            {col: 1, row: 2},
                                             {col: 1, row: 2, cls: ["br"]},   
            {col: 1, row: 1, cls: ["bl"]}                        ]

        /*[   {col: 2, row: 1}, 
            {col: 1, row: 1}, {col: 1, row: 1}, 
            {col: 1, row: 1}, {col: 1, row: 1} ],

        [   {col: 1, row: 1}, {col: 1, row: 1}, 
            {col: 1, row: 1}, {col: 1, row: 1},
            {col: 2, row: 1} ],

        [   {col: 1, row: 1}, {col: 1, row: 3}, 
            {col: 1, row: 1}, 
            {col: 1, row: 1},
            {col: 2, row: 1} ],

        [   {col: 1, row: 3},   {col: 1, row: 1}, 
                                {col: 1, row: 1}, 
                                {col: 1, row: 1},
            {col: 2, row: 1} ],

        [   {col: 1, row: 2},   {col: 1, row: 1}, 
                                {col: 1, row: 2},   
            {col: 1, row: 1},
            {col: 2, row: 1} ],

        [   {col: 1, row: 1},   {col: 1, row: 2}, 
            {col: 1, row: 2},   {col: 1, row: 1},
            {col: 2, row: 1} ]*/
    ]
}