import { batch } from "react-redux"
import { data, ui } from "common/src/store/user"
import store from "app/store"
import user from "common/src/user"
import hub from "common/src/hub"
import Auth from "@aws-amplify/auth"


export default async function() {

    const anonymous = {
        headers: {
            "X-Hasura-Role": "anonymous"
        }
    };

    return user.authPromise()
        // currentSession() makes amplify refresh jwt token
        .then(() => Auth.currentSession())
        //.then(() => Auth.currentAuthenticatedUser())
        .then(session => {
            let token = session.idToken,
                groups = token.payload['cognito:groups'].map(g => g.toLowerCase()),
                grp;

            grp = groups.indexOf("admin") !== -1 ?
                    "tf-admin" : 
                    groups.indexOf("fri") !== -1 ?
                        "fri" :
                        groups.indexOf("gps") !== -1 ?
                            "gps" :
                            groups.indexOf("user") !== -1 || 
                            groups.indexOf("contributor") !== -1 ?
                                "user" : "anonymous";

            return {
                headers: {
                    Authorization: "Bearer " + token.jwtToken,
                    "X-Hasura-Role": grp
                }
            }
        })
        .catch(err => {

            let signedOut = false;
            let jwtExpired = false;
            let msg = typeof err === "string" ? 
                        err : 
                        err.message ? 
                            err.message : 
                            "";

            if (msg.toLowerCase() === "no current user") {
                //signedOut = true;
                return anonymous;
            }
            if (msg.match(/JWSError/i) || 
                (msg.match(/jwt/i) && msg.match(/expired/i))) {
                jwtExpired = true;
            }

            if (signedOut || jwtExpired) {
                hub.dispatch("error", "token-gen", err);

                return Auth.currentAuthenticatedUser({ bypassCache: true })
                    .then(() => {
                        hub.dispatch("error", "token-gen-still-has-user");
                    })
                    .catch(err => {
                        batch(() => {
                            store.dispatch(data.loggedIn(false));
                            store.dispatch(ui.loading(false));
                            store.dispatch(ui.checking(false));
                        });
                        hub.dispatch("app-auth", "stateChange", false);
                        hub.dispatch("app", "redir", "/signin");
                    });
            }
            
            return anonymous;
        });
}