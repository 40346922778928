
import { ReactComponent as IconDress } from "common/src/svg/dress.svg"
import { ReactComponent as IconShoe } from "common/src/svg/shoe.svg"

const sizeType2Icon = {
    default: IconDress,
    shoes: IconShoe
}

function SizeItem({ size }) {
    
    const Icon = sizeType2Icon[size.type || size.key];

    return (
        <div>
            <Icon/>
            <span>
                { size.size } { size.sizeSystem.toUpperCase() }
            </span>
        </div>
    )
}

function CustomerSizes({ sizes }) {

    return (
        <div className="customer-sizes">
            { sizes.map((s, inx) => (
                <SizeItem key={ s?.id +"-"+ inx } size={ s }/>
            ))}
        </div>
    )
}

export default CustomerSizes