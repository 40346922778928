
const ACTIVITY_TIME = 5000;

export function isCurrentActivity(last) {
    if (!last) {
        return false;
    }
    const now = (new Date()).getTime();
    const then = (new Date(last)).getTime();
    return now - then <= ACTIVITY_TIME;
};

