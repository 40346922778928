
export default function(name, value, localDomain = false) {

	if (typeof window !== "undefined" && typeof document !== "undefined") {

		let domain = window.location.host,
			d;
		name = `tf-${process.env.REACT_APP_ENV}-${name}`;

		if (domain.indexOf("thefloorr") !== -1 && !localDomain) {
			domain = "thefloorr.com";
		}

		if (value !== null) {
			d = new Date((new Date()).getTime() + (1000 * 60 * 60 * 24 * 365));
			document.cookie = `${name}=${value};domain=${domain};expires=${d.toISOString()}`;
		}
		else {
			d = new Date((new Date()).getTime() - (1000 * 1000));
			document.cookie = `${name}=;domain=${domain};expires=${d.toISOString()}`;
		}
	}
}