
import { ReactComponent as IconLike } from "common/src/svg/heart-active.svg"
import { ReactComponent as IconDislike } from "common/src/svg/heart-active-cross.svg"
import { ReactComponent as IconShop } from "common/src/svg/basket-active.svg"

const reaction2icon = {
    "like": <IconLike/>,
    "dislike": <IconDislike className="icon icon-svg-fill icon-dislike"/>,
    "shop": <IconShop className="icon icon-svg-fill icon-shop"/>
}
    
function CatalogueProductReaction({ reaction }) {
    return (
        <div className="catalogue-product-reaction">
            { reaction2icon[reaction] || null }
        </div>
    )
}

export default CatalogueProductReaction