import { Modal, Spin, Input } from "antd"
import { useCallback, useState, useMemo, useEffect } from "react"
import { useSelector } from "react-redux"

import Button from "../button/Button"
import { ReactComponent as IconSearch } from "common/src/svg/search.svg"
import { ReactComponent as IconClose } from "common/src/svg/close.svg"
import MoodboardCard from "common/src/refactor/components/moodboard/Card"

import useDualState from "common/src/refactor/hooks/useDualState"
import useQuery from "common/src/refactor/hooks/useQuery"
import useInputHander from "common/src/refactor/hooks/userInputHandler"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import { loadMoodboards } from "common/src/actions/moodboards"
import store from "app/store"
import { ui } from "common/src/store/dialogs"
import useDictRef from "common/src/hooks/useDictRef"
import app from "app/appContext"
import useOnAppEvent from "../../hooks/useOnAppEvent"
import Radio from "common/src/refactor/components/form/Radio";
import useSelection from "common/src/refactor/hooks/useSelection"
import { ReactComponent as IconPlus } from "common/src/svg/plus.svg"
import EmptyMessage from "common/src/refactor/components/EmptyMessage"

const selectionPromise = {};

const PER_PAGE = 6;
const DIALOG_NAME = "moodboards-dialog";

const params = [
    {
        name: "query",
        default: ""
    }
];

function MoodboardSelect({ id, selected, onChange }) {
    const onClick = useSwallowEventCallback(
        () => onChange(id, !selected),
        [ selected, id, onChange ]
    )
    

    return (
        <a href="/#" 
        className="chat-moodboard-card-select"
        onClick={ onClick }>
            <Radio checked={ selected } size="medium"/>
        </a>
    )
}


function Moodboards({ dialogName = DIALOG_NAME, onlyMine = false }) {

    const [ page, setPage ] = useState(0);
    const { query, setQuery, all, applyAll } = useDualState({
        params,
        mode: "state"
    });

    const { selection, map, add, remove } = useSelection({
        mapFn: m => m.id,
        comparisonFn: (a, b) => a.id === b.id
    });
    const selectionCnt = useMemo(() => Object.keys(selection).length, [ selection ]);

    const handleQueryChange = useInputHander(setQuery);
    const onSearch = useCallback(
        (value, e, { source }) => {
            setPage(0);
            if (source === "clear") {
                setQuery("");
            }
            applyAll();
        },
        [ applyAll, setQuery ]
    );
    const onClose = useSwallowEventCallback(
        () => MoodboardsDialog.hide(dialogName),
        [ dialogName ]
    );

    const { data: moodboards,
            isLoading, 
            isLoaded, 
            extraData: { hasMore } } = 
            useQuery(
                loadMoodboards,
                [ all, page, onlyMine ],
                {
                    params: {
                        mine: onlyMine,
                        page,
                        perPage: PER_PAGE,
                        ...all
                    },
                    rowKey: "id",
                    append: page > 0,
                    processResponse: (response) => {
                        return { 
                            data: response.items, 
                            count: response.count,
                            hasMore: response.items.length >= PER_PAGE
                        }
                    },
                    initialData: []
                }
            );

    useEffect(
        () => { // dirty hack to update styles if empty
            if (isLoaded && moodboards.length === 0 && !query) {
                app.trigger(`app/${ dialogName }/options`, { onlyMine, empty: true });
            }
        }, 
        [ isLoaded, onlyMine, moodboards ]
    );

    const loadMore = useCallback(
        () => setPage(page => page + 1),
        []
    );

    const ref = useDictRef({ hasMore, isLoading, loadMore });

    const onScroll = useCallback(
        (e) => {
            const el = e.target;
            if (ref.hasMore && !ref.isLoading) {
                if (el.scrollTop / (el.scrollHeight - el.offsetHeight) > 0.75) {
                    ref.loadMore();
                }
            }
        },
        // eslint-disable-next-line
        []
    );

    const onSelectionChange = useCallback(
        (id, selected) => {
            const moodboard = moodboards.find(p => p.id === id);
            selected ? add(moodboard) : remove(moodboard);
        },
        [ selection, moodboards ]
    );

    const onSelectClick = useCallback(
        () => {
            if (selectionPromise[dialogName]) {
                selectionPromise[dialogName](selection);
            }

            MoodboardsDialog.hide(dialogName);
        },
        [ selection, moodboards, dialogName ]
    );

    const onAddClick = useSwallowEventCallback(
        () => MoodboardsDialog.hide(dialogName),
        [ dialogName ]
    )

    return (
        <div className="moodboards-dialog-body">
            <div className="toolbar">
            { (moodboards.length > 0 || query || isLoading || isLoaded) && 
                <Input.Search 
                    placeholder="Search moodboards"
                    size="large"
                    enterButton={
                        <Button 
                            Icon={ IconSearch }
                            text="Search" 
                            type="primary"/>
                    }
                    allowClear
                    onChange={ handleQueryChange }
                    value={ query }
                    onSearch={ onSearch }/>
            }
                <a href="/#" 
                    className="feed-dialog-close"
                    onClick={ onClose }>
                    <IconClose/>
                </a>
            </div>
            <div className="moodboard-cards" onScroll={ onScroll }>
                { moodboards.length > 0 &&  moodboards.map(m => (
                    <MoodboardCard 
                        key={ m.id } 
                        moodboard={ m }>
                        <MoodboardSelect id={ m.id } selected={ map[m.id] || false } onChange={ onSelectionChange }/>
                    </MoodboardCard>
                ))}

                { (moodboards.length === 0 && !!query && isLoaded && !isLoading) &&
                    <EmptyMessage size="small" message="No moodboards matched your search"/>
                 }

                { (hasMore || !isLoaded) && <Spin spinning className="infinite-scroll-spinner"/> }
            </div>
            { (moodboards.length === 0 && !query && !isLoading && !isLoaded) &&
                <div className="moodboard-cards-empty">
                    <p>You haven't created any moodboards yet</p>

                    <div className="moodboard-cards-empty-action">
                        Create a moodboard here
                        <Button
                            size="large"
                            type="primary"
                            shape="circle"
                            Icon={ IconPlus }
                            href="/moodboards"
                            onClick={ onAddClick }
                        />
                    </div>
                </div>
            }
            { (moodboards.length > 0 || query || isLoading || isLoaded) && 
                <div className="feed-dialog-footer">
                    <Button 
                        type="primary" 
                        text={ `Select (${ selectionCnt })` }
                        onClick={ onSelectClick }
                        disabled={ selectionCnt === 0 || isLoading }/>
                </div>
            }
        </div>
    )
}

function MoodboardsDialog({ name = DIALOG_NAME }) {

    const open = useSelector(s => s.dialogs[name] || false);
    const [ options, setOptions ] = useState({});

    const onClose = useCallback(
        () => MoodboardsDialog.hide(name),
        [ name ]
    );

    useOnAppEvent(`app/${ name }/options`, setOptions);

    return (
        <Modal 
            classNames={{ wrapper: `moodboards-dialog ${ options?.empty && "empty" }` }}
            centered    
            width={options?.empty ? "480px" : "852px"}
            open={ open } 
            destroyOnClose 
            closeIcon={ null }
            footer={ null }
            maskClosable={ false }
            onCancel={ onClose }>
            <Moodboards dialogName={ name } { ...options }/>
        </Modal>
    )
}


MoodboardsDialog.NAME = DIALOG_NAME;

MoodboardsDialog.show = function(options = {}, name = DIALOG_NAME) {
    console.log(options, name)
    app.trigger(`app/${ name }/options`, options);
    store.dispatch(ui.show(name));
    return new Promise((resolve) => {
        selectionPromise[name] = resolve;
    });
}

MoodboardsDialog.hide = function(name = DIALOG_NAME) {
    selectionPromise[name] && selectionPromise[name]([]);
    store.dispatch(ui.hide(name));
    delete selectionPromise[name];
}

export default MoodboardsDialog