//import { useState, useCallback } from "react"
import { useSelector } from "react-redux"
//import Button from "@mui/material/Button"

//import Loader from "common/src/components/Loader"
//import Avatar from "common/src/components/user/Avatar"

import { ReactComponent as IconEmpty } from "common/src/svg/no-image.svg"

//import { ui } from "common/src/store/user"
import userSelector from "common/src/selectors/user/current"
//import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
//import { removeAvatar } from "common/src/actions/user"
import s3url from "common/src/lib/image/s3url"


function ProfileAvatar() {

    //const dispatch = useDispatch();
    const current = useSelector(userSelector);
    //const [ removingAvatar, setRemovingAvatar ] = useState(false);
    const avatarUrl = current.avatar ? s3url(current.avatar) : null;

    return (
        <div className="user-profile-avatar">
            <label className="user-profile-title">Photo</label>
            <div className="user-profile-avatar-image">
                { avatarUrl ?
                    <img src={ avatarUrl }/> :
                    <IconEmpty/> }
            </div>
        </div>
    )
}

export default ProfileAvatar