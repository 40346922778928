
import StoreModule from "common/src/lib/store/StoreModule"

const css = new StoreModule("consulations.page");
css.addList("list");

css.ui.children.list.add("status", (s, a) => {
    s.ui.list.status = a.payload;
});

export let { reducer, data, ui, initialState } = css.export();
