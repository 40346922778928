import { useMemo, useCallback, useState } from "react"
import { Auth } from "@aws-amplify/auth"
import { Input, Typography, Flex, App } from "antd"

import Button from "common/src/refactor/components/button/Button"
import FormInput from "../form/FormInput"
import { Form, FormContext, useFormFields } from "common/src/refactor/lib/form/Form"
import { ReactComponent as Logo } from "common/src/svg/full_logo.svg"
import NullForm from "common/src/components/NullForm"

import api from "app/api"
import settings from "app/settings"

import required from "common/src/refactor/lib/form/validator/required"
import isEmail from "common/src/refactor/lib/form/validator/isEmail"
import validatePassword from "common/src/refactor/lib/form/validator/password"
import useDictRef from "common/src/hooks/useDictRef"
import Link from "common/src/components/Link"


function validatePasswordConfirm(password, _, values) {
    if (!password) {
        return null;
    }
    return password === values.password;
}

const emailFormFields = [
    {
        name: "email",
        validator: [ 
            [ required, "Please enter your email" ],
            [ isEmail, "Please enter a valid email" ]
        ],
        defaultValue: ""
    },
];

const codeFormFields = [
    {
        name: "code",
        validator: [ 
            [ required, "Please enter the code you received" ],
        ],
        defaultValue: ""
    },
    {
        name: "password",
        validator: [ 
            [ required, "Please enter your new password" ],
            [ validatePassword ],
        ],
        defaultValue: ""
    },
    {
        name: "password2",
        validator: [ 
            [ required, "Please confirm your password" ],
            [ validatePasswordConfirm, "Passwords don't match" ],
        ],
        defaultValue: ""
    },
];

const title = "Reset your password";


async function sendCode(email, onError, onSuccess, modal, onStateError) {


    const resend = async function() {
        await api.unauth.post("/resend-email-verification", { body: { email }});
        modal.info("Check your email for new verification link");
    };

    return Auth.forgotPassword(email.toLowerCase())
                .then(data => onSuccess && onSuccess(data.CodeDeliveryDetails))
                .catch(err => {
                    if (err.message.indexOf("limit exceeded") !== -1) {
                        modal.error({ content: err.message });
                    }
                    else if (err.message.indexOf("verified email") !== -1) {
                        modal.error({
                            title: err.message,
                            okText: "Re-send verification email",
                            okType: "primary",
                            onOk: resend
                        });
                    }
                    else if (err.message.indexOf("cannot be reset in the current state") !== -1) {
                        onStateError(
                            "email", 
                            "Set up your account using the temporary password provided via email."
                        );
                    }
                    else {
                        modal.error({ content: "Sorry we don't recognise that email address" });
                    }

                    onError && onError(err);
                });
}


function ForgotPasswordEmailView({ onAuthStateChange, authData, 
                                    onCodeReceived, onError, onEmailChange }) {

    const [ loading, setLoading ] = useState(false);
    const form = useMemo(() => new Form(emailFormFields), []);
    const { email, emailChange, emailError, emailSetError } = useFormFields(["email"], form);
    const { modal } = App.useApp();

    const ref = useDictRef({ email });

    const onBackClick = useCallback(
        () => onAuthStateChange('signIn'),
        [ onAuthStateChange ]
    );

    const onStateError = useCallback(
        (field, error) => {
            if (field === "email") {
                emailSetError(error);
            }
        },
        [ emailSetError ]
    );

    const send = useCallback(
        async () => {
            setLoading(true);
            onEmailChange && onEmailChange(ref.email || authData.email);
            await sendCode(ref.email || authData.email, 
                            onError, onCodeReceived, modal, onStateError);
            setLoading(false);
        },
        [ onCodeReceived, onError, onEmailChange, modal, onStateError ]
    );

    const onSubmit = useCallback(
        async () => {
            const valid = await form.validateAll();
            if (valid) {
                send();
            }
        },
        [ form, send ]
    );

    return (
        <FormContext.Provider value={ form }>
        <NullForm className="page-signin-form">
            
            <Link to="/"><Logo/></Link>
            <Typography.Title>{ title }</Typography.Title>

            <FormInput label="Email *" error={ emailError }>
                <Input 
                    autoFocus
                    autoCorrect="off" 
                    autoCapitalize="none"
                    id="login-email"
                    name="email"
                    value={ email }
                    disabled={ loading }
                    onPressEnter={ onSubmit }
                    onChange={ emailChange }
                    data-cy="login-form-email"/>
            </FormInput>

            <Button 
                data-cy="login-form-submit"
                id="login-submit"
                onClick={ onSubmit }
                loading={ loading }
                disabled={ loading }
                text="Send code"/>
            
            <Button 
                type="text" 
                size="small" 
                disabled={ loading }
                text="Back to login"
                onClick={ onBackClick }/>
        </NullForm>
        </FormContext.Provider>
    )
}

function ForgotPasswordCodeView({ onAuthStateChange, email, delivery, 
                                    onDeliveryChange, onError }) {

    const [ loading, setLoading ] = useState(false);
    const form = useMemo(() => new Form(codeFormFields), []);
    const { code, codeChange, codeError,
            password, passwordChange, passwordError,
            password2, password2Change, password2Error } = 
        useFormFields(["code", "password", "password2"], form);

    const ref = useDictRef({ code, email, password });
    const { modal } = App.useApp();

    const onBackClick = useCallback(
        () => onAuthStateChange('signIn'),
        [ onAuthStateChange ]
    );

    const onResendClick = useCallback(
        async () => {
            setLoading(true);
            await sendCode(email, onError, onDeliveryChange, modal);
            setLoading(false);
        },
        [ email, onDeliveryChange, onError, modal ]
    );

    const submit = useCallback(
        async () => {
            
		    const email = ref.email;
            const clientMetadata = {
                action: "passwordChange",
                origin: "web",
                app: settings.app
            };

            setLoading(true);

		    await Auth.forgotPasswordSubmit(
                email.toLowerCase(), ref.code.trim(), 
                ref.password, clientMetadata)
                .then(data => {
                    modal.success({
                        title: "Done",
                        content: "Your password has been reset"
                    });
                    onAuthStateChange('signIn');
                    onDeliveryChange(null);
                })
                .finally(() => {
                    setLoading(false);
                })
                .catch(err => {
                    modal.error({
                        title: "Ooops",
                        content: err.message
                    });
                    onError && onError(err);
                });
        },
        [ onError, onDeliveryChange, onAuthStateChange ]
    );

    const onSubmit = useCallback(
        async () => {
            const valid = await form.validateAll();
            if (valid) {
                submit();
            }
        },
        []
    );

    return (
        <FormContext.Provider value={ form }>
        <NullForm className="page-signin-form">
            
            <Link to="/"><Logo/></Link>
            <Typography.Title>{ title }</Typography.Title>

            <FormInput label="Code *" error={ codeError }>
                <Input 
                    autoFocus
                    autoCorrect="one-time-code" 
                    autoCapitalize="none"
                    id="login-code"
                    value={ code }
                    disabled={ loading }
                    onPressEnter={ onSubmit }
                    onChange={ codeChange }
                    data-cy="login-form-code"/>
            </FormInput>

            <FormInput label="New password" error={ passwordError }>
                <Input 
                    id="login-password"
                    autoCorrect="new-password" 
                    autoCapitalize="none"
                    value={ password }
                    disabled={ loading }
                    onPressEnter={ onSubmit }
                    onChange={ passwordChange }
                    type="password"
                    data-cy="login-form-password"/>
            </FormInput>

            <FormInput label="Confirm new password" error={ password2Error }>
                <Input 
                    id="login-password"
                    name="confirm-password"
                    autoCorrect="new-password-repeat" 
                    autoCapitalize="none"
                    value={ password2 }
                    disabled={ loading }
                    onPressEnter={ onSubmit }
                    onChange={ password2Change }
                    type="password"
                    data-cy="login-form-password2"/>
            </FormInput>

            <Button 
                data-cy="login-form-submit"
                id="login-submit"
                onClick={ onSubmit }
                loading={ loading }
                disabled={ loading }
                text="Submit"/>
            
            <Flex gap="small" justify="center">
                <Button 
                    type="text" 
                    size="small" 
                    disabled={ loading }
                    text="Back to login"
                    onClick={ onBackClick }/>
                <Button 
                    type="text" 
                    size="small" 
                    disabled={ loading }
                    text="Resend code"
                    onClick={ onResendClick }/>
            </Flex>
        </NullForm>
        </FormContext.Provider>
    )
}

function ForgotPasswordForm({ onAuthStateChange, onError }) {

    const [ email, setEmail ] = useState(null);
    const [ delivery, setDelivery ] = useState(null);

    if (!delivery) {
        return (
            <ForgotPasswordEmailView 
                onAuthStateChange={ onAuthStateChange }
                onError={ onError }
                onCodeReceived={ setDelivery }
                onEmailChange={ setEmail }/>
        )
    }
    else {
        return (
            <ForgotPasswordCodeView 
                onAuthStateChange={ onAuthStateChange }
                onError={ onError }
                delivery={ delivery }
                onDeliveryChange={ setDelivery }
                email={ email }/>
        )
    }

}


export default ForgotPasswordForm