import { useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Input, Drawer } from "antd"

import NullForm from "common/src/components/NullForm";
import { ReactComponent as IconSearch } from "common/src/svg/search.svg"

import store from "app/store"
import { ui } from "common/src/store/dialogs"
import useInputHander from "common/src/refactor/hooks/userInputHandler";
import swallowEvent from "common/src/lib/dom/swallowEvent";
//import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import useOnAppEvent from "common/src/refactor/hooks/useOnAppEvent"
import app from "app/appContext"
import Button from "../button/Button";
import useDictRef from "common/src/hooks/useDictRef";
import ListItem from "./ConnectionItem";
import useConnections from "common/src/refactor/hooks/useConnections";

const DIALOG_NAME = "connection-selector";

let selectorResolve;

function ConnectionSelectorPage({ name, multiple, connectionId, cta, showSearch = true }) {

    const conns = useConnections();
    const [ query, setQuery ] = useState("");
    const handleQueryChange = useInputHander(setQuery);
    const [ selection, setSelection ] = useState(() => {
        if (connectionId) {
            const contact = conns.find(c => c.id === connectionId);
            if (contact) {
                return [ contact ];
            }
        }
        return [];
    });

    const ref = useDictRef({ selection, multiple, connectionId });

    const list = useMemo(
        () => {
            let list = conns.map(c => ({
                ...c,
                isSelected: selection.findIndex(c1 => c1.id === c.id) !== -1
            }))

            if (connectionId) {
                list = list.filter(c => c.id === connectionId);
            }

            if (!query) {
                return list;
            }

            const lc = query.toLowerCase();
            return list.filter(c => {
                return c.givenName.toLowerCase().indexOf(lc) !== -1 ||
                        c.familyName.toLowerCase().indexOf(lc) !== -1 ||
                        c.email.toLowerCase().indexOf(lc) !== -1;
            })
        },
        [ query, conns, selection, connectionId ]
    );

    const onConnectionPress = useCallback(
        (conn) => {
            const inx = ref.selection.findIndex(c => c.id === conn.id);
            const sel = ref.multiple ? [ ...ref.selection ] : [];

            if (inx === -1) {
                sel.push(conn);
            } else {
                if (ref.multiple) {
                    sel.splice(inx, 1);
                }
            }

            setSelection(sel);
        },
        []
    )

    const onSelectConnection = useCallback(
        (e) => {
            swallowEvent(e);
            selectorResolve && selectorResolve([...ref.selection]);
            ConnectionSelector.hide(name);
        },
        [ conns, name ]
    );

    return (
        <div className="connection-selector">
            { showSearch &&             
                <NullForm>
                    <Input 
                        prefix={ <IconSearch/> }
                        size="large"
                        placeholder="Search connections"
                        allowClear
                        onChange={ handleQueryChange }
                        value={ query }/>
                </NullForm>}
            <div className="connection-selector-list">
                { list.map(c => (
                    <ListItem 
                        key={ c.id } 
                        c={ c } 
                        onConnectionPress={ onConnectionPress }/>
                ))}
            </div>
            <Button 
                text={ cta || "Send" }
                disabled={ selection.length === 0 }
                onClick={ onSelectConnection }
            />
        </div>
    )
}

function ConnectionSelector({ name = DIALOG_NAME }) {

    const [ options, setOptions ] = useState({});
    const open = useSelector(s => s.dialogs[name]);
    const onCancel = useCallback(
        () => {
            selectorResolve && selectorResolve(null);
            ConnectionSelector.hide(name);
        },
        [ name ]
    );

    useOnAppEvent(`app/${ name }/options`, setOptions);

    return (
        <Drawer 
            open={ open } 
            className="tf-drawer" 
            title={ options.title || "Send to a client" }
            destroyOnClose 
            onClose={ onCancel }>
            { open && <ConnectionSelectorPage name={ name } { ...options }/> }
        </Drawer>
    )
}

ConnectionSelector.show = function(options = {}, name = DIALOG_NAME) {
    app.trigger(`app/${ name }/options`, options);
    store.dispatch(ui.show(name));
    return new Promise(resolve => {
        selectorResolve = resolve;
    });
}

ConnectionSelector.hide = function(name = DIALOG_NAME) {
    store.dispatch(ui.hide(name));
    selectorResolve = null;
}

export default ConnectionSelector