import { createContext, useContext } from "react"
import Context from "common/src/refactor/lib/Context"
import { default as validateValue } from "common/src/refactor/lib/form/validate"
import useKeys from "common/src/refactor/hooks/useKeys"

export const FormContext = createContext();


export class Form extends Context {

    fields = null

    constructor(fields = [], defaults = {}) {
        const data = {};

        fields = fields.map(f => {
            if (typeof f === "string") {
                return {
                    name: f
                }
            }
            return f;
        })

        fields.forEach(f => {
            const defaultsValue = defaults[f.name];
            const defaultValue = typeof f.default === "function" ? f.default() : f.default;
            data[f.name] = defaultsValue === undefined ? defaultValue : defaultsValue;
            data[f.name + "Error"] = null;
        });

        data["errorMode"] = false;

        super(data);
        this.fields = fields;
    }

    getField(name) {
        return this.fields.find(f => f.name === name);
    }

    async validate(name, dryRun = false) {
        const field = typeof name === "string" ? this.getField(name) : name;
        if (!field) {
            return false;
        }
        let valid = true;
        if (field.validator) {
            const res = await validateValue(
                field.name, 
                this.data[field.name],
                field.validator,
                { ...this.data }
            );
            if (!dryRun) {
                this.set(field.name + "Error", res);
            }

            if (res !== null) {
                valid = false;
            }
        }
        return valid;
    }

    async validateAll(dryRun = false) {
        let valid = true;
        for (const f of this.fields) {
            const res = await this.validate(f, dryRun);
            if (res !== true) {
                valid = false;
            }
        }

        if (!valid && !dryRun) {
            this.set("errorMode", true);
        }

        return valid;
    }

    resetAll(resetData = {}) {
        this.set("errorMode", false);
        this.fields.forEach(f => {
            this.set(f.name, f.default || resetData[f.name] || "");
            this.set(f.name + "Error", null);
        });
    }

    async set(name, value) {
        if (value && typeof value === "object" && value.target) {
            if (value.target.type === "checkbox") {
                value = value.target.checked;
            }
            else value = value.target.value;
        }
        const field = this.getField(name);

        super.set(name, value);

        if (field && this.data["errorMode"]) {
            if (field.validator) {
                const res = await validateValue(
                    field.name,
                    value,
                    field.validator,
                    { ...this.data }
                );
                this.set(name + "Error", res);
            }
        }
    }
}

export function useFormFields(fields = [], ...rest) {

    if (typeof fields === "string") {
        fields = [ fields, ...rest ];
    }

    const form = useContext(FormContext) || (rest[0] instanceof Form ? rest[0] : null);
    const keys = [ ...fields, ...fields.map(name => name + "Error") ];
    const values = useKeys(keys, form);

    for (let name of fields) {
        values[name + "Change"] = ((name) => (value) => form.set(name, value))(name);
        values[name + "SetError"] = ((name) => (error) => form.set(name + "Error", error))(name);
    }

    return values;
}

export function useForm() {
    return useContext(FormContext);
}