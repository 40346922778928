
import settings from "common/src/settings"

let commonPrefix = '';

const paramStore = {};

export const setStoreParam = (name, value) => {
    paramStore[name] = value;
}

export const getStoreParam = (name) => {
    return paramStore[name] || null;
}

export const setPrefix = (p) => {
    commonPrefix = p;
}

const getUrl = function(path, params = {}, domain, hash, addPath, prefixPath) {

    let used = {};
    const remove = [];

    path = path.replace(/:[a-z0-9-?]+\(?/ig, function(match) {

        let withRegex = false;

        if (match[match.length - 1] === '(') {
            withRegex = true;
            match = match.substring(0, match.length - 1);
            let inx = path.indexOf(match) + match.length;
            let end = -1;
            let i, l = path.length;
            for (i = inx; i < l; i++) {
                if (path[i] === ')' && path[i - 1] !== '\\') {
                    end = i + 1;
                    break;
                }
            }
            remove.push(path.substring(inx, end))
        }

        let param = match.substring(1);

        const optional = param[param.length - 1] === "?";
        optional && (param = param.substring(0, param.length - 1));
        
        let value = params[param];

        if (value === undefined || value === null) {
            value = getStoreParam(param);
        }

        if (value === undefined || value === null) {
            if (optional) {
                return "";
            }
            else {
                throw new Error(`Missing url param ${param}`);
            }
        }
        else {
            used[param] = true;
        }

        return value + (withRegex ? '(' : '');
        //return ':' + param + (withRegex ? '(' : '');
    });

    remove.forEach(part => {
        path = path.replace(part, '');
    });

    //path = generatePath(path, params);

    /*path = path.replace(/:[a-z0-9-?]+/ig, function(param) {
        param = param.substring(1);

        const optional = param[param.length - 1] === "?";
        optional && (param = param.substring(0, param.length - 1));
        
        const value = params[param];
        used[param] = true;

        if (value === undefined || value === null) {
            if (optional) {
                return "";
            }
            else {
                throw new Error(`Missing url param ${param}`);
            }
        }

        return value;
    });*/

    path = path.replace("//", "/");
    path = path.replace(/\/$/, '');

    if (addPath) {
        path += addPath;
    }

    if (prefixPath) {
        path = prefixPath + path;
    }

    if (commonPrefix) {
        path = commonPrefix + path;
    }

    if (path === '') {
        path = '/';
    }

    for (let param in params) {
        if (!used[param] && params[param] !== null && params[param] !== undefined) {
            if (path.indexOf("?") === -1) {
                path += "?";
            }
            path += "&" + param + "=" + 
                            encodeURIComponent(params[param].toString());
        }
    }

    if (hash) {
        path += '#' + hash;
    }

    if (domain) {
        if (settings.domains[process.env.REACT_APP_ENV][domain]) {
            return settings.domains[process.env.REACT_APP_ENV][domain] + path;
        }
        if (domain.substring(0, 4) === "http") {
            return domain + path;
        }
        else {
            return "https://" + domain + path;
        }
    }
    else {
        return path;
    }
}

//window.tfGetUrl = getUrl

export default getUrl