import api from "app/api"
import user from "common/src/user"
import hub from "common/src/hub"
import uuid from "uuid"

import upload from "common/src/lib/image/upload"
import finalizeUpload from "common/src/lib/image/finalizeUpload";
import preload from "common/src/lib/image/preload"


export function normalizeContact(c) {

    if (c.__normalized) {
        return c;
    }

    const contact = { type: c.key, __normalized: true };

    if (c.id) {
        contact.id = c.id;
    }

    switch (contact.type) {
        case "phone":
        case "whatsapp": {

            if (!c.number) {
                return null;
            }

            contact.value = c.code + ' ' + c.number;
            contact.normalized = contact.value.replace(/[^0-9+]/, '');
            break;
        }
        case "email":
        case "custom": {

            if (!c[ contact.type ]) {
                return null;
            }

            contact.value = c[ contact.type ];
            break;
        }
    }

    return contact;
}

export async function checkContacts(contacts, notForCustomerId) {

    let exists = false;
    let type = null;

    for (let i = 0, l = contacts.length; i < l; i++) {
        let c = contacts[ i ];
        let contact = normalizeContact(c);

        if (!contact) {
            continue;
        }
        
        if (contact.type === "whatsapp") {
            exists = await checkContactExists(contact, null, notForCustomerId);
            if (exists) {
                type = contact.type;
                break;
            }
        }
    }

    return type || null;
}

export async function checkContactExists(c, forCustomerId = null, notForCustomerId = null) {

    const res = await api.backend.post("/me/check-contact-exists", { body: {
        contact: c,
        forCustomerId,
        notForCustomerId
    }});

    return res.found || false;
}


export async function createContact(c, customerId) {
    
    const ex = await checkContactExists(c, customerId);

    if (ex) {
        return ex;
    }
    else {
        c.userId = customerId;
        const contact = { ...c };
        delete contact.__normalized;
        const newc = await api.userContact.create(contact);
        return newc.id;
    }
}


async function createContactConnection(consId, contactId) {
    const where = {
        consultationId: { _eq: consId },
        contactId: { _eq: contactId }
    }
    const check = await api.consultationContact.list({ where }, "id");

    if (check.length === 0) {
        await api.consultationContact.create({
            consultationId: consId,
            contactId: contactId
        });
    }
}


export async function saveContacts(consId, customerId, contacts) {

    contacts = contacts.map(normalizeContact);
    const tmpContacts = [];

    for (let i = 0, l = contacts.length; i < l; i++) {
        let c = contacts[i];
        let contact = normalizeContact(c);

        if (c.id) {
            delete contact.__normalized;
            await api.userContact.update(c.id, contact);
            await createContactConnection(consId, c.id);
        }
        else {

            if (customerId) {
                let cid = await createContact(contact, customerId);
                await api.consultationContact.create({
                    consultationId: consId,
                    contactId: cid
                });
            }
            else {
                tmpContacts.push(contact);
            }
        }
    }

    if (!customerId) {
        await api.consultation.update(consId, {
            contacts: JSON.stringify(tmpContacts)
        });
    }
}


export async function createSize(s, customerId) {

    if (!s.setBy) {
        s.setBy = user.id() === customerId ? "client" : "shopper";
    }

    const where = {
        userId: { _eq: customerId },
        type: { _eq: s.type },
        system: { _eq: s.system },
        value: { _eq: s.value }
    };
    const order = { createdAt: "desc" };
    const found = await api.userSize.list({ where, order }).then(l => l[0]);

    if (found) {
        if (found.comment !== s.comment) {
            await api.userSize.update(found.id, size);
        }
        return found.id;
    }
    else {
        s.userId = customerId;
        const news = await api.userSize.create(s);
        return news.id;
    }
}

async function createSizeConnection(consId, sizeId) {
    const where = {
        consultationId: { _eq: consId },
        sizeId: { _eq: sizeId }
    }
    const check = await api.consultationSize.list({ where }, "id");

    if (check.length === 0) {
        await api.consultationSize.create({
            consultationId: consId,
            sizeId: sizeId
        });
    }
}


export async function saveSizes(consId, customerId, sizes) {

    const tmpSizes = [];
    const setBy = customerId === user.id() ? "client" : "shopper";

    for (let i = 0, l = sizes.length; i < l; i++) {
        let s = sizes[i];

        if (!s || (!s.size && !s.value)) {
            continue;
        }

        let size = { 
            type: s.key || s.type,
            system: s.sizeSystem || s.system,
            value: s.size || s.value,
            comment: s.comment,
            setBy
        };
        if (s.id) {
            await api.userSize.update(s.id, size);
            consId && await createSizeConnection(consId, s.id);
        }
        else {
            if (customerId) {
                let sid = await createSize(size, customerId);
                if (consId) {
                    await api.consultationSize.create({
                        consultationId: consId,
                        sizeId: sid
                    });
                }
            }
            else {
                tmpSizes.push(size);
            }
        }
    }

    if (!customerId && consId) {
        await api.consultation.update(consId, {
            sizes: JSON.stringify(tmpSizes)
        });
    }
}

function uniqueByKey(list) {
    return list.filter((i1, inx, self) => self.findIndex(i2 => i1.key === i2.key) === inx);
}

export function restoreContact(c) {
    const contact = { key: c.type, id: c.id };
    switch (c.type) {
        case "phone":
        case "whatsapp": {
            let parts = c.value.split(' ', 2);
            contact.code = parts[0];
            contact.number = parts[1];
            break;
        }
        case "email": {
            contact.email = c.value;
            break;
        }
        case "custom": {
            contact.custom = c.value;
            break;
        }
    }
    return contact;
}

export async function chatExistsBetween(userId1, userId2) {

    const where = {
        participants: {
            userId: { _in: [ userId1, userId2 ] }
        }
    }
    const graph = `
        id
        participants {
            userId
            whatsappPoolNumber
        }
    `;

    // TODO: check that pool number already assigned
    const chatIds = await api.chat.list({ where }, graph)
                            .then(list => list.filter(c => {
                                const ps = c.participants.map(p => p.userId);
                                return ps.indexOf(userId1) !== -1 &&
                                        ps.indexOf(userId2) !== -1;
                            }))
                            .then(list => list.map(c => c.id));

    return chatIds.length > 0;
}

export async function getWhatsappPoolAvailability(userId) {

    const res = userId ?
                    await api.backend.post("/has-available-pool-numbers", { body: { userId }}) :
                    await api.backend.post("/me/has-available-pool-numbers");

    return res;
}

export async function loadPreviousContacts(customerId) {

    const where = { userId: { _eq: customerId }};
    const order = { createdAt: "desc" };
    const contacts = await api.userContact.list({ where, order });
    return contacts;
    //const contacts = savedContacts.map(restoreContact);
    /*if (!contacts.find(c => c.key === "email")) {
        contacts.push({
            tmpid: uuid.v4(),
            key: "email",
            email: user.current().email
        });
    }
    if (!contacts.find(c => c.key === "phone")) {
        const phone = user.current().phone;
        if (phone) {
            const res = await api.backend.post("/phone/parse", { body: { phone }});
            if (res.success && res.number) {
                contacts.push({
                    tmpid: uuid.v4(),
                    key: "phone",
                    code: res.code,
                    number: res.number
                });
            }
        }
    }*/
    
    //return uniqueByKey(contacts);
}


export function restoreSize(s) {
    return { 
        id: s.id,
        key: s.type,
        sizeSystem: s.system,
        size: s.value,
        comment: s.comment
    };
}

export async function loadPreviousSizes(customerId) {

    const where = { userId: { _eq: customerId }};
    const order = { createdAt: "desc" };
    const savedSizes = await api.userSize.list({ where, order });
    //const sizes = savedSizes.map(restoreSize);

    //return uniqueByKey(sizes);
    return savedSizes;
}


export async function setReaction(consultationId, productId, reaction, userId) {

    userId = userId || user.id();

    const prev = await api.consultationCustomerReaction.list({
        where: {
            consultationId,
            productId,
            userId
        }
    }).then(l => l[0]);

    if (prev) {
        if (reaction) {
            await api.consultationCustomerReaction.update(prev.id, {
                reaction,
                reactedAt: (new Date()).toISOString()
            });
            
        }
        else {
            await api.consultationCustomerReaction.remove(prev.id);
        }
    }
    else {
        await api.consultationCustomerReaction.create({
            consultationId,
            productId,
            userId,
            reaction
        });
    }

    hub.dispatch("product", "reaction-changed", { productId, reaction });

}



export async function saveImages(consId, images, imageFiles=[]) {
    
    const promises = [];

    for (let i = 0, l = images.length; i < l; i++) {
        const img = images[i];
        
        promises.push(new Promise(async (resolve) => {

            let imgResponse, uploadKey;

            if (img?.id) {
                api.consultationImage.update(img.id, {
                    comment: img.comment || "",
                    position: i
                });
            }
            else {
                if (img?.key) {
                    imgResponse = await api.backend.post("/image/copy", {
                        body: {
                            key: images[i].key
                        }
                    })
                }
                else {
                    uploadKey = await upload(imageFiles[i] || img);
                    imgResponse = await api.backend.post("/upload/consultation", {
                        body: {
                            consultationId: consId, 
                            uploadKey, 
                            contentType: img.mime,
                            name: img.name
                        }
                    });
                }
    
                await api.consultationImage.create({
                    consultationId: consId,
                    comment: img.comment || "",
                    position: i,
                    image: JSON.stringify({
                        mime: img.mime,
                        name: img.name,
                        width: img.width,
                        height: img.height,
                        key: imgResponse.key
                    })
                });
            }

            resolve();
        }));
    }

    await Promise.allSettled(promises);
}

export async function saveDraftImages(images, currentData) {
    const resp = [];
    for (let i = 0; i < images.length; i++) {
        if (currentData?.images?.some((img) => img.initialSrc?.includes(images[i].src) || img.initialSrc?.includes(images[i].initialSrc))) {
            const img = currentData.images.find(
                (el) => el.initialSrc === images[i].src || el.initialSrc === images[i].initialSrc
            ) || images[i];
            resp.push(img);
        } else {
            if (images[i].name && images[i].src) {
                let uploadKey = await upload(images[i]);
                const image = await finalizeUpload(uploadKey);
                resp.push({ ...image, localId: i, initialSrc: images[i].src });
            }
        }
    }

    return resp;
} 

export async function loadDraft() {
    const where = { userId: { _eq: user.id() }};

    const draft = await api.consultationDraft.list({ where });

    if (draft?.length) {
        if (draft[0]?.data.customerId) {
            const whereConn = { 
                users: { 
                    user: { 
                        id: { _eq: user.id() } 
                    }, 
                    connection: { 
                        users: { 
                            user: { 
                                id: { _eq: draft[0].data.customerId } 
                            } 
                        } 
                    } 
                } 
            };

            const connection = await api.connection.list({ where: whereConn });

            if (connection?.length) {
                const userInfo = await api.user.get(
                    draft[0].data.customerId, 
                    "sizes { comment id system type value }"
                );

                if (userInfo?.sizes) {
                    draft[0].data.sizes = userInfo.sizes.map(s => {
                        const { __typename, ...rest } = s;
                        return rest;
                    });
                }
            }
            else {
                draft[0].data.customerId = null;
            }
        }

        return draft[0];
    }
    return null;
}

export async function saveDraft(data) {

    const where = { userId: { _eq: user.id() }};
    const existingDraft = await api.consultationDraft.list({ where });

    if (data?.images) {
        const images = await saveDraftImages(data?.images || [], existingDraft[0]?.data);
        data.images = images;
    }
    else {
        if (existingDraft[0]) {
            data.images = existingDraft[0].data.images;
        }
        else {
            data.images = [];
        }
    }

    const dataToInsert = {
        data: JSON.stringify(data),
        userId: user.id()
    }

    if (existingDraft?.length > 0) {
        await api.consultationDraft.update(existingDraft[0].id, { data: dataToInsert.data });
    }
    else {
        await api.consultationDraft.create(dataToInsert);
    }
}

export async function resetDraft() {
    const where = { userId: { _eq: user.id() }};

    const draft = await api.consultationDraft.list({ where });

    if (draft?.length)
        await api.consultationDraft.remove(draft[0].id)
}