import { useMemo } from "react"
import throttle from "lodash/throttle"

function useThrottleCallback(cb, deps, timeout, options) {
    const thcb = useMemo(
        () => throttle(cb, timeout, options),
        deps
    );
    return thcb;
}

export default useThrottleCallback