
import authTypes from "./authTypes"
import user from "common/src/user"

export default authMode => {
    if (!authMode) {
        if (user.loggedIn()) {
            return Promise.resolve(authTypes.USER);
        }
        return user.authPromise()
                .then(loggedIn => loggedIn ? authTypes.USER : authTypes.KEY)
    }
    if (authMode === authTypes.KEY) {
        return Promise.resolve(authTypes.KEY);
    }
    return user.loggedIn() ? 
        Promise.resolve(authTypes.USER) : 
        user.authPromise()
            .then(loggedIn => loggedIn ? authTypes.USER : Promise.reject("Not authenticated"))
}