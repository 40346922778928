import { useCallback } from "react"
import useStyle from "common/src/refactor/hooks/useStyle"

import Loader from "common/src/components/Loader"
import { ReactComponent as IconTick } from "common/src/svg/tick.svg"

const baseClass = "input-checkbox";
const stylePostfixes = [ "tick-container", "tick", "label" ];

const loaderSizes = {
    "medium": 20,
    "small": 12
}

function Checkbox({ checked = null, loading = false, disabled = false,
                    className, style,
                    size = "small", variant, label,
                    onChange, ...rest }) {

    const styles = useStyle(
        {
            className: [ baseClass, className ],
            children: stylePostfixes,
            variants: [
                variant,
                size,
                checked === null ? 
                    "uncertain" :
                    checked === true ?
                        "checked" : 
                        "unchecked",
                disabled ? "disabled" : null,
                loading ? "loading" : null,
                !!label ? "with-label" : "no-label"
            ],
            extend: {
                [baseClass]: style
            }
        }, 
        [ checked, loading, disabled, size, variant, className, style, label ]
    );

    const onClick = useCallback(
        () => onChange && onChange(!checked),
        [ onChange, checked ]
    );

    return (
        <button 
            { ...styles.main } 
            onClick={ onClick } 
            disabled={ disabled }
            { ...rest }>
            <span { ...styles.tickContainer }>
                { loading ? 
                    <Loader size={ loaderSizes[size] }/> :
                    <IconTick { ...styles.tick }/> }
            </span>
            { label && 
                <span { ...styles.label }>{ label }</span> }
        </button>
    )
}

export default Checkbox