import { useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Input, Drawer } from "antd"

import NullForm from "common/src/components/NullForm";
import { ReactComponent as IconSearch } from "common/src/svg/search.svg"

import store from "app/store"
import { ui } from "common/src/store/dialogs"
import useInputHander from "common/src/refactor/hooks/userInputHandler";
import connectionSelector from "common/src/selectors/connections"
//import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import swallowEvent from "common/src/lib/dom/swallowEvent";
import useOnAppEvent from "common/src/refactor/hooks/useOnAppEvent"
import app from "app/appContext"
import Button from "../button/Button";
import useDictRef from "common/src/hooks/useDictRef";
import ListItem from "./ConnectionItem";
import Checkbox from "../form/Checkbox";
import useConnections from "common/src/refactor/hooks/useConnections";


const DIALOG_NAME = "assign-client";

let assignClientResolve;

function AssignClientPage({ name }) {
    
    const connections = useConnections();
    const [ query, setQuery ] = useState("");
    const handleQueryChange = useInputHander(setQuery);
    const [ selection, setSelection ] = useState(null);

    const ref = useDictRef({ selection });

    const list = useMemo(
        () => {
            let list = connections
                .map(c => ({
                    ...c,
                    isSelected: selection?.id === c.id
                }))

            if (!query) {
                return list;
            }

            const lc = query.toLowerCase();
            return list.filter(c => {
                return c.givenName.toLowerCase().indexOf(lc) !== -1 ||
                        c.familyName.toLowerCase().indexOf(lc) !== -1 ||
                        c.email.toLowerCase().indexOf(lc) !== -1;
            })
        },
        [ query, connections, selection ]
    );

    const onConnectionPress = useCallback(
        (conn) => {
            setSelection(conn);
        },
        []
    );

    const onSelectConnection = useCallback(
        (e) => {
            swallowEvent(e);
            assignClientResolve && assignClientResolve({ customer: ref.selection });
            AssignClient.hide(name);
        },
        [ connections, name ]
    );

    return (
        <div className="connection-selector">    
                <p className="connection-selector-header-text">This session will be hidden as default until you're ready to share it</p>        
                <NullForm>
                    <p className="connection-selector-form-text">Choose from your client list:</p>
                    <Input 
                        prefix={ <IconSearch/> }
                        size="large"
                        placeholder="Search connections"
                        allowClear
                        onChange={ handleQueryChange }
                        value={ query }/>
                </NullForm>
            <div className="connection-selector-list">
                { list.map(c => (
                    <ListItem 
                        key={ c.id } 
                        c={ c } 
                        onConnectionPress={ onConnectionPress }/>
                ))}
            </div>
            <p className="connection-selector-text">Once you click 'assign' you won't be able to remove or add further clients from seeing this styling session</p>
            <Button 
                text="Assign"
                disabled={ !selection }
                onClick={ onSelectConnection }
            />
        </div>
    )
}

function AssignClient({ name = DIALOG_NAME }) {

    const [ options, setOptions ] = useState({});
    const open = useSelector(s => s.dialogs[name]);
    const onCancel = useCallback(
        () => {
            assignClientResolve && assignClientResolve(null);
            AssignClient.hide(name);
        },
        [ name ]
    );

    useOnAppEvent(`app/${ name }/options`, setOptions);

    return (
        <Drawer 
            open={ open } 
            className="tf-drawer" 
            title={ options.title || "Assign to a client" }
            destroyOnClose 
            onClose={ onCancel }>
            { open && <AssignClientPage name={ name } { ...options }/> }
        </Drawer>
    )
}

AssignClient.show = function(options = {}, name = DIALOG_NAME) {
    app.trigger(`app/${ name }/options`, options);
    store.dispatch(ui.show(name));
    return new Promise(resolve => {
        assignClientResolve = resolve;
    });
}

AssignClient.hide = function(name = DIALOG_NAME) {
    store.dispatch(ui.hide(name));
    assignClientResolve = null;
}

export default AssignClient;
