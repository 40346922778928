
import getDuration from "./getDuration"
import raf from "./raf"
import rafPromise from "./rafPromise"


let callTimeout = function(startTime, duration) {
    return new Promise(resolve => {
        let tick = () => {
            if ((new Date).getTime() - startTime >= duration) {
                return resolve();
            }
            raf(tick);
        };
        raf(tick);
    });
}


export default function(el, cls, cfg = {}) {
    let anim = rafPromise()
        .then(() => {
            if (!anim.stopped) {
                cfg.start && cfg.start(anim, el, cls, cfg);
                el.classList.add(cls);
                return rafPromise();
            }
        })
        .then(() => {
            if (!anim.stopped) {
                cfg.middle && cfg.middle(anim, el, cls, cfg);
                el.classList.add(cls + "-active");
                let duration = getDuration(el);
                if (duration) {
                    return callTimeout((new Date).getTime(), duration);
                }
                return rafPromise();
            }
        })
        .then(() => {
            if (!anim.stopped) {
                cfg.end && cfg.end(anim, el, cls, cfg);
            }
            el.classList.remove(cls);
            el.classList.remove(cls + "-active");
        });

    anim.stopped = false;
    anim.stop = function() {
        this.stopped = true;
        return anim;
    }

    return anim;
}

