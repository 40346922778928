import { useEffect, useState, useCallback } from "react"
import { useSelector } from "react-redux"
import { Tooltip } from "antd"
import moment from "moment"

import Button from "../button/Button"
import Loader from "common/src/components/Loader"
import { ReactComponent as IconClicks } from "common/src/svg/click.svg"
import { ReactComponent as IconCommApproved } from "common/src/svg/commission-approved.svg"
import { ReactComponent as IconCommPaid } from "common/src/svg/commission-paid.svg"
import { ReactComponent as IconConnections } from "common/src/svg/connections.svg"
import { ReactComponent as IconInfo } from "common/src/svg/info-reversed.svg"
import { ReactComponent as IconPackage} from "common/src/svg/package.svg"
import { ReactComponent as IconTime } from "common/src/svg/time.svg"

import formatCurrency from "common/src/lib/format/currency"
import api from "app/api"
import useDictRef from "common/src/hooks/useDictRef"
import {
    grossEarningsInfoText,
    approvedInfoText,
    comissionInfoText  
} from "common/src/lib/profileTooltips"


const period2label = {
    "last7": "7 days",
    "last30": "30 days",
    "lastMonth": "Last month",
    "week": "This week",
    "month": "This month",
    "total": "Total"
}

const period2dates = (period) => {
    switch (period) {
        case "last7": {
            return {
                start: moment().subtract(7, "days").toDate(),
                end: moment().toDate()
            }
        }
        case "last30": {
            return {
                start: moment().subtract(30, "days").toDate(),
                end: moment().toDate()
            }
        }
        case "lastMonth": {
            return {
                start: moment().subtract(1,'months').startOf('month').toDate(),
                end: moment().subtract(1,'months').endOf('month').toDate()
            }
        }
        case "week": {
            return {
                start: moment().startOf('week'),
                end: moment().endOf('week')
            }
        }
        case "month": {
            return {
                start: moment().startOf("month").toDate(),
                end: moment().endOf("month").toDate()
            }
        }
        default: {
            return null;
        }
    }
}

const userFormattedDate = (date, format) => {
    return moment(date).format(format).toString()
}

const userDateRange = (start, end, format="MMM Do YYYY") => {
    return `${userFormattedDate(start, format)} - ${userFormattedDate(end, format)}`
}

function ProfileAnalytics() {

    const currency = useSelector(s => s.user.geo?.currency || "GBP");
    const userJoinedAt = useSelector(s => s.user.current.createdAt);

    const [ loading, setLoading ] = useState(false);
    const [ loadingAllTime, setLoadingAllTime ] = useState(false);

    const connections = useSelector(s => s.connections.data.connections.length);
    const [ period, setPeriod ] = useState("week");

    const [ earningsPeriod, setEarningsPeriod ] = useState(
        userDateRange(
            moment().startOf('week'),
            moment().endOf('week')
        ));

    const [ allTimePeriod, setAllTimePeriod ] = useState(userDateRange(userJoinedAt, new Date()));
    
    const [ commission, setCommission ] = useState(() => formatCurrency(0, currency || "usd", 2));
    const [ grossCommission, setGrossCommission ] = useState(() => formatCurrency(0, currency || "usd", 2));
    const [ pendingCommission, setPendingCommission ] = useState(() => formatCurrency(0, currency || "usd", 2));
    const [ rejectedCommission, setRejectedCommission] = useState(() => formatCurrency(0, currency || "usd", 2));

    const [ approvedCommission, setApprovedCommission ] = 
        useState(() => formatCurrency(0, currency || "usd", 2));
    const [ paidCommission, setPaidCommission ] = 
        useState(() => formatCurrency(0, currency || "usd", 2));
    const [ clicks, setClicks ] = useState(0);

    const ref = useDictRef({ currency, period });

    const loadStats = useCallback(
        async () => {

            const currency = ref.currency.toLowerCase();
            const dates = period2dates(ref.period);
            const clicksRes = await api.reporting.post("/report/my/clicks/byreceiver", {
                body: {
                    ...dates
                }
            });
            const clicks = clicksRes.rows[0]?.cnt || 0;
            
            const ordersRes = await api.reporting.post("/report/my/orders/byreceiver", {
                body: {
                    targetCurrency: currency,
                    ...dates
                }
            });
            const orders = ordersRes.rows[0] || {};

            setClicks(clicks);
            setCommission(
                formatCurrency(
                    orders.pseCommissionAmount,
                    orders.currency || currency, 
                    2
                )
            );
            setGrossCommission(
                formatCurrency(
                    orders.pseGrossCommissionAmount,
                    orders.currency || currency, 
                    2
                )
            );
            
            //setOrders(orders.cnt || 0);
            //setTotal(formatCurrency(orders.saleAmount || 0, orders.currency || currency, 2));
            //setSold(orders.itemsCount || 0);
        },
        []
    );

    const loadAllTimeStats = useCallback(
        async () => {

            const currency = ref.currency.toLowerCase();
            const allTimeDates = period2dates('total');

            const ordersRes = await api.reporting.post("/report/my/orders/byreceiver", {
                body: {
                    targetCurrency: currency,
                    ...allTimeDates
                }
            });
            const orders = ordersRes.rows[0] || {};

            const approvedRes = await api.reporting.post("/report/my/orders/byreceiver", {
                body: {
                    approved: true,
                    targetCurrency: currency,
                    ...allTimeDates
                }
            });
            const approved = approvedRes.rows[0] || {};

            const paidRes = await api.reporting.post("/report/my/orders/byreceiver", {
                body: {
                    approved: true,
                    paid: true,
                    withPaid: true,
                    targetCurrency: currency,
                    ...allTimeDates
                }
            });
            const paid = paidRes.rows[0] || {};
            
            // pending = net commission - approved
            const pending = orders.pseCommissionAmount - approved.pseCommissionAmount

            // rejected commission = gross commission - (approved + pending)
            const rejected = orders.pseGrossCommissionAmount - pending - approved.pseCommissionAmount


            setPendingCommission(
                formatCurrency(
                    pending,
                    orders.currency || currency, 
                    2
                )
            );
            setRejectedCommission(
                formatCurrency(
                    rejected,
                    orders.currency || currency, 
                    2
                )
            );
            setApprovedCommission(
                formatCurrency(
                    approved.pseCommissionAmount, 
                    approved.currency || currency, 
                    2
                )
            );
            setPaidCommission(
                formatCurrency(
                    paid.paidAmount || 0, 
                    approved.currency || currency, 
                    2
                )
            );
        },
        []
    );

    const loadAll = useCallback(
        async () => {
            setLoading(true);
            await loadStats();
            setLoading(false);
        },
        // eslint-disable-next-line
        []
    );

    const loadAllTime = useCallback(
        async () => {
            setLoadingAllTime(true);
            await loadAllTimeStats();
            setLoadingAllTime(false);
        },
        // eslint-disable-next-line
        []
    );

    const onPeriodChange = useCallback(
        (period) => {
            setPeriod(period);
            const dates = period2dates(period)
            if (dates) {
                setEarningsPeriod(userDateRange(dates.start, dates.end))
            } else {
                setEarningsPeriod(userDateRange(userJoinedAt, new Date()))
            }
        },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => { loadAll() },
        // eslint-disable-next-line
        [ period, currency ]
    )

    useEffect(
        () => { loadAllTime() },
        []
    )

    return (
        <div className={ "user-profile-analytics " + (loading ? "loading" : "") }>
            <label className="user-profile-title">
                Earnings
                <Tooltip title={grossEarningsInfoText}>
                   <IconInfo className="user-profile-analytics-info-icon"/>
                </Tooltip>
                { loading ? <Loader inline size={ 16 }/> : null }
            </label>

            <div className="user-profile-earnings-stats">
            
            <div className="user-profile-analytics-periods-container">
                <div className="user-profile-analytics-periods">
                    <Button
                        disabled={ loading }
                        size="small"
                        className={ period === "week" ? "ant-btn-active" : "" }
                        onClick={ () => onPeriodChange("week") }>
                        { period2label["week"] }
                    </Button>
                    <Button 
                        disabled={ loading }    
                        size="small"
                        className={ period === "month" ? "ant-btn-active" : "" }
                        onClick={ () => onPeriodChange("month") }>
                        { period2label["month"] }
                    </Button>
                    <Button 
                        disabled={ loading }    
                        size="small"
                        className={ period === "lastMonth" ? "ant-btn-active" : "" }
                        onClick={ () => onPeriodChange("lastMonth") }>
                        { period2label["lastMonth"] }
                    </Button>
                    <Button
                        disabled={ loading }
                        size="small"
                        className={ period === "total" ? "ant-btn-active" : "" }
                        onClick={ () => onPeriodChange("total") }>
                        { period2label["total"] }
                    </Button>
                </div>
                
                <span className="user-profile-date-period-label">{ earningsPeriod }</span>
            </div>


            <div className="user-profile-analytics-main-stats">
                <div className="user-profile-analytics-main-stats-inner">
                    <label>Net Earnings</label>
                    <div className="user-profile-analytics-total">
                        <p>{ commission }</p>
                    </div>
                    <p>The net value after returns and refunds</p>
                </div>

                <div className="user-profile-analytics-main-stats-inner">
                    <label>Gross Earnings</label>
                    <div className="user-profile-analytics-total">
                        <p>{ grossCommission }</p>
                    </div>
                    <p>The total value before returns and refunds</p>
                </div>
            </div>

            <div className="user-profile-analytics-stats user-profile-analytics-stats-white">
                <IconClicks className="icon"/>
                <p className="user-profile-analytics-label">Clicks</p>
                <p>{ clicks }</p>
            </div>
            </div>

            <div className="user-profile-label-section">
                <label className="user-profile-label">
                    Order Status
                    <Tooltip title={approvedInfoText}>
                        <IconInfo className="user-profile-analytics-info-icon"/>
                    </Tooltip>
                </label>
                <span className="user-profile-date-period-label">{ allTimePeriod }</span>
            </div>

            <div className="user-profile-analytics-stats">
                <IconCommApproved/>
                <div>
                    <p className="user-profile-analytics-label">Approved </p>
                    <p>Approved by the affiliate</p>
                </div>
                <p>{ approvedCommission }</p>
            </div>
            <div className="user-profile-analytics-stats">
                <IconTime/>
                <div>
                    <p className="user-profile-analytics-label">Pending </p>
                    <p>Waiting to be approved by affiliate</p>
                </div>
                <p>{ pendingCommission }</p>
            </div>
            <div className="user-profile-analytics-stats">
                <IconPackage/>
                <div>
                    <p className="user-profile-analytics-label">Returned </p>
                    <p>Rejected by affiliate due to return or refund</p>
                </div>
                <p>{ rejectedCommission }</p>
            </div>
            
            <div className="user-profile-label-section">
                <label className="user-profile-label">
                    All Time Earnings
                    <Tooltip title={comissionInfoText}>
                        <IconInfo className="user-profile-analytics-info-icon"/>
                    </Tooltip>
                </label>
                <span className="user-profile-date-period-label">{ allTimePeriod }</span>
            </div>

            <div className="user-profile-analytics-stats">
                <IconCommPaid/>
                <div>
                    <p className="user-profile-analytics-label">Paid</p>
                    <p>Commission that has been paid to you by THE FLOORR</p>
                </div>
                <p>{ paidCommission }</p>
            </div>

            <label className="user-profile-label">
                Connections
            </label>
            <div className="user-profile-analytics-stats">
                <IconConnections/>
                <p className="user-profile-analytics-label">Clients</p>
                <p>{ connections }</p>
            </div>
        </div>
    )
}

export default ProfileAnalytics