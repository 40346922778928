import useDictRef from "common/src/hooks/useDictRef";
import { useMemo } from "react";
import { useCallback } from "react";
import { useState } from "react"


function useSelection({ 
        initialSelection = [],
        comparisonFn,
        mapFn,
        multiple = true
    } = {}) {

    const [ selection, setSelection ] = useState(initialSelection);
    const [ map, setMap ] = useState(() => {
        if (mapFn) {
            const map = {};
            initialSelection.forEach(item => {
                const key = mapFn(item);
                map[key] = true;
            })
            return map;
        }
    })
    
    const cmpFn = useMemo(
        () => comparisonFn || ((a, b) => a === b),
        [ comparisonFn ]
    );

    const ref = useDictRef({ selection, cmpFn, mapFn, map });

    const add = useCallback(
        (item) => {
            if (ref.selection.findIndex(i => ref.cmpFn(i, item)) === -1) {
                multiple ? 
                    setSelection([ ...ref.selection, item ]) :
                    setSelection([ item ]);
                if (ref.mapFn) {
                    const key = ref.mapFn(item);
                    multiple ? 
                        setMap(prev => ({ ...prev, [key]: true })) :
                        setMap({ [key]: true });
                }
            }
        },
        [ multiple ]
    );

    const remove = useCallback(
        (item) => {
            const inx = ref.selection.findIndex(i => ref.cmpFn(i, item));
            if (inx !== -1) {
                const sel = [ ...ref.selection ];
                sel.splice(inx, 1);
                setSelection(sel);
                if (ref.mapFn) {
                    const key = ref.mapFn(item);
                    setMap(prev => {
                        const next = { ...prev };
                        delete next[key];
                        return next;
                    });
                }
            }
        },
        []
    );

    const toggle = useCallback(
        (item) => {
            const inx = ref.selection.findIndex(i => ref.cmpFn(i, item));
            inx === -1 ? add(item) : remove(item);
        },
        [ add, remove ]
    );

    const isSelected = useCallback(
        (item) => {
            if (ref.mapFn) {
                const key = ref.mapFn(item);
                return ref.map[key] || false;
            }
            return ref.selection.findIndex(i => ref.cmpFn(i, item)) !== -1;
        },
        []
    );

    return {
        selection,
        setSelection,
        add, 
        remove,
        isSelected,
        toggle,
        map
    }
}

export default useSelection