import gql from "graphql-tag"
import client from "common/src/api/hasura/client"
import getUserContext from "common/src/api/getUserContext"
import { prepareGraph } from "common/src/api/hasura/add"
import generator from "common/src/api/hasura/generate"
import entities from "common/src/api/hasura/entities"
import normalizeCatalogueProduct from "common/src/api/normalize/catalogueProduct"
import currencyExchange from "common/src/lib/currencyExchange"
import user from "common/src/user"
import hub from "common/src/hub"
import api from "app/api"
import store from "app/store"
import preloadImages from "common/src/lib/image/preload"
import calculateCommissionRate from "common/src/lib/catalogue/calculateCommissionRate"
//import timing from "common/src/lib/timing"

function getCommissionRates() {
    const state = store.getState();
    return state.catalogue?.data?.commissionRates || null;
}

const productGraph = `
    ${entities.Catalogue_Product.list}
    ---productFields---
`;
const { query, queryWithCount } = generator.list("Catalogue_Product");



const productReactionGraph = `
    User_Reaction(where: { catalogueProductId: { _in: $catalogueProductIds },
                                            userId: { _eq: $reactionsUserId }}) {
        id
        userId
        catalogueProductId
        reaction
    }
`;

const productStockGraph = `
    Catalogue_Product_Stock(where: { catalogueProductId: { _in: $catalogueProductIds }, 
                                     region: { _in: $regions }}) {
        catalogueProductId
        region
        price
        salePrice
        currency
        sizes
        sizeSystem
        availability
        retailer
    }
`;

const dataQueryVariablesTpl = {
    catalogueProductIds: "$catalogueProductIds: [uuid!]!",
    reactionsUserId: "$reactionsUserId: uuid!",
    regions: "$regions: [String!]!"
};

//$lookIds: [uuid!]!, $productIds: [uuid!]!, $regions: [String!]!
const dataQueryTpl = `
    query getCatalogueProductData(---variables---) {
        ---productReactionGraph---
        ---productStockGraph---
    }
`;


const prepareQueryVariables = (query, templates) => {
    const variables = [];
    for (let name in templates) {
        if (query.indexOf('$' + name) !== -1) {
            variables.push(templates[name]);
        }
    }
    return query.replace('---variables---', variables.join(', '));
};

async function checkExisting(productWebIds) {
    const catalogueProductIds = [];
    const missingProductWebIds = [];
    const idsMap = {};
    productWebIds.forEach(pwid => {
        idsMap[pwid] = null;
    });

    const where = {
        productWebId: { _in: productWebIds }
    };
    const graph = "id productWebId";
    const list = await api.catalogueProduct.list({ where }, graph);
    list.forEach(row => {
        if (!idsMap[row.productWebId]) {
            catalogueProductIds.push(row.id);
            idsMap[row.productWebId] = row.id;
        }
    });
    productWebIds.forEach(pwid => {
        if (!idsMap[pwid]) {
            missingProductWebIds.push(pwid);
        }
    })

    return [catalogueProductIds, idsMap, missingProductWebIds];
};

export async function loadCSProducts(productWebIds) {
    const preferredCurrencies = ["USD", "GBP", "EUR"];
    const body = {
        currency: preferredCurrencies.join(","),
        product_web_id: productWebIds,
        // stage: "live"
    }
    const { products } = await api.unauth.post("/catalogue/search", { body });
    const map = {};

    products.forEach(p => {
        const pid = p.product_web_id;
        if (!map[pid]) {
            map[pid] = p;
        }
        /*else {
            if (map[pid].currency !== "GBP" && p.currency === "GBP") {
                map[pid] = p;
            }
        }*/
    });
    return Object.values(map);
}

export async function createProduct(csp) {

    const details = typeof csp.details === "string" ? JSON.parse(csp.details) : csp.details;
    let images = typeof csp.images === "string" ? csp.images.split(";") : csp.images;
    images = await preloadImages(images);
    const designerIds = csp.designer_id ?
        Array.isArray(csp.designer_id) ?
            csp.designer_id :
            JSON.parse(csp.designer_id) :
        [];

    const data = {
        catalogueId: csp.product_catalogue_id,
        productWebId: csp.product_web_id,
        catalogueSource: csp.catalogue_source,
        catalogueFile: csp.catalogue_file,
        name: csp.name,
        description: csp.description,
        price: csp.price,
        salePrice: csp.sale_price,
        designerIds: JSON.stringify(designerIds),
        currency: csp.currency.toLowerCase(),
        retailer: csp.retailer,
        brand: csp.brand,
        region: csp.region,
        url: details.click_url || csp.url || details.url,
        images: JSON.stringify(images),
        details: JSON.stringify(details)
    };

    const resp = await api.catalogueProduct.create(data);
    return resp.id;
}

export async function createProducts(productWebIds) {

    const [existing, _, missingIds] = await checkExisting(productWebIds);

    if (missingIds.length === 0) {
        return existing;
    }

    const createdMap = {};
    const csProducts = await loadCSProducts(missingIds);

    for (let i = 0, l = csProducts.length; i < l; i++) {
        const pid = csProducts[i].product_web_id;
        if (!createdMap[pid]) {
            const id = await createProduct(csProducts[i]);
            existing.push(id);
            createdMap[pid] = true;
        }
    }

    return existing;
}

const assignReaction = (itemsMap, reactions, idKey) => {
    reactions.forEach(r => {
        const id = r[idKey];
        if (!itemsMap[id]) {
            return;
        }
        itemsMap[id].forEach(item => {
            item.reaction = r.reaction;
        });
    })
};


function applyCommissionRates(product, commissionRates) {

    const rate = calculateCommissionRate(
        product.catalogueSource,
        product.retailer,
        product.region,
        commissionRates
    );

    /*if (!rate) {
        console.log("not found", product.catalogue_source, product.retailer, product.region);
    }
    else {
        console.log("found", product.catalogue_source, product.retailer, product.region);
    }*/

    product.commissionRate = rate;
    return product;
}



const assignStock = (products, stock, retailerCountries,
    currentCurrency, exchangeRates) => {
    const stockMap = {};
    stock.forEach(entry => {
        if (!stockMap[entry.productId]) {
            stockMap[entry.productId] = [];
        }
        stockMap[entry.productId].push(entry);
    });

    products.forEach(product => {
        product.stock = null;
        product.currencyConverted = false;
        const id = product.id;
        const countries = retailerCountries ? [].concat(retailerCountries[product.retailer]) : [];

        if (stockMap[id]) {
            const stock = stockMap[id];
            const country = countries ?
                countries.find(c => stock.find(s => s.region === c)) :
                null;
            product.stock = (country ? stock.find(s => s.region === country) : null) ||
                stock.find(s => s.region === "GB") ||
                stock.find(s => s.region === "UK");

            if (product.stock) {
                product.stock.currencyConverted = false;

                if (currentCurrency &&
                    product.stock.currency.toUpperCase() !== currentCurrency.toUpperCase()) {
                    product.stock.currencyConverted = true;
                    product.stock.price = currencyExchange(product.stock.price,
                        product.stock.currency,
                        exchangeRates);
                    product.stock.salePrice = currencyExchange(product.stock.salePrice,
                        product.stock.currency,
                        exchangeRates);
                    product.stock.currency = currentCurrency;
                }
            }
        }

        if (currentCurrency && !product.stock &&
            product.currency.toUpperCase() !== currentCurrency.toUpperCase()) {
            //console.log("converting price", product.price, product.currency, exchangeRates)
            product.price = currencyExchange(product.price,
                product.currency,
                exchangeRates);
            product.salePrice = currencyExchange(product.salePrice,
                product.currency,
                exchangeRates);
            product.currency = currentCurrency;
            product.currencyConverted = true;
            //console.log("converted to", product.price, product.currency)
        }
    });
};


async function loadDesigners(items) {

    const designerIdsMap = {};
    const designerIds = items.map(p => {
        if (!designerIdsMap[p.id]) {
            designerIdsMap[p.id] = [];
        }
        const dids = p.designerIds ? JSON.parse(p.designerIds) : [];
        dids.forEach(did => {
            designerIdsMap[p.id].push(did);
        })
        return dids;
    })
        .flat();

    if (designerIds.length === 0) {
        return;
    }

    const { designers } = await api.unauth.post("/catalogue/designers", {
        body: {
            ids: designerIds
        }
    })
    const designerMap = {};
    designers.forEach(d => {
        designerMap[d.id] = d;
    })

    items.forEach(p => {
        const ds = designerIdsMap[p.id].map(did => designerMap[did]).filter(d => !!d);
        if (ds.length === 0) {
            return;
        }
        p.designers = ds;
        p.commissionExclusion = false;
        if (!!ds.find(d => !!d.exclusions.find(e => e.retailer === p.retailer))) {
            p.commissionExclusion = true;
        }
    })
}

export const catalogueProductDataLoader = async (items, opt = {}) => {

    const productMap = {};
    const productIds = [];
    let productRetailers = {};

    if (user.loggedIn() === null) {
        await hub.promise("app-auth", "stateChange");
    }

    if (!user.loggedIn()) {
        opt.savedProducts = false;
    }
    else {
        if (user.current() === null) {
            await hub.promise("app-auth", "info-loaded");
        }
    }

    let brands, country = null, currency = "GBP",
        exchangeRates = {};

    await hub.promise("app-auth", "geo-loaded");
    const state = store.getState();
    brands = state.user.geo.brands;
    country = state.user.geo.country;
    currency = state.user.geo.currency;
    exchangeRates = state.user.geo.exchange;

    if (state.user.geo.original) {
        currency = null;
    }

    let retailerCountries = [];


    items.forEach(item => {
        // there might be duplicates
        if (!productMap[item.id]) {
            productMap[item.id] = [];
        }
        productMap[item.id].push(item);
        productIds.push(item.id);
        productRetailers[item.retailer] = true;
    });


    productRetailers = Object.keys(productRetailers);
    productRetailers.forEach(ret => {
        const cs = brands[ret] || [];
        retailerCountries = retailerCountries.concat(cs);
    });

    retailerCountries = retailerCountries.filter((i1, inx, self) =>
        self.findIndex(i2 => i1 === i2) === inx);

    let dataQuery = dataQueryTpl;
    dataQuery = dataQuery.replace('---productReactionGraph---',
        opt.productReactions ? productReactionGraph : "");
    dataQuery = dataQuery.replace('---productStockGraph---',
        opt.productStock ? productStockGraph : "");

    const emptyId = '00000000-0000-0000-0000-000000000000';
    dataQuery = dataQuery.replace(/#userId/g, user.id() || emptyId);

    const context = await getUserContext();
    const authRole = context.headers['X-Hasura-Role'];
    const variables = {
        catalogueProductIds: productIds,
        regions: retailerCountries,
        reactionsUserId: opt.reactionsUserId || user.id()
    };
    const dataResponse = await client.query({
        query: gql(prepareQueryVariables(prepareGraph(authRole, dataQuery), dataQueryVariablesTpl)),
        variables,
        context
    });

    if (opt.productReactions) {
        const { User_Reaction } = dataResponse.data;
        User_Reaction && assignReaction(productMap, User_Reaction, "catalogueProductId");
    }

    if (opt.productStock) {
        const { Catalogue_Product_Stock } = dataResponse.data;
        Catalogue_Product_Stock && assignStock(items, Catalogue_Product_Stock,
            brands, currency, exchangeRates);
    }

    return items;
}

async function getMoodboardProductIds(moodboardId, limit, offset) {
    const where = {
        moodboardId: { _eq: moodboardId }
    }
    const order = {
        position: "asc"
    }

    const ids = await api.moodboardCatalogueProduct.list({ where, order, limit, offset })
        .then(list => list.map(item => item.catalogueProductId));

    return ids;
}


export async function catalogueProductLoader(options = {}) {

    //timing.start("catalogue-products-loading");

    let { limit = 20, offset = 0 } = options;
    let productIds;
    const { order,
        withCount = false,
        withStock = true,
        withReactions = true,
        reactionsUserId = null,
        moodboardId,
        query: search,
        productFields = "" } = options;
    const where = options.where || {};
    const commissionRates = user.isOnly("User") ? null : getCommissionRates();

    if (moodboardId) {
        productIds = await getMoodboardProductIds(moodboardId, limit, offset);
        where.id = { _in: productIds }
        limit = null;
        offset = null;
        //where.moodboardCatalogueProducts = {
        //    moodboardId: { _eq: moodboardId }
        //}
    }

    if (search) {
        where._or = [
            { name: { _ilike: `%${search}%` } },
            { brand: { _ilike: `%${search}%` } }
        ]
    }
    //timing.since("catalogue-products-loading", "prepared");
    const productQuery = (withCount ? queryWithCount : query)
        .replace('---graph---', productGraph)
        .replace('---productFields---', productFields)
        .replace('---countGraph---', "count");
    const context = await getUserContext();
    //timing.since("catalogue-products-loading", "user context");
    const authRole = context.headers['X-Hasura-Role'];
    const productResponse = await client.query({
        query: gql(prepareGraph(authRole, productQuery)),
        variables: { where, order, limit, offset },
        context
    });
    //timing.since("catalogue-products-loading", "products loaded");

    //console.log(productResponse)

    let items = productResponse.data["Catalogue_Product"];
    const count = withCount ?
        productResponse.data['Catalogue_Product_aggregate']['aggregate']['count'] :
        0;

    items = moodboardId ?
        productIds.map(id => items.find(i => i.id === id)).filter(p => !!p) :
        items;

    if (commissionRates) {
        items = items.map(p => applyCommissionRates(p, commissionRates));
    }

    if (items.length && (withReactions || withStock)) {

        await catalogueProductDataLoader(items, {
            productStock: withStock,
            productReactions: withReactions,
            reactionsUserId: reactionsUserId
        });
        //timing.since("catalogue-products-loading", "products data loaded");
        await loadDesigners(items);
        //timing.since("catalogue-products-loading", "designers loaded");
        const products = items.map(normalizeCatalogueProduct);

        //timing.clear("catalogue-products-loading");

        return withCount ? { products, count } : products;
    }

    await loadDesigners(items);
    //timing.since("catalogue-products-loading", "designers loaded");
    //timing.clear("catalogue-products-loading");

    const products = items.map(normalizeCatalogueProduct);
    return withCount ? { products, count } : products;
}



export async function setReaction(catalogueProductId, reaction, userId, moodboardId = null) {

    let productWebId;

    if (typeof catalogueProductId !== "string") {
        const p = catalogueProductId;
        productWebId = p.product_web_id || p.productWebId;
        const pids = await createProducts([productWebId]);
        catalogueProductId = pids[0];
        if (!catalogueProductId) {
            return false;
        }
    }

    userId = userId || user.id();

    const prev = await api.userReaction.list({
        where: {
            catalogueProductId,
            userId
        }
    }).then(l => l[0]);

    if (prev) {
        if (reaction) {
            const payload = {
                reaction,
                createdAt: (new Date()).toISOString()
            };
            if (moodboardId && !prev.moodboardId) {
                payload.moodboardId = moodboardId;
            }
            await api.userReaction.update(prev.id, payload);
        }
        else {
            await api.userReaction.remove(prev.id);
        }
    }
    else if (reaction) {
        const payload = {
            catalogueProductId,
            userId,
            reaction
        };
        if (moodboardId) {
            payload.moodboardId = moodboardId;
        }
        await api.userReaction.create(payload);
    }

    hub.dispatch("catalogue-product", "reaction-changed", { catalogueProductId, reaction, productWebId });

}

