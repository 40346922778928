import hub from "common/src/hub"
import { ui } from "common/src/store/app"
import store from "app/store"

let alertOpen = false;
let openItems = 0;
let visible = false;

function onAlertPoolChange(poolSize) {
    alertOpen = poolSize > 0;
    checkState();
}

function onDialogHide() {
    openItems--;
    checkState();
}

function onDialogShow() {
    openItems++;
    checkState();
}

function checkState() {
    let show = openItems > 0 || alertOpen;
    if (visible !== show) {
        visible = show;
        hub.dispatch("dialog", show ? "overlayShow" : "overlayHide");
        store.dispatch(ui.overlay(show));
    }
}


export default {
    init: () => {
        hub.listen("dialog", "alertPoolChange", onAlertPoolChange);
        hub.listen("dialog", "sideShow", onDialogShow);
        hub.listen("dialog", "sideHide", onDialogHide);
        hub.listen("dialog", "show", onDialogShow);
        hub.listen("dialog", "hide", onDialogHide);
        //hub.listen("dialog", "menuShow", onDialogShow);
        //hub.listen("dialog", "menuHide", onDialogHide);
    }
}

