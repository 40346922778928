import getClickDomain from "common/src/lib/url/clickDomain"
import extractUrlFromClick from "common/src/lambdalib/extractUrlFromClick"
import user from "common/src/user"

function isEncoded(url) {
    return url !== decodeURIComponent(url);
}


function getProductUrl(p, referenceUserId = null, source = null) {
    const cpid = p.catalogueId || p.product_web_id;
    let refid = null;

    if (referenceUserId !== false) {
        refid = referenceUserId;
        if (!refid) {
            if (!user.isOnly("User")) {
                refid = user.id();
            }
        }
    }

    const uid = user.id();
    let url = p.details ?
                    p.details.url || p.details.product_url ||
                    extractUrlFromClick(p.details.click_url) :
                    p.url || this.props.url;
    const clickDomain = getClickDomain();

    let encoded = isEncoded(url) ? 1 : 0;

    if (url.indexOf('{') !== -1 || url.indexOf('[') !== -1) {
        url = encodeURIComponent(url);
        encoded++;
    }

    let click = `https://${ clickDomain }?`;
    if (refid) {
        click += `refid=${ refid }&`;
    }
    if (source && source.type && source.id) {
        click += `srctype=${ source.type }&srcid=${ source.id }&`;
    }
    click += `cpid=${ cpid }&uid=${ uid }&encoded=${ encoded }&url=${ url }`;
    return click;
}


export default getProductUrl