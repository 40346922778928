
let boxSizingReliableVal;

function computePixelPositionAndBoxSizingReliable() {

    var doc = window.document,
        container = doc.createElement("div"),
        div = doc.createElement("div"),
        body = doc.body;

    if (!div.style || !window.getComputedStyle) {
        return false;
    }

    container.style.cssText = "border:0;width:0;height:0;top:0;left:-9999px;margin-top:1px;" +
                              "position:absolute";
    container.appendChild(div);

    div.style.cssText =
        // Support: Firefox<29, Android 2.3
        // Vendor-prefix box-sizing
    "-webkit-box-sizing:border-box;-moz-box-sizing:border-box;" +
    "box-sizing:border-box;display:block;margin-top:1%;top:1%;" +
    "border:1px;padding:1px;width:4px;position:absolute";
    div.innerHTML = "";
    body.appendChild(container);

    var divStyle = window.getComputedStyle(div, null),
        ret = divStyle.width === "4px";

    body.removeChild(container);

    return ret;
};

export default function boxSizingReliable() {
    if (boxSizingReliableVal === undefined) {
        boxSizingReliableVal = computePixelPositionAndBoxSizingReliable();
    }

    return boxSizingReliableVal;
};
