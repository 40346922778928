import { Modal, Spin, Input } from "antd"
import { useCallback, useState, useMemo, useEffect } from "react"
import { useSelector } from "react-redux"

import Button from "../button/Button"
import { ReactComponent as IconSearch } from "common/src/svg/search.svg"
import { ReactComponent as IconClose } from "common/src/svg/close.svg"
import { ReactComponent as IconTag } from "common/src/svg/tag.svg"
import { ReactComponent as IconPlus } from "common/src/svg/plus.svg"
import { ReactComponent as IconCamera } from "common/src/svg/camera_add.svg"

import useDualState from "common/src/refactor/hooks/useDualState"
import useInputHander from "common/src/refactor/hooks/userInputHandler"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import store from "app/store"
import { ui } from "common/src/store/dialogs"
import app from "app/appContext"
import useOnAppEvent from "../../hooks/useOnAppEvent"
import Radio from "common/src/refactor/components/form/Radio";
import useSelection from "common/src/refactor/hooks/useSelection"
import user from "common/src/user"
import useUpdateEffect from "common/src/hooks/useUpdateEffect"
import { lookLoader } from "common/src/actions/looks"
import LookCard from "./Card"
import useDateFormat from "common/src/refactor/hooks/useDateFormat"
import EmptyMessage from "common/src/refactor/components/EmptyMessage"

const selectionPromise = {};

const DIALOG_NAME = "looks-dialog";

const params = [
    {
        name: "query",
        default: ""
    }
];

function LookSelect({ id, selected, onChange }) {
    const onClick = useSwallowEventCallback(
        () => onChange(id, !selected),
        [ selected, id, onChange ]
    )
    
    return (
        <a href="/#" 
        className="chat-moodboard-card-select"
        onClick={ onClick }>
            <Radio checked={ selected } size="medium"/>
        </a>
    )
}

function LookCardFooter({ look }) {
    const imagesCount = 1;
    const productsCount = useMemo(
        () => look.products.length || 0,
        [ look ]
    );

    const date = useDateFormat(look.createdAt)

    return (
        <div className="look-footer">
            <div className="look-footer-counts">
                { look.productMode === "tagged" &&
                    <span className="look-footer-count">
                        <IconCamera/>
                        { imagesCount } { imagesCount === 1 ? "Image" : "Images" }
                    </span>
                }
                <span className="look-footer-count">
                    <IconTag/>
                    { productsCount } { productsCount === 1 ? "Product" : "Products" }
                </span>
            </div>

            <p className="look-footer-title">{ look.title }</p>
        </div>
    )
}

function Looks({ dialogName = DIALOG_NAME, contactId = null }) {
    const { query, setQuery } = useDualState({
        params,
        mode: "state"
    });
    const [ looks, setLooks ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ search, setSearch ] = useState("")

    const { selection, map, add, remove } = useSelection({
        mapFn: m => m.id,
        comparisonFn: (a, b) => a.id === b.id
    });
    const selectionCnt = useMemo(() => Object.keys(selection).length, [ selection ]);

    const items = useMemo(() => {
        if (!looks || !looks.length) {
            return [];
        }
        
        const filtered = looks.filter(l => {
            let isMatch = true;

            if (search && !(l.title?.toLowerCase().includes(search?.toLowerCase()))) {
                isMatch = false;
            }

            return isMatch;
        });

        return filtered;
    }, 
    [ search, looks ]

    )

    const handleQueryChange = useInputHander(setQuery);
    const onSearch = useCallback(
        (value, e, { source }) => {
            if (source === "clear") {
                setSearch("")
            }
            else {
                setSearch(value)
            }
        },
        [ setSearch, setQuery ]
    );
    const onClose = useSwallowEventCallback(
        () => LooksDialog.hide(dialogName),
        [ dialogName ]
    );

    const loadLooks = useCallback(
        async () => {
            setLoading(true);
            const where = {
                published: { _eq: true },
                deleted: { _eq: false },
                friId: { _eq: user.id() },
                consultation: {
                    customerId: { _eq: contactId },
                    hidden: { _eq: false },
                    status: { _nin: ["cancelled", "deleted"] },
                }
            }
            const res = await lookLoader({ where, limit: 100 });

            setLooks(res);
            setLoading(false);
        },
        [ contactId ]
    );

    useEffect(
        () => {
            loadLooks()
        },
        [ ]
    );

    useUpdateEffect(
        () => {
            loadLooks()
        },
        [ dialogName ]
    );

    useEffect(
        () => { // dirty hack to update styles if empty
            if (!loading && looks.length === 0) {
                app.trigger(`app/${ dialogName }/options`, { contactId, empty: true });
            }
        }, 
        [ loading, contactId, looks ]
    );

    const onSelectionChange = useCallback(
        (id, selected) => {
            const look = looks.find(p => p.id === id);
            selected ? add(look) : remove(look);
        },
        [ selection, looks ]
    );

    const onSelectClick = useCallback(
        () => {
            if (selectionPromise[dialogName]) {
                selectionPromise[dialogName](selection);
            }

            LooksDialog.hide(dialogName);
        },
        [ selection, looks, dialogName ]
    );

    const onAddClick = useSwallowEventCallback(
        () => LooksDialog.hide(dialogName),
        [ dialogName ]
    );

    return (
        <div className="looks-dialog-body">
            <div className="toolbar">
            { looks.length > 0 && 
                <Input.Search 
                    placeholder="Search looks"
                    size="large"
                    enterButton={
                        <Button 
                            Icon={ IconSearch }
                            text="Search" 
                            type="primary"/>
                    }
                    allowClear
                    onChange={ handleQueryChange }
                    value={ query }
                    onSearch={ onSearch }/>
            }
                <a href="/#" 
                    className="feed-dialog-close"
                    onClick={ onClose }>
                    <IconClose/>
                </a>
            </div>
            { looks.length > 0 && 
                <div className="looks-cards">
                { items?.map(l => (
                    <div key={ l.id } className="look-item">
                        <LookCard 
                            look={ l } showTags={ false }>
                                <LookSelect id={ l.id } selected={ map[l.id] || false } onChange={ onSelectionChange }/>
                        </LookCard>
                        <LookCardFooter look={ l }/>
                    </div>
                ))}

                { (!loading && items.length === 0) && 
                    <EmptyMessage size="small" message="No looks matched your search"/>
                }
                </div>
            }

            { (loading) && <Spin spinning className="infinite-scroll-spinner"/> }
            {
                looks.length === 0 && !loading &&
                <div className="looks-cards-empty">
                    <p>You haven't created any looks yet</p>

                    <div className="looks-cards-empty-action">
                        Create a styling session and add some looks
                        <Button
                            size="large"
                            type="primary"
                            shape="circle"
                            Icon={ IconPlus }
                            href="/styling"
                            onClick={ onAddClick }
                        />
                    </div>
                </div>
            }
            { looks.length > 0 && 
                <div className="feed-dialog-footer">
                    <Button 
                        type="primary" 
                        text={ `Select (${ selectionCnt })` }
                        onClick={ onSelectClick }
                        disabled={ selectionCnt === 0 }/>
                </div>
            }
        </div>
    )

}

function LooksDialog({ name = DIALOG_NAME }) {
    const open = useSelector(s => s.dialogs[name] || false);
    const [ options, setOptions ] = useState({});

    const onClose = useCallback(
        () => LooksDialog.hide(name),
        [ name ]
    );

    useOnAppEvent(`app/${ name }/options`, setOptions);

    return (
        <Modal 
            classNames={{ wrapper: `looks-dialog ${ options?.empty && "empty" }` }}
            centered    
            width={options?.empty ? "480px" : "650px"}
            open={ open } 
            destroyOnClose 
            closeIcon={ null }
            footer={ null }
            maskClosable={ false }
            onCancel={ onClose }>
            <Looks dialogName={ name } { ...options }/>
        </Modal>
    )
}

LooksDialog.NAME = DIALOG_NAME;

LooksDialog.show = function(options = {}, name = DIALOG_NAME) {
    app.trigger(`app/${ name }/options`, options);
    store.dispatch(ui.show(name));
    return new Promise((resolve) => {
        selectionPromise[name] = resolve;
    });
}

LooksDialog.hide = function(name = DIALOG_NAME) {
    selectionPromise[name] && selectionPromise[name]([]);
    store.dispatch(ui.hide(name));
    delete selectionPromise[name];
}

export default LooksDialog