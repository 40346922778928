import { Flex, Input, Dropdown } from "antd"
//import Icon from "@ant-design/icons"
import { useCallback, useMemo, useContext } from "react"

import Button from "common/src/refactor/components/button/Button"

import { RetailerSelect } from "common/src/refactor/components/catalogue/filter/Retailer"
import { GenderSelect } from "common/src/refactor/components/catalogue/filter/Gender"
import { DesignerSelect } from "common/src/refactor/components/catalogue/filter/Designer"
import { RegionSelect } from "common/src/refactor/components/catalogue/filter/Region"
import { PriceSelect } from "common/src/refactor/components/catalogue/filter/Price"

import { ReactComponent as IconSearch } from "common/src/svg/search.svg"

import useInputHandler from "common/src/refactor/hooks/userInputHandler"
import useDualState from "common/src/refactor/hooks/useDualState"

import { filterQueryParams } from "common/src/refactor/lib/catalogue"
import swallowEvent from "common/src/lib/dom/swallowEvent"
import { CatalogueFilterContext } from "common/src/refactor/components/catalogue/Context"


function FeedToolbar({ useQueryString = false }) {

    const filterContext = useContext(CatalogueFilterContext);
    //const [ showFilters, setShowFilters ] = useState(true);
    const onApplyContext = useCallback(
        ({ filters }) => filterContext.set(filters),
        [ filterContext ]
    );

    const { query, setQuery, 
            retailer, setRetailer,
            gender, setGender,
            designer, setDesigner,
            region, setRegion,
            price, setPrice,
            sortdir, setSortdir,
            applySearchParams } = 
            useDualState({
                params: filterQueryParams, 
                // both= query and state; 
                mode: useQueryString ? "both" : "state",
                // param overrides
                overrides: {
                    gender: { autoApply: useQueryString },
                    designer: { autoApply: useQueryString },
                    retailer: { autoApply: useQueryString },
                    region: { autoApply: useQueryString },
                    price: { autoApply: useQueryString },
                    sortdir: { autoApply: useQueryString }
                },
                onApply: onApplyContext
            });
    
    const onQueryChange = useInputHandler(setQuery);
    
    const onSortingClick = useCallback(
        (e) => {
            swallowEvent(e);
            const sortdir = e.target.dataset.sorting;
            setSortdir(sortdir);
        },
        [ setSortdir ]
    );

    const onSearch = useCallback(
        async (value, e, { source }) => {

            if (source === "clear") {
                setQuery("");
            }
            applySearchParams();
        },
        [ applySearchParams, setQuery ]
    );

    // const onShowFiltersClick = useCallback(
    //     () => setShowFilters(prev => !prev),
    //     []
    // );

    const sortMenu = useMemo(
        () => ({
            items: [
                {
                    key: "random",
                    label: (
                        <a href="/#"
                            data-sorting="random"
                            className={ sortdir === "random" || !sortdir ? "active" : "" }
                            onClick={ onSortingClick }
                            children="Random"/>
                    )
                },
                {
                    key: "asc",
                    label: (
                        <a href="/#"
                            data-sorting="asc"
                            className={ sortdir === "asc" ? "active" : "" }
                            onClick={ onSortingClick }
                            children="Price (Low-High)"/>
                    )
                },
                {
                    key: "desc",
                    label: (
                        <a href="/#"
                            data-sorting="desc"
                            className={ sortdir === "desc" ? "active" : "" }
                            onClick={ onSortingClick }
                            children="Price (High-Low)"/>
                    )
                }
            ]
        }),
        [ sortdir, onSortingClick ]
    );

    const sortName = useMemo(
        () => {
            if (!sortdir || sortdir === "random") {
                return "Sort (random)";
            }
            else if (sortdir === "asc") {
                return "Sort (Low-High)";
            }
            return "Sort (High-Low)";
        },
        [ sortdir ]
    );

    return (
        <Flex className="toolbar">
            <Input.Search 
                placeholder="Search by product, brand, retailer or URL"
                size="large"
                enterButton={
                    <Button 
                        Component="a"
                        onClick={ e => e.preventDefault() }
                        size="large"
                        Icon={ IconSearch }
                        text="Search" 
                        type="primary"/>
                }
                allowClear
                onChange={ onQueryChange }
                value={ query }
                onSearch={ onSearch }/>
            
                <GenderSelect
                    size="large"
                    popupMatchSelectWidth={ false }
                    value={ gender }
                    onChange={ setGender }/>
                <RetailerSelect 
                    size="large"
                    popupMatchSelectWidth={ false }
                    allowClear 
                    mode="multiple"
                    value={ retailer }
                    onChange={ setRetailer }/>
                <DesignerSelect
                    size="large"
                    popupMatchSelectWidth={ false }
                    allowClear
                    mode="multiple"
                    value={ designer }
                    onChange={ setDesigner }/>
                <RegionSelect
                    size="large"
                    popupMatchSelectWidth={ false }
                    value={ region }
                    onChange={ setRegion }/>
                <PriceSelect
                    size="large"
                    popupMatchSelectWidth={ false }
                    value={ price }
                    onChange={ setPrice }/>
                
                <div className="toolbar-spacer"/>
                {/*<Button 
                    size="large"
                    type="text" 
                    text={ showFilters ? "Hide filters" : "Show filters" }
                    onClick={ onShowFiltersClick }/>*/}
                <Dropdown menu={ sortMenu }>
                    <Button 
                        size="large"
                        type="text"
                        text={ sortName }/>
                </Dropdown>
            
        </Flex>
    )
}

export default FeedToolbar