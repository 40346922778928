const ONLINE_TIME = 30000;

export function isOnline(lastOnlineAt) {
    if (!lastOnlineAt) {
        return false;
    }
    const now = (new Date()).getTime();
    const then = (new Date(lastOnlineAt)).getTime();
    return now - then <= ONLINE_TIME;
}
