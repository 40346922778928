
import { ReactComponent as IconTag } from "common/src/svg/tag.svg"
import { ReactComponent as IconCamera } from "common/src/svg/camera_add.svg"

import useDateFormat from "common/src/refactor/hooks/useDateFormat"



function LookCardFooter({ look, showDate = true }) {

    const productsCount = look.products.length || 0;
    const imagesCount = look.layouts.images?.length || 0;
    const date = useDateFormat(look.createdAt);

    return (
        <div className="look-card-footer">
            <div className="look-card-footer-counts">
               <div className="look-card-footer-counts-wrapper">
                    { look.productMode === "tagged" &&
                        <div className="look-card-footer-count">
                            <IconCamera/>
                            { imagesCount } { imagesCount === 1 ? "Image" : "Images" }
                        </div> }
                    <div className="look-card-footer-count">
                        <IconTag/>
                        { productsCount } { productsCount === 1 ? "Product" : "Products" }
                    </div> 
               </div>
                { showDate && 
                    <div className="look-card-footer-date">
                        { date }
                    </div> }
            </div>

            <p> { look.title } </p>
        </div>
    )
}

export default LookCardFooter