
import { batch } from "react-redux"
import createActionMap, { map2builder } from "common/src/lib/store/createActionMap.js";
import { createReducer } from "@reduxjs/toolkit"
import hub from "common/src/hub"
import * as listManager from "common/src/lib/store/listManager"
import cloneDeep from "lodash/cloneDeep"


const initialLayout = {
    zoom: 0, 
    v: "c", 
    h: "c", 
    vplus: 0, 
    hplus: 0
};

const getInitialLayouts = function() {
    return {
        "template": [ {col: 1, row: 2, cls: ["tl","bl"]}, {col: 1, row: 1, cls: ["tr"]}, 
                        {col: 1, row: 1, cls: ["br"]} ],
        "order": [ null ],
        "tags": {}
    };
};

const getLookScheme = function() {
    return {
        id: null,
        layouts: getInitialLayouts(),
        products: [],
        styles: [],
        seasons: [],
        occasions: [],
        locations: [],
        hiddenTags: [],
        description: "",
        title: "",
        productMode: "multiple",
        published: false,
        createdAt: (new Date()).toISOString()
    };
}

const getLookExtra = function() {
    return {
        deletedImages: [],
        deletedUploads: [],
        deletedProducts: [],
        deletedStyles: [],
        deletedSeasons: [],
        deletedLocations: [],
        deletedOccasions: [],
        deletedHiddenTags: []
    }
}

const getInitialState = function() {
    return {

        look: { ...getLookScheme(), ...getLookExtra() },

        ui: {
            page: "products",
            form: null,
            syncImages: false,
            hasChanges: false,
            submitting: false,
            saving: false,
            declining: false,
            publishing: false,
            deleting: []
        }
    }
}


export const sortProducts = (a, b) => {
    return a.position < b.position ? -1 :
            a.position > b.position ? 1 : 0;
};

const lookScheme = getLookScheme();
const lookExtra = getLookExtra();
const initialState = getInitialState();

const data = createActionMap("look/editor/look/", {
    set: ".",
    reset: ".",
    update: ".",
    "layouts/": ["update", "reset", "set", "applyTemplate", "setImage"],
    "description/": ["set"],
    "title/": ["set"],
    "published/": ["set"],
    "productTags/": ["set", "update", "remove"],
    "products/": ["push", "remove", "replace", "move", 
                    "update", "setTags", "removeExtra", 
                    "removeFromLayout", "setToLayout",
                    "updateImage", "moveImage", "removeImage", "addImages"],
    "styles/": ["set", "push", "remove"],
    "seasons/": ["set", "push", "remove"],
    "locations/": ["set", "push", "remove"],
    "occasions/": ["set", "push", "remove"],
    "hiddenTags/": ["set", "push", "remove"]
});

const ui = createActionMap("look/editor/ui/", {
    "page": ".",
    "form": ".",
    "syncImages": ".",
    "saving": ".",
    "declining": ".",
    "publishing": ".",
    "submitting": ".",
    "hasChanges": ".",
    "deleting/": ["start", "stop"],
    "restoring/": ["start", "stop"]
});

function copyObjectOrArray(obj) {
    return Array.isArray(obj) ? [ ...obj ] : { ...obj };
}


const removeProduct = (state, id) => {

    let inx = state.look.products.findIndex(p => p.id === id),
        prev = state.look.products[inx],
        order = [].concat(state.look.layouts.order || []),
        orderInx = order.indexOf(id),
        sortProducts = (a, b) => {
            return a.position < b.position ? -1 :
                    a.position > b.position ? 1 : 0;
        };

    batch(() => {
        if (inx !== -1) {
            hub.dispatch("store", "look-editor-product-remove", {
                product: prev
            });
            state.look.products.splice(inx, 1);
            delete state.look.layouts[id];

            if (state.look.layouts.tags && state.look.layouts.tags[id]) {
                delete state.look.layouts.tags[id];
            }

            if (typeof prev.id === "string" && prev.id.indexOf("tmp") !== 0) {
                !state.look.deletedProducts && (state.look.deletedProducts = []);
                state.look.deletedProducts.push(prev);
            }

            let products = [].concat(state.look.products).sort(sortProducts);
            products.forEach((pr, i) => {
                let inx = state.look.products.findIndex(p => p.id == pr.id);
                state.look.products[inx].position = i;
            })
        }
    
        if (orderInx !== -1) {
            if (state.look.productMode === "tagged") {
                delete order[orderInx];
            }
            else {
                order[orderInx] = null;
            }
            state.look.layouts.order = order;
        }
    });
}




const reducer = createReducer(getInitialState(), (builder) => {
    map2builder(builder, {
        // top level actions
        [data.reset]: (state, action) => {
            hub.dispatch("store", "look-reset", { look: state.look });
            let initial = getInitialState(),
                key;
            batch(() => {
                state.look = { ...initial.look }
                /*for (key in initial.look) {
                    state.look[key] = initial.look[key]
                }*/
                for (key in initial.ui) {
                    state.ui[key] = initial.ui[key]
                }
            });
        },
    
        [data.set]: (state, action) => {
            let look = { ...action.payload },
                initial = getInitialState();
            for (let key in initial.look) {
                if (!(key in look)) {
                    look[key] = initial.look[key];
                }
            }
            state.look = look;
        },
        [data.update]: (state, action) => {
            batch(() => {
                Object.assign(state.look, action.payload);
                state.ui.hasChanges = true;
            });
            hub.dispatch("look-editor", "save-draft");
        },
    
        // new look description
        [data.description.set]: (state, action) => {
            batch(() => {
                state.look.description = action.payload;
                state.ui.hasChanges = true;
            });
            hub.dispatch("look-editor", "save-draft");
        },

        [data.published.set]: (state, action) => {
            batch(() => {
                state.look.published = action.payload;
                state.ui.hasChanges = true;
            });
            hub.dispatch("look-editor", "save-draft");
        },
    
        [data.title.set]: (state, action) => {
            batch(() => {
                state.look.title = action.payload;
                state.ui.hasChanges = true;
            });
            hub.dispatch("look-editor", "save-draft");
        },
    
        // products
        [data.products.push]: (state, action) => {
            let p = cloneDeep(action.payload);
            //state.look.layouts[p.id] = initialLayout;
            p.position = state.look.products.length;
            batch(() => {
                state.look.products = [ ...state.look.products, p ]
                //state.look.products.push(p);
                state.ui.hasChanges = true;
            });
            hub.dispatch("look-editor", "save-draft");
        },
    
        [data.products.update]: (s, a) => {
            let product = a.payload,
                inx = s.look.products.findIndex(p => p.id === product.id);

            const products = [ ...s.look.products ];
            products[inx] = {
                ...products[inx],
                product
            }
    
            if (inx !== -1) {
                batch(() => {
                    /*s.look = {
                        ...s.look,
                        products
                    }*/
                    Object.assign(s.look.products[inx], product);
                    s.ui.hasChanges = true;
                });
                hub.dispatch("look-editor", "save-draft");
            }
        },
    
        [data.products.addImages]: (s, a) => {
            let { id, images } = a.payload,
                pinx = s.look.products.findIndex(p => p.id === id);
    
            if (pinx !== -1) {
                batch(() => {
                    s.look.products[pinx].images = s.look.products[pinx].images.concat(images);
                    s.look.layouts[id] = s.look.layouts[id].concat(images.map(i => ({ ...initialLayout })));
                    s.ui.hasChanges = true;
                });
                hub.dispatch("look-editor", "save-draft");
            }
        },
    
        [data.products.updateImage]: (s, a) => {
            let { id, inx, image } = a.payload,
                pinx = s.look.products.findIndex(p => p.id === id);
    
            if (pinx !== -1) {
                batch(() => {
                    Object.assign(s.look.products[pinx].images[inx], image);
                    s.ui.hasChanges = true;
                });
                hub.dispatch("look-editor", "save-draft");
            }
        },
    
        [data.products.moveImage]: (s, a) => {
            let { id, src, fromInx, toInx } = a.payload,
                product = s.look.products.find(p => p.id === id),
                productLayout = s.look.layouts[id],
                prevInx = fromInx !== undefined ? 
                            fromInx : 
                            product.images.findIndex(i => i.src === src),
                img = product.images[prevInx];
    
            if (prevInx === toInx) {
                return;
            }
        
            batch(() => {
                product.images.splice(prevInx, 1);
                product.images.splice(toInx < prevInx ? toInx : toInx - 1, 0, img);
    
                if (!Array.isArray(productLayout)) {
                    productLayout = product.images.map((i, inx) => {
                        return inx === 0 ? productLayout : { ...initialLayout }
                    });
    
                    s.look.layouts[id] = productLayout;
                }
                else if (productLayout.length === product.images.length) {
                    let l = productLayout[prevInx];
                    productLayout.splice(prevInx, 1);
                    productLayout.splice(toInx < prevInx ? toInx : toInx - 1, 0, l);
    
                    s.look.layouts[id] = productLayout;
                }
    
                s.ui.hasChanges = true;
            });
    
            hub.dispatch("look-editor", "save-draft");
        },
    
        [data.products.removeImage]: (s, a) => {
            const { id, image, key } = a.payload,
                    product = s.look.products.find(p => p.id === id),
                    imgInx = !key ? image : product.images.findIndex(i => i.key === key),
                    img = product.images[imgInx];
            let productLayout = s.look.layouts[id];
    
            batch(() => {
    
                if (img && typeof img !== "string") {
                    img.key && s.look.deletedImages.push(img.key);
                    img.uploadKey && s.look.deletedUploads.push(img.uploadKey);
                }
    
                img && product.images.splice(imgInx, 1);
    
                if (!Array.isArray(productLayout)) {
                    productLayout = product.images.map((i, inx) => {
                        return inx === 0 ? productLayout : { ...initialLayout }
                    });
    
                    s.look.layouts[id] = productLayout;
                }
                else if (productLayout.length === product.images.length - 1) {
                    img && productLayout.splice(imgInx, 1);
                    s.look.layouts[id] = productLayout;
                }
    
                s.ui.hasChanges = true;
            });
    
            hub.dispatch("look-editor", "save-draft");
        },
    
        [data.products.move]: (s, a) => {
            const { id, toInx } = a.payload;
    
            let i, l, p, inx,
                products = [].concat(s.look.products).sort(sortProducts),
                pos = 0,
                prevInx = products.findIndex(p => p.id === id),
                dir = prevInx > toInx ? "l" : "r";
    
            if (prevInx === toInx) {
                return;
            }
    
            batch(() => {
                for (i = 0, l = products.length; i < l; i++) {
    
                    p = products[i];
    
                    if (dir === "l" && toInx === i) {
                        pos++;
                    }
                    if (dir === "r" && prevInx === i) {
                        pos--;
                    }
                    if (dir === "r" && toInx + 1 === i) {
                        pos++;
                    }
    
                    inx = s.look.products.findIndex(p1 => p1.id === p.id);
                    s.look.products[inx].position = p.id === id ? toInx : pos;
                    pos++;
                }
    
                s.ui.hasChanges = true;
            })
    
            hub.dispatch("look-editor", "save-draft");
        },
    
        [data.products.replace]: (state, action) => {
            let product = action.payload,
                inx = state.look.products.findIndex(p => p.id === product.id),
                prev = state.look.products[inx];
        
            if (inx !== -1) {
                hub.dispatch("store", "look-editor-product-replace", {
                    prev: prev,
                    next: product
                });
                batch(() => {
                    state.look.products.splice(inx, 1, product);
                    state.ui.hasChanges = true;
                });
                hub.dispatch("look-editor", "save-draft");
            }
        },
        
        [data.products.removeFromLayout]: (state, action) => {
            const id = action.payload,
                order = [].concat(state.look.layouts.order || []),
                orderInx = order.indexOf(id);
    
            batch(() => {
                delete state.look.layouts[id];
                if (orderInx !== -1) {
                    order[orderInx] = null;
                    state.look.layouts.order = order;
                    state.ui.hasChanges = true;
                }
            })
            
            hub.dispatch("look-editor", "save-draft");
        },
    
        [data.products.removeExtra]: (state, action) => {
            const layouts = state.look.layouts;
            const tags = state.look.layouts.tags || {};
            console.trace()

            batch(() => {
                for (let i = 0, l = state.look.products.length; i < l; i++) {
                    let id = state.look.products[i]?.id;
                    if (id && !layouts[id] && !tags[id]) {
                        removeProduct(state, id);
                        state.ui.hasChanges = true;
                    }
                }
            })

            hub.dispatch("look-editor", "save-draft");
        },
    
        [data.products.remove]: (state, action) => {
    
            batch(() => {
                removeProduct(state, action.payload);
                state.ui.hasChanges = true;
            });
            hub.dispatch("look-editor", "save-draft");
        },
    
        [data.products.setToLayout]: (s, a) => {
    
            const { id, toInx } = a.payload;
            const order = [].concat(s.look.layouts.order || []);
            const product = s.look.products.find(p => p && p.id === id);
    
            if (!product) {
                return;
            }
    
            if (order.length < toInx - 1) {
                order.length = toInx - 1;
            }
    
            if (order[toInx] === id) {
                return;
            }
        
            let prevInx = order.indexOf(id),
                prevPid = order[toInx],
                i, l;
    
            batch(() => {
    
                order[toInx] = id;
    
                if (prevInx !== -1) {
                    order[prevInx] = null;
                }
                // first time added to layout
                else {
                    const layout = product.images.map(() => ({ ...initialLayout }));
                    s.look.layouts[id] = layout;
                }
    
                // trying to move product that occupied this space before
                if (prevPid) {
                    // looking to the right
                    for (i = toInx + 1, l = order.length; i < l; i++) {
                        if (!order[i]) {
                            order[i] = prevPid;
                            prevPid = null;
                            break;
                        }
                    }
                    // looking to the left
                    if (prevPid) {
                        for (i = toInx - 1; i >= 0; i--) {
                            if (!order[i]) {
                                order[i] = prevPid;
                                prevPid = null;
                                break;
                            }
                        }
                    }
                    // if this is no more space, remove it from layouts
                    if (prevPid) {
                        delete s.look.layouts[prevPid];
                    }
                }
    
                s.look.layouts.order = order;
                s.ui.hasChanges = true;
            });
    
            hub.dispatch("look-editor", "save-draft");
        },
    
        [data.products.setTags]: (state, action) => {
            let { id, type, tags } = action.payload,
                inx = state.look.products.findIndex(p => p.id === id),
                product = inx !== -1 ? state.look.products[inx] : null;
    
            if (product) {
                const delList = "deleted" + type[0].toUpperCase() + type.substring(1);
                batch(() => {
                    !product[delList] && (product[delList] = []);
                    listManager.set(product[type], tags, product[delList]);
                    state.ui.hasChanges = true;
                });
                //product[type] = tags;
            }
    
            hub.dispatch("look-editor", "save-draft");
        },

        [data.productTags.set]: (s, a) => {
            const { id, layout } = a.payload;

            batch(() => {
                if (!s.look.layouts.tags) {
                    s.look.layouts.tags = {};
                }
                s.look.layouts.tags[id] = [ layout ];
            });
            hub.dispatch("look-editor", "save-draft");
        },

        [data.productTags.update]: (s, a) => {
            const { id, index = 0, layout } = a.payload;
            s.look.layouts.tags[id][index] = { ...s.look.layouts.tags[id][index], ...layout };
            hub.dispatch("look-editor", "save-draft");
        },

        [data.productTags.remove]: (s, a) => {
            const { id, index = null } = a.payload;
            if (index === null) {
                delete s.look.layouts.tags[id];
            }
            else {
                delete s.look.layouts.tags[id][index];
            }
            hub.dispatch("look-editor", "save-draft");
        },
    
        [data.layouts.applyTemplate]: (s, a) => {
            const { tpl, productMode } = a.payload;
            const prevOrder = s.look.layouts.order || [];
            const prevMode = s.look.productMode || "multiple";
    
            if (productMode !== prevMode) {
                if (productMode === "single") {
    
                    const product = s.look.products[0];
                    const layouts = product ? s.look.layouts[product.id] : null;
        
                    batch(() => {
                        s.look.layouts = { template: tpl, order: [] };
                        s.look.productMode = productMode;
                        s.look.products = [];
    
                        if (product) {
                            s.look.products.push(product);
                            if (layouts) {
                                s.look.layouts[product.id] = layouts;
                            }
                        }
    
                        s.ui.hasChanges = true;
                    });
                }
                else {
                    const product = s.look.products[0];
    
                    batch(() => {
                        s.look.productMode = productMode;
                        s.look.layouts.template = tpl;
                        s.look.layouts.order = product ? [ product.id ] : [];
                        s.ui.hasChanges = true;
                    });
                }
            }
            else  {
                if (productMode === "multiple") {
                    // const prev = prevOrder || [];
                    // const order = [].concat(prev);
                    // order.length = tpl.length;
                    // let pinx, oinx;
    
                    // while ((oinx = order.findIndex(id => !id)) !== -1 &&
                    //         (pinx = prev.findIndex(
                    //             (id, inx) => 
                    //                 !!id && 
                    //                 inx > order.length -1 && 
                    //                 order.findIndex(oid => oid === id) === -1)) !== -1) {
                    //     order[oinx] = prev[pinx];
                    //     prev[pinx] = null;
                    // }
    
                    // const removed = prevOrder ?
                    //                     prevOrder.filter(id => order.indexOf(id) === -1) :
                    //                     [];
    
                    batch(() => {
                        s.look.layouts.template = tpl;
                        //s.look.layouts.order = order;
                        s.look.productMode = productMode;
                        /*removed.forEach(id => {
                            delete s.look.layouts[id];
                            const inx = s.look.products.findIndex(p => p.id === id);
                            if (inx !== -1) {
                                s.look.products.splice(inx, 1);
                            }
                        });*/
                        s.ui.hasChanges = true;
                    });
                }
                else {
                    batch(() => {  
                        s.look.layouts.template = tpl;
                        s.ui.hasChanges = true;
                    });
                }
            }
    
            hub.dispatch("look-editor", "save-draft");
        },

        [data.layouts.setImage]: (state, action) => {
            const prev = state.look.layouts.images;
            batch(() => {
                if (prev && prev.length > 0 && prev[0].key) {
                    state.look.deletedImages.push(prev[0].key);
                }
                state.look.layouts.images = [ action.payload ];
            })
            hub.dispatch("look-editor", "save-draft");
        },
    
        [data.layouts.update]: (state, action) => {
            let { key, property, value, image, all } = action.payload;

            const update = (layout, property, value) => {
                if (!property) {
                    Object.assign(layout, value);
                }
                else {
                    if (value === null) {
                        delete layout[property];
                    }
                    else {
                        layout[property] = value;
                    }
                }
            };
    
            if (!property && value === null && (image === null || image === undefined)) {
                batch(() => {
                    delete state.look.layouts[key];
                    state.ui.hasChanges = true;
                });
            }
            else {
                let keyLayout = state.look.layouts[key],
                    layout;
                const product = state.look.products.find(p => p.id === key);
    
                if (!image) {
                    image = 0;
                }

                batch(() => {
    
                    if (!all) {
                        if (product) {
                            if (!Array.isArray(keyLayout)) {
                                if (keyLayout[image]) {
                                    keyLayout = product.images.map((i, inx) => {
                                        if (keyLayout[inx]) {
                                            return { ...keyLayout[inx] };
                                        }
                                        return { ...initialLayout };
                                    })
                                }
                                else {
                                    keyLayout = product.images.map((i, inx) => {
                                        return inx === 0 ? { ...keyLayout } : { ...initialLayout }
                                    })
                                }
                            }

                            layout = keyLayout[ image ] || { ...initialLayout };
                            keyLayout[ image ] = layout;
                        }
                        else {
                            layout = keyLayout;
                        }

                        update(layout, property, value);
                    }
                    else {
                        if (!Array.isArray(keyLayout)) {
                            keyLayout = product.images.map((i, inx) => {
                                return inx === 0 ? { ...keyLayout } : { ...initialLayout }
                            })
                        }
    
                        keyLayout.forEach(l => update(l, property, value));
                    }

                    state.look.layouts[key] = keyLayout;
                    state.ui.hasChanges = true;
                })
            }
            
            hub.dispatch("look-editor", "save-draft");
        },
    
        [data.layouts.reset]: (state, action) => {
            state.look.layouts = getInitialLayouts();
            state.ui.hasChanges = true;
            hub.dispatch("look-editor", "save-draft");
        },
    
        [data.layouts.set]: (state, action) => {
            let { key, value } = action.payload;
            
            batch(() => {
                if (value === null) {
                    delete state.look.layouts[key];
                }
                else {
                    state.look.layouts[key] = value;
                }
                state.ui.hasChanges = true;
            });
            hub.dispatch("look-editor", "save-draft");
        },
    
        
    
        // look tags
        [data.styles.set]: (state, action) => {
            let l = state.look;
            batch(() => {
                !l.styles && (l.styles = []);
                !l.deletedStyles && (l.deletedStyles = []);
                listManager.set(l.styles, action.payload, l.deletedStyles);
                state.ui.hasChanges = true;
            });
            hub.dispatch("look-editor", "save-draft");
        },
        [data.styles.push]: (state, action) => {
            let l = state.look;
            batch(() => {
                !l.styles && (l.styles = []);
                listManager.push(l.styles, action.payload);
                state.ui.hasChanges = true;
            });
            hub.dispatch("look-editor", "save-draft");
        },
        [data.styles.remove]: (state, action) => {
            let l = state.look;
            batch(() => {
                !l.styles && (l.styles = []);
                !l.deletedStyles && (l.deletedStyles = []);
                listManager.remove(l.styles, action.payload, l.deletedStyles);
                state.ui.hasChanges = true;
            });
            hub.dispatch("look-editor", "save-draft");
        },
    
    
        [data.seasons.set]: (state, action) => {
            let l = state.look;
            batch(() => {
                !l.seasons && (l.seasons = []);
                !l.deletedSeasons && (l.deletedSeasons = []);
                listManager.set(l.seasons, action.payload, l.deletedSeasons);
                state.ui.hasChanges = true;
            });
            hub.dispatch("look-editor", "save-draft");
        },
        [data.seasons.push]: (state, action) => {
            let l = state.look;
            batch(() => {
                !l.seasons && (l.seasons = []);
                listManager.push(l.seasons, action.payload);
                state.ui.hasChanges = true;
            });
            hub.dispatch("look-editor", "save-draft");
        },
        [data.seasons.remove]: (state, action) => {
            let l = state.look;
            batch(() => {
                !l.seasons && (l.seasons = []);
                !l.deletedSeasons && (l.deletedSeasons = []);
                listManager.remove(l.seasons, action.payload, l.deletedSeasons);
                state.ui.hasChanges = true;
            });
            hub.dispatch("look-editor", "save-draft");
        },
    
    
        [data.locations.set]: (state, action) => {
            let l = state.look;
            batch(() => {
                !l.locations && (l.locations = []);
                !l.deletedLocations && (l.deletedLocations = []);
                listManager.set(l.locations, action.payload, l.deletedLocations);
                state.ui.hasChanges = true;
            });
            hub.dispatch("look-editor", "save-draft");
        },
        [data.locations.push]: (state, action) => {
            let l = state.look;
            batch(() => {
                !l.locations && (l.locations = []);
                listManager.push(l.locations, action.payload);
                state.ui.hasChanges = true;
            });
            hub.dispatch("look-editor", "save-draft");
        },
        [data.locations.remove]: (state, action) => {
            let l = state.look;
            batch(() => {
                !l.locations && (l.locations = []);
                !l.deletedLocations && (l.deletedLocations = []);
                listManager.remove(l.locations, action.payload, l.deletedLocations);
                state.ui.hasChanges = true;
            });
            hub.dispatch("look-editor", "save-draft");
        },
    
    
        [data.occasions.set]: (state, action) => {
            let l = state.look;
            batch(() => {
                !l.occasions && (l.occasions = []);
                !l.deletedOccasions && (l.deletedOccasions = []);
                listManager.set(l.occasions, action.payload, l.deletedOccasions);
                state.ui.hasChanges = true;
            });
            hub.dispatch("look-editor", "save-draft");
        },
        [data.occasions.push]: (state, action) => {
            let l = state.look;
            batch(() => {
                !l.occasions && (l.occasions = []);
                listManager.push(l.occasions, action.payload);
                state.ui.hasChanges = true;
            });
            hub.dispatch("look-editor", "save-draft");
        },
        [data.occasions.remove]: (state, action) => {
            let l = state.look;
            batch(() => {
                !l.occasions && (l.occasions = []);
                !l.deletedOccasions && (l.deletedOccasions = []);
                listManager.remove(l.occasions, action.payload, l.deletedOccasions);
                state.ui.hasChanges = true;
            });
            hub.dispatch("look-editor", "save-draft");
        },
    
    
        [data.hiddenTags.set]: (state, action) => {
            let l = state.look;
            batch(() => {
                !l.hiddenTags && (l.hiddenTags = []);
                !l.deletedHiddenTags && (l.deletedHiddenTags = []);
                listManager.set(l.hiddenTags, action.payload, l.deletedHiddenTags);
                state.ui.hasChanges = true;
            });
            hub.dispatch("look-editor", "save-draft");
        },
        [data.hiddenTags.push]: (state, action) => {
            let l = state.look;
            batch(() => {
                !l.hiddenTags && (l.hiddenTags = []);
                listManager.push(l.hiddenTags, action.payload);
                state.ui.hasChanges = true;
            });
            hub.dispatch("look-editor", "save-draft");
        },
        [data.hiddenTags.remove]: (state, action) => {
            let l = state.look;
            batch(() => {
                !l.hiddenTags && (l.hiddenTags = []);
                !l.deletedHiddenTags && (l.deletedHiddenTags = []);
                listManager.remove(l.hiddenTags, action.payload, l.deletedHiddenTags);
                state.ui.hasChanges = true;
            });
            hub.dispatch("look-editor", "save-draft");
        },
    
    
    
    
        // wizard page
        [ui.page]: (state, action) => {
            state.ui.page = action.payload;
        },
        // current side form
        [ui.form]: (state, action) => {
            state.ui.form = action.payload;
        },
        [ui.submitting]: (state, action) => {
            state.ui.submitting = action.payload;
        },
        [ui.syncImages]: (state, action) => {
            state.ui.syncImages = action.payload;
        },
        [ui.saving]: (state, action) => {
            state.ui.saving = action.payload;
        },
        [ui.hasChanges]: (state, action) => {
            state.ui.hasChanges = action.payload;
        },
        [ui.publishing]: (state, action) => {
            state.ui.publishing = action.payload;
        },
        [ui.declining]: (state, action) => {
            state.ui.declining = action.payload;
        },
        [ui.deleting.start]: (state, action) => {
            listManager.push(state.ui.deleting, action.payload);
        }
    })
});


export { reducer, data, ui, initialState, lookScheme, lookExtra, initialLayout, copyObjectOrArray }
