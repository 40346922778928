import React, { useMemo } from "react"
import { Link } from "react-router-dom"

export default (props) => {

    const isExternal = useMemo(() => props.to.indexOf("http") === 0, [ props.to ]);

    if (isExternal) {
        /*const linkProps = Object.assign({}, props);
        linkProps.href = linkProps.to;
        delete linkProps.to;*/
        return <a { ...props } href={ props.to }/>
    }

    return <Link { ...props }/>
}