
import getCookie from "common/src/lib/cookie/get"
import appDataSource from "common/src/appDataSource"

async function getCookieProxy(name) {
    return getCookie(name);
}

const listeners = {
    init: () => {
        if (!appDataSource.has("*", getCookieProxy)) {
            appDataSource.on("*", getCookieProxy);
        }
    }
}

export default listeners;