import { useContext, useState, useMemo, useCallback } from "react"
import { useDispatch, batch } from "react-redux"

import ProductCard from "common/src/refactor/components/look/Product"
//import LookBuilderSearch from "./Search"
//import LookBuilderProduct from "./ProductDialog"
import Dnd from "common/src/refactor/components/Dnd"

//import { ReactComponent as IconEdit } from "common/src/svg/edit.svg"
import { ReactComponent as IconDelete } from "common/src/svg/close.svg"
//import { ReactComponent as IconHeart } from "common/src/svg/heart.svg"
//import { ReactComponent as IconRefresh } from "common/src/svg/refresh.svg"
import { ReactComponent as IconLt } from "common/src/svg/lt.svg"
import { ReactComponent as IconGt } from "common/src/svg/gt.svg"
import { ReactComponent as IconDrag } from "common/src/svg/drag.svg"

import addListener from "common/src/lib/dom/addListener"
import removeListener from "common/src/lib/dom/removeListener"
import adjustPosition from "common/src/lib/look/layout/adjustPosition"
import { getBackgroundSize } from "common/src/lib/look/layout2style"
import { data as lookData } from "common/src/store/look/editor"
import getProductLayout from "common/src/lib/look/getProductLayout"
import LookBuilderContext from "./LookBuilderContext"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import useUpdateEffect from "common/src/hooks/useUpdateEffect"
import useKey from "common/src/refactor/hooks/useKey"
import { data } from "common/src/store/look/editor"
import useDictRef from "common/src/hooks/useDictRef"
import { useSelector } from "react-redux"
import async from "common/src/lib/js/async"
//import useKeySetter from "common/src/refactor/hooks/useKeySetter"


function LookCardProductActions({ product, onLtClick, onGtClick, index }) {

    const dispatch = useDispatch();
    const builder = useContext(LookBuilderContext);
    const totalImages = useMemo(() => product.images?.length || 0, [ product ]);
    const activeSlot = useKey("activeSlot", builder);
    const editMode = activeSlot === index;
    const look = useSelector(s => s.lookEditor.look);
    const ref = useDictRef({ look });

    const onEditClick = useSwallowEventCallback(
        () => {
            builder.set("activeSlot", index);
            //builder.set("productPage", "images");
            //builder.set("currentModal", LookBuilderProduct.NAME);
        },
        [  builder, product, index ]
    );

    const onDeleteClick = useSwallowEventCallback(
        () => {
            batch(() => {
                dispatch(data.products.removeFromLayout(product.id));
                dispatch(data.products.remove(product.id));
            })
        },
        [ builder, product ]
    );

    /*const onMoodboardClick = useSwallowEventCallback(
        () => {
            builder.set("addToMoodboardProducts", [ product ]);
            builder.openPage("add-to-moodboard");
            builder.set("currentModal", LookBuilderSearch.NAME);
        },
        [ builder, product ]
    );

    const onRefreshClick = useSwallowEventCallback(
        () => {
            //console.log(index)
            builder.set("activeSlot", index);
            builder.openPage("catalogue");
            builder.set("currentModal", LookBuilderSearch.NAME);
            //builder.trigger("open-moodboard", product.id)
        },
        [ index, builder ]
    );*/

    const onProductMove = useCallback(
        (to, what) => {
            const pid = what.id, 
                    fromIndex = what.slotIndex, 
                    toInx = to.slotIndex,
                    look = ref.look,
                    product = look.products.find(p => p.id === pid),
                    mode = look.productMode || "multiple";
                
            if (mode === "multiple") {
                dispatch(data.products.setToLayout({ id: pid, toInx }));
            }
            else {
                const src = product.images[fromIndex].src;
                dispatch(data.products.moveImage({ id: pid, src, toInx }));
            }
        },
        // eslint-disable-next-line
        []
    );

    const { Cmp, attrs } = useMemo(
        () => {
            //if (!editMode) {
                return {
                    Cmp: Dnd,
                    attrs: {
                        // draggable: {
                        //     imageElId: `look-card-product-${ product.id }`,
                        //     id: product.id,
                        //     index: index,
                        //     origin: "look-card",
                        //     data: {
                        //         id: product.id,
                        //         index: index
                        //     }
                        // },
                        droppable: {
                            cardSelector: ".look-card-product",
                            drop: onProductMove,
                            //drop: (to, what) => this.onProductMove(what.id, what.index, to.index),
                            data: {
                                id: product.id,
                                slotIndex: index
                            }
                        }
                    }
                }
            /*}
            else {
                return {
                    Cmp: "div",
                    attrs: {}
                }
            }*/
        },
        [ product, index, onProductMove ]
    );

    const moveAttrs = useMemo(
        () => {
            //if (editMode) {
                return {
                    draggable: {
                        imageElId: `look-card-product-${ product.id }`,
                        id: product.id,
                        index: index,
                        origin: "look-card",
                        data: {
                            id: product.id,
                            index: index
                        }
                    },
                    onMouseDown: e => {
                        e.stopPropagation();
                    }
                }
            /*}
            return {
                onMouseDown: e => {
                    e.stopPropagation();
                    e.preventDefault();
                },
                onDragStart: e => {
                    e.stopPropagation();
                    e.preventDefault();
                }
            };*/
        },
        [ product, index ]
    )

    return (
        <Cmp { ...attrs } 
            className="look-card-product-actions"
            onClick={ onEditClick }>
            {/*<a href="/#" 
                className="look-card-product-edit"
                onClick={ onEditClick }>
                <IconEdit/>
            </a>
            <a href="/#" 
                className="look-card-product-add-to-moodboard"
                onClick={ onMoodboardClick }>
                <IconHeart/>
            </a>*/}
            <Dnd Component="a" 
                href="/#" 
                { ...moveAttrs }
                className="look-card-product-drag">
                <IconDrag/>
            </Dnd>
            <a href="/#" 
                className="look-card-product-delete"
                onClick={ onDeleteClick }>
                <IconDelete/>
            </a>

            {/*<a href="/#" 
                className="look-card-product-refresh"
                onClick={ onRefreshClick }>
                <IconRefresh/>
            </a>*/}

            { (totalImages > 1 && !editMode) && 
                <>
                <a href="/#" 
                    className="look-card-product-lt"
                    onClick={ onLtClick }>
                    <IconLt/>
                </a>
                <a href="/#" 
                    className="look-card-product-gt"
                    onClick={ onGtClick }>
                    <IconGt/>
                </a>
                </> }
        </Cmp>
    )
}

function CardBuilderProduct({ product, currentImage = 0, ...rest }) {

    const dispatch = useDispatch();
    const builder = useContext(LookBuilderContext);
    const look = useSelector(s => s.lookEditor.look);
    const syncImages = useSelector(s => s.lookEditor.ui.syncImages);
    const totalImages = useMemo(() => product.images?.length || 0, [ product ]);
    const [ currentImageIndex, setCurrentImageIndex ] = useState(currentImage);
    const [ layout, setLayout ] = useState(null);

    const activeSlot = useKey("activeSlot", builder);
    const activeSlotImageIndex = useKey("activeSlotImageIndex", builder);
    const editMode = activeSlot === rest.slotIndex;// && activeSlotImageIndex !== null;

    //useKeySetter("lookProductImageIndex", builder, currentImageIndex);

    const ref = useDictRef({ 
        activeSlotImageIndex, activeSlot, editMode, syncImages,
        product,
        rest,
        layout,
        look
    });

    const onLtClick = useSwallowEventCallback(
        () => {
            setCurrentImageIndex(prev => prev > 0 ? prev - 1 : totalImages - 1)
        },
        [ totalImages ]
    );

    const onGtClick = useSwallowEventCallback(
        () => {
            setCurrentImageIndex(prev => prev >= totalImages - 1 ? 0 : prev + 1)
        },
        [ totalImages ]
    );

    const onMouseUp = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            
            removeListener(document.body, "mousemove", onMouseMove);
            removeListener(document.body, "mouseup", onMouseUp);

            if (ref.moving) {

                dispatch(lookData.layouts.update({
                    key: ref.product.id,
                    property: null,
                    value: { ...ref.layout },
                    image: 0,//ref.activeSlotImageIndex,
                    all: false//ref.syncImages
                }));

                ref.moving = false;
                ref.startLayout = null;
                ref.layout = null;
                setLayout(null);
            }

            async(() => builder.set("slotImageMoving", false));
            //builder.set("slotImageMoving", false);
            //console.log("stop moving")
            //builder.set("activeSlot", ref.rest.slotIndex);
        },
        // eslint-disable-next-line
        []
    );

    const onMouseMove = useCallback(
        (e) => {
            let currentImage = 0,//ref.activeSlotImageIndex,
                //layoutTpl = ref.rest.layoutTemplate,
                //fullTemplate = ref.rest.fullTemplate,
                start = ref.startLayout,
                layout = { ...ref.layout },

                // how far mouse moved
                xdiff = e.clientX - ref.startX,
                ydiff = e.clientY - ref.startY,
                div = ref.currDiv,

                // background position (hpos: left/right, vpos: top/bottom)
                // (vplus: xx%, hplus: xx%)
                hpos = start.h,
                hplus = start.hplus,
                vpos = start.v,
                vplus = start.vplus, 

                // container/slot size
                w = div.offsetWidth,
                h = div.offsetHeight,

                tmpw, tmph,

                img = ref.product.images[currentImage],

                // full image size
                iw = img?.width,
                ih = img?.height,

                // image size ratio
                iratio = iw ? iw / ih : 0,

                // background-size property in percents
                // (with zoom applied)
                backSize = iratio ? 
                            getBackgroundSize(
                                Object.assign(
                                    {}, 
                                    ref.look.layouts['template'][ref.activeSlot] || {}, 
                                    layout
                                ), 
                                img, 
                                ref.look.layouts['template'],
                                w, 
                                h
                            )
                            : 0;

            if (img?.horizontal) {
                tmph = (backSize * h) / 100;
                tmpw = tmph * iratio;
            }
            else {
                tmpw = (backSize/100) * w;// / 100;
                tmph = tmpw / iratio;
            }

            const imgSize = { width: tmpw, height: tmph };
            const containerSize = { width: w, height: h };

            if (ydiff !== 0) {
                
                // if the image position is in Center
                if (vpos === "c") {
                    vpos = "t";
                    vplus = 50;
                }
                let { position, offset } = adjustPosition(vpos, vplus, ydiff, imgSize, containerSize);
                vpos = position;
                vplus = offset;
            }

            if (xdiff !== 0) {

                if (hpos === "c") {
                    hpos = "l";
                    hplus = 50;
                }
            
                let { position, offset } = adjustPosition(hpos, hplus, xdiff, imgSize, containerSize);
                hpos = position;
                hplus = offset;
            }

            layout.h = hpos;
            layout.v = vpos;
            layout.hplus = hplus;
            layout.vplus = vplus;

            // console.log("move end", { hpos, vpos, hplus, vplus })

            ref.layout = layout;
            setLayout(layout);
        },
        // eslint-disable-next-line
        []
    );

    const onMouseDown = useCallback(
        (e) => {

            //console.log("mouse down", ref.editMode)

            if (!ref.editMode) {
                return;
            }
            
            const layout = getProductLayout(
                look.layouts[product.id], 
                0//ref.activeSlotImageIndex
            );

            builder.set("slotImageMoving", true);
    
            e.preventDefault();
            e.stopPropagation();
            addListener(document.body, "mousemove", onMouseMove);
            addListener(document.body, "mouseup", onMouseUp);
            ref.currDiv = e.target;
            ref.startX = e.clientX;
            ref.startY = e.clientY;
            ref.moving = true;
            ref.startLayout = JSON.parse(JSON.stringify(layout));
            ref.layout = JSON.parse(JSON.stringify(layout));
            setLayout(ref.layout);
        },
        // eslint-disable-next-line
        [ product, onMouseMove, onMouseUp, look, ref.editMode, builder ]
    );

    const attrs = useMemo(
        () => {
            if (ref.editMode) {
                return {
                    onMouseDown
                }
            }
        },
        [ onMouseDown, ref.editMode ]
    );

    const overrides = useMemo(
        () => {
            if (layout) {
                return { layout }
            }
        },
        [ layout ]
    );

    useUpdateEffect(
        () => setCurrentImageIndex(currentImage),
        [ currentImage ]
    );

    useUpdateEffect(
        () => {
            if (activeSlot === rest.slotIndex) {
                builder.set("activeSlotCurrentImageIndex", activeSlotImageIndex || currentImageIndex || 0);
            }
        },
        [ currentImage, currentImageIndex, activeSlotImageIndex, activeSlot, rest.slotIndex ]
    );

    return (
        <ProductCard 
            attrs={ attrs }
            override={ overrides }
            className={ activeSlot === rest.slotIndex ? 
                        "active" : 
                        activeSlot !== null && activeSlot !== undefined ? 
                            "inactive" : "" }
            product={ product } 
            currentImage={ !editMode ? 
                            currentImageIndex :
                            activeSlotImageIndex || 0 } 
            { ...rest }>
            {/* !editMode && */}
                <LookCardProductActions 
                    index={ rest.slotIndex }
                    product={ product }
                    onLtClick={ onLtClick }
                    onGtClick={ onGtClick }/> 
                    
        </ProductCard>
    )
}

export default CardBuilderProduct

/*
class CardBuilderProduct extends React.Component {

    slotEl = null

    constructor(props) {
        super(props)
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.state = {
            layout: null,
            moving: false,
            currentImage: 0
        }
    }
    
    componentDidUpdate(prev) {
        if (this.props.currentImage !== prev.currentImage) {
            this.setState({ currentImage: this.props.initialImage || 0 });
        }
    }

    onMouseDown(e) {

        if (!this.props.allowMoving) {
            return;
        }

        const currentImage = this.props.currentImage;

        if (currentImage === null) {
            return;
        }

        const layout = getProductLayout(this.props.layout, currentImage);

        e.preventDefault();
        e.stopPropagation();
        addListener(document.body, "mousemove", this.onMouseMove);
        addListener(document.body, "mouseup", this.onMouseUp);
        this.currDiv = e.target;
        this.startX = e.clientX;
        this.startY = e.clientY;
        this.startLayout = JSON.parse(JSON.stringify(layout));

        this.setState({
            moving: true, 
            layout: JSON.parse(JSON.stringify(layout))
        });
    }

    onMouseUp(e) {

        e.stopPropagation();
        e.preventDefault();
        hub.dispatch("dialog", "builder-keep-dialog", null);

        removeListener(document.body, "mousemove", this.onMouseMove);
        removeListener(document.body, "mouseup", this.onMouseUp);

        if (this.state.moving) {

            const { syncImages } = this.props.editor.ui;

            //this.props.onChange(this.state.layout);
            this.props.dispatch(lookData.layouts.update({
                key: this.props.product.id,
                property: null,
                value: this.state.layout,
                image: this.props.currentImage,
                all: syncImages
            }));
            this.setState({ moving: false, layout: null });
            this.startLayout = null;
        }
    }

    onMouseMove(e) {
        let currentImage = this.props.currentImage,
            layoutTpl = this.props.layoutTemplate,
            fullTemplate = this.props.fullTemplate,
            start = this.startLayout,
            layout = this.state.layout,

            // how far mouse moved
            xdiff = e.clientX - this.startX,
            ydiff = e.clientY - this.startY,
            div = this.currDiv,

            // background position (hpos: left/right, vpos: top/bottom)
            // (vplus: xx%, hplus: xx%)
            hpos = start.h,
            hplus = start.hplus,
            vpos = start.v,
            vplus = start.vplus, 

            // container/slot size
            w = div.offsetWidth,
            h = div.offsetHeight,

            tmpw, tmph,

            img = this.props.product.images[currentImage],

            // full image size
            iw = img.width,
            ih = img.height,

            // image size ratio
            iratio = iw / ih,

            // background-size property in percents
            // (with zoom applied)
            backSize = getBackgroundSize(
                            Object.assign({}, layoutTpl, layout), 
                            img, 
                            fullTemplate);

        if (img.horizontal) {
            tmph = (backSize * h) / 100;
            tmpw = tmph * iratio;
        }
        else {
            tmpw = (backSize * w) / 100;
            tmph = tmpw / iratio;
        }

        const imgSize = { width: tmpw, height: tmph };
        const containerSize = { width: w, height: h };

        if (ydiff !== 0) {
            // if the image position is in Center
            if (vpos === "c") {
                vpos = "t";
                vplus = 50;
            }
        
            let { position, offset } = adjustPosition(vpos, vplus, ydiff, imgSize, containerSize);
            vpos = position;
            vplus = offset;
        }

        if (xdiff !== 0) {
            if (hpos === "c") {
                hpos = "l";
                hplus = 50;
            }
        
            let { position, offset } = adjustPosition(hpos, hplus, xdiff, imgSize, containerSize);
            hpos = position;
            hplus = offset;
        }

        layout.h = hpos;
        layout.v = vpos;
        layout.hplus = hplus;
        layout.vplus = vplus;

        this.setState({ layout });
    }

    onAddToMoodboard() {
        hub.dispatch("look-builder", "add-product-to-moodboard", { 
            product: { ...this.props.product },
            onClose: () => {
                this.props.dispatch(ddUi.hide("product-search-dialog"));
            }
        });
        this.props.dispatch(ddUi.show("product-search-dialog"));
    }



    getProductCardProps() {

        const   p = this.props.product,
                l = this.props.look,
              { biggestSlot = false, onEvent, currentImage, slotIndex } = this.props,
                layout = this.state.layout,
                override = {
                    layout, currentImage
                },
              { productMode = "multiple" } = l,
                actions = [ "images" ],
                layers = [ "images" ],
                attrs = {
                    id: "product-card-" + p.id,
                    onMouseDown: (e) => this.onMouseDown(e)
                },
                cls = [ this.props.cls || "" ],
                moving = this.state.moving,
                inConsultation = !!l.consultationId || !!this.props.consultationId,
                untagged = (!p.designers || p.designers.length === 0) ||
                            (!p.productStyles || p.productStyles.length === 0);

        moving && cls.push("moving");

        if (!inConsultation && untagged && (productMode === "multiple" || slotIndex === 0)) { 
            layers.push(<div className="untagged"><IconTag/></div>);
        }
        layers.push(<div className="layer-overlay"/>);

        if (productMode !== "single" || biggestSlot) {
            actions.push({
                title: "Delete product",
                cls: "action-delete",
                icon: <IconDelete/>,
                onClick: () => onEvent("product-delete")
            });
            actions.push({
                title: "Edit details",
                cls: "action-edit",
                icon: <IconEdit/>,
                onClick: () => onEvent("product-edit", { tab: "images" })
            });
            (!inConsultation && untagged) && actions.push({
                title: "Edit tags",
                cls: "action-tags",
                icon: <IconTag/>,
                onClick: () => onEvent("product-edit", { tab: "product" })
            });
            actions.push({
                title: "Add to Moodboard",
                cls: "action-moodboard",
                icon: <IconHeart/>,
                onClick: () => this.onAddToMoodboard()
            });
            actions.push({
                title: "Replace product",
                cls: "action-change",
                icon: <IconChange/>,
                onClick: () => onEvent("product-replace")
            });
        }

        return {
            cls: cls.join(" "),
            attrs, actions, layers, override
        };
    }

    render() {
        const props = Object.assign({}, this.props, this.getProductCardProps());
        return <ProductCard { ...props }/>
    }
}

export default connect(state => ({ editor: state.lookEditor }))(CardBuilderProduct)


*/