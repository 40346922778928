
import { useState, useMemo, useContext, useCallback, useEffect } from "react"
import { useOn } from "@kuindji/observable-react"

import Button from "common/src/refactor/components/button/Button"
import LookCard from "common/src/refactor/components/look/Card"
import LookProductReaction from "common/src/refactor/components/look/ProductReaction"
import LookProductRetailer from "common/src/refactor/components/look/ProductRetailer"
import CatalogueProductDrawer from "common/src/refactor/components/catalogue/product/Drawer"


import ChatContext from "common/src/lib/chat/ChatContext"
import swallow from "common/src/lib/dom/swallowEvent"


function findClickedProductId(e) {
    let el = e.target;
    while (el && el !== document.body) {
        if ('productId' in el.dataset) {
            return el.dataset.productId; 
        }
        el = el.parentNode;
    }
    return null;
};

function MessageLook({ message }) {

    const chat = useContext(ChatContext);
    const [ cacheCounter, setCacheCounter ] = useState(0);
    const look = useMemo(
        () => {
            if (!message.lookId) {
                return null;
            }
            return chat.getLookCache().find(l => l.id === message.lookId)
        }, 
        [ cacheCounter ]
    );

    const onProductClick = useCallback(
        (product) => {
            CatalogueProductDrawer.show({ product });
        },
        []
    );

    const productProps = useCallback(
        (product, look) => {
            const props = {
                children: (
                    <>
                        <LookProductRetailer product={ product }/>
                        <LookProductReaction product={ product }/>
                    </>
                    
                )
            }
            
            if (look.productMode === "tagged") {
                props.onClick = onProductClick;
            }

            return props;
        },
        [ onProductClick ]
    );

    useOn(chat, "cache", setCacheCounter);

    if (!look) {
        return null;
    }

    return (
        <div className="chat-message-look">
            <LookCard look={ look } productProps={ productProps } showTags={ false }/>
            <a className="chat-message-look-href" href={`/styling/look/${ look.id }`}/>
            <p>{ look.title }</p>
        </div>
    )

}

export default MessageLook