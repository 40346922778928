
function normalizeChatMessage(cm) {

    if (!cm.__normalized) {

        if (cm.images) {
            cm.images = cm.images.map(i => {
                i.image = i.image ? JSON.parse(i.image) : null;
                return i;
            });
        }

        if (cm.catalogueProducts) {
            cm.catalogueProductIds = cm.catalogueProducts.map(cp => cp.catalogueProductId);
            cm.catalogueProducts = [];
        }

        if (cm.replySource) {
            cm.replySource = normalizeChatMessage(cm.replySource);
        }
    }

    cm.__normalized = true;
    return cm;
}

export default normalizeChatMessage