
import auth from "./auth";
import dom from "./dom";
import overlay from "./overlay";
import api from "./api"
import cookies from "./cookies"

export default {
    init: () => {
        cookies.init();
        auth.init();
        dom.init();
        overlay.init();
        api.init();
    }
}