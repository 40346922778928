import React, { useRef, useState, useCallback, useMemo, useEffect } from "react"
import hub from "common/src/hub"
import Observable from "@kuindji/observable"
import { useOn } from "@kuindji/observable-react"

import animation from "common/src/lib/animation"
import { Button, TextField, TextareaAutosize } from "@mui/material"
import Loader from "common/src/components/Loader"
import { ReactComponent as IconClose } from "common/src/svg/close.svg"

import async from "common/src/lib/js/async"

import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import useClassEffect from "common/src/hooks/useClassEffect"
import NullForm from "common/src/components/NullForm"

let id = 0;

function getClosePromise(dlgId) {
    return new Promise(function(resolve, reject) {
        const listener = function({ id, action, data }) {
            if (id === dlgId) {
                hub.remove("dialog", "alertAction", listener);
                action === "ok" ? resolve(data) : reject(action);
            }
        }
    
        hub.listen("dialog", "alertAction", listener);
    })
}


class AlertPool extends Observable {

    pool = []
    fields = {}
    fieldChangeCounter = 0
    
    setFieldValue(name, value) {
        this.fields[name] = value;
        this.fieldChangeCounter++;
        this.trigger("fieldChange", name, value);
    }

    getFieldValue(name) {
        return this.fields[name] || null;
    }

    setFields(values) {
        this.fields = { ...values };
        this.fieldChangeCounter++;
        this.trigger("fieldChange");
    }

    getFields() {
        return { ...this.fields };
    }

    getLength() {
        return this.pool.length;
    }

    add(cfg) {
        cfg.id = ++id;
        const prevLength = this.pool.length;

        if (!("modal" in cfg)) {
            cfg.modal = true;
        }

        if (!cfg.cls) {
            cfg.cls = "dialog-alert-v2";
        }
        if (!cfg.anim) {
            cfg.anim = ["a-zoom-in", "a-fadeout"];
        }
        
        this.pool.push(cfg);
        hub.dispatch("dialog", "alertPoolChange", this.pool.length);
        this.trigger("change", this.getCurrent(), this.pool.length, prevLength);

        return cfg.id;
    }

    alert(cfg) {
        cfg.type = "alert";
        return this.add(cfg);
    }

    confirm(cfg) {
        cfg.type = "confirm";
        return this.add(cfg);
    }

    prompt(cfg = {}) {
        cfg.type = "prompt";
        return this.add(cfg);
    }

    loading(cfg = {}) {
        cfg.type = "loading";
        return this.add(cfg);
    }

    getCurrent() {
        return this.pool[0] || null;
    }

    removeCurrent(action, data = {}) {
        const   current = this.getCurrent(),
                id = current.id,
                inx = this.pool.indexOf(current),
                prevLength = this.pool.length;

        if (inx !== -1) {
            this.pool.splice(inx, 1);
            this.trigger("change", this.getCurrent(), this.pool.length, prevLength);
        }

        current.prompt = data.prompt || current.defaultValue;
        current.fields = { ...this.fields };
        this.last = { ...current };
        this.fields = {};
        this.fieldChangeCounter++;

        hub.dispatch("dialog", "alertPoolChange", this.pool.length);
        hub.dispatch("dialog", "alertAction", { id, action, data: current });
    }

    dismiss(action = "ok", data) {

        if (this.getCurrent()) {
            this.removeCurrent(action, data);
        }

        return Promise.resolve();
    }

    getCurrent() {
        let pool = this.pool;
        return pool.length > 0 ? pool[0] : null;
    }

};

const alertPool = new AlertPool();


function Alert() {

    const mainRef = useRef(null);
    const okRef = useRef(null);
    const cancelRef = useRef(null);
    const inputRef = useRef(null);
    const animRef = useRef(null);
    const [ active, setActive ] = useState(false);
    const [ current, setCurrent ] = useState(null);
    const [ prompt, setPrompt ] = useState("");
    const [ resetFields, setResetFields ] = useState(0);

    const { type, 
            title = null, message = null, html = null, 
            multiline = false, closeable = false,
            okText = "Ok", cancelText = "Cancel",
            label = null, placeholder = null } = useMemo(
        () => {
            const html = current?.html ? { __html: current.html } : null;
            return { ...current, html };
        },
        [ current ]
    );

    const notOk = useMemo(
        () => type === "prompt" && !((prompt || "").trim()),
        [ type, prompt ]
    );

    const form = useMemo(
        () => {
            const form = current?.form;
            if (form) {
                if (typeof form === "string") {
                    return <div className="dialog-prompt-form" 
                                dangerouslySetInnerHTML={{ __html: form }}/>
                }
                if (typeof form === "function") {
                    return <div className="dialog-prompt-form" 
                                children={ form() }></div>
                }
                if (typeof form !== "string" && typeof form !== "function") {
                    return <div className="dialog-prompt-form" 
                                children={ form }></div>
                }
            }
            return null;
        },
        [ current?.form, resetFields ]
    );

    const onPromptChange = useCallback(
        e => setPrompt(e.target.value),
        [ setPrompt ]
    );

    const setFocus = useCallback(
        () => {
            const current = alertPool.getCurrent();
            if (current) {
                async(() => {
                    if (current.type === "alert" || current.type === "confirm") {
                        okRef.current.focus();
                    }
                    else if (current.type === "prompt") {
                        inputRef.current.focus();
                    }
                })
            }
        },
        []
    );

    const animate = useCallback(
        (show) => {
            const anim = (alertPool.getCurrent() || {}).anim || ["a-fadein", "a-fadeout"];
            return animRef.current = animation(mainRef.current, show ? anim[0] : anim[1], {
                start: () => {
                    if (show)  {
                        mainRef.current && mainRef.current.classList.add("active");
                        setActive(true);
                    }
                },
                end: () => {
                    if (!show) {
                        mainRef.current && mainRef.current.classList.remove("active");
                        setActive(false);
                    }
                    setFocus();
                    animRef.current = null;
                }
            });
        },
        []
    );


    const onButton = useCallback(
        (btn) => {
            if (!current) {
                return;
            }
    
            const cb = current[btn];
            const dismissData = {
                prompt: prompt || current.defaultValue
            };
    
            if (cb) {
                const cbData = {
                    ...current,
                    prompt: prompt || current.defaultValue,
                    fields: alertPool.getFields()
                };
                const res = cb(cbData, btn, alertPool);
                if (res === false) {
                    return;
                }
                else if (res && res.then) {
                    res.then(() => alertPool.dismiss(btn, dismissData));
                }
                else {
                    alertPool.dismiss(btn, dismissData);
                }
            }
            else {
                alertPool.dismiss(btn, dismissData);
            }
        },
        [ current, prompt ]
    );


    const onPromptKeyDown = useCallback(
        e => e.key === "Enter" && !multiline && !notOk && onButton("ok"),
        [ multiline, notOk, onButton ]
    );

    const onCloseClick = useSwallowEventCallback(
        () => onButton("close"),
        [ onButton ]
    );

    const onOkClick = useCallback(
        () => onButton("ok"),
        [ onButton ]
    );

    const onCancelClick = useCallback(
        () => onButton("cancel"),
        [ onButton ]
    );

    const onPoolChange = useCallback(
        (current, length, prevLength) => {
            
            setPrompt(current?.defaultValue || "");
            setCurrent(current);

            if (length === 0) {
                animate(false);
            }
            else if (prevLength === 0) {
                if (animRef.current) {
                    animRef.current.then(() => animate(true));
                }
                else animate(true);
            }
            else {
                setFocus();
            }
        },
        []
    );

    const onFieldsChange = useCallback(
        () => setResetFields(alertPool.fieldChangeCounter),
        []
    );

    useOn(alertPool, "change", onPoolChange);
    useOn(alertPool, "fieldChange", onFieldsChange);

    useClassEffect(mainRef, [ 
        "dialog", "dialog-alert", "theme-light", "centered", current?.cls,
        active ? "active" : null ]);


    if (type === "loading") {
        return (
            <div ref={ mainRef } data-cy="dialog-alert">
                <Loader size={ 32 }/>
            </div>
        )
    }

    return (
        <div    id="dialog-alert" 
                data-cy="dialog-alert"
                ref={ mainRef }>
            { title && 
                <h3>
                    <span>{ title }</span>
                    <a href="/#" onClick={ onCloseClick }><IconClose/></a>
                </h3> }
            { (!title && closeable) && 
                <h3>
                    <span>{ title }</span>
                    <a href="/#" onClick={ onCloseClick }><IconClose/></a>
                </h3>}
            { message && <p className="message">{ message }</p> }
            { html && <p className="message" dangerouslySetInnerHTML={ html }/> }
            { type === "prompt" && 
                <NullForm className="dialog-prompt-field">
                    { multiline ? 
                        <TextareaAutosize
                            id="dialog-alert-prompt"
                            inputProps={{ "data-cy": "dialog-alert-prompt" }}
                            rowsMax={ 10 }
                            label={ label }
                            placeholder={ placeholder }
                            value={ prompt }
                            onKeyDown={ onPromptKeyDown }
                            onChange={ onPromptChange }/> :
                        <TextField
                            id="dialog-alert-prompt"
                            inputProps={{ "data-cy": "dialog-alert-prompt" }}
                            inputRef={ inputRef }
                            variant="outlined"
                            autoComplete="off"
                            label={ label }
                            placeholder={ placeholder }
                            value={ prompt }
                            onKeyDown={ onPromptKeyDown }
                            onChange={ onPromptChange }
                            fullWidth/> }
                </NullForm>
            }
            { form }
            <div className="actions">
                <Button 
                    data-cy="dialog-alert-ok"
                    className="dialog-alert-ok"
                    ref={ okRef }
                    onClick={ onOkClick }
                    color="primary"
                    disabled={ notOk }
                    variant="contained"
                    children={ okText }/>
                { (type === "confirm" || type === "prompt") && 
                    <Button 
                        data-cy="dialog-alert-cancel"
                        className="dialog-alert-cancel"
                        ref={ cancelRef }
                        onClick={ onCancelClick }
                        color="primary"
                        variant="text"
                        children={ cancelText }/> }
            </div>
        </div>
    )
}

Alert.self = () => {
    return alertPool;
};

export default Alert;
export const alert = cfg => getClosePromise(alertPool.alert(cfg));
export const confirm = cfg => getClosePromise(alertPool.confirm(cfg));
export const prompt = cfg => getClosePromise(alertPool.prompt(cfg));
export const loading = cfg => getClosePromise(alertPool.loading(cfg));
export const current = () => alertPool.getCurrent();
export const isActive = () => !!alertPool.getCurrent();

window.tfAlert = {
    alert, confirm, prompt, loading, current, isActive
};