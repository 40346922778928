import { Fragment, useCallback, useContext, useMemo, useState, useEffect } from "react"
import moment from "moment"
import { Tooltip, App as AntApp } from "antd"

import { ReactComponent as IconTick } from "common/src/svg/tick.svg"
import { ReactComponent as IconTickDouble } from "common/src/svg/tick-double.svg"
import { ReactComponent as IconReply } from "common/src/svg/reply.svg"
import { ReactComponent as IconReaction } from "common/src/svg/reaction-add.svg"
import { ReactComponent as IconDelete } from "common/src/svg/delete.svg"

import EmojiPicker from "common/src/components/EmojiPicker"

import Loader from "common/src/components/Loader"
import UserAvatar from "common/src/refactor/components/UserAvatar"
import MessageText from "common/src/refactor/components/chat_v3/messages/MessageText"
import Reaction from "./Reaction"
import Catalogue from "./Catalogue"
import Images from "./Images"
import VoiceMessage from "./VoiceMessage"
import Consultation from "./Consultation"
import Moodboard from "./Moodboard"
import Look from "./Look"
import LookProduct from "./LookProduct"
import Reply from "./Reply"
import Uploading from "./Uploading"

import { UPLOADING_NONE } from "common/src/lib/chat/Chat"
import { setMessageReaction, removeMessageReaction } from "common/src/actions/chat"
import user from "common/src/user"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import ChatContext from "common/src/lib/chat/ChatContext"

import api from "common/src/api"


function MessageInfo({ message, onToggleReactions }) {

    const { modal, message: ntfmessage } = AntApp.useApp();
    const chat = useContext(ChatContext);
    const contact = chat?.getContact();
    const isConcierge = contact?.role === "concierge";

    const onDeleteClick = useSwallowEventCallback(
        () => {
            modal.confirm({
                closable: true,
                icon: null,
                cancelButtonProps: {
                    type: "text"
                },
                content: "Are you sure you want to delete this message?",
                onOk: async () => {
                    await api.chatMessage.remove(message.id);
                    chat.removeMessage(message.id);
                    ntfmessage.success({ content: "Message was deleted" });
                }
            })
        },
        [ message, chat ]
    );

    const onReplyClick = useSwallowEventCallback(
        () => {
            chat.clearAttachments("reply");
            chat.addAttachment("reply", message, message.id);
        },
        [ message, chat ]
    );

    const { isMine, date, read, sending, hasMyReaction } = useMemo(
        () => {
            let read, readOrSent, sending = null;
            const isMine = message.userId === user.id();
            const date = moment(message.createdAt).format("D MMM");
            const hasMyReaction = !!message.reactions.find(r => r.userId === user.id());

            if (isMine) {
                read = message.notifications[0]?.read || false;
                readOrSent = !!message.notifications?.find(m => m.sent || m.read) || false;
                sending = message.uploading !== 0;// || false;
            }

            return { isMine, date, read, readOrSent, sending, hasMyReaction };
        },
        [ message ]
    );

    return (

        <Fragment>
            { sending && <Loader/> }
            { (isMine && !sending) && 
                <span className="chat-message-info-sent">
                    { read ? <IconTickDouble/> : <IconTick/> }
                </span> }

            { !isConcierge && 
            <div className="chat-message-info">
                <Tooltip title="Reply">
                    <a href="/#" onClick={ onReplyClick }><IconReply/></a>
                </Tooltip>
                { !hasMyReaction && 
                    <Tooltip title="React">
                        <a href="/#" onClick={ onToggleReactions }><IconReaction/></a>
                    </Tooltip> }
                { (isMine && !read) && 
                    <Tooltip title="Delete">
                        <a href="/#" onClick={ onDeleteClick }><IconDelete/></a>
                    </Tooltip> }
                <span className="chat-message-info-date">{ date }</span>
            </div> }
            
        </Fragment>
    )
}

function Message({ message, showInfo = true, showReply = true }) {

    const [ showReactions, setShowReactions ] = useState(false);
    const [ reactionsLoading, setReactionsLoading ] = useState(false);
    const hasMyReaction = !!message.reactions.find(r => r.userId === user.id());

    const chat = useContext(ChatContext);
    const isMine = useMemo(() => message.userId === user.id(), [ message ]);
    const hasAttachments = useMemo(() => !!message.lookId || !!message.productId || !!message.consultationId || !!message.moodboardId || message.images.length > 0 || message.voiceMessages.length > 0, [ message ]);
    const cls = useMemo(
        () =>   [ 
                    "chat-message", 
                    isMine ? "chat-message-mine" : "chat-message-other",
                    showReactions ? "chat-message-with-reactions" : ""
                ]
                .filter(c => !!c)
                .join(" "),
        [ isMine, showReactions ]
    );

    const time = useMemo(
        () => {
            return moment(message.createdAt).format("HH:mm")
        },
        [ message ]
    )

    const toggleReactions = useSwallowEventCallback(
        () => setShowReactions(prev => !prev),
        []
    );

    const onEmojiPickerClose = useCallback(
        () => setShowReactions(false),
        [ setShowReactions ]
    );

    const onEmojiSelect = useCallback(
        async (reaction) => {
            setReactionsLoading(true);
            setShowReactions(false);
            await setMessageReaction(chat.getId(), message.id, reaction);
        },
        [ message.id ]
    );

    const onReactionDelete = useCallback(
        async () => {
            setReactionsLoading(true);
            setShowReactions(false);
            await removeMessageReaction(chat.getId(), message.id);
        },
        [ message.id ]
    );

    useEffect(
        () => setReactionsLoading(false),
        [ message ]
    );

    if (message.hidden) {
        return null;
    }

    return (
        <div className={ cls } id={ "chat-message-" + message.id }>
            <UserAvatar user={ message.user } size="small"/>

            <div className="chat-message-content">

                { (!!message.replySource && showReply) &&
                    <Reply message={ message }/> }
                { !!message.lookId && 
                    <Look message={ message }/> }
                { !!message.productId && 
                    <LookProduct message={ message }/> }

                { message.images.length > 0 && 
                    <Images message={ message }/> }
                { (message.voiceMessages.length > 0 && 
                    message.uploading === UPLOADING_NONE) && 
                    <VoiceMessage message={ message }/> }

                { (!!message.moodboardId && message.uploading === UPLOADING_NONE) && 
                    <Moodboard message={ message }/> }
                { (message.catalogueProductIds.length > 0 && 
                    message.uploading === UPLOADING_NONE) && 
                    <Catalogue message={ message }/> }

                { (!!message.consultationId && !message.productId && message.uploading === UPLOADING_NONE) &&
                    <Consultation message={ message }/>
                }
                
                { (message.uploading !== UPLOADING_NONE && 
                    message.images.length === 0 &&
                    message.voiceMessages.length === 0 ) &&
                    <Uploading message={ message }/>}

                { message.message && 
                    <MessageText 
                        text={ message.message } 
                        messageId={ message.id }
                        hasAttachments={ hasAttachments }
                        /> }
                { (message.reactions.length > 0 || reactionsLoading) && 
                    <Reaction
                        loading={ reactionsLoading }
                        reactions={ message.reactions } 
                        onClick={ toggleReactions }/>}
                
                { message.voiceMessages.length === 0 &&   
                    <span className="chat-message-content-time">
                        { time }
                    </span> 
                }
            </div>

            { showInfo && 
                <MessageInfo 
                    message={ message }
                    onToggleReactions={ toggleReactions }/> }
            
            { showReactions && 
                <EmojiPicker 
                    className="chat-message-emoji-picker" 
                    closeable
                    deletable={ hasMyReaction }
                    onDelete={ onReactionDelete }
                    onSelect={ onEmojiSelect }
                    onClose={ onEmojiPickerClose }/> }
        </div>
    )
}

export default Message