import { useSelector } from "react-redux";
import connectionSelector from "common/src/selectors/connections"
import { useMemo } from "react";


function useConnections() {
    const storeConnections = useSelector(connectionSelector);

    const connections = useMemo(() => {
        return storeConnections.filter(c => c.role !== "concierge");
    }, [ storeConnections ]);

    return connections;
}

export default useConnections;