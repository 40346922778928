import { useCallback, useContext, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"

import Button from "common/src/refactor/components/button/Button"
import LookCard from "common/src/refactor/components/look/Card"
import CardBuilderProduct from "./CardBuilderProduct"
import LookBuilderSearch from "./Search"
import Dnd from "common/src/refactor/components/Dnd"

import { ReactComponent as IconPlus } from "common/src/svg/add.svg"

import { data } from "common/src/store/look/editor"
import { getLayoutClass, getSlotClass } from "common/src/lib/look/layout2style"
import LookBuilderContext from "./LookBuilderContext"
import useKey from "common/src/refactor/hooks/useKey"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import useDictRef from "common/src/hooks/useDictRef"

function EmptySlot({ style, index, layout, template }) {

    const dispatch = useDispatch();
    const builder = useContext(LookBuilderContext);
    const look = useSelector(s => s.lookEditor.look);
    const { productMode = "multiple" } = look;
    const activeSlot = useKey("activeSlot", builder);
    const isActive = activeSlot === index;
    const showAdd = productMode === "multiple" || index === 0;
    //const editMode = activeSlot !== null && activeSlot !== undefined;
    const ref = useDictRef({ look })

    const cls = useMemo(
        () => {
            const slotClass = getSlotClass(index, template);
            const base = "look-card-product";
            
            return [ 
                "look-editor-empty-slot", 
                getLayoutClass(template, layout),
                ...(slotClass.map(c => base +"-"+ c)),
                isActive ? "active" : 
                    activeSlot !== null ? "inactive" : "" ].join(" ")
        },
        [ template, layout, isActive, activeSlot, index ]
    );


    const onProductMove = useCallback(
        (to, what) => {
            const pid = what.id, 
                    fromIndex = what.index, 
                    toInx = to.index,
                    look = ref.look,
                    product = look.products.find(p => p.id === pid),
                    mode = look.productMode || "multiple";
                
            if (mode === "multiple") {
                dispatch(data.products.setToLayout({ id: pid, toInx }));
                builder.set("activeSlot", to.index);
            }
            else {
                const src = product.images[fromIndex].src;
                dispatch(data.products.moveImage({ id: pid, src, toInx }));
            }
        },
        // eslint-disable-next-line
        [ builder ]
    );

    const { Cmp, attrs } = useMemo(
        () => {
            //console.log("empty slot", editMode)
            //if (!isActive) {
                return {
                    Cmp: Dnd,
                    attrs: {
                        droppable: {
                            cardSelector: ".look-card-product",
                            drop: onProductMove,
                            //drop: (to, what) => this.onProductMove(what.id, what.index, to.index),
                            data: {
                                index: index
                            }
                        }
                    }
                }
            /*}
            else {
                return {
                    Cmp: "div",
                    attrs: {}
                }
            }*/
        },
        [  index, onProductMove ]
    );

    /*const onProductMove = useCallback(
        () => {},
        []
    );

    const droppable = useMemo(
        () => activeSlot !== null ? 
                null :
                {
                    cardSelector: ".look-editor-empty-slot",
                    drop: (to, what) => onProductMove(what.id, what.index, to.index),
                    data: { index }
                },
        [ activeSlot, onProductMove ]
    );*/

    const onAddClick = useSwallowEventCallback(
        () => {
            builder.set("activeSlot", index);
            builder.openPage("catalogue");
            builder.set("currentModal", LookBuilderSearch.NAME);
        },
        [ index ]
    );

    return (
        <Cmp { ...attrs } style={ style } className={ cls }>
            { showAdd && 
                <Button 
                    Icon={ IconPlus } 
                    type="primary" 
                    shape="circle" 
                    size="medium" 
                    onClick={ onAddClick }/> }
        </Cmp>
    )
}


function LookCardBuilder() {
    
    const look = useSelector(s => s.lookEditor.look);

    const productProps = useCallback(
        (product, look, props) => {
            return {
                productCmp: CardBuilderProduct
            }
        },
        []
    );

    const renderEmpty = useCallback(
        (props) => (<EmptySlot { ...props }/>),
        []
    );

    const onCardClick = useCallback(
        (e) => {
            e.stopPropagation();
        },
        []
    );

    const attrs = useMemo(
        () => ({ onClick: onCardClick }),
        [ onCardClick ]
    );

    return (
        <LookCard 
            attrs={ attrs }
            empty={ renderEmpty }
            productCmp={ CardBuilderProduct }
            look={ look } 
            productProps={ productProps }/>
    )
}

export default LookCardBuilder
