import { useSelector } from "react-redux"
import moment from "moment"
import { Tooltip } from "antd"


import { ReactComponent as IconTick } from "common/src/svg/tick.svg"
import { ReactComponent as IconInst } from "common/src/svg/inst_rounded.svg"
import { ReactComponent as IconFacebook } from "common/src/svg/facebook_rounded.svg"
import { ReactComponent as IconX } from "common/src/svg/x_rounded.svg"

import VerifyEmailDialog from "./VerifyEmailDialog"
import VerifyPhoneDialog from "./VerifyPhoneDialog"

import df from "common/src/lib/date/formats"
import userSelector from "common/src/selectors/user/current"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"


function ProfileDetails() {

    const current = useSelector(userSelector);
    const {
        givenName,
        familyName,
        emailVerified,
        phoneVerified,
        email,
        phone,
        createdAt,
        twitter,
        instagram,
        website,
        facebook,
    } = current;
    const canVerify = phone && phone.indexOf("+1") !== 0;  

    const onVerifyEmailClick = useSwallowEventCallback(
        () => {
            VerifyEmailDialog.show();
        },
        []
    );

    const onVerifyPhoneClick = useSwallowEventCallback(
        () => {
            VerifyPhoneDialog.show();
        },
        []
    )

    return (
        <div className="user-profile-details">
            <div className="user-profile-section">
                <label className="user-profile-title">Your Details</label>
                <p>
                    {givenName} {familyName}
                </p>

                <p>
                    {email}
                    {emailVerified ? (
                        <Tooltip title="Email verified">
                            <span className="user-profile-verified">
                                <IconTick />
                            </span>
                        </Tooltip>
                    ) : (
                        <a href="/#" onClick={onVerifyEmailClick}>
                            Verify email
                        </a>
                    )}
                </p>

                {!!phone && (
                    <p>
                        {phone}
                        {canVerify ? (
                            phoneVerified ? (
                                <Tooltip title="Phone number verified">
                                    <span className="user-profile-verified">
                                        <IconTick />
                                    </span>
                                </Tooltip>
                            ) : (
                                <a href="/#" onClick={onVerifyPhoneClick}>
                                    Verify phone
                                </a>
                            )
                        ) : null}
                    </p>
                )}
            </div>

            <div className="user-profile-section">
                <label className="user-profile-title">Signed up</label>
                <p>{moment(createdAt).format(df.full)}</p>
            </div>

            <div className="user-profile-section">
                <label className="user-profile-title">Social Links</label>
                { instagram ? (
                    <p>
                        <IconInst /> { instagram }
                    </p>
                ) : null }
                { twitter ? (
                    <p>
                        <IconX /> { twitter }
                    </p>
                ) : null }
                { facebook ? (
                    <p>
                        <IconFacebook /> { facebook }
                    </p>
                ) : null }
            </div>

            { website ? (
                <div className="user-profile-section">
                    <label className="user-profile-title">Website</label>
                    <p>
                        { website }
                    </p>
                </div>
            ) : null }
            <VerifyEmailDialog />
            <VerifyPhoneDialog />
        </div>
    );

}

export default ProfileDetails