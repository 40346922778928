
import { useMemo } from "react"
import { Dropdown } from "antd"

import Button from "common/src/refactor/components/button/Button"

import { ReactComponent as IconSend } from "common/src/svg/send.svg"
import { ReactComponent as IconBookmark } from "common/src/svg/bookmark.svg"
//import { ReactComponent as IconLink } from "common/src/svg/link.svg"
import { ReactComponent as IconDelete } from "common/src/svg/delete.svg"
import { ReactComponent as IconDots } from "common/src/svg/dots.svg"

import useProductActions from "app/hooks/product/useProductActions"

function ProductContextMenu({ 
    product, 
    menu: replaceMenu, 
    extraMenuItems, 
    showAddToMoodboard = true,
    showRemoveFromMoodboard = false,
    showSend = true,
    showTrackableLink = true,
    moodboardId,
    ...rest }) {

    const {
        onSendClick,
        onAddToMoodboardClick,
        onDeleteProductClick,
        //onTrackableLinkClick,
        loading
    } = useProductActions({ product, moodboardId });


    const menu = useMemo(
        () => {

            if (replaceMenu) {
                return replaceMenu;
            }

            const items = [

                (showAddToMoodboard && onAddToMoodboardClick) ? {
                    key: "moodboard",
                    icon: <IconBookmark className="icon"/>,
                    label: "Add to moodboard",
                    onClick: onAddToMoodboardClick
                } : null,

                (showSend && onSendClick) ? {
                    key: "send",
                    icon: <IconSend className="icon"/>,
                    label: "Send",
                    onClick: onSendClick
                } : null,

                (showRemoveFromMoodboard && onDeleteProductClick) ?  {
                    key: "delete",
                    icon: <IconDelete className="icon"/>,
                    label: "Remove",
                    onClick: onDeleteProductClick
                } : null,
                
                // showTrackableLink ? {
                //     key: "link",
                //     icon: <IconLink className="icon"/>,
                //     label: "Trackable link",
                //     onClick: onTrackableLinkClick
                // } : null,

                ...(extraMenuItems || [])

            ].filter(m => !!m);

            return { items };
        },
        [ extraMenuItems, replaceMenu,
            onSendClick, onAddToMoodboardClick,
            showSend, showAddToMoodboard, 
            showRemoveFromMoodboard, onDeleteProductClick ]
    );
    
    if (menu.length === 0) {
        return null;
    }

    return (
        <Dropdown menu={ menu } trigger="click" rootClassName="context-menu">
            <Button 
                className="context-button"
                loading={ loading }
                type="primary" 
                shape="circle"
                size="small"
                Icon={ IconDots }
                { ...rest }/>
        </Dropdown>
    )
}

export default ProductContextMenu