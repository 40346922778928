import useUpdateEffect from "common/src/hooks/useUpdateEffect"
import { useState } from "react"


function useStateEffect(initial, deps) {

    const [ value, setValue ] = useState(initial);

    useUpdateEffect(
        () => setValue(initial),
        deps
    );

    return [ value, setValue ];
}

export default useStateEffect