import api from "app/api"
import hub from "common/src/hub"

async function onUserAnalyticsChange(data) {
    if (Object.keys(data).length > 0) {
        api.backend.post("/me/update-analytics", {
            body: data
        })
    }
}

const listeners = {
    init: () => {

        !hub.hasListener("user-analytics", "change", onUserAnalyticsChange) && 
            hub.listen("user-analytics", "change", onUserAnalyticsChange);
        
    }
}

export default listeners