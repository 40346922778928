import { Modal, Spin } from "antd"
import { useCallback, useMemo, useRef } from "react"
import { useSelector } from "react-redux"

import Button from "../button/Button"
import ProductCard from "common/src/refactor/components/catalogue/product/Card"
import CatalogueProductSelect from "common/src/refactor/components/catalogue/product/Select"
import EmptyMessage from "common/src/refactor/components/EmptyMessage"
import FeedDialogToolbar from "./FeedDialogToolbar"
import { ReactComponent as IconUp } from "common/src/svg/angle-up.svg"

import { CatalogueFilterContextProvider, createCatalogueFilterContext } 
    from "common/src/refactor/components/catalogue/Context"


import store from "app/store"
import { ui } from "common/src/store/dialogs"
import useProductCatalogue from "common/src/refactor/hooks/useProductCatalogue"
import useContextData from "common/src/refactor/hooks/useContextData"
import useDictRef from "common/src/hooks/useDictRef"
import useSelection from "common/src/refactor/hooks/useSelection"
import BackToTop from "./BackToTop"

const footerProps = {
    addNameSpacing: true
}

const selectionPromise = {};

const DIALOG_NAME = "feed-dialog";

function isRetailerSearch(query, retailer) {
    return query ? query.indexOf(retailer) !== -1 : false
}


export function Feed({ dialogName }) {

    const filterContext = useMemo(() => createCatalogueFilterContext(), []);
    const filters = useContextData(filterContext);
    const { selection, map, add, remove } = useSelection({
        mapFn: p => p.uid,
        comparisonFn: (a, b) => a.uid === b.uid
    });
    const selectionCnt = useMemo(() => Object.keys(selection).length, [ selection ]);
    const bodyRef = useRef(null);

    const { products, loadMore, hasMore, loading, loaded } = 
        useProductCatalogue({ filters, setName: "feed-dialog" });

    const ref = useDictRef({ hasMore, loading, loadMore });

    const onClose = useCallback(
        () => FeedDialog.hide(dialogName),
        [ dialogName ]
    );

    const onSelectionChange = useCallback(
        (id, selected) => {
            const product = products.find(p => p.uid === id);
            selected ? add(product) : remove(product);
        },
        [ selection, products ]
    );

    const onScroll = useCallback(
        (e) => {
            const el = e.target;
            if (ref.hasMore && !ref.loading) {
                if (el.scrollTop / (el.scrollHeight - el.offsetHeight) > 0.75) {
                    ref.loadMore();
                }
            }
        },
        // eslint-disable-next-line
        []
    );

    const onSelectClick = useCallback(
        () => {
            if (selectionPromise[dialogName]) {
                selectionPromise[dialogName](selection);
            }

            FeedDialog.hide(dialogName);
        },
        [ selection, products, dialogName ]
    );

    return (
        <CatalogueFilterContextProvider value={ filterContext }>
            <FeedDialogToolbar 
                dialogName={ dialogName } 
                onClose={ dialogName ? onClose : null }/>

            <div className="feed-dialog-body" onScroll={ onScroll }>
                { (loaded && !loading && products.length === 0 && !isRetailerSearch(filters.query, "bottegaveneta")) && <EmptyMessage size="small" message="No products matched your search"/> }
                { (loaded && !loading && products.length === 0 && isRetailerSearch(filters.query, "bottegaveneta")) && <EmptyMessage size="small" message="Search via URL is currently not available from Bottega Veneta website. Please use text search or filter by retailer."/> }
                <div className="feed-dialog-products">
                    { products.map(p => (
                        <ProductCard 
                            key={ p.uid } 
                            product={ p } 
                            footer={ footerProps }
                            children={ 
                                <CatalogueProductSelect 
                                    id={ p.uid }
                                    selected={ map[p.uid] || false }
                                    onChange={ onSelectionChange }/> 
                            }/>))}
                </div>
                { (hasMore || !loaded) && <Spin spinning className="infinite-scroll-spinner"/> }
            </div>

            <div className="feed-dialog-footer">
                <Button 
                    type="primary" 
                    text={ `Select (${ selectionCnt })` }
                    onClick={ onSelectClick }
                    disabled={ selectionCnt === 0 }/>
                <BackToTop elemRef={ bodyRef }>
                    <Button
                        type="text"
                        size="medium"
                        text="Back to top"
                        variant="link"
                        Icon={ IconUp }
                        iconPosition="after"
                    />
                </BackToTop>
            </div>
        </CatalogueFilterContextProvider>
    )

}

function FeedDialog({ dialogName = DIALOG_NAME }) {

    const open = useSelector(s => s.dialogs[dialogName] || false);

    const onClose = useCallback(
        () => {
            FeedDialog.hide(dialogName)
        },
        [ dialogName ]
    );

    return (
        <Modal 
            classNames={{ wrapper: "feed-dialog" }}
            centered    
            width="642px"
            open={ open } 
            destroyOnClose 
            closeIcon={ null }
            footer={ null }
            maskClosable={ false }
            onCancel={ onClose }>
            <Feed dialogName={ dialogName }/>
        </Modal>
    )
}

FeedDialog.show = function(name = DIALOG_NAME) {
    store.dispatch(ui.show(name));
    return new Promise((resolve) => {
        selectionPromise[name] = resolve;
    });   
}

FeedDialog.hide = function(name = DIALOG_NAME) {
    selectionPromise[name] && selectionPromise[name]([]);
    store.dispatch(ui.hide(name));
    delete selectionPromise[name];
}

export default FeedDialog