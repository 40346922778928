import { useCallback, useContext, useMemo, useState } from "react"
import { Typography } from "antd"
//import { LoadingOutlined } from "@ant-design/icons"
import { App as AntApp } from "antd"

import Button from "common/src/refactor/components/button/Button"
//import Loader from "common/src/components/Loader"
//import Spacer from "common/src/refactor/components/Spacer"
import Radio from "common/src/refactor/components/form/Radio"

import { ReactComponent as IconBack } from "common/src/svg/back.svg"
import { ReactComponent as IconRefresh } from "common/src/svg/refresh.svg"
//import { ReactComponent as IconHeart } from "common/src/svg/heart.svg"
//import { ReactComponent as IconExport } from "common/src/svg/export.svg"
import { ReactComponent as IconLink } from "common/src/svg/link.svg"
import { ReactComponent as IconOpenIn } from "common/src/svg/open_in.svg"
import { ReactComponent as IconBookmark } from "common/src/svg/bookmark.svg"
import { ReactComponent as IconClose } from "common/src/svg/close.svg"

import formatCurrency from "common/src/lib/format/currency"
import url2retailer from "common/src/lib/look/url2retailer"
import * as productEditor from "common/src/actions/look/product"

import hub from "common/src/hub"
import getShortLink from "common/src/lib/catalogue/getShortLink"
import LookBuilderContext from "../LookBuilderContext"
import useKey from "common/src/refactor/hooks/useKey"
import useSelection from "common/src/refactor/hooks/useSelection"
import s3url from "common/src/lib/image/s3url"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import useCatalogueProductUrl from "common/src/refactor/hooks/useCatalogueProductUrl"
import extractUrlFromClick from "common/src/lambdalib/extractUrlFromClick"
import useUpdateEffect from "common/src/hooks/useUpdateEffect"
import { Portal } from "common/src/refactor/components/Portal"
import Spacer from "common/src/refactor/components/Spacer"

function ProductImage({ image, selected, toggleSelection }) {

    // const cls = useMemo(
    //     () => ["card-radio-select", selected ? "active" : "" ].join(" "),
    //     [ selected ]
    // );

    const style = useMemo(
        () => {
            const url = typeof image === "string" ? 
                            image :
                            image.src ? 
                                image.src : 
                                s3url(image.key);
            return {
                backgroundImage: `url(${ url })`
            }
        },
        [ image ]
    );

    const onChange = useCallback(
        () => {
            toggleSelection(image);
        },
        [ image, toggleSelection ]
    );

    return (
        <div className="look-builder-search-product-image" style={ style } onClick={ onChange }>
            <Radio checked={ selected } size="medium" onChange={ onChange }/>
        </div>
    )
}

function LookBuilderSearchProduct({ open }) {

    const builder = useContext(LookBuilderContext);
    const product = useKey("searchProduct", builder);
    const [ scraping, setScraping ] = useState(false);
    const [ generatingLink, setGeneratingLink ] = useState(false);
    const [ link, setLink ] = useState(null);
    const { selection: selectedImages, 
            toggle: toggleImage,
            setSelection,
            isSelected: isImageSelected } = useSelection({ multiple: false });
    const images = useMemo(() => product?.originalImages || product?.images || [], [ product ]);
    const sizes = product?.sizes || [];
    const productUrl = useCatalogueProductUrl(product);
    const { message } = AntApp.useApp();

    
    const onBackClick = useSwallowEventCallback(
        () => {
            builder.goBack();
        },
        [ builder ]
    );

    const onOpenProductClick = useCallback(
        () => {
            window.open(productUrl);
        },
        [ productUrl ]
    );

    const onAddToMoodboardClick = useCallback(
        async () => {

            const add = productEditor.normalizeCatalogueProduct(product);
            if (add.images && typeof add.images[0] === "string") {
                add.images = await productEditor.preloadProductImages(add.images);
            }
            builder.set("addToMoodboardProducts", [ add ]);
            builder.openPage("add-to-moodboard");
        },
        [ builder, product ]
    );

    const onClose = useSwallowEventCallback(
        () => {
            builder.set("currentModal", null);
        },
        [ builder ]
    );

    const onGenerateClick = useCallback(
        async () => {
            setGeneratingLink(true);
            
            const url = product.url || 
                                extractUrlFromClick(product.details?.click_url ||
                                                    product.click_url);
            const link = await getShortLink({ 
                catalogueProductId: product.catalogueId || product.product_web_id, 
                url: url, 
                retailer: product.retailer,
                name: product.name,
                brand: product.brand || null,
                sku: product?.details?.sku || null
            });

            setGeneratingLink(false);
            setLink(link);
        },
        [ product ]
    );

    const onScrapeClick = useSwallowEventCallback(
        async () => {
            setScraping(true);

            try {
                const url = product.url || 
                                extractUrlFromClick(product.details?.click_url ||
                                                    product.click_url);
                if (url) {
                    const p = await productEditor.scrapeUrl(url);
                    builder.set("searchProduct", p);
                }
            }
            catch (err) {
                console.log(err)
            }

            setScraping(false);
        },
        [ product ]
    );

    const sendMessage = useCallback(
        (text) => message.success({
            content: text,
            icon: <></>
        }),
        [ message ]
    );

    const addToLook = useCallback(
        async () => {
            const finalImages = [];

            for (const img of selectedImages) {
                
                if (img) {
                    finalImages.push(
                        typeof img === "string" ? 
                        { src: img } : 
                        { ...img }
                    );
                }
            }

            if (!finalImages.length) {
                return;
            }

            const finalProduct = {
                ...product,
                images: finalImages,
                originalImages: product.images
            };

            sendMessage('Product added to your Look');
            builder.trigger("add-product", finalProduct);
            hub.dispatch("look-builder", "product-used");
            setSelection([]);
        },
        [ product, selectedImages, builder, setSelection, sendMessage ]
    );

    useUpdateEffect(
        () => {
            setSelection([]);
            setLink(null);
        },
        [ product ]
    );


    if (!open) {
        return null;
    }

    return (
        <div className="look-builder-search-product">
            <div className="look-builder-search-product-header">
                <div>
                    <a href="/#" onClick={ onBackClick }>
                        <IconBack/>
                        Back
                    </a>
                    <Button 
                        type="text"
                        iconPosition="before" 
                        size="medium" 
                        text="Refresh"
                        Icon={ IconRefresh }
                        loading={ scraping }
                        onClick={ onScrapeClick }/>
                    </div>
                <a href="/#" 
                    className="close"
                    onClick={ onClose }><IconClose/></a>
            </div>
            <div className="look-builder-search-product-scroller">
                <h4>Select product image</h4>
                <div className="look-builder-search-product-layout">
                    <div className="look-builder-search-product-images">
                        <div>
                        { images.map((i, inx) => (
                            <ProductImage 
                                image={ i } 
                                key={ inx } 
                                selected={ isImageSelected(i) }
                                toggleSelection={ toggleImage }/>
                        ))}
                        </div>
                    </div>
                    <div className="look-builder-search-product-toolbar">
                        <Button
                            Icon={ IconOpenIn }
                            text={ url2retailer(product.retailer) }
                            iconPosition="after"
                            type="secondary"
                            size="medium"
                            onClick={ onOpenProductClick }/>
                        <Button
                            Icon={ IconBookmark }
                            text="Add to moodboard"
                            type="text"
                            shape="circle"
                            iconPosition="before"
                            size="medium"
                            onClick={ onAddToMoodboardClick }/>
                        { !link && <Button
                            Icon={ IconLink }
                            text="Trackable link"
                            type="text"
                            shape="circle"
                            iconPosition="before"
                            size="medium"
                            loading={ generatingLink }
                            onClick={ onGenerateClick }/> }
                        { link && 
                            <Typography.Paragraph 
                                className="catalogue-product-drawer-link" 
                                copyable={{ 
                                    text: link, 
                                    onCopy: () => sendMessage('Your link was copied to clipboard'), 
                                    tooltips: ['Copy', ''] 
                                }} 
                                children={ <span>{ link }</span> }/> }
                    </div>
                    <div className="look-builder-search-product-info">

                        <div>
                            <h3>{ product.designers ? product.designers.map(d => d.name || d).join(", ") : product.brand }</h3>
                            <p className="name">{ product.name }</p>

                            <p className="price">
                                { !!product.salePrice && 
                                    <span>{ formatCurrency(product.salePrice, product.currency) }</span> }
                                <span>{ formatCurrency(product.price, product.currency) }</span>
                            </p>
                        </div>

                        { sizes.length > 0 &&
                            <div className="sizes">
                            <p>Available sizes</p>
                            <ul className="sizes">
                                { sizes.map(s => 
                                    <li key={ s.size } className={ s.availability }>
                                        { s.size }
                                    </li>
                                ) }
                            </ul>
                            </div> }

                        <p className="description">{ product.description }</p>
                        <Spacer size="10"/>
                    </div>
                </div>
            </div>
            <Portal host="look-builder-search-footer">
                <div className="look-builder-dialog-footer">
                <Button
                    disabled={ selectedImages.length === 0 }
                    type="primary"
                    text="Add image to look"
                    onClick={ addToLook }/>
                </div>
            </Portal>
        </div>
    )
}

export default LookBuilderSearchProduct
