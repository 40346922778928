
import { useMemo } from "react"

import Tag from "./Tag"
import LookCardProduct from "./Product"
import { getDefaultBackgroundSize, getGridStyle, getSlotClass } from "common/src/lib/look/layout2style"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback";
import s3url from "common/src/lib/image/s3url";
import { LinkSourceContextProvider } from "common/src/refactor/lib/LinkSourceContext";
import { ReactComponent as IconTag } from "common/src/svg/tag.svg"
import LookPublished from "./Published";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";


//look.layouts.template
function getBiggestSlotInx(template) {
    let m = 0, mv = 0;
    template?.forEach((s, i) => {
        const v = s.row + s.col;
        if (v > mv) {
            mv = v;
            m = i;
        }
    });

    return m;
}

//look.layouts.template
function getBiggestSpanSize(template) {
    let maxs = 0;
    template?.forEach((s) => {
        const r = s.row;
        if (r > 1 && r > maxs) {
            maxs = r;
        }
    });
    return maxs;
}


function LookLayoutEmptyItem({ index, look, empty }) {

    const fullTemplate = look.layouts.template;
    const layoutTemplate = look.layouts.template[index];
    const style = useMemo(
        () => getGridStyle(layoutTemplate, { slotIndex: index, template: fullTemplate }),
        [layoutTemplate, fullTemplate, index]
    );

    const slotClasses = useMemo(
        () => {
            const { layouts = {} } = look || {},
            template = layouts['template'],
            slotClass = getSlotClass(index, template);
            const base = "look-card-product";
            const slotCls1 = slotClass.map(c => base +"-"+ c).join(" ");
            return [ slotCls1 ].join(" ");
        },
        [ index, look ]
    );

    const cls = useMemo(
        () => {
            const base = "look-card-empty look-card-product";

            return [ 
                base,
                slotClasses,
            ].join(" ")
        },
        [ slotClasses ]
    );

    const item = useMemo(
        () => {
            if (empty) {
                if (typeof empty === "function") {
                    return empty({
                        look,
                        style,
                        index,
                        layout: layoutTemplate,
                        template: fullTemplate
                    });
                }
                else {
                    return empty;
                }
            }

            return (
                <div style={ style } className={ cls } />
            )
        },
        [look, style, index, empty]
    );

    return item;
}


function LookLayoutProductItem({ look, index, biggestSpan, biggestInx, 
                                    onEvent, productCmp, productProps }) {

    const layoutTemplate = look.layouts.template[index];
    const ProductCmp = useMemo(() => productCmp || LookCardProduct, [ productCmp ]);

    const { product, bigSlot } = useMemo(
        () => {
            const { layouts, products, productMode = "multiple" } = look,
                    order = layouts['order'] || [],
                    pid = productMode === "multiple" ?
                        order[index] :
                        products.length > 0 ?
                            products[0].id :
                            null,
                    product = pid ? products.find(p => p.id === pid) : null,
                    bigSlot = layoutTemplate.row === biggestSpan;

            return { product, bigSlot };
        },
        [ index, look ]
    );

    const props_1 = useMemo(
        () => ({
            slotIndex: index,
            biggestSlot: biggestInx === index,
            bigSlot
        }),
        [ index, biggestInx, bigSlot ]
    );

    const props_2 = useMemo(
        () => {
            return typeof productProps === "function" ?
                        productProps(product, look, props_1) :
                        { ...productProps };
        },
        [ props_1, product, look, productProps ]
    );

    return (
        <ProductCmp
            look={ look }
            product={ product }
            {...props_1}
            {...props_2}/>
    )
}


function LookLayoutItem({ look, index, biggestSpan, biggestInx, ...rest }) {

    const { product, isEmptyImage } = useMemo(
        () => {
            const { layouts, products, productMode = "multiple" } = look,
                    order = layouts['order'] || [],
                    pid = productMode === "multiple" ?
                            order[index] :
                            products.length > 0 ?
                                products[0].id :
                                null,
                    product = pid ? products.find(p => p.id === pid) : null,
                    isEmptyImage = productMode === "single" && (!product || !product.images[index]);
            return { product, isEmptyImage };
        },
        [ index, look ]
    ); 

    if (product && !isEmptyImage) {
        return (
            <LinkSourceContextProvider type="look-product" id={ product.id }>
                <LookLayoutProductItem
                    look={ look }
                    index={ index }
                    biggestSpan={ biggestSpan }
                    biggestInx={ biggestInx }
                    { ...rest } />
            </LinkSourceContextProvider>
        )
    }
    else {
        return (
            <LookLayoutEmptyItem
                look={ look }
                index={ index }
                { ...rest }/>
        );
    }
}


function LookLayout({ look, ...rest }) {

    const fullTemplate = look.layouts.template;
    const biggestInx = useMemo(() => getBiggestSlotInx(fullTemplate), [fullTemplate]);
    const biggestSpan = useMemo(() => getBiggestSpanSize(fullTemplate), [fullTemplate]);

    return fullTemplate?.map((_, index) => 
        <LookLayoutItem
            key={ index }
            look={ look }
            index={ index }
            biggestInx={ biggestInx }
            biggestSpan={ biggestSpan }
            { ...rest }/>
    );
}

function TaggedLook({ look, className, attrs, onClick, children,
                        showTags = true, 
                        mode,
                        TagComponent = Tag, productProps, ...rest }) {
    const [ containerSize, setContainerSize ] = useState({ width: 0, height: 0 });
    const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current) {
          const { width, height } = containerRef.current.getBoundingClientRect();
          setContainerSize({ width, height });
        }
      }, [ containerRef ]);

    const onLookClick = useSwallowEventCallback(
        () => onClick && onClick(look),
        [ onClick, look ]
    );

    const cls = useMemo(
        () => ["look-card-tagged", className].filter(c => !!c).join(" "),
        []
    );

    const tags = useMemo(
        () => {
            if (!showTags) {
                return [];
            }

            const pids = Object.keys(look?.layouts?.tags || {});
            return pids.map((pid, inx) => {
                if (!pid) {
                    return null;
                }
                const point = look.layouts.tags?.[pid]?.[0].point;
                const layout = look.layouts.tags?.[pid]?.[0].layout;
                const product = look.products.find(p => p.id === pid);
                if (!point || !product) {
                    return null;
                }

                const props_2 = typeof productProps === "function" ?
                                    productProps(product, look, inx) :
                                    productProps;

                return { product, point, layout, ...props_2 };
            }).filter(t => !!t);
        },
        [ look, productProps ]
    );

    const imageStyle = useMemo(
        () => {
            const images = look?.layouts?.images || [];
            const image = images[0] || null;
            if (!image) {
                return null;
            }
            const zoom = image.zoom || 0;
            const style = {
                backgroundImage: `url(${ image.src || s3url(image.key) })`,
            }
            const bgSize = getDefaultBackgroundSize({}, image, null, containerSize.width, containerSize.height);

            if (zoom !== undefined && zoom !== null) {
                style.backgroundSize = (zoom + (bgSize || 100)) + '%';
            } else {
                style.backgroundSize = (bgSize || 100) + '%';
            }

            return style;
        },
        [ look, containerSize ]
    );

    return (
        <div className={ cls } 
            ref={ containerRef }
            data-look-id={ look.id }
            { ...attrs }>
            <div className="look-card-tagged-image" style={ imageStyle }/>
            { showTags && 
                <div className="look-card-tags">
                    { tags.map((t, inx) => (
                        <LinkSourceContextProvider key={ inx } type="look-product" id={ t.product.id }>
                            <TagComponent { ...t }/>
                        </LinkSourceContextProvider>
                    ))}
                </div> }
            { mode === "list" && <LookPublished look={ look }/> }
            { onClick && 
                <a href="/#" className="look-card-link" onClick={ onLookClick }/>}
            { children }
        </div>
    )
}

function LayoutLook({ look, className, style, children, onClick, attrs, mode, ...rest }) {

    const cls = useMemo(
        () => ["look-card", className].filter(c => !!c).join(" "),
        []
    );

    const onLookClick = useSwallowEventCallback(
        () => onClick && onClick(look),
        [ onClick, look ]
    );

    return (
        <div className={ cls } 
            style={ style } 
            data-look-id={ look.id }
            { ...attrs }>
            { (look?.products.length > 0 || mode !== "list") && 
                <div className="look-card-products">
                    <LookLayout look={ look } { ...rest }/>
                </div>  }
            { (look?.products.length === 0 && !!mode) &&
                <div className="look-card-products-empty">
                    <IconTag/>
                    <p>Add products to complete your look</p>
                </div>  }
            { mode === "list" && <LookPublished look={ look }/> }
            { onClick && 
                <a href="/#" className="look-card-link" onClick={ onLookClick }/>}
            { children }
        </div>
    )
}

function LookCard({ look, ...rest }) {

    if (look.productMode === "tagged") {
        return (
            <LinkSourceContextProvider type="look" id={ look?.id }>
            <TaggedLook look={ look } { ...rest }/>
            </LinkSourceContextProvider>
        )
    }
    else {
        return (
            <LinkSourceContextProvider type="look" id={ look?.id }>
            <LayoutLook look={ look } { ...rest }/>
            </LinkSourceContextProvider>
        )
    }
}

export default LookCard