
export default function (url) {

    let retailer, catalogueId;

    if (url.indexOf("net-a-porter") !== -1) {
        //https://www.net-a-porter.com/en-ru/shop/product/{remove-all}/{id}
        let match = url.match(/product\/.*\/(\d+)/) || url.match(/product\/(\d+)/);
        retailer = "net-a-porter";
        catalogueId = match?.[1];
    }
    else if (url.indexOf("ssense") !== -1) {
        //https://www.ssense.com/en-ru/{women|men}/product/a/b/{id}
        let match = url.match(/product\/.*\/(\d+)/);
        retailer = "ssense";
        catalogueId = match?.[1];
    }
    else if (url.indexOf("drsturm") !== -1) {
        //https://www.drsturm.com/{clarifying-spot-treatment}/
        url = new URL(url);
        retailer = "sturm";
        catalogueId = url.pathname.split("/").shift();
    }
    else if (url.indexOf("mytheresa") !== -1) {
        //https://www.mytheresa.com/eu_en/{some-text-1604740}.html
        url = new URL(url);
        retailer = "mytheresa";
        catalogueId = url.pathname.split("/").pop().replace('.html', '');
    }
    else if (url.indexOf("matchesfashion") !== -1) {
        //https://www.matchesfashion.com/intl/products/{possibly-some-text-}{id}
        retailer = "matchesfashion";
        catalogueId = url.match(/products\/[^/\d]*(\d+)/i)?.[1];
    }
    else if (url.indexOf("harveynichols") !== -1) {
        // old: https://www.harveynichols.com/int/brand/{whatever}/{391255-faye-medium-leather-shoulder-bag/p3859324}/
        // https://www.harveynichols.com/{{brand}/{pirouette-printed-966274-pink-51684-pirouette-pink-161966}}/
        retailer = "harveynichols";
        const match = url.match(/harveynichols\.com\/([^/]+\/[^/]+)/i);
        if (match) {
            catalogueId = match[1];
        }
    }
    else if (url.indexOf("neous.co") !== -1) {
        //                          removeable                      catalogueId
        //https://www.neous.co.uk/{collections/aw20-sale}/products/{menea-cream-neous}
        retailer = "neous";
        catalogueId = url.match(/products\/([^/?]+)/i)?.[1];
    }
    else if (url.indexOf("chloe.com") !== -1) {
        // https://www.chloe.com/gb/{shoulder-bag_cod45535314lm}.html
        url = new URL(url);
        retailer = "chloe";
        catalogueId = url.pathname.split("/").pop().replace('.html', '');
    }
    else if (url.indexOf("luisaviaroma.com") !== -1) {
        //                                            removeable                id
        // https://www.luisaviaroma.com/en-ru/p/{bottega-veneta/women/shirts}/{73I-CD3079}
        // if catalogue id would be 73ICD3079
        url = new URL(url);
        retailer = "luisaviaroma";
        catalogueId = url.pathname.split("/").pop();
        catalogueId = catalogueId.replace('-', '');
    }
    else if (url.indexOf('modaoperandi') !== -1) {
        //                                              replaceable with a/b
        // https://www.modaoperandi.com/{women|men}/p/{blaze/lyra-everynight-houndstooth-blazer}/{439800}
        url = new URL(url);
        retailer = "modaoperandi";
        catalogueId = url.pathname.split("/").pop();
        if (!catalogueId.match(/^\d+$/)) {
            let tmp = url.pathname.split("/");
            catalogueId = `${tmp[tmp.length - 2]}/${tmp[tmp.length - 1]}`;
        }
    }
    else if (url.indexOf('intermixonline') !== -1) {
        retailer = "intermix";
        url = new URL(url);
        catalogueId = url.pathname.split("/").pop().replace('.html', '');
        catalogueId = decodeURIComponent(catalogueId);
        catalogueId = catalogueId.replace(/\s/g, '+');
    }
    else if (url.indexOf("farfetch.com") !== -1) {
        retailer = "farfetch";
        //let match = url.match(/shopping\/[^/]+\/([^/]+\d+)\.aspx/i);
        let match = url.match(/item-(\d+)/i);
        if (match) {
            catalogueId = match[1];
        }
        /*else {
            match = url.match(/item-(\d+)/i);
            if (match) {
                catalogueId = match[1];
            }
        }*/
    }
    else if (url.indexOf("ralphlauren.") !== -1) {
        let match = url.match(/([\d_]+)\.html/i);
        retailer = "rl";
        catalogueId = match?.[1]
    }
    else if (url.indexOf("brownsfashion.") !== -1) {
        let match = url.match(/shopping\/[a-z0-9\-]+-(\d+)/i);
        retailer = "browns";
        catalogueId = match?.[1]
    }
    else if (url.indexOf("jimmychoo.") !== -1) {
        let match = url.match(/-([a-z0-9]+)\.html/i);
        retailer = "jimmychoo";
        catalogueId = match?.[1]
    }
    else if (url.indexOf("etro.") !== -1) {
        let match = url.match(/([a-z0-9]+)\.html/i);
        retailer = "etro";
        catalogueId = match?.[1]
    }
    else if (url.indexOf("ferragamo.com") !== -1) {
        // https://https://www.ferragamo.com/shop/gb/en/{earth-top-handle-745910--24}
        url = new URL(url);
        retailer = "ferragamo";
        catalogueId = url.pathname.split("/").pop();
    }
    else if (url.indexOf("selfridges.com") !== -1) {
        // https://www.selfridges.com/GB/en/cat/balenciaga-speed-knitted-high-top-trainers-4-7-years_{5121-10004-2747700609}/
        url = new URL(url);
        retailer = "selfridges";
        catalogueId = url.pathname.split("/").filter(p => !!p).pop().split('_').pop();
    }
    else if (url.indexOf("rogervivier.com") !== -1) {
        // https://www.rogervivier.com/ww-en/Broche-Vivier-Buckle-Pumps-in-Satin/p/{RVW53830030912B999}/
        retailer = "rogervivier";
        let match = url.match(/\/p\/([^/]+)/i);
        catalogueId = match?.[1];
    }
    else if (url.indexOf("shopbop.com") !== -1) {
        // https://www.shopbop.com/aaa/vp/v=1/{1541831723}.htm
        retailer = "shopbop";
        let match = url.match(/([a-z0-9]+)\.htm/i);
        catalogueId = match?.[1];
    }
    else if (url.indexOf("thewebster.us") !== -1) {
        // https://thewebster.us/shop/{draped-mini-dress-mastic-white}.html
        retailer = "webster";
        let match = url.match(/([a-z0-9]+)\.html/i);
        catalogueId = match?.[1];
    }
    else if (url.indexOf("neimanmarcus.com") !== -1) {
        // https://www.neimanmarcus.com/en-ru/p/aaa-bbb-{prod242410425}
        retailer = "neiman";
        let match = url.match(/(prod[0-9]+)/i);
        catalogueId = match?.[1];
    }
    else if (url.indexOf("bergdorfgoodman.com") !== -1) {
        // https://www.bergdorfgoodman.com/p/halston-rhodes-skirted-satin-jumpsuit-{prod162260114}
        retailer = "bergdorf";
        let match = url.match(/(prod[0-9]+)/i);
        catalogueId = match?.[1];
    }
    else if (url.indexOf("fwrd.com") !== -1) {
        // https://www.fwrd.com/product-aaa/{AMCQ-WO54}
        retailer = "forward";
        let match = url.match(/product-[a-z0-9\-]+\/([a-z0-9\-]+)/i);
        catalogueId = match?.[1];
    }
    else if (url.indexOf("tods.com") !== -1) {
        // https://www.tods.com/ww-en/p/aa-bb/{XBWAOUS0300P3AC801}
        retailer = "tods";
        let match = url.match(/p\/(X[a-z0-9\-]+)/i);
        if (match) {
            catalogueId = match[1];
        }
        else {
            match = url.match(/p\/[^\/]+\/(X[a-z0-9\-]+)/i);
            if (match) {
                catalogueId = match[1];
            }
        }
    }
    else if (url.indexOf("saksfifthavenue.com") !== -1) {
        // https://www.saksfifthavenue.com/product/aaa-bbb-{0400010010585}.html
        retailer = "saks";
        let match = url.match(/([0-9]+)\.html/i);
        catalogueId = match?.[1];
    }
    else if (url.indexOf("gucci.com") !== -1) {
        // https://www.gucci.com/uk/en_gb/pr/women/handbags/totes-for-women/gucci-diana-medium-tote-bag-p-65565817QDT2582
        retailer = "gucci";
        let match = url.match(/p-([a-z0-9]+)(\/|$)/i);
        catalogueId = match?.[1];
    }
    else if (url.indexOf("emiliawickstead.com") !== -1) {
        retailer = "wickstead";
        let match = url.match(/products\/([a-z0-9\-]+)/i);
        catalogueId = match?.[1];
    }
    else if (url.indexOf("altuzarra.") !== -1) {
        retailer = "altuzarra";
        let match = url.match(/products\/([a-z0-9\-]+)/i);
        catalogueId = match?.[1];
    }

    else if (url.indexOf("miumiu.") !== -1) {
        retailer = "miumiu";
        let match = url.match(/p\/[^/]+\/([^/]+)/i);
        catalogueId = match?.[1];
    }
    else if (url.indexOf("dolcegabbana.") !== -1) {
        retailer = "dg";
        let match = url.match(/-([^-]+)\.html/i);
        catalogueId = match?.[1];
    }
    else if (url.indexOf("prada.") !== -1) {
        retailer = "prada";
        let match = url.match(/p\/[^/]+\/([^/]+)/i);
        catalogueId = match?.[1];
    }
    else if (url.indexOf("bottegaveneta.com") !== -1) {
        retailer = "bottega";
        let match = url.match(/([^/.]+)-[\d]+\.html/i);
        catalogueId = match?.[1];
    }
    else if (url.indexOf("jwanderson.com") !== -1) {
        retailer = "jwanderson"
        let match = url.match(/shopping\/[^/]+-(\d+)/i);
        catalogueId = match?.[1];
    }
    else if (url.indexOf("loewe.com") !== -1) {
        retailer = "loewe";
        let match = url.match(/([^/]+-\d+)\.html/i);
        catalogueId = match?.[1];
        if (!catalogueId) {
            match = url.match(/pid=([^&]+)/i);
            catalogueId = match?.[1];
        }
    }

    if (retailer && catalogueId) {
        return { retailer, catalogueId }
    }

    return null;
}