
import useLookProductData from "common/src/refactor/hooks/look/useLookProductData";

function LookProductFooter({ product, look = {}, biggestSlot = false }) {

    const { productMode = "multiple" } = look;

    const { price, salePrice, brand, 
            isSale, isSold, 
            priceStr, salePriceStr } = useLookProductData(product)

    if (productMode === "single" && !biggestSlot) {
        return null;
    }

    return (
        <div className="look-card-product-footer">
            {/*<div className="look-card-product-footer-line-1">*/}
            { !!brand && <p className="look-card-product-brand" children={ brand }/> }
            { isSold && <p className="look-card-product-price look-card-product-sold-out" children={ priceStr }/> }

            { !isSold && 
                <>
                { isSale &&
                    <p className="look-card-product-price look-card-product-price-old-new">
                        <span className="look-card-product-price-discounted">
                            { salePriceStr }
                        </span>
                        <span>&nbsp;</span>
                        <span className="look-card-product-price-old">
                            { priceStr }
                        </span>
                    </p> }
                { (!!price && !salePrice) &&
                    <p className="look-card-product-price" children={ priceStr }/> }
                </> }
            {/*</div>*/}
            {/*<div className="look-card-product-footer-line-2">
                <p className="look-card-product-name" 
                    children={ product.name }/>
                </div>*/}
        </div>
    )
}

export default LookProductFooter