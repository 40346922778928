import { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux"

import Button from "common/src/refactor/components/button/Button"
import UserAvatar from "common/src/refactor/components/UserAvatar"
import Page from "common/src/refactor/components/Page"
import ImagePreview from "common/src/refactor/components/ImagePreview"
//import LookDialog from "app/components/look/Dialog"
import LookCard from "common/src/refactor/components/look/Card"
import Spacer from "common/src/refactor/components/Spacer"
import ProductReaction from "common/src/refactor/components/look/ProductReaction"
import ProductRetailer from "common/src/refactor/components/look/ProductRetailer"
import CatalogueProductDrawer from "common/src/refactor/components/catalogue/product/Drawer"
import LookCardFooter from "common/src/refactor/components/look/Footer"
import CustomerSizes from "app/components/customer/Sizes"
import CatalogueProductCard from "common/src/refactor/components/catalogue/product/Card"
import ProductContextMenu from "app/components/product/ContextMenu"

import { ReactComponent as IconBack } from "common/src/svg/back.svg"
import { ReactComponent as IconAdd } from "common/src/svg/add.svg"

import * as actions from "app/actions/page/consultation"
import useDictRef from "common/src/hooks/useDictRef"
import useSearchParam from "common/src/refactor/hooks/useSearchParam"
import findParent from "common/src/lib/dom/findParent"
import useHub from "common/src/hooks/useHub"
import LookContextMenu from "app/components/look/ContextMenu"
import ConsultationActions from "app/components/consultation/ConsultationActions"
import ConsultationStatus from "common/src/refactor/components/consultation/Status"
import async from "common/src/lib/js/async"
import useConsultationActions from "app/hooks/consultation/useConsultationActions"
import AssignClient from "common/src/refactor/components/dialog/AssignToClient"
import api from "common/src/api"
import { Switch } from "antd"

function ConsultationInfoImage({ image }) {

    const style = useMemo(
        () => ({ backgroundImage: `url(${ image.src })` }),
        [ image ]
    );

    const onClick = useCallback(
        () => {
            ImagePreview.show(image.src + "?w=1000");
        },
        [ image ]
    );

    return (
        <div
            onClick={ onClick } 
            style={ style } 
            className="page-consultation-image"/>
    )
}


function LookItem({ look, ...rest }) {

    return (
        <div className="page-consultation-look-card">
            <LookCard look={ look } { ...rest }/>
            <LookCardFooter look={ look }/>
        </div>
    )
}

function ProductItem({ product, ...rest }) {

    const onProductClick = useCallback(
        () => {
            CatalogueProductDrawer.show({ 
                product,
                source: {
                    type: "look-product",
                    id: product?.id
                }
            });
        },
        [ product ]
    );

    return (
        <div className="page-consultation-product-card">
            <CatalogueProductCard 
                onClick={ onProductClick }
                product={ product }
                { ...rest }>
                <ProductContextMenu product={ product }/>
            </CatalogueProductCard>
        </div>
    )
}

function ConsultationInfo({ consultation }) {

    const from = consultation ? `/styling/${ consultation.id }` : "/styling";
    const [ isHidden, setIsHidden ] = useState(consultation.hidden);
    const { onHideToggle, loading } = useConsultationActions({ cons: consultation, onHide: setIsHidden });
    const navigate = useNavigate();

    const onEdited = useCallback(
        () => actions.loadOne(consultation.id, true),
        [ consultation ]
    );
    
    const onDeleted = useCallback(
        () => {
            navigate("/styling")
        },
        // eslint-disable-next-line
        [ ]
    );
    const onHiddenChange = useCallback(
        (val) => {
            setIsHidden(!val);
            onHideToggle && onHideToggle(!val);
        }, 
        [ onHideToggle ]
    );

    useEffect(() => {
        if (consultation) {
            setIsHidden(consultation.hidden);
        }
    }, [ consultation ])

    const onAssignClientClick = useCallback(
        async () => {
            const res = await AssignClient.show();

            const { customer } = res;
            await api.consultation.update(consultation.id, { customerId: customer.id });
        },
        [ consultation ]
    );

    return (
        <div className="page-consultation-info-cons">

            { consultation.title && 
                <p className="page-consultation-title">
                    { consultation.title }
                </p> }
            { consultation.description && 
                <p className="page-consultation-description">
                    { consultation.description }
                </p> }
            
            { consultation.customer ?  
                <div className="page-consultation-customer">
                    <UserAvatar 
                        user={ consultation.customer } 
                        size="small"/>
                    <div>
                        <p className="page-consultation-customer-name">
                            For { consultation.customer.givenName } { consultation.customer.familyName }
                        </p>
                        <CustomerSizes sizes={ consultation.sizes }/>
                    </div>

                </div> :
                <div className="page-consultation-customer">
                    <Button
                        type="primary"
                        shape="circle"
                        size="medium"
                        Icon={ IconAdd }
                        onClick={ onAssignClientClick }/>
                    <p className="page-consultation-customer-name">
                        Assign a client
                    </p>
                </div> }


            <div className="page-consultation-status">
                <div className="page-consultation-hide">
                <Switch
                    disabled={ !consultation.customerId }
                    size="small" 
                    checked={ !isHidden }
                    loading={ loading }
                    onChange={ onHiddenChange }
                    />
                    <span>Visible to your client</span>
                </div>

                <ConsultationStatus status={ consultation.status }/>
            </div>

            <ConsultationActions 
                cons={ consultation } 
                onEdited={ onEdited }
                onDeleted={ onDeleted }
                showAddLook={ false }
                createFrom={ from }/>

            { consultation.images.length > 0 && 
                <div className="page-consultation-images">
                    { consultation.images.map((img, inx) => (
                        <ConsultationInfoImage key={ inx } image={ img }/>
                    ))}
                </div> }

        </div>
    )
}

function ConsultationProducts({ consultation }) {

    const products = useMemo(
        () => {
            const products = consultation.looks.map(l => l.products).flat();
            return products;
        },
        [ consultation ]
    );

    return (
        <div className="page-consultation-product-cards">
            { products.map(p => (
                <ProductItem product={ p }/>
            ))}
        </div>
    )
}

function ConsultationLooks({ consultation }) {

    const navigate = useNavigate();


    const onLookClick = useCallback(
        (e) => {
            if (e.target instanceof HTMLButtonElement || e.target instanceof SVGElement) {
                return;
            }

            const cardEl = findParent(e.target, ".look-card") || 
                            findParent(e.target, ".look-card-tagged");
            const lookId = cardEl?.dataset.lookId;
            navigate(`/styling/look/${ lookId }`);
            // const look = consultation.looks.find(l => l.id === lookId);

            // if (!look) {
            //     return;
            // }

            // const prodEl = findParent(e.target, ".look-card-product");
            // const prodId = prodEl?.dataset.productId;
            // const prodInx = prodId ? look.products.findIndex(p => p.id === prodId) : 0;

            // LookDialog.show(look, consultation, prodInx || 0);
        },
        [ navigate ]
    );

    const onProductClick = useCallback(
        (product) => {
            CatalogueProductDrawer.show({ 
                product,
                source: {
                    type: "look-product",
                    id: product.id
                }
            });
        },
        []
    );

    const productProps = useCallback(
        (product, look) => {
            const props = {
                children: (
                    <>
                        <ProductRetailer product={ product }/>
                        <ProductReaction product={ product }/>
                    </>
                )
            };

            if (look.productMode === "tagged") {
                props.onClick = onProductClick;
            }

            return props;
        },
        [ onProductClick ]
    );

    return (
        <>
            <div className="page-consultation-look-cards">
                { consultation.looks.map(l => (
                    <LookItem 
                        showTags={ false }
                        mode="list"
                        key={ l.id } 
                        look={ l }
                        productProps={ productProps }
                        attrs={{ onClick: onLookClick }}>
                        <LookContextMenu look={ l } showSend={ l.published } cons={ consultation }/>
                    </LookItem>
                ))}
            </div>
            <Spacer size="1rem"/>
        </>
    )
}

function ConsultationEmpty({ consultation }) {

    const from = consultation ? `/styling/${ consultation.id }` : "/styling";
    const { onAddLookClick } = useConsultationActions({ cons: consultation, fromHref: from });

    return (
        <div className="page-consultation-empty">
            {/* <p>Add a look</p>
            <Button
                type="primary"
                shape="circle"
                size="xlarge"
                Icon={ IconAdd }
                onClick={ onAddLookClick }/> */}
                <Button
                    type="primary"
                    text="Add look"
                    Icon={ IconAdd }
                    onClick={ onAddLookClick }
                />
        </div>
    )
}

function PageConsultation() {

    const { id } = useParams();
    //const [ loading, setLoading ] = useState(false);
    const [ page, setPage ] = useState("looks");
    const consultation = useSelector(s => {
        const cons = s.consultationPage.data.cons;
        return cons?.id === id ? cons : null
    });
    const loading = useSelector(s => s.consultationPage.ui.cons.loading);
    const [ from ] = useSearchParam("from", "/styling");
    const fromHref = consultation ? `/styling/${ consultation.id }` : "/styling";
    const { onAddLookClick } = useConsultationActions({ cons: consultation, fromHref })

    const ref = useDictRef({ id });
    const looks = consultation?.looks || [];

    const loadConsultation = useCallback(
        async () => {
            //setLoading(true);
            await actions.loadOne(ref.id);
            //setLoading(false);
        },
        // eslint-disable-next-line
        []
    );

    const onViewClick = useCallback(
        (e) => {
            e.preventDefault();
            setPage(e.target.dataset.view);
        },
        []
    );

    useEffect(
        () => {
            if (id) {
                actions.unsubscribeFromConsultation();
                async(() => {
                    actions.loadOne(id);
                    actions.subscribeToConsultation(id)
                });
            }

            return () => {
                actions.unsubscribeFromConsultation();
            }
        },
        // eslint-disable-next-line
        [ id ]
    );

    useHub("app", "currency-change", loadConsultation);
    useHub("app", "page-consultation-reload", loadConsultation);
    useHub("look", "restored", loadConsultation);

    return (
        <Page className="page-consultation" loading={ loading && !consultation }>
            <div className="page-consultation-header">
                <Button
                    className="page-consultation-back"
                    Component="a"
                    href={ from }
                    type="text"
                    variant="link"
                    size="medium"
                    Icon={ IconBack }
                    iconPosition="before"
                    text="Back"/>
            </div>
            <div className="page-consultation-columns">
                <div className="page-consultation-info">
                    { consultation && <ConsultationInfo consultation={ consultation }/> }
                </div>
                <div className="page-consultation-looks">
                    
                    <div className="toolbar">
                        <div className="page-consultation-view-menu">
                            <a href="/#"
                                data-view="looks"
                                onClick={ onViewClick }
                                className={ page === "looks" ? "active" : "" }>
                                Looks
                            </a>
                            <a href="/#"
                                data-view="products"
                                onClick={ onViewClick }
                                className={ page === "products" ? "active" : "" }>
                                Products
                            </a>
                        </div>
                    </div>

                    { (consultation && looks.length > 0) &&
                        <Button
                            className="page-consultation-add-button"
                            type="primary"
                            text="Add look"
                            Icon={ IconAdd }
                            onClick={ onAddLookClick }
                        />
                     }

                    { (consultation && looks.length > 0 && page === "looks") && 
                        <ConsultationLooks consultation={ consultation }/> }
                    { (consultation && looks.length > 0 && page === "products") && 
                        <ConsultationProducts consultation={ consultation }/> }
                    { (consultation && looks.length === 0) &&
                        <ConsultationEmpty consultation={ consultation }/> }
                </div>
            </div>
        </Page>
    )
}

export default PageConsultation