import { useMemo, useEffect } from 'react'
import { useSelector } from "react-redux"
import { Amplify } from '@aws-amplify/core'
import { BrowserRouter, Route, Routes, Navigate, useNavigate, useLocation } from "react-router-dom"
import { PostHogProvider } from "posthog-js/react"
import { ConfigProvider, App as AntApp, Spin, Flex } from "antd"

import PageSignIn from "./pages/SignIn"
import PageFeed from './pages/Feed'
import PageStyling from './pages/Styling'
import PageConsultation from './pages/Consultation'
import PageProfile from "common/src/refactor/pages/Profile"
import PageLookBuider from './pages/Builder'
import PageLook from './pages/Look'

import Footer from 'common/src/refactor/components/Footer'
import Header from "app/components/Header"

import CatalogueProductDrawer from "common/src/refactor/components/catalogue/product/Drawer"
import MoodboardDrawer from "common/src/refactor/components/moodboard/Drawer"
import NotificationsDialog from "common/src/refactor/components/dialog/NotificationsDialog"
import ImagePreview from "common/src/refactor/components/ImagePreview"
import ChangePasswordDialog from "common/src/refactor/components/profile/ChangePasswordDialog"
import EditBankDetailsDialog from "common/src/refactor/components/profile/EditBankDetailsDialog"
import LookDialog from "app/components/look/Dialog"
import ConsultationDialog from './components/consultation/Dialog'
import ConnectionSelector from "common/src/refactor/components/dialog/ConnectionSelector"
import AssignToClient from "common/src/refactor/components/dialog/AssignToClient"
import MoodboardsDialog from "common/src/refactor/components/moodboard/Dialog"
import ConsultationsDialog from "common/src/refactor/components/consultation/Dialog"
import LooksDialog from "common/src/refactor/components/look/Dialog"

import awsconfig from "common/src/awsConfig"
import * as userActions from "common/src/actions/user"
import routes from "./routes"
import commonListeners from "common/src/listeners"
import listeners from "./listeners"
import logger from "common/src/logger"
import user from "common/src/user"

import themeConfig from "common/src/refactor/ant-theme/config"
import detectDnd from "common/src/lib/feature/dnd"
import featureDetector from "common/src/lib/feature/detect"
import api from "app/api"
import { posthog } from "common/src/lib/analytics/posthog"

import { ReactComponent as IconLoading } from "common/src/svg/loading.svg"

import AppContext from "common/src/refactor/lib/AppContext"
import app from "app/appContext"
import PageChat from './pages/Chat'
import useHub from 'common/src/hooks/useHub'
import PageMoodboards from './pages/Moodboards'
import PageMoodboard from './pages/Moodboard'
import PageRecentlyDeleted from './pages/RecenltyDeleted'

Spin.setDefaultIndicator(<IconLoading className="icon icon-svg-stroke icon-loading"/>);

Amplify.configure(awsconfig);
logger.init("/" + process.env.REACT_APP_ENV + "/tf-tools/app");
commonListeners.init();
listeners.init();
api.init();

const pages = [
    {
        route: "/feed",
        component: <PageFeed/>
    },
    {
        route: "/chat/:contactId?",
        component: <PageChat/>
    },
    {
        route: "/look-editor/:id?",
        component: <PageLookBuider/>
    },
    {
        route: "/styling/look/:id",
        component: <PageLook/>
    },
    {
        route: "/styling/:id",
        component: <PageConsultation/>
    },
    {
        route: "/styling",
        component: <PageStyling/>
    },
    {
        route: "/moodboards/:moodboardId",
        component: <PageMoodboard/>
    },
    {
        route: "/moodboards",
        component: <PageMoodboards/>
    },
    {
        route: "/profile",
        component: <PageProfile/>
    },
    {
        route: "/recently-deleted",
        component: <PageRecentlyDeleted/>
    }
];

const nliPages = [
    {
        route: routes.signin,
        component: <PageSignIn/>
    },
    {
        route: "/app/signin",
        component: <PageSignIn/>
    }
];

function RedirectWithParams() {
    const location = useLocation();
    return <Navigate to={`/signin${location.search}`} />;
}

function AppLoading() {
    return (
        <Spin spinning size="large" className="app-loading"/>
    )
}

function AppNLI() {
    return (
        <Routes>
            { nliPages.map((p,inx) => 
                <Route key={ p.route } path={ p.route } element={ p.component }/>
            )}
            <Route path="/app/signin" element={ <RedirectWithParams /> } />
            <Route path="*" element={ <Navigate to={ routes.signin } /> }/>
        </Routes> 
    )
}


function AppLI() {

    const navigate = useNavigate();

    const defaultRoute = useMemo(
        () => pages.find(p => !p.accessKey || user.hasAccessTo(p.accessKey, p.accessExact)),
        []
    );

    const liPages = useMemo(
        () => pages.filter(p => !p.accessKey || user.hasAccessTo(p.accessKey, p.accessExact)),
        []
    );

    useHub("app", "redir", navigate);

    return (
        <Flex vertical className="app-view">
        <Header/>
        <Routes>
            { liPages.map((p,inx) => 
                <Route key={ p.route } path={ p.route } element={ p.component }/>
            )}
            <Route path="*" element={ <Navigate to={ defaultRoute.route } /> }/>
        </Routes> 
        <CatalogueProductDrawer/>
        <MoodboardDrawer/>
        <ChangePasswordDialog/>
        <EditBankDetailsDialog/>
        <ImagePreview/>
        <LookDialog/>
        <ConsultationDialog/>
        <ConnectionSelector/>
        <MoodboardsDialog/>
        <LooksDialog/>
        <ConsultationsDialog/>
        <NotificationsDialog/>
        <AssignToClient/>
        <Footer/>
        </Flex>
    )
}


function App() {

    const loggedIn = useSelector(s => s.user.loggedIn);
    const checking = useSelector(s => s.user.ui.checking);
    const current = useSelector(s => s.user.current);

    useEffect(
        () => {
            userActions.checkCurrent({ bypassCache: false }).catch(err => console.log(err));
            featureDetector([ detectDnd ]);
        },
        []
    );

    const content = useMemo(
        () => {
            if (loggedIn === null || (loggedIn === true && !current)) {
                return (
                    <AppLoading/>
                )
            }
            else if (checking) {
                return (
                    <AppLoading/>
                )
            }
            else if (loggedIn === false) {
                return (
                    <AppNLI/>
                )
            }
            return (
                <AppLI/>
            )
        },
        [ loggedIn, current, checking ]
    );

    return (
        <AppContext.Provider value={ app }>
        <PostHogProvider client={ posthog } autocapture>
        <ConfigProvider theme={ themeConfig }>
        <AntApp component={ false }>
        <BrowserRouter basename="/">
            { content }
        </BrowserRouter>
        </AntApp>
        </ConfigProvider>
        </PostHogProvider>
        </AppContext.Provider>
    )
}

export default App