import { useMemo, useCallback, useEffect, useContext, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useSelector, useDispatch, batch } from "react-redux"
import { App as AntApp, Input, Popconfirm, Slider, Dropdown, Switch } from "antd"

import { ReactComponent as Logo } from "common/src/svg/full_logo.svg"
import { ReactComponent as IconClose } from "common/src/svg/close.svg"
import { ReactComponent as IconEdit } from "common/src/svg/edit.svg"
import { ReactComponent as IconAdd } from "common/src/svg/add.svg"
import { ReactComponent as IconImage } from "common/src/svg/image.svg"
//import { ReactComponent as IconUndo } from "common/src/svg/undo.svg"
//import { ReactComponent as IconRedo } from "common/src/svg/redo.svg"
import { ReactComponent as IconLayout } from "common/src/svg/looks.svg"
import { ReactComponent as IconDelete } from "common/src/svg/delete.svg"

import Page from "common/src/refactor/components/Page"
import Button from "common/src/refactor/components/button/Button"
import LookBuilderContext, { LookBuilderSearchApi } from "app/components/builder/LookBuilderContext"
import LookBuilderCard from "app/components/builder/CardBuilder"
import LookBuiderTaggedCard from "app/components/builder/TaggedCard"
import LookBuilderSearch from "app/components/builder/Search"
import LookBuilderProducts from "app/components/builder/Products"
import LookType from "app/components/builder/Type"
import Dropzone from "common/src/components/Dropzone"
import FormInput from "common/src/refactor/components/form/FormInput"

import api from "app/api"
import * as actions from "common/src/actions/look/editor"
import { data } from "common/src/store/look/editor"
import { data as prodData, fillProduct } from "common/src/store/look/product"
import { remove as deleteLook, resetDraft } from "common/src/actions/look/editor"
import useSearchParam from "common/src/refactor/hooks/useSearchParam"
import useDictRef from "common/src/hooks/useDictRef"
import useKey from "common/src/refactor/hooks/useKey"
import { useOn } from "@kuindji/observable-react"
import store from "app/store"
import async from "common/src/lib/js/async"
import { prepareLookImage } from "lib/look/prepareImage"
import getProductLayout from "common/src/lib/look/getProductLayout"
import { sendLook } from "common/src/actions/chat"
import { saveDraft } from "common/src/actions/look/editor"


const MAX_TRIES = 2;

async function previewWithRetry(body, tryNo = 1) {

    try {
        const response = await api.backend.post("/removebg/preview", { body });
        if (!response.success && tryNo < MAX_TRIES) {
            return previewWithRetry(body, tryNo + 1);
        }
        return response;
    }
    catch (err) {
        console.error(err);
        if (tryNo < MAX_TRIES) {
            return previewWithRetry(body, tryNo + 1);
        }
        else {
            throw err;
        }
    }
}


function PageLookBuiderHeader() {

    const { message } = AntApp.useApp();
    const builder = useContext(LookBuilderContext);
    const navigate = useNavigate();
    const [ consId ] = useSearchParam("consId");
    const [ from ] = useSearchParam("from", "/styling");
    const look = useSelector(s => s.lookEditor.look);
    const { hasChanges } = useSelector(s => s.lookEditor.ui); 
    const [ sending, setSending ] = useState(false);
    const [ saving, setSaving ] = useState(false);
    //const consultation = useSelector(s => s.consultationsPage.data.list.find(el => el.id === consId));
    const consultation = useKey("consultation", builder);
    const empty = useKey("empty", builder);
    const isReadToSave = useMemo(
        () => {
            if (!look) {
                return false;
            }

            if (!look.products.length) {
                return false;
            }
    
            if (look.productMode !== "single") {
                if (!look.layouts || !look.layouts.order) {
                    return false;
                }
    
                if (!look.layouts.order.find(id => id !== null)) {
                    return false;
                }
            }
    
            return true;
        },
        [ look ]
    );

    // const onDeleteClick = useCallback(
    //     () => {
    //         saveDraft.cancel();
    //         modal.confirm({
    //             title: "Delete look",
    //             content: "Are you sure?",
    //             closable: true,
    //             icon: null,
    //             cancelButtonProps: { type: "text" },
    //             onOk: async () => {
    //                 if (!look.id) {
    //                     await resetDraft();
    //                     navigate(from);
    //                 }
    //                 else {
    //                     await deleteLook(look.id);
    //                     navigate(from);
    //                 }
    //             }
    //         });
    //     },
    //     [ look, from, navigate, modal ]
    // );

    const onSaveClick = useCallback(
        async () => {
            //await asyncRemoveExtraProducts();
            setSaving(true);
            await actions.submit(
                consId,
                look.published, 
                false
            );
            setSaving(false);
            navigate(from);
        },
        [ consId, from, navigate, look ]
    );

    const onSendClick = useCallback(
        async () => {
            //const lookId = 
            setSending(true);
            const lookId = await actions.submit(
                consId,
                true, 
                false
            );
            // if (!user.is("Admin")) {
            //     await looksActions.publishLook(lookId);
            // }

            if (consultation.customer) {

                const cid = consId || consultation?.id || look?.consultationId;
                if (cid) {
                    await sendLook(lookId, cid);
                }

                const customer = consultation?.customer;
                message.success({
                    content: `Look sent to ${customer.givenName} ${customer.familyName}` ,
                    icon: <></>
                })
            }

            setSending(false);

            async(() => {
                navigate(from);
            })
        },
        [ consId, from, navigate, message, consultation?.customer, look, consultation?.id ]
    );

    const onHeaderClick = useCallback(
        (e) => {
            e.stopPropagation();
        },
        []
    );

    const onCloseConfirm = useCallback(
        async () => {
            onSaveClick();
        },
        [ onSaveClick ]
    );

    const onCloseCancel = useCallback(
        async () => {
            if (look.id) {
                navigate(from);
            }
            else {
                await resetDraft();
                saveDraft.cancel();
                navigate(from);
            }
        },
        [ look, from, navigate ]
    );

    return (
        <div className="page-look-builder-header" onClick={ onHeaderClick }>
            <Link to="/" className="logo-link">
                <Logo/>
            </Link>

            { !empty && 
            <>
            {/* <Button 
                type="text" 
                size="medium"
                disabled={ saving || sending || !look }
                onClick={ onDeleteClick }
                text="Delete look"
                variant="link"/> */}
            <Button 
                loading={ sending }
                type="secondary" 
                size="medium"
                onClick={ onSendClick }
                disabled={ 
                    !isReadToSave || 
                    sending || 
                    saving || 
                    !consultation?.customer || 
                    consultation?.hidden ||
                    !look.published
                }
                text="Send to customer"/>
            <Button 
                loading={ saving }
                type="primary" 
                size="medium"
                onClick={ onSaveClick }
                disabled={ !isReadToSave || saving || sending }
                text="Save"/>
            </> }

            { hasChanges ? 
                <Popconfirm 
                    rootClassName="page-look-builder-close-confirm"
                    arrow={ false }
                    placement="bottomLeft"
                    cancelText={ look.id ? "DISCARD CHANGES" : "DISCARD LOOK" }
                    okText="SAVE"
                    onCancel={ onCloseCancel }
                    onConfirm={ onCloseConfirm }
                    okButtonProps={{ size: "medium" }}
                    cancelButtonProps={{ size: "medium" }}
                    icon={ null }
                    title="If you close this page, your look won’t be saved ">
                    <span className="page-look-builder-close">
                        <IconClose/>
                    </span>
                </Popconfirm> :
                <Link to={ from } className="page-look-builder-close">
                    <IconClose/>
                </Link> }
        </div>
    )
}



function PageLookBuiderForm() {

    const dispatch = useDispatch();
    const look = useSelector(s => s.lookEditor.look);
    const builder = useContext(LookBuilderContext);
    const { productMode, published, title } = look;
    //const [ consId ] = useSearchParam("consId");
    const consultation = useKey("consultation", builder);
    const customerId = consultation?.customerId;

    const onNameChange = useCallback(
        (e) => {
            dispatch(data.title.set(e.target.value));
        },
        [ dispatch ]
    );

    const onHiddenChange = useCallback(
        (hidden) => {
            dispatch(data.published.set(hidden));
        },
        [ dispatch ]
    );

    return (
        <div className="page-look-builder-form">
            <div className="page-look-builder-title">
                <h3>{ productMode === "tagged" ? "Tag products" : "Look layout" }</h3>

                <div className="page-look-builder-form-hide">
                    <Switch 
                        checked={ published }
                        disabled={ !customerId || consultation.hidden }
                        size="small" 
                        onChange={ onHiddenChange }/>
                    <span>Visible to your client</span>
                </div>
            </div>
            
            <FormInput label="Name this look (Optional)">
                <Input 
                    value={ title }
                    size="large"
                    placeholder="'Loewe Resort Looks'"
                    onChange={ onNameChange }/>
            </FormInput>
        </div>
    )
}

function PageLookBuilderZoom({ productMode }) {

    const builder = useContext(LookBuilderContext);
    const look = useSelector(s => s.lookEditor.look);
    const activeSlot = useKey("activeSlot", builder);
    const activeSlotCurrentImageIndex = useKey("activeSlotCurrentImageIndex", builder);
    const dispatch = useDispatch();

    const currentLayout = useMemo(
        () => {
            if (activeSlot === null || activeSlot === undefined) {
                return;
            }
            const pid = look.layouts.order[activeSlot];
            if (!pid) {
                return null;
            }
            const layout = look.layouts[pid];
            return layout ? 
                    getProductLayout(layout, activeSlotCurrentImageIndex) :
                    {};
        },
        [ activeSlotCurrentImageIndex, look, activeSlot ]
    );

    const zoom = useMemo(
        () => {
            return look?.layouts?.images?.[0]?.zoom || 0;
        },
        [ look ]
    );

    const onZoomChange = useCallback(
        (zoom) => {
            if (productMode === "layout") {
                const pid = look.layouts.order[activeSlot];
                dispatch(data.layouts.update({ 
                    key: pid, 
                    property: "zoom", 
                    value: zoom,
                    all: false,
                    image: activeSlotCurrentImageIndex 
                }));
            } else {
                const image = { ...look.layouts.images[0] };
                image.zoom = zoom;
                dispatch(data.layouts.setImage(image));
            }
            
        },
        [ look, activeSlot, activeSlotCurrentImageIndex, dispatch, productMode ]
    );

    if (productMode === "layout") {
        return (
            <div className="page-look-builder-actions-zoom">
                <Slider 
                    disabled={ activeSlot === null || activeSlot === undefined }
                    onChange={ onZoomChange }
                    min={ -120 } 
                    max={ 120 }
                    tooltip={{ open: false }}
                    value={ currentLayout?.zoom || 0 }/>
                <p>Zoom image</p>
            </div>
        )
    }
    
    return  (
        <div className="page-look-builder-actions-zoom">
                <Slider 
                    onChange={ onZoomChange }
                    min={ -120 } 
                    max={ 120 }
                    tooltip={{ open: false }}
                    value={ zoom }/>
                <p>Zoom image</p>
        </div>
    )
}

function PageLookBuilderActions() {

    const { message, modal } = AntApp.useApp();
    const dispatch = useDispatch();
    const builder = useContext(LookBuilderContext);
    const look = useSelector(s => s.lookEditor.look);
    const activeSlot = useKey("activeSlot", builder);
    const [ removingBg, setRemovingBg ] = useState(false);
    const uploading = useKey("uploadingLookImage", builder);
    const navigate = useNavigate();

    const isProduct = activeSlot !== null && !!look.layouts.order[activeSlot];
    
    const product = useMemo(() => {
        const pid = look.layouts.order[activeSlot];
        return look.products.find(p => p.id === pid);
    }, [ look, activeSlot ]);

    const onRemoveBgClick = useCallback(
        async (e) => {
            e.stopPropagation();
            //builder.set("activeSlot", activeSlot);
            //builder.set("productPage", "images");
            //builder.set("currentModal", LookBuilderProduct.NAME);
            //window.scrollTo({ top: 0, behavior: "smooth" });

            let imageData;
            const pid = look.layouts.order[activeSlot];
            const product = look.products.find(p => p.id === pid);
            const { id, images } = product;
            const image = images[ 0 /*activeSlotImageIndex*/ ];
            const body = id.indexOf("tmp_") === 0 || image.uploadKey ? 
                            { url: image.src } :
                            { productId: id, index: 0 /*activeSlotImageIndex*/, key: image.key };

            setRemovingBg(true);

            try {
                //console.log(body)
                const response = await previewWithRetry(body);
                if (response.success) {
                    imageData = response.image;
                    //setImageData(response.image);

                    dispatch(data.products.updateImage({
                        id, 
                        inx: 0,//activeSlotImageIndex,
                        image: {
                            bgPreview: imageData,
                            removeBg: true
                        }
                    }));
                }
                else {
                    //hub.dispatch("dialog", "builder-keep-dialog");
                    console.log(response)
                    message.error({
                        content: "Something went wrong. Please try again later."
                    });
                }
            }
            catch (err) {
                console.error(err);
                //hub.dispatch("dialog", "builder-keep-dialog");
                message.error({
                    content: "Something went wrong. Please try again later."
                })
            }

            setRemovingBg(false);


        },
        [ activeSlot, look, message, dispatch ]
    );

    const onReplaceClick = useCallback(
        (e) => {
            e.stopPropagation();
            // builder.set("activeSlot", activeSlot);
            builder.openPage("catalogue");
            builder.set("currentModal", LookBuilderSearch.NAME);
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
        [ builder ]
    );

    const onChangeImageClick = useCallback(
        (e) => {
            e.stopPropagation();
            const pid = look.layouts.order[activeSlot];
            const p = look.products.find(p => p.id === pid);
            //console.log("look product", { ...p })
            const product = {
                ...p,
                images: p.originalImages
            }
            builder.set("currentModal", LookBuilderSearch.NAME);
            builder.trigger("search-select-product", product);
            //console.log(p)
            //builder.set("activeSlot", activeSlot);
            //builder.set("productPage", "images");
            //builder.set("currentModal", LookBuilderProduct.NAME);
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
        [ builder, look, activeSlot ]
    );

    const onChangeLayoutClick = useCallback(
        (e) => {
            e.stopPropagation();
            builder.set("layoutChangeMode", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
        [ builder ]
    );

    const onDeleteClick = useCallback(
        () => {
            saveDraft.cancel();
            modal.confirm({
                title: "Delete look",
                content: "Are you sure?",
                closable: true,
                icon: null,
                cancelButtonProps: { type: "text" },
                onOk: async () => {
                    if (!look.id) {
                        await resetDraft();
                        navigate(`/styling/${ look.consultationId }`);
                    }
                    else {
                        await deleteLook(look.id);
                        navigate(`/styling/${ look.consultationId }`);
                    }
                }
            });
        },
        [ look, navigate, modal ]
    );

    const onImageFileChange = useCallback(
        async (file) => {
            builder.set("uploadingLookImage", true);

            const { src, info, key } = await prepareLookImage(file);

            dispatch(data.layouts.setImage({
                //mime,
                src,
                key,
                zoom: 0,
                hplus: 0,
                vplus: 0,
                ...info
            }));

            builder.set("uploadingLookImage", false);
        },
        [ builder, dispatch ]
    );

    const menu = useMemo(
        () => {
            const items = [
                product?.retailer === "farfetch" ? {
                    key: "text",
                    label: "This feature is currently unavailable for Farfetch"
                } : null
            ].filter(m => !!m);

            return { items };
        },
        [ product ]
    );

    return (
        <div className="page-look-builder-layout-actions"
            onClick={ e => e.stopPropagation() }>
            
            <div className="page-look-builder-layout-actions-buttons">
            <Dropdown menu={ menu } trigger="hover" rootClassName="commission-badge" placement="top">
                <div>
                <Button
                    disabled={ (product?.retailer === "farfetch") || !isProduct  }
                    Icon={ IconEdit }
                    text="Remove background"
                    loading={ removingBg }
                    shape="circle"
                    type="secondary"
                    size="lmedium"
                    iconPosition="before"
                    onClick={ onRemoveBgClick }/>
                </div>
            </Dropdown>
                <Button
                    disabled={ !isProduct }
                    Icon={ IconAdd }
                    text="Replace product"
                    shape="circle"
                    type="secondary"
                    size="lmedium"
                    iconPosition="before"
                    onClick={ onReplaceClick }/>
                <Button
                    disabled={ !isProduct }
                    Icon={ IconImage }
                    text="Change image"
                    shape="circle"
                    type="secondary"
                    size="lmedium"
                    iconPosition="before"
                    onClick={ onChangeImageClick }/>
                <Button 
                    Icon={ IconLayout }
                    text="Change layout"
                    shape="circle"
                    type="secondary"
                    size="lmedium"
                    iconPosition="before"
                    onClick={ onChangeLayoutClick }/>
                { look.productMode === "tagged" && 
                <Dropzone onChange={ onImageFileChange }>
                    <Button 
                        style={{ pointerEvents: "none" }}
                        type="secondary" 
                        loading={ uploading }
                        disabled={ uploading }
                        Icon={ IconImage }
                        shape="circle"
                        size="medium"
                        text="Replace image"
                        iconPosition="before"/>
                </Dropzone>
                 }
                 <Button
                    Icon={ IconDelete }
                    text="Delete look"
                    shape="circle"
                    type="secondary"
                    size="lmedium"
                    iconPosition="before"
                    onClick={ onDeleteClick }/>
                {/*<Button
                    disabled
                    Icon={ IconUndo }
                    text="Undo"
                    shape="circle"
                    type="secondary"
                    size="lmedium"
                    iconPosition="before"/>
                <Button
                    disabled
                    Icon={ IconRedo }
                    text="Redo"
                    shape="circle"
                    type="secondary"
                    size="lmedium"
                    iconPosition="before"/>*/}
            </div>
        </div>
    )
}


function PageLookBuider() {

    const { id } = useParams();
    const [ defaultMode ] = useSearchParam("mode");
    const [ consId ] = useSearchParam("consId");
    const look = useSelector(s => s.lookEditor.look);
    const preloadedCons = useSelector(s => s.consultationsPage.data.list.find(el => el.id === consId));
    const dispatch = useDispatch();
    const builder = useMemo(
        () => new LookBuilderSearchApi({ 
            searchPage: "catalogue",
            empty: !id,
            lookLoading: !!id,
            draftLoading: false,
            consultation:  preloadedCons || null,
            activeSlot: id ? null : 0
        }), 
        // eslint-disable-next-line
        [ id ]
    );
    //const [ uploadingImage, setUploadingImage ] = useState(false);
    const uploadingImage = useKey("uploadingLookImage", builder);
    const layoutChangeMode = useKey("layoutChangeMode", builder);
    const currentModal = useKey("currentModal", builder);
    const lookLoading = useKey("lookLoading", builder);
    const empty = useKey("empty", builder);
    const showType = empty || layoutChangeMode || lookLoading;

    // const hasImage = useMemo(
    //     () => {
    //         if (look.productMode !== "tagged") {
    //             return false;
    //         }
    //         return look.layouts?.images?.length > 0 || false;
    //     },
    //     [ look ]
    // );

    //const { load: loadDraft, exists: draftExists, loading: draftLoading } = useLookDraft({});

    const mode = useMemo(
        () => {
            // we only take mode from saved looks
            if (look) {
                return look.productMode === "tagged" ? "tagged" : "layout"
            }
            return defaultMode;
        },
        [ look, defaultMode ]
    );

    //const showType = (!draftExists && !look?.id) || layoutChangeMode;
    //useKeySetter("empty", builder, showType);
    //useKeySetter("draftLoading", builder, draftLoading);
    //useKeySetter("draftExists", builder, draftExists);
    //useOn(builder, "load-draft", loadDraft);

    const ref = useDictRef({ id, mode });

    const loadLook = useCallback(
        async () => {
            await actions.load(ref.id)
        },
        // eslint-disable-next-line
        []
    );

    // const onLayoutChangeClick = useCallback(
    //     (e) => {
    //         e.stopPropagation();
    //         builder.set("currentModal", LayoutSelectorDialog.NAME)
    //     },
    //     [ builder ]
    // );

    // const onMoodboardClick = useCallback(
    //     (e) => {
    //         e.stopPropagation();
    //         builder.openPage("moodboards");
    //         builder.set("currentModal", LookBuilderSearch.NAME);
    //     },
    //     [ builder ]
    // );

    // const onCatalogueClick = useCallback(
    //     (e) => {
    //         e.stopPropagation();
    //         builder.openPage("catalogue");
    //         builder.set("currentModal", LookBuilderSearch.NAME);
    //     },
    //     [ builder ]
    // );

    const onAddProduct = useCallback(
        (product) => {

            if (look.productMode === "tagged") {
                const newTagPoint = builder.get("newTagPoint") || { x: 50, y: 50 };
                builder.set("newTagPoint", null);
                builder.set("currentModal", null);
                const order = [ ...(look.layouts.order || []) ].filter(p => !!p);

                product = fillProduct(product);
                order.push(product.id);
                //const inx = order.length - 1;

                batch(() => {
                    dispatch(data.products.push(product));
                    dispatch(data.productTags.set({
                        id: product.id,
                        layout: {
                            point: newTagPoint
                        }
                    }));
                    dispatch(data.layouts.set({
                        key: "order",
                        value: order
                    }));
                })

                async(() => {
                    //builder.set("activeSlot", inx);
                    //builder.set("productPage", "images");
                    //builder.set("currentModal", LookBuilderProduct.NAME);
                })
                
            }
            else {
                let position = builder.get("activeSlot");
                const order = look.layouts.order || [];
                const offLayout = position === null || position === -1 || position === undefined;
                
                // if (position === null || position === -1 || position === undefined) {
                //     position = order.findIndex(pid => !pid);
                //     if (position === -1) {
                //         position = 0;
                //     }
                // }
                
                const prevPid = offLayout ? null : order[position];
                product = fillProduct(product);

                batch(() => {
                    if (prevPid) {
                        dispatch(data.products.removeFromLayout(prevPid));
                        dispatch(data.products.remove(prevPid));
                    }
                    dispatch(data.products.push(product));
                    if (!offLayout) {
                        dispatch(data.products.setToLayout({ id: product.id, toInx: position }));
                    }
                });

                if (!offLayout) {
                    builder.set("activeSlot", position);
                }
                builder.set("currentModal", null);
                //builder.set("productPage", "images");
                //builder.set("currentModal", LookBuilderProduct.NAME);
            }
        },
        [ builder, look, dispatch ]
    );

    const onCurrentModalChange = useCallback(
        (cm) => {
            if (!cm) {
                //builder.set("activeSlot", null);
                builder.set("newTagPoint", null);
            }
            builder.set("dialogExpanded", false);
        },
        [ builder ]
    );

    const onPageClick = useCallback(
        () => {
            if (!builder.get("slotImageMoving") && !showType) {
                builder.set("activeSlot", null);
                builder.set("currentModal", null);
            }
        },
        [ builder, showType ]
    );

    useOn(builder, "add-product", onAddProduct);
    useOn(builder, "currentModal", onCurrentModalChange);


    useEffect(
        () => {
            
            //id ? loadLook() : loadDraft();
            if (id) {
                builder.set("lookLoading", true);
                loadLook().then(async () => {
                    if (!builder.get("consultation")) {
                        const consId = store.getState()?.lookEditor?.look?.consultationId;
                        if (consId) {
                            const list = await api.consultation.list({ where: { id: { _eq: consId }}});
                            builder.set("consultation", list[0] || null);
                        }
                    }
                    async(() => builder.set("lookLoading", false), 200);
                });
            }
            else {
                //loadDraft();
                if (!builder.get("consultation")) {
                    api.consultation.list({ where: { id: { _eq: consId }}}).then(list => {
                        builder.set("consultation", list[0] || null);
                    })
                }
                builder.set("currentModal", LookBuilderSearch.NAME);
                builder.openPage("catalogue");
            }

            return () => {
                dispatch(data.reset());
                dispatch(prodData.reset());
            }
        },
        // eslint-disable-next-line
        []
    );

    return (
        <LookBuilderContext.Provider value={ builder }>
        <Page 
            onClick={ onPageClick }
            className="page-look-builder" 
            htmlClassName="html-hide-footer html-hide-header">
            <PageLookBuiderHeader/>

            <div className="page-look-builder-layout">

                { (showType) && 
                    <LookType/> }

                { (!showType && mode === "layout") && 
                    <div className="page-look-builder-look">
                        <PageLookBuiderForm look={ look }/>
                        {/* look card loses aspec-ratio if becomes part of flex
                            so we need to wrap it in div */}
                        <div className="page-look-builder-look-wrapper">
                            <PageLookBuilderActions/>
                            <div className="page-look-builder-look-container">
                                <LookBuilderCard look={ look }/>
                            </div>
                        </div>
                        <PageLookBuilderZoom productMode={ mode }/>
                    </div> }
                { (!showType && mode === "tagged") && 
                    <div className="page-look-builder-look">
                        <PageLookBuiderForm look={ look }/>

                        <div className="page-look-builder-look-wrapper">
                            <PageLookBuilderActions/>
                            <LookBuiderTaggedCard look={ look } uploadingImage={ uploadingImage }/>
                        </div>
                        <PageLookBuilderZoom productMode={ mode }/>

                    </div> }
                

                { (!showType && mode) &&    
                <div className={ "page-look-builder-info" +
                                    (currentModal ? " with-modal" : "") }>
                    <LookBuilderProducts/>
                    <LookBuilderSearch/>
                    {/*<LookBuilderProduct/>*/}
                </div> }
            </div>
            
        </Page>
        </LookBuilderContext.Provider>
    )
}

export default PageLookBuider