import { ReactComponent as IconEye } from "common/src/svg/eye-off2.svg"

function LookPublished ({ look }) {
    if (look?.published || (look?.products.length === 0 && look?.productMode !== "tagged") || !look.id || look.deleted) {
        return null;
    } 

    if (!look?.published) {
        return (
            <div className="look-card-hidden">
                <IconEye/>
            </div>
        )
    }
}

export default LookPublished