

export default (name, qs = '', defaultValue = null) => {

    if (typeof window !== "undefined" && !!window.location) {

        let s = "" + decodeURIComponent("" + (qs || window.location.search));
        if (s.indexOf('?') === 0) {
            s = s.substring(1) || "";
        }
        if (!s) {
            return defaultValue;
        }

        return (s.split("&")
                    .filter(part => part.indexOf(name + "=") === 0)
                    .pop() || "")
                    .split("=")[1] || defaultValue;
    }

    return null;
}