import moment from "moment"
import { useEffect, useState, useCallback } from "react"

import useCurrentRef from "common/src/hooks/useCurrentRef"
import useInterval from "common/src/hooks/useInterval"
import { isOnline } from "common/src/lib/chat/online"

function useIsOnline(contact, options = {}) {

    const { label = false } = options;
    const lastOnlineRef = useCurrentRef(contact?.lastOnlineAt);
    const [ online, setOnline ] = useState(label ? "" : false);

    const updateOnline = useCallback(
        () =>{
            if (label) {
                if (!lastOnlineRef.current) {
                    setOnline("");
                }
                else {
                    setOnline(
                        isOnline(lastOnlineRef.current) ?
                            "Online":
                            "Last seen " + moment(lastOnlineRef.current).fromNow()
                    );
                }
            }
            else {
                setOnline(isOnline(lastOnlineRef.current))
            }
        },
        []
    );

    const [ startTracking, stopTracking ] = useInterval(
        updateOnline, 
        1000, 
        {
            immediateStart: true,
            trailing: true
        }
    );

    useEffect(
        () => contact?.lastOnlineAt ? startTracking() : stopTracking(),
        [ contact?.lastOnlineAt ]
    );

    return online;
}

export default useIsOnline