
import api from "common/src/api"
import readInputFile from "common/src/lib/dom/readInputFile"

export default async (file) => {
    const ext = file.name.split('.').pop();
    const { url, s3key } = await api.backend.post("/upload/url", { body: { ext }});
    const buf = file.buf || await readInputFile(file, "buffer");

    await fetch(url, { 
        method: "PUT", 
        body: buf,
        // we must pass headers here
        // otherwise Android will fail
        headers: {
            "Content-type": file.mime
        }
    });
    return s3key;
}