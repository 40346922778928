
import useStyle from "common/src/refactor/hooks/useStyle"

const baseClass = "consultation-status";
const stylePostfixes = ["text", "indicator"];

const statusText = {
    "new": "New",
    "inprogress": "In progress",
    "completed": "Completed",
    "cancelled": "Cancelled"
};

function ConsultationStatus({ 
        status, 
        className, 
        style, 
        noText = false, 
        noIndicator = false, 
        withShadow = false,
        variant,
        ...rest }) {

    const styles = useStyle(
        {
            className: [ 
                baseClass, 
                baseClass + "-" + (status || "new"),
                className 
            ],
            children: stylePostfixes,
            variants: [
                noText ? "no-text" : null,
                noIndicator ? "no-indicator" : null,
                variant
            ],
            extend: {
                [baseClass]: [ 
                    withShadow ? filterButtonShadow : null,
                    style 
                ]
            }
        },
        [ className, style, noText, noIndicator, status, variant ]
    );

    if (status === "request") {
        status = "new";
    }

    if (!status || !statusText[status]) {
        return null;
    }
    
    return (
        <div { ...styles.main } { ...rest }>
            { !noIndicator && <span { ...styles.indicator }/> }
            { !noText && <span { ...styles.text }>{ statusText[status] }</span> }
        </div>
    )
}

export default ConsultationStatus