import Button from "common/src/refactor/components/button/Button"

import { ReactComponent as IconSend } from "common/src/svg/send.svg"
import { ReactComponent as IconBookmark } from "common/src/svg/bookmark.svg"
import { ReactComponent as IconLink } from "common/src/svg/link.svg"
import useProductActions from "app/hooks/product/useProductActions"


function ProductActions({
    product,
    showSend = true,
    showAddToMoodboard = true,
    showLink = true,
    onTrackableLinkCreated,
    connectionSelectorName
}) {

    const { onSendClick, onAddToMoodboardClick, onTrackableLinkClick,
            sending, addingToMoodboard, generatingLink } = useProductActions({
        product,
        onTrackableLinkCreated,
        connectionSelectorName,
    });

    return (
        <div className="actions-toolbar">
            { (showSend && onSendClick) && 
            <Button
                type="secondary"
                size="medium"
                shape="circle" 
                iconPosition="before"
                Icon={ IconSend }
                text="Send"
                loading={ sending }
                onClick={ onSendClick }/> }
            { (showAddToMoodboard && onAddToMoodboardClick) &&
            <Button
                type="secondary"
                size="medium"
                shape="circle" 
                iconPosition="before"
                Icon={ IconBookmark }
                text="Add to moodboard"
                loading={ addingToMoodboard }
                onClick={ onAddToMoodboardClick }/> }
            { (showLink && onTrackableLinkClick) && 
            <Button
                type="secondary"
                size="medium"
                shape="circle" 
                iconPosition="before"
                Icon={ IconLink }
                text="Trackable link"
                loading={ generatingLink }
                onClick={ onTrackableLinkClick }/> }
        </div>
    )
}

export default ProductActions