import { useEffect, useRef, useCallback, useState, useMemo } from "react"

import async from "common/src/lib/js/async"

function Expandable({ children }) {

    const ref = useRef();
    const [ expanded, setExpanded ] = useState(false);
    const [ toggleable, setToggleable ] = useState(false);

    const cls = useMemo(
        () => ["expandable", expanded ? "expanded" : "", toggleable ? "enabled" : ""].join(" "),
        [ expanded, toggleable ]
    );

    const checkToggleable = useCallback(
        () => {
            const el = ref.current;
            const tg = el.clientHeight < el.scrollHeight;
            setToggleable(tg);
            setExpanded(!tg);
        },
        []
    );

    const onToggle = useCallback(
        () => toggleable && setExpanded(expanded => !expanded),
        [ toggleable ]
    );

    useEffect(
        () => {
            setExpanded(false);
            async(checkToggleable, 100);
        },
        [ children ]
    );

    return (
        <div className={ cls } ref={ ref } onClick={ onToggle }>
            { children }
        </div>
    );
}

export default Expandable