import { useMemo } from "react"
import { Dropdown } from "antd"

import Button from "common/src/refactor/components/button/Button"

import { ReactComponent as IconEdit } from "common/src/svg/edit.svg"
import { ReactComponent as IconDelete } from "common/src/svg/delete.svg"
import { ReactComponent as IconDuplicate } from "common/src/svg/duplicate.svg"
import { ReactComponent as IconSend } from "common/src/svg/send.svg"
import { ReactComponent as IconDots } from "common/src/svg/dots.svg"
import { ReactComponent as IconEye } from "common/src/svg/eye2.svg"
import { ReactComponent as IconEyeOff } from "common/src/svg/eye-off2.svg"
import useLookActions from "app/hooks/look/useLookActions"

function LookContextMenu({
    look,
    cons,
    menu: replaceMenu,
    extraMenuItems,
    showDuplicate = true,
    showEdit = true,
    showSend = true,
    showDelete = true,
    ...rest
}) {
    const { 
        onEditClick, onSendClick,
        onDeleteClick, onDuplicateClick,
        onHideToggle
    } = useLookActions({ look });

    const menu = useMemo(
        () => {
            if (replaceMenu) {
                return replaceMenu;
            }

            const items = [
                {
                    key: "hide",
                    icon: look.published ? <IconEyeOff/> : <IconEye/>,
                    label: look.published ? "Hide look" : "Show look",
                    onClick: onHideToggle,
                    disabled: cons?.hidden || !cons?.customerId
                },

                (showEdit && onEditClick) ? {
                    key: "edit",
                    icon: <IconEdit/>,
                    label: "Edit",
                    onClick: onEditClick
                } : null,

                (showDuplicate && onDuplicateClick) ? {
                    key: "duplicate",
                    icon: <IconDuplicate/>,
                    label: "Duplicate",
                    onClick: onDuplicateClick
                } : null,

                (showSend && onSendClick) ? {
                    key: "send",
                    icon: <IconSend/>,
                    label: "Send",
                    onClick: onSendClick
                } : null,

                (showDelete && onDeleteClick) ? {
                    key: "delete",
                    icon: <IconDelete/>,
                    label: "Delete",
                    onClick: onDeleteClick
                } : null,

                ...(extraMenuItems || [])
            ].filter(m => !!m);

            return { items };
        },
        [ extraMenuItems, replaceMenu, showDelete, cons, look, showDuplicate, showEdit, showSend, onDeleteClick, onEditClick, onDuplicateClick, onSendClick, onHideToggle ]
    )

    if (menu.length === 0) {
        return null;
    }

    return (
        <Dropdown menu={ menu } trigger="click" rootClassName="context-menu">
            <Button
                className="context-button"
                type="primary"
                shape="circle"
                size="small"
                Icon={ IconDots }
                style={ { zIndex: 100 } }
                { ...rest }
            />
        </Dropdown>
    )
}

export default LookContextMenu;