import user from "common/src/user"
import api from "app/api"

let itv = null;
let currentChatId = null;
const SET_ONLINE_INTERVAL = 5000;

function setLastOnline() {
    const userId = user.id();
    if (userId) {
        const lastOnlineAt = ( new Date() ).toISOString();
        api.userOnlineState.update(
            { 
                userId: { _eq: userId },
                connectionUserId: { _is_null: true }
            }, 
            { lastOnlineAt }
        );
        if (currentChatId) {
            const where = {
                userId: { _eq: userId },
                chatId: { _eq: currentChatId }
            };
            api.chatParticipant.update(where, { lastOnlineAt });
        }
    }
}

export function setCurrentChatId(chatId) {
    currentChatId = chatId;
}

export function startOnlineState() {
    if (!itv) {
        setLastOnline();
        itv = setInterval(setLastOnline, SET_ONLINE_INTERVAL);
    }
}

export function stopOnlineState() {
    if (itv) {
        clearInterval(itv);
        itv = null;
    }
}