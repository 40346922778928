import { useSelector } from "react-redux"
import { useCallback, useMemo, useState, createContext, useEffect } from "react"
import { Drawer, Input, Segmented, App as AntApp } from "antd"

import Button from "../button/Button"
import FormInput from "common/src/refactor/components/form/FormInput"
import NullForm from "common/src/components/NullForm"
import Loader from "common/src/components/Loader"
import Select from "common/src/refactor/components/ant/Select"

import { defaultContext_v2, loadCounterparty, 
        deleteCounterparty, fields_v2, accountSettings } from "common/src/actions/revolut"
import { FormContext, Form, useFormFields } from "common/src/refactor/lib/form/Form"
import { data as userData } from "common/src/store/user"
import { ui } from "common/src/store/dialogs"
import store from "app/store"
import api from "app/api"
import useDictRef from "common/src/hooks/useDictRef"
import { countryNames, countryCurrency, currencyNames } from "common/src/lib/countryCodes"
import { Fragment } from "react"

const DIALOG_NAME = "bank-details";


async function saveCounterparty(data, message) {

    const res = await api.revolut.post("/counterparty", { body: data });

    if (!res.success && res.error) {
        message.error({
            content: res.error
        })
        //alert({ closeable: true, message: res.error });
    }
    else if (res.success) {
        message.success({
            content: "Your Bank Details are now saved on THE FLOORR.",
            icon: <></>
        })
        //alert({ closeable: true, message: "Your bank details have been saved" });
        store.dispatch(userData.current.update({ hasBankDetails: true }));
    }

    return res.success;
}


function EditBankDetailsForm({ onSaved }) {

    const { message } = AntApp.useApp();
    const form = useMemo(() => new Form(fields_v2, defaultContext_v2), []);
    const [ saving, setSaving ] = useState(false);
    const {
        profileType, profileTypeChange,
        country, countryError, countryChange,
        currency, currencyError, currencyChange,
        givenName, givenNameError, givenNameChange,
        familyName, familyNameError, familyNameChange,
        account, accountError, accountChange,
        sortCode, sortCodeError, sortCodeChange,
        companyName, companyNameError, companyNameChange,
        routing, routingError, routingChange,
        ifsc, ifscError, ifscChange,
        bsb, bsbError, bsbChange,
        bic, bicError, bicChange,
        iban, ibanError, ibanChange,
        addressLine1, addressLine1Error, addressLine1Change,
        addressLine2, addressLine2Error, addressLine2Change,
        addressCity, addressCityError, addressCityChange,
        region, regionError, regionChange,
        addressPostcode, addressPostcodeError, addressPostcodeChange
    } = useFormFields(fields_v2.map(f => f.name), form);


    const settings = useMemo(() => accountSettings(country, currency), [ country, currency ]);
    const { isIFSC, isBSB, isSort, isRouting, isIBAN, addressRequired } = settings;
    const countryName = countryNames[country] || "";

    const ref = useDictRef({
        profileType,
        country, currency, givenName, familyName, companyName,
        account, sortCode, routing, ifsc, bsb, bic, iban,
        addressLine1, addressLine2, addressCity, addressPostcode, region,
        isIFSC, isBSB, isSort, isRouting, isIBAN, addressRequired
    });

    const countries = useMemo(
        () => {
            const countries = Object.entries(countryNames)
                                    .map(c => ({ key: c[0], label: c[1], value: c[0] }));
            countries.sort((a, b) => a.label === b.label ? 0 : a.label < b.label ? -1 : 1);
            return countries;
        },
        []
    );
    
    const currencies = useMemo(
        () => {
            const currencies = Object.entries(countryCurrency)
                                    .map(c => c[1])
                                    .filter((curr, inx, self) => self.indexOf(curr) === inx)
                                    .filter((curr) => !!currencyNames[curr])
                                    .map(c => {
                                        return { 
                                            key: c, 
                                            label: c + " - " + (currencyNames[c]?.name || ""), 
                                            value: c }
                                    });
            currencies.sort((a, b) => a.value === b.value ? 0 : a.value < b.value ? -1 : 1);
            return currencies;
        },
        []
    );

    const profileOptions = useMemo(
        () => [
            {
                value: "business",
                label: "Company"
            },
            {
                value: "individual",
                label: "Individual"
            }
        ],
        []
    );

    const onSubmitClick = useCallback(
        async () => {
            setSaving(true);
            const valid = await form.validateAll();

            if (!valid) {
                setSaving(false);
                return;
            }

            const data = {
                bank_country: ref.country,
                currency: ref.currency
            };

            if (ref.profileType === "business") {
                data.company_name = ref.companyName;
            }
            else {
                data.individual_name = {
                    first_name: ref.givenName,
                    last_name: ref.familyName
                }
            };

            if (!ref.isIBAN || ref.isSort || ref.isRouting || ref.isIFSC || ref.isBSB) {
                data.account_no = ref.account;
            }

            if (ref.isIBAN && !ref.isSort && !ref.isRouting && !ref.isIFSC && !ref.isBSB) {
                data.iban = ref.iban;
            }

            if (!ref.isSort && !ref.isRouting && !ref.isIFSC && !ref.isBSB) {
                data.bic = ref.bic;
            }

            if (ref.isSort) {
                data.sort_code = ref.sortCode;
            }

            if (ref.isRouting) {
                data.routing_number = ref.routing;
            }

            if (ref.isIFSC) {
                data.ifsc = ref.ifsc;
            }

            if (ref.isBSB) {
                data.bsb_code = ref.bsb;
            }

            if (ref.addressRequired) {
                data.address = {
                    street_line1: ref.addressLine1,
                    street_line2: ref.addressLine2,
                    country: ref.country,
                    city: ref.addressCity,
                    postcode: ref.addressPostcode,
                    region: ref.region
                }
            }

            const success = await saveCounterparty(data, message);

            if (success) {
                onSaved && onSaved();
            }

            setSaving(false);
        },
        [ onSaved ]
    );


    return (
        <FormContext.Provider value={ form }>
        <NullForm className="bank-details-form">

            <Segmented 
                block
                options={ profileOptions }
                value={ profileType } 
                onChange={ profileTypeChange }/>

            <FormInput label="Country *" error={ countryError }>
                <Select
                    optionFilterProp="label"
                    showSearch
                    options={ countries }
                    onSelect={ countryChange }
                    value={ country }/>
            </FormInput>

            <FormInput label="Currency *" error={ currencyError }>
                <Select
                    optionFilterProp="label"
                    showSearch
                    options={ currencies }
                    onSelect={ currencyChange }
                    value={ currency }/>
            </FormInput>

            { isRouting && 
                <FormInput label="Wire routing number *" error={ routingError }>
                    <Input
                        disabled={ saving }
                        placeholder="#########"
                        value={ routing }
                        onPressEnter={ onSubmitClick }
                        onChange={ routingChange }/>
                </FormInput> }

            { (!isIBAN || isSort || isRouting || isIFSC || isBSB) && 
                <FormInput 
                    label="Account number *" 
                    error={ accountError }
                    help="You can ask your bank or check your bank statement.">
                    <Input
                        disabled={ saving }
                        placeholder="#########"
                        value={ account }
                        onPressEnter={ onSubmitClick }
                        onChange={ accountChange }/>
                </FormInput> }

            { (isIBAN && !isSort && !isRouting && !isIFSC && !isBSB) && 
                <FormInput 
                    label="IBAN *" 
                    error={ ibanError }
                    help="You can ask your bank or check your bank statement.">
                    <Input
                        disabled={ saving }
                        placeholder="**** **** **** **** **** **** **** **** **"
                        value={ iban }
                        onPressEnter={ onSubmitClick }
                        onChange={ ibanChange }/>
                </FormInput> }

            { (!isSort && !isRouting && !isIFSC && !isBSB) && 
                <FormInput 
                    label="BIC *" 
                    error={ bicError }
                    help="Must be between 8 and 11 characters long. You can ask your bank or check your bank statement.">
                    <Input
                        disabled={ saving }
                        placeholder="XXXXXX*****"
                        value={ bic }
                        onPressEnter={ onSubmitClick }
                        onChange={ bicChange }/>
                </FormInput> }

            { (isSort) && 
                <FormInput 
                    label="Sort code *" 
                    error={ sortCodeError }
                    help="You can ask your bank or check your bank statement.">
                    <Input
                        disabled={ saving }
                        placeholder="##-##-##"
                        value={ sortCode }
                        onPressEnter={ onSubmitClick }
                        onChange={ sortCodeChange }/>
                </FormInput> }

            { (isIFSC) && 
                <FormInput 
                    label="IFSC *" 
                    error={ ifscError }
                    help="You can ask your bank or check your bank statement.">
                    <Input
                        disabled={ saving }
                        value={ ifsc }
                        onPressEnter={ onSubmitClick }
                        onChange={ ifscChange }/>
                </FormInput> }

            { (isBSB) && 
                <FormInput 
                    label="BSB *" 
                    error={ bsbError }
                    help="You can ask your bank or check your bank statement.">
                    <Input
                        disabled={ saving }
                        value={ bsb }
                        onPressEnter={ onSubmitClick }
                        onChange={ bsbChange }/>
                </FormInput> }

            { profileType ? 
                profileType === "business" ?
                    <Fragment>
                        <FormInput 
                            label="Legal name *" 
                            error={ companyNameError }>
                            <Input
                                disabled={ saving }
                                value={ companyName }
                                onPressEnter={ onSubmitClick }
                                onChange={ companyNameChange }/>
                        </FormInput>
                    </Fragment> :
                    <Fragment>
                        <FormInput   
                            label="First name *" 
                            error={ givenNameError }>
                            <Input
                                disabled={ saving }
                                value={ givenName }
                                onPressEnter={ onSubmitClick }
                                onChange={ givenNameChange }/>
                        </FormInput>
                        <FormInput   
                            label="Last name *" 
                            error={ familyNameError }>
                            <Input
                                disabled={ saving }
                                value={ familyName }
                                onPressEnter={ onSubmitClick }
                                onChange={ familyNameChange }/>
                        </FormInput>
                    </Fragment> :
                <Fragment/> }

            { addressRequired && 
                <Fragment>
                    <label>Address</label>
                    <FormInput   
                        label="Country *">
                        <Input disabled value={ countryName }/>
                    </FormInput>
                    <FormInput   
                        label="Street line 1 *" 
                        error={ addressLine1Error }>
                        <Input
                            disabled={ saving }
                            value={ addressLine1 }
                            onPressEnter={ onSubmitClick }
                            onChange={ addressLine1Change }/>
                    </FormInput>
                    <FormInput   
                        label="Street line 2" 
                        error={ addressLine2Error }>
                        <Input
                            disabled={ saving }
                            value={ addressLine2 }
                            onPressEnter={ onSubmitClick }
                            onChange={ addressLine2Change }/>
                    </FormInput>
                    <FormInput   
                        label="City *" 
                        error={ addressCityError }>
                        <Input
                            disabled={ saving }
                            value={ addressCity }
                            onPressEnter={ onSubmitClick }
                            onChange={ addressCityChange }/>
                    </FormInput>
                    <FormInput   
                        label="Region/State" 
                        error={ regionError }>
                        <Input
                            disabled={ saving }
                            value={ region }
                            onPressEnter={ onSubmitClick }
                            onChange={ regionChange }/>
                    </FormInput>
                    <FormInput   
                        label="Postcode/Zip code  *" 
                        error={ addressPostcodeError }>
                        <Input
                            disabled={ saving }
                            value={ addressPostcode }
                            onPressEnter={ onSubmitClick }
                            onChange={ addressPostcodeChange }/>
                    </FormInput>
                </Fragment> }

            <Button 
                loading={ saving }
                disabled={ saving }
                type="primary" 
                text="Save"
                onClick={ onSubmitClick }/>
        </NullForm>
        </FormContext.Provider>
    )
}

function BankDetailsInfo({ info, onRemoved }) {

    const { modal, message } = AntApp.useApp();
    const [ removing, setRemoving ] = useState(false);

    const onRemoveClick = useCallback(
        async () => {

            modal.confirm({
                title: "Remove banking details",
                content: "Are you sure you want to remove your banking details?",
                icon: null,
                closable: true,
                cancelButtonProps: {
                    type: "text"
                },
                onOk: async () => {
                    setRemoving(true);
                    await deleteCounterparty();
                    message.success({
                        content: "Your banking details have been removed."
                    });
                    onRemoved && onRemoved();
                    setRemoving(false);
                }
            });
        },
        [ onRemoved ]
    );

    return (
        <div className="bank-details-info">
            <p>{ info.name }</p>
            <p>{ countryNames[info.country] }</p>
            <p>{ info.currency } { info.iban || info.account }</p>
            <p>{ info.bic || info.sortCode || info.routing || info.ifsc || info.bsb }</p>
            <Button 
                loading={ removing }
                disabled={ removing }
                children="Remove" 
                type="primary" 
                onClick={ onRemoveClick }/>
        </div>
    )
}

function BankDetails() {

    const [ loading, setLoading ] = useState(true);
    const [ info, setInfo ] = useState(null);

    const onInfoRemoved = useCallback(
        () => {
            setInfo(null);
        },
        []
    );

    const loadInfo = useCallback(
        async () => {
            setLoading(true);
            const cp = await loadCounterparty();

            if (cp && cp.accounts && cp.accounts.length !== 0) {
                const acc = cp.accounts[0];
                const data = {
                    country: acc.bank_country,
                    currency: acc.currency,
                    name: acc.name
                };
                if (acc.account_no) {
                    data.account = acc.account_no;
                }
                if (acc.sort_code) {
                    data.sortCode = acc.sort_code;
                }
                if (acc.iban) {
                    data.iban = acc.iban;
                }
                if (acc.routing_number) {
                    data.routing = acc.routing_number;
                }
                if (acc.bsb_code) {
                    data.bsb = acc.bsb_code;
                }
                if (acc.ifsc) {
                    data.ifsc = acc.ifsc;
                }
                if (acc.bic) {
                    data.bic = acc.bic;
                }

                setInfo(data);
            }

            setLoading(false);
        },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            loadInfo();
        },
        []
    );

    if (loading) {
        return (
            <Loader/>
        )
    }
    else if (info) {
        return (
            <BankDetailsInfo info={ info } onRemoved={ onInfoRemoved }/>
        )
    }
    else {
        return (
            <EditBankDetailsForm onSaved={ loadInfo }/>
        )
    }
    
}

function EditBankDetailsDialog() {
    const open = useSelector(s => s.dialogs[DIALOG_NAME]);

    const onClose = useCallback(
        () => { EditBankDetailsDialog.hide() },
        []
    );

    return (
        <Drawer 
            className="tf-drawer bank-details-drawer"
            title="Bank details"
            open={ open } 
            onClose={ onClose }
            destroyOnClose>
            { open && <BankDetails/> }
        </Drawer>
    )
}


EditBankDetailsDialog.show = function() {
    store.dispatch(ui.show(DIALOG_NAME));
}

EditBankDetailsDialog.hide = function() {
    store.dispatch(ui.hide(DIALOG_NAME));
}

export default EditBankDetailsDialog