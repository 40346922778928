
import * as rest from "./rest"
import createMultiple from "./createMultiple"
import restMultiple from "./restMultiple"
import { init, customQuery } from "./hasura/add"
import entities from "./hasura/entities"
import AnalyticsTiming from "common/src/lib/analytics/Timing"
import settings from "app/settings"

const api = { 
    ...rest, 
    createMultiple, 
    restMultiple,

    customGqlQuery: customQuery,

    init: () => {
        AnalyticsTiming.init(api);
        init(api, entities, AnalyticsTiming);
        rest.init(AnalyticsTiming);
        if (process.env.REACT_APP_ENV === "devtwo" || settings.app === "admin") {
            window.tfApi = api;
        }
        
    }
}

export default api;