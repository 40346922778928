import { useSelector } from "react-redux"
import { useCallback, useMemo, useState } from "react"
import { Drawer, Input, App as AntApp } from "antd"

import Button from "../button/Button"
import NullForm from "common/src/components/NullForm"
import FormInput from "common/src/refactor/components/form/FormInput"


import { ui } from "common/src/store/dialogs"
import store from "app/store"
import { Form, useFormFields } from "common/src/refactor/lib/form/Form"
import required from "common/src/refactor/lib/form/validator/required"
import { resendEmailVerification, verifyEmail, checkCurrent } 
                        from "common/src/actions/user"

                        
const fields = [
    {
        name: "code",
        validator: [
            [ required, "Please enter the verification code" ]
        ]
    }
]

const DIALOG_NAME = "verify-email";

function VerifyEmailForm() {

    const { message } = AntApp.useApp();
    const form = useMemo(() => new Form(fields), []);
    const [ codeSent, setCodeSent ] = useState(false);
    const [ sendingCode, setSendingCode ] = useState(false);
    const [ verifying, setVerifying ] = useState(false);

    const { code, codeError, codeChange, codeSetError } = useFormFields(["code"], form);

    const onSendCodeClick = useCallback(
        async () => {
            setSendingCode(true);

            const resp = await resendEmailVerification()

            if (!resp.success) {
                if (resp.verified) {
                    message.info({
                        content: "Email is already verified"
                    })
                    checkCurrent();
                    VerifyEmailDialog.hide();
                    return;
                }
            }

            setCodeSent(true);
            setSendingCode(false);
        },
        []
    );

    const onSubmitClick = useCallback(
        async () => {
            setVerifying(true);

            try {
                const resp = await verifyEmail(null, code);
                if (resp.success) {
                    message.success({
                        content: "You're ready to go. We verified your email address.",
                        icon: <></>,
                    });
                    VerifyEmailDialog.hide();
                }
                else {
                    if (resp.status === "VERIFIED") {
                        message.info({
                            content: "Your email is already verified"
                        });
                        VerifyEmailDialog.hide();
                    }
                    else if (resp.status === "EXPIRED") {
                        codeSetError("Verification code has expired");
                    }
                    else if (resp.status === "USED") {
                        codeSetError("You already used this code");
                    }
                    else if (resp.status === "INVALID") {
                        codeSetError("The code is invalid");
                    }
                    else {
                        message.error({
                            content: "Failed to verify your email"
                        });
                    }
                }
            }
            catch (err) {
                message.error({
                    content: "Failed to verify the email"
                })
            }

            setVerifying(false);
        },
        [ code, codeSetError ]
    );

    if (codeSent) {
        return (
            <NullForm className="verify-email-form">
                <FormInput error={ codeError } label="Verification code *">
                    <Input 
                        disabled={ verifying || sendingCode }
                        value={ code } 
                        onChange={ codeChange }
                        onPressEnter={ onSubmitClick }/>
                </FormInput>
                <Button 
                    type="primary"
                    disabled={ verifying || sendingCode }
                    loading={ verifying }
                    text="Submit" 
                    onClick={ onSubmitClick }/>
                <Button
                    type="text"
                    disabled={ verifying || sendingCode }
                    loading={ sendingCode }
                    text="Resend code" 
                    onClick={ onSendCodeClick }/>
            </NullForm>
        )
    }
    else {
        return (
            <NullForm className="verify-email-form">
                <Button 
                    type="primary"
                    disabled={ sendingCode }
                    loading={ sendingCode }
                    children="Send code" 
                    onClick={ onSendCodeClick }/>
            </NullForm>
        )
    }
}

function VerifyEmailDialog() {
    const open = useSelector(s => s.dialogs[DIALOG_NAME]);

    const onClose = useCallback(
        () => { VerifyEmailDialog.hide() },
        []
    );

    return (
        <Drawer 
            className="tf-drawer verify-email-drawer"
            title="Verify email"
            open={ open } 
            onClose={ onClose }
            destroyOnClose>
            { open && <VerifyEmailForm/> }
        </Drawer>
    )
}


VerifyEmailDialog.show = function() {
    store.dispatch(ui.show(DIALOG_NAME));
}

VerifyEmailDialog.hide = function() {
    store.dispatch(ui.hide(DIALOG_NAME));
}

export default VerifyEmailDialog