//import { theme as defaultTheme } from "antd"
import theme from "../theme.json";

//console.log(defaultTheme)

const config = {
    //algorithm: theme.darkAlgorithm,
    token: {
        fontFamilyHeading1: theme.font.family.header,
        fontFamily: theme.font.family.default,
        colorPrimary: theme.color.primary,
        colorPrimaryHover: theme.color.primary + "dd",
        fontSize: parseInt(theme.font.size.base),
        controlHeightLG: 2.25 * parseInt(theme.font.size.base),
        controlHeight: 1.75 * parseInt(theme.font.size.base),
        controlHeightSM: 1.5 * parseInt(theme.font.size.base),
        inputFontSize: 12,
        inputFontSizeLG: 14,
        inputFontSizeSM: 12,
        colorLink: theme.color.primary,
        //boxShadowSecondary: "0 0 0 2px rgba(0, 0, 0, 0.03)",
        controlOutline: "none"
    },
    components: {
        Badge: {
            textFontSizeSM: 0.5 * parseInt(theme.font.size.base),
        },
        Button: {
            paddingBlock: 6,
            paddingInline: 15,
            contentFontSize: 14,
            contentFontSizeLG: 14,
            contentFontSizeSM: 12,
            contentLineHeight: 1,
            contentLineHeightLG: 1,
            contentLineHeightSM: 1,
        },
        Input: {
            activeShadow: "0 0 0 2px rgba(0, 0, 0, 0.03)",
            paddingBlock: 1,
            paddingBlockLG: 4,
            paddingBlockSM: 0,
            paddingInline: 12,
            inputFontSize: 12,
            inputFontSizeLG: 14,
            inputFontSizeSM: 12,
        },
        Select: {
            optionSelectedBg: "transparent"
        },
        Typography: {
            titleMarginBottom: 0,
            titleMarginTop: 0,
        },
        Message: {
            contentPadding: "9px 16px",
            contentBg: "#1D1D1DCC",
            colorText: "#FFFFFF",
            fontSize: 14, 
            lineHeight: 1
        },
        Avatar: {
            containerSize: "2rem",
            textFontSize: "0.875rem",
            containerSizeSM: "1.5rem",
            textFontSizeSM: "0.75rem"
        }
    }
}

//console.log("theme cfg", config)

export default config;