
// export default function getDefaultBackgroundSize(layout = {}, img, template, containerWidth, containerHeight) {

//     let row = (layout.row || 1) - 1,
//         col = (layout.col || 1) - 1;

//     if (template) {
//         // console.log(1)
//         let min = null, max = null, diff;
//         template.forEach(l => {
//             min === null && (min = max = l.row)
//             min > l.row && (min = l.row);
//             max < l.row && (max = l.row);
//             diff = max - min;
//         });
//         if (row > diff) {
//             row = diff;
//         }
//     }

//     let backSize = img.vertical ? 
//         (120 + (row * 50) - (col * 50)) :
//         100;

//     backSize < 100 && (backSize = 100);


//     return backSize;
// }

export default function getDefaultBackgroundSize(layout = {}, img, template, containerWidth = 100, containerHeight = 100) {
    const imgRatio = img.width / img.height;
    const containerRatio = containerWidth / containerHeight;

    let backSize;

    if (imgRatio > containerRatio) {
        backSize = 100;
    } else {
        backSize = (imgRatio / containerRatio) * 100
    }

    backSize = Math.round(backSize * 100) / 100;

    return backSize;
}