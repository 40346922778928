
export default function(draft) {

    if (draft.__normalized) {
        return product;
    }

    draft.data = JSON.parse(draft.data);
    draft.__normalized = true;

    return draft;
}