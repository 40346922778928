import getUrl from "common/src/lib/url/get"
import getQueryParam from "common/src/lib/url/getQueryParam"
import routes from "app/routes"


function initialRedirect() {

    const redir = getQueryParam("redir");

    if (!redir) {
        return false;
    }

    let params = getQueryParam("params");
    if (params) {
        params = JSON.parse(params);
    }
    else {
        params = {};
    }
    const url = getUrl(routes[redir], params);
    window.location = url;
    return true;
}

export default initialRedirect