import { useEffect, useState, useCallback } from "react";

import { Progress } from "antd"
import Button from "common/src/refactor/components/button/Button";

import { ReactComponent as IconDelete } from "common/src/svg/delete.svg"
import { ReactComponent as IconPause } from "common/src/svg/pause.svg"
import { ReactComponent as IconPlay } from "common/src/svg/play.svg"
import { ReactComponent as IconSend } from "common/src/svg/send.svg"

import useDictRef from "common/src/hooks/useDictRef"
import audioRecorder from "common/src/lib/audio/recorder"

function pad(num) {
    return ("" + num).padStart(2, '0');
}

function renderDuration(total) {
    const hours = Math.floor(total / 3600);
    const minutes = Math.floor((total - (hours * 3600)) / 60);
    const seconds = total - (hours * 3600) - (minutes * 60);
    let ts = `${ pad(minutes) }:${ pad(seconds) }`;
    if (hours > 0) {
        ts = `${ pad(hours) }:` + ts;
    }
    return ts;
}

function getPercent(secs) {
    const total = 5 * 60; // 5 min
    return secs / total * 100;
}

function AudioRecorder({ onError, onUpdateDuration, onRecord, setIsRecording, isRecording }) {
    const [ duration, setDuration ] = useState(0);
    const [ loading, setLoading ] = useState(false);
    const [ paused, setPaused ] = useState(false);
    const ref = useDictRef({ onError, onUpdateDuration, onRecord, isRecording, paused });


    const localOnUpdateDuration = useCallback(
        (d) => {
            setDuration(d);
            ref.onUpdateDuration && ref.onUpdateDuration(d);
        },
        [ ]
    );

    const startRecording = useCallback(
        async () => {
            setLoading(true);
            await audioRecorder.start();
            setLoading(false);
        },
        [ ] 
    );

    const onToggleRecord = useCallback(
        async () => {
            if (ref.paused) {
                await audioRecorder.togglePause(false);
                setPaused(false);
            } else {
                await audioRecorder.togglePause(true);
                setPaused(true);
            }
        },
        [ ref.paused ]
    );

    const onDeleteClick = useCallback(
        async () => {
            await audioRecorder.stop();
            audioRecorder.clear();
            setIsRecording(false);
        },
        [ ]
    );

    const onDoneClick = useCallback(
        async () => {
            await audioRecorder.stop();
            ref.onRecord && ref.onRecord(audioRecorder.getLastRecording());
            audioRecorder.clear();
            setIsRecording(false);
        },
        [ ]
    )

    useEffect(
        () => {
            startRecording();
        },
        [ ]
    );

    useEffect(
        () => {
            audioRecorder.onUpdateDuration(localOnUpdateDuration);
            audioRecorder.onError(onError);
        },
        [ onError, localOnUpdateDuration ]
    );

    return (
        <div className="audio-recorder">
            <div className="audio-recorder-actions">
                <Button
                    size="large"
                    type="transparent"
                    className="chat-form-input-button" 
                    Icon={ IconDelete }
                    onClick={ onDeleteClick }
                />
                <Button
                    size="large"
                    type="transparent"
                    className="chat-form-input-button" 
                    Icon={ !paused ? IconPause : IconPlay }
                    onClick={ onToggleRecord }
                />
            </div>

            <div className="audio-recorder-time">
                { loading ? 
                    "00:00" :
                    renderDuration(duration)
                }
            </div>

            <Progress percent={getPercent(duration)} showInfo={ false }/>

            <Button 
                size="large"
                disabled={ duration <= 0 }
                className="chat-form-input-button" 
                type="transparent" 
                onClick={ onDoneClick }
                Icon={ IconSend }/>
        </div>
    )
}

export default AudioRecorder;