
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"

import Radio from "common/src/refactor/components/form/Radio";

function CatalogueProductSelect({ id, selected, onChange }) {

    const onClick = useSwallowEventCallback(
        () => onChange(id, !selected),
        [ selected, id, onChange ]
    );

    return (
        <a href="/#" 
            className="catalogue-product-card-select"
            onClick={ onClick }>
            <Radio checked={ selected } size="medium"/>
        </a>
    )
}

export default CatalogueProductSelect