
import api from "app/api"
import user from "common/src/user"
import store from "app/store"
import { data } from "common/src/store/user"


export async function checkContacts(contacts, notForCustomerId) {

    let exists = false;
    let type = null;

    for (let i = 0, l = contacts.length; i < l; i++) {
        let contact = contacts[ i ];

        if (!contact) {
            continue;
        }
        
        if (contact.type === "whatsapp") {
            exists = await checkContactExists(contact, null, notForCustomerId);
            if (exists) {
                type = contact.type;
                break;
            }
        }
    }

    return type || null;
}

export async function checkContactExists(c, forCustomerId = null, notForCustomerId = null) {

    const res = await api.backend.post("/me/check-contact-exists", { body: {
        contact: c,
        forCustomerId,
        notForCustomerId
    }});

    return res.found || false;
}


export async function createContact(c, userId) {
    
    const ex = await checkContactExists(c, userId);

    if (ex) {
        return ex;
    }
    else {
        c.userId = userId;
        const contact = { ...c };
        delete contact.id;
        const newc = await api.userContact.create(contact);
        return newc.id;
    }
}


export async function saveContacts(contacts, userId) {

    for (let i = 0, l = contacts.length; i < l; i++) {
        let contact = contacts[i];

        if (contact.id) {
            const payload = { ...contact };
            delete payload.id;
            await api.userContact.update(contact.id, payload);
        }
        else {
            await createContact(contact, userId || user.id());
        }
    }
}

export async function syncContacts(contacts, userId) {

    const where = { userId: { _eq: userId }};
    const exContacts = await api.userContact.list({ where });

    let i, l;

    for (i = 0, l = exContacts.length; i < l; i++) {
        const ex = exContacts[i];
        if (!contacts.find(c => c.type === ex.type)) {
            await removeContactType(ex.type, userId);
        }
    }

    for (i = 0, l = contacts.length; i < l; i++) {
        const c = contacts[i];
        const ex = exContacts.find(ex => ex.type === c.type);

        if (!ex) {
            const contact = { 
                userId, 
                type: c.type, 
                value: c.value, 
                normalized: c.normalized,
                notifications: false //c.notifications === undefined ? true : c.notifications 
            };
            await api.userContact.create(contact);
        }
        else {
            const contact = { 
                value: c.value, 
                normalized: c.normalized,
                notifications: false //c.notifications === undefined ? true : c.notifications
            };
            await api.userContact.update(ex.id, contact);
        }
    }
}

export async function removeContactType(type, userId) {

    const where = {
        userId: { _eq: userId || user.id() },
        type: { _eq: type }
    }
    await api.userContact.remove(where);

}

export async function reload() {

    const where = { userId: { _eq: user.id() }};
    const contacts = await api.userContact.list({ where });
    store.dispatch(data.current.update({ contacts }));
}

export function getUserContacts(user) {
        const order = [ "email", "phone", "whatsapp" ];

        let contacts = user.contacts.map(c => ({ ...c }));

        contacts = contacts
                    .sort((a, b) => {
                        const inx1 = order.indexOf(a.type);
                        const inx2 = order.indexOf(b.type);
                        return inx1 < inx2 ? 
                                    -1 : 
                                    inx1 === inx2 ? 0 : 1;
                    });

        if (!contacts.find(c => c.type === "email")) {
            contacts.unshift({
                id: null,
                type: "email",
                value: user.email,
                notifications: false, //true,
                allowDelete: false
            });
        }
        else {
            let email = contacts.find(c => c.type === "email");
            if (email.normalized === user.email.toLowerCase()) {
                email.allowDelete = false;
            }
        }

        return contacts;
}

export function normalizeContact(c) {

    const { type, value, code } = c;
    
    if (type === "phone" || type === "whatsapp") {
        return (code + value).replace(/[^0-9+]/, '');
    }
    else if (type === "email") {
        return value.toLowerCase();
    }
    
    return value;
}

export function splitNumber(number = "") {
    const parts = (number || "").split(" ", 2);
    const code = parts[0] || "+44";
    const value = parts[1] || "";
    return [ code, value ];
}


export function getDefaultEmailContact(current) {
    return {
        type: "email",
        value: current?.email,
        normalized: (current?.email || "").toLowerCase(),
        notifications: false
    }
}

export function getDefaultPhoneContact(current) {
    return {
        type: "phone",
        value: current?.phone,
        normalized: (current?.phone || "").replace(/[^0-9+]/, '').toLowerCase(),
        notifications: false
    }
}
