
let timer;
let duration = 0;
let timerCallback = null;
let errorCallback = null;

let stream = null;
let mediaRecorder = null;
let blobs = [];
let lastRecording = null;

const setDuration = (d) => {
    duration = d;
    timerCallback && timerCallback(duration);
}

const startTimer = () => {
    setDuration(0);
    timer = window.setInterval(() => setDuration(duration + 1), 1000);
};

const resumeTimer = () => {
    timer = window.setInterval(() => setDuration(duration + 1), 1000);
}

const stopTimer = () => {
    if (timer) {
        window.clearInterval(timer);
        timer = 0;
    }
};

const onError = (code, message) => {
    errorCallback && errorCallback(code, message);
}

const getAudioStream = async () => {
    try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        return stream;
    }
    catch (error) {
        //if (error.message.includes("mediaDevices API or getUserMedia method is not supported in this browser.")) {       
        //    console.log("To record audio, use browsers like Chrome and Firefox.");
        //}

        //Error handling structure
        switch (error.name) {
            case 'AbortError': //error from navigator.mediaDevices.getUserMedia
                console.error("An AbortError has occured.");
                break;
            case 'NotAllowedError': //error from navigator.mediaDevices.getUserMedia
                console.error("A NotAllowedError has occured. User might have denied permission.");
                break;
            case 'NotFoundError': //error from navigator.mediaDevices.getUserMedia
                console.error("A NotFoundError has occured.");
                break;
            case 'NotReadableError': //error from navigator.mediaDevices.getUserMedia
                console.error("A NotReadableError has occured.");
                break;
            case 'SecurityError': //error from navigator.mediaDevices.getUserMedia or 
                                    //from the MediaRecorder.start
                console.error("A SecurityError has occured.");
                break;
            case 'TypeError': //error from navigator.mediaDevices.getUserMedia
                console.error("A TypeError has occured.");
                break;
            case 'InvalidStateError': //error from the MediaRecorder.start
                console.error("An InvalidStateError has occured.");
                break;
            case 'UnknownError': //error from the MediaRecorder.start
                console.error("An UnknownError has occured.");
                break;
            default:
                console.error("An error occured with the error name " + error.name);
        }

        onError(error.name, "");
    
        return null;
    }
};

const startMediaRecorder = () => {
    try {
        mediaRecorder.start();
        return true;
    }
    catch (error) {
        console.error(error.name, error.message);
        onError(error.name);
        return false;
    }
}

const stopRecorder = function() {
    mediaRecorder && mediaRecorder.stop();
    stream && stream.getTracks().forEach(track => track.stop());
};

const getRecording = function() {
    return new Promise((resolve) => {
        const mimeType = mediaRecorder.mimeType;
        mediaRecorder.addEventListener("stop", () => {
            //create a single blob object, as we might have gathered a few Blob objects 
            // that needs to be joined as one
            const audioBlob = new Blob(blobs, { type: mimeType });
            //resolve promise with the single audio blob representing the recorded audio
            resolve(audioBlob);
        });

        stopRecorder();
    });
};

const getPausedRecoding = function() {
    mediaRecorder.requestData();

    return new Promise((resolve) => {
        const mimeType = mediaRecorder.mimeType;
        mediaRecorder.addEventListener("pause", () => {
            const audioBlob = new Blob(blobs, { type: mimeType });
            resolve(audioBlob);
        })

        mediaRecorder.pause()
    })
}

const resetRecorder = () => {
    blobs = [];
    mediaRecorder = null;
    stream = null;
}

const recorder = {

    isSupported: () => {
        return !!navigator.mediaDevices && !!navigator.mediaDevices.getUserMedia;
    },

    start: async () => {
        if (mediaRecorder && mediaRecorder.state !== "inactive") {
            return;
        }
        blobs = [];
        lastRecording = null;
        stream = await getAudioStream();
        if (stream) {
            mediaRecorder = new MediaRecorder(stream);
            mediaRecorder.addEventListener("dataavailable", event => blobs.push(event.data));

            if (startMediaRecorder()) {
                startTimer();
            }
            else {
                mediaRecorder = null;
                stream = null;
            }
        }
    },

    stop: async () => {
        if (!mediaRecorder) {
            return;
        }
        if (mediaRecorder.state === "inactive") {
            return;
        }
        lastRecording = await getRecording();
        resetRecorder();
        stopTimer();
        return lastRecording;
    },

    togglePause: async (flag) => {

        if (!mediaRecorder) {
            return;
        }

        if (!flag) {
            mediaRecorder.resume();
            resumeTimer();
        }
        else {
            lastRecording = await getPausedRecoding();
            stopTimer();
        }
    },

    isRecording: () => {
        return !!mediaRecorder && mediaRecorder.state === "recording";
    },

    getLastRecording() {
        return lastRecording;
    },

    clear: () => {
        stopTimer();
        setDuration(0);
        timerCallback = null;
        errorCallback = null;
        lastRecording = null;
    },
    
    onUpdateDuration(cb) {
        timerCallback = cb;
    },

    onError(cb) {
        errorCallback = cb;
    }

}

export default recorder;