import { useCallback, useContext, useState } from "react"
import { useOn } from "@kuindji/observable-react"
import { useSelector } from "react-redux"
import { App as AntApp } from "antd"

import PageCatalogue from "./search/Catalogue"
import PageURL from "./search/URL"
import PageMoodboards from "./search/Moodboards"
import PageMoodboard from "./search/Moodboard"
import PageProduct from "./search/Product"
import AddToMoodboard from "./search/AddToMoodboard"

import { PortalHost } from "common/src/refactor/components/Portal"

import { ReactComponent as IconClose } from "common/src/svg/close.svg"
import { ReactComponent as IconLink } from "common/src/svg/link.svg"
import { ReactComponent as IconMoodboard } from "common/src/svg/favorite.svg"
import { ReactComponent as IconSearch } from "common/src/svg/search.svg"
import LookBuilderDialog from "./Dialog"

import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import LookBuilderContext from "./LookBuilderContext"
import useKey from "common/src/refactor/hooks/useKey"
import swallowEvent from "common/src/lib/dom/swallowEvent"
import appContext from "app/appContext"
import hub from "common/src/hub"

export const SEARCH_DIALOG_NAME = "look-builder-search";

function LookBuilderSearch() {

    const { message } = AntApp.useApp();
    const builder = useContext(LookBuilderContext);
    const page = useKey("searchPage", builder);
    const look = useSelector(s => s.lookEditor.look);
    const dialogExpanded = useKey("dialogExpanded", builder);
    const [ withFooter, setWithFooter ] = useState(false);

    const onClose = useSwallowEventCallback(
        () => {
            builder.set("currentModal", null);
        },
        [ builder ]
    );

    /*const toggleFullscreen = useSwallowEventCallback(
        () => {
            builder.set("dialogExpanded", !dialogExpanded);
        },
        [ dialogExpanded ]
    );*/

    const onPageClick = useCallback(
        (e) => {
            swallowEvent(e);
            builder.openPage(e.target.dataset.page);
        },
        [ builder ]
    );

    const onSelectMoodboard = useCallback(
        (m) => {
            builder.set("searchMoodboard", m);
            builder.openPage("moodboard");
        },
        [ builder ]
    );

    const onSelectProduct = useCallback(
        (product, source) => {
            if (look.productMode === "tagged" && source !== "by-url") {

                const p = { ...product };
                p.images = product.images.map(img => typeof img === "string" ? 
                                                        { src: img } : 
                                                        { ...img });

                message.success({
                    content: 'Product added to your Look',
                    icon: <></>
                });
                builder.trigger("add-product", p);
                hub.dispatch("look-builder", "product-used");
            }
            else {
                builder.set("searchProduct", product);
                builder.openPage("product");
            }
        },
        [ builder, look, message ]
    );

    const onFooterPortalChange = useCallback(
        (children) => setWithFooter(!!children),
        []
    );

    useOn(builder, "search-select-moodboard", onSelectMoodboard);
    useOn(builder, "search-select-product", onSelectProduct);
    useOn(appContext, "portal-look-builder-search-footer", onFooterPortalChange);

    return (
        <LookBuilderDialog 
            name={ SEARCH_DIALOG_NAME } 
            expanded={ dialogExpanded }
            withFooter={ withFooter }>
            { page !== "product" && <div className="look-builder-dialog-header">
                {/*<a href="/#" 
                    className="expand"
                    onClick={ toggleFullscreen }>
                    { dialogExpanded ? <IconCollapse/> : <IconExpand/> }
                </a>*/}
                <h4>Select products</h4>
                <a href="/#" 
                    data-page="catalogue"
                    className={ `look-builder-dialog-header-item ${ page === "catalogue" ? "active" : "" }` }
                    onClick={ onPageClick }>
                    <IconSearch/>Product Feed</a>
                <a href="/#"
                    data-page="url"
                    className={ `look-builder-dialog-header-item ${ page === "url" ? "active" : "" }` }
                    onClick={ onPageClick }>
                    <IconLink/>Add by URL</a>
                <a href="/#"
                    data-page="moodboards"
                    className={ `look-builder-dialog-header-item ${ page === "moodboards" || page === "moodboard" ? "active" : "" }` }
                    onClick={ onPageClick }>
                    <IconMoodboard/>Moodboard</a>
                <a href="/#" 
                    className="close"
                    onClick={ onClose }><IconClose/></a>
            </div> }
            <div className="look-builder-search-layout">
                <div className="look-builder-search-pages">
                    <PageCatalogue open={ page === "catalogue" }/>
                    <PageURL open={ page === "url" }/>
                    <PageMoodboards open={ page === "moodboards" }/>
                    <PageMoodboard open={ page === "moodboard" }/>
                    <PageProduct open={ page === "product" }/>
                    <AddToMoodboard open={ page === "add-to-moodboard" }/>
                </div>
            </div>
            <PortalHost name="look-builder-search-footer"/>
        </LookBuilderDialog>
    )
}

LookBuilderSearch.NAME = SEARCH_DIALOG_NAME;

export default LookBuilderSearch
