import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './app/App'
//import reportWebVitals from './reportWebVitals';
import store from "./app/store"
import { Provider } from "react-redux"
import initialRedirect from "common/src/lib/initialRedirect"

if (!initialRedirect()) {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
        <Provider store={ store }>
            <App/>
        </Provider>
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    //reportWebVitals();
}