import Button from "common/src/refactor/components/button/Button";

import { ReactComponent as IconSend } from "common/src/svg/send.svg"
import { ReactComponent as IconEdit } from "common/src/svg/edit.svg"
import { ReactComponent as IconDuplicate } from "common/src/svg/duplicate.svg"
import { ReactComponent as IconDelete } from "common/src/svg/delete.svg"

import useLookActions from "app/hooks/look/useLookActions";

function LookActions({ 
        look, consultation, editFrom, onDelete,
        showSend = true, showEdit = true, showDuplicate = true, showDelete = true,
    }) {
    const { 
        onDeleteClick, onDuplicateClick, onEditClick, onSendClick 
    } = useLookActions({ look, consultation, fromHref: editFrom, onDelete });

    return (
        <div className="look-actions">
            
            { (showSend && onSendClick) && 
            <Button 
                type="secondary"
                shape="circle"
                size="lmedium"
                Icon={ IconSend }
                onClick={ onSendClick }
                disabled={ !consultation?.customerId || consultation?.hidden || !look.published }
                text="Send"
                iconPosition="before"/> }
        
            { (showEdit && onEditClick) && 
            <Button 
                type="secondary"
                shape="circle"
                size="lmedium"
                Icon={ IconEdit }
                onClick={ onEditClick }
                text="Edit"
                iconPosition="before"/> }
        
            { (showDuplicate && onDuplicateClick) && <Button 
                type="secondary"
                shape="circle"
                size="lmedium"
                Icon={ IconDuplicate }
                onClick={ onDuplicateClick }
                text="Duplicate"
                iconPosition="before"/> }
        
            { (showDelete && onDeleteClick) && <Button 
                type="secondary"
                shape="circle"
                size="lmedium"
                Icon={ IconDelete }
                onClick={ onDeleteClick }
                text="Delete"
                iconPosition="before"/> }
        </div>
    )
}

export default LookActions;