import { useMemo, useContext } from "react"
import getProductUrl from "common/src/lib/catalogue/getProductUrl"

import LinkSourceContext from "common/src/refactor/lib/LinkSourceContext"

function useCatalogueProductUrl(product, referenceUserId = null, source = null) {
    const contextSource = useContext(LinkSourceContext);
    const productUrl = useMemo(
        () => product ? getProductUrl(product, referenceUserId, source || contextSource) : null,
        [ product, referenceUserId, source, contextSource ]
    );

    return productUrl;
}

export default useCatalogueProductUrl