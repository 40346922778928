import getDefaultBackgroundSize from "./getDefaultBackgroundSize"

export default function getBackgroundSize(layout = {}, img, template, containerWidth, containerHeight) {


    let backSize = getDefaultBackgroundSize(layout, img, template, containerWidth, containerHeight);
    backSize += (layout.zoom || 0);

    return backSize;
}

export function backSize2scale(bs, imageWidth, imageHeight, containerWidth, containerHeight) {

    if (!bs) {
        const imageRatio = imageWidth / imageHeight;
        const containerRatio = containerWidth / containerHeight;
      
        if (imageRatio > containerRatio) {
          return 1; 
        } else {
          return imageRatio / containerRatio; 
        }
    
    }

    return (bs / 100) + 1;
}