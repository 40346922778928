import { useCallback } from "react"


function useInputHander(handler) {

    const onChange = useCallback(
        (e) => handler(e.target.value),
        [ handler ]
    );

    return onChange;
}

export default useInputHander