import { useMemo, useCallback, useState } from "react"
import { Auth } from "@aws-amplify/auth"

import { Input, Typography } from "antd"

import Button from "../button/Button"
import FormInput from "../form/FormInput"
import { Form, FormContext, useFormFields } from "common/src/refactor/lib/form/Form"
import { ReactComponent as Logo } from "common/src/svg/full_logo.svg"
import Link from "common/src/components/Link"
import NullForm from "common/src/components/NullForm"


//import async from "common/src/lib/js/async"
import isEmptyObject from "common/src/lib/js/isEmptyObject"


import required from "common/src/refactor/lib/form/validator/required"
import validatePassword from "common/src/refactor/lib/form/validator/password"
import useDictRef from "common/src/hooks/useDictRef"


function validatePasswordConfirm(password, _, values) {
    if (!password) {
        return null;
    }
    return password === values.password;
}


const codeFormFields = [
    {
        name: "password",
        validator: [ 
            [ required, "Please enter your new password" ],
            [ validatePassword ],
        ],
        defaultValue: ""
    },
    {
        name: "password2",
        validator: [ 
            [ required, "Please confirm your password" ],
            [ validatePasswordConfirm, "Passwords don't match" ],
        ],
        defaultValue: ""
    },
];

/*
function objectWithProperties(obj, keys) {
	const target = {};
	for (const key in obj) {
		if (keys.indexOf(key) === -1) {
			continue;
		}
		if (!Object.prototype.hasOwnProperty.call(obj, key)) {
			continue;
		}
		target[key] = obj[key];
	}
	return target;
}*/


function RequireNewPassword({ onAuthStateChange, onError, authData }) {
    const [ loading, setLoading ] = useState(false);
    const form = useMemo(() => new Form(codeFormFields), []);
    const { password, passwordChange, passwordError,
            password2, password2Change, password2Error } = 
        useFormFields(["code", "password", "password2"], form);

    const ref = useDictRef({ password });

    const onBackClick = useCallback(
        () => onAuthStateChange('signIn'),
        [ onAuthStateChange ]
    );

    const checkContact = useCallback(
        (user) => {
            Auth.verifiedContact(user).then(data => {
                if (!isEmptyObject(data.verified)) {
                    onAuthStateChange('signedIn', user);
                } 
                else {
                    console.error("Contact verification is not implemented")
                }
            });
        },
        [ onAuthStateChange ]
    );

    const change = useCallback(
        async () => {
            const user = authData;
            const password = ref.password;
            //const { requiredAttributes } = user.challengeParam;
            //const attrs = objectWithProperties(this.state, requiredAttributes);
            setLoading(true);

            await Auth.completeNewPassword(user, password, {})
                .then(user => {
                    //logger.debug('complete new password', user);
                    if (user.challengeName === 'SMS_MFA') {
                        console.error("MFA not implemented");
                    } 
                    else if (user.challengeName === 'MFA_SETUP') {
                        console.error("MFA not implemented");
                    } 
                    else {
                        checkContact(user);
                    }
                })
                .catch(err => onError && onError(err));

            setLoading(false);
        },
        [ checkContact, authData ]
    );


    const onSubmit = useCallback(
        async () => {
            const valid = await form.validateAll();
            if (valid) {
                change();
            }
        },
        [ form, change ]
    );

    return (
        <FormContext.Provider value={ form }>
        <NullForm className="page-signin-form page-signin-newpass">
            
            <Link to="/"><Logo/></Link>
            <Typography.Title>Set your password</Typography.Title>

            <FormInput label="New password" error={ passwordError }>
                <Input.Password 
                    id="login-password"
                    autoCorrect="new-password" 
                    autoCapitalize="none"
                    value={ password }
                    disabled={ loading }
                    onPressEnter={ onSubmit }
                    onChange={ passwordChange }
                    type="password"
                    data-cy="login-form-password"/>

                    <ul className="password-rules">
                        <li><span>·</span> Password must be between 8 and 32 characters</li>
                        <li><span>·</span> Password must contain atleast one lower case letter, one upper case letter, one symbol and one digit</li>
                        <li><span>·</span> Password must not contain spaces </li>
                    </ul>
            </FormInput>

            <FormInput label="Confirm password" error={ password2Error }>
                <Input.Password 
                    id="confirm-password"
                    name="confirm-password"
                    autoCorrect="new-password-repeat" 
                    autoCapitalize="none"
                    value={ password2 }
                    disabled={ loading }
                    onPressEnter={ onSubmit }
                    onChange={ password2Change }
                    type="password"
                    data-cy="login-form-password2"/>
            </FormInput>

            <Button 
                data-cy="login-form-submit"
                id="login-submit"
                onClick={ onSubmit }
                loading={ loading }
                disabled={ loading }
                text="Set password"/>
            
            {/* <Button 
                type="text" 
                size="small" 
                disabled={ loading }
                text="Back to login"
                onClick={ onBackClick }/> */}
        </NullForm>
        </FormContext.Provider>
    )
}

export default RequireNewPassword
