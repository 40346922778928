
import { ReactComponent as Logo } from "common/src/svg/full_logo.svg"
import Link from "common/src/components/Link"
import { useState } from "react"
import hub from "common/src/hub";

function Footer() {
    const [ className, setClassName ] = useState("");

    hub.listen("footer", "set-class", setClassName);

    return (
        <div className={`footer ${ className }`}>
            <Link to="/"><Logo/></Link>
            <p>Copyright © 2023 The Floorr. The Floorr Ltd. is registered in England and Wales</p>
        </div>
    )
}

export default Footer