import { useCallback, useContext, useState } from "react"
import { Input, Space, App as AntApp } from "antd"

import Button from "common/src/refactor/components/button/Button"

import isURL from "validator/es/lib/isURL"
import * as productEditor from "common/src/actions/look/product"

import api from "app/api"
import LookBuilderContext from "../LookBuilderContext"
import Spacer from "common/src/refactor/components/Spacer"
import retailers from "common/src/lib/catalogue/retailers"


async function checkUrlRetailer(url) {
    const u = new URL(url);
    const hostname = u.hostname;

    for (const retailer of retailers) {
        if (retailer.matchUrls) {
            for (const part of retailer.matchUrls) {
                if (hostname.toLowerCase().indexOf(part.toLowerCase()) !== -1) {
                    if (retailer.visible === false) {
                        return false;
                    }
                    return true;
                }
            }
        }
    }

    return false;
}

function LookBuilderURL({ open = false }) {

    const builder = useContext(LookBuilderContext);
    const { message } = AntApp.useApp();
    const [ loading, setLoading ] = useState(false);
    const [ url, setUrl ] = useState("");
    const [ isValid, setIsValid ] = useState(false);

    const onLocalUrlMatch = useCallback(
        async (match) => {
            if (match.catalogueProductId) {
                const cproduct = await productEditor.getCatalogueProduct(
                    null, 
                    null,
                    match.catalogueProductId
                );
                if (cproduct) {
                    if (cproduct.images) {
                        cproduct.images = await productEditor.preloadProductImages(cproduct.images);
                    }

                    builder.trigger("search-select-product", cproduct);
                }
                else {
                    message.error({
                        content: "We can no longer find this product in the catalogue"
                    });
                }
            }
            else if (match.moodboardId && match.productId) {
                const mproduct = await api.catalogueProduct
                                        .list({ where: { id: { _eq: match.productId }}})
                                        .then(list => list[0]);
                if (mproduct) {
                    const cproduct = await productEditor.getCatalogueProduct(
                        mproduct.retailer, 
                        mproduct.productWebId
                    );
                    if (cproduct) {
                        if (cproduct.images) {
                            cproduct.images = await productEditor.preloadProductImages(cproduct.images);
                        }
                        builder.trigger("search-select-product", cproduct);
                    }
                    else {
                        message.error({
                            content: "We can no longer find this product in the catalogue"
                        });
                    }
                }
                else {
                    throw new Error(`Product ${ match.productId } not found`)
                }
            }
        },
        [ builder, message ]
    );


    const onSubmit = useCallback(
        async (value, e, { source }) => {

            if (source === "clear" || !url || !isValid) {
                return;
            }

            setLoading(true);
    
            if (url.indexOf(window.location.origin) === 0) {

                let res;
    
                if (url.indexOf("id=live-") !== -1) {
                    const match = url.match(/id=([^&/]+)/);
                    if (match) {
                        const catalogueProductId = match[1];
                        //if (props.onLocalUrlMatch) {
                            res = onLocalUrlMatch({ catalogueProductId });
                        //}
                    }
                }
                else if (url.indexOf("productId=") !== -1 && url.indexOf("moodboard") !== -1) {
                    const match1 = url.match(/moodboard\/([^?&/]+)/i);
                    const match2 = url.match(/productId=([^&/]+)/);
                    if (match1 && match2) {
                        const moodboardId = match1[1];
                        const productId = match2[1];
                        //if (props.onLocalUrlMatch) {
                            res = onLocalUrlMatch({ moodboardId, productId });
                        //}
                    }
                }
    
                if (res instanceof Promise) {
                    try {
                        await res;
                    }
                    catch (err) {
                        console.error(err);
                        message.error({
                            content: "Failed to add to a product from this link"
                        });
                    }
                }
    
                setLoading(false);
                return;
            }

            const retailerCheck = await checkUrlRetailer(window.decodeURI(url));
            if (retailerCheck === false) {
                message.error({
                    content: "URLs from this domain are not supported"
                });
                setLoading(false);
                return;
            }
    
            try {
                const product = await productEditor.scrapeUrl(window.decodeURI(url));
                if (product?.error) {
                    message.error({
                        content: "Failed to scrape the URL"
                    });
                    setLoading(false);
                    return;
                }
                //console.log(product)
                product.images = await productEditor.checkProductImages(product.images || []);
                setLoading(false);
                setUrl("");
                setIsValid(false);
                builder.trigger("search-select-product", product, "by-url");
            }
            catch (err) {
                console.log(err)
                setLoading(false);
            }
        },
        [ url, onLocalUrlMatch, builder, message, isValid ]
    );

    const onURLChange = useCallback(
        (e) => {
            let url = e.target.value;
            url = url.trim();
            if (url.indexOf("://") === -1 && url.length > 10) {
                url = "http://" + url;
            }
            if (url) {
                setUrl(url);
                setIsValid(isURL(url, { 
                    require_tld: window.location.hostname !== "localhost" 
                }))
            }
            else {
                setUrl(url);
                setIsValid(false);
            }
        },
        []
    );

    if (!open) {
        return null;
    }

    return (
        <div className="look-builder-search-url">
            <Spacer size="1rem"/>
            <Space.Compact>
                <Input.Search 
                    placeholder="Insert URL from the retailer's website"
                    size="large"
                    enterButton={
                        <Button 
                            //Component="a"
                            //href="/#"
                            //onClick={ e => e.preventDefault() }
                            text="Add URL" 
                            type="primary"
                            loading={ loading }
                            disabled={ !isValid || loading }/>
                    }
                    allowClear
                    disabled={ loading }
                    onPressEnter={ isValid ? onSubmit : null }
                    onChange={ onURLChange }
                    value={ url }
                    onSearch={ onSubmit }/>
            </Space.Compact>
            <div className="look-builder-search-url-trusted">
                <p>Currently, only URLs from our trusted brands are supported</p>
            </div>
        </div>
    )
}

export default LookBuilderURL
