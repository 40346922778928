import { useMemo } from "react"

function useSubComponent(DefaultComponent, defaultProps, overrides) {

    const subComponent = useMemo(
        () => {
            if (overrides === undefined || overrides === null || overrides === true) {
                return <DefaultComponent { ...defaultProps }/>
            }
            if (overrides === false) {
                return null;
            }
            if (typeof overrides === "object") {
                return <DefaultComponent { ...defaultProps } { ...overrides }/>
            }
            if (typeof overrides === "function") {
                return overrides(defaultProps);
            }
        },
        [ DefaultComponent, defaultProps, overrides ]
    );

    return subComponent
}

export default useSubComponent