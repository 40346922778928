
import { createReducer } from "@reduxjs/toolkit"
import * as lm from "common/src/lib/store/listManager"
import { createAction } from "@reduxjs/toolkit"


/* 
exploring ways to simplify (not make it more complex!) 
redux interactions
*/


class StoreProperty {

    name = null
    fullName = null
    parent = null
    action = null
    children = {}
    leaf = null

    root = null
    actions = null

    constructor(name, parent, reducer) {
        this.name = name;
        this.actions = parent.actions[name] = {};
        this.parent = parent;

        if (parent instanceof StoreModule) {
            this.root = parent;
            this.fullName = parent.name + "/" + name;
        }
        else {
            this.root = parent.root;
            this.fullName = parent.fullName + "/" + name;
        }

        if (reducer) {
            this.setReducer(reducer);
        }
    }

    setReducer(reducer) {
        if (this.leaf === false) {
            throw new Error(`Cannot set reducer to the node ${this.name}`)
        }
        if (typeof reducer !== "function") {
            throw new Error(`StoreProperty can only accept functions; ${this.name}`)
        }

        this.action = createAction(this.fullName);
        this.leaf = true;
        this.parent.actions[this.name] = this.action;
        this.root.reducers[this.action] = reducer;
        //console.log(this.action, reducer)
    }

    add(name, reducer) {
        if (this.leaf) {
            throw new Error(`Cannot add child property ${name} to the leaf ${this.name}`)
        }
        this.leaf = false;
        return this.children[name] = new StoreProperty(name, this, reducer);
    }

    sub() {
        let args = Array.from(arguments),
            name = args.shift(),
            prop = this.children[name];
        
        if (!prop) {
            throw new Error(`Cannot find child property ${name} to the leaf ${this.name}`)
        }

        return prop.add.apply(prop, args);
    }
}

const getDefaultListUi = () => ({
    loading: false,
    loaded: false,
    loadingMore: false,
    hasMore: false,
    search: null,
    filter: null,
    misc: {},
    params: {},
    pages: [],
    page: 0,
    count: 0,
    sortBy: null,
    sortDir: null
});

class StoreModule {

    name = ""
    state = null
    data = null
    ui = null
    reducer = null

    actions = {}
    defaults = {}
    reducers = {}

    constructor(name, initialState) {
        this.name = name;
        this.data = new StoreProperty("data", this);
        this.ui = new StoreProperty("ui", this);
        this.sets = new StoreProperty("sets", this);
        
        this.state = initialState || { 
            data: {},
            ui: {},
            sets: {}
        };
    }

    addList(name = "list", defaultData = [], defaultUi = {}, pkey=lm.DEFAULT_PKEY) {

        const lname = name;
        this.defaults[name] = { data: defaultData, ui: defaultUi, sets: {} };
        this.state.data[name] = defaultData;
        this.state.sets[name] = {};
        this.state.ui[name] = Object.assign({}, getDefaultListUi(), defaultUi);

        const data = this.data.add(name);
        const ui = this.ui.add(name);
        const sets = this.sets.add(name);

        data.add("set", (s, a) => { s.data[name] = a.payload; });
        data.add("replace", (s, a) => lm.replace(s.data[name], a.payload, pkey));
        data.add("add", (s, a) => lm.push(s.data[name], a.payload, pkey));
        data.add("unshift", (s, a) => lm.unshift(s.data[name], a.payload, pkey));
        data.add("append", (s, a) => lm.append(s.data[name], a.payload, pkey));
        data.add("merge", (s, a) => lm.merge(s.data[name], a.payload, pkey));
        data.add("remove", (s, a) => lm.remove(s.data[name], a.payload, null, pkey));
        data.add("update", (s, a) => lm.update(s.data[name], a.payload, pkey));
        data.add("move", (s, a) => lm.move(s.data[name], a.payload, pkey));
        data.add("sync", (s) => lm.syncWithSets(s.data[lname], s.sets[lname], pkey));
        data.add("updateField", (s, a) => {
            const { id, field, value } = a.payload;
            lm.update(s.data[name], id, field, value, pkey);
        });
        data.add("reset", s => { 
            s.data[name] = [ ...this.defaults[name].data ];
            s.sets[name] = {};
            s.ui[name] = Object.assign({}, 
                            getDefaultListUi(), 
                            this.defaults[name].ui
                        );
        });

        sets.add("init", (s, a) => {
            const name = a.payload;
            if (!s.sets[lname][name]) {
                s.sets[lname][name] = [];
            }
        });
        sets.add("set", (s, { payload: { name, value }}) => { 
            s.sets[lname][name] = value;
            lm.syncWithSets(s.data[lname], s.sets[lname], pkey);
        });
        sets.add("unset", (s, a) => { 
            delete s.sets[lname][a.payload];
            lm.syncWithSets(s.data[lname], s.sets[lname], pkey);
        });
        sets.add("add", (s, { payload: { name, value }}) => lm.push(s.sets[lname][name], value));
        sets.add("unshift", (s, { payload: { name, value }}) => lm.unshift(s.sets[lname][name], value));
        sets.add("append", (s, { payload: { name, value }}) => lm.append(s.sets[lname][name], value));
        sets.add("merge", (s, { payload: { name, value }}) => lm.merge(s.sets[lname][name], value));
        sets.add("remove", (s, { payload: { name, value }}) => lm.remove(s.sets[lname][name], value));
        sets.add("move", (s, { payload: { name, value }}) => lm.move(s.sets[lname][name], value));

        ui.add("loading", (s, a) => { s.ui[name].loading = a.payload });
        ui.add("loaded", (s, a) => { s.ui[name].loaded = a.payload });
        ui.add("loadingMore", (s, a) => { s.ui[name].loadingMore = a.payload });
        ui.add("hasMore", (s, a) => { s.ui[name].hasMore = a.payload });
        ui.add("params", (s, a) => { 
            s.ui[name].params = Object.assign({}, s.ui[name].params, a.payload);
        });
        ui.add("misc", (s, a) => { 
            s.ui[name].misc = Object.assign({}, s.ui[name].misc, a.payload);
        });
        ui.add("setMisc", (s, a) => { s.ui[name].misc = a.payload });
        ui.add("filter", (s, a) => { s.ui[name].filter = a.payload });
        ui.add("sortBy", (s, a) => { s.ui[name].sortBy = a.payload });
        ui.add("sortDir", (s, a) => { s.ui[name].sortDir = a.payload });
        ui.add("updateFilter", (s, a) => { 
            const { key, value } = a.payload;
            if (!s.ui[name].filter) {
                s.ui[name].filter = {};
            }
            s.ui[name].filter[key] = value;
        });
        ui.add("search", (s, a) => { s.ui[name].search = a.payload });
        ui.add("page", (s, a) => { s.ui[name].page = a.payload });
        ui.add("pages", (s, a) => {
            if (Array.isArray(a.payload)) {
                s.ui[name].pages = a.payload;
            } 
            else {
                s.ui[name].pages.push(a.payload);
            }
        });
        ui.add("count", (s, a) => { s.ui[name].count = a.payload });
    }

    addEntity(name, defaultValue = null) {
        this.state.data[name] = defaultValue;
        this.state.ui[name] = {
            loading: false
        };
        let data = this.data.add(name);
        let ui = this.ui.add(name);

        data.add("set", (s, a) => { s.data[name] = a.payload });
        data.add("reset", s => { 
            s.data[name] = defaultValue;
            s.ui[name] = {...this.state.ui[name] };
        });
        data.add("update", (s, a) => { s.data[name] = Object.assign({}, s.data[name], a.payload) });

        ui.add("loading", (s, a) => { s.ui[name].loading = a.payload });
        ui.add("update", (s, a) => { s.ui[name] = Object.assign({}, s.ui[name], a.payload) });
        ui.add("reset", s => { data.ui[name] = {...this.state.ui[name] }});
    }

    addSimpleEntity(name, initialValue = null) {
        this.state.data[name] = initialValue;
        let data = this.data.add(name);
        data.add("set", (s, a) => { s.data[name] = a.payload });
        data.add("reset", s => { s.data[name] = initialValue });
    }

    getReducer() {
        if (!this.reducer) {
            //this.reducer = createReducer(this.state, this.reducers);
            this.reducer = createReducer(this.state, (builder) => {
                const rs = this.reducers;
                Object.keys(rs).forEach(key => {
                    builder.addCase(key, rs[key]);
                })
            });
        }
        return this.reducer;
    }

    export() {
        return {
            initialState: this.state,
            data: this.actions.data,
            sets: this.actions.sets,
            ui: this.actions.ui,
            reducer: this.getReducer()
        }
    }
}

/*

let cs = new StoreModule("consultations");
cs.addList("list");

cs.state.current = null;
cs.data.add("current")

let initialState = cs.state,
    data = cs.data,
    ui = cs.ui,
    reducer = cs.getReducer();

* data.list.set([])
* ui.list.loading(true)


export { initialState, data, ui, reducer }

*/


export default StoreModule

