import { Spin } from "antd"
import { useCallback, useEffect, useMemo, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"

import ProductCard from "common/src/refactor/components/catalogue/product/Card"
import CatalogueProductDrawer from "common/src/refactor/components/catalogue/product/Drawer"
import FeedToolbar from "app/components/feed/FeedToolbar"
import EmptyMessage from "common/src/refactor/components/EmptyMessage"
import ProductContextMenu from "app/components/product/ContextMenu"
import BackToTop from "common/src/refactor/components/catalogue/BackToTop"
import { ReactComponent as IconUp } from "common/src/svg/angle-up.svg"
import Button from 'common/src/refactor/components/button/Button';

import { CatalogueFilterContextProvider, createCatalogueFilterContext } 
    from "common/src/refactor/components/catalogue/Context"

import { filterQueryParams } from "common/src/refactor/lib/catalogue"
import useProductCatalogue from "common/src/refactor/hooks/useProductCatalogue"
import Spacer from "common/src/refactor/components/Spacer"
import Page from "common/src/refactor/components/Page"
import useContextData from "common/src/refactor/hooks/useContextData"
import useDualState from "common/src/refactor/hooks/useDualState"
import useIsMobile from "common/src/refactor/hooks/useIsMobile"
import DownloadApp from "common/src/refactor/components/authenticator/DownloadApp"
import QRTip from "app/components/feed/QR"
import user from "common/src/user"
import api from "common/src/api"
import Banner from "app/components/feed/Banner"

function isRetailerSearch(query, retailer) {
    return query ? query.indexOf(retailer) !== -1 : false
}


function PageFeed() {

    const { filters: initialFilters } = useDualState({ params: filterQueryParams, mode: "query" });
    // eslint-disable-next-line
    const filterContext = useMemo(() => createCatalogueFilterContext(initialFilters), []);
    const filters = useContextData(filterContext);
    const isMobile = useIsMobile();
    const [ showQR, setShowQR ] = useState(false);
    
    const { products, loadMore, hasMore, loading, loaded } = 
        useProductCatalogue({ filters, setName: "feed", perPage: 25 });

    const onProductClick = useCallback((p) => CatalogueProductDrawer.show({ product: p }), []);

    const userInfo = useCallback(
        async () => {
            const id = user.id();
            const { firstLoggedIn, lastLoggedIn } = await api.user.get(id, 'firstLoggedIn lastLoggedIn');
            const lastDate = new Date(lastLoggedIn);
            const firstDate = new Date(firstLoggedIn);

            if (localStorage.getItem("showQR") === "false") {
                setShowQR(false);
            }
            else if (lastDate - firstDate <= 2 * 60 * 1000) {
                setShowQR(true);
                localStorage.setItem("showQR", true);
            }
        }, 
        [ ]
    )

    useEffect(() => {
        userInfo();
    }, [ userInfo ]);

    if (isMobile) {
        return (
            <DownloadApp/>
        )
    }

    return (
        <Page className="page-feed" loading={ loading && products.length === 0 }>
        { showQR && <QRTip onClose={ setShowQR }/> }
        <CatalogueFilterContextProvider value={ filterContext }>
            <Banner/>
            <FeedToolbar useQueryString/>
            <Spacer size="xlarge"/>
            <InfiniteScroll 
                className="page-feed-scroller page-feed-scroller-full-page"
                dataLength={ products.length }
                hasMore={ hasMore }
                next={ loadMore }
                loader={ products.length > 0 ? 
                            <Spin spinning className="infinite-scroll-spinner"/> : 
                            null }>
                { (loaded && !loading && products.length === 0 && !isRetailerSearch(filters.query, "bottegaveneta")) && <EmptyMessage message="No products matched your search" size="small"/> }
                { (loaded && !loading && products.length === 0 && isRetailerSearch(filters.query, "bottegaveneta")) && <EmptyMessage message="Search via URL is currently not available from Bottega Veneta website. Please use text search or filter by retailer." size="small"/> }
                <div className="catalogue-products">
                    { products.map(p => (
                        <ProductCard 
                            key={ p.uid } 
                            product={ p } 
                            onClick={ onProductClick }>
                            <ProductContextMenu product={ p }/>
                        </ProductCard> ))}
                </div>
            </InfiniteScroll>
            <BackToTop>
                 <Button
                    type="secondary"
                    shape="circle"
                    Icon={ IconUp }
                    text="Back to top"
                    iconPosition="before"/>
            </BackToTop>
            <Spacer size="3rem"/>
        </CatalogueFilterContextProvider>
        </Page>
    )
}

export default PageFeed