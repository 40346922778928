import hub from "common/src/hub"
import user from "common/src/user"
import store from "app/store"
import api from "app/api"
import { ui } from "app/store/notifications"


let subscriptions = {
    "new": null,
    "del": null
};

const initNewSubscription = async (authState) => {

    if (user.is("Admin")) {
        if (authState) {
            const where = { 
                friId: { _eq: user.id() },
            };
            subscriptions['new'] = await api.consultation.subscribeCount({ where }, null, async (res) => {

                const count = res.count;
                const state = store.getState();

                if (state.consNotifications.ui.consultationsCount !== count) {
                    store.dispatch(ui.setConsultationsCount(count));
                    hub.dispatch("consultations", "count-change"); 
                }
            });
        }
    }
};

/*
const initDelSubscription = async (authState) => {

    if (user.is("Admin")) {
        if (authState) {
            const where = { 
                status: { _in: [ "cancelRequest" ]}
            };
            subscriptions['del'] = await api.consultation.subscribeCount({ where }, null, async (res) => {

                const count = res.count;
                const state = store.getState();

                if (state.consNotifications.ui.delConsultationsCount !== count) {
                    store.dispatch(ui.setDelConsultationsCount(count));
                    hub.dispatch("consultations", "del-count-change"); 
                }
            });
        }
    }
};
*/
function onBeforeSignout() {
    if (subscriptions['new'] && subscriptions['new'].subscription) {
        subscriptions['new'].subscription.unsubscribe();
        subscriptions['new'] = null;
    }
    if (subscriptions['del'] && subscriptions['del'].subscription) {
        subscriptions['del'].subscription.unsubscribe();
        subscriptions['del'] = null;
    }
}


const listeners = {

    init: () => {
        hub.listen("app-auth", "before-signout", onBeforeSignout);
        hub.listen("app-auth", "stateChange", initNewSubscription);
        //hub.listen("app-auth", "stateChange", initDelSubscription);
    }
}

export default listeners