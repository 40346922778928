import { getPosthogClient } from "common/src/lib/analytics/posthog"
import hub from "common/src/hub"
import user from "common/src/user"

function posthogOnUserInfoLoaded() {
    const posthog = getPosthogClient();
    const data = user.current();
    posthog?.identify(user.id(), {
        email: data.email,
        name: data.givenName + " " + data.familyName
    });
}

function posthogOnAuthState(state) {
    const posthog = getPosthogClient();
    if (!state) {
        posthog.reset();
    }
}

function appEventListener(payload, event, channel) {
    getPosthogClient()?.capture(
        `event_api_${ channel.replace(/-/g, "_") }_${ event.replace(/-/g, "_") }`,
        typeof payload === "object" ? payload : {
            payload
        }
    )
}

function onPosthogEvent(data) {
    const { event, payload } = data;
    getPosthogClient()?.capture(
        `event_${ event }`,
        typeof payload === "object" ? payload : {
            payload
        }
    )
}

const listeners = {
    init: () => {

        !hub.hasListener("app-auth", "info-loaded", posthogOnUserInfoLoaded) && 
            hub.listen("app-auth", "info-loaded", posthogOnUserInfoLoaded);
        !hub.hasListener("app-auth", "stateChange", posthogOnAuthState) && 
            hub.listen("app-auth", "stateChange", posthogOnAuthState);

        ["catalogue", "catalogue-product", "product", "chat", "connection",
            "consultation", "moodboard", "notifications", "look", "user"].forEach(channel => {
            !hub.hasListener(channel, "*", appEventListener) && 
                hub.listen(channel, "*", appEventListener);
        });
        !hub.hasListener("posthog", "event", onPosthogEvent) && 
            hub.listen("posthog", "event", onPosthogEvent);

        !hub.hasListener("app", "currency-change", appEventListener) && 
                hub.listen("app", "currency-change", appEventListener);
    }
}

export default listeners