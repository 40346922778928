import { useCallback, useState } from "react"
import { useOn } from "@kuindji/observable-react"
import useUpdateEffect from "common/src/hooks/useUpdateEffect";

function useKeys(keys, ctx, namespace) {
    const [ value, setValue ] = useState(() => ctx ? ctx.get(keys, namespace) : {});

    const onChange = useCallback(
        (changedKeys) => {
            let found = false;
            if (Array.isArray(keys)) {
                found = keys.find(k => changedKeys.indexOf(k) !== -1);
            }
            else {
                const names = Object.keys(keys);
                found = names.find(k => changedKeys.indexOf(k) !== -1);
            }
            if (found) {
                setValue(ctx ? ctx.get(keys, namespace) : {})
            }
        },
        [ keys, ctx, namespace ]
    );

    useUpdateEffect(
        () => {
            setValue(ctx ? ctx.get(keys, namespace) : {});
        },
        [ ctx, Array.isArray(keys) ? keys.join("-") : Object.keys(keys).join("-"), namespace ]
    )

    useOn(
        ctx, 
        namespace ? `${ namespace }:change` : "change", 
        onChange
    );

    return value;
}

export default useKeys