import { useCallback, useContext, useMemo } from "react"
import LookBuilderContext from "./LookBuilderContext"
import useKey from "common/src/refactor/hooks/useKey"


function LookBuilderDialog({ name, children, expanded = false, withFooter = false }) {

    const builder = useContext(LookBuilderContext);
    const currentModal = useKey("currentModal", builder);

    const cls = useMemo(
        () => [ 
            "look-builder-dialog", 
            expanded ? "expanded" : "",
            withFooter ? "with-footer" : ""
        ].join(" "),
        [ expanded, withFooter ]
    );

    const style = useMemo(
        () => {
            return ({
                display: name === currentModal ? "flex" : "none"
            })
        },
        [ name, currentModal ]
    );

    const onDialogBodyClick = useCallback(
        (e) => {
            e.stopPropagation();
        },
        []
    );

    return (
        <div className={ cls } style={ style } onClick={ onDialogBodyClick }>
            { children }
        </div>
    )
}

export default LookBuilderDialog