import { useMemo } from "react"

function Typing({ size = 6 }) {
    const mainStyle = useMemo(
        () => ({
            width: (size * 3) + 4
        }),
        []
    )
    const dotStyle = useMemo(
        () => ({
            width: 6,
            height: 6,
            borderRadius: size / 2
        }),
        []
    );
    return (
        <span className="animation-typing" style={ mainStyle }>
            <span className="animation-typing-1" style={ dotStyle }></span>
            <span className="animation-typing-2" style={ dotStyle }></span>
            <span className="animation-typing-3" style={ dotStyle }></span>
        </span>
    )
}

export default Typing