
import pageConsultations from "./page/consultations"
import pageConsultation from "./page/consultation"
//import pageLook from "./page/look"
//import pageLooks from "./page/looks"
import pageCreate from "./page/createLook"
import auth from "./auth"
import consultations from "./consultations"

import onlineState from "common/src/listeners/web/onlineState"

//import catalogue from "common/src/listeners/catalogue"
import chat from "common/src/listeners/chat"
import notifications from "common/src/listeners/notifications"

import webAuth from "common/src/listeners/web/auth"
import webCookies from "common/src/listeners/web/cookies"
import posthog from "common/src/listeners/posthog"
import userAnalytics from "common/src/listeners/userAnalytics"


const listeners = {
    init: function() {

        auth.init();
        webAuth.init();
        webCookies.init();
        
        consultations.init();
        
        pageConsultation.init();
        pageConsultations.init();
        //pageLook.init();
        //pageLooks.init();
        pageCreate.init();

        //catalogue.init();
        chat.init();
        notifications.init();

        onlineState.init();
        posthog.init();
        userAnalytics.init();
    }
}

export default listeners