//import api from "../index"
import hub from "common/src/hub"
import generators from "./generate"
import * as normalizers from "../normalize"
import client from "common/src/api/hasura/client"
import getUserContext from "../getUserContext"
import gql from "graphql-tag"
import prepareWhere from "common/src/lib/prepareWhere"
import logger from "common/src/logger"
import user from "common/src/user"
import async from "common/src/lib/js/async"
import { default as getWsClient, invalidateWsClient } from "../getWsClient"
//import AnalyticsTiming from "common/src/lib/analytics/Timing"
//import entities from "./entities"

let AnalyticsTiming;
const LONG_QUERY = 3000;

const errHandler = (err, query, vars) => {
    console.log(query);
    logger.log(typeof err === "string" ? { message: err } : err, { query, vars });
    return Promise.reject(err);
}

const timeLogger = (query, variables, userId, startTime, result) => {
    const now = (new Date()).getTime();
    if (now - startTime > LONG_QUERY) {
        logger.log(new Error("Slow query"), {
            type: "slow-query",
            query,
            variables, 
            userId, 
            time: now - startTime, 
            result
        });
    }
}

/**
 * 
 * @param {*} authRole 
 * @param {*} g current graph
 */
const getGraph = (authRole, g) => {
    if (!g) {
        return null;
    }
    if (typeof g === "string") {
        return g;
    }
    return g[authRole] || g['*'] || null;
}

export const prepareGraph = (authRole, graph) => {
    let currMode = authRole === "anonymous" ? "anonymous" : "auth";
    graph = graph.replace(
        /<(auth|anonymous)>([\s\S]*?)<\/(auth|anonymous)>/gm,
        function(match, mode, content) {
            return mode === currMode ? content : "";
        }
    );
    graph = graph.replace(
        /<(Admin|FRI|GPS)>([\s\S]*?)<\/(Admin|FRI|GPS)>/gm,
        function(match, grp, content) {
            return user.is(grp) ? content : "";
        }
    )
    graph = graph.replace(
        /<([^>]+)>([\s\S]*?)<\/\1>/gm,
        function(match, grps, content) {
            grps = grps.split("|")
            return user.is(grps) ? content : "";
        }
    )
    graph = graph.replace(
        /#([a-zA-Z_]+)/igm,
        function(match, param) {
            switch (param) {
                case "userId": {
                    return user.id();
                }
                default: {
                    return "";
                }
            }
        }
    );
    return graph;
}


const prepareQuery = (query, context, g, dg, countGraph) => {
    let authRole = context.headers['X-Hasura-Role'];
    let graph = getGraph(authRole, g) || getGraph(authRole, dg);
    graph = prepareGraph(authRole, graph);
    query = query.replace('---graph---', graph);
    query = query.replace('---countGraph---', countGraph);

    return query;
};

const defHandler = (response, dataName, entity, dataType) => {

    if (response.errors) {

        let jwsError = false;

        response.errors.forEach(err => {
            let msg = err.message;
            if (msg.match(/jwserror/i)) {
                jwsError = true;
                hub.dispatch("app-auth", "signout-required");
                async(() => hub.dispatch("app", "redir", "signin"));
            }
        });
        
        if (jwsError) {
            throw new Error(response.errors[0].message);
        }

        hub.dispatch("error", "graphql", {
            entity, dataName,
            errors: response.errors
        });

        if (!response.data || !response.data[dataName]) {
            return null;
        }
    }

    let data, normalizer = normalizers[entity];

    try {
        data = response.data[dataName];

        switch (dataType) {
            case "none": {
                break;
            }
            case "mutation": {
                break;
            }
            case "returning-one": {
                data = data["returning"][0];
                break;
            }
            case "returning-many": {
                data = data["returning"];
                break;
            }
            case "aggregate": {
                data = data["aggregate"];
                break;
            }
            case "items": {
                data = data.filter(i => i !== null);
                if (normalizer) {
                    data = data.map(normalizer);
                }
                break;
            }
            case "nodes": {
                data = data["nodes"];
                break;
            }
            case "response":
            default: {
                if (normalizer) {
                    data = normalizer(data);
                }
                break;
            }
        }
    
        return data;
    }
    catch (err) {
        hub.dispatch("error", "normalize", {
            entity, 
            dataName,
            dataType,
            data,
            error: err
        });
    }

    return null;
};

const createWrapper = ({ query, dataName, entity, dg, 
                        queryWithConstraint, ignoreConflict }) => {
    return (objects, graph = dg, conflict) => {

        if (conflict === false) {
            conflict = ignoreConflict;
        }

        let vars = { objects, conflict };
        let gquery = (conflict ? queryWithConstraint : query)
                        .replace('---graph---', graph);

        return getUserContext()
            .then(context => {
                return AnalyticsTiming.get().trackAsync(`graphql/${ entity }/create`)(
                    () => client.mutate({mutation: gql(gquery), variables: vars, context})
                )
            })
            .catch(err => errHandler(err, gquery, vars))
            .then(response => defHandler(
                response, 
                dataName, 
                entity, 
                graph === "affected_rows" ?
                    "none":
                    Array.isArray(objects) ? "returning-many" : "returning-one"
            ));
    }
};

const updateWrapper = ({ query, dataName, entity, dg }) => {
    return (where, data, graph) => {

        let vars = {};
        let gquery = query.replace('---graph---', graph || dg);

        where = typeof where === "string" ? 
                    {id: {_eq: where}} :
                    where;
        where = prepareWhere(where);

        vars['where'] = where;
        vars['set'] = data;
    
        return getUserContext()
            .then(context => {
                return AnalyticsTiming.get().trackAsync(`graphql/${ entity }/update`)(
                    () => client.mutate({mutation: gql(gquery), variables: vars, context})
                )
            })
            .catch(err => errHandler(err, gquery, vars))
            .then(response => defHandler(response, dataName, entity, "mutation"));
    }
}


const removeWrapper = ({ query, dataName, entity, dg }) => {
    return (where, graph) => {

        if (typeof where === "string") {
            where = {id: {_eq: where}};
        }
        where = prepareWhere(where);

        let vars = { where };
        let gquery = query.replace('---graph---', graph || dg);

        return getUserContext()
            .then(context => {
                return AnalyticsTiming.get().trackAsync(`graphql/${ entity }/remove`)(
                    () => client.mutate({mutation: gql(gquery), variables: vars, context})
                )
            })
            .then(response => defHandler(response, dataName, entity, "mutation"))
            .catch(err => errHandler(err, gquery, vars));
    }
}

const getWrapper = ({ query, dataName, entity, dg }) => {
    return (id, graph) => {
        let /*time,*/ gquery, variables = { id };
        return getUserContext()
            .then(context => {
                //time = (new Date()).getTime()
                gquery = prepareQuery(query, context, graph, dg);
                return AnalyticsTiming.get().trackAsync(`graphql/${ entity }/get`)(
                    () => client.query({ query: gql(gquery), variables, context })
                )
            })
            .catch(err => {
                //timeLogger(gquery, variables, user.id(), time, "error");
                return errHandler(err, gquery, variables);
            })
            .then(response => {
                //timeLogger(gquery, variables, user.id(), time, "success");
                return defHandler(response, dataName, entity, "response");
            });
    }
}

const listWrapper = ({ query, dataName, entity, dg, queryWithCount }) => {
    return (input = {}, graph, withCount = false) => {
        if (input.where) {
            input.where = prepareWhere(input.where);
        }
        let /*time,*/ gquery, variables = input;
        return getUserContext()
            .then(context => {
                //time = (new Date()).getTime();
                gquery = prepareQuery(withCount ? queryWithCount : query, 
                                        context, 
                                        graph, 
                                        dg,
                                        typeof withCount === "string" ?
                                            withCount :
                                            "count");
                return AnalyticsTiming.get().trackAsync(`graphql/${ entity }/list`)(
                    () => client.query({ query: gql(gquery), variables, context })
                );
                //return client.query({ query: gql(gquery), variables, context });
            })
            .catch(err => {
                //timeLogger(gquery, variables, user.id(), time, "error");
                return errHandler(err, gquery, input);
            })
            .then(response => {
                //timeLogger(gquery, variables, user.id(), time, "success");
                const items = defHandler(response, dataName, entity, "items");

                if (withCount) {
                    const agg = response.data[dataName + "_aggregate"].aggregate;
                    const res = { items };

                    if (withCount === true) {
                        res.count = agg.count;
                    }
                    else if(typeof withCount === "string") {
                        res.aggregate = agg;
                    }

                    return res;
                }

                return items;
            });
    }
}


const countWrapper = ({ query, dataName, entity, dg }) => {
    return (input = {}, graph) => {
        if (input.where) {
            input.where = prepareWhere(input.where);
        }
        let /*time,*/ variables = input;
        let gquery = query.replace('---graph---', graph || dg);
        return getUserContext()
            .then(context => {
                //time = (new Date()).getTime();
                return AnalyticsTiming.get().trackAsync(`graphql/${ entity }/count`)(
                    () => client.query({ query: gql(gquery), variables, context })
                )
            })
            .catch(err => {
                //timeLogger(gquery, variables, user.id(), time, "error");
                return errHandler(err, gquery, variables);
            })
            .then(response => {
                //timeLogger(gquery, variables, user.id(), time, "success");
                return defHandler(response, dataName, entity, "aggregate");
            })
    }
}

export const customQuery = (query, variables) => {
    let time;
    return getUserContext()
            .then(context => {
                //time = (new Date()).getTime();
                return AnalyticsTiming.get().trackAsync("graphql/custom-query", { query })(
                    () => client.query({ query: gql(query), variables, context })
                )
            })
            .catch(err => {
                //timeLogger(query, variables, user.id(), time, "error");
                return errHandler(err, query, variables);
            })
            .then(response => {
                //timeLogger(query, variables, user.id(), time, "success");
                return response;
            })
}

export const distinctValues = (table, column, variables = {}) => {
    const { query, responseKey } = generators.distinctValue(table, column);
    variables.distinct = column;
    return getUserContext()
            .then(context => {
                return AnalyticsTiming.get().trackAsync("graphql/distinct-query", { query })(
                    () => client.query({ query: gql(query), variables, context })
                )
            })
            .then(response => defHandler(response, responseKey, table, "nodes"))
            .then(nodes => nodes.map(node => node[column]));
}


class Subscription {
    query = null
    dataName = null 
    entity = null
    dg = null 

    observer = null
    subscription = null 

    constructor(opt) {
        Object.assign(this, opt);
    }

    prepareVariables(input) {
        if (input && input.where) {
            input.where = prepareWhere(input.where);
        }
        return input;
    }

    processResponse(response) {
        return response;
    }

    subscribe(input = {}, graph, next) {

        const errorHandler = async (err) => {
            console.log("gql subscription error");
            console.log("message: ", err?.message);
            console.log(err); 
            await (new Promise(resolve => setTimeout(resolve, 500)));
            if (err && err.message && 
                (err.message.match(/jwtexpired/i) ||
                 err.message.match(/frame received after close/i))) {
                console.log("re-subscribing on expired token")
                invalidateWsClient();
                this.subscribe(input, graph, next);
            }
            else {
                console.log("invalidating client")
                invalidateWsClient();
                this.subscribe(input, graph, next);
            }
        }

        return getWsClient()
            .then(client => getUserContext().then(context => [client, context]))
            .then(async ([client, context]) => {

                try {
                    this.observer = client.subscribe({
                        query: gql(prepareQuery(this.query, context, graph, this.dg)), 
                        variables: this.prepareVariables(input)
                    });
                }
                catch (err) {
                    await errorHandler(err);
                    return null;
                }

                if (next) {
                    this.subscription = this.observer.subscribe({
                        next: response => {
                            next(this.processResponse(response));
                        },
                        error: errorHandler
                    });
                }

                return this;
            })
            .catch(err => {
                console.log(err);
                return Promise.reject(err);
            });
    };

    unsubscribe() {
        this.subscription && this.subscription.unsubscribe();
        this.subscription = null;
        this.observer = null;
    }
}


class ListSubscription extends Subscription {
    processResponse(response) {
        return defHandler(response, this.dataName, this.entity, "items");
    }
}

class CountSubscription extends Subscription {
    processResponse(response) {
        return defHandler(response, this.dataName, this.entity, "aggregate");
    }
}

const countSubscription = ({ query, dataName, entity, dg }) => {
    return (input = {}, graph, next) => {
        const cs = new CountSubscription({ query, dataName, entity, dg });
        return cs.subscribe(input, graph, next);
    };
};

const listSubscription = ({ query, dataName, entity, dg }) => {
    return (input = {}, graph, next) => {
        const cs = new ListSubscription({ query, dataName, entity, dg });
        return cs.subscribe(input, graph, next);
    };
};


const wrappers = {
    "create": createWrapper,
    "update": updateWrapper,
    "remove": removeWrapper,
    "list": listWrapper,
    "get": getWrapper,
    "count": countWrapper,
    "subscribeList": listSubscription,
    "subscribeCount": countSubscription
}

let lc = function(name) {
    return name[0].toLowerCase() + name.substring(1);
}

//api.customGqlQuery = customQuery;
//export customQuery;

export const init = (api, entities, timingModule) => {

    AnalyticsTiming = timingModule;

    const actions = {
        list: [ "list", "get", "count" ], 
        create: [ "create" ], 
        update: [ "update" ], 
        remove: [ "remove" ], 
        subscribe: [ "subscribeList", "subscribeCount" ]
    };

    Object.keys(entities).forEach(table => {
        const entity = entities[ table ];
        const entityKey = entity.apiName || lc(table).replace(/_/g, "");
        

        Object.keys(actions).forEach(group => {

            if (!entity[ group ]) {
                return;
            }

            actions[ group ].forEach(action => {

                let graph = entity[ action ] || 
                            null;

                if ((action === "subscribeList" || action === "get") && graph === null) {
                    graph = entity.list || null;
                }

                if (graph === true) {
                    graph = null;
                }

                if (!api[entityKey]) {
                    api[entityKey] = {};
                }

                // creating placeholder function
                // first time it is called, it will replace itself 
                // with api wrapper and call the wrapper
                api[entityKey][action] = function() {
                    add(api, table, action, graph, {}, entity.apiName);
                    return api[entityKey][action].apply(null, Array.from(arguments));
                }
            })
        })
    });
}

const add = function(api, table, action, graph, opts = {}, apiName=null) {

    const entity = apiName || lc(table).replace(/_/g, "");
    const wrapper = wrappers[action];
    const actionName = action;
    const getAction = lc(action.replace("subscribe", ""));
    const generator = generators[getAction];
    action.indexOf("subscribe") === 0 && (opts.subscription = true);
    
    const { query, 
            queryType,
            responseKey, 
            ignoreConflict, 
            defaultGraph, 
            defaultAuthGraph,
            queryWithCount,
            queryWithConstraint } = generator(table, graph, opts);

    if (!api[entity]) {
        api[entity] = {};
    }

    api[entity][actionName] = wrapper(
        {   query, 
            dataName: responseKey, 
            entity, 
            dg: defaultGraph, 
            dgAuth: defaultAuthGraph,
            queryWithCount,
            queryWithConstraint,
            table,
            ignoreConflict,
            queryType
        }
    );
}


export default add;