import createActionMap, { map2builder } from "common/src/lib/store/createActionMap.js";
import { createReducer } from "@reduxjs/toolkit"


let initialRequest = {
    title: "",
    contacts: {},
    description: "",
    due: null,
    sizes: "",
    customerId: null
}

let initialState = {
    request: {...initialRequest},
    ui: {
        request: {
            submitting: false
        }
    }
}

let data = createActionMap("consultation.request/", 
    ["start", "update", "reset"])


let ui = createActionMap("consultations/ui/", 
    ["submitting"],
);



let reducer = createReducer(initialState, (builder) => {
    map2builder(builder, {
    
        [data.start]: (state, action) => {
            state.request = {...initialRequest};
        },
        [data.update]: (state, action) => {
            Object.assign(state.request, action.payload);
        },
        [data.reset]: (state, action) => {
            state.request = {...initialRequest};
        },
    
        [ui.submitting]: (state, action) => {
            state.ui.request.submitting = action.payload;
        }
    })
});

export { reducer, data, ui, initialState }