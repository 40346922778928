import { initialLayout } from "common/src/store/look/editor"

const getLayout = (layout, currentImage = 0) => {

    if (layout && layout[currentImage]) {
        return { ...layout[currentImage] };
    }

    if (layout && layout.h && layout.v) {
        return { ...layout };
    }

    /*if (Array.isArray(layout)) {
        if (layout[currentImage]) {
            return { ...layout[currentImage] };
        }
    }
    else {
        if (layout && layout[currentImage]) {
            return { ...layout[currentImage] };
        }
    }*/

    return { ...initialLayout };
}


export default getLayout