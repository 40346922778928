
import hub from "common/src/hub"


function onGraphqlError(data) {
    console.log("api error", data)
}

function onNormalizeError(data) {
    console.log("normalize error", data)
}

export default {
    init: function() {
        hub.listen("error", "graphql", onGraphqlError);
        hub.listen("error", "normalize", onNormalizeError);
    }
}