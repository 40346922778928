import { useMemo } from "react";


function cssPfx2js(jsPfx) {
    return jsPfx.replace(/-([a-z])/, (match, letter) => {
        return letter.toUpperCase();
    }) || "";
}

function prepareList(classList) {
    if (!classList) {
        return [];
    }
    const list = Array.isArray(classList) ? classList : [ classList ];
    return list.flat().flat().filter(c => !!c).filter((c,inx,self) => self.indexOf(c) === inx);
}

function extendStyle(styles, extension) {
    if (!extension) {
        return;
    }
    if (Array.isArray(extension)) {
        extension.forEach(s => Object.assign(styles.style, s));
    }
    else {
        Object.assign(styles.style, extension)
    }
}


function useStyle({ className: unmemoizedClassName, 
                    children: unmemoizedChildren, 
                    variants: unmemoizedVariants, 
                    extend }, deps) {

    const className = useMemo(
        () => prepareList(unmemoizedClassName),
        [ unmemoizedClassName ]
    );

    const children = useMemo(
        () => prepareList(unmemoizedChildren),
        [ unmemoizedChildren ]
    );

    const variants = useMemo(
        () => prepareList(unmemoizedVariants),
        [ unmemoizedVariants ]
    );

    return useMemo(
        () => {
            const classMap = {};
            const extensions = extend || {};
 
            [ null, ...children ].forEach(ch => {
                const name = ch === null ? "main" : cssPfx2js(ch);
                const chPostfix = ch === null ? "" : `__${ ch }`;
                classMap[name] = {
                    className: [],
                    style: {}
                };
            
                className.forEach(cl => {
                    const cls = cl + chPostfix;
                    classMap[name].className.push(cls);
                    extendStyle(classMap[name], extensions[cls]);

                    variants.forEach(v => {
                        const cls = `${ cl }${ chPostfix }--${ v }`;
                        classMap[name].className.push(cls);
                        extendStyle(classMap[name], extensions[cls]);
                    });
                    
                    if (variants.length > 1) {
                        const cls = `${ cl }${ chPostfix }--${ variants.join("--") }`;
                        classMap[name].className.push(cls);
                        extendStyle(classMap[name], extensions[cls]);
                    }
                });

                //classMap[name] = classMap[name].filter(s => !!s);
            });

            for (const ch in classMap) {
                classMap[ch].className = classMap[ch].className.join(" ");
            }
            return classMap;
        },
        deps || [ className.join("-"), children.join("-"), variants.join("-"), extend ]
    );

}

export default useStyle