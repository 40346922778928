import createActionMap, { map2builder } from "common/src/lib/store/createActionMap.js";
import { createReducer } from "@reduxjs/toolkit"

const initialState = {
    search: {
        tags: []
    },
    session: {
        referenceUserId: null
    },
    currentFilters: [],
    url: {},
    ui: {
        theme: "light",
        menu: false,
        overlay: false,
        showFilters: false,
        dialogs: {}
    }
}

const data = createActionMap("app/", {
    "search/": {
        "tags/": ["set"]
    },
    "session/": ["set"],
    "url/": ["set"],
    "currentFilters": "."
});

const ui = createActionMap("app/ui/", {
    "theme": ".",
    "overlay": ".",
    "menu": ".",
    "showFilters": "."
});


let reducer = createReducer(initialState, (builder) => {
    map2builder(builder, {

        [data.search.tags.set]: (s, a) => {
            s.search.tags = a.payload;
        },
        [data.currentFilters]: (s, a) => {
            s.currentFilters = a.payload;
        },
    
        [data.url.set]: (s, a) => {
            if (a.payload.value === undefined) {
                delete s.url[ a.payload.key ];
            }
            else {
                s.url[ a.payload.key ] = a.payload.value;
            }
        },
    
        [data.session.set]: (s, a) => {
            s.session = Object.assign(s.session, a.payload);
        },
    
        [ui.theme]: (state, action) => {
            state.ui.theme = action.payload;
        },
        [ui.overlay]: (state, action) => {
            state.ui.overlay = action.payload;
        },
        [ui.menu]: (state, action) => {
            state.ui.menu = action.payload;
        },
        [ui.showFilters]: (state, action) => {
            state.ui.showFilters = action.payload;
        }
    })
});

export { reducer, data, ui, initialState }
