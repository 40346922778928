import { useState, useCallback, useContext } from "react"
import { useOn } from "@kuindji/observable-react"

import Message from "../messages/Message"
import { ReactComponent as IconClose } from "common/src/svg/close.svg"

import ChatContext from "common/src/lib/chat/ChatContext"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"


function Reply() {

    const chat = useContext(ChatContext);
    const [ , setEventCounter ] = useState(0);
    const [ message, setMessage ] = useState(() => chat.getAttachmentValue("reply"));

    const onAttachmentsChange = useCallback(
        () => setMessage(chat.getAttachmentValue("reply")), 
        [ chat ]
    );

    const onRemoveAttachmentClick = useSwallowEventCallback(
        () => chat.removeAttachment("reply"),
        [ chat ]
    );

    useOn(chat, "chat", setEventCounter);
    useOn(chat, "attachments", onAttachmentsChange);

    if (!message) {
        return null;
    }

    return (
        <div className="chat-form-reply">
            <Message showInfo={ false } message={ message }/>
            <a href="/#" className="close" onClick={ onRemoveAttachmentClick }><IconClose/></a>
        </div>
    )
}

export default Reply