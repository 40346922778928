import useDictRef from "common/src/hooks/useDictRef";
import Page from "common/src/refactor/components/Page";
import { useCallback, useEffect, useState } from "react";
import * as actions from "common/src/actions/recentlyDeleted"
import user from "common/src/user";
import ConsultationCard from "common/src/refactor/components/consultation/Card";
import MoodboardCard from "common/src/refactor/components/moodboard/Card";
import LookCard from "common/src/refactor/components/look/Card"
import LookCardFooter from "common/src/refactor/components/look/Footer"
//import { ReactComponent as IconTag } from "common/src/svg/tag.svg"
//import useDateFormat from "common/src/refactor/hooks/useDateFormat";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import DeletedContextMenu from "app/components/recently-deleted/ContextMenu";
import useHub from "common/src/hooks/useHub";

function Badge({ days, type }) {
    return (
        <div className={`page-recently-deleted-badge ${ type }`}>
            { days } {`${ days === 1 ? "Day" : "Days" }`}
        </div> 
    )
}

function LookItem({ look, children }) {

    return (
        <div className="page-recently-deleted-look-card">
            <LookCard look={ look } showTags={ false }>
                { children }
            </LookCard>
            <LookCardFooter look={ look } showDate={ false }/>
        </div>
    )
}

function PageRecentlyDeleted() {
    const [ deleted, setDeleted ] = useState([]);
    const [ loading, setLoading ] = useState(false);

    const ref = useDictRef({
        deleted
    });

    const loadDeleted = useCallback(
        async () => {
            setLoading(true);
            const deleted = await actions.deletedLoader({ userId: user.id() });
            
            setDeleted(deleted);
            setLoading(false);
        },
        [ ]
    );

    useEffect(() => {
        loadDeleted();

        // eslint-disable-next-line
    }, [  ])

    const onItemsUpdated = useCallback(
        ({ type, id }) => {
            let newItems;
            if (type === "look") {
                newItems = ref.deleted.filter(el => el.lookId !== id)
            }

            if (type === "moodboard") {
                newItems = ref.deleted.filter(el => el.moodboardId !== id)
            }

            if (type === "consultation") {
                newItems = ref.deleted.filter(el => el.consultationId !== id)
            }

            setDeleted(newItems);
        },
        [ ref.deleted ]
    )

    useHub("recently-deleted", "updated", onItemsUpdated);

    const renderItem = (item) => {
        const deletedAt = new Date(item.deletedAt);
        const today = new Date();

        const difference = today < deletedAt ? 30 : 30 - Math.floor((today - deletedAt) / (1000 * 3600 * 24));

        if (item.type === "consultation") {
            return (
                <ConsultationCard
                    key={ item.consultationId }
                    cons={ item.consultation }
                >
                    <Badge days={ difference } type={ item.type }/>
                    <DeletedContextMenu item={ item }/>
                </ConsultationCard>
            )
        }

        if (item.type === "moodboard") {
            return (
                <MoodboardCard
                    key={ item.moodboardId }
                    moodboard={ item.moodboard }
                    onClick="default"
                    fromRecentlyDeleted
                >
                    <Badge days={ difference } type={ item.type } />
                    <DeletedContextMenu item={ item }/>
                </MoodboardCard>
            )
        }

        if (item.type === "look") {
            return (
                <LookItem
                    look={ item.look }
                    key={ item.lookId }
                >
                    <Badge days={ difference } type={ item.type }/>
                    <DeletedContextMenu item={ item }/>
                </LookItem>
            )
        }

        return null;

    }

    return (
        <Page className="page-recently-deleted" loading={ loading }>
            <div className="header">
                <h2>Recently deleted</h2>
                <p>Only you can see these posts. They will be permanently deleted after the number of days shown. After that you wont be able to restore them.</p>
            </div>

            <div className="page-recently-deleted-list">
            <ResponsiveMasonry columnsCountBreakPoints={{250: 1, 400: 2, 600: 3, 800: 4, 1000: 5}}>
                <Masonry columnsCount={ 5 } gutter="20px">
                    { deleted.map((d) => renderItem(d)) }
                </Masonry>
            </ResponsiveMasonry>
            </div>
        </Page>
    )
}

export default PageRecentlyDeleted;