
import normalizeLook from "./look"
import normalizeProduct from "./product"
import s3url from "common/src/lib/image/s3url"

export default function normalizeConsultation(cons) {

    if (cons.__normalized) {
        return cons;
    }

    if (cons.products) {
        cons.products = cons.products.map(up => {
            up.product = normalizeProduct(up.product);
            up.product.selectedSizes = up.sizes;
            return up;
        });
    }

    if (cons.images) {
        cons.images = cons.images.map(i => {
            const img = JSON.parse(i.image);
            img.url = s3url(img.key);
            img.src = img.url;
            img.id = i.id;
            img.comment = i.comment;
            return img;
        });
    }

    if (cons.looks) {
        cons.looks = cons.looks.map(l => {
            l = normalizeLook(l);
            if (l.consultation) {
                l.consultation = normalizeConsultation(l.consultation);
            }
            return l;
        });
    }

    if (cons.lastMessage) {
        cons.lastMessage = cons.lastMessage[0] || null;
    }

    if (cons.unreadCount) {
        cons.unreadCount = cons.unreadCount.aggregate.count;
    }

    if (cons.publishedLooksCount) {
        cons.publishedLooksCount = cons.publishedLooksCount.aggregate.count;
    }
    if (cons.looksCount) {
        cons.looksCount = cons.looksCount.aggregate.count;
    }

    if (cons.contacts) {
        if (typeof cons.contacts === "string" && (cons.contacts[0] === '[' || cons.contacts[0] === '{')) {
            cons.contacts = JSON.parse(cons.contacts);
        }
        else {
            cons.contacts = [];
        }

        if (!Array.isArray(cons.contacts)) {
            cons.contacts = [];
        }
        
        /*if (typeof cons.contacts === "string") {
            if (cons.contacts[0] === '[') {
                cons.contacts = JSON.parse(cons.contacts);
            }
            else if (cons.contacts[0] === '{') {
                const cs = JSON.parse(cons.contacts);
                cons.contacts = [];
                for (let key in cs) {
                    switch (key) {
                        case "other": {
                            cons.contacts.push({ key: "custom", custom: cs[key] });
                            break;
                        }
                        case "phone": {
                            cons.contacts.push({ key, number: cs[key], code: "+44" });
                            break;
                        }
                        case "whatsapp": {
                            cons.contacts.push({ key, number: cs[key], code: "+44" });
                            break;
                        }
                        case "email": {
                            cons.contacts.push({ key, email: cs[key] });
                            break;
                        }
                        default: {}
                    }
                }
            }
            else {
                cons.contacts = [{ key: "custom", custom: cons.contacts }];
            }
        }*/
    }
    else {
        cons.contacts = [];
    }

    if (cons.sizes) {
        
        if (typeof cons.sizes === "string") {
            if (cons.sizes[0] === '[') {
                cons.sizes = JSON.parse(cons.sizes);
            }
            else {
                cons.sizes = [{ key: "default", comment: cons.sizes }];
            }
        }
    }
    else {
        cons.sizes = [];
    }

    cons.__normalized = true;

    return cons;
}