import { useMemo, useState, useCallback, useEffect } from "react"

import { ReactComponent as IconExpand } from "common/src/svg/expand.svg"
import { ReactComponent as IconClose } from "common/src/svg/clear.svg"

import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import useLocalStorage from "common/src/hooks/useLocalStorage"
import useUnicodeEmoji from "common/src/hooks/useUnicodeEmoji"
import hub from "common/src/hub"

import { omitWhere, groups, defaultRecents } from "common/src/lib/emojiPicker"


function Group(props) {

    const onClick = useSwallowEventCallback(
        () => props.onSelect(props.id), 
        [ props.onSelect, props.id ]
    );
    const cls = useMemo(
        () => [ "emoji-picker-group", props.isCurrent ? "current" : "" ].join(" "),
        [ props.isCurrent ]
    );

    return (
        <a href="/#" className={ cls } onClick={ onClick }>
            { props.icon }
        </a>
    )
}

function OneEmoji(props) {

    const onClick = useSwallowEventCallback(
        () => props.onSelect(props.emoji), 
        [ props.onSelect, props.emoji ]
    );

    return (
        <a href="/#" className="emoji-picker-emoji" onClick={ onClick }>
            { props.emoji }
        </a>
    )
}

function EmojiPicker(props) {

    const { showRecents = true, 
            closeable = false, 
            expandable = true, 
            deletable = false,
            className } = props;
    const [ expanded, setExpanded ] = useState(!showRecents);
    const [ category, setCategory ] = useState(groups[0].id);
    const unicodeEmoji = useUnicodeEmoji();
    const emojis = useMemo(
        () => unicodeEmoji?.getEmojis(omitWhere).filter(e => e.group === category) || [], 
        [ category, unicodeEmoji ]
    );
    const [ storedRecents, setStoredRecents ] = useLocalStorage("recent-emojis", []);

    const recents = useMemo(
        () => [ ...storedRecents, ...defaultRecents ]
                .filter((e, i, self) => self.indexOf(e) === i)
                .slice(0, 8), 
        [ storedRecents ]
    );

    const cls = useMemo(
        () => {
            const cls = [ "emoji-picker" ];
            className && cls.push(className);
            showRecents && cls.push("with-recents");
            expanded && cls.push("expanded");

            return cls.join(" ");
        },
        [ showRecents, expandable, expanded, className ]
    )

    const onExpandClick = useSwallowEventCallback(
        () => setExpanded(!expanded),
        [ expanded, setExpanded ]
    );

    const onCloseClick = useSwallowEventCallback(
        () => props.onClose(),
        [ props.onClose ]
    );

    const onEmojiSelect = useCallback(
        (e) => {
            props.onSelect(e);
            setStoredRecents(
                [ e, ...storedRecents ]
                    .filter((e, i, self) => self.indexOf(e) === i)
                    .slice(0, 8)
            );
        },
        [ storedRecents, setStoredRecents, props.onSelect ]
    );

    const onBodyClick = useCallback(
        () => {
            if (closeable && props.onClose) {
                props.onClose();
            }
        },
        []
    );

    const onSelfClick = useSwallowEventCallback(() => {}, []);

    useEffect(
        () => {
            hub.listen("app", "body-click", onBodyClick);
            return () => {
                hub.remove("app", "body-click", onBodyClick);
            }
        },
        []
    )

    return (
        <div className={ cls } onClick={ onSelfClick }>
            { showRecents && 
                <div className="emoji-picker-recents">
                    { deletable && 
                        <a href="/#" 
                            onClick={ props.onDelete }
                            className="emoji-picker-delete">
                            <IconClose/>
                        </a> }
                    { recents.map(e => 
                        <OneEmoji 
                            key={ e } 
                            emoji={ e } 
                            onSelect={ onEmojiSelect }/>)}
                    { expandable && 
                        <a href="/#" 
                            onClick={ onExpandClick }
                            className="emoji-recents-expand">
                            <IconExpand/>
                        </a> }
                    { closeable  && 
                        <a href="/#" 
                            onClick={ onCloseClick }
                            className="emoji-recents-close">
                            <IconClose/>
                        </a> }
                </div> }
            { expanded && 
                <>
                <div className="emoji-picker-groups">
                    { groups.map(g => 
                        <Group 
                            id={ g.id }
                            key={ g.id } 
                            icon={ g.icon }
                            isCurrent={ category === g.id }
                            onSelect={ setCategory }/> )}
                </div>
                <div className="emoji-picker-emojis">
                    { emojis.map(e => 
                        <OneEmoji 
                            emoji={ e.emoji } 
                            key={ e.emoji }
                            onSelect={ onEmojiSelect }/> )}
                </div>  
                </> }
        </div>
    )
}

export default EmojiPicker