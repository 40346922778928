import hub from "common/src/hub"
//import store from "app/store"
//import { ui } from "common/src/store/dialogs"
//import async from "common/src/lib/js/async"
import user from "common/src/user"
import { subscribeToUserConnections, 
            unsubscribeFromUserConnections,
            clearConnections } from "common/src/actions/connections"
import { subscribeToUserData, unsubscribeFromUserData } from "common/src/actions/user"


const initConnectionsListener = async (authState) => {
    if (authState) {
        if (user.isOnly("Admin")) {
            subscribeToUserConnections("concierge");
        }
        else {
            subscribeToUserConnections("shopper");
        }
    }
};


const initUserDataListener = async (authState) => {
    if (authState) {
        subscribeToUserData(user.id());
    }
    else {
        unsubscribeFromUserData();
    }
}

// const onAuthInfoLoaded = () => {

//     const state = store.getState();
//     const user = state.user.current;

//     if (user.groups.indexOf("FRI") !== -1 && 
//         !user.friTerms && 
//         window.location.pathname.indexOf("/fri-terms") !== 0) {
//         async(() => store.dispatch(ui.show("fri-terms")));
//     }
// }

function onBeforeSignout() {

    try {
        unsubscribeFromUserConnections();
    }
    catch (err) { console.log(err) }

    clearConnections();
}


const listeners = {
    init: () => {
        //!hub.hasListener("app-auth", "stateChange", onAuthStateChange) && 
        //    hub.listen("app-auth", "stateChange", onAuthStateChange);
        //hub.listen("app-auth", "info-loaded", onAuthInfoLoaded);
        !hub.hasListener("app-auth", "stateChange", initConnectionsListener) && 
            hub.listen("app-auth", "stateChange", initConnectionsListener);
        !hub.hasListener("app-auth", "before-signout", onBeforeSignout) && 
            hub.listen("app-auth", "before-signout", onBeforeSignout);

        !hub.hasListener("app-auth", "stateChange", initUserDataListener) && 
            hub.listen("app-auth", "stateChange", initUserDataListener);
    }
}

export default listeners