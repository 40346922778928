import { useMemo } from "react";
import { createContext } from "react"

const LinkSourceContext = createContext();

export default LinkSourceContext

export function LinkSourceContextProvider({ type, id, children }) {

    const value = useMemo(
        () => ({ type, id }),
        [ type, id ]
    );

    return (
        <LinkSourceContext.Provider value={ value }>
            { children }
        </LinkSourceContext.Provider>
    )
}
