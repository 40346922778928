
import findParent from "common/src/lib/dom/findParent"

export const TIP_SIZE = 14;
export const TIP_OFFSET = 10;
export const TIP_CENTER_OFFSET = 12 + TIP_OFFSET;

export function getDefaultTagLayout() {
    return ({
        tagPosition: 'top',
        tipPosition: 'bl',
        offset: {
            left: '100%',
            top: '100%',
            opacity: 0
        }
    })
}

export function position2coords({ tagPosition, tipPosition, point, tagWidth, tagHeight }) {

    let top, left;

    switch (tagPosition) {
        case "right": {
            left = point.left + TIP_SIZE;
            if (tipPosition === 'lt') {
                top = point.top - TIP_CENTER_OFFSET;
            }
            else {
                top = point.top - tagHeight + TIP_CENTER_OFFSET;
            }
            break;
        }
        case "bottom": {
            top = point.top + TIP_SIZE;
            if (tipPosition === 'tl') {
                left = point.left - TIP_CENTER_OFFSET;
            }
            else {
                left = point.left - tagWidth + TIP_CENTER_OFFSET;
            }
            break;
        }
        case "left": {
            left = point.left - tagWidth - TIP_SIZE;
            if (tipPosition === 'rt') {
                top = point.top - TIP_CENTER_OFFSET;
            }
            else {
                top = point.top - tagHeight + TIP_CENTER_OFFSET;
            }
            break;
        }
        // top
        default: {
            top = point.top - tagHeight - TIP_SIZE;
            if (tipPosition === 'bl') {
                left = point.left - TIP_CENTER_OFFSET;
            }
            else {
                left = point.left - tagWidth + TIP_CENTER_OFFSET;
            }
        }
    }

    return { top, left, opacity: 1 };
}

export function mouse2position({ pointX, pointY, mouseX, mouseY, tagWidth, tagHeight }) {
    let tagPosition = null, tipPosition = null;
    const xOffset = tagWidth / 4;
    const yOffset = tagHeight / 4;

    if (pointY - mouseY > yOffset) {
        tagPosition = "top";
        tipPosition = mouseX < pointX ? "br" : "bl";
    }
    else if (mouseY - pointY > yOffset) {
        tagPosition = "bottom";
        tipPosition = mouseX < pointX ? "tr" : "tl";
    }
    else if (pointX - mouseX > xOffset) {
        tagPosition = "left";
        tipPosition = mouseY > pointY ? "rt" : "rb";
    }
    else if (mouseX - pointX > xOffset) {
        tagPosition = "right";
        tipPosition = mouseY > pointY ? "lt" : "lb";
    }

    return { tagPosition, tipPosition };
}

export function findPosition({ point, 
                        tagWidth, tagHeight, 
                        lookWidth, lookHeight,
                        defaultTagPosition = "top", 
                        defaultTipPosition = "bl" }) {

    let iterations = 0;
    let tagPosition = defaultTagPosition, 
        tipPosition = defaultTipPosition;

    while (true) {
        const coords = position2coords({ tagPosition, tipPosition, tagWidth, tagHeight, point });
        iterations++;
        if (iterations > 5) {
            break;
        }

        if (coords.left < 0) {
            if (coords.top < tagHeight) {
                tagPosition = "bottom";
                tipPosition = "tl"
            } 
            else if (coords.top > lookHeight - (tagHeight + TIP_CENTER_OFFSET + TIP_SIZE)) {
                tagPosition = "top";
                tipPosition = "bl";
            }
            else {
                tagPosition = "right";
                tipPosition = "lt";
            }
            continue;
        }
        if (coords.top < 0) {
            tagPosition = "bottom";
            if (coords.left + tagWidth > lookWidth - (TIP_CENTER_OFFSET + TIP_SIZE)) {
                tipPosition = "tr";
            } else {
                tipPosition = "tl";
            }
            continue;
        }
        if (coords.left + tagWidth > lookWidth) {
            if (coords.top > lookHeight - (tagHeight + TIP_CENTER_OFFSET + TIP_SIZE)) {
                tagPosition = "top";
                tipPosition = "br";
            } else {
                tagPosition = "left";
                tipPosition = "rt";
            }
            continue;
        }
        if (coords.top + tagHeight > lookHeight) {
            tagPosition = "top";
            tipPosition = "bl";
            continue;
        }

        break;
    }

    

    /*

    if (tagPosition === "top" && point.top < tagHeight + TIP_SIZE) {
        tagPosition = 'bottom';
        tipPosition = tipPosition === 'br' ? 'tr' : 'tl';
    }
    else if (tagPosition === "bottom" && point.top + tagHeight + TIP_SIZE > lookHeight) {
        tagPosition = 'top';
        tipPosition = tipPosition === 'tr' ? 'br' : 'bl';
    }
    else if ((tagPosition === "top" || tagPosition === "bottom") && point.left < TIP_CENTER_OFFSET) {
        tagPosition = 'right';
        tipPosition = 'lt';
        if (lookHeight - point.top < tagHeight + TIP_SIZE) {
            tipPosition = 'lb';
        }
    }
    else if (tagPosition === "left" && point.left < tagWidth + TIP_SIZE) {
        tagPosition = 'right';
        tipPosition = 'lt';
        if (lookHeight - point.top < tagHeight + TIP_SIZE) {
            tipPosition = 'lb';
        }
    }
    else if ((tagPosition === "left" || tagPosition === "right") && 
                point.top + tagHeight + TIP_SIZE > lookHeight) {
        tagPosition = 'top';
        tipPosition = tagPosition === "left" ? 'bl' : 'br';
    }
    else if (tagPosition === "right" && lookWidth - point.left < tagWidth + TIP_SIZE) {
        tagPosition = 'left';
        tipPosition = 'rt';
        if (lookHeight - point.top < tagHeight + TIP_SIZE) {
            tipPosition = 'rb';
        }
    }

    if ((tipPosition === 'tl' || tipPosition === 'bl') && lookWidth - point.left < tagWidth) {
        tipPosition = tipPosition === 'tl' ? 'tr' : 'br';
    }*/

    return { tagPosition, tipPosition };
}


export function calculateTagPosition({ layout, point, tagEl, 
                                        lookWidth, lookHeight, 
                                        tagWidth, tagHeight }) {

    const defaultTagPosition = layout?.tagPosition || undefined;
    const defaultTipPosition = layout?.tipPosition || undefined;

    if (!tagWidth && tagEl) {
        const lookEl = findParent(tagEl, ".look-card-tagged");
        tagWidth = tagEl.offsetWidth;
        tagHeight = tagEl.offsetHeight;
        lookWidth = lookEl.offsetWidth;
        lookHeight = lookEl.offsetHeight;
    }

    if (!tagWidth || !lookWidth) {
        return {}
    }

    const pointOffset = {
        left: lookWidth * (point.x / 100),
        top: lookHeight * (point.y / 100)
    };

    const adjustedLayout = findPosition({
        tagWidth, tagHeight,
        lookWidth, lookHeight,
        point: pointOffset,
        defaultTagPosition, 
        defaultTipPosition
    });

    // console.log({
    //     tagWidth, tagHeight,
    //     lookWidth, lookHeight,
    //     point: pointOffset,
    //     tagPosition: adjustedLayout.tagPosition,
    //     tipPosition: adjustedLayout.tipPosition
    // })

    const offset = position2coords({
        tagWidth, tagHeight,
        lookWidth, lookHeight,
        point: pointOffset,
        tagPosition: adjustedLayout.tagPosition,
        tipPosition: adjustedLayout.tipPosition
    });

    return {
        adjustedLayout,
        offset
    }

    // setLayout({ ...adjustedLayout, offset });

    // if (ref.product) {
    //     dispatch(data.productTags.update({
    //         id: ref.product.id,
    //         layout: {
    //             point: ref.point,
    //             layout: adjustedLayout
    //         }
    //     }));
    // }
}