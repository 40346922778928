import { useCallback, useMemo, useState } from "react"
import { removeFromDb, restoreItem } from "common/src/actions/recentlyDeleted";

import { Dropdown, App as AntApp } from "antd"
import Button from "common/src/refactor/components/button/Button"

import { ReactComponent as IconDelete } from "common/src/svg/delete.svg"
import { ReactComponent as IconUndo } from "common/src/svg/undo.svg"
import { ReactComponent as IconDots } from "common/src/svg/dots.svg"

function DeletedContextMenu({ item }) {
    const { modal } = AntApp.useApp();
    const [ deleting, setDeleting ] = useState(false);
    const [ restoring, setRestoring ] = useState(false);

    const deleteItem = useCallback(
        async () => {
            setDeleting(true);
            await removeFromDb(item);
            setDeleting(false);
        },
        [ item ]
    );

    const onRestorePress = useCallback(
        async () => {
            setRestoring(true);
            await restoreItem(item);
            setRestoring(false);
        }, 
        [ item ]
    );

    const onDeletePress = useCallback(
        async () => {
            await modal.confirm({
                closable: true,
                icon: null,
                title: "Permanently delete",
                content: "This item will be permanently deleted and cannot be restored",
                okText: "Delete",
                cancelButtonProps: { 
                    style: { 
                        display: 'none' 
                    } 
                },
                okButtonProps: { 
                    style: {
                        textTransform: "uppercase"
                    }
                },
                onOk: async () => {
                    await deleteItem();
                }
            })
        }, 
        [ deleteItem, modal ]
    )

    const menu = useMemo(
        () => {
            const items = [
                {
                    key: "undo",
                    icon: <IconUndo/>,
                    label: "Restore",
                    onClick: onRestorePress
                },
                {
                    key: "delete",
                    icon: <IconDelete/>,
                    label: "Delete",
                    onClick: onDeletePress
                },
            ];

            return { items }
        },
        [ onRestorePress, onDeletePress ]
    );

    return (
        <Dropdown menu={ menu } trigger="click" rootClassName="context-menu">
            <Button
                className="context-button"
                type="primary"
                shape="circle"
                size="small"
                loading={ deleting || restoring }
                Icon={ IconDots }
                style={ { zIndex: 100 } }
            />
        </Dropdown>
    )
}

export default DeletedContextMenu;