import Loader from "common/src/components/Loader"


function LookBuiderSearchLoading() {

    return (
        <div className="look-builder-search-loading">
            <Loader/>
        </div>
    )
}

export default LookBuiderSearchLoading