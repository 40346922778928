import { useCallback, useState, useContext, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"

import Dropzone from "common/src/components/Dropzone"
import Button from "common/src/refactor/components/button/Button"

import LookBuilderContext from "./LookBuilderContext"
import { prepareLookImage } from "lib/look/prepareImage"
import { data } from "common/src/store/look/editor"
import useKey from "common/src/refactor/hooks/useKey"
import swallowEvent from "common/src/lib/dom/swallowEvent"
import templates from "common/src/lib/look/layout/templates"
import { getGridStyle } from "common/src/lib/look/layout2style"
import cloneDeep from "lodash/cloneDeep"
import isEqual from "lodash/isEqual"
import Loader from "common/src/components/Loader"

// function PageCanva() {

//     const [ clicked, setClicked ] = useState(false);

//     const onClick = useCallback(
//         () => {
//             hub.dispatch("posthog", "event", {
//                 event: "canva_wanted_press"
//             });
//             setClicked(true)
//         },
//         []
//     );

//     return (
//         <div className="look-builder-type-page-canva">
//             { clicked ?
//                 <>
//                     <p>Thanks! We've let our team know you'd like this feature. Watch this space.</p>
//                 </> :
//                 <>
//                     <p>Share designs & imagery directly to <nobr>THE FLOORR</nobr> from Canva </p>
//                     <Button
//                         type="primary"
//                         size="large"
//                         text="I WANT THIS FEATURE"
//                         onClick={ onClick }/>
//                 </> }
            
//         </div>
//     )
// }

function TemplateCard({ template, current, onChange }) {

    //const dispatch = useDispatch();
    //const look = useSelector(s => s.lookEditor.look);
    const cls = useMemo(
        () => ["look-builder-type-page-template", isEqual(template, current) ? "active" : "" ].join(" "),
        [ template, current ]
    );

    const onTemplateClick = useCallback(
        () => {
            onChange(cloneDeep(template));
            // dispatch(data.layouts.applyTemplate({
            //     tpl: cloneDeep(template), 
            //     productMode: look.productMode || "multiple"
            // }));
        },
        [ template, onChange ]
    );

    return (
        <div className={ cls } onClick={ onTemplateClick }>
            <div className="look-builder-type-page-tpl-grid">
            { template.map((item, i) => (
                <div key={ i } style={ getGridStyle(item) }/>
            )) }
            </div>
        </div>
    )
}


function PageLayout() {

    const dispatch = useDispatch();
    // const { modal } = AntApp.useApp();
    const builder = useContext(LookBuilderContext);
    const look = useSelector(s => s.lookEditor.look);
    const [ current, setCurrent ] = useState(look?.layouts.template);
    //const current = look?.layouts.template;
    const [ count, setCount ] = useState(() => look?.layouts.template.length || 3);
    const [ saving, setSaving ] = useState(false);
    const tpls = useMemo(() => templates[count], [ count ]);
    //const consultation = useKey("consultation", builder);

    const onCountClick = useCallback(
        (e) => {
            swallowEvent(e);
            setCount(parseInt(e.target.dataset.count));
        },
        []
    );

    const onStartClick = useCallback(
        async () => {
            setSaving(true);
            dispatch(data.layouts.applyTemplate({
                tpl: current, 
                productMode: look.productMode || "multiple"
            }));
            // if (!look?.id && !builder.get("draftExists")) {
            //     await saveDraft();
            //     await new Promise(resolve => setTimeout(resolve, 1000));
            //     await builder.resolveAll("load-draft");
            // }
            builder.set("layoutChangeMode", false);
            builder.set("empty", false);
            setSaving(false);
        },
        [ current, dispatch, builder, look?.productMode ]
    );

    return (
        <div className="page-builder-type-page-layout">
            <p className="look-builder-type-page-description">Select the number of products for your look</p>
            <div className="look-builder-type-page-layout-counts">
                <a href="/#" 
                    className={ count === 1 ? "active" : "" } 
                    data-count="1"
                    onClick={ onCountClick }>1</a>
                <a href="/#" 
                    className={ count === 2 ? "active" : "" } 
                    data-count="2"
                    onClick={ onCountClick }>2</a>
                <a href="/#" 
                    className={ count === 3 ? "active" : "" } 
                    data-count="3"
                    onClick={ onCountClick }>3</a>
                <a href="/#" 
                    className={ count === 4 ? "active" : "" } 
                    data-count="4"
                    onClick={ onCountClick }>4</a>
                <a href="/#" 
                    className={ count === 5 ? "active" : "" } 
                    data-count="5"
                    onClick={ onCountClick }>5</a>
            </div>
            <div className="look-builder-type-page-selector-templates">
                { tpls.map((tpl, i) => (
                    <TemplateCard 
                        template={ tpl } 
                        current={ current } 
                        key={ i }
                        onChange={ setCurrent }/>
                ))}
            </div>
            <Button
                disabled={ !current || saving }
                loading={ saving }
                size="large"
                type="primary"
                text="Add products"
                onClick={ onStartClick }/>
        </div>
    )
}

function PageType({ preselectedMode, onTaggedClick, onLayoutClick, onCreateClick }) {
    const builder = useContext(LookBuilderContext);
    const dispatch = useDispatch();

    const uploading = useKey("uploadingLookImage", builder);

    const onInputChange = useCallback(
        async (file) => {
            builder.set("uploadingLookImage", true);

            const { src, info, key } = await prepareLookImage(file);

            dispatch(data.update({ productMode: "tagged" }));
            dispatch(data.layouts.setImage({
                //mime,
                src,
                key,
                zoom: 0,
                hplus: 0,
                vplus: 0,
                ...info
            }));

            builder.set("uploadingLookImage", false);
            builder.set("empty", false);
        },
        [ builder, dispatch ]
    );

    return (
        <>
            <p className="look-builder-type-page-description">Choose how you'd like to create your look</p>

            <div className="look-builder-types">
                <div className={ `look-builder-types-type` } onClick={ onTaggedClick }>
                <span>Tag an image with multiple products</span>
                <img 
                    className={ `look-builder-types-img ${ preselectedMode === "tagged" ? "active" : "" }` }
                    alt=""
                    width="259"
                    height="357"
                    src="https://thefloorr-assets.s3.eu-west-2.amazonaws.com/images/look_tagged_type.png"/>
                </div>
                <span>OR</span>
                <div className={ `look-builder-types-type` } onClick={ onLayoutClick }>
                    <span>Add between 1 to 5 products to a grid layout</span>
                    <img 
                    className={ `look-builder-types-img ${ preselectedMode === "layout" ? "active" : "" }` }
                    alt=""
                    width="259"
                    height="357"
                    src="https://thefloorr-assets.s3.eu-west-2.amazonaws.com/images/look_layout_type.png"/>
                </div>
            </div>

            { preselectedMode === "tagged" ?    
            <Dropzone onChange={ onInputChange }>
            <Button
                disabled={ uploading }
                loading={ uploading }
                size="large"
                type="primary"
                text="Create look"
                onClick={ onCreateClick }/>
            </Dropzone> : 
            <Button
                size="large"
                type="primary"
                text="Create look"
                onClick={ onCreateClick }/>
             }
        </>
    )
}

function LookType() {

    const builder = useContext(LookBuilderContext);
    const [ preselectedMode, setPreselectedMode ] = useState("tagged");
    const [ mode, setMode ] = useState(null);
    const draftLoading = useKey("draftLoading", builder);
    const lookLoading = useKey("lookLoading", builder);
    const layoutChangeMode = useKey("layoutChangeMode", builder);

    const onLayoutClick = useCallback(
        () => setPreselectedMode("layout"),
        []
    );

    const onTaggedClick = useCallback(
        () => setPreselectedMode("tagged"),
        []
    );

    const onCreateClick = useCallback(
        () => {
            if (preselectedMode === "layout") {
                setMode("layout")
            }
        }, [ preselectedMode ])

    if (draftLoading || lookLoading) {
        return (
            <div className="look-builder-loading-page">
                <Loader size={ 32 }/>
            </div>
        );
    }

    return (
        <div className="look-builder-type-page">
            <h2>{ layoutChangeMode ? "Change layout" : "Create your look" }</h2>

            { !mode && !layoutChangeMode && 
                <PageType 
                    onLayoutClick={ onLayoutClick } 
                    onCreateClick={ onCreateClick } 
                    onTaggedClick={ onTaggedClick }
                    preselectedMode={ preselectedMode }/> }
            { (mode === "layout" || layoutChangeMode) && <PageLayout/> }
        </div>
    )
}

export default LookType