import moment from "moment"
import { useMemo } from "react"

function useDateFormat(date, thisYear = "D MMM", otherYear = "D MMM, YYYY") {

    const value = useMemo(
        () => {
            if (!date) {
                return "";
            }
        
            const year = (new Date(date)).getFullYear();
            const cyear = (new Date()).getFullYear();
            const format = year === cyear ? thisYear : otherYear;
            return moment(date).format(format);
        },
        [ date ]
    );

    return value;
}

export default useDateFormat