import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom"

import ConsultationDialog from "app/components/consultation/Dialog";
import { App as AntApp } from "antd";
import api from "app/api"
import hub from "common/src/hub";
import ConnectionSelector from "common/src/refactor/components/dialog/ConnectionSelector";
import { sendConsultation } from "common/src/actions/chat";
import useDictRef from "common/src/hooks/useDictRef";
import user from "common/src/user"

function useConsultationActions({ cons, fromHref, onEdited, onSend, onHide, onDeleted }) {

    const navigate = useNavigate();

    const [ duplicating, setDuplicating ] = useState(false);
    const [ sending, setSending ] = useState(false);
    const [ deleting, setDeleting ] = useState(false);
    const [ hiding, setHiding ] = useState(false);
    const [ lastDuplicateId, setLastDuplicateId ] = useState(null);
    const { modal, message } = AntApp.useApp();
    const ref = useDictRef({ lastDuplicateId });

    const loading = duplicating || sending || deleting || hiding;

    const deleteConsultation = useCallback(
        async () => {
            setDeleting(true);

            await api.consultation.update(cons.id, {
                status: "cancelled",
                cancelledAt: (new Date()).toISOString()
            });
            hub.dispatch("consultation", "cancelled", { id: cons.id });

            setDeleting(false);
            message.success({
                content: `${ cons.title } has been deleted`,
                icon: <></>
            });

            onDeleted && onDeleted();
        },
        [ cons, message, onDeleted ]
    );

    const onDeleteClick = useCallback(() => {
            modal.confirm({
                closable: true,
                icon: null,
                width: 540,
                title: "Delete styling session?",
                content: "You can restore this styling session in 'Recently Deleted' from your profile within 30 days. After that, it will be permanently deleted.",
                okText: "DELETE NOW",
                cancelButtonProps: { 
                    style: { 
                        display: 'none' 
                    } 
                },
                okButtonProps: { 
                    style: {
                        textTransform: "uppercase"
                    }
                },
                onOk: async () => {
                    await deleteConsultation()
                }
            });
        }, 
        [ deleteConsultation, modal ] 
    );

    const onSendClick = useCallback(
        async () => {
            const conns = await ConnectionSelector.show(
                {
                    multiple: false,
                    connectionId: cons.customerId,
                    showSearch: false
                },
            );

            if (conns) {
                setSending(true);
                await sendConsultation(cons.id);
    
                message.success({
                    content: `Styling session sent to ${ conns[0].givenName } ${ conns[0].familyName }`,
                    icon: <></>
                })
                
                onSend && onSend();
                setSending(false);
            }
        },
        [ cons, message, onSend ]
    );
    
    const onUndoPress = useCallback(
        async () => {
            await api.consultation.update(ref.lastDuplicateId, {status: "cancelled", cancelledAt: (new Date()).toISOString()});
            hub.dispatch("consultation", "cancelled", { id: ref.lastDuplicateId });
            message.destroy();
        },
        [ message, ref.lastDuplicateId ]
    );

    const onDuplicateClick = useCallback(
        async () => {
            setDuplicating(true);
            message.success({
                content: "Duplicating...",
                duration: 0,
                icon: <></>
            });

            const { id } = await api.backend.post("/cons/copy", {
                body: {
                    consId: cons.id
                }
            });

            setLastDuplicateId(id);
            setDuplicating(false);

            message.destroy();
            message.success({
                content: <>
                    { cons.title } has been duplicated.
                    <button 
                        className="notice-button-link"
                        onClick={ onUndoPress }
                        children="UNDO"/>
                </>,
                icon: <></>
            });
        }, 
        [ cons, onUndoPress, message ]
    );

    const onAddLookClick = useCallback(
        () => {
            let url = `/look-editor?consId=${ cons.id }`;
            if (fromHref) {
                url += `&from=${ encodeURIComponent(fromHref) }`
            }
            navigate(url);
        },
        [ cons, fromHref, navigate ]
    );

    const onEditClick = useCallback(
        async () => {
            const edited = await ConsultationDialog.show({ consultation: cons });
            onEdited && onEdited(edited);
        },
        [ cons, onEdited ]
    );

    const onHideToggle = useCallback(
        async (val) => {
            setHiding(true);

            const newState = val;

            await api.consultation.update(cons.id, {
                hidden: newState
            });

            hub.dispatch("consultation", "updated", cons.id);

            onHide && onHide(newState);

            setHiding(false);
        },
        [ cons, onHide ]
    );

    const consApi = {
        onAddLookClick,
        onEditClick,
        onDeleteClick,
        onSendClick,
        onDuplicateClick,
        onHideToggle,

        duplicating,
        hiding,
        sending,
        deleting,
        loading
    };

    // disable sending for Concierge
    if (user.isOnly("Admin")) {
        consApi.onSendClick = null;
    }

    return consApi;
}

export default useConsultationActions