
import hub from "common/src/hub"
import singlePromise from "common/src/lib/js/singlePromise"

import api from "app/api"
import store from "app/store"
import user from "common/src/user"
import { data, ui } from "app/store/page/consultation"
import { consultationsLoader } from "common/src/actions/consultations"
import entities from "common/src/api/hasura/entities"

let subscription;

export const loadOne = singlePromise({
    mode: singlePromise.KEY_CANCEL,
    pre: (id, force) => {
        let state = store.getState(),
            setup = {
                key: id
            }

        if (!force && state.consultationPage.data.cons && 
            state.consultationPage.data.cons === id) {
            setup.value = state.consultationPage.data.cons;
            setup.skip = true;
        }

        return setup;
    },
    fn: id => {
        store.dispatch(ui.cons.loading(true));
        const isPlainUser = user.isOnly("User");
        return consultationsLoader({
            where: { id: { _eq: id }},
            withLooks: true,
            withPublishedLooks: false,
            withContacts: true,
            withSizes: true,
            withReactions: isPlainUser,
            withCustomerReactions: !isPlainUser,
            withMyLastMessage: true,
            withInvitations: true
        })
        .then(items => items[0]);
    },
    resolve: response => {
        store.dispatch(data.cons.set(response));
        return response;
    },
    reject: err => {
        hub.dispatch("error", "consultation-load-one", err);
    },
    always: () => {
        store.dispatch(ui.cons.loading(false));
    }
});

export const subscribeToConsultation= async (id) => {
    if (subscription) {
        return;
    }

    const graph = entities.Consultation.list;
    const where = { id: { _eq: id } };
    subscription = await api.consultation.subscribeList({ where }, graph, async () => {
        await loadOne(id);
    });
};

export const unsubscribeFromConsultation = () => {
    if (subscription) {
        if (subscription.subscription) {
            subscription.subscription.unsubscribe();
        }
        subscription = null;
    }
};

export const loadMessages = singlePromise({
    mode: singlePromise.ONE,
    pre: (page = 0, force) => {
        const   state = store.getState(),
                setup = { page };

        if (!force && 
            state.consultationPage.ui.messages.page === page && 
            state.consultationPage.data.messages.length) {
            setup.value = state.consultationPage.data.messages;
            setup.skip = true;
        }

        return setup;
    },
    fn: (page, force) => {
        const state = store.getState();
        const id = state.consultationPage.data.cons.id;
        store.dispatch(ui.messages.loading(true));
        
        return api.consultationCustomerChat.list({
            where: { consultationId: id },
            order: { createdAt: "desc" }
        });
    },
    resolve: response => {
        store.dispatch(data.messages.set(response));
        return response;
    },
    reject: err => {
        hub.dispatch("error", "consultation-load-messages", err);
    },
    always: () => {
        store.dispatch(ui.messages.loading(false));
    }
})





export function assignToMe(cons) {
    return assignTo(cons, user.id());
}

export function assignCustomer(cons, customerId) {
    let status = cons.status;

    if (status === "cancelled" && status === "completed") {
        hub.dispatch("consultation", "assign-fri", "Consultation is closed");
        return Promise.reject();
    }
    return api.consultation.update(cons.id, {
        customerId,
        updatedAt: (new Date()).toISOString(),
        lastUpdateAt: (new Date()).toISOString()
    })
    .then(() => {
        hub.dispatch("consultation", "updated", cons.id);
        // we don't have to wait for this
        api.backend.post("/me/log-action", { body: {
            action: "consultation/update"
        }});
    })
    .catch(err => {
        hub.dispatch("error", "consultation-assign-fri", err);
    })
}

export async function inviteCustomer(cons, email) {

    try {
        await api.invitation.create({
            consultationId: cons.id,
            email: email.toLowerCase()
        });
        hub.dispatch("consultation", "updated", cons.id);
    }
    catch (err) {
        console.error(err);
    }
}

export function assignTo(cons, friId) {
    let status = cons.status;

    if (status === "cancelled" && status === "completed") {
        hub.dispatch("consultation", "assign-fri", "Consultation is closed");
        return Promise.reject();
    }

    if (status !== "new") {
        status = "new";
    }

    return api.consultation.update(cons.id, {
        friId,
        status,
        updatedAt: (new Date()).toISOString(),
        lastUpdateAt: (new Date()).toISOString()
    })
    .then(() => {
        hub.dispatch("consultation", "updated", cons.id);
        // we don't have to wait for this
        api.backend.post("/me/log-action", { body: {
            action: "consultation/update"
        }});
    })
    .catch(err => {
        hub.dispatch("error", "consultation-assign-fri", err);
    })
}

export function sendSuggestions(cons) {
    let status = cons.status;

    if (status !== "inprogress") {
        hub.dispatch("consultation", "assign-fri", "Consultation is not in progress");
        return Promise.reject();
    }

    return api.consultation.update(cons.id, {
        subStatus: "suggestions-sent",
        updatedAt: (new Date()).toISOString(),
        lastUpdateAt: (new Date()).toISOString()
    })
    .then(() => {
        hub.dispatch("consultation", "updated", cons.id);
    })
    .catch(err => {
        hub.dispatch("error", "consultation-send", err);
    })
}

export function markPurchased(userProduct) {

    return api.userProduct.update(userProduct.id, {
        status: "purchased",
        purchasedAt: (new Date()).toISOString(),
        friId: user.id(),
        updatedAt: (new Date()).toISOString(),
        lastUpdateAt: (new Date()).toISOString()
    })
    .then(() => {
        hub.dispatch("consultation", "product-purchased", userProduct);
    })
    .catch(err => {
        hub.dispatch("error", "consultation-product-purchase", err);
    })
}

export function markNotPurchased(userProduct) {
    return api.userProduct.update(userProduct.id, {
        status: "selected",
        purchasedAt: null,
        friId: null,
        updatedAt: (new Date()).toISOString(),
        lastUpdateAt: (new Date()).toISOString()
    })
    .then(() => {
        hub.dispatch("consultation", "product-unpurchased", userProduct);
    })
    .catch(err => {
        hub.dispatch("error", "consultation-product-unpurchase", err);
    })
}


export function restore(c) {
    return api.consultation.update(c.id, {
        status: "inprogress",
        subStatus: "byfri",
        updatedAt: (new Date()).toISOString(),
        lastUpdateAt: (new Date()).toISOString()
    })
    .then(() => {
        hub.dispatch("consultation", "updated", c.id);
        // we don't have to wait for this
        api.backend.post("/me/log-action", { body: {
            action: "consultation/update"
        }});
    })
    .catch(err => {
        hub.dispatch("error", "consultation-restore", err);
    })
}


export function cancel(c, reason = "") {
    return api.consultation.update(c.id, {
        status: "cancelled",
        subStatus: "byfri",
        deletionRequest: reason,
        updatedAt: (new Date()).toISOString(),
        lastUpdateAt: (new Date()).toISOString()
    })
    .then(() => {
        hub.dispatch("consultation", "updated", c.id);
        // we don't have to wait for this
        api.backend.post("/me/log-action", { body: {
            action: "consultation/update"
        }});
    })
    .catch(err => {
        hub.dispatch("error", "consultation-cancel", err);
    })
}


export function requestCancel(c, reason) {
    return api.consultation.update(c.id, {
        status: "cancelRequest",
        subStatus: "byfri",
        deletionRequest: reason,
        updatedAt: (new Date()).toISOString(),
        lastUpdateAt: (new Date()).toISOString()
    })
    .then(() => {
        hub.dispatch("consultation", "updated", c.id);
        // we don't have to wait for this
        api.backend.post("/me/log-action", { body: {
            action: "consultation/update"
        }});
    })
    .catch(err => {
        hub.dispatch("error", "consultation-cancel", err);
    })
}


export function complete(c) {
    return api.consultation.update(c.id, {
        status: "completed",
        subStatus: "byfri",
        updatedAt: (new Date()).toISOString(),
        lastUpdateAt: (new Date()).toISOString()
    })
    .then(() => {
        hub.dispatch("consultation", "updated", c.id);
        // we don't have to wait for this
        api.backend.post("/me/log-action", { body: {
            action: "consultation/update"
        }});
    })
    .catch(err => {
        hub.dispatch("error", "consultation-complete", err);
    })
}


export function setStatus(c, status) {
    return api.consultation.update(c.id, {
        status,
        subStatus: "byfri",
        updatedAt: (new Date()).toISOString(),
        lastUpdateAt: (new Date()).toISOString()
    })
    .then(() => {
        hub.dispatch("consultation", "updated", c.id);
        // we don't have to wait for this
        api.backend.post("/me/log-action", { body: {
            action: "consultation/update"
        }});
    })
    .catch(err => {
        hub.dispatch("error", "consultation-status", err);
    })
}