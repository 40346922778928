import { useEffect, useState, useCallback, useMemo, useContext } from "react"
import { useSelector } from "react-redux"
import { Badge, App, Input } from "antd"
import { useLocation, useNavigate } from "react-router-dom"

import copy from 'copy-to-clipboard';

import UserAvatar from "common/src/refactor/components/UserAvatar"
import Loader from "common/src/components/Loader"
import ChatContext from "common/src/lib/chat/ChatContext"
import Typing from "common/src/components/chat_v2/animations/Typing"
import Recording from "common/src/components/chat_v2/animations/Recording"

import { ReactComponent as IconOpen } from "common/src/svg/gt.svg"
import { ReactComponent as IconTick } from "common/src/svg/tick.svg"

import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import useContactActivity from "common/src/lib/chat/useContactActivity"
import useIsOnline from "common/src/lib/chat/useIsOnline"
import { useOn } from "@kuindji/observable-react"

import getWidth from "common/src/lib/dom/getWidth"
import { subscribeToUserConnections } from "common/src/actions/connections"
import connectionSelector from "common/src/selectors/connections"
import hub from "common/src/hub"
import user from "common/src/user"
import Button from "../button/Button"
import api from "common/src/api"
import useDictRef from "common/src/hooks/useDictRef";

function ModalButton({ children, onClick, ...rest }) { // needed 'cause modal renders outside the router and usual button uses useNavigate hook 
    return (
        <button className="button button--primary button--large button--default-shape button--center button--text-only button--primary--large--default-shape--center--text-only" onClick={ onClick } { ...rest }>
            <span className="button__text button__text--primary button__text--large button__text--default-shape button__text--center button__text--text-only button__text--primary--large--default-shape--center--text-only">
                { children }
            </span>
        </button>)
}

function Contact({ contact, isActive, hasUnreads }) {
    const chat = useContext(ChatContext);
    const activity = useContactActivity(contact);
    const isOnline = useIsOnline(contact);
    const cls = useMemo(
        () => [ "chat-contacts-contact", isActive ? "active" : "" ].join(" "),
        [ isActive ]
    );

    const onUserClick = useSwallowEventCallback(
        () => chat.setContact(contact),
        [ contact ]
    );

    return (
        <div className={ cls }  
            role="button" 
            onClick={ onUserClick }
            data-cy="chat-contact"
            data-cy-contact-id={ contact.id }>
            <UserAvatar user={ contact }/>
            <div className="chat-contacts-contact-info">
                <div className="chat-contacts-contact-name">
                    { contact.givenName } { contact.familyName }
                </div>
                <div className="chat-contacts-contact-status">
                    { activity ? 
                        activity === "typing" ? <Typing/> : <Recording/> :
                        isOnline ? "Online" : "Offline" }
                </div>
                { hasUnreads > 0 && <Badge count={ hasUnreads } size="small"/> }
            </div>
            <IconOpen/>
        </div>
    )

}

function BroadcastContact() {
    const navigate = useNavigate();
    const location = useLocation();
    const chat = useContext(ChatContext);

    const cls = useMemo(
        () => [ "chat-contacts-contact", location.pathname.includes("broadcast") ? "active" : "" ].join(" "),
        [ location ]
    );
    
    const onUserClick = useSwallowEventCallback(
        () => {
            chat.setContact(null);
            navigate("/chat/broadcast");
        },
        [ ]
    );

    return (
        <div className={ cls } 
            role="button" 
            onClick={ onUserClick }>
            <div className="chat-contacts-contact-info">
                <div className="chat-contacts-contact-name">
                    Broadcast to PSE's
                </div>
            </div>
            <IconOpen/>
        </div>
    )
}

function Contacts() {

    const chat = useContext(ChatContext);
    const ntfs = useSelector(s => s.chats.data.notifications || []);
    const location = useLocation();
    const { modal } = App.useApp();

    const connectionsLoading = useSelector(s => s.connections.ui.loading);
    const connections = useSelector(connectionSelector);
    const [ activeId, setActiveId ] = useState(null);
    const [ contacts, setContacts ] = useState([]);
    const [ active, setActive ] = useState(true);

    const [ creating, setCreating ] = useState(false);
    const [ currentModal, setCurrentModal ] = useState(null);

    const ref = useDictRef({ currentModal })

    const mainCls = useMemo(() => `chat-contacts ${ active ? "active" : "" }`, [ active ]);

    function hasUnreads(c) {
        return ntfs.filter(n => n.message.userId === c.id).length;
    }

    const onContactChange = useCallback(
        () => {
            setActiveId(chat.getContact()?.id);
            chat.setState("showContacts", getWidth(window) > chat.getState("windowBreakpoint"));
            chat.getState("singleContact") && setContacts([ chat.getContact() ]);
        }, 
        []
    );
    const onStateChange = useCallback(() => setActive(chat.getState("showContacts")), []);

    useOn(chat, { contact: onContactChange, state: onStateChange });
    useEffect(() => { subscribeToUserConnections(chat.currentUserRole) }, []);

    useEffect(() => {
        if (chat.getState("singleContact")) {
            if (activeId) {
                setContacts([ chat.getContact() ]);
            }
            else {
                setContacts([]);
            }
        }
        else {
            setContacts(connections);
            if ((!activeId && !location.pathname.split('/')[2]) && connections.length > 0 && !location.pathname.includes("broadcast") ) {
                hub.dispatch("chat", "set-contact", connections[0]);
                chat.setContact(connections[0]);
            };
        }
    }, [ connections, activeId ]);

    const createInvitationLink = useCallback(
        async () => {
        setCreating(true);
        const res = await api.backend.post("/invitation/create");
        setCreating(false);

        return res.body;
    }, []);

    const onCopyClick = useCallback((link) => {
        copy(link);

        ref.currentModal?.update({
            content: <>
                    <p>The invitation will be valid for a single person</p>
                    <div className="invite-link-wrapper">
                        <Input value={ link } disabled/>
                        <ModalButton disabled>
                            COPIED
                            <IconTick/>
                        </ModalButton> 
                    </div>
                </>,
          });

    }, [ currentModal, ref ])

    const onCreateInvitationClick = useCallback(
        (link) => {
            const md = modal.confirm({
                className: "modal-invite",
                closable: true,
                width: 500,
                icon: null,
                title: "Copy the link below to send to your client",
                content: <>
                    <p>The invitation will be valid for a single person</p>
                    <div className="invite-link-wrapper">
                        <Input value={ link } disabled/>
                        <ModalButton onClick={ () => onCopyClick(link) }>
                            COPY
                        </ModalButton> 
                    </div>
                </>,
                cancelButtonProps: { 
                    style: { 
                        display: 'none' 
                    } 
                },
                okButtonProps: { 
                    style: { 
                        display: 'none' 
                    } 
                },
            });

            setCurrentModal(md);
        },[ modal ]);

    const onInviteClick = useCallback(() => {
        modal.confirm({
            closable: true,
            width: 470,
            icon: null,
            title: "Would you like to create an invite?",
            content: "The invitation will be valid for a single person",
            okText: "INVITE YOUR CLIENT",
            cancelButtonProps: { 
                style: { 
                    display: 'none' 
                } 
            },
            okButtonProps: { 
                style: {
                    width: "70%"
                }
            },
            confirmLoading: creating,
            onOk: async () => {
                const link = await createInvitationLink();
                onCreateInvitationClick(link)
            },

        });

    }, [ modal, creating, createInvitationLink ])

    return (
        <div className={ mainCls } data-cy="chat-contacts">
            <div className="chat-contacts-inner">
                    <div className="chat-contacts-invite">
                        <Button
                            type="primary"
                            text="invite your client"
                            onClick={ onInviteClick }
                            loading={ creating }
                        />
                    </div> 
                { connectionsLoading && <div className="loading"><Loader inline/></div> }
                { user.isOnly("Admin") && <BroadcastContact/>}
                { contacts.map(c => (
                    <Contact 
                        key={ c.id }
                        contact={ c }
                        isActive={ activeId === c.id } 
                        hasUnreads={ hasUnreads(c) }/>
                ))}
            </div>
        </div>
    )
}

export default Contacts