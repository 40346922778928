import store from "app/store";

export default function (value, from, rates) {

    if (!value) {
        return 0;
    }

    let rate;

    if (!rates) {
        const state = store.getState();
        rates = state.user.geo.exchange;      
    }

    from = from.toUpperCase();
    rate = rates[from] || 1;

    return value * rate;
};