import api from "common/src/api";
import Spacer from "common/src/refactor/components/Spacer";
import { ReactComponent as IconClose } from "common/src/svg/close.svg"
import user from "common/src/user";
import { useCallback, useEffect, useState } from "react";

function Banner() {
    const [ banner, setBanner ] = useState();
    const [ userBanner, setUserBanner ] = useState(0);

    const getBanner = useCallback(
        async () => {
            const where = {
                active: { _eq: true },
                startDate: { _lte: "now" },
                endDate: { _gte: "now" },
              },
              order_by = { startDate: "asc" },
              limit = 1
            
            const result = await api.banner.list({ where, order_by, limit });

            if (result.length > 0) {
                const b = result[0];
                const cUser = user.id();

                const where = {
                    userId: { _eq: cUser },
                    bannerId: { _eq: b.id },
                }
                const uBanner = await api.userBanner.list({ where });

                if (uBanner.length === 0) {
                    const r = await api.userBanner.create({ userId: cUser, bannerId: b.id, active: true }, "returning { id countClosed }");
                    setUserBanner(r);
                    setBanner(b);
                } else if (uBanner[0].countClosed < 3) {
                    setUserBanner(uBanner[0]);
                    setBanner(b)
                }
            }
        },
        [ ]
    );

    useEffect(
        () => {
            getBanner();
        },
        [ getBanner ]
    )

    const onCloseClick = useCallback(
        async (e) => {
            e.preventDefault();
            if (userBanner) {
                await api.userBanner.update(userBanner.id, { countClosed: userBanner.countClosed + 1 });
            }
            setBanner(null);
        },
        [ setBanner, userBanner ]
    )

    if (!banner) {
        return (<Spacer size="xlarge"/>)
    }

    return (
        <div className="feed-banner">
            <p>{ banner.text }</p>

            <a href="/#" onClick={ onCloseClick }>
                <IconClose />
            </a>
        </div>
    );
}

export default Banner;