
export default function createMultiple(items, apiFn, inputFn) {

    let ids = [],
        promises = [];

    items.map((item, inx) => {
        if (!item.id || item.id.indexOf("tmp_") === 0) {
            ids.push(null);
            let input = inputFn(item, inx);
            promises.push(apiFn(input).then(resp => {
                ids[inx] = resp.id;
            }));
        }
        else {
            ids.push(item.id);
        }
        return null;
    });

    return Promise.all(promises).then(() => ids);
}