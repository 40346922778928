import getRetailerName from "common/src/lib/look/url2retailer"
import { useMemo } from "react";

function getAffiliateName(p) {
    const retailer = p.retailer || p.url;
    return getRetailerName(retailer);
}

function LookProductRetailer({ product }) {

    const retailer = useMemo(
        () => getAffiliateName(product),
        [ product ]
    );

    return (
        <div className="look-card-product-retailer">
            { retailer }
        </div>
    )
}

export default LookProductRetailer