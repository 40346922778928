import preloadImages from "common/src/lib/image/preload"
import { url2key } from "common/src/lib/image/s3url"

export default function(look) {

    let images = [],
        prodMap = {};

    look.products.forEach(p => {
        if (typeof p.image === "string") {
            images.push(p.image);
            prodMap[p.image] = p;
        }
    });

    if (images.length > 0) {
        return preloadImages(images)
            .then(images => {
                images.forEach(i => {
                    i.key = url2key(i.src);
                    prodMap[i.src].image = i;
                })
                return look;
            });
    }
    else {
        return look;
    }
}