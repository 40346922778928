
import { useMemo } from "react"

import Select from "common/src/refactor/components/ant/Select"

const options = [
    {
        key: "all",
        value: "all",
        label: "All Regions"
    },
    {
        key: "gb",
        value: "gb",
        label: "United Kingdom"
    },
    {
        key: "us",
        value: "us",
        label: "United States"
    },
    {
        key: "euro",
        value: "eu",
        label: "Europe"
    }
]

export function RegionSelect({ className, ...props }) {
    const cls = useMemo(
        () => [ "selector-region", className || "" ].join(" "),
        [ className ]
    );

    return (
        <Select 
            className={ cls }
            placeholder="All Regions"
            options={ options } 
            { ...props }/>
    )
}
