import { Modal, Input, Space, Tooltip, App as AntApp } from "antd"
import { useState, useCallback, useMemo } from "react"
import { useSelector } from "react-redux"

import { ReactComponent as IconClose } from "common/src/svg/close.svg"
import { ReactComponent as IconSend } from "common/src/svg/send.svg"
import { ReactComponent as IconChat } from "common/src/svg/chat.svg"
import { ReactComponent as IconLike } from "common/src/svg/heart-active.svg"
import { ReactComponent as IconBasket } from "common/src/svg/basket-active.svg"

import Button from "common/src/refactor/components/button/Button"
import ProductCard from "common/src/refactor/components/catalogue/product/Card"
import ImageSlider from "common/src/refactor/components/image-slider/ImageSlider"
import Expandable from "common/src/refactor/components/Expandable"
import NullForm from "common/src/components/NullForm"
import Loader from "common/src/components/Loader"

import { ui } from "common/src/store/dialogs"
import store from "app/store"
import hub from "common/src/hub"
import app from "app/appContext"
import user from "common/src/user"
import useOnAppEvent from "common/src/refactor/hooks/useOnAppEvent"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import { publishLook, unpublishLook } from "app/actions/page/looks"
import formatCurrency from "common/src/lib/format/currency"
import { setReaction } from "common/src/actions/consultation"
import { addProductComment } from "common/src/actions/chat"
import s3url from "common/src/lib/image/s3url"
import useInputHander from "common/src/refactor/hooks/userInputHandler"
import UserAvatar from "common/src/refactor/components/UserAvatar"
import { useNavigate } from "react-router-dom"
import useUpdateEffect from "common/src/hooks/useUpdateEffect"
import { consultationLoader } from "common/src/actions/consultations"
import useLookActions from "app/hooks/look/useLookActions"

const DIALOG_NAME = "look-dialog";
const modalClassNames = { wrapper: "look-dialog" };


function Look({ look, consultation, initialProductIndex = 0 }) {

    const navigate = useNavigate();
    const { message: ntfMessage } = AntApp.useApp();
    const [ currentProductIndex, setCurrentProductIndex ] = useState(initialProductIndex);
    const [ message, setMessage ] = useState("");
    const [ sendingMessage, setSendingMessage ] = useState(false);
    const [ publishing, setPublishing ] = useState(false);
    const [ published, setPublished ] = useState(look.published);
    const [ liking, setLiking ] = useState(false);
    const [ shopping, setShopping ] = useState(false);
    const customer = useMemo(() => consultation?.customer, [ consultation ]);
    const product = useMemo(() => look.products[currentProductIndex], [ look, currentProductIndex ]);
    const images = useMemo(
        () => product?.images.map(i => i.key ? s3url(i.key, 500) : i.src) || [], 
        [ product ]
    );
    const [ lastMessages, setLastMessages ] = useState(
        () => look.products?.map(p => p.myLastMessage?.message ? [p.id, p.myLastMessage.message] : null)
                .filter(m => m !== null) || []
    );
    const [ reactions, setReactions ] = useState(() => look.products?.map(p => ([ p.id, p.reaction ])) || []);
    const lastMessage = useMemo(
        () => lastMessages.find(r => r[0] === product.id)?.[1], 
        [ product, lastMessages ]
    );
    const reaction = useMemo(
        () => reactions.find(r => r[0] === product.id)?.[1], 
        [ product, reactions ]
    );

    const hideDialog = useCallback(
        () => {
            LookDialog.hide();
        },
        [ ]
    );

    const { onEditClick, onDeleteClick } = useLookActions({ look, onDelete: hideDialog, onEdit: hideDialog })

    const onInputChange = useInputHander(setMessage);

    const onCloseClick = useSwallowEventCallback(
        () => {
            LookDialog.hide();
        },
        []
    );

    const onProductClick = useCallback(
        (product) => {
            const inx = look.products.findIndex(p => p.id === product.id);
            setCurrentProductIndex(inx);
        },
        [ look ]
    );

    const onSendComment = useCallback(
        async () => {
            setSendingMessage(true);
            await addProductComment(look.consultationId, product.id, message);
            hub.dispatch("product", "comment-added", {
                lookId: look.id,
                productId: product.id,
                createdAt: (new Date()).toISOString(),
                message
            });
            setSendingMessage(false);
            setMessage("");
            ntfMessage.success({ content: "Message sent" });

            const inx = lastMessages.findIndex(r => r[0] === product.id);
            if (inx === -1) {
                setLastMessages([ ...lastMessages, [ product.id, message ]]);
            }
            else {
                const newMsg = [ ...lastMessages ];
                newMsg[inx] = [ product.id, message ];
                setLastMessages(newMsg);
            }
        },
        [ message, product, look, lastMessages, ntfMessage ]
    );

    const onTogglePublished = useCallback(
        async () => {
            setPublishing(true);
            if (published) {
                await unpublishLook(look.id);
            }
            else {
                await publishLook(look.id);
                ntfMessage.success({
                    content: `Look sent to ${customer.givenName} ${customer.familyName}` ,
                    icon: <></>
                });
            }
            setPublished(published => !published);
            setPublishing(false);
        },
        [ published, look, customer, ntfMessage ]
    );

    const onOpenChat = useSwallowEventCallback(
        () => {
            LookDialog.hide();
            navigate(`/chat/${ customer.id }`);
        },
        [ customer ]
    );

    const toggleReaction = useCallback(
        async (r) => {
            await setReaction(
                look.consultationId, 
                product.id, 
                product.reaction === r ? null : r,
                consultation.customerId
            );
            const inx = reactions.findIndex(r => r[0] === product.id);
            const newReactions = [ ...reactions ];
            newReactions[inx] = [ product.id, reaction === r ? null : r ];
            setReactions(newReactions);
        },
        [ look, product, consultation, reactions, reaction ]
    )

    const onLikeClick = useCallback(
        async () => {
            setLiking(true);
            await toggleReaction("like");
            setLiking(false);
        },
        [ toggleReaction ]
    );

    const onShopClick = useCallback(
        async () => {
            setShopping(true);
            await toggleReaction("shop");
            setShopping(false);
        },
        [ toggleReaction ]
    );

    return (
        <>
        <div className="look-dialog-look">
            <div className="look-dialog-images">
                <ImageSlider images={ images } indicator="auto"/>
            </div>
            <div className="look-dialog-info">
                <a href="/#" className="look-dialog-close" onClick={ onCloseClick }>
                    <IconClose/>
                </a>

                <label>{ product?.designers.map(d => d.name).join(", ") || "" }</label>
                <h4>{ product?.name }</h4>

                { product?.description && 
                    <Expandable>
                        <p className="look-dialog-product-description" 
                            children={ product.description }/>
                    </Expandable> }

                <p className="look-dialog-product-price">
                    { !!product?.salePrice && 
                        <span>{ formatCurrency(product.salePrice, product?.currency) }</span> }
                    <span>{ formatCurrency(product?.price, product?.currency) }</span> 
                </p>

                { !!customer && 
                    <div className="look-dialog-customer-actions">
                        <Button 
                            loading={ liking }
                            disabled={ liking }
                            type={ reaction === "like" ? "primary" : "secondary" }
                            Icon={ IconLike }
                            onClick={ onLikeClick }
                            text={ `${ customer.givenName } likes it` }/>
                        <Button 
                            loading={ shopping }
                            disabled={ shopping }
                            type={ reaction === "shop" ? "primary" : "secondary" }
                            Icon={ IconBasket }
                            onClick={ onShopClick }
                            text={ `${ customer.givenName } wants it` }/>
                    </div> }

                <div className="look-dialog-products">
                    { look.products.map((p, inx) => (
                        <ProductCard 
                            key={ p.id }
                            product={ p } 
                            footer={ false }
                            className={ currentProductIndex === inx ? "active" : "" }
                            onClick={ onProductClick }/>
                    ))}
                </div>

                { lastMessage && 
                    <div className="look-dialog-last-message">
                        <UserAvatar user={ user.current() }/>
                        <p>{ lastMessage }</p>
                        { !!customer && 
                            <Tooltip title="Open chat">
                                <a href="/#" onClick={ onOpenChat }>
                                    <IconChat/>
                                </a>
                            </Tooltip> }
                    </div>
                }

                <NullForm className="look-dialog-comment">
                    <Space.Compact>
                        <Input
                            disabled={ sendingMessage || consultation.hidden }
                            placeholder="Comment on this piece"
                            value={ message }
                            onChange={ onInputChange }
                            onPressEnter={ !message || sendingMessage ? null : onSendComment }/>
                        <Button 
                            disabled={ !message || sendingMessage }
                            type="primary"
                            onClick={ onSendComment }
                            loading={ sendingMessage }
                            icon={ IconSend }/>
                    </Space.Compact>
                </NullForm>
            </div>
        </div>
        { look.fri?.id === user.id() && 
            <div className="look-dialog-actions">
                <Button 
                    loading={ publishing }
                    disabled={ publishing || !consultation.customer || consultation.hidden }
                    text={ published ? "Hide from customer" : "Send to customer" }
                    type="primary"
                    onClick={ onTogglePublished }/>
                <Button text="Edit" onClick={ onEditClick }/>
                <Button 
                    onClick={ onDeleteClick }
                    text={ look.deleted ? "Delete from db" : "Delete" }
                    type="text"/>
            </div> }
        </>
    )
}

function LookDialog() {

    const open = useSelector(s => s.dialogs[DIALOG_NAME]);
    const [ look, setLook ] = useState(null);
    const [ consultation, setConsultation ] = useState(null);
    const [ initialProductIndex, setInitialProductIndex ] = useState(0);
    const onClose = useCallback(
        () => { LookDialog.hide() },
        []
    );

    useUpdateEffect(
        async () => {
            if (open && look && !consultation) {
                const cons = await consultationLoader(look.consultationId, {
                    withLooks: true,
                    withReactions: true,
                    withMyLastMessage: true
                });
                setConsultation(cons);
            }
        },
        [ open, look ]
    );

    const content = useMemo(
        () => {
            if (open && look && consultation) {
                return (
                    <Look 
                        look={ look } 
                        consultation={ consultation }
                        initialProductIndex={ initialProductIndex }/>
                )
            }
            else if (open) {
                return <Loader/>
            }
        },
        [ open, look, consultation, initialProductIndex ]
    );

    useOnAppEvent("app/look-dialog/look", setLook);
    useOnAppEvent("app/look-dialog/producIndex", setInitialProductIndex);
    useOnAppEvent("app/look-dialog/consultation", setConsultation);

    return (
        <Modal
            classNames={ modalClassNames }
            centered    
            open={ open } 
            destroyOnClose 
            closeIcon={ null }
            footer={ null }
            onCancel={ onClose }
            children={ content }/>
    )
}

LookDialog.show = function(look, consultation, productIndex = 0) {
    app.trigger("app/look-dialog/producIndex", productIndex);
    app.trigger("app/look-dialog/consultation", consultation);
    app.trigger("app/look-dialog/look", look);
    store.dispatch(ui.show(DIALOG_NAME));
}

LookDialog.hide = function() {
    store.dispatch(ui.hide(DIALOG_NAME));
}

export default LookDialog