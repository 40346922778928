import api from "app/api"


const sortRets = (a, b) => {
    return a.name < b.name ? -1 :
        a.name === b.name ? 0 : 1;
};


let defaultRetailers;

export async function getRetailers() {

    if (defaultRetailers) {
        return defaultRetailers;
    }

    const retailers = await api.retailer.list()
        .then(list => list.map(row => {
            return {
                ...row,
                name: row.name.toUpperCase(),
                label: row.name,
                value: row.id
            }
        }))
        .then(list => list.sort(sortRets));
    defaultRetailers = retailers;
    return retailers;
}


export default [
    {
        name: "BOTTEGA VENETA",
        label: "Bottega Veneta",
        value: "bottega",
        id: "bottega",
        matchUrls: [
            "bottegaveneta.com"
        ]
    },
    {
        name: "NET-A-PORTER",
        label: "Net-a-Porter",
        value: "net-a-porter",
        id: "net-a-porter",
        matchUrls: [
            "net-a-porter.com"
        ],
        logo: {
            white: "nap.svg"
        }
    },
    {
        name: "MYTHERESA",
        label: "MyTheresa",
        value: "mytheresa",
        id: "mytheresa",
        matchUrls: [
            "mytheresa.com"
        ],
        logo: {
            white: "mytheresa.svg"
        }
    },
    {
        name: "MODA OPERANDI",
        label: "Moda Operandi",
        value: "modaoperandi",
        id: "modaoperandi",
        matchUrls: [
            "modaoperandi.com"
        ],
        //visible: false,
        logo: {
            white: "moda.svg"
        }
    },
    {
        visible: false,
        name: "MATCHES FASHION",
        label: "Matches Fashion",
        value: "matchesfashion",
        id: "matchesfashion",
        matchUrls: [
            "matchesfashion.com"
        ],
        logo: {
            white: "matches.svg"
        }
    },
    /*{
        name: "INTERMIX",
        value: "intermix",
        id: "intermix"
    },*/
    {
        name: "LUISA VIA ROMA",
        label: "Luisa Via Roma",
        value: "luisaviaroma",
        id: "luisaviaroma",
        matchUrls: [
            "luisaviaroma.com"
        ],
        logo: {
            white: "lvr.svg"
        }
    },
    {
        visible: false,
        name: "HARVEY NICHOLS",
        label: "Harvey Nichols",
        value: "harveynichols",
        id: "harveynichols",
        matchUrls: [
            "harveynichols.com"
        ],
        logo: {
            white: "hn.svg"
        }
    },
    {
        //visible: false,
        name: "SSENSE",
        label: "Ssense",
        value: "ssense",
        id: "ssense",
        matchUrls: [
            "ssense.com"
        ],
        logo: {
            white: "ssense.svg"
        }
    },
    {
        name: "FARFETCH",
        label: "Farfetch",
        value: "farfetch",
        id: "farfetch",
        matchUrls: [
            "farfetch.com"
        ],
        logo: {
            white: "ff.svg"
        }
    },
    {
        //visible: false,
        name: "BROWNS FASHION",
        label: "Browns Fashion",
        value: "browns",
        id: "browns",
        matchUrls: [
            "brownsfashion.com"
        ],
        logo: {
            white: "browns.png"
        }
    },
    {
        visible: false,
        name: "JIMMY CHOO",
        label: "Jimmy Choo",
        value: "jimmychoo",
        id: "jimmychoo",
        matchUrls: [
            "jimmychoo.com"
        ],
        logo: {
            white: "jc.png",
            black: "jc-black.png"
        }
    },
    {
        name: "JW ANDERSON",
        label: "JW Anderson",
        value: "jwanderson",
        id: "jwanderson",
        matchUrls: [
            "jwanderson.com"
        ]
    },
    {
        name: "CHLOE",
        label: "Chloe",
        value: "chloe",
        id: "chloe",
        matchUrls: [
            "chloe.com"
        ],
        logo: {
            white: "chloe.png"
        }
    },
    {
        name: "NEIMAN MARCUS",
        label: "Neiman Marcus",
        value: "neiman",
        id: "neiman",
        matchUrls: [
            "neimanmarcus.com"
        ],
        logo: {
            white: "neiman.svg"
        }
    },
    {
        visible: false,
        name: "NEOUS",
        label: "Neous",
        value: "neous",
        id: "neous",
        matchUrls: [
            "neous.co.uk"
        ],
        logo: {
            white: "neous.svg"
        }
    },
    {
        visible: true,
        name: "RALPH LAUREN",
        label: "Ralph Lauren",
        value: "rl",
        id: "rl",
        matchUrls: [
            "ralphlauren.co"
        ],
        logo: {
            white: "rl.svg"
        }
    },
    {
        visible: true,
        name: "SELFRIDGES",
        label: "Selfridges",
        value: "selfridges",
        id: "selfridges",
        matchUrls: [
            "selfridges.com"
        ],
        logo: {
            white: "selfridges.png",
            black: "selfridges-black.svg"
        }
    },
    {
        visible: true,
        name: "SHOPBOP",
        value: "shopbop",
        label: "Shopbop",
        id: "shopbop",
        matchUrls: [
            "shopbop.com"
        ],
        logo: {
            white: "shopbop.svg"
        },
        matchUrls: [
            "shopbop.com"
        ],
    },
    {
        visible: false,
        name: "ETRO",
        label: "Etro",
        value: "etro",
        id: "etro",
        matchUrls: [
            "etro.com"
        ],
        logo: {
            white: "etro.svg"
        }
    },
    {
        name: "ROGER VIVIER",
        label: "Roger Vivier",
        value: "rogervivier",
        id: "rogervivier",
        matchUrls: [
            "rogervivier.com"
        ],
        logo: {
            white: "rvivier.svg"
        }
    },
    {
        name: "FERRAGAMO",
        label: "Ferragamo",
        value: "ferragamo",
        id: "ferragamo",
        matchUrls: [
            "ferragamo.com"
        ],
        logo: {
            white: "sf.svg"
        }
    },
    {
        visible: false,
        name: "THE WEBSTER",
        label: "The Webster",
        value: "webster",
        id: "webster",
        matchUrls: [
            "thewebster.us"
        ],
        logo: {
            white: "webster.svg"
        }
    },
    {
        name: "BERGDORF GOODMAN",
        label: "Bergdorf Goodman",
        value: "bergdorf",
        id: "bergdorf",
        matchUrls: [
            "bergdorfgoodman.com"
        ],
        logo: {
            white: "goodman.svg"
        }
    },
    {
        name: "FORWARD",
        label: "Forward",
        value: "forward",
        id: "forward",
        matchUrls: [
            "fwrd.com"
        ],
        logo: {
            white: "forward.svg"
        }
    },
    {
        id: "wickstead",
        label: "Emilia Wickstead",
        name: "EMILIA WICKSTEAD",
        value: "wickstead",
        matchUrls: [
            "emiliawickstead.com"
        ]
    },
    {
        id: "altuzarra",
        label: "Altuzarra",
        name: "ALTUZARRA",
        value: "altuzarra",
        matchUrls: [
            "altuzarra.com"
        ]
    },
    {
        visible: false,
        name: "TODS",
        label: "Tod's",
        value: "tods",
        id: "tods",
        matchUrls: [
            "tods.com"
        ]
    },

    {
        name: "SAKS",
        label: "Saks",
        value: "saks",
        id: "saks",
        matchUrls: [
            "saksfifthavenue.com"
        ],
        logo: {
            white: "saks.png",
            black: "saks-black.png"
        }
    },

    {
        id: "miumiu",
        label: "MiuMiu",
        name: "MIU MIU",
        value: "miumiu",
        matchUrls: [
            "miumiu.com"
        ]
    },

    {
        id: "dg",
        label: "DG",
        name: "DG",
        value: "dg",
        matchUrls: [
            "dolcegabbana.com"
        ]
    },

    {
        id: "prada",
        label: "Prada",
        name: "PRADA",
        value: "prada",
        matchUrls: [
            "prada.com"
        ]
    },

    {
        id: "loewe",
        label: "Loewe",
        name: "LOEWE",
        value: "loewe",
        matchUrls: [
            "loewe.com"
        ]
    },

    /*{
        name: "GUCCI",
        value: "gucci",
        id: "gucci",
        logo: {
            white: "gucci.png"
        }
    },

    {
        name: "DR. BARBARA STURM",
        value: "sturm",
        id: "sturm"
    }*/

].sort(sortRets)