
export const omitWhere = { 
    versionAbove: '13.0'
};

export const groups = [
    { id: 'smileys-emotion', icon: "😃" },
    { id: 'people-body', icon: "🕺" },
    { id: 'animals-nature', icon: "🐻" },
    { id: 'food-drink', icon: "🍔" },
    { id: 'travel-places', icon: "🚀" },
    { id: 'activities', icon: "⚽" },
    { id: 'objects', icon: "💡" },
    { id: 'symbols', icon: "💕" },
    { id: 'flags', icon: "🎌" }
];

export const defaultRecents = [
    "👍", "❤️", "😂", "😲", "🥲", "🙏"
];
