
import { alert } from "common/src/components/dialog/Alert"
import hub from "common/src/hub"


function onAccessDenied() {
    alert({
        title: "Access denied",
        message: "Sorry, you don't have access to this app"
    });
}

const listeners = {
    init: () => {
        hub.listen("app-auth", "access-denied", onAccessDenied);
    }
}

export default listeners;