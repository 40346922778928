import { useCallback, useState } from "react"
import { useOn } from "@kuindji/observable-react"


function useContextData(ctx, namespace) {
    const [ data, setData ] = useState(() => ctx ? ctx.getData(namespace) : {});

    const onChange = useCallback(
        () => setData(ctx.getData(namespace)),
        [ ctx, namespace ]
    );

    useOn(
        ctx, 
        namespace ? `${ namespace }:change` : "change", 
        onChange
    );

    return data;
}

export default useContextData