//import EventEmmitter from "events"
import { v4 as uuid } from "uuid"
import throttle from "lodash/throttle"
import Observable from "@kuindji/observable"

import api from "common/src/api"
import { lookLoader } from "common/src/actions/looks"
import productLoader from "common/src/actions/products"
import { consultationsLoader } from "common/src/actions/consultations"
import { createChat, clearNotifications } from "common/src/actions/chat"
import { catalogueProductLoader } from "common/src/actions/catalogueLocal"
import upload from "common/src/lib/image/upload"
import uploadAudio from "common/src/lib/audio/upload"
import readInputFile from "common/src/lib/dom/readInputFile"
import user from "common/src/user"
import preloadImages from "common/src/lib/image/preload"
import { createProducts } from "common/src/actions/catalogueLocal"
import hub from "common/src/hub"
import { setCurrentChatId } from "common/src/actions/online"

export const DEFAULT_MESSAGES_PER_PAGE = 20;

export const UPLOADING_NONE = 0;
export const UPLOADING_IMAGES = 1;
export const UPLOADING_VOICE_MESSAGE = 2;
export const UPLOADING_CATALOGUE_PRODUCTS = 3;

let instances = 0;
let lookCache = [];
let productCache = [];
let moodboardCache = [];
let consultationCache = [];
let catalogueProductCache = [];
let catalogueProductCacheMap = {};

const cleanupCache = () => {
    lookCache = [];
    productCache = [];
    moodboardCache = [];
    consultationCache = [];
    catalogueProductCache = [];
    catalogueProductCacheMap = {};
};

const messagesAreEqual = (m1, m2) => {
    if (m1.id === m2.id) {
        const r1 = m1.notifications && m1.notifications[0] && m1.notifications[0].read;
        const r2 = m2.notifications && m2.notifications[0] && m2.notifications[0].read;
        const s1 = m1.notifications && !!m1.notifications.find(n => n.sent);
        const s2 = m2.notifications && !!m2.notifications.find(n => n.sent);
        const i1 = m1.images.map(i => i.id + "-" + i.uploading).join("-");
        const i2 = m2.images.map(i => i.id + "-" + i.uploading).join("-");
        const v1 = m1.voiceMessages.map(v => v.s3key + "-" + v.uploading).join('-');
        const v2 = m2.voiceMessages.map(v => v.s3key + "-" + v.uploading).join('-');
        const re1 = m1.reactions.map(r => r.reaction).join("-");
        const re2 = m2.reactions.map(r => r.reaction).join("-");
        const cp1 = m1.catalogueProductIds.join("-");
        const cp2 = m2.catalogueProductIds.join("-");
        const h1 = m1.hidden;
        const h2 = m2.hidden;
        const u1 = m1.uploading;
        const u2 = m2.uploading;
        return r1 === r2 && s1 === s2 && i1 === i2 && v1 === v2 
                && re1 === re2 && cp1 === cp2 && h1 === h2 
                && u1 === u2;
    }
    return false;
};


async function publishLook(lookId, chat) {
    await api.look.update(lookId, { published: true });
    chat.loadLooks([ lookId ], false);            
}

async function shareMoodboard(moodboardId, contactId) {
    const shares = await api.moodboardShare.list({ where: {
        moodboardId,
        userId: contactId
    }});

    if (shares.length === 0) { 
        await api.moodboardShare.create({
            moodboardId,
            userId: contactId
        })
    }
}

async function uploadAudioMessage(audio, chatId, messageId) {

    const audioUploadKey = await uploadAudio(audio.blob || audio.buffer, audio.mime);

    if (audioUploadKey) {
        const audioUploadResp = await api.backend.post("/upload/voicemessage", {
            body: {
                chatId, 
                messageId, 
                contentType: audio.mime || audio.blob?.type,
                uploadKey: audioUploadKey
            }
        })

        await api.chatVoiceMessage.create({
            chatId, 
            messageId,
            userId: user.id(),
            s3key: audioUploadResp.key,
            length: audioUploadResp.length || 0,
            size: audioUploadResp.size || 0
        });
    }
}

async function uploadCatalogueProducts(products, chat, chatId, messageId) {
    const pwids = products.map(p => p.productWebId || p.product_web_id);
    const catalogueProductIds = await createProducts(pwids);
    chat.loadCatalogueProducts(catalogueProductIds);

    if (catalogueProductIds.length > 0) {
        for (let i = 0, l = catalogueProductIds.length; i < l; i++) {
            await api.chatCatalogueProduct.create({
                chatId, 
                messageId,
                userId: user.id(),
                catalogueProductId: catalogueProductIds[i]
            });
        }
    }
}

async function uploadImage(img, chat, chatId, messageId) {

    const imgRec = await api.chatImage.create({
        chatId, 
        messageId,
        userId: user.id(),
        image: null,
        uploading: true
    });

    try {

        const { mime, buffer } = 
            chat._attachmentBufferPromise[ img.localId ] ?
                await chat._attachmentBufferPromise[ img.localId ] :
                {};
        delete chat._attachmentBufferPromise[ img.localId ];

        if (buffer) {
            img.buf = buffer;
        }

        const uploadKey = await upload(img);
        
        const imgResponse = await api.backend.post("/upload/chat", {
            body: {
                chatId, 
                messageId, 
                contentType: mime,
                name: img.name,
                uploadKey: uploadKey
            }
        });

        await api.chatImage.update(imgRec.id, {
            image: JSON.stringify({
                mime: mime,
                name: img.name,
                key: imgResponse.key,
                width: img.width,
                height: img.height
            }),
            uploading: false
        });
    }
    catch (err) {
        console.error(err);
        await api.chatImage.remove({ id: { _eq: imgRec.id }});
    }

}


class Chat extends Observable {

    contact = null
    contactRole = null
    currentUserRole = null
    chat = null
    messages = []
    searchResults = []
    consultations = []
    localMessages = {}
    state = {}
    attachments = []

    _attachmentBufferPromise = {}
    _searchMode = false
    _detached = false
    _eventCounters = {}
    _endReached = false
    perPage = DEFAULT_MESSAGES_PER_PAGE

    constructor({ perPage = DEFAULT_MESSAGES_PER_PAGE, currentUserRole, state = {}, detached = false }) {
        super();
        this.perPage = perPage;
        this.currentUserRole = currentUserRole;
        this.state = state;
        this._detached = detached;
        instances++;
        //this.setMaxListeners(100);

        this.setTyping = throttle(this.setTyping.bind(this), 1000, { trailing: true });
        this.setRecording = throttle(this.setRecording.bind(this), 1000, { trailing: true });

        this.onMoodboardRemoved = this.onMoodboardRemoved.bind(this);
        this.onLookUpdated = this.onLookUpdated.bind(this);
        this.onLookProductCommentAdded = this.onLookProductCommentAdded.bind(this);
        this.onProductReactionChanged = this.onProductReactionChanged.bind(this);
        this.onCatalogueProductReactionChanged = this.onCatalogueProductReactionChanged.bind(this);

        hub.listen("product", "reaction-changed", this.onProductReactionChanged);
        hub.listen("product", "comment-added", this.onLookProductCommentAdded);
        hub.listen("catalogue-product", "reaction-changed", this.onCatalogueProductReactionChanged);
        hub.listen("moodboard", "removed", this.onMoodboardRemoved);
        hub.listen("look", "updated", this.onLookUpdated);
        hub.listen("look", "published", this.onLookUpdated);
        hub.listen("look", "unpublished", this.onLookUpdated);
        hub.listen("look", "unpublished", this.onLookUpdated);
        this.on("loadingConsultations", (e) => {
            this.state.isConsLoading = e;
        });
    }

    off(...args) {
        return this.un.apply(this, args);
    }

    emit(...args) {
        return this.trigger.apply(this, args);
    }

    destroy() {
        instances--;
        this.removeAllListeners();
        hub.remove("product", "reaction-changed", this.onProductReactionChanged);
        hub.remove("catalogue-product", "reaction-changed", this.onCatalogueProductReactionChanged);
        hub.remove("moodboard", "removed", this.onMoodboardRemoved);
        hub.remove("look", "updated", this.onLookUpdated);
        hub.remove("look", "published", this.onLookUpdated);
        hub.remove("look", "unpublished", this.onLookUpdated);
        if (instances === 0) {
            cleanupCache();
        }
    }

    reset() {
        this.unsubscribe();
        this.localMessages = {};
        this.messages = [];
        this.searchResults = [];
        this.consultations = [];
        this.attachments = [];
        this.chat = null;
        this.contact = null;
        this._searchMode = false;
        this.trigger("messages");
        this.trigger("consultations");
        this.trigger("chat");
        this.trigger("contact");
        this.trigger("attachments");
        cleanupCache();
    }

    _getEventCount(event) {
        if (this._eventCounters[event] === undefined) {
            this._eventCounters[event] = 0;
        }
        return ++this._eventCounters[event];
    }

    async setContact(contact) {

        if (!contact) {
            this.reset();
            return;
        }

        if (this.contact && this.contact.id === contact.id) {
            return;
        }

        if (this.contact) {
            this.reset();
        }

        this.contact = contact;
        this._endReached = false;
        this.trigger("endReached", false);
        this.trigger("contact", contact);

        await this.loadChat();

        if (this.chat && !this._detached) {
            setCurrentChatId(this.chat.id);
            this.contactRole = this.chat.participants.find(p => p.userId === this.contact.id)?.role;
            await this.loadMessages();
            this.subscribe();
        }
    }

    async search() {

        if (!this.state.query) {
            this.clearSearch();
            return;
        }

        this.trigger("loadingMessages", true);

        const res = await api.backend.post("/chat/search", { body: {
            query: this.state.query,
            chat_id: this.chat.id
        }});
        const ids = res.messages.map(row => row.message_id);

        const input = {
            limit: ids.length,
            where: { 
                id: { _in: ids },
                chatId: { _eq: this.chat.id },
                hidden: { _eq: false }
            },
            order: { createdAt: "desc" }
        };

        const messages = await api.chatMessage.list(input);
        
        await this.processMessageCache(messages);

        this._searchMode = true;
        this.searchResults = messages;
        
        this.trigger("loadingMessages", false);
        this.trigger("messages");
        
    }

    clearSearch() {
        this._searchMode = false;
        this.searchResults = [];
        this.setState("query", "");
        this.trigger("messages");
    }

    setState(key, value) {
        if (arguments.length > 1) {
            if (this.state[key] !== value) {
                this.state[key] = value;
                this.trigger("state");
            }
        }
        else {
            this.state = { ...this.state, ...key };
            this.trigger("state");
        }
    }


    async createMessage(text="", contacts) {
        let uploads = [], audio, lookId = null, look = null,
            productId = null, moodboardId = null, catalogue = [],
            consultationId = null, consultation = null,
            //catalogueProductIds = [],
            uploading = UPLOADING_NONE;

        const attachment = this.getFirstAttachment();
        const replyTo = this.getAttachmentValue("reply");

        if (attachment) {
            if (attachment.type === "look") {
                lookId = attachment.value.id;
                look = attachment.value;
            }
            else if (attachment.type === "product") {
                productId = attachment.value.id;
            }
            else if (attachment.type === "moodboard") {
                moodboardId = attachment.value.id;
            }
            else if (attachment.type === "audio") {
                audio = attachment.value;
                uploading = UPLOADING_VOICE_MESSAGE;
            }
            else if (attachment.type === "image") {
                uploads = attachment.value;
                uploading = UPLOADING_IMAGES;
            }
            else if (attachment.type === "catalogue") {
                catalogue = attachment.value;
                uploading = UPLOADING_CATALOGUE_PRODUCTS;
            }
            else if (attachment.type === "consultation") {
                consultationId = attachment.value.id;
                consultation = attachment.value;
            }
        }

        this.trigger("creatingMessage", true);

        if (contacts) {
            const result = await api.chat.list({
                where: {
                    _and: [
                        { participants: { userId: { _eq: user.id() }}},
                        { participants: { userId: { _eq: contact.id }}}
                    ]
                }
            });
            this.chat = result[0] || result;
        }

        let chatId = this.chat?.id,
            contactId = this.contact.id,
            //audioUploadKey, 
            msg;

        const   tmpId = "tmp_" + (new Date()).getTime(),
                replyToId = replyTo ? replyTo.id : null;

        if (!this.chat) {
            chatId = await createChat(contactId, this.currentUserRole);
            await this.loadChat();
            !this._detached && this.subscribe();
        }

        const localMessage = {
            id: tmpId,
            sending: true,
            chatId,
            lookId,
            productId,
            moodboardId,
            consultationId,
            replyToId,
            replyTo,
            uploading,
            userId: user.id(),
            message: text,
            user: user.current(),
            createdAt: (new Date()).toISOString(),
            notifications: [],
            images: [],
            voiceMessages: [],
            catalogueProductIds: [],
            reactions: []
        };

        if (uploads && uploads.length > 0) {
            localMessage.images = uploads.map((i, inx) => {
                return {
                    id: "tmp_img_" + (new Date()).getTime() +"_"+ inx,
                    image: i
                };
            })
        };

        if (catalogue.length > 0) {
            localMessage.catalogueProducts = [ ...catalogue ];
            // const pwids = catalogue.map(p => p.productWebId || p.product_web_id);
            // catalogueProductIds = await createProducts(pwids);
            // localMessage.catalogueProductIds = [ ...catalogueProductIds ];
            // this.loadCatalogueProducts(catalogueProductIds);
        }

        if (audio) {
            localMessage.voiceMessages = [
                {
                    id: "vm_" + (new Date()).getTime(),
                    src: audio.src,
                    length: audio.length
                }
            ]
        };

        //await 
        this.processMessageCache([ localMessage ]);

        // immediately display this new message
        this.messages.unshift(localMessage);
        this.trigger("messages");

        if (lookId && !look.published) {
            publishLook(lookId, this);
        }

        if (moodboardId) {
            shareMoodboard(moodboardId, contactId);
        }

        msg = await api.chatMessage.create({
            chatId,
            lookId,
            consultationId,
            productId,
            moodboardId,
            replyToId,
            uploading,
            userId: user.id(),
            message: text
        });

        this.localMessages[ msg.id ] = {
            tmpId,
            //sending: true
        };

        this.removeAttachment("reply");
        if (attachment) {
            this.removeAttachmentsByIndexes(attachment.indexes);
        }

        //hub.dispatch("chat", "message-sent", { chatId });
        this.triggerMessageSentEvent(attachment);
        this.trigger("creatingMessage", false);


        // Uploading stuff

        const promises = [];

        if (audio) {
            promises.push(uploadAudioMessage(audio, chatId, msg.id));
        }

        if (catalogue.length > 0) {
            promises.push(uploadCatalogueProducts(catalogue, this, chatId, msg.id));
        }

        if (uploads && uploads.length > 0) {
            for (const img of uploads) {
                promises.push(uploadImage(img, this, chatId, msg.id));
            }
        }

        /*this.localMessages[ msg.id ].sending = false;
        if (this.localMessages[ msg.id ].msg) {
            this.proccessSubscriptionResponse([ this.localMessages[ msg.id ].msg ]);
        }*/

        if (promises.length > 0) {
            Promise.allSettled(promises).then(() => {
                api.chatMessage.update(msg.id, {
                    uploading: UPLOADING_NONE
                });
            });
        }
    }

    async createBroadcastMessage(text, broadcastId, contacts) {
        let uploads = [], productId = null, 
            moodboardId = null, catalogue = [],
            uploading = UPLOADING_NONE;

        const attachment = this.getFirstAttachment();

        if (attachment) {
            if (attachment.type === "product") {
                productId = attachment.value.id;
            }
            else if (attachment.type === "moodboard") {
                moodboardId = attachment.value.id;
            }
            else if (attachment.type === "image") {
                uploads = attachment.value;
                uploading = UPLOADING_IMAGES;
            }
            else if (attachment.type === "catalogue") {
                catalogue = attachment.value;
                uploading = UPLOADING_CATALOGUE_PRODUCTS;
            }
        }

        if (attachment) {
            this.removeAttachmentsByIndexes(attachment.indexes);
        }

        this.trigger("creatingMessage", true);

        const localMessage = {
            broadcastId,
            productId,
            moodboardId,
            uploading,
            userId: user.id(),
            message: text,
        };

        const promises = [];

        if (contacts) {
            const contactIds = contacts.map(c => c.id);

            const result = await api.chat.list({
                where: {
                    _and: [
                        { participants: { userId: { _eq: user.id() }, role: { _eq: "concierge" } }},
                        { participants: { userId: { _in: contactIds }, role: { _eq: "shopper" } }}
                    ]
                }
            });

            const chatIds = result.map(r => r.id);

            for (let i = 0; i < chatIds.length; i++) {

                const chatMessages = await api.chatMessage.list({ where: { _and: [
                    { chatId: { _eq: chatIds[i] } },
                    { broadcastId: { _eq: broadcastId } }
                ] } });

                if (chatMessages.length === 0) {
                    const { id: messageId } = await api.chatMessage.create({ ...localMessage, chatId: chatIds[i] });

                    if (catalogue.length > 0) {
                        promises.push(uploadCatalogueProducts(catalogue, this, chatIds[i], messageId));
                    }

                    if (uploads && uploads.length > 0) {
                        for (const img of uploads) {
                            promises.push(uploadImage(img, this, chatIds[i], messageId));
                        }
                    }

                    if (promises.length > 0) {
                        Promise.allSettled(promises).then(() => {
                            api.chatMessage.update(messageId, {
                                uploading: UPLOADING_NONE
                            });
                        });
                    }
                }
            }



            this.triggerMessageSentEvent(attachment);
            this.trigger("creatingMessage", false);
        }
    }


    async send(text="") {
        this.trigger("sending", true);
        await this.createMessage(text);
        this.trigger("scrollToLast");

        while (this.attachments.length > 0) {
            await this.createMessage("");
            this.trigger("scrollToLast");
        }
        this.trigger("sending", false);
    }

    async sendBroadcast(text="", broadcastId, connections) {
        this.trigger("sending", true);

        await this.createBroadcastMessage(text, broadcastId, connections);

        this.trigger("sending", false);
    }

    triggerMessageSentEvent(attachment) {
        const payload = {};
        const analytics = {};
        const chatId = this.chat?.id
        payload.chatId = chatId;

        if (attachment) {
            if (attachment.type === "look") {
                payload.lookId = attachment.value.id;   
                analytics.lookLastSentAt = (new Date()).toISOString();
            }
            else if (attachment.type === "product") {
                payload.productId = attachment.value.id;
            }
            else if (attachment.type === "moodboard") {
                payload.moodboardId = attachment.value.id;
                analytics.moodboardLastSentAt = (new Date()).toISOString();
            }
            else if (attachment.type === "consultation") {
                payload.consultationId = attachment.value.id;
                analytics.consultationLastSentAt = (new Date()).toISOString();
            }
            else if (attachment.type === "audio") {
                payload.withAudioMessage = true;
            }
            else if (attachment.type === "image") {
                payload.withImages = true;
                payload.numberOfImages = attachment.value.length;
            }
            else if (attachment.type === "catalogue") {
                payload.withCatalogueProducts = true;
                payload.numberOfProducts = attachment.value.length;
                analytics.catalogueLastSentAt = (new Date()).toISOString();
            }
        }

        hub.dispatch("chat", "message-sent", payload);

        if (Object.keys(analytics).length > 0) {
            hub.dispatch("user-analytics", "change", analytics);
        }
    }


    async loadChat() {
        this.trigger("loadingChat", true);
        
        this.chat = await api.chat.list({
            where: {
                _and: [
                    { participants: { userId: { _eq: user.id() }}},
                    { participants: { userId: { _eq: this.contact.id }}}
                ]
            }
        })
        .then(list => list[0]);
        this.trigger("loadingChat", false);

        if (this.chat) {
            this.trigger("chat");
        }
    }

    async subscribe() {

        if (this._subscription || !this.chat) {
            return;
        }

        const input = {
            where: { 
                chatId: { _eq: this.chat.id },
                _and: [
                  {
                    _or: [
                      { look: { deleted: { _neq: true } } },
                      { lookId: { _is_null: true } }
                    ]
                  },
                  {
                    _or: [
                      { hidden: { _eq: false } },
                      { action: { _nin: ["product-like", "product-shop", "register"] } }
                    ]
                  }
                ]
            },
            order: { 
                createdAt: "desc" 
            },
            limit: this.perPage
        };

        this._subscription = await api.chatMessage.subscribeList(input, null, (res) => {
            this.proccessSubscriptionResponse(res);
        });
        this.trigger("subscribe");
    }

    unsubscribe() {
        if (this._subscription) { 
            this._subscription.subscription.unsubscribe();
            this._subscription = null;
            this.trigger("unsubscribe");
        }
    }

    async loadMessages() {

        if (!this.chat) {
            return;
        }

        this.trigger("loadingMessages", true);
        const input = {
            limit: this.perPage,
            where: { 
                chatId: { _eq: this.chat.id },
                _and: [
                  {
                    _or: [
                      { look: { deleted: { _neq: true } } },
                      { lookId: { _is_null: true } }
                    ]
                  },
                  {
                    _or: [
                        {
                            _and: [
                                { consultation: { status: { _neq: "cancelled" } } },
                                { consultation: { hidden: { _eq: false } } }
                            ]
                        },
                        { consultationId: { _is_null: true } }
                    ]
                  },
                  {
                    _or: [
                      { hidden: { _eq: false } },
                      { action: { _nin: ["product-like", "product-shop", "register"] } }
                    ]
                  }
                ]
            },
            order: { createdAt: "desc" }
        };

        this.messages = await api.chatMessage.list(input);
        
        await this.processMessageCache(this.messages);
        clearNotifications(this.chat.id);
        
        this.trigger("loadingMessages", false);
        this.trigger("messages");

        if (this.messages.length < this.perPage) {
            this._endReached = true;
            this.trigger("endReached", true);
        }
    }

    async loadMoreMessages() {

        if (!this.chat || this._endReached) {
            return;
        }

        this.trigger("loadingMoreMessages", true);

        const input = {
            where: { 
                chatId: { _eq: this.chat.id },
                _and: [
                  {
                    _or: [
                      { look: { deleted: { _neq: true } } },
                      { lookId: { _is_null: true } }
                    ]
                  },
                  {
                    _or: [
                      { hidden: { _eq: false } },
                      { action: { _nin: ["product-like", "product-shop", "register"] } }
                    ]
                  }
                ]
                //hidden: { _eq: false }
            },
            order: { createdAt: "desc" },
            limit: this.perPage,
            offset: this.messages.length + 1
        };

        const messagesPage = await api.chatMessage.list(input);
        const messages = this.messages;
        messagesPage.forEach(message => {
            if (!messages.find(m => m.id === message.id)) {
                messages.push(message);
            }
        });
        await this.processMessageCache(messagesPage);

        this.trigger("loadingMoreMessages", false);
        this.trigger("messages");

        if (messagesPage.length < this.perPage) {
            this._endReached = true;
            this.trigger("endReached", true);
        }
    }

    async loadConsultations(ids) {

        if (this.state.isConsLoading) {
            return;
        }

        if (!this.contact) {
            return;
        }

        if (ids?.length) {
            ids = ids.filter(id => this.consultations.findIndex(c => c.id === id) === -1);
        }

        this.trigger("loadingConsultations", true);
        const contactId = this.contact.id;
        const userId = user.id();
        const where = ids?.length ? {
            id: { _in: ids },
            _or: [
                {
                    friId: { _eq: contactId },
                    customerId: { _eq: userId }
                },
                {
                    friId: { _eq: userId },
                    customerId: { _eq: contactId }
                }
            ]
        } : {
            _not: { id: { _in: consultationCache.map(c => c.id) } },
            _or: [
                {
                    friId: { _eq: contactId },
                    customerId: { _eq: userId }
                },
                {
                    friId: { _eq: userId },
                    customerId: { _eq: contactId }
                }
            ]
        };
        const cons = await consultationsLoader({
            withLooks: true,
            withReactions: true,
            limit: 1000,
            where: where,
            order: {
                createdAt: "desc"
            }
        });

        cons.forEach(c => consultationCache.push(c));

        this.consultations = [ ...this.consultations, ...cons];

        this.trigger("loadingConsultations", false);   
        this.trigger("consultations");
    }

    async proccessSubscriptionResponse(messages) {

        if (messages.length === 0) {
            return;
        }

        if (messages[0].chatId !== this.chat.id) {
            return;
        }

        const stateMessages = this.messages;
        const localMessages = this.localMessages;
        let update = false;
        let hasNew = false;
        const newIds = [];
        const updateIds = [];
        const promises = [];

        messages.reverse().forEach(message => {

            // if this is a current user's message and it is still uploading,
            // do not replace localMessage record.
            if (message.uploading && message.userId === user.id()) {
                return;
            }

            if (localMessages[ message.id ]) {
                /*if (localMessages[ message.id ].sending) {
                    localMessages[ message.id ].msg = message;
                    return;
                }
                else {*/
                    const tmpId = localMessages[ message.id ].tmpId;
                    const inx = stateMessages.findIndex(m => m.id === tmpId);
                    stateMessages.splice(inx, 1, message);
                    delete localMessages[ message.id ];
                    update = true;
                //}
            }
            else {
                const inx = stateMessages.findIndex(m => m.id === message.id);
                if (inx === -1) {
                    // in case we deleted a message and subscription now returns 
                    // one more message before the earliest in the list 
                    // (given subscription request with page size)
                    // we must put in to the opposite side of array
                    // (the array is reversed, [0] is the latest)

                    // console.log("got new message", 
                    //     message.id, 
                    //     message.createdAt, 
                    //     (new Date(message.createdAt)).getTime());

                    const firstMessage = stateMessages[ 0 ];

                    // console.log("current first message", 
                    //     firstMessage?.id, 
                    //     firstMessage?.createdAt,
                    //     (new Date(firstMessage?.createdAt)).getTime())

                    if (firstMessage && 
                            (new Date(message.createdAt)).getTime() < 
                            (new Date(firstMessage.createdAt)).getTime()) {

                        // do nothing
                        //update = true;
                        //promises.push(this.processMessageCache([ message ]));
                        //stateMessages.pop(message);
                    }
                    else {
                        hasNew = true;
                        update = true;
                        //console.log(message)
                        newIds.push(message.id);
                        promises.push(this.processMessageCache([ message ]));
                        stateMessages.unshift(message);
                    }
                }
                else {
                    const prevMsg = stateMessages[inx];
                    if (!messagesAreEqual(prevMsg, message)) {
                        update = true;
                        updateIds.push(message.id);
                        promises.push(this.processMessageCache([ message ]));
                        stateMessages.splice(inx, 1, { ...message });
                    }
                }
            }
        });

        await Promise.all(promises);

        if (update) {
            this.trigger("messages", updateIds);
        }
        if (hasNew) {
            this.trigger("newMessages", newIds);
        }
    }

    async processMessageCache(messages) {
    
        const lookIds = messages
                        .filter(m => !!m.lookId && !lookCache.find(l => l.id === m.lookId))
                        .map(m => m.lookId);

        const productIds = messages
                            .filter(m => !!m.productId && !productCache.find(p => p.id === m.productId))
                            .map(m => m.productId);

        const catalogueProductIds = messages
                                    .filter(m => !!m.catalogueProductIds && m.catalogueProductIds.length > 0)
                                    .map(m => m.catalogueProductIds)
                                    .flat();

        const moodboardIds = messages
                                .filter(m => !!m.moodboardId && !moodboardCache.find(c => c.id === m.moodboardId))
                                .map(m => m.moodboardId);

        const consultationIds = messages
                                    .filter(m => !!m.consultationId && !consultationCache.find(c => c.id === m.consultationId))
                                    .map(m => m.consultationId);

        messages.forEach(m => {
            if (m.replyTo) {
                const moodboardId = m.replyTo.moodboardId;
                const lookId = m.replyTo.lookId;
                const productId = m.replyTo.productId;
                const rCatalogueProductIds = m.replyTo.catalogueProductIds;
                if (productId && productIds.indexOf(productId) === -1) {
                    productIds.push(productId);
                }
                if (lookId && lookIds.indexOf(lookId) === -1) {
                    lookIds.push(lookId);
                }
                if (rCatalogueProductIds) {
                    rCatalogueProductIds.forEach(id => catalogueProductIds.push(id));
                }
                if (moodboardId && moodboardIds.indexOf(moodboardId) === -1) {
                    moodboardIds.push(moodboardId);
                }
            }
        });

        const promises = [];

        if (lookIds.length) {
            promises.push(this.loadLooks(lookIds));
        }
        if (productIds.length) {
            promises.push(this.loadProducts(productIds));
        }
        if (catalogueProductIds.length) {
            promises.push(this.loadCatalogueProducts(catalogueProductIds));
        }
        if (moodboardIds.length) {
            promises.push(this.loadMoodboards(moodboardIds));
        }
        if (consultationIds.length) {
            promises.push(this.loadConsultations(consultationIds))
        }
    
        return Promise.all(promises);
    }

    async loadCatalogueProducts(ids, skipExisting = true) {

        const existing = ids.filter(id => !!catalogueProductCacheMap[id]);

        if (skipExisting) {
            ids = ids.filter(id => existing.indexOf(id) === -1);
        }

        if (ids.length === 0) {
            return;
        }

        const reactionsUserId = this.currentUserRole === "customer" ? user.id() : this.contact?.id;

        const products = await catalogueProductLoader({
            limit: null,
            where: {
                id: {
                    _in: ids
                }
            },
            withReactions: true,
            reactionsUserId,
            withStock: true
        });

        products.forEach(p => {
            catalogueProductCacheMap[p.id] = p;
            catalogueProductCache.push(p)
        });
        this.trigger("catalogueProducts", this._getEventCount("catalogueProducts"));
        this.trigger("cache", this._getEventCount("cache"));
    }

    async loadLooks(ids, skipExisting = true) {
        const existing = ids.filter(id => lookCache.findIndex(l => l.id === id) !== -1);

        if (skipExisting) {
            ids = ids.filter(id => existing.indexOf(id) === -1);
        }

        if (ids.length === 0) {
            return;
        }

        const reactionsUserId = this.currentUserRole === "customer" ? user.id() : this.contact?.id;
        const looks = await lookLoader({
            limit: null,
            where: {
                id: {
                    _in: ids
                }
            },
            withReactions: true,
            reactionsUserId,
            withMyLastMessage: true,
            withProductStock: true
        });

        looks.forEach(l => {
            if (existing.indexOf(l.id) !== -1) {
                const inx = lookCache.findIndex(look => look.id === l.id);
                lookCache[inx] = l;
            }
            else {
                lookCache.push(l);
            }
        });
        
        this.trigger("looks", this._getEventCount("looks"));
        this.trigger("cache", this._getEventCount("cache"));
    }

    async loadProducts(ids, skipExisting = true) {

        const existing = ids.filter(id => productCache.findIndex(p => p.id === id) !== -1);

        if (skipExisting) {
            ids = ids.filter(id => existing.indexOf(id) === -1);
        }

        if (ids.length === 0) {
            return;
        }

        const reactionsUserId = this.currentUserRole === "customer" ? user.id() : this.contact?.id;
        const products = await productLoader({
            limit: null,
            withReactions: true,
            reactionsUserId,
            where: {
                id: { _in: ids }
            },
            productFields: `
                designers { designer { id name } }
                look { published }
            `
        });

        products.forEach(p => {
            if (existing.indexOf(p.id) !== -1) {
                const inx = productCache.findIndex(product => product.id === p.id);
                productCache[inx] = p;
            }
            else {
                productCache.push(p)
            }
        });

        this.trigger("products", this._getEventCount("products"));
        this.trigger("cache", this._getEventCount("cache"));
    }

    async loadMoodboards(ids) {
        
        ids = ids.filter(id => moodboardCache.findIndex(m => m.id === id) === -1);

        if (ids.length === 0) {
            return;
        }

        const where = { 
            id: { _in: ids }
        };
        const moodboards = await api.moodboard.list({ where });
        moodboards.forEach(m => moodboardCache.push(m));

        this.trigger("moodboards", this._getEventCount("moodboards"));
        this.trigger("cache", this._getEventCount("cache"));
    }


    reloadAttachmentsWithCurrency() {
        const lookIds = lookCache.map(l => l.id);
        const lookProductIds = productCache.map(p => p.id);
        const catalogueProductIds = catalogueProductCache.map(p => p.id);

        if (lookIds.length > 0) {
            this.loadLooks(lookIds, false);
        }
        if (lookProductIds.length > 0) {
            this.loadProducts(lookProductIds, false);
        }
        if (catalogueProductIds.length > 0) {
            this.loadCatalogueProducts(catalogueProductIds, false);
        }
    }

    removeMessage(id) {
        const inx = this.messages.findIndex(m => m.id === id);
        if (inx !== -1) {
            this.messages.splice(inx, 1);
            this.trigger("messages");
        }
    }


    async readInputImage(file) {

        const { mime, data } = await readInputFile(file, "base64");
        const buf = await readInputFile(file, "buffer");
        const src = `data:${ mime };base64,${ data }`;
        const img = await preloadImages([ src ]).then(imgs => imgs[0]);
        img.name = file.name;
        img.mime = mime;
        img.localId = uuid();
        img.buf = buf;

        return img;
    }


    onMoodboardRemoved({ id }) {

        let found = false;
        moodboardCache.forEach((m, inx) => {
            if (m.id === id) {
                found = true;
                moodboardCache[inx] = { ...moodboardCache[inx], deleted: true };
            }
        })   
        if (found) {
            this.trigger("moodboards", this._getEventCount("moodboards"));
            this.trigger("cache", this._getEventCount("cache"));
        }
    }

    onProductReactionChanged({ productId, reaction }) {
        let productsFound = false;
        let looksFound = false;

        productCache.forEach((p, inx) => {
            if (p.id === productId) {
                productsFound = true;
                productCache[inx] = Object.assign({}, p, { reaction });
            }
        });

        lookCache.forEach((look, lookInx) => {
            look.products.forEach((p, inx) => {
                if (p.id === productId) {
                    looksFound = true;
                    look.products[inx] = Object.assign({}, p, { reaction });
                    lookCache[lookInx] = Object.assign({}, look);
                }
            })
        })

        if (productsFound) {
            this.trigger("products", this._getEventCount("products"));
        }
        if (looksFound) {
            this.trigger("looks", this._getEventCount("looks"));
        }
        if (productsFound || looksFound) {
            this.trigger("cache", this._getEventCount("cache"));
        }
    }

    onCatalogueProductReactionChanged({ catalogueProductId, reaction }) {
        let found = false;

        catalogueProductCache.forEach((p, inx) => {
            if (p.id === catalogueProductId) {
                found = true;
                catalogueProductCache[inx] = Object.assign({}, p, { reaction });
                catalogueProductCacheMap[p.id] = Object.assign({}, p, { reaction });
            }
        });

        if (found) {
            this.trigger("catalogueProducts", this._getEventCount("catalogueProducts"));
            this.trigger("cache", this._getEventCount("cache"));
        }
    }

    onLookUpdated({ id }) {
        id = Array.isArray(id) ? id : [ id ];
        this.loadLooks(id, false);
    }

    onLookProductCommentAdded({ lookId }) {
        this.loadLooks([ lookId ], false);
    }


    setTyping() {
        const id = this.getId();
        if (id) {
            const where = {
                chatId: { _eq: this.getId() },
                userId: { _eq: user.id() }
            }
            const ts = (new Date()).toISOString();
            api.chatParticipant.update(where, { lastTypingAt: ts });
        }
    }

    setRecording() {
        const id = this.getId();
        if (id) {
            const where = {
                chatId: { _eq: this.getId() },
                userId: { _eq: user.id() }
            }
            const ts = (new Date()).toISOString();
            api.chatParticipant.update(where, { lastRecordingAt: ts });
        }
    }


    getState(key) {
        if (key) {
            return this.state[key];
        }
        return { ...this.state };
    }

    getContact() {
        return this.contact ? { ...this.contact } : null;
    }

    getChat() {
        return this.chat ? { ...this.chat } : null;
    }

    getId() {
        return this.chat?.id;
    }

    getLookCache() {
        return [ ...lookCache ];
    }

    getProductsCache() {
        return [ ...productCache ];
    }

    getCatalogueProductCache(ids) {
        if (ids) {
            return ids.map(id => catalogueProductCacheMap[id]).filter(p => !!p);
        }
        return [ ...catalogueProductCache ];
    }

    getMoodboardCache() {
        return [ ...moodboardCache ];
    }

    getConsultationsCache() {
        return [ ...consultationCache ].filter(c => !c.hidden);
    }

    getMessage(id) {
        return this.messages.find(m => m.id === id) ||
                this.searchResults.find(m => m.id === id);
    }

    getMessages() {
        return this._searchMode ? [ ...this.searchResults ] : [ ...this.messages ];
    }

    getConsultations() {
        return [ ...this.consultations ].filter(c => !c.hidden);
    }


    getFirstAttachment() {
        let type = null, value = null;
        const indexes = [];
        this.attachments.forEach((a, inx) => {
            if (a.type === "reply") {
                return;
            }
            if (!type) {
                type = a.type;
            }
            if (type !== a.type) {
                return;
            }

            // we group images and catalogue products into one message attachment
            // others, like look products, are sent separately
            if ((type !== "image" && type !== "catalogue") &&
                type === a.type && 
                indexes.length > 0) {
                return;
            }

            indexes.push(inx);

            if (type === "image" || type === "catalogue") {
                if (!value) {
                    value = [];
                }
                value.push(a.value);
            }
            else {
                value = a.value;
            }
        });
        
        return type ? {
            type,
            value, 
            indexes
        } : null;
    }


    addAttachment(type, value, id) {
        const inx = this.attachments.findIndex(a => a.type === type && a.id === id);
        if (inx === -1) {
            this.attachments.push({ type, value, id });
            this.trigger("attachments");
        }
    }

    setAttachmentBufferPromise(id, promise) {
        this._attachmentBufferPromise[id] = promise;
    }

    removeAttachment(type, id) {
        const inx = this.attachments.findIndex(a => a.type === type && (!id || a.id === id));
        if (inx !== -1) {
            this.attachments.splice(inx, 1);
            this.trigger("attachments");
        }
    }

    removeAttachmentsByIndexes(indexes) {
        indexes.forEach(index => {
            this.attachments[index] = null;
        })
        this.attachments = this.attachments.filter(a => !!a);
        this.trigger("attachments");
    }

    clearAttachments(type) {
        if (type) {
            if (typeof type === "string") {
                this.attachments = this.attachments.filter(a => a.type !== type);
            }
            else {
                this.attachments = this.attachments.filter(a => type.indexOf(a.type) === -1);
            }
        }
        else {
            this.attachments = [];
        }
        this.trigger("attachments");
    }

    hasAttachments(type) {
        return this.getAttachments(type).length > 0;
    }

    getAttachments(type) {
        if (!type) {
            return [ ...this.attachments ];
        }
        else {
            if (typeof type === "string") {
                return this.attachments.filter(a => a.type === type);
            }
            else {
                return this.attachments.filter(a => type.indexOf(a.type) !== -1);
            }
        }
    }

    getAttachmentValue(type, id) {
        const a = this.attachments.find(a => a.type === type && (!id || a.id === id));
        return a?.value || null;
    }

}


export default Chat