import { useEffect, useCallback, useState } from "react"

function useIsMobile() {
    const [ isMobile, setIsMobile ] = useState(false);


    const checkIsMobile = useCallback(
        () => {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
              setIsMobile(
                window.innerWidth <= 450 ||
                /android|iPad|iPhone|iPod/.test(userAgent)
              );        
        },
        [ ]
    )

    useEffect(
        () => {
            checkIsMobile();
    
            window.addEventListener('resize', checkIsMobile);
            return () => {
                window.removeEventListener('resize', checkIsMobile);
            };
        }, 
        [ ]
    );

    return isMobile;
}

export default useIsMobile