import { useCallback, useState, useEffect, useRef } from "react";

const useScrollDetection = (scrollableTarget) => {
    const [hasVerticalScroll, setHasVerticalScroll] = useState(false);
    const [hasHorizontalScroll, setHasHorizontalScroll] = useState(false);
    const [scrollInfo, setScrollInfo] = useState({
        scrollTop: 0,
        scrollLeft: 0,
        scrollWidth: 0,
        scrollHeight: 0,
        clientWidth: 0,
        clientHeight: 0,
    });
    
    const elementRef = useRef(null);
    const isWindowRef = useRef(false);

    const getScrollValues = useCallback(() => {
        if (isWindowRef.current) {
            return {
                scrollTop: window.scrollY || window.pageYOffset,
                scrollLeft: window.scrollX || window.pageXOffset,
                scrollWidth: document.documentElement.scrollWidth,
                scrollHeight: document.documentElement.scrollHeight,
                clientWidth: window.innerWidth,
                clientHeight: window.innerHeight,
            };
        }

        const element = elementRef.current;
        if (!element) return null;

        return {
            scrollTop: element.scrollTop,
            scrollLeft: element.scrollLeft,
            scrollWidth: element.scrollWidth,
            scrollHeight: element.scrollHeight,
            clientWidth: element.clientWidth,
            clientHeight: element.clientHeight,
        };
    }, []); 

    const checkScroll = useCallback(() => {
        const values = getScrollValues();
        if (!values) return;

        const {
            scrollWidth,
            scrollHeight,
            clientWidth,
            clientHeight,
            ...scrollPosition
        } = values;

        const hasVertical = scrollHeight > clientHeight;
        const hasHorizontal = scrollWidth > clientWidth;

        setHasVerticalScroll(hasVertical);
        setHasHorizontalScroll(hasHorizontal);
        setScrollInfo({
            scrollWidth,
            scrollHeight,
            clientWidth,
            clientHeight,
            ...scrollPosition,
        });
    }, [getScrollValues]);

    useEffect(() => {
        let el;
        if (typeof scrollableTarget === "string") {
            el = document.getElementById(scrollableTarget);
        } else if (scrollableTarget?.current) {
            if (scrollableTarget.current.el) {
                if (scrollableTarget.current.getScrollableTarget()) {
                    el = scrollableTarget.current.getScrollableTarget();
                } else {
                    el = scrollableTarget.current.el;
                    isWindowRef.current = true;
                }
            } else {
                el = scrollableTarget.current;
            }
        }

        if (!el) return;

        elementRef.current = el;
        
        let resizeObserver;
        if (!isWindowRef.current) {
            resizeObserver = new ResizeObserver(checkScroll);
            resizeObserver.observe(el);
        }

        const target = isWindowRef.current ? window : el;
        target.addEventListener("scroll", checkScroll);

        if (isWindowRef.current) {
            window.addEventListener("resize", checkScroll);
        }

        checkScroll();

        return () => {
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
            target.removeEventListener("scroll", checkScroll);
            if (isWindowRef.current) {
                window.removeEventListener("resize", checkScroll);
            }
        };
    }, [scrollableTarget, checkScroll]);

    return {
        hasVerticalScroll,
        hasHorizontalScroll,
        scrollInfo,
    };
};

export default useScrollDetection;