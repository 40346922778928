import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { Input, Modal } from "antd"
import { useSelector } from "react-redux"
import { v4 as uuid } from "uuid"

import Button from "common/src/refactor/components/button/Button"
import NullForm from "common/src/components/NullForm"
import FormInput from "common/src/refactor/components/form/FormInput"
import Dropzone from "common/src/components/Dropzone"
import Dnd from "common/src/refactor/components/Dnd";
import ConsultationStatus from "common/src/refactor/components/consultation/Status"

import { ReactComponent as IconAdd } from "common/src/svg/plus.svg"
import { ReactComponent as IconDelete } from "common/src/svg/delete.svg"

import api from "app/api"
import user from "common/src/user"
import readInputFile from "common/src/lib/dom/readInputFile"
import { saveImages, saveSizes, loadPreviousSizes } from "common/src/actions/consultation"
import { Form, useFormFields } from "common/src/refactor/lib/form/Form"
import store from "app/store"
import { ui } from "common/src/store/dialogs"
import app from "app/appContext"
import useOnAppEvent from "common/src/refactor/hooks/useOnAppEvent"
import required from "common/src/refactor/lib/form/validator/required"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import useDictRef from "common/src/hooks/useDictRef";
import hub from "common/src/hub";
import findParent from "common/src/lib/dom/findParent";
import useConnections from "common/src/refactor/hooks/useConnections";

const MAX_IMAGES = 6;
const DIALOG_NAME = "consultation-dialog";
let editorResolve;

const fields = [
    {
        name: "title",
        default: "",
        validator: [
            [ required, "Please enter styling session's title" ]
        ]
    },
    {
        name: "description",
        default: ""
    },
    {
        name: "customerId",
        default: "",
    },
    {
        name: "hidden",
        default: false
    },
    {
        name: "status",
        default: null
    }
]


function Placeholder({ onAdd, index }) {
    if (index === 0) {
        return (
            <Dropzone
            className="consultation-form-dropzone-small"
            useInputField
            onChange={ onAdd }>
                <Button
                    size="small"
                    Icon={ IconAdd }
                    type="primary"
                    shape="circle"/>
            </Dropzone>
        )
    }
    
    return (
        <div className="consultation-form-dropzone-small"></div>
    )
}

function Image({ image, index, onRemove, onMove, moveable }) {

    const onRemoveClick = useSwallowEventCallback(
        () => onRemove(index),
        [ onRemove ]
    );

    const style = useMemo(
        () => ({
            backgroundImage: `url(${ image.src })`
        }),
        [ image ]
    );

    const { containerProps, imageAttrs } = useMemo(
        () => {
            const imageAttrs = { style };
            const containerProps = {};

            if (moveable) {
                containerProps.draggable = { data: index };
                containerProps.droppable = {
                    data: index,
                    cardSelector: ".consultation-form-image",
                    drop: onMove
                }
            }

            return { containerProps, imageAttrs };
        },
        [ moveable, style, onMove, index ]
    );

    return (
        <Dnd className="consultation-form-image" { ...containerProps }>
            <div { ...imageAttrs }/>
            { index === 0 && <span>Cover</span> }
            <a href="/#" onClick={ onRemoveClick }>
                <IconDelete/>
            </a>
        </Dnd>
    )
}

function Images({ images, onRemove, onMove, onAdd }) {

    const MAX = 6;
    const placeholders = useMemo(
        () => {
            return Array.from({ length: (MAX - images.length) });;
        },
        [ images ]
    )

    return (
        <div className="consultation-form-images-wrapper">
            <div className="consultation-form-image-list">
            { images.map((image, inx) => (
                <Image 
                    image={ image } 
                    key={ inx } 
                    index={ inx }
                    moveable={ images.length > 1 }
                    onMove={ onMove }
                    onRemove={ onRemove }/>
            ))}

            { images.length < MAX ?
             placeholders.map((_, i) => 
                <Placeholder onAdd={ onAdd } index={ i } key={ i } /> 
             )
             : null}
            </div>
            { images.length > 1 && <p className="consultation-form-image-tip">Drag images to re-order</p> }
        </div>
    )
}



function ConsultationForm({ consultation }) {

    const connections = useConnections();
    const form = useMemo(
        () => new Form(fields, {
            title: consultation?.title,
            description: consultation?.description,
            customerId: consultation?.customerId,
            hidden: consultation ? consultation.hidden : true,
            status: consultation?.status || "new"
        }), 
        [ consultation ]
    );
    //const [ page, setPage ] = useState("request");
    const [ sizes, setSizes ] = useState([]);
    const [ images, setImages ] = useState(consultation?.images || []);
    const [ saving, setSaving ] = useState(false);
    const [ removedImages, setRemovedImages ] = useState([]);
    const imagesRef = useRef({});
    const {
        title, titleChange, titleError,
        description, descriptionChange, descriptionError,
        customerId, 
        // customerIdChange,
        hidden, 
        // hiddenChange,
        status, statusChange
    } = useFormFields(["title", "description", "customerId", "hidden", "status"], form);

    const customer = useMemo(
        () => customerId ? connections.find(c => c.id === customerId) : null, 
        [ customerId, connections ]
    );

    const ref = useDictRef({ sizes, images, title, description, 
                            customer, removedImages, hidden, status })

    // const allSizes = useMemo(
    //     () => {
    //         if (!customerId) {
    //             return [];
    //         }
    //         return Object.keys(sizeSchemas).map(type => {
    //             return sizes.find(s => s.type === type) || {
    //                 type,
    //                 system: "uk",
    //                 value: "",
    //                 comment: ""
    //             }
    //         })
    //     },
    //     [ sizes, customerId ]
    // );

    const loadSizes = useCallback(
        async (clientId) => {
            const sizes = await loadPreviousSizes(clientId);
            setSizes(sizes);
        },
        []
    );

    const onSubmit = useCallback(
        async () => {
            const valid = await form.validateAll();
            if (!valid) {
                return;
            }
            setSaving(true);

            let id = consultation?.id;
            let editedCons;
            const { title, description, sizes, customer, images, hidden, status } = ref;

            if (id) {
                const updates = { title, description, hidden, status };
                editedCons = { ...consultation, ...updates, images, sizes };
                if (customer?.id) {
                    updates.customerId = customer.id;
                    editedCons.customerId = customer.id;
                    editedCons.customer = customer;
                }
                await api.consultation.update(id, updates);
            
                hub.dispatch("consultation", "created", { id });
            }
            else {
                const resp = await api.consultation.create({
                    indexNo: 0,
                    customerId: customer?.id ?? null,
                    friId: user.id(),
                    title,
                    description,
                    status: "new",
                    hidden: ref.hidden
                });
                id = resp.id;
                editedCons = resp;
                hub.dispatch("consultation", "updated", { id });
            }

            const imageFiles = images.map(image => {
                if (!image.id) {
                    return imagesRef.current[image.tmpid];
                }
                return null;
            });

            await saveImages(id, images, imageFiles);
            
            if (customer) {
                await saveSizes(id, customer.id, sizes);
            }

            for (let i = 0, l = ref.removedImages.length; i < l; i++) {
                await api.consultationImage.remove(ref.removedImages[i]);
            }

            setSaving(false);
            setRemovedImages([]);
            editorResolve && editorResolve(editedCons);
            ConsultationDialog.hide();
        },
        [ imagesRef, ref, form, consultation ]
    );
    
    // const onSizeChange = useCallback(
    //     (id, prop, value, sizeType) => {
    //         //console.log("on change", { id, prop, value })
    //         const sizes = [ ...ref.sizes];
    //         const inx = id ? sizes.findIndex(s => s.id === id || s.tmpid === id) : -1;

    //         if (value === undefined) {
    //             if (inx !== -1) {
    //                 sizes.splice(inx, 1);
    //                 setSizes(sizes);
    //             }
    //         }
    //         else if (inx === -1) {
    //             const newSize = { 
    //                 tmpid: uuid(),
    //                 type: sizeType,
    //                 system: "uk",
    //                 value: ""
    //             };
    //             newSize[prop] = value;
    //             sizes.push(newSize);
    //         }
    //         else {
    //             sizes[inx][prop] = value;
    //             if (prop === "system") {
    //                 sizes[inx].size = "";
    //             }
    //         }

    //         setSizes(sizes);
    //     },
    //     // eslint-disable-next-line
    //     []
    // );

    const onInputFileChange = useCallback(
        async (file) => {
            if (images.length < MAX_IMAGES) {
                const { mime, data } = await readInputFile(file);
                const images = [ ...ref.images ];
                const tmpid = uuid();
    
                images.push({
                    name: file.name,
                    mime,
                    data,
                    src: `data:${mime};base64,${data}`,
                    tmpid
                });
                setImages(images);
                imagesRef.current[tmpid] = file;
            }
        },
        // eslint-disable-next-line
        []
    );


    const onRemoveImage = useCallback(
        (inx) => {
            const images = [...ref.images];
            if (inx !== -1) {
                const img = images[inx];

                if (img.id) {
                    const removed = [ ...ref.removedImages ];
                    removed.push(img.id);
                    setRemovedImages(removed);
                }
                
                images.splice(inx, 1);
                setImages(images);

                if (img.tmpid) {
                    delete imagesRef.current[img.tmpid];
                }
            }
        },
        // eslint-disable-next-line
        [ ref ]
    );

    const onImageMove = useCallback(
        (toIndex, fromIndex) => {
            const images = [ ...ref.images ];
            const srcImg = images[fromIndex];
            if (fromIndex > toIndex) {
                images.splice(fromIndex, 1);
                images.splice(toIndex, 0, srcImg);
            }
            else if (toIndex > fromIndex) {
                images.splice(fromIndex, 1);
                images.splice(toIndex, 0, srcImg);
            }
            setImages(images);
        },
        // eslint-disable-next-line
        []
    );

    const onStatusClick = useCallback(
        (e) => {
            const el = findParent(e.target, ".consultation-form-status");
            statusChange(el.dataset.status);
        },
        [ statusChange ]
    );


    useEffect(
        () => {
            setSizes([]);
            customer && loadSizes(customer.id);
        },
        // eslint-disable-next-line
        [ customer ]
    )

    return (
        <NullForm className="consultation-form">

            <div className="modal-header">
                <h2>{ consultation?.id ? "Edit styling session" : "Create styling session" }</h2>
                { consultation?.id ? 
                    <div className="consultation-form-statuses">
                        <ConsultationStatus 
                            status="new"
                            className="consultation-form-status"
                            data-status="new"
                            onClick={ onStatusClick }
                            variant={ status === "new" ? "active" : "" }/>
                        <ConsultationStatus 
                            status="inprogress"
                            className="consultation-form-status"
                            data-status="inprogress"
                            onClick={ onStatusClick }
                            variant={ status === "inprogress" ? "active" : "" }/>
                        <ConsultationStatus 
                            status="completed"
                            className="consultation-form-status"
                            data-status="completed"
                            onClick={ onStatusClick }
                            variant={ status === "completed" ? "active" : "" }/>
                    </div> : null }
            </div>
                
            <div className="consultation-form-fields">
                <FormInput
                    error={ titleError }
                    label="Name this styling session">
                    <Input
                        size="large"
                        placeholder="'Example: Wedding guest looks'"
                        value={ title }
                        onChange={ titleChange }
                        onPressEnter={ onSubmit }/>
                </FormInput>
                <FormInput
                    error={ descriptionError }
                    label="Description">
                    <Input.TextArea
                        size="large"
                        placeholder="Tell your client a little more about the styling session you've created for them"
                        value={ description }
                        onChange={ descriptionChange }/>
                </FormInput>
            </div>
                
            <div className="consultation-form-images">
                <FormInput 
                    label="Images (optional)">
                    <p className="consultation-form-images-sub-label">Add up to 6 inspiration images to be the cover of your styling session</p>
                    <Images 
                        images={ images } 
                        onMove={ onImageMove }
                        onRemove={ onRemoveImage }
                        onAdd={ onInputFileChange }/>
                </FormInput>
            </div>

            <div className="consultation-form-footer">
                <Button 
                    type="primary"
                    loading={ saving }
                    disabled={ saving }
                    onClick={ onSubmit }
                    text={ consultation?.id ? "Save styling session" : "Create styling session" }/>
            </div>
        </NullForm>
    )

}

function ConsultationDialog() {

    const [ options, setOptions ] = useState({});
    const open = useSelector(s => s.dialogs[DIALOG_NAME]);
    const onCancel = useCallback(
        () => {
            editorResolve && editorResolve(null);
            ConsultationDialog.hide();
        },
        []
    );

    useOnAppEvent("app/consultation-dialog/options", setOptions);

    return (
        <Modal open={ open } 
            classNames={{ wrapper: "consultation-dialog" }}
            centered    
            closeIcon={ null }
            footer={ null }
            closable
            destroyOnClose 
            onCancel={ onCancel }>
            { open && <ConsultationForm { ...options }/> }
        </Modal>
    )
}


ConsultationDialog.show = function(options) {
    app.trigger("app/consultation-dialog/options", options);
    store.dispatch(ui.show(DIALOG_NAME));
    return new Promise(resolve => {
        editorResolve = resolve;
    });
}

ConsultationDialog.hide = function() {
    app.trigger("app/consultation-dialog/options", null);
    store.dispatch(ui.hide(DIALOG_NAME));
    editorResolve = null;
}

export default ConsultationDialog