
import hub from "common/src/hub"
import { startOnlineState, stopOnlineState } from "common/src/actions/online"

function onWindowFocus() {
    startOnlineState();
}

function onWindowBlur() {
    stopOnlineState();
}

function onAuthStateChange(state) {
    if (state) {
        window.addEventListener("focus", onWindowFocus);
        window.addEventListener("blur", onWindowBlur);
        if (document.hasFocus()) {
            onWindowFocus();
        }
    }
    else {
        window.removeEventListener("focus", onWindowFocus);
        window.removeEventListener("blur", onWindowBlur);
    }
}

const listeners = {
    init: () => {
        hub.listen("app-auth", "stateChange", onAuthStateChange);
    }
}

export default listeners;