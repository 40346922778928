import { useSelector } from "react-redux"
import { useCallback, useMemo, useState } from "react"
import { Drawer, Input, App as AntApp } from "antd"
import Auth from "@aws-amplify/auth"

import Button from "../button/Button"
import FormInput from "common/src/refactor/components/form/FormInput";
import NullForm from "common/src/components/NullForm"

import { ui } from "common/src/store/dialogs"
import store from "app/store"
import { Form, useFormFields } from "common/src/refactor/lib/form/Form"
import required from "common/src/refactor/lib/form/validator/required"
import validatePassword from "common/src/refactor/lib/form/validator/password"
import useDictRef from "common/src/hooks/useDictRef";

const DIALOG_NAME = "change-pasxsword";

function validatePasswordRepeat(value, _, values) {
    return values.newp === value;
}

const fields = [
    {
        name: "oldp",
        default: "",
        validator: [
            [ required, "Please enter your old password" ]
        ]
    },
    {
        name: "newp",
        default: "",
        validator: [
            [ required, "Please enter your new password" ],
            [ validatePassword, "Password is not secure enough" ]
        ]
    },
    {
        name: "repeatp",
        default: "",
        validator: [
            [ required, "Please repeat your new password" ],
            [ validatePasswordRepeat, "Passwords don't match" ]
        ]
    }
]

function ChangePasswordForm() {

    const form = useMemo(() => new Form(fields), []);
    const [ saving, setSaving ] = useState(false);
    const { message } = AntApp.useApp();
    const {
        oldp, oldpChange, oldpError, oldpSetError,
        newp, newpChange, newpError,
        repeatp, repeatpChange, repeatpError
    } = useFormFields(["oldp", "newp", "repeatp"], form);

    const ref = useDictRef({ oldp, newp });

    const onSubmitClick = useCallback(
        async () => {
            setSaving(true);
            const valid = await form.validateAll();

            if (valid) {
                await Auth.currentAuthenticatedUser()
                    .then(user => Auth.changePassword(user, ref.oldp, ref.newp))
                    .then(() => {
                        ChangePasswordDialog.hide();
                        message.success({
                            content: "Your password was successfully changed",
                            icon: <></>
                        })
                    })
                    .catch(err => {
                        if (err.message === "Incorrect username or password.") {
                            oldpSetError("Old password doesn't match");
                        }
                        else {
                            oldpSetError(err.message);
                        }
                    });
            }

            setSaving(false);
        },
        [ form ]
    );

    return (
        <NullForm className="change-password-form">
            <FormInput error={ oldpError } label="Old password *">
                <Input.Password 
                    disabled={ saving }
                    value={ oldp } 
                    onChange={ oldpChange }
                    onPressEnter={ onSubmitClick }
                    autoComplete="one-time-password"/>
            </FormInput>
            <FormInput error={ newpError } label="New password *">
                <Input.Password 
                    disabled={ saving }
                    value={ newp } 
                    onChange={ newpChange }
                    onPressEnter={ onSubmitClick }
                    autoComplete="one-time-password"/>
            </FormInput>
            <FormInput error={ repeatpError } label="Repeat password *">
                <Input.Password 
                    disabled={ saving }
                    value={ repeatp } 
                    onChange={ repeatpChange }
                    onPressEnter={ onSubmitClick }
                    autoComplete="one-time-password"/>
            </FormInput>

            <Button 
                disabled={ saving }
                loading={ saving }
                type="primary" 
                text="Change password"
                onClick={ onSubmitClick }/>
        </NullForm>
    )
}

function ChangePasswordDialog() {

    const open = useSelector(s => s.dialogs[DIALOG_NAME]);

    const onClose = useCallback(
        () => { ChangePasswordDialog.hide() },
        []
    );

    return (
        <Drawer 
            className="tf-drawer change-password-drawer"
            title="Change password"
            open={ open } 
            onClose={ onClose }
            destroyOnClose>
            { open && <ChangePasswordForm/> }
        </Drawer>
    )
}

ChangePasswordDialog.show = function() {
    store.dispatch(ui.show(DIALOG_NAME));
}

ChangePasswordDialog.hide = function() {
    store.dispatch(ui.hide(DIALOG_NAME));
}

export default ChangePasswordDialog