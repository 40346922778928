import { useMemo } from "react"
import { Spin } from "antd"
import useHtmlClass from "common/src/hooks/useHtmlClass"

function Page({ children, className, loading=false, 
                htmlClassName, passthrough = false,
                ...rest }) {

    useHtmlClass(htmlClassName);

    const cls = useMemo(
        () => [ "page", className || "" ].join(" "),
        [ className ]
    );

    if (passthrough) {
        return children;
    }

    return (
        <div className={ cls } { ...rest }>
            { children }
            { loading && <Spin spinning className="page-spinner"/> }
        </div>
    )
}

export default Page