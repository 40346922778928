import createActionMap, { map2builder } from "common/src/lib/store/createActionMap.js";
import { createReducer } from "@reduxjs/toolkit"
//import getQueryParam from "common/src/lib/url/getQueryParam"

let initialState = {
    loggedIn: null,
    current: null,
    groups: [],
    id: null,
    pushToken: null,
    iid: null,//getQueryParam("iid"),
    session: {},
    geo: {
        country: null
    },

    ui: {
        checking: false,
        saving: false,
        loading: false,
        removingAvatar: false,
        form: null
    }
}


let data = createActionMap("user/", {
    "loggedIn": ".",
    "pushToken": ".",
    "id": ".",
    "iid": ".",
    "session": ".",
    "current/": ["set", "reset", "update"],
    "groups/": ["set"],
    "geo/": ["set", "update"]
});


let ui = createActionMap("user/ui/", {
    "saving": ".",
    "loading": ".",
    "checking": ".",
    "removingAvatar": ".",
    "form": "."
});


let reducer = createReducer(initialState, (builder) => {
    map2builder(builder, {
        [data.loggedIn]: (state, action) => {
            state.loggedIn = action.payload;
        },
        [data.id]: (state, action) => {
            state.id = action.payload;
        },
        [data.iid]: (state, action) => {
            state.iid = action.payload;
        },
        [data.session]: (state, action) => {
            state.session = Object.assign(state.session, action.payload);
        },
    
        [data.groups.set]: (state, action) => {
            state.groups = action.payload;
        },
    
        [data.current.set]: (state, action) => {
            state.current = action.payload;
        },
        [data.current.update]: (state, action) => {
            state.current = Object.assign({}, state.current, action.payload);
        },
        [data.current.reset]: (state, action) => {
            state.current = null;
        },
    
        [data.geo.set]: (state, action) => {
            state.geo = { ...action.payload };
        },
        [data.geo.update]: (state, action) => {
            state.geo = { ...state.geo, ...action.payload };
        },
    
        [ui.loading]: (state, action) => {
            state.ui.loading = action.payload;
        },
        [ui.saving]: (state, action) => {
            state.ui.saving = action.payload;
        },
        [ui.checking]: (state, action) => {
            state.ui.checking = action.payload;
        },
        [ui.removingAvatar]: (state, action) => {
            state.ui.removingAvatar = action.payload;
        },
        [ui.form]: (state, action) => {
            state.ui.form = action.payload;
        }
    })
});

function reset(store) {
    store.dispatch(data.current.reset());
}

export { reducer, data, ui, initialState, reset }
