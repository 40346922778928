import StoreModule from "common/src/lib/store/StoreModule"

const catalogue = new StoreModule("catalogue");

catalogue.addEntity("colors", []);
catalogue.addEntity("materials", []);
catalogue.addEntity("characteristics", []);
catalogue.addEntity("categories", []);
catalogue.addList("products", [], {}, "uid");
catalogue.addEntity("brands", []);
catalogue.addEntity("retailers", []);
catalogue.addEntity("prices", []);
catalogue.addEntity("commissionRates", {});
catalogue.addSimpleEntity("facetsLoaded", false);

export const { reducer, data, ui, sets, initialState } = catalogue.export();
