import useStyle from "common/src/refactor/hooks/useStyle"
import Loader from "common/src/components/Loader";
import { useCallback } from "react";

const baseClass = "input-radio";
const stylePostfixes = [ "tick-container", "tick", "label" ];

const loaderSizes = {
    "medium": 20,
    "small": 16
}

function Radio({ checked, onChange, 
                size = "medium", variant, 
                loading = false, disabled = false,
                className, style,
                label,
                ...rest }) {

    const styles = useStyle(
        {
            className: [ baseClass, className ],
            children: stylePostfixes,
            variants: [
                variant,
                size,
                checked === null ? 
                    "uncertain" :
                    checked === true ?
                        "checked" : 
                        "unchecked",
                disabled ? "disabled" : null,
                loading ? "loading" : null,
                !!label ? "with-label" : "no-label"
            ],
            extend: {
                [baseClass]: style
            }
        }, 
        [ checked, loading, disabled, size, variant, className, style, label ]
    );

    const onClick = useCallback(
        () => onChange && onChange(!checked),
        [ onChange, checked ]
    );

    return (
        <button 
            { ...styles.main } 
            onClick={ onClick } 
            disabled={ disabled }
            { ...rest }>
            <span { ...styles.tickContainer }>
                { loading ? 
                    <Loader size={ loaderSizes[size] }/> :
                    <span { ...styles.tick }></span> }
            </span>
            { label && 
                <span { ...styles.label }>{ label }</span> }
        </button>
    )
}

export default Radio