import { ReactComponent as IconBasket } from "common/src/svg/basket-active.svg"
import { ReactComponent as IconLike } from "common/src/svg/heart-active.svg"

function ProductCardReaction({ product }) {

    return (
        <div className="look-card-product-reaction">
            { product.reaction === "like" ?
                    <IconLike/> :
                    product.reaction === "shop" ?
                        <IconBasket/> :
                        null}
        </div>
    )
}

export default ProductCardReaction