
import useStyle from "common/src/refactor/hooks/useStyle"

const baseClass = "empty-message";
const stylePostfixes = ["message", "actions"];

function EmptyMessage({ message, children, before, after,
                        className, style,
                        cta, size }) {

    const styles = useStyle(
        {
            className: [ baseClass, className ],
            children: stylePostfixes,
            variants: [
                size
            ],
            extend: {
                [baseClass]: style,
                [className]: style
            }
        },
        [ size, className, style ]
    );

    return (
        <div { ...styles.main }>
            { before }
            { message && <div { ...styles.message }>{ message }</div> }
            { children }
            { cta && <div { ...styles.cta }>{ cta }</div> }
            { after }
        </div>
    )
}

export default EmptyMessage