import StoreModule from "common/src/lib/store/StoreModule"

const notifications = new StoreModule("notifications.consultation");

notifications.state.ui.newConsultationsCount = 0;
notifications.state.ui.delConsultationsCount = 0;
notifications.state.ui.consultationsCount = 0;

notifications.ui.add("setNewConsultationsCount", (s, a) => { 
    s.ui.newConsultationsCount = a.payload;
});
notifications.ui.add("setDelConsultationsCount", (s, a) => { 
    s.ui.delConsultationsCount = a.payload;
});
notifications.ui.add("setConsultationsCount", (s, a) => { 
    s.ui.consultationsCount = a.payload;
});



export let { reducer, data, ui, initialState } = notifications.export();