import { useCallback, useContext, useMemo } from "react"
import Linkify from "linkify-react"
import user from "common/src/user"
import getClickDomain from "common/src/lib/url/clickDomain"
import ChatContext from "common/src/lib/chat/ChatContext"
import isEmail from "validator/es/lib/isEmail"

const SHORT_ID_REG = /\/s\/([^/]{10})/;

function ExternalLink({ attributes, content, messageId }) {

    const chat = useContext(ChatContext);

    const [ unboundHref, boundHref ] = useMemo(
        () => {
            let href = attributes.href;

            if (href.indexOf("mailto") === 0) {
                return [ href, href ];
            }

            let unboundHref = href, boundHref = href;
            const contactRole = chat.contactRole;
            const message = chat.getMessage(messageId);
            let referenceUserId = null;
            if (message.userId !== user.id()) {
                if (contactRole === "shopper") {
                    referenceUserId = message.userId;
                }
            }
            else {
                if (!user.isOnly("User")) {
                    referenceUserId = user.id();
                }
            }

            // trackable link case
            const url = new URL(href);
            if (url.hostname.toLowerCase().indexOf("thefloorr") !== -1) {
                if (url.pathname.match(SHORT_ID_REG)) {
                    unboundHref = url.toString();
                    url.searchParams.append("uid", user.id());
                    boundHref = url.toString();
                }
            }
            else {
                const clickDomain = getClickDomain();
                const clickUrl = new URL(`https://${ clickDomain }`);
                if (referenceUserId) {
                    clickUrl.searchParams.append("refid", referenceUserId);
                }
                clickUrl.searchParams.append("rurl", href);
                unboundHref = clickUrl.toString();

                clickUrl.searchParams.append("uid", user.id());

                boundHref = clickUrl.toString();
            }

            return [ unboundHref, boundHref ];
        },
        [ attributes?.href, messageId ]
    );

    const onClick = useCallback(
        (e) => {
            e.preventDefault();
            window.open(boundHref, "_blank");
        },
        [ boundHref ]
    );

    return (
        <a href={ unboundHref } onClick={ onClick }>{ content }</a>
    )

}


function MessageText({ text, messageId, hasAttachments }) {

    const renderLink = useCallback(
        ({ attributes, content }) => {
            if (content.length > 50) {
                content = content.substring(0, 50) + "...";
            }
            return (
                <ExternalLink 
                    attributes={ attributes } 
                    content={ content }
                    messageId={ messageId }/>
            )
        },
        [ messageId ]
    )

    const content = useMemo(
        () => {
            return (
                <Linkify 
                    children={ text }
                    options={{ 
                        render: renderLink, 
                        defaultProtocol: "https",
                        nl2br: true
                    }}/>
            )
        },
        [ text, renderLink ]
    );

    return (
        <p className={`chat-message-text ${!hasAttachments ? "plain-text" : ""}`}>{ content }</p>
    )
}

export default MessageText