import { useCallback } from "react"
import { useNavigate } from "react-router-dom"

import Loader from "common/src/components/Loader"

import useStyle from "common/src/refactor/hooks/useStyle"
import { useMemo } from "react";

const baseClass = "button";
const stylePostfixes = [ "icon", "icon-svg", "text" ];

const loaderSizes = {
    "small": 12,
    "medium": 16,
    "large": 24
};

function Button({ text, 
                    Icon, 
                    iconUrl,
                    type = "primary", 
                    size = "large", 
                    variant, 
                    justify = "center",
                    shape = "default-shape",
                    iconPosition = "after",
                    loading = false, 
                    disabled = false,
                    className, style,
                    before, 
                    after, 
                    onClick, 
                    Component = "button",
                    children,
                    ...rest }) {

    const navigate = useNavigate();
    const styles = useStyle({
        className: [ baseClass, className ],
        children: stylePostfixes,
        variants: [
            type,
            size,
            shape,
            justify,
            !text ? "icon-only" : null,
            (!Icon && !iconUrl) ? "text-only" : null,
            !!text && (!!Icon || !!iconUrl) ? "icon-w-text" : null,
            iconUrl ? "icon-url" : null,
            loading ? "loading" : null,
            disabled ? "disabled" : null,
            variant,
        ],
        extend: {
            [baseClass]: style
        }
    },
    [ type, size, variant, loading, disabled, className, style, text, Icon, iconUrl ]);

    const localOnClick = useCallback(
        (e) => {
            if (rest.href && !e.isDefaultPrevented()) {
                e.preventDefault();
                if (rest.href.toLowerCase().indexOf("http") === 0) {
                    window.open(rest.href);
                }
                else {
                    navigate(rest.href);
                }
            }
            onClick && onClick(e);
        },
        [ onClick, rest.href ]
    );

    const iconComponent = useMemo(
        () => {
            if (!Icon && !iconUrl && !loading) {
                return null;
            }
            const iconStyle = {
                className: styles.icon.className,
                style: { ...styles.icon.style }
            };
            if (iconUrl) {
                iconStyle.style.backgroundImage = `url(${ iconUrl })`;
            }
            return (
                <span { ...iconStyle }>
                    { loading ? 
                        <Loader size={ loaderSizes[size] }/> :
                        Icon ? 
                            <Icon { ...styles.iconSvg }/> :
                            null }
                </span>
            )
        },
        [ Icon, iconUrl, loading, styles.iconSvg, styles.icon, size ]
    );

    return (
        <Component 
            { ...styles.main } 
            onClick={ localOnClick } 
            disabled={ disabled }
            { ...rest }>
            { before }
            { iconPosition === "before" ? iconComponent : null }
            { (text || children) && <span { ...styles.text }>{ text || children }</span> }
            { iconPosition === "after" ? iconComponent : null }
            { after }
        </Component>
    )
}

export default Button