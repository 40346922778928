import { useState, useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { sets } from "common/src/store/catalogue"
import { catalogueLoader, getProductsBySet } from "common/src/actions/catalogue"
import useDictRef from "common/src/hooks/useDictRef"
import useUpdateEffect from "common/src/hooks/useUpdateEffect"
import useHub from "common/src/hooks/useHub"
import extractCatalogueId from "common/src/lambdalib/extractCatalogueId"

const defaultFilters = {
    // stage: "live",
    currency: "USD,GBP,EUR",
};

function useProductCatalogue({ filters, setName, clearOnUnmount = true, perPage = 36 }) {

    const dispatch = useDispatch();
    const puids = useSelector(s => s.catalogue.sets.products[setName]);
    const geo = useSelector(s => s.user.geo || {});
    const currency = geo.currency;
    const originalCurrency = geo.original || false;
    const { loading = true,
        loaded = false,
        page = 0,
        hasMore } = useSelector(s => s.catalogue.ui.products.misc[setName] || {});
    const [products, setProducts] = useState(() => getProductsBySet(setName));
    const [productWebId, setProductWebId] = useState(null);

    const ref = useDictRef({
        clearOnUnmount, setName,
        filters, page, perPage,
        originalCurrency, currency,
        productWebId
    });

    //const { hasResettingChanges, hasChanges } = useProductFilters(filters);

    const load = useCallback(
        () => {
            const cat = extractCatalogueId(filters?.query || "");

            if (cat?.catalogueId) {
                const { query, ...filters } = ref.filters;
                catalogueLoader({
                    ...defaultFilters,
                    displayCurrency: ref.originalCurrency ? undefined : ref.currency.toUpperCase(),
                    ...filters,
                    append: false,
                    page: 0,
                    perPage: ref.perPage,
                    setName: ref.setName,
                    product_web_id: cat.catalogueId
                });
            }
            else {
                catalogueLoader({
                    ...defaultFilters,
                    displayCurrency: ref.originalCurrency ? undefined : ref.currency.toUpperCase(),
                    ...ref.filters,
                    append: false,
                    page: 0,
                    perPage: ref.perPage,
                    setName: ref.setName
                });
            }

            setProductWebId(cat?.catalogueId || null);

        },

        [ref, productWebId, filters]
    );

    useHub("catalogue-loader", "productWebId-set", load);

    const reload = useCallback(
        () => {
            load();
        },
        []
    );

    const loadMore = useCallback(
        () => {
            if (ref.productWebId) {
                catalogueLoader({
                    ...defaultFilters,
                    displayCurrency: ref.originalCurrency ? undefined : ref.currency.toUpperCase(),
                    ...ref.filters,
                    append: true,
                    page: ref.page + 1,
                    perPage,
                    setName,
                    product_web_id: ref.productWebId
                });
            } else {
                catalogueLoader({
                    ...defaultFilters,
                    displayCurrency: ref.originalCurrency ? undefined : ref.currency.toUpperCase(),
                    ...ref.filters,
                    append: true,
                    page: ref.page + 1,
                    perPage,
                    setName,
                });
            }
        },
        [ref.productWebId]
    );

    const loadPage = useCallback(
        (page) => {
            catalogueLoader({
                ...ref.filters,
                append: false,
                page,
                perPage,
                setName
            });
        },
        []
    );

    useUpdateEffect(
        () => setProducts(getProductsBySet(ref.setName)),
        [puids]
    );

    useUpdateEffect(
        () => {
            load();
        },
        [filters, currency, originalCurrency]
    );

    useEffect(
        () => {
            load();
            return () => {
                if (ref.clearOnUnmount) {
                    dispatch(sets.products.unset(ref.setName));
                }
            }
        },
        []
    );


    return { loading, products, reload, loadMore, loadPage, page, hasMore, loaded };
}

export default useProductCatalogue