
const settings = {
    "app": "tools",
    "allowGroups": ["Admin", "FRI", "GPS", "Contributor"],
    "consultationsPerPage": 300,
    "looksPerPage": 20,
    "peoplePerPage": 20,
    "tagsPerPage": 20,
    "appStoreUrl": "https://apps.apple.com/us/app/the-floorr-stylist/id6451112463"
}

export default settings