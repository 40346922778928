import StoreModule from "common/src/lib/store/StoreModule"

const ntfs = new StoreModule("notifications");

ntfs.addList("list", []);

ntfs.state.ui.enable = false;
ntfs.state.ui.unreadCount = {
    total: 0,
    chat: 0,
    general: 0
};

ntfs.data.children.list.add("setRead", (s, a) => {
    let { type, id, read } = a.payload;
    if (typeof id === "string") {
        id = [ id ];
    }
    s.data.list.forEach(item => {
        if (type === "chat-message-notification" && 
            id.indexOf(item.chatMessageNotificationId) !== -1) {
            item.chatMessageNotification.read = read;
        }
        else if (type === "user-notification" &&
            id.indexOf(item.userNotificationId) !== -1) {
            item.userNotification.read = read;
        }
    })
})
ntfs.ui.add("setUnreadCount", (s, a) => { 
    s.ui.unreadCount = a.payload;
});
ntfs.ui.add("setEnabled", (s, a) => { 
    s.ui.enable = a.payload;
});


const { reducer, data, ui, initialState } = ntfs.export();

function reset(store) {
    store.dispatch(data.list.reset());
}

export { reducer, data, ui, initialState, reset };