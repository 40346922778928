import CommonHeader from "common/src/refactor/components/Header"
import useIsMobile from "common/src/refactor/hooks/useIsMobile"

function Header() {
    const isMobile = useIsMobile();

    if (isMobile) {
        return null
    }

    return (
        <CommonHeader/>
    )
}

export default Header