
export default (where) => {
    let key, value;

    for (key in where) {
        value = where[key];
        if (typeof value === "string" || 
            typeof value === "number" ||
            typeof value === "boolean") {

            where[key] = { _eq: value };
        }
    }

    return where;
};