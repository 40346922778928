
import StoreModule from "common/src/lib/store/StoreModule"

const conn = new StoreModule("connections");
conn.addList("connections");

const { reducer, data, ui, initialState } = conn.export();


function reset(store) {
    store.dispatch(data.connections.reset());
}


export { reducer, data, ui, initialState, reset };