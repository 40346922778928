
import hub from "common/src/hub"
import store from "app/store"
import * as actions from "app/actions/page/consultations"
import async from "common/src/lib/js/async"

let skipCountChange = false;

function onConsUpdated(consId) {
    skipCountChange = true;
    let state = store.getState();
    if (state.consultationsPage.data.list.find(c => c.id === consId)) {
        actions.reloadList(true);
    }

    async(() => skipCountChange = false);
}

function onConsCreated() {
    skipCountChange = true;
    actions.reloadList(true);

    async(() => skipCountChange = false);
}

function onNewConsCountChange() {
    if (!skipCountChange) {
        async(() => {
            const state = store.getState();
            if (state.consultationsPage.ui.list.status === "new") {
                actions.reloadList(true);
            }
        })
    }
}

function onLooksChange() {
    actions.reloadList(true);
}

const listeners = {
    init: function() {
        hub.listen("consultation", "created", onConsCreated);
        hub.listen("consultation", "updated", onConsUpdated);
        hub.listen("consultations", "new-count-change", onNewConsCountChange);
        
        hub.listen("look", "created", onLooksChange);
        hub.listen("look", "updated", onLooksChange);
        hub.listen("look", "deleted", onLooksChange);
    }
}

export default listeners