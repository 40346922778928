import { batch } from "react-redux"
import createActionMap, { map2builder } from "common/src/lib/store/createActionMap.js";
import { createReducer } from "@reduxjs/toolkit"
import * as listManager from "common/src/lib/store/listManager"
import hub from "common/src/hub"
import { v4 as uuidv4 } from "uuid"

let productScheme = {
    id: null,
    url: null,
    name: "",
    description: "",
    keywords: "",
    image: "",
    images: [],
    originalImages: [],
    productStyles: [],
    designers: [],
    price: null,
    salePrice: 0,
    currency: null,
    type: "",
    position: 0,
    createdAt: null,
    retailer: null,
    catalogueId: null,
    sizes: null,
    availability: null
};

let productExtra = {
    scraped: {},
    imageData: null,
    deletedProductStyles: [],
    deletedDesigners: []
}

let initialState = {

    product: { ...productScheme, ...productExtra },

    ui: {
        hasChanges: false,
        scraper: {
            loading: false,
            error: null
        },
        deleting: []
    }
}

let data = createActionMap("productEditor/product/", {
    start: ".",  // start new product
    reset: ".",  // reset current edited
    update: ".", // update current edited
    edit: ".", // make existing product editable

    "scraped/": ["set", "update"],
    "productStyles/": ["set", "push", "remove"],
    "designers/": ["set", "push", "remove"],
    "image/": {
        //"id/": ["set"],
        "data/": ["set"]
    },
    "images/": {
        "set": ".",
        "add": "."
    }
});


let ui = createActionMap("productEditor/ui/", {
    "scraper/": {
        running: ".",
        error: "."
    },
    "hasChanges": ".",
    "deleting/": ["start", "stop"]
});


export function fillProduct(p) {
    if (!p.id) {
        p.id = "tmp_product_" + uuidv4();
    }
    if (!p.createdAt) {
        p.createdAt = (new Date()).toISOString();
    }
    for (let k in productScheme) {
        if (p[k] === undefined) {
            p[k] = productScheme[k];
        }
    }
    if (p.designers) {
        p.designers = p.designers.map(d => {
            return typeof d === "string" ? { name: d } : d;
        });
    }
    else {
        p.designers = [];
    }

    return p;
}


let reducer = createReducer(initialState, (builder) => {
    map2builder(builder, {
        // new product
        [data.start]: state => {
            if (!state.product.id) {
                state.product.id = "tmp_product_" + uuidv4();
                state.product.createdAt = (new Date()).toISOString();
            }
        },
        [data.reset]: () => {
            return initialState
        },
        [data.update]: (state, action) => {
            batch(() => {
                Object.assign(state.product, action.payload);
                state.ui.hasChanges = true;
            });
            hub.dispatch("look-editor", "save-draft");
        },
        [data.edit]: (state, action) => {
            state.product = action.payload;
        },
    
        [data.scraped.set]: (state, action) => {
            state.product.scraped = action.payload;
        },
        [data.scraped.update]: (state, action) => {
            batch(() => {
                Object.assign(state.product.scraped, action.payload);
                state.ui.hasChanges = true;
            });
        },
    
        [data.images.set]: (s, a) => {
            batch(() => {
                s.product.images = a.payload;
                s.ui.hasChanges = true;
            });
            hub.dispatch("look-editor", "save-draft");
        },
        [data.images.add]: (s, a) => {
            batch(() => {
                s.product.images = s.product.images.concat(a.payload);
                s.ui.hasChanges = true;
            });
            hub.dispatch("look-editor", "save-draft");
        },
    
        // product tags
        [data.productStyles.set]: (state, action) => {
            let p = state.product;
            batch(() => {
                !p.productStyles && (p.productStyles = []);
                !p.deletedProductStyles && (p.deletedProductStyles = []);
                listManager.set(p.productStyles, action.payload, p.deletedProductStyles);
                state.ui.hasChanges = true;
            });
            hub.dispatch("look-editor", "save-draft");
        },
        [data.productStyles.push]: (state, action) => {
            batch(() => {
                !state.product.productStyles && (state.product.productStyles = []);
                listManager.push(state.product.productStyles, action.payload);
                state.ui.hasChanges = true;
            })
            hub.dispatch("look-editor", "save-draft");
        },
        [data.productStyles.remove]: (state, action) => {
            let p = state.product;
            batch(() => {
                !p.productStyles && (p.productStyles = []);
                !p.deletedProductStyles && (p.deletedProductStyles = []);
                listManager.remove(p.productStyles, action.payload, p.deletedProductStyles);
                state.ui.hasChanges = true;
            })
            hub.dispatch("look-editor", "save-draft");
        },
    
    
        [data.designers.set]: (state, action) => {
            let p = state.product;
            batch(() => {
                !p.designers && (p.designers = []);
                !p.deletedDesigners && (p.deletedDesigners = []);
                listManager.set(p.designers, action.payload, p.deletedDesigners);
                state.ui.hasChanges = true;
            })
            hub.dispatch("look-editor", "save-draft");
        },
        [data.designers.push]: (state, action) => {
            batch(() => {
                !state.product.designers && (state.product.designers = []);
                listManager.push(state.product.designers, action.payload);
                state.ui.hasChanges = true;
            })
            hub.dispatch("look-editor", "save-draft");
        },
        [data.designers.remove]: (state, action) => {
            let p = state.product;
            batch(() => {
                !p.designers && (p.designers = []);
                !p.deletedDesigners && (p.deletedDesigners = []);
                listManager.remove(p.designers, action.payload, p.deletedDesigners);
                state.ui.hasChanges = true;
            })
            hub.dispatch("look-editor", "save-draft");
        },
    
    
    
        //[data.image.id.set]: (state, action) => {
        //    state.product.imageInputId = action.payload;
        //},
        [data.image.data.set]: (state, action) => {
            state.product.imageData = action.payload;
            //hub.dispatch("look-editor", "save-draft");
        },
    
        // scraper
        [ui.scraper.running]: (state, action) => {
            state.ui.scraper.running = action.payload;
        },
        [ui.scraper.error]: (state, action) => {
            state.ui.scraper.error = action.payload;
        },
    
        [ui.deleting.start]: (state, action) => {
            listManager.push(state.ui.deleting, action.payload);
        },
        [ui.deleting.stop]: (state, action) => {
            listManager.remove(state.ui.deleting, action.payload);
        },
    
        [ui.hasChanges]: (state, action) => {
           state.ui.hasChanges = action.payload;
        }
    })
});

export { reducer, data, ui, initialState, productScheme, productExtra }
