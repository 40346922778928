
module.exports = function(clickUrl) {

    if (!clickUrl) {
        console.error("missing product url");
        return "";
    }

    if (clickUrl.indexOf("destination:http") !== -1) {
        const match = clickUrl.match(/destination:(.+)/);
        if (match) {
            let durl = match[1];
            if (durl.indexOf("https%3A%2F%2F") === 0) {
                durl = decodeURIComponent(durl);
            }
            else if (durl.indexOf("http%3A%2F%2F") === 0) {
                durl = decodeURIComponent(durl);
            }
            return durl;
        }
    }
    if (clickUrl.indexOf('&murl=') !== -1) {
        const url = new URL(clickUrl);
        const murl = url.searchParams.get("murl");

        if (murl.indexOf('&destinationUrl=') !== -1) {
            const durl = new URL(murl);
            return durl.searchParams.get("destinationUrl");
        }
        if (murl.indexOf('&url=') !== -1) {
            const durl = new URL(murl);
            return durl.searchParams.get("url");
        }

        return murl;
    }
    if (clickUrl.indexOf("intermix.") !== -1) {
        const url = new URL(clickUrl);
        return url.searchParams.get("u");
    }
    if (clickUrl.match(/\/click-\d+-\d+\?/) || clickUrl.indexOf(".thefloorr.com") !== -1) {
        const url = new URL(clickUrl);
        return url.searchParams.get("url");
    }

    return clickUrl;
}