
import Input from "./form/Input"
import Attachments from "./form/Attachments"
import Reply from "./form/Reply"

function Form() {

    return (
        <div className="chat-form">
            <Reply/>
            <Attachments/>
            <Input/>
        </div>
    )
}

export default Form