import Loader from "common/src/components/Loader"

import { UPLOADING_IMAGES, UPLOADING_VOICE_MESSAGE, 
        UPLOADING_NONE, UPLOADING_CATALOGUE_PRODUCTS } from "common/src/lib/chat/Chat"
import user from "common/src/user"

const code2status = {
    [UPLOADING_IMAGES]: "sending images",
    [UPLOADING_VOICE_MESSAGE]: "sending voice message",
    [UPLOADING_CATALOGUE_PRODUCTS]: "sending products"
}

function ChatMessageUploading({ message }) {

    if (message.uploading === UPLOADING_NONE) {
        return null;
    }

    if (message.userId === user.id()) {
        return null;
    }

    return (
        <div className="chat-message-uploading">
            <Loader/>
            { code2status[message.uploading] }
        </div>
    )
}

export default ChatMessageUploading