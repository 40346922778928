export default function (cc) {

    if (!cc.__normalized) {

        if (cc.feedCategories_aggregate) {
            cc.feedCategoriesCount = cc.feedCategories_aggregate.aggregate.count;
        }
    }

    cc.__normalized = true;
    return cc;
}