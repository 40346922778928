import { Hub } from "@aws-amplify/core"

const memory = {};
const handlers = {};
const providers = [];
let pool = [];
let suspended = false;

Hub.testId = 1;

const hub = {

    registerProvider: function(provider) {
        providers.push(provider);
    },

    provide: function(what) {
        for (let i = 0, l = providers.length; i < l; i++) {
            let value = providers[i](what);
            if (value) {
                return value;
            }
        }
        return null;
    },

    suspend: function() {
        suspended = true;
    },

    resume: function(replay) {
        suspended = false;
        replay && pool.forEach(e => {
            if (e.source === "app") {
                this.dispatch.apply(this, e.args);
            }
            else if (e.source === "amplify") {
                e.listener(e.data.payload.data, e.data.payload.event);
            }
        });
        pool = [];
    },

    hasListener: function(channel, event, listener) {
        const ekey = channel + "-" + event + "-wrapper";
        return !!listener[ekey];
    },

    asyncHandle: function(event, handler) {
        if (!handlers[event]) {
            handlers[event] = [];
        }
        const ekey = event + "-async-handler";
        if (handler[ekey]) {
            return;
        }
        handler[ekey] = true;
        handlers[event].push(handler);
    },

    removeAsyncHandle: function(event, handler) {
        if (!handlers[event]) {
            return;
        }
        const ekey = event + "-async-handler";
        if (!handler[ekey]) {
            return;
        }
        const inx = handlers[event].find(fn => fn === handler);
        if (inx !== -1) {
            handlers[event].splice(inx, 1);
        }
    },

    hasAsyncHandle: function(event, handler) {
        if (!handlers[event]) {
            return false;
        }
        const ekey = event + "-async-handler";
        return handler[ekey] || handlers[event].indexOf(handler) !== -1;
    },

    callHandlers: async function(event, payload) {
        const hs = handlers[event];
        if (!hs) {
            return;
        }
        for (let i = 0, l = hs.length; i < l; i++) {
            await hs[i](payload);
        }
        //delete handlers[event]; // why was it every here??
    },

    listen: function(channel, event, listener) {
        const ekey = channel + "-" + event + "-wrapper";

        if (listener[ekey]) {
            //console.log(ekey, "already listening")
            return;
            //throw new Error("Already subscribed to this event");
        }

        //console.log("subscribing", channel, event);
    
        const wrapper = function(data) {

            //console.log("hub event", channel, data.payload.event)
            
            if (event === "*" || data.payload.event === event) {
                // the event came from Amplify framework, it doesn't know 
                // events are suspended
                if (suspended) {
                    pool.push({
                        source: "amplify",
                        listener, data
                    });
                    return;
                }
                listener(data.payload.data, data.payload.event, channel);
            }
        }
        listener[ekey] = wrapper;
    
        return Hub.listen(channel, wrapper);
    },

    // wait till event happens, then resolve promise
    promise: function(channel, event) {
        let mk = channel + "-" + event;
        if (mk in memory) {
            return Promise.resolve(memory[mk]);
        }
        return new Promise((resolve, reject) => {
            const listener = (data) => {
                resolve(data);
                hub.remove(channel, event, listener);
            }
            hub.listen(channel, event, listener);
        });
    },

    remove: function(channel, event, listener) {
        const ekey = channel + "-" + event + "-wrapper";
        const wrapper = listener[ekey];
        if (wrapper) {
            delete listener[ekey];
            return Hub.remove(channel, wrapper);
        }
    },

    dispatch: function(channel, event, data, remember) {

        if (suspended) {
            pool.push({
                source: "app",
                args: Array.prototype.slice.call(arguments)
            });
            return;
        }

        if (remember) {
            memory[ channel + "-" + event ] = data;
        }
        else {
            let k = channel + "-" + event;
            if (k in memory) {
                delete memory[k];
            }
        }
        return Hub.dispatch(channel, {
            event: event,
            data: data
        })
    }
}


export default hub;