import { defaultRates } from "common/src/lambdalib/userPaymentSettings"


function getRateForRetailer(affiliate, rates, userRate = defaultRates.fri) {

    const als = rates.aliases.find(als => als.indexOf(affiliate) !== -1) || [ affiliate ];
    const networks = Object.keys(rates.networks);

    for (let n = 0, nl = networks.length; n < nl; n++) {
        const network = networks[n];
        const affs = rates.networks[network];
        let affiliateRates;
        for (let i = 0, l = als.length; i < l; i++) {
            const name = als[i];
            if (affs[name]) {
                affiliateRates = affs[name];
                break;
            }
        }

        if (affiliateRates) {
            const commissions = affiliateRates.map(r => r.commission * (r.pseCommission/100));
            const max = Math.max.apply(Math, commissions);
            //return parseInt(max * userRate);
            return parseInt(max);
        }
    }

    return null;
}

export default getRateForRetailer