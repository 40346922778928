
import throttle from "lodash/throttle"

/**
 * 
 * #Modes
 * 
 * ONE: always have at most one working promise. Until is finished, 
 *              fn() is not called again;
 * ONE_CANCEL: always have at most one working promise; cancel previous, 
 *              call fn() each time
 * KEY: have one promise per key
 * KEY_CANCEL: always have at most one working promise; cancel previous
 *              if key changes
 * 
 * options: {
 *  mode: ONE|ONE_CANCEL|KEY|KEY_CANCEL,
 *  throttle: boolean|number|object{wait: number, options: {}}
 *  pre: function(original args) => {
 *      return setup {
 *          key: promise key, optional
 *          value: already resolved value, optional
 *          skip: skip provided resolver, optional
 *          mode: change mode, optional
 *          ... - user data
 *      }
 *  }
 *  fn(original args): function,
 *  resolve: function(response, setup),
 *  reject: function(err, setup),
 *  always: function(response, setup)
 * }
 */

function singlePromise(options) {
    let promises = {},
        fn, pre, fnResolve, fnReject, fnAlways,
        mode = singlePromise.ONE,
        thro = false,
        last,
        setup;

    if (typeof options === "function") {
        fn = options;
    }
    else {
        mode = options.mode || singlePromise.ONE;
        fn = options.fn; 
        pre = options.pre;
        fnResolve = options.resolve;
        fnReject = options.reject;
        fnAlways = options.always;
        thro = options.throttle || false;
    }

    let run = function() {

        let args = Array.from(arguments),
            key = "_",
            ts = (new Date()).getTime();

        if (pre) {
            setup = pre.apply(null, args) || {};
            // change mode
            if (setup.mode) {
                mode = setup.mode;
            }
            if (setup.value) {
                return Promise.resolve(
                    fnResolve && !setup.skip ? 
                        fnResolve(setup.value, setup, args) : 
                        setup.value
                );
            }
            if (setup.key && (mode === singlePromise.KEY ||
                            mode === singlePromise.KEY_CANCEL)) {
                key = setup.key;
            }
        }

        if (promises[key] && mode !== singlePromise.ONE_CANCEL) {
            return promises[key];
        }

        last = mode === singlePromise.ONE_CANCEL ? ts : key;

        promises[key] = new Promise((resolve, reject) => {
            fn.apply(null, args)
                .then(response => {
                    
                    delete promises[key];

                    if ((mode === singlePromise.ONE_CANCEL && last !== ts) ||
                        (mode === singlePromise.KEY_CANCEL && last !== key)) {
                        reject("cancelled");
                        return;
                    }
                
                    resolve(fnResolve ? fnResolve(response, setup, args) : response);
                    fnAlways && fnAlways(response, setup, args);
                })
                .catch(err => {
                    delete promises[key];
                    if ((mode === singlePromise.ONE_CANCEL && last !== ts) ||
                        (mode === singlePromise.KEY_CANCEL && last !== key)) {
                        if (err !== "cancelled") {
                            return Promise.reject(err);
                        }
                        else return null;
                    }

                    if (err !== "cancelled") {
                        reject(fnReject ? fnReject(err, setup, args) : err);
                    }
    
                    fnAlways && fnAlways(null, setup, args);
                })
        });

        promises[key].catch(err => {
            if (err !== "cancelled") {
                return Promise.reject(err);
            }
        })

        return promises[key];
    }

    if (thro) {
        if (thro === true) {
            return throttle(run, 200);
        }
        else if (typeof thro === "number") {
            return throttle(run, thro);
        }
        else {
            return throttle(run, thro.wait, thro.options);
        }
    }
    else 
        return run;
}


singlePromise.ONE = "one";
singlePromise.ONE_CANCEL = "one_cancel";
singlePromise.KEY = "key";
singlePromise.KEY_CANCEL = "key_cancel";


export default singlePromise;