export default function (cm) {

    if (!cm.__normalized) {

        if (cm.feedMaterials_aggregate) {
            cm.feedMaterialsCount = cm.feedMaterials_aggregate.aggregate.count;
        }
    }

    cm.__normalized = true;
    return cm;
}