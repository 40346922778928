import api from "app/api"
import s3url from "./s3url"

export default async function finalizeUpload(uploadKey) {
    const s3key = await api.backend.post("/upload/image", {
        body: {
            //contentType: mime,
            uploadKey: uploadKey
        }
    })
    .then(resp => resp.key);
    const src = s3url(s3key);

    return { src, key: s3key };
}