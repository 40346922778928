import { QRCode } from "antd";
import settings from "app/settings"
import { ReactComponent as Logo } from "common/src/svg/full_logo.svg"
import { ReactComponent as IconClose } from "common/src/svg/close.svg"
import { useCallback } from "react";

function QRTip ({ onClose }) {

    const handleClose = useCallback(
        (e) => {
            e.preventDefault();
            onClose(false);
            localStorage.setItem("showQR", false);
        },
        [ onClose ]
    )

    return (
        <div className="page-feed-qr-tip">
            <div className="page-feed-qr-wrapper">
                <QRCode value={ settings.appStoreUrl } bordered={ false } size={ 84 }/>
            </div>

            <div className="page-feed-qr-text">
                <div>
                    <Logo/>
                    <a href="/#" onClick={ handleClose }>
                        <IconClose/>
                    </a>
                </div>

                <p>
                    Style on the go. Scan the QR code to download the app
                </p>
            </div>
        </div>
    )

}

export default QRTip