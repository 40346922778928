
import { batch } from "react-redux"
import user from "common/src/user";
import api from "app/api"
//import { lookLoader } from "common/src/actions/looks"
import store from "app/store"
import { data, ui } from "common/src/store/chat"

async function getConnectionId(userId1, userId2) {

    const where = {
        users: {
            userId: { _in: [ userId1, userId2 ]}
        }
    };
    const graph = `
        id
        users {
            userId
        }
    `;
    const conns = await api.connection.list({ where }, graph);
    const conn = conns.find(c => {
        return !!c.users.find(u => u.userId === userId1) &&
                !!c.users.find(u => u.userId === userId2);
    });

    return conn?.id  || null;
}

export const createChat = async (contactId, currentRole = null) => {

    const contactRole = currentRole === null ? 
                            null :
                            currentRole === "customer" ?
                                "shopper" :
                                "customer";
    const userId = user.id();
    const connectionId = await getConnectionId(userId, contactId);
    const chat = await api.chat.create({ connectionId, createdAt: (new Date()).toISOString() });
    await api.chatParticipant.create({ chatId: chat.id, userId: userId, role: currentRole });
    await api.chatParticipant.create({ chatId: chat.id, userId: contactId, role: contactRole });

    return chat.id;
}

/*export const loadChats = async (consId) => {


    const where = { consultationId: { _eq: consId }};
    const order = { look: { createdAt: "desc_nulls_first" }}
    const chats = await api.chat.list({ where, order });
    const lookIds = chats.map(c => c.lookId).filter(id => !!id);
    const looks = lookIds.length > 0 ? 
                    await lookLoader({ where: { id: { _in: lookIds }}}) :
                    [];

    chats.forEach(c => {
        if (c.lookId) {
            const look = looks.find(l => l.id === c.lookId);
            c.look = look;
        }
    });

    return chats;
}*/

export const loadMessages = async (chatId) => {

    const where = { 
        chatId: { _eq: chatId },
        action: { _is_null: true }
    };
    const messages = await api.chatMessage.list({ where });

    return messages;
}

export const loadNotifications = async () => {

    store.dispatch(ui.notifications.loading(true));

    const where = {
        userId: { _eq: user.id() },
        read: { _eq: false }
    }
    const state = store.getState();
    const openChat = state.chats.ui.openChat;
    let foundCurrent = 0;

    const ntfs = await api.chatMessageNotification
                            .list({ where, limit: 100 })
                            // do not include notifications from currently open chat
                            .then(list => openChat ? 
                                            list.filter(n => {
                                                if (n.message.chatId === openChat.id) {
                                                    foundCurrent++;
                                                    return false;
                                                }
                                                return true;
                                            }) :
                                            list);

    batch(() => {
        store.dispatch(data.notifications.set(ntfs));
        store.dispatch(ui.notifications.loading(false))
    });

    if (foundCurrent > 0) {
        clearNotifications(openChat.id);
    }
    
    return { notifications: ntfs, exclude: foundCurrent };
}

export const clearNotifications = async (chatId) => {
    const where = { message: { chatId: { _eq: chatId }}, read: { _eq: false }};
    store.dispatch(data.notifications.remove({ chatId }));
    await api.chatMessageNotification.update(where, { read: true, readAt: (new Date()).toISOString() });
};

async function _clearMessageNotification(messageId) {
    const where = { messageId: { _eq: messageId }};
    const payload = { read: true, readAt: (new Date()).toISOString() };
    const res = await api.chatMessageNotification.update(where, payload);
    return res.affected_rows > 0;
}

export const clearMessageNotification = async (messageId) => {

    let tries = 0;
    let maxTries = 5;

    const tryUpdate = async (resolve) => {
        while (tries < maxTries) {
            const res = await _clearMessageNotification(messageId);
            if (res === true) {
                resolve();
                break;
            }
            await new Promise(resolve => setTimeout(resolve, 1000));
            tries++;
        }
        resolve();
    };
    
    await new Promise((resolve) => tryUpdate(resolve));
};


export const findChatByConsultation = async (consultationId, userId) => {
    userId = userId || user.id();
    const cons = await api.consultation.get(consultationId, "id friId customerId");

    if (!cons) {
        return;
    }

    const where = {
        _and: [
            { participants: { userId: { _eq: userId }}},
            { participants: { userId: { _eq: cons.customerId === userId ? cons.friId : cons.customerId }}}
        ]
    }

    const chat = await api.chat.list({ where }, "id").then(l => l[0]);
    let chatId;

    if (!chat) {
        const currentRole = cons.customerId === userId ? "customer" : "shopper";
        chatId = await createChat(cons.customerId === userId ? cons.friId : cons.customerId, currentRole);
    }
    else {
        chatId = chat.id;
    }

    return chatId;
}

export async function sendLook(lookId, consultationId, userId) {
    userId = userId || user.id();
    const chatId = await findChatByConsultation(consultationId, userId);
    if (chatId) {
        await api.chatMessage.create({
            chatId,
            consultationId: null,
            lookId,
            productId: null,
            userId,
            message: '',
        });
    }
}

export const sendConsultation = async ( consultationId, userId ) => {
    userId = userId || user.id();
    const chatId = await findChatByConsultation(consultationId, userId);

    if (chatId) {
        await api.chatMessage.create({
            chatId,
            consultationId,
            lookId: null,
            productId: null,
            userId,
            message: '',
        });
    }
}

export const addProductComment = async (consultationId, productId, message, userId) => {

    userId = userId || user.id();
    const chatId = await findChatByConsultation(consultationId, userId);

    if (chatId) {
        await api.chatMessage.create({
            chatId,
            consultationId,
            lookId: null,
            productId,
            userId,
            message
        });
    }
}

export const addProductReaction = async (consultationId, productId, reaction, userId) => {
    userId = userId || user.id();
    const chatId = await findChatByConsultation(consultationId, userId);

    if (chatId) {
        await api.chatMessage.create({
            chatId,
            consultationId,
            lookId: null,
            productId,
            userId,
            message: "",
            action: `product-${ reaction }`
        });
    }
};

export const setMessageReaction = async (chatId, messageId, reaction) => {

    const where = {
        chatId: { _eq: chatId },
        messageId: { _eq: messageId },
        userId: { _eq: user.id() }
    }
    const prev = await api.chatMessageReaction.list({ where }).then(list => list[0]);

    if (prev) {

        await api.chatMessageReaction.update(prev.id, { 
            reaction,
            createdAt: (new Date()).toISOString()
        });
    }
    else {
        await api.chatMessageReaction.create({
            chatId,
            messageId,
            userId: user.id(), 
            reaction,
            createdAt: (new Date()).toISOString()
        });
    }
}

export const removeMessageReaction = async (chatId, messageId) => {
    const where = {
        chatId: { _eq: chatId },
        messageId: { _eq: messageId },
        userId: { _eq: user.id() }
    }
    await api.chatMessageReaction.remove(where);
}