import { useEffect, useState, useMemo } from "react"

let moduleLoaded = false;
let unicodeEmoji = null;

function useUnicodeEmoji() {

    const [ loaded, setLoaded ] = useState(moduleLoaded);
    const api = useMemo(() => unicodeEmoji, [ loaded ]);

    useEffect(
        () => {
            if (!loaded) {
                // dynamic import for code splitting
                import("unicode-emoji").then((mod) => {
                    unicodeEmoji = mod;
                    moduleLoaded = true;
                    setLoaded(true);
                });
            }
        },
        []
    );

    return api;
}

export default useUnicodeEmoji