import { useMemo, useState, useEffect, useCallback,
            useImperativeHandle, useRef, forwardRef } from "react"

import useLookProductData from "common/src/refactor/hooks/look/useLookProductData"
import useUpdateEffect from "common/src/hooks/useUpdateEffect"
import useDomEvent from "common/src/hooks/useDomEvent"
import { getDefaultTagLayout, calculateTagPosition } from "common/src/lib/look/tag/position"
import useDictRef from "common/src/hooks/useDictRef"
import useCatalogueProductUrl from "common/src/refactor/hooks/useCatalogueProductUrl"
import async from "common/src/lib/js/async"
import useThrottleCallback from "common/src/hooks/useThrottleCallback"

function LookTag({ product, point, layout: savedLayout, 
                    className, attrs, onClick,
                    before, after }, impRef) {

    const elRef = useRef(null);
    const [ layout, setLayout ] = useState(() => savedLayout || getDefaultTagLayout());

    const cls = useMemo(
        () => [ "look-card-tagged-tag", className || "" ].join(" "),
        [ className ]
    );

    const ref = useDictRef({ point, layout });

    const { price, salePrice, 
            brand, retailerName,
            isSale, isSold, 
            priceStr, salePriceStr } = useLookProductData(product);

    const productUrl = useCatalogueProductUrl(product);

    const defaultOnClick = useCallback(
        (e) => {
            e.stopPropagation();
            if (onClick) {
                e.preventDefault();
                onClick(product);
            }
        },
        [ onClick, product ]
    );

    const _calculateTagPosition = useCallback(
        (opts) => {

            const { 
                defaultTagPosition = ref.layout?.tagPosition || undefined, 
                defaultTipPosition = ref.layout?.tipPosition || undefined
            } = opts || {};

            const { adjustedLayout, offset } = calculateTagPosition({
                layout: {
                    tagPosition: defaultTagPosition,
                    tipPosition: defaultTipPosition
                },
                tagEl: elRef.current,
                point: ref.point
            });

            setLayout({ ...adjustedLayout, offset });

            return {
                adjustedLayout,
                offset
            }

            // if (ref.product) {
            //     dispatch(data.productTags.update({
            //         id: ref.product.id,
            //         layout: {
            //             point: ref.point,
            //             layout: adjustedLayout
            //         }
            //     }));
            // }
        },
        []
    );

    const _thrtlCalculatePosition = useThrottleCallback(
        _calculateTagPosition,
        [ _calculateTagPosition ],
        200,
        {
            trailing: true
        }
    )

    useImperativeHandle(
        impRef, 
        () => ({
            el: elRef.current,
            calculateTagPosition: _calculateTagPosition,
            layout: { ...layout },
            point: { ...point }
        }), 
        [ _calculateTagPosition, elRef.current, layout, point ]
    );

    useEffect(
        () => {
            async(_calculateTagPosition);
            //async(_calculateTagPosition, 500);
        },
        []
    );

    useUpdateEffect(
        () => void _calculateTagPosition(),
        [ point, product?.id ]
    );

    useEffect(
        () => {
            //console.log(layout.offset)
            const style = {
                ...layout.offset,
                transform: "none"
            };
            Object.keys(style).forEach(key => {
                let value = style[key];
                if (typeof value === "number") {
                    value += "px";
                }
                elRef.current.style[key] = value;
            })
        },
        [ layout ]
    );

    useDomEvent(window, "resize", _thrtlCalculatePosition);

    return (
        <a href={ productUrl } 
            className={ cls } 
            ref={ elRef }
            target="_blank"
            rel="noreferrer noopener" 
            onClick={ defaultOnClick }
            { ...attrs }>
            { before }
            <div className="look-card-tagged-tag-content">
                <p className="look-card-tagged-tag-brand">{ brand }</p>
                <p className="look-card-tagged-tag-retailer">{ retailerName }</p>
                { isSold && <p className="look-card-tagged-tag-price look-card-tagged-tag-sold-out" children={ priceStr }/> }
                { !isSold && 
                    <>
                    { isSale &&
                        <p className="look-card-tagged-tag-price look-card-tagged-tag-price-old-new">
                            <span className="look-card-tagged-tag-price-discounted">
                                { salePriceStr }
                            </span>
                            <span>&nbsp;</span>
                            <span className="look-card-tagged-tag-price-old">
                                { priceStr }
                            </span>
                        </p> }
                    { (!!price && !salePrice) &&
                        <p className="look-card-tagged-tag-price" children={ priceStr }/> }
                    </> }
            </div>
            { after }
            <div className={ "look-card-tagged-tag-tip " + layout.tipPosition }/>
        </a>
    )
}

export default forwardRef(LookTag)