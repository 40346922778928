
import React, { useMemo } from "react"
import { ReactComponent as IconLoading } from "common/src/svg/loading.svg"

const DEFAULT_SIZE = 20;

function Loader(props) {

    const { className = "", nbsp = false, inline = false } = props;
    const size = useMemo(() => props.size ? parseInt(props.size) : DEFAULT_SIZE, [ props.size ]);
    const cls = useMemo(() => "loading " + className, [ className ]);
    const style = useMemo(
        () => {
            if (props.size === false) {
                return {};
            }
            const unit = typeof size === "number" ? size + "px" : size;
            return { width: unit, height: unit };
        }, 
        [ props.size, size ]
    );

    if (inline) {
        if (nbsp) {
            return (
                <>
                    &nbsp;
                    <IconLoading style={ style }/>
                    &nbsp;
                </>
            )
        }
        else {
            return <IconLoading style={ style }/>
        }
    }

    return (
        <div className={ cls }>
            <IconLoading style={ style }/>
        </div>
    )
};

export default Loader