
function Recording() {

    return (
        <span className="animation-recording">
            <span className="animation-recording-1"></span>
            <span className="animation-recording-2"></span>
            <span className="animation-recording-3"></span>
        </span>
    )
}

export default Recording