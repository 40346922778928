import { useMemo } from "react";
import { useNavigate } from "react-router-dom"

import useStyle from "common/src/refactor/hooks/useStyle"
import useDateFormat from "common/src/refactor/hooks/useDateFormat"



import { ReactComponent as IconLooksActive } from "common/src/svg/looks_active.svg"
import { ReactComponent as IconLooks } from "common/src/svg/looks.svg"
import { ReactComponent as IconTag } from "common/src/svg/tag.svg"

import ConsultationStatus from "./Status";

import s3url from "common/src/lib/image/s3url"
import swallowEvent from "common/src/lib/dom/swallowEvent";
import { useCallback } from "react";

const stylePostfixes = [
    "image", "name", "description", 
    "date", "count", 
    "footer", "footer-counts", 
];
const baseClass = "consultation-card";

function ConsultationCard({ cons, onClick, href,
                            className, 
                            style,
                            children, imageChildren,
                            showDate = true,
                            showCounts = true,
                            showStatus = true, 
                            showDescription = true }) {

    const navigate = useNavigate();
    const styles = useStyle(
        {
            className: [ baseClass, className ],
            children: stylePostfixes,
            extend: {
                [baseClass]: style
            }
        },
        [ className ]
    );

    const image = useMemo(
        () => {
            if (cons.images.length > 0) {
                const image = cons.images[0];
                const src = image.key ? s3url(image.key, 500) : image.src;
                return src;
            }
            if (cons.looks.length > 0) {
                const look = cons.looks.find(l => !!l.products.find(p => p.images?.length > 0));
                return look ? look.products[0].images[0].src : null;
            }
            return null;
        },
        [ cons.images ]
    );

    const counts = useMemo(
        () => {
            const looks = cons.looks.length;
            const products = cons.looks.reduce((prev, l) => prev + l.products.length, 0);
            return { looks, products };
        },
        [ cons ]
    );

    const date = useDateFormat(cons?.createdAt);

    const onCardClick = useCallback(
        (e) => {
            swallowEvent(e);

            if (href) {
                navigate(href);
                return;
            }
            
            if (onClick) {
                return onClick(cons.id);
            }
        },
        [ cons, onClick, href ]
    );

    const _children = useMemo(
        () => {
            return typeof children === "function" ? children(cons) : children;
        },
        [ children, cons ]
    );

    const _imageChildren = useMemo(
        () => {
            return typeof imageChildren === "function" ? imageChildren(cons) : imageChildren;
        },
        [ imageChildren, cons ]
    );

    const imageStyle = useMemo(
        () => {
            const style = {
                ...styles.image
            }

            if (image) {
                style.style = {
                    ...style.style,
                    backgroundImage: `url(${ image })`
                }
                //style.style.backgroundImage = `url(${ image })`;
            }

            return style;
        },
        [ styles.image, image ]
    );

    return (
        <div { ...styles.main }>

            <div { ...imageStyle }>
                { !image && <IconLooksActive/> }
                { (!!onClick || !!href) && 
                    <a 
                        className="card-link"
                        href={ href || "/#" } 
                        onClick={ onCardClick }/> }
                { _imageChildren }
            </div>

            <div { ...styles.footer }>
                <div { ...styles.footerCounts }>
                    { showStatus && 
                        <ConsultationStatus noText status={ cons.status }/> }

                    { showCounts &&
                        <>
                            <div { ...styles.count }>
                                <IconLooks/>
                                { counts.looks } { counts.looks === 1 ? "Look" : "Looks" }
                            </div>

                            <div { ...styles.count }>
                                <IconTag/>
                                { counts.products } { counts.products === 1 ? "Product" : "Products" }
                            </div>
                        </> }

                    { showDate && 
                        <span { ...styles.date }>{ date }</span> }
                </div>

                <div { ...styles.name }>
                    { cons.title || cons.description }
                </div>

                { (showDescription && !!cons.title && !!cons.description ) && 
                    <div { ...styles.description }>
                        { cons.description }
                    </div> }
            </div>

            { _children }
        </div>
    )
}

export default ConsultationCard