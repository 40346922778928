import { useState, useMemo, useEffect, useContext, useCallback } from "react"
import { useOn } from "@kuindji/observable-react"

import ProductCard from "common/src/refactor/components/catalogue/product/Card"
import LookProductReaction from "common/src/refactor/components/look/ProductReaction"
import ChatContext from "common/src/lib/chat/ChatContext"


function MessageLookProduct({ message }) {
    const chat = useContext(ChatContext);
    const [ cacheCounter, setCacheCounter ] = useState(0);
    const product = useMemo(
        () => {
            if (!message.productId) {
                return null;
            }
            return chat.getProductsCache().find(p => p.id === message.productId)
        }, 
        [ cacheCounter, message.productId ]
    );

    const onClick = useCallback(
        async () => {
            const look = chat.getLookCache().find(l => l.id === product.lookId);
            if (look) {
                chat.trigger("messageAction", "review-look", look, message.productId);
            }
            else {
                await chat.loadLooks([ product.lookId ]);
                const look = chat.getLookCache().find(l => l.id === product.lookId);
                if (look) {
                    chat.trigger("messageAction", "review-look", look, message.productId);
                }
            }
        },
        [ message.productId, chat, product ]
    );

    useOn(chat, "cache", setCacheCounter);
    
    if (!product) {
        return null;
    }

    return (
        <ProductCard product={ product } onClick={ onClick }>
            <LookProductReaction product={ product }/>
        </ProductCard>
    )
}

export default MessageLookProduct