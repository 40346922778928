
import hub from "common/src/hub"
import { saveDraft } from "common/src/actions/look/editor"

const listeners = {
    init: () => {
        hub.listen("look-editor", "save-draft", saveDraft);
    }
}

export default listeners