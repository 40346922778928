import { batch } from "react-redux"
import api from "common/src/api"
import user from "common/src/user"
import store from "app/store"
import { data, ui } from "common/src/store/connections"
import action2message from "common/src/lib/chat/actionNames"

let subscription;
let lastCurrentUserRole;

function latter(d1, d2) {
    if (!d1) return d2;
    if (!d2) return d1;
    return d1 > d2 ? d1 : d2;
};

function dsort(d1, d2) {
    return d1 === d2 ? 0 : d1 < d2 ? 1 : -1;
};

function activitySort(a1, a2) {
    return dsort(a1.last, a2.last);
};

function currentActivity(c) {
    const activities = [
        { type: "typing", last: c.lastTypingAt },
        { type: "recording", last: c.lastRecordingAt }
    ].filter(a => !!a.last).sort(activitySort);
    return activities[0] || null;
}


export const normalizeUserConnection = (c, currentUserRole) => {
    const msg = c.userConnections[0]?.connection?.chats[0]?.lastMessages[0];
    const prt = c.userConnections[0]?.connection?.chats[0]?.participants.find(p => p.userId === c.id);
    const cons = c.consultationsAsCustomer ? 
                    c.consultationsAsCustomer[0] :
                    c.consultationsAsFri[0];
    const online = c.onlineState[0];
    c.createdAt = "" + (c.userConnections[0]?.connection?.createdAt || "");
    c.connectionId = c.userConnections[0]?.connection?.id || null;
    c.lastMessageAt = "" + (msg?.createdAt || "");
    c.lastMessageRead = msg?.notifications[0]?.read === false ? false : true;
    c.lastAction = "" + (msg?.action || "");
    c.lastConsultationAt = "" + (cons?.createdAt || "");
    c.lastTypingAt = "" + (prt?.lastTypingAt || "");
    c.lastRecordingAt = "" + (prt?.lastRecordingAt || "");
    c.lastOnlineAt = "" + (online?.lastOnlineAt || "");
    c.currentActivity = currentActivity(c);
    //c.role = currentUserRole === "customer" ? "shopper" : "customer";
    c.role = c.userConnections[0]?.connection?.users?.find(u => u.userId === c.id)?.role;
    c.lastMessage = "";
    c.unreadCount = c.chatMessages_aggregate?.aggregate?.count || 0;

    if (msg) {
        if (msg.message) {
            c.lastMessage = msg.message;
        }
        else if (msg.productId) {
            c.lastMessage = "* sent a product *";
        }
        else if (msg.lookId) {
            c.lastMessage = "* sent a look *";
        }
        else if (msg.consultationId) {
            c.lastMessage = "* sent a styling session *"
        }
        else if (msg.moodboardId) {
            c.lastMessage = "* sent a moodboard *";
        }
        else if (msg.replyToId) {
            c.lastMessage = "* replied to a message *";
        }
        else if (msg.action) {
            c.lastMessage = `* ${ action2message[msg.action] } *`;
        }
        else if (msg.images && msg.images.length > 0) {
            c.lastMessage = "* sent images *";
        }
        else if (msg.voiceMessages && msg.voiceMessages.length > 0) {
            c.lastMessage = "* sent a voice message *"
        }
        else if (msg.catalogueProducts && msg.catalogueProducts.length > 0) {
            c.lastMessage = "* sent catalogue products *";
        }
        else if (msg.voiceMessages && msg.voiceMessages.length > 0) {
            c.lastMessage = "* sent a voice message *";
        }
    }

    return c;
};

export const userConnectionSorter = (a, b) => {
    const da = latter(a.createdAt, latter(a.lastMessageAt, a.lastConsultationAt));
    const db = latter(b.createdAt, latter(b.lastMessageAt, b.lastConsultationAt));
    return dsort(da, db);
};



export const getUserConnectionWhere = (currentUserAs) => {
    const userId = user.id();
    const where = {
        id: { _neq: userId },
        userConnections: {
            connection: {
                users: {
                    role: { _eq: currentUserAs },
                    userId: { _eq: userId }
                }
            }
        }
    };
    /*const consultations = {};

    if (currentUserAs === "shopper") {
        // "consultationsAsCustomer" 
        // if current user is shopper we select consultations
        // for each connected user is a customer
        consultations.consultationsAsCustomer = {
            friId: { _eq: userId }
        }
    }
    else {
        consultations.consultationsAsFri = {
            customerId: { _eq: userId }
        }
    }

    const where = {
        id: { _neq: userId },
        _or: [
            {
                participantInChats: {
                    chat: {
                        participants: {
                            role: { _eq: currentUserAs },
                            userId: { _eq: userId }
                        }
                    }
                }
            },
            consultations
        ]
    };*/
    return where;
};


export const getUserConnectionGraph = (currentUserAs) => {
    const userId = user.id();
    const consultations =   
        currentUserAs === "shopper" ?
            `consultationsAsCustomer(
                order_by: {createdAt: desc}, 
                limit: 1, 
                where: { friId: {_eq: "${ userId }"}} ) {
                createdAt
            }` :
            `consultationsAsFri(
                order_by: {createdAt: desc}, 
                limit: 1, 
                where: { customerId: {_eq: "${ userId }"}} ) {
                createdAt
            }`;
    const graph = `
        id
        avatar
        email
        phone
        givenName
        familyName
        sizes {
            id
            type
            system
            value
            comment
        }
        chatMessages_aggregate(
            where: {
                hidden: { _eq: false }
                notifications: {
                    userId: {_eq: "${ userId }"}, 
                    read: { _eq: false }
                }
            }
        ) {
            aggregate {
                count
            }
        }
        onlineState(where: { connectionUserId: { _is_null: true }}) {
            lastOnlineAt
        }
        userConnections(where: {
            connection: { 
                deleted: { _eq: false },
                users: { 
                    userId: {_eq: "${ userId }"}, 
                    role: {_eq: "${ currentUserAs }"}}}
            }) {
            connection {
                id
                createdAt
                deleted
                users {
                    userId
                    role
                }
                chats {
                    participants {
                        userId
                        lastTypingAt
                        lastRecordingAt
                        lastOnlineAt
                    }
                    lastMessages: messages(where: { hidden: { _eq: false }} order_by: {createdAt: desc}, limit: 1) {
                        action
                        message
                        lookId
                        productId
                        moodboardId
                        consultationId
                        replyToId
                        createdAt
                        images {
                            id
                        }
                        catalogueProducts(limit: 1) {
                            id
                        }
                        voiceMessages(limit: 1) {
                            id
                        }
                        notifications(
                            limit: 1, 
                            order_by: {readAt: desc},
                            where: {
                                userId: {_eq: "${ userId }"}
                            }) {
                            read
                        }
                    }
                }
            }
        }
        ${ consultations }
    `;
    
    /*const graph = `
        id
        avatar
        givenName
        familyName
        sizes {
            id
            type
            system
            value
            comment
        }
        participantInChats(where: {
                chat: { 
                    participants: { 
                        userId: {_eq: "${ userId }"}, 
                        role: {_eq: "${ currentUserAs }"}}}}
            ){
            chat {
                participants {
                    userId
                    lastTypingAt
                    lastRecordingAt
                    lastOnlineAt
                }
                messages(order_by: {createdAt: desc}, limit: 1) {
                    action
                    message
                    createdAt
                    notifications(
                        limit: 1, 
                        order_by: {readAt: desc},
                        where: {
                            userId: {_eq: "${ userId }"}
                        }) {
                        read
                    }
                }
            }
        }
        ${ consultations }
    `;*/
    return graph;
};

export const reloadUserConnections = async () => {
    return loadUserConnections(lastCurrentUserRole, true);
}

export const loadUserConnections = async (currentUserAs, reload = false) => {

    lastCurrentUserRole = currentUserAs;
    const state = store.getState();
    if (state.connections && state.connections.data.connections.length > 0 && !reload) {
        return state.connections.data.connections;
    }

    store.dispatch(ui.connections.loading(true));
    const where = getUserConnectionWhere(currentUserAs);
    const graph = getUserConnectionGraph(currentUserAs);

    let connections = await api.user.list({ where }, graph);
    connections = connections.map(normalizeUserConnection);
    connections = connections.sort(userConnectionSorter);

    batch(() => {
        store.dispatch(ui.connections.loading(false));
        store.dispatch(data.connections.set(connections));
    });

    return connections;
}

export const loadUserConnection = async (currentUserAs, contactId) => {

    lastCurrentUserRole = currentUserAs;
    const state = store.getState();
    if (state.connections && state.connections.data.connections.length > 0) {
        return state.connections.data.connections.find(c => c.id === contactId);
    }

    const where = { id: { _eq: contactId }};
    const graph = getUserConnectionGraph(currentUserAs);
    let connection = await api.user.list({ where }, graph).then(list => list[0]);
    return connection;
}

export const subscribeToUserConnections = async (currentUserAs) => {

    lastCurrentUserRole = currentUserAs;
    if (subscription) {
        return;
    }

    store.dispatch(ui.connections.loading(true));

    const where = getUserConnectionWhere(currentUserAs);
    const graph = getUserConnectionGraph(currentUserAs);

    subscription = await api.user.subscribeList({ where }, graph, (connections) => {
        connections = connections.filter(c => c.userConnections.length).map(c => normalizeUserConnection(c, currentUserAs));
        connections = connections.sort(userConnectionSorter);
        connections = connections.sort(userConnectionSorter);
        batch(() => {
            store.dispatch(ui.connections.loading(false));
            store.dispatch(ui.connections.loaded(true));
            store.dispatch(data.connections.set(connections));
        });
    });
}

export const unsubscribeFromUserConnections = () => {
    if (subscription) {
        if (subscription.subscription) {
            subscription.subscription.unsubscribe();
        }
        subscription = null;
    }
}

export const clearConnections = () => {
    batch(() => {
        store.dispatch(ui.connections.loading(false));
        store.dispatch(ui.connections.loaded(false));
        store.dispatch(data.connections.set([]));
    });
}


export async function getConnectionInfo(byUserId, userId) {

    const where = {
        byUserId: { _eq: byUserId },
        userId: { _eq: userId }
    }
    const info = await api.connectionUserInfo.list({ where }).then(list => list[0]);

    return info;
}

export async function updateConnectionInfo(byUserId, userId, info = {}) {

    const where = {
        byUserId: { _eq: byUserId },
        userId: { _eq: userId }
    };

    const ex = await getConnectionInfo(byUserId, userId);

    if (ex) {
        await api.connectionUserInfo.update(where, {
            notes: info.notes || ""
        });
    }
    else {
        await api.connectionUserInfo.create({
            byUserId,
            userId,
            notes: info?.notes || ""
        });
    }   
}