import { createContext } from "react"
import Context from "common/src/refactor/lib/Context"

const FilterContext = createContext();

export const CatalogueFilterContext = FilterContext;
export const CatalogueFilterContextProvider = FilterContext.Provider;

export function createCatalogueFilterContext(initialData = {}) {
    return new Context(initialData)
}