
import hub from "common/src/hub"
import store from "app/store"
import * as actions from "app/actions/page/consultation"
import { data } from "app/store/page/consultation"


function onLookCreated({ consultationId }) {
    let state = store.getState(),
        c = state.consultationPage.data.cons;
    if (consultationId && c && c.id === consultationId) {
        actions.loadOne(c.id, true);
    }
}

function getConsByLook(lookId) {
    let state = store.getState(),
        c = state.consultationPage.data.cons;
    if (c && c.looks.find(l => l.id === lookId)) {
        return c;
    }
    return null;
}

function onLookUpdated({ id: lookId }) {
    if (!Array.isArray(lookId)) {
        lookId = [ lookId ];
    }
    for (let i = 0, l = lookId.length; i < l; i++) {
        let c = getConsByLook(lookId[i]);
        if (c) {
            actions.loadOne(c.id, true);
            break;
        }
    }    
}

function onLookDeleted({ id: lookId }) {
    let c = getConsByLook(lookId);
    if (c) {
        actions.loadOne(c.id, true);
    }
}

function onConsultationUpdated(id) {
    let state = store.getState(),
        c = state.consultationPage.data.cons;
    if (c && c.id === id) {
        actions.loadOne(c.id, true);
    }
}

function onProductPurchase(p) {
    let state = store.getState(),
        c = state.consultationPage.data.cons;
    if (c) {
        actions.loadOne(c.id, true);
    }
}


function onProductReactionChanged({ productId, reaction }) {
    store.dispatch(data.setProductReaction({ productId, reaction }))
}

function onProductCommentAdded(message) {
    const productId = message.productId;
    store.dispatch(data.setProductLastMessage({
        productId,
        message
    }))
}


const listeners = {
    init: function() {

        hub.listen("look", "updated", onLookUpdated);
        hub.listen("look", "published", onLookUpdated);
        hub.listen("look", "unpublished", onLookUpdated);
        hub.listen("look", "deleted", onLookDeleted);
        hub.listen("look", "created", onLookCreated);

        hub.listen("consultation", "updated", onConsultationUpdated);
        hub.listen("consultation", "product-purchased", onProductPurchase);
        hub.listen("consultation", "product-unpurchased", onProductPurchase);

        hub.listen("product", "reaction-changed", onProductReactionChanged);
        hub.listen("product", "comment-added", onProductCommentAdded);
    }
}

export default listeners