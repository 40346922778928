
import settings from "common/src/settings"

function getHost() {
    return settings['cloudfront'][process.env.REACT_APP_ENV] + "/";
}

export default function(key, width) {
    if (!key || !(typeof key === "string")) {
        return "";
    }
    if (key.match(/^(http|data|file)/i)) {
        return key;
    }
    let url = getHost() + key;

    if (width) {
        url += "?w=" + width;
    }

    return url;
}

export function url2key(url) {
    return url.replace(getHost(), "");
}