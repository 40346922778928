import { useEffect, useCallback, useMemo } from "react"
import { useSelector } from "react-redux"
import moment from "moment"
import { Spin, List, Flex, Drawer } from "antd"
import InfiniteScroll from 'react-infinite-scroll-component'

import Link from "common/src/components/Link"
import UserAvatar from "common/src/refactor/components/UserAvatar"
import { ReactComponent as IconGo } from "common/src/svg/aright.svg"
import Spacer from "common/src/refactor/components/Spacer"

import { setRead, loadMoreNotifications, loadNotifications } from "common/src/actions/notifications"
import settings from "app/settings"
import commonSettings from "common/src/settings"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import swallowEvent from "common/src/lib/dom/swallowEvent"
import formatCurrency from "common/src/lib/format/currency"
import store from "app/store"
import { ui } from "common/src/store/dialogs"


export const DIALOG_NAME = "notifications-dialog";

function getOutsideUrl(domain, redir, params) {

    let url = commonSettings.domains[ process.env.REACT_APP_ENV ][ domain ];
    url += "?redir=" + encodeURIComponent(redir);
    if (params) {
        url += "&params=" + encodeURIComponent(JSON.stringify(params));
    }

    return url;
}

function getToolsChatUrl(contactId) {
    let url, crossApp;
    if (settings.app === "tools") {
        url = `/chat/${ contactId }`;
    }
    else {
        url = getOutsideUrl("tools", `/chat/${ contactId }`);
        crossApp = true;
    }
    return { url, crossApp };
}

function getToolsConsultationUrl(id) {
    let url, crossApp;
    if (settings.app === "tools") {
        url = `/styling/${ id }`
    }
    else {
        url = getOutsideUrl("tools", `/styling/${ id }`);
        crossApp = true;
    }
    return { url, crossApp };
}

function chatNotification(n) {
    const message = n.chatMessageNotification.message;
    const user = message.user;
    const from = user.givenName;
    const fromFull = `${ user.givenName } ${ user.familyName }`;
    const read = n.chatMessageNotification.read;

    let text = "";
    let url = "";
    let crossApp = false;
    let productAction = null;
    let subtext = "";

    if (["product-like", "product-dislike", 
        "product-replace", "product-shop"].indexOf(message.action) !== -1) {
        if (!message.product && !message.product?.look &&
            (!message.catalogueProducts || message.catalogueProducts.length === 0)) {
            return {};
        }
        subtext = message.product?.name || 
                    message.catalogueProducts[0]?.catalogueProduct.name;
        if (settings.app === "tools") {
            if (message.product && message.product.look) {
                productAction = `/styling/${ message.product.look.consultationId }`;
            }
            else if (message.catalogueProducts && message.catalogueProducts.length > 0) {
                const cp = message.catalogueProducts[0].catalogueProduct;
                productAction = `/feed/?cpid=${ cp.id }`;
            }
        }
    }

    switch (message.action) {

        // for shopper, in tools
        case "product-like": {
            text = `${ from } liked a product`;
            ({ url, crossApp } = getToolsChatUrl(user.id));
            break;
        }
        // for shopper, in tools
        case "product-dislike": {
            text = `${ from } disliked a product`;
            ({ url, crossApp } = getToolsChatUrl(user.id));
            break;
        }
        // for shopper, in tools
        case "product-replace": {
            text = `${ from } wants a different product`;
            ({ url, crossApp } = getToolsChatUrl(user.id));
            break;
        }
        // for shopper, in tools
        case "product-shop": {
            text = `${ from } wants to buy a product`;
            ({ url, crossApp } = getToolsChatUrl(user.id));
            break;
        }
        // for shopper, in tools
        case "register": {
            text = `${ fromFull } has joined THE FLOORR.`;
            ({ url, crossApp } = getToolsChatUrl(user.id));
            break;
        }
        case "":
        case null: {
            if (!message.chat) {
                return {};
            }
            text = `You have a message from ${ from }`;
            ({ url, crossApp } = getToolsChatUrl(user.id));
            break;
        }
        default: {
            return {}
        }
    }

    return { read, url, text, user, crossApp, productAction, subtext };
}

function userNotification(n) {
    let text = "", url = "", user, crossApp = false;
    const read = n.userNotification.read;

    switch (n.userNotification.type) {
        // for shopper in tools
        case "consultation-assigned": {
            if (!n.userNotification.consultationId) {
                console.log(n)
                return <></>
            };
            text = "You have a new styling session assigned to you.";
            ({ url, crossApp } = getToolsConsultationUrl(n.userNotification.consultationId));
            user = n.userNotification.consultation?.customer;
            break;
        }
        // for shopper in tools
        case "consultation-request-received": {
            if (!n.userNotification.consultationId) {
                console.log(n)
                return <></>
            };
            text = "You have a new Personal Shopping Styling session request.";
            ({ url, crossApp } = getToolsConsultationUrl(n.userNotification.consultationId));
            user = n.userNotification.consultation?.customer;
            break;
        }
        case "message-reaction": {
            user = n.userNotification.fromUser;
            const { reaction } = JSON.parse(n.userNotification.details);
            text = `${ user.givenName } reacted ${ reaction } to your message`;
            if (settings.app === "admin") {
                ({ url, crossApp } = getToolsChatUrl(user.id));
            }
            else {
                url = `/chat/${ user.id }?mid=${ n.userNotification.messageId }`;
            }
            break;
        }
        case "network-order-received": {
            const { saleAmount, advertiser, currency } = JSON.parse(n.userNotification.details);
            const value = formatCurrency(saleAmount, currency.toUpperCase());
            text = `We've received your ${ advertiser } order for ${ value }. Your commission will be updated shortly.`;
            url = "/profile";
            break;
        }
        case "network-order-approved": {
            const { advertiser, commissionAmount, currency } = JSON.parse(n.userNotification.details);
            const value = formatCurrency(commissionAmount, currency.toUpperCase());
            text = `${ value } commission has been approved by ${ advertiser }`;
            url = "/profile";
            break;
        }
        case "revolut-payment-completed": {
            const { amount, currency } = JSON.parse(n.userNotification.details);
            const value = formatCurrency(amount, currency.toUpperCase());
            text = `${ value } commission has been paid to you by The Floorr`;
            url = "/profile";
            break;
        }
        case "ec-changed-profile": {
            user = n.userNotification.fromUser;
            text = `${ user.givenName } updated their profile`;
            url = `/chat/${ user.id }`;
            break;
        }
        case "ec-deleted-profile": {
            user = n.userNotification.fromUser;
            if (!user) {
                return <></>
            }
            text = `Just letting you know that ${ user.givenName } deleted their profile`;
            url = `/chat`;
            break;
        }
        case "consultation-look-favorite": {
            user = n.userNotification.fromUser;
            if (!user) {
                return <></>
            }
            text = `${ user.givenName } liked a look`;
            url = `/styling/${ n.userNotification.consultationId }`;
            break;
        }
        default: {
            return <></>
        }
    }

    return { read, url, text, user, crossApp };
}


function Notification({ n }) {

    const onActionClick = useCallback(
        () => {
            if (n.chatMessageNotification) {
                setRead("chat-message-notification", n.chatMessageNotificationId, true);
            }
        },
        [ n ]
    );

    const date = useMemo(() => moment(n.createdAt).fromNow(), [ n ]);
    
    const { type, read, text, url, user, crossApp } = useMemo(
        () => {
            if (n.chatMessageNotificationId) {
                return { type: "chat-message-notification", ...chatNotification(n) };
            }
            else if (n.userNotificationId) {
                return { type: "user-notification", ...userNotification(n) };
            }
            return {};
        },
        [ n ]
    );

    const toggleNotificationRead = useSwallowEventCallback(
        (e) => {
            e && swallowEvent(e);
            setRead(type, n.group, !read);
        },
        [ read, type, n ]
    );

    const onLinkClick = useCallback(
        () => !read && toggleNotificationRead(),
        [ read ]
    );



    if (!text || !n.chatMessageNotificationId && !n.userNotificationId) {
        return null;
    }

    return (
        <List.Item>
            <Flex className="notifications-dialog-item" gap="0.5rem" align="center">
                <a href="/#" 
                    title={ read ? "Mark as unread" : "Mark as read" }
                    className={ read ? "notifications-dialog-item-read" : 
                                        "notifications-dialog-item-unread" }
                    onClick={ toggleNotificationRead }/>
                { user && <UserAvatar user={ user }/> }

                <Flex vertical className="notifications-dialog-item-content">
                    { crossApp ?
                        <a href={ url }
                            className="notifications-dialog-item-text"
                            target="_blank"
                            rel="noreferrer noopener"
                            children={ text }
                            onClick={ onLinkClick }/> :
                        <Link 
                            className="notifications-dialog-item-text"
                            to={ url } 
                            children={ text }
                            onClick={ onLinkClick }/>
                    }
                    <span className="notifications-dialog-item-date">{ date }</span>
                </Flex>

                { n.group.length > 1 && 
                    <span className="notifications-dialog-item-group">{ n.group.length }</span> }
            </Flex>
        </List.Item>
    )
}

function NotificationsDialog({ name = DIALOG_NAME }) {

    const open = useSelector(s => s.dialogs[name] || false);
    const notifications = useSelector(s => s.notifications.data.list);
    const loading = useSelector(s => s.notifications.ui.list.loading);
    const hasMore = useSelector(s => s.notifications.ui.list.hasMore);
    const onClose = useCallback(() => NotificationsDialog.hide(name), [ name ]);

    useEffect(
        () => {
            if (notifications.length === 0) {
                loadNotifications();
            }
        },
        []
    );

    const renderItem = useCallback(
        (item) => <Notification n={ item }/>,
        []
    );

    return (
        <Drawer
            className="tf-drawer"
            title="Notifications"
            destroyOnClose
            open={ open }
            onClose={ onClose }
            rootClassName="notifications-dialog"
        >
            <InfiniteScroll
            dataLength={ notifications.length }
            next={ loadMoreNotifications }
            hasMore={ hasMore }
            hasChildren
            loader={ <Flex vertical align="center"><Spin spinning/><Spacer size="small"/></Flex> }>
            <List
                loading={ loading }
                rowKey="id"
                pagination={ false }
                dataSource={ notifications }
                renderItem={ renderItem }/>
        </InfiniteScroll>
        </Drawer>
    )
}

NotificationsDialog.show = (name = DIALOG_NAME) => {
    store.dispatch(ui.show(name));
}

NotificationsDialog.hide = (name = DIALOG_NAME) => {
    store.dispatch(ui.hide(name));
}

export default NotificationsDialog