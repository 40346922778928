import { useCallback, useMemo, useState } from "react"
//import { App as AntApp } from "antd"

import Select from "common/src/refactor/components/ant/Select"

import * as consActions from "app/actions/page/consultation"
import useConsultationActions from "app/hooks/consultation/useConsultationActions";


function ConsultationStatusSelect({ consultation, onToggle, onStatusChange, ...rest }) {

    //const { modal } = AntApp.useApp();
    const [ changingStatus, setChangingStatus ] = useState(false);
    const { onDeleteClick } = useConsultationActions({ cons: consultation })
    //const [ isOpen, setIsOpen ] = useState(false);

    const statusMenu = useMemo(
        () => {
            return [
                { value: "new", label: "New" },
                { value: "inprogress", label: "In progress" },
                { value: "completed", label: "Completed" },
                { value: "cancelled", label: "Deleted" }
            ];
        },
        []
    );

    const onCancelStatusChange = useCallback(
        () => {
            onToggle(false);
            //setIsOpen(false);
        },
        [ onToggle ]
    );

    const onSelectClick = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
        },
        []
    );

    const setConsultationStatus = useCallback(
        async (status) => {
            setChangingStatus(true);
            await consActions.setStatus(consultation, status);
            setChangingStatus(false);
            onStatusChange && onStatusChange(consultation);
            onCancelStatusChange();
        },
        // eslint-disable-next-line
        [ consultation, onStatusChange, onCancelStatusChange ]
    );

    /*const cancelConsultation = useCallback(
        async () => {
        
            modal.confirm({
                title: "Are you sure?",
                content: "Are you sure you want to delete the styling session?",
                closable: true,
                cancelButtonProps: { type: "text" },
                icon: null,
                onOk: async () => {
                    await consActions.cancel(consultation);
                    onStatusChange && onStatusChange(consultation);
                }
            });

            onCancelStatusChange();
        },
        [ consultation, onStatusChange, onCancelStatusChange, modal ]
    );*/

    const onStatusSelect = useCallback(
        (newStatus) => {
            
            if (consultation.status === newStatus) {
                onCancelStatusChange();
                return;
            }
        
            if (newStatus === "cancelRequest" || newStatus === "cancelled") {
                onDeleteClick();
            }
            else if (newStatus === "new" || newStatus === "inprogress" || newStatus === "completed") {
                setConsultationStatus(newStatus);
            }
        },
        [ consultation, setConsultationStatus, 
            onCancelStatusChange, onDeleteClick ]
    );

    return (
        <Select 
            className="consultation-status-select"
            onClick={ onSelectClick }
            variant="borderless"
            value={ consultation.status }
            onChange={ onStatusSelect }
            options={ statusMenu }
            loading={ changingStatus }
            //onDropdownVisibleChange={ setIsOpen }
            popupMatchSelectWidth={ false }
            suffixIcon={ null }
            { ...rest }/>
    );
}

export default ConsultationStatusSelect