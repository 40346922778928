import { Avatar } from "antd"
import s3url from "common/src/lib/image/s3url"


function UserAvatar({ user, imageSize = 80, ...props }) {

    let src = null, alt = null;

    if (user) {

        if (user.avatar) {
            src = s3url(user.avatar, imageSize);
        }
        else {
            if (user.givenName && user.familyName) {
                alt = user.givenName[0].toUpperCase() + user.familyName[0].toUpperCase();
            }
        }
    }

    return (
        <Avatar src={ src } children={ alt } { ...props }/>
    )
}

export default UserAvatar