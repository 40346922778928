import { useCallback } from "react"

function useSwallowEventCallback(fn, deps, options = {}) {
    return useCallback(
        function(e) {
            const { preventDefault = true, stopPropagation = true } = options;
            e && preventDefault && e.preventDefault();
            e && stopPropagation && e.stopPropagation();
            return fn.apply(null, Array.from(arguments).slice(1));
        },
        deps
    );
}

export default useSwallowEventCallback