import { Drawer, Flex, Typography, App as AntApp } from "antd"
import { useCallback, useMemo, useState } from "react"
import { useSelector } from "react-redux"

import ProductCard from "./Card"

import ProductActions from "app/components/product/Actions"
import ConnectionSelector from "common/src/refactor/components/dialog/ConnectionSelector"

import { ui } from "common/src/store/dialogs"
import store from "app/store"
import app from "app/appContext"
import useOnAppEvent from "common/src/refactor/hooks/useOnAppEvent"
import { LinkSourceContextProvider } from "common/src/refactor/lib/LinkSourceContext"

export const DIALOG_NAME = "catalogue-product-drawer";

const retailerProps = { buttonSize: "large"};

function CatalogueProductDrawerContent({ product }) {

    const { message } = AntApp.useApp();
    const [ link, setLink ] = useState("");

    const imageProps = useMemo(
        () => {
            return {
                indicator: "auto",
            }
        },
        []
    );

    const copyableProps = useMemo(
        () => {
            return { 
                text: link, 
                onCopy: () => {
                    message.success({
                        content: "Your link was copied to clipboard",
                        icon: <></>
                    });
                }, 
                tooltips: ['Copy', ''] 
            }
        },
        [ link ]
    );

    return (
        <Flex vertical gap="1.5rem" className="catalogue-product-drawer-content">
            <ProductActions 
                product={ product } 
                onTrackableLinkCreated={ setLink }
                connectionSelectorName="product-details-connection-selector"/>
            { link && <Typography.Paragraph 
                        className="catalogue-product-drawer-link" 
                        copyable={ copyableProps } 
                        children={ <span>{ link }</span> }/> }
            <ProductCard 
                product={ product } 
                fluid 
                size="large"
                slider={ imageProps }
                retailer={ retailerProps }/>
            
            <div>
                <h3 className="catalogue-product-drawer-name">{ product.name }</h3>
                <p className="catalogue-product-drawer-description">{ product.description }</p>
            </div>
        </Flex>
    )
}

function CatalogueProductDrawer({ name = DIALOG_NAME }) {

    const [ options, setOptions ] = useState({});
    const open = useSelector(s => s.dialogs[name] || false);
    const onClose = useCallback(() => CatalogueProductDrawer.hide(name), [ name ]);
    const { product, source } = options;

    useOnAppEvent(`app/${ name }/options`, setOptions);

    return (
        <Drawer
            className="tf-drawer catalogue-product-drawer"
            title="Product Details"
            destroyOnClose
            open={ open }
            onClose={ onClose }>
            <LinkSourceContextProvider type={ source?.type } id={ source?.id }>
            { product && <CatalogueProductDrawerContent { ...options }/> }
            { product && <ConnectionSelector name="product-details-connection-selector"/> }
            </LinkSourceContextProvider>
        </Drawer>
    )
}

CatalogueProductDrawer.show = (options = {}, name = DIALOG_NAME) => {
    app.trigger(`app/${ name }/options`, options);
    store.dispatch(ui.show(name));
}

CatalogueProductDrawer.hide = (name = DIALOG_NAME) => {
    store.dispatch(ui.hide(name));
}

export default CatalogueProductDrawer