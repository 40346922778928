import hub from "common/src/hub"
import { subscribe, unsubscribe } from "common/src/actions/notifications"

const onAppAuth = (state) => {

    if (state) {
        subscribe();
    }
    else {
        unsubscribe();
    }
}

const listeners = {
    init: () => {
        hub.listen("app-auth", "stateChange", onAppAuth);
    }
}

export default listeners