import { useMemo } from "react"
import formatCurrency from "common/src/lib/format/currency"
import { ReactComponent as IconInfo } from "common/src/svg/info-reversed.svg"
import { Dropdown } from "antd"

function InfoBadge({ type, children }) {

    const menu = useMemo(
        () => {
            const items = [
                type === "exclusion" ? {
                    key: "text",
                    label: "This item is excluded from the retailer's pre-agreed commission rates."
                } : null,
                type === "sale" ? {
                    key: "text",
                    label: "This sale item is excluded from the retailer's pre-agreed commission rates."
                } : null,
                !type ? {
                    key: "text",
                    label: "The retailer has pre-agreed this commission rate. Commission rates are subject to change."
                } : null
            ].filter(m => !!m);

            return { items };
        },
        [ type ]
    );

    return (
        <Dropdown menu={ menu } trigger="hover" rootClassName="commission-badge">
            { children }
        </Dropdown>
    )
}

function Footer({ product, addNameSpacing = true, showCommission = true }) {

    const designer = useMemo(
        () => {
            if (product.designers) {
                return product.designers.map(d => d.name).join(", ");
            }
            else if (product.brand) {
                return product.brand;
            }
            else return null;
        },
        [ product.designers ] 
    );

    const { price, salePrice } = useMemo(
        () => {
            if (!product.price) {
                return {};
            }

            const price = formatCurrency(product.price, product.currency);
            let salePrice = product.sale_price || product.salePrice;
            salePrice = salePrice && product.price !== salePrice ? 
                                formatCurrency(salePrice, product.currency) : 
                                null;

            return { price, salePrice };
        },
        [ product ]
    );

    const commission = useMemo(
        () => {
            if (product.retailer === "net-a-porter" && salePrice) {
                return "Sale exclusion";
            }
            if (!!product.commissionRate && 
                !product.commissionExclusion && 
                !product.commission_exclusion) {
                return `Up to ${ product.commissionRate }%`;
            }
            else {
                return "Exclusion";
            }
        },
        [ product, salePrice ]
    );

    const badgeType = useMemo(
        () => {
            if (product.retailer === "net-a-porter" && salePrice) {
                return "sale";
            }
            if (!!product.commissionRate && 
                !product.commissionExclusion && 
                !product.commission_exclusion) {
                return null;
            }
            else {
                return "exclusion";
            }
        },
        [ product, salePrice ]
    )

    return (
        <div className="catalogue-product-footer">
            <p className="catalogue-product-footer-designer">{ designer }</p>
            <p className="catalogue-product-footer-name">
                { product.name || product.description }
                { addNameSpacing && <><br/><br/></> }
            </p>
            { (price || commission) && 
                <div className="catalogue-product-footer-prices">
                    { price && 
                        <p className="catalogue-product-footer-price">
                            { salePrice && <span className="catalogue-product-footer-sale-price">{ salePrice }</span> }
                            <span>{ price }</span>
                        </p> }
                    { (showCommission && commission) && 
                        <InfoBadge type={ badgeType }>
                            <p className="catalogue-product-footer-commission">
                                { commission }
                                <IconInfo/>
                            </p>
                        </InfoBadge> }
                </div> }
        </div>
    )

}

export default Footer