
export default function (moodboard) {

    if (!moodboard.__normalized) {

        if (moodboard.hideMoodboards && moodboard.hideMoodboards.length > 0) {
            moodboard.deleted = true;
        }

        if (moodboard.catalogueProducts_aggregate) {
            moodboard.productsCount = moodboard.catalogueProducts_aggregate.aggregate.count;
            delete moodboard.catalogueProducts_aggregate;
        }
        else {
            moodboard.productsCount = 0;
        }

        if (moodboard.catalogueProducts) {
            moodboard.images = moodboard.catalogueProducts.map(p => {
                const images = JSON.parse(p.catalogueProduct.images);
                return images[0];
            });
        }
        else {
            moodboard.images = [];
        }

        if (moodboard.image) {
            moodboard.image = JSON.parse(moodboard.image);
        }
    }

    moodboard.__normalized = true;
    return moodboard;
}