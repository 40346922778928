
import { batch } from "react-redux"
import singlePromise from "common/src/lib/js/singlePromise"
import { data, ui } from "app/store/page/looks"
import store from "app/store"
import api from "app/api"
import hub from "common/src/hub"
import settings from "app/settings"
import user from "common/src/user"
import { lookLoader } from "common/src/actions/looks"
import uuidReg from "common/src/lib/uuidReg"
import unaccent from "common/src/lib/unaccent"


export const PER_PAGE = settings.looksPerPage;



const applyTagFilters = (where, filters) => {
    let key, ts;
    
    for (key in filters) {

        ts = filters[key];
        if (ts && ts.length) {
            switch (key) {
                case "designer": {
                    (!where.products && (where.products = {}));
                    where.products.designers = {
                        designerId: { _in: ts.map(t => t.id) }
                    }
                    break;
                }
                case "productStyle": {
                    (!where.products && (where.products = {}));
                    where.products.productStyles = {
                        productStyleId: { _in: ts.map(t => t.id) }
                    }
                    break;
                }

                case "retailer": {
                    (!where.products && (where.products = {}));
                    where.products.retailer = { _in: ts.map(t => t.id) }
                    break;
                }

                case "style": {
                    where.styles = { styleId: { _in: ts.map(t => t.id) }}; 
                    break;
                }
                case "season": {
                    where.seasons = { seasonId: { _in: ts.map(t => t.id) }};
                    break;
                }
                case "occasion": {
                    where.occasions = { occasionId: { _in: ts.map(t => t.id) }};
                    break;
                }
                case "location": {
                    where.locations = { locationId: { _in: ts.map(t => t.id) }};
                    break;
                }
                case "hiddenTag": {
                    where.hiddenTags = { hiddenTagId: { _in: ts.map(t => t.id) }};
                    break;
                }
                case "user": {
                    where.friId = { _in: ts.map(t => t.id) };
                    break;
                }
                default: {}
            }
        }
    }
}

const getFilterTags = (filterTags) => {
    let tags = [];
    for (let k in filterTags) {
        tags = tags.concat(filterTags[k]);
    }
    return tags;
}

export function reloadList() {
    let state = store.getState(),
        page = state.looksPage.ui.list.page;
    return loadList(page, true);
}

export let loadList = singlePromise({
    mode: singlePromise.ONE_CANCEL,
    pre: (page, force) => {
        let state = store.getState(),
            currPage = state.looksPage.ui.list.page,
            setup = {
                page: page
            };

        if (state.looksPage.data.list.length > 0 && 
            currPage === page) {
            if (!force) {
                setup.value = {
                    items: state.looksPage.data.list,
                    count: state.looksPage.ui.count
                };
                setup.skip = true;
            }
        }
        return setup;
    },
    fn: async (page) => {
        store.dispatch(ui.list.loading(true));

        let     lookFields = `  published publishedAt 
                                submitted declinedBy 
                                submittedAt
                                submissionStage deleted `;
        const   state = store.getState(),
                filter = state.looksPage.ui.list.filter,
                //params = state.looksPage.ui.list.params,
                search = unaccent(state.looksPage.ui.list.search || "").trim(),
                cleanQuery = search.replace(/\s+/, " ")
                                .replace(/[^a-zA-Z0-9 ]/gi, "")
                                .trim().toLowerCase(),
                where = {
                    consultationId: { _is_null: true },
                    deleted: { _eq: false }
                },
                order = {},
                perPage = state.looksPage.data.perPage || PER_PAGE,
                filterTags = filter && filter.tags ? getFilterTags(filter.tags) : [];
                //tagFilters = await extractFilterFromUrl();

        (filter && filter.tags) && applyTagFilters(where, filter.tags);

        const status = filter ? filter.status || "published" : "published";

        if (user.is("Admin")) {
            switch (status) {
                case "published": 
                default: {
                    where.published = true;
                    order.ordering = { orderLatest: "desc" };
                    break;
                }
                case "all": {
                    where.submissionStage = { _nin: ["recovery", "hidden"] };
                    where.deleted = false;
                    where.submitted = true;
                    order.createdAt = "desc";
                    break;
                }
                case "awaiting": {
                    where.published = false;
                    where.submitted = true;
                    where._or = [
                        { submissionStage: { _is_null: true }},
                        { submissionStage: { _eq: "submitted" }}
                    ];
                    order.createdAt = "desc";
                    break;
                }
                case "recovery": {
                    where.submissionStage = "recovery";
                    order.createdAt = "desc";
                    break;
                }
                case "crashed": {
                    where.submissionStage = "crashed";
                    order.createdAt = "desc";
                    break;
                }
                case "hidden": {
                    where.published = false;
                    where.submitted = true;
                    where.submissionStage = "hidden";
                    order.createdAt = "asc";
                    break;
                }
                case "final": {
                    where.published = false;
                    where.submitted = true;
                    where.submissionStage = "final";
                    order.createdAt = "asc";
                    break;
                }
                case "declined": {
                    where.friId = user.id();
                    where.published = false;
                    where.submitted = false;
                    where.declinedBy = {_is_null: false};
                    order.createdAt = "asc";
                    break;
                }
                case "picked": {
                    where.picked = {};
                    order.ordering = { orderPicked: "desc" };
                    break;
                }
                case "deleted": {
                    where.published = false;
                    where.deleted = true;
                    order.createdAt = "desc";
                    break;
                }
                case "list": {
                    where.listId = { _eq: filter.listId };
                    order.createdAt = "desc";
                    break;
                }
                case "my": {
                    where.friId = user.id();
                    order.createdAt = "desc";
                    break;
                }
                case "my-published": {
                    where.friId = user.id();
                    where.published = true;
                    order.ordering = { orderPicked: "desc" };
                    break;
                }
                case "my-submitted": {
                    where.friId = user.id();
                    where.published = false;
                    where.submitted = true;
                    order.createdAt = "desc";
                    break;
                }
                case "private": {
                    where.friId = user.id();
                    where.published = false;
                    where.submitted = false;
                    order.createdAt = "desc";
                    break;
                }
            }
        }
        else {
            where.friId = { _eq: user.id() };
            switch (status) {
                case "my": 
                default: {
                    order.createdAt = "desc";
                    break;
                }
                case "recovery": {
                    where.submissionStage = "recovery";
                    order.createdAt = "desc";
                    break;
                }
                case "crashed": {
                    where.submissionStage = "crashed";
                    order.createdAt = "desc";
                    break;
                }
                case "published": {
                    where.published = true;
                    order.ordering = { orderLatest: "desc" };
                    break;
                }
                case "private": {
                    where.published = false;
                    where.submitted = false;
                    order.createdAt = "desc";
                    break;
                }
                case "awaiting": {
                    where.published = false;
                    where.submitted = true;
                    order.createdAt = "desc";
                    break;
                }
                case "declined": {
                    where.published = false;
                    where.submitted = false;
                    where.declinedBy = {_is_null: false};
                    order.createdAt = "desc";
                    break;
                }
            }
        }

        if (search) {
            if (search.match(uuidReg)) {
                where.id = search;
            }
            else {
                const ids = await api.unauth.post(
                                "/search/looks", { body: { query: cleanQuery } }
                            )
                            .then(response => response.looks);
                where.id = { _in: ids };
            }
        }

        const loaderOptions = {
            where, order,
            offset: page * perPage,
            limit: perPage,
            withCount: true,
            withLookTags: true,
            withProductStock: true,
            lookFields,
            listId: status === "list" ? (filter ? filter.listId : null) : null
        };

        if (filterTags.length === 1 && status !== "list") {
            loaderOptions['tagId'] = filterTags[0].id;
            loaderOptions['tagType'] = filterTags[0].__typename;
        }
        
        return lookLoader(loaderOptions);
    },
    resolve: (response, setup, args) => {
        batch(() => {
            store.dispatch(data.list.set(response.items));
            store.dispatch(ui.list.count(response.count));
            store.dispatch(ui.list.page(setup.page));
        });
    },
    reject: err => {
        hub.dispatch("error", "look-load-list", err);
    },
    always: () => {
        store.dispatch(ui.list.loading(false));
    }
})


export const pickLook = function(id) {
    return api.pickedLook.create({ lookId: id, friId: user.id() })
            .then(resp => {
                hub.dispatch("look", "picked", { id });
                return resp;
            })
            .catch(err => {
                hub.dispatch("error", "pick-look", err)
            });
}


export const unpickLook = function(id) {
    return api.pickedLook.remove({ lookId: {_eq: id}})
            .then(resp => {
                hub.dispatch("look", "unpicked", { id });
                return resp;
            })
            .catch(err => {
                hub.dispatch("error", "unpick-look", err)
            });
}

export const setSubmissionStage = function(id, stage) {
    return api.look.update(id, { submissionStage: stage })
            .then(() => {
                hub.dispatch("look", "submission-stage", { id, stage });
            });
};

export const setDeleted = function(ids) {
    const returnSingle = !Array.isArray(ids);
    ids = Array.isArray(ids) ? ids : [ ids ];
    const promises = ids.map(id => api.look.update(id, { deleted: true, published: false }));
    return Promise.all(promises)
        .then(values => {
            hub.dispatch("look", "deleted", { id: ids });
            return returnSingle ? values[0] : values;
        })
        .catch(err => {
            hub.dispatch("error", "look-delete", err)
        });
}

export const setHidden = function(ids) {
    const returnSingle = !Array.isArray(ids);
    ids = Array.isArray(ids) ? ids : [ ids ];
    const promises = ids.map(id => api.look.update(id, { submissionStage: "hidden", published: false }));
    return Promise.all(promises)
        .then(values => {
            hub.dispatch("look", "submission-stage", { id: ids });
            return returnSingle ? values[0] : values;
        })
        .catch(err => {
            hub.dispatch("error", "submission-stage", err)
        });
};


export const publishLook = function(ids) {
    const returnSingle = !Array.isArray(ids);
    ids = Array.isArray(ids) ? ids : [ ids ];
    const promises = ids.map(id => api.look.update(id, { 
                                        published: true, 
                                        publishedAt: (new Date()).toISOString() }));
    return Promise.all(promises)
        .then(values => {
            hub.dispatch("look", "published", { id: ids });
            return returnSingle ? values[0] : values;
        })
        .catch(err => {
            hub.dispatch("error", "publish-look", err)
        });
};


export const unpublishLook = function(ids) {
    const returnSingle = !Array.isArray(ids);
    ids = Array.isArray(ids) ? ids : [ ids ];
    const promises = ids.map(id => api.look.update(id, { published: false, publishedAt: null }))
    return Promise.all(promises)
        .then(values => {
            hub.dispatch("look", "unpublished", { id: ids });
            return returnSingle ? values[0] : values;
        })
        .catch(err => {
            hub.dispatch("error", "unpublish-look", err)
        });
};


export const moveLook = async function(id, beforeId, list, listId, tagType) {

    return api.backend.post("/look/ordering/shift", {
        body: { id, beforeId, list, listId, tagType }
    })
    .then(() => {
        hub.dispatch("look", "shifted", { id, beforeId, list, listId, tagType })
    })
    .catch(err => {
        hub.dispatch("error", "shift-look", err);
    });
};


export const addLookToList = async function(ids, listId) {
    const returnSingle = !Array.isArray(ids);
    ids = Array.isArray(ids) ? ids : [ ids ];
    const promises = ids.map(id => api.look.update(id, { listId }));
    return Promise.all(promises)
        .then(values => {
            hub.dispatch("look", "updated", { id: ids });
            return returnSingle ? values[0] : values;
        })
        .catch(err => {
            hub.dispatch("error", "update-look", err)
        });
}
