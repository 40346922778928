import { useEffect, useState } from "react"
import { getRetailers } from "common/src/lib/catalogue/retailers"


function useRetailers() {

    const [ retailers, setRetailers ] = useState([]);

    useEffect(
        () => {
            getRetailers().then(setRetailers);
        },
        []
    );

    return retailers;
}

export default useRetailers