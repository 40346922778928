
//import api from "app/api"

export default function restMultiple(items, path, prepareFn, apiFn) {

    let res = [],
        promises = [];

    items.forEach((item, inx) => {
        let data = prepareFn(item, inx);
        data && promises.push(apiFn(path, data)
                        .then(resp => res[inx] = resp));
    });

    return Promise.all(promises).then(() => res);
}