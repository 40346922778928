import { useMemo } from "react"

import Button from "common/src/refactor/components/button/Button"
import { ReactComponent as IconExport } from "common/src/svg/open_in.svg"

import getRetailerName from "common/src/lib/look/url2retailer"
import useCatalogueProductUrl from "common/src/refactor/hooks/useCatalogueProductUrl"

function Retailer({ product, buttonSize = "small" }) {

    const productUrl = useCatalogueProductUrl(product);

    const retailer = useMemo(
        () => getRetailerName(product.retailer || product.url),
        [ product.retailer ]
    );

    const cls = useMemo(
        () => {
            //const cls = ["catalogue-product-card-retailer"];
            const cls = [];
            if (retailer?.length > 8) {
                cls.push("len-8");
            }
            if (retailer?.length > 16) {
                cls.push("len-16");
            }
            return cls;//.join(" ");
        },
        [ retailer ]
    );

    return (
        <Button 
            className={ cls }
            type="primary"
            size={ buttonSize }
            Component="a"
            target="_blank"
            rel="noreferrer"
            iconPosition="after"
            href={ productUrl }
            text={ retailer }
            Icon={ IconExport }/>
    )
}

export default Retailer