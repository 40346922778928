import UserAvatar from "../UserAvatar";
import Radio from "../form/Radio";
import { useCallback } from "react";

function ListItem({ c, onConnectionPress }) {
    
    const isSelected = c.isSelected || false;
    const onPress = useCallback(
        () => onConnectionPress(c),
        [ c, onConnectionPress ]
    );

    return (
        <li className="connection-selector-list-item" onClick={ onPress }>
            <div>
                <UserAvatar user={ c }/>
                { c.givenName } { c.familyName }
            </div>
            <Radio size="small" checked={ isSelected } />
        </li>
    )
}

export default ListItem;