
export default (name) => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
        const match = document.cookie.match(new RegExp(`${name}=([^;]+)`));
        if (match) {
            return window.decodeURIComponent(match[1]);
        }
        return null;
    }
    return null;
};