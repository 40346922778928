import api from "app/api"

let exchangeRates = null;


const getExchangeRates = async () => {

    if (!exchangeRates) {
        const rates = await api.exchangeRate.list();    
        exchangeRates = {};
        rates.forEach(row => {
            if (!exchangeRates[ row.from.toLowerCase() ]) {
                exchangeRates[ row.from.toLowerCase() ] = {};
            }
            if (!exchangeRates[ row.from.toLowerCase() ][ row.to.toLowerCase() ]) {
                exchangeRates[ row.from.toLowerCase() ][ row.to.toLowerCase() ] = row.rate;
            }
        })
    }
    
    return exchangeRates;
};

export default getExchangeRates;