import { useState, useEffect } from "react"

import preload from "common/src/lib/image/preload"
import useDictRef from "common/src/hooks/useDictRef"

function useImageLoading(src) {

    const [ loading, setLoading ] = useState(false);
    const [ preloaded, setPreloaded ] = useState(false);
    const ref = useDictRef({ preloaded, loading });


    useEffect(
        () => {
            if (!src) {
                return;
            }

            preload([ src ], false)
                .then(() => {
                    ref.preloaded = true;
                    setPreloaded(true);
                    setLoading(false);
                });

            setTimeout(
                () => {
                    if (!ref.preloaded) {
                        setLoading(true);
                    }
                },
                20
            );
        },
        []
    );

    return loading;
}

export default useImageLoading