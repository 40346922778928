import { useMemo } from "react"
import { Dropdown } from "antd"

import Button from "common/src/refactor/components/button/Button"

import { ReactComponent as IconEdit } from "common/src/svg/edit.svg"
import { ReactComponent as IconDelete } from "common/src/svg/delete.svg"
import { ReactComponent as IconSend } from "common/src/svg/send.svg"
import { ReactComponent as IconShare } from "common/src/svg/share.svg"
import { ReactComponent as IconDots } from "common/src/svg/dots.svg"
import { ReactComponent as IconDuplicate } from "common/src/svg/duplicate.svg"

import useMoodboardActions from "app/hooks/moodboard/useMoodboardActions"

function MoodboardContextMenu({
    moodboard,
    menu: replaceMenu,
    extraMenuItems,
    showEdit = true,
    showSend = true,
    showDelete = true,
    showDuplicate = true,
    showShare = true,
    onDelete, 
    onEdit,
    onDuplicate,
    ...rest
}) {

    const { 
        onDeleteClick, onEditClick, 
        onSendClick, 
        onDuplicateClick, onShareLinkClick,
        loading
    } = useMoodboardActions({ moodboard, onEdit, onDelete, onDuplicate });

    const menu = useMemo(
        () => {
            if (replaceMenu) {
                return replaceMenu;
            }

            const items = [
                (showSend && onSendClick) ? {
                    key: "send",
                    icon: <IconSend/>,
                    label: "Send",
                    onClick: onSendClick
                } : null,
                showShare ? {
                    key: "share",
                    icon: <IconShare/>,
                    label: "Share a Moodboard",
                    onClick: onShareLinkClick
                } : null,
                (showEdit && onEditClick) ? {
                    key: "edit",
                    icon: <IconEdit/>,
                    label: "Edit",
                    onClick: onEditClick
                } : null,
                (showDuplicate) ? {
                    key: "duplicate",
                    icon: <IconDuplicate/>,
                    label: "Duplicate",
                    onClick: onDuplicateClick,
                } : null,
                (showDelete && onDeleteClick) ? {
                    key: "delete",
                    icon: <IconDelete/>,
                    label: "Delete",
                    onClick: onDeleteClick
                } : null,

                ...(extraMenuItems || [])
            ].filter(m => !!m);

            return { items };
        },
        [ extraMenuItems, replaceMenu, showDelete, showEdit, showSend, onDeleteClick, onEditClick, onSendClick, showDuplicate, onDuplicateClick, showShare, onShareLinkClick ]
    )

    if (menu.length === 0) {
        return null;
    }

    return (
        <Dropdown menu={ menu } trigger="click" rootClassName="context-menu">
            <Button
                className="context-button"
                type="primary"
                shape="circle"
                size="small"
                Icon={ IconDots }
                loading={ loading }
                { ...rest }
            />
        </Dropdown>
    )

}

export default MoodboardContextMenu