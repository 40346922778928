
import { data as lookData, sortProducts } from "common/src/store/look/editor"
import { useCallback, useContext, useMemo } from "react";
import { useSelector, batch, useDispatch } from "react-redux"

import { ReactComponent as IconDelete } from "common/src/svg/close.svg"
import { ReactComponent as IconDrag } from "common/src/svg/drag.svg"
import { ReactComponent as IconAdd } from "common/src/svg/add.svg"
import { ReactComponent as IconInfo } from "common/src/svg/info-reversed.svg"

import CatalogueProductDrawer from "common/src/refactor/components/catalogue/product/Drawer"
import LookBuilderSearch from "./Search"
import ProductCard from "common/src/refactor/components/catalogue/product/Card"
import Dnd from "common/src/refactor/components/Dnd"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import Button from "common/src/refactor/components/button/Button"
import LookBuilderContext from "app/components/builder/LookBuilderContext"
import useKey from "common/src/refactor/hooks/useKey";
import { Dropdown } from "antd";

// function AddProductItem() {

//     const builder = useContext(LookBuilderContext);

//     const onAddClick = useCallback(
//         (e) => {
//             e.stopPropagation();
//             //builder.set("activeSlot", 0);
//             builder.openPage("catalogue");
//             builder.set("currentModal", LookBuilderSearch.NAME);
//         },
//         [ builder]
//     );

//     return (
//         <div className="look-builder-products-add">
//             <Button
//                 Icon={ IconAdd }
//                 shape="circle"
//                 type="primary"
//                 size="medium"
//                 onClick={ onAddClick }/>
//         </div>
//     )
// }

function ProductItem({ product }) {

    const dispatch = useDispatch();
    const look = useSelector(s => s.lookEditor.look);
    const slotIndex = look?.layouts.order[product.id] || 0;
    const listIndex = look?.products.findIndex(p => p.id === product.id);

    const onProductClick = useCallback(
        (p) => CatalogueProductDrawer.show({ 
            product: p,
            source: {
                type: "look-product",
                id: p.id
            }
        }), 
        []
    );

    const moveProduct = useCallback(
        (pid, inx) => {
            dispatch(lookData.products.move({
                id: pid, toInx: inx
            }));
        },
        [ dispatch ]
    );

    const cardAttrs = useMemo(
        () => {
            return {
                id: `look-builder-product-${ product.id }`,
                droppable: {
                    data: { 
                        id: product.id, 
                        slotIndex: slotIndex,
                        listIndex: listIndex
                    },
                    cardSelector: ".look-builder-catalogue-products-item",
                    drop: (to, what) => moveProduct(what.id, to.listIndex)
                }
            }
        },
        [ product, slotIndex, listIndex, moveProduct ]
    );

    const moveAttrs = useMemo(
        () => {
            
            return {
                draggable: {
                    imageElId: `look-builder-product-${ product.id }`,
                    id: product.id,
                    slotIndex: slotIndex,
                    listIndex: listIndex,
                    origin: "look-card",
                    data: {
                        id: product.id,
                        slotIndex: slotIndex,
                        listIndex: listIndex
                    }
                }
            }
        
        },
        [ product, slotIndex, listIndex ]
    );

    const onDeleteClick = useSwallowEventCallback(
        () => {
            batch(() => {
                dispatch(lookData.products.removeFromLayout(product.id));
                dispatch(lookData.products.remove(product.id));
            })
        },
        [ product ]
    );

    return (
        <ProductCard 
            className="look-builder-catalogue-products-item"
            product={ product } 
            attrs={ cardAttrs }
            footer={{ product }}
            onClick={ onProductClick }>
            <Dnd Component="a" className="look-card-product-drag" { ...moveAttrs }>
                <IconDrag/>
            </Dnd>
            <a href="/#" 
                className="look-card-product-delete"
                onClick={ onDeleteClick }>
                <IconDelete/>
            </a>
        </ProductCard>
    )
}

function Products() {

    const look = useSelector(s => s.lookEditor.look);
    const builder = useContext(LookBuilderContext);
    const currentModal = useKey("currentModal", builder);

    const onAddClick = useCallback(
        (e) => {
            e.stopPropagation();
            //builder.set("activeSlot", 0);
            builder.openPage("catalogue");
            builder.set("currentModal", LookBuilderSearch.NAME);
        },
        [ builder]
    );

    const tooltip = useMemo(
        () => {
            const items = [
                {
                    key: "text",
                    label: "To re-order the products drag and drop using the lines on the top left corner of the product cards"
                }
            ];

            return { items };
        },
        [ ]
    )

    const products = useMemo(
        () => {
            if (!look || !look.products) {
                return [];
            }
            return [].concat(look.products).sort(sortProducts)
        },
        [ look ]
    );

    if (currentModal) {
        return null;
    }

    return (
        <div className="look-builder-products-wrapper">
            <div className="look-builder-products-title">
                <h3>{ look.productMode === "tagged" ? "Tagged products" : "Added products" }</h3>
                <p>
                    { products.length } { products.length === 1 ? "Product" : "Products" } 
                    <Dropdown menu={ tooltip } trigger="hover" rootClassName="look-builder-products-tip">
                        <IconInfo/>
                    </Dropdown>
                </p>
            </div>
            <div className="look-builder-products">
                { products.map(p => (
                    <ProductItem key={ p.id } product={ p }/>
                ))}
            </div>
            { (look.productMode === "tagged" && products.length === 0) && 
                <div className="look-builder-products-tagged-add">
                    <p>Tagged products will show here </p>
                    <Button
                        Icon={ IconAdd }
                        shape="circle"
                        size="large"
                        type="primary"
                        onClick={ onAddClick }/>
                </div> }
        </div>
    )
}

export default Products