import { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"

import Select from "common/src/refactor/components/ant/Select"

import formatCurrency from "common/src/lib/format/currency"
import { loadFacets } from "common/src/actions/catalogue"

export function PriceSelect({ className, ...props }) {

    const prices = useSelector(s => s.catalogue.data.prices);
    const currency = useSelector(s => s.user.geo.currency);
    const loading = useSelector(s => s.catalogue.ui.loading);

    const options = useMemo(
        () => [ { key: "all", value: "all", label: "All" }, 
                ...prices.map(p => ({
                    key: "" + p,
                    value: "" + p,
                    label: `Up to ${ formatCurrency(p, currency) }`
        })) ],
        [ currency, prices ]
    );

    const cls = useMemo(
        () => [ "selector-price", className || "" ].join(" "),
        [ className ]
    );

    useEffect(
        () => {
            loadFacets();
        },
        []
    );

    return (
        <Select 
            className={ cls }
            placeholder="Price"
            options={ options } 
            loading={ loading }
            { ...props }/>
    )
}
