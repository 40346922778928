
export const map = {
    'Š':'S', 'š':'s', 
    'Ð':'D',
    'Ž':'Z', 'ž':'z', 

    'À':'A', 'Á':'A', 'Â':'A', 'Ã':'A', 'Ä':'A','Å':'A', 'Æ':'A', 
    'â':'a', 'Ă':'A', 'ă':'a', 
    'à':'a', 'á':'a', 'ã':'a', 'ä':'a','å':'a', 'æ':'a',

    'Ç':'C', 
    'È':'E', 'É':'E', 'Ê':'E', 'Ë':'E', 
    'Ì':'I', 'Í':'I', 'Î':'I', 'Ï':'I', 'ì':'i', 'í':'i', 'î':'i', 'ï':'i', 
    'Ñ':'N', 'Ń':'N', 
    'Ò':'O', 'Ó':'O', 'Ô':'O', 'Õ':'O', 'Ö':'O', 'Ø':'O', 
    'Ù':'U', 'Ú':'U', 'Û':'U', 'Ü':'U', 
    'Ý':'Y', 'ý':'y', 'þ':'b', 'ÿ':'y', 
    'Þ':'B', 
    'ß':'Ss',
    'ç':'c', 
    'è':'e', 'é':'e', 'ê':'e', 'ë':'e', 
    
    'ð':'o', 
    'ñ':'n', 'ń':'n', 
    'ò':'o', 'ó':'o', 'ô':'o', 'õ':'o', 'ö':'o', 'ø':'o', 
    'ù':'u', 'ú':'u', 'û':'u', 'ü':'u', 
    'ƒ':'f',
    'ș':'s', 
    'ț':'t', 'Ț':'T',
    'Ș':'S',   
};

let accReg;

const prepareReg = () => {
    let k, str = "";
    for (k in map) {
        str += k;
    }
    accReg = new RegExp(`[${str}]`, 'g');
};

const replacer = (match, char) => {
    return map[match] || match;
}

export default (str) => {
    !accReg && prepareReg();
    return str.replace(accReg, replacer);
};