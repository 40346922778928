import { useCallback, useEffect, useRef } from "react"

function useDomEvent(node, event, fn) {

    const fnRef = useRef(null);
    fnRef.current = fn;
    const cb = useCallback(
        (e) => fnRef.current && fnRef.current(e),
        []
    );

    useEffect(
        () => {
            node.addEventListener(event, cb);
            return () => {
                node.removeEventListener(event, cb) ;
            }
        },
        []
    );

}

export default useDomEvent