//import createActionMap from "common/src/lib/store/createActionMap.js";
//import { createReducer } from "@reduxjs/toolkit"

export const DEFAULT_PKEY = "id";

function _find(list, item, pkey = DEFAULT_PKEY) {
    if (!item) {
        return -1;
    }
    if (typeof item === "object") {
        let id = item[pkey];
        return list.findIndex(i => typeof i === "object" ? i[pkey] === id : i === id);
    }
    return list.findIndex(i => typeof i === "object" ? i[pkey] === item : i === item);
}

function _add(list, item, method, pkey = DEFAULT_PKEY) {
    const inx = _find(list, item, pkey);
    if (inx === -1) {
        list[method](item);
    }
}

function _addOrReplace(list, item, method, pkey = DEFAULT_PKEY) {
    const inx = _find(list, item, pkey);
    if (inx === -1) {
        list[method](item);
    }
    else {
        list[inx] = item;
    }
}

export function merge(list, items, pkey = DEFAULT_PKEY) {
    items.forEach(item => _addOrReplace(list, item, "push", pkey));
}

export function push(list, item, pkey = DEFAULT_PKEY) {
    _add(list, item, "push", pkey);
}

export function unshift(list, item, pkey = DEFAULT_PKEY) {
    _add(list, item, "unshift", pkey);
}

export function append(list, items, pkey = DEFAULT_PKEY) {
    items.forEach(item => _add(list, item, "push", pkey));
}

export function set(list, items, deleted, pkey = DEFAULT_PKEY) {
    if (deleted) {
        list.forEach(i => {
            if (_find(items, i, pkey) === -1) {
                remove(list, i.id, deleted, pkey);
            } 
        });
    }
    items.forEach(i => push(list, i, pkey));
}

export function remove(list, id, deleted, pkey = DEFAULT_PKEY) {
    let inx = _find(list, id, pkey),
        prev;

    if (inx !== -1) {
        prev = list[inx];
        list.splice(inx, 1);
        deleted && deleted.push(prev);
    }
}

export function replace(list, item, pkey = DEFAULT_PKEY) {
    let inx = _find(list, item, pkey);
    if (inx !== -1) {
        list[inx] = item;
    }
}

export function update(list, item, pkey = DEFAULT_PKEY) {
    let inx = _find(list, item, pkey);
    if (inx !== -1) {
        Object.assign(list[inx], item);
    }
}

export function updateField(list, item, field, value, pkey = DEFAULT_PKEY) {
    list.forEach(i => {
        if ((typeof item === "string" && i[pkey] === item) || i === item) {
            i[field] = value;
        }
    })
}

export function move(list, { item, before }, pkey = DEFAULT_PKEY) {
    
    const inx = _find(list, item, pkey);
    let beforeInx = _find(list, before, pkey);
    if (inx === -1 || beforeInx === -1) {
        return;
    }
    item = list[inx];
    list.splice(inx, 1);

    if (inx < beforeInx) {
        beforeInx--;
    }

    list.splice(beforeInx, 0, item);
}

export function existsInSets(sets, id) {

    let found = false;

    Object.values(sets).forEach(s => {
        if (found) return;
        if (s && s.indexOf(id) !== -1) {
            found = true;
        }
    });

    return found;
}

export function syncWithSets(list, sets, pkey = DEFAULT_PKEY) {

    const idsToRemove = [];
    list.forEach(item => {
        const id = item[pkey];
        if (!existsInSets(sets, id)) {
            idsToRemove.push(id);
        }
    });

    idsToRemove.forEach(id => remove(list, id));
}