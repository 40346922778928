import { useMemo } from "react"
import { Dropdown } from "antd"

import Button from "common/src/refactor/components/button/Button"

import { ReactComponent as IconSend } from "common/src/svg/send.svg"
import { ReactComponent as IconDots } from "common/src/svg/dots.svg"
import { ReactComponent as IconLook } from "common/src/svg/look.svg"
import { ReactComponent as IconEdit } from "common/src/svg/edit.svg"
import { ReactComponent as IconDelete } from "common/src/svg/delete.svg"
import { ReactComponent as IconDuplicate } from "common/src/svg/duplicate.svg"

import useConsultationActions from "app/hooks/consultation/useConsultationActions"

function ConsultationContextMenu({ 
    cons,
    menu: replaceMenu, 
    extraMenuItems, 
    showAddLook = true,
    showSend = true,
    showEdit = true,
    showDuplicate = true,
    showDelete = true,
    fromHref,
    onEdited,
    ...rest }) {

    const {
        onSendClick,
        onAddLookClick,
        onEditClick,
        onDuplicateClick,
        onDeleteClick,
        loading
    } = useConsultationActions({ cons, fromHref, onEdited });


    const menu = useMemo(
        () => {

            if (replaceMenu) {
                return replaceMenu;
            }

            const items = [

                (showAddLook && onAddLookClick) ? {
                    key: "look",
                    icon: <IconLook/>,
                    label: "Add a look",
                    onClick: onAddLookClick
                } : null,

                (showEdit && onEditClick) ? {
                    key: "edit",
                    icon: <IconEdit/>,
                    label: "Edit",
                    onClick: onEditClick
                } : null,

                (showDuplicate && onDuplicateClick) ? {
                    key: "duplicate",
                    icon: <IconDuplicate/>,
                    label: "Duplicate",
                    onClick: onDuplicateClick
                } : null,

                (showSend && onSendClick) ? {
                    key: "send",
                    icon: <IconSend/>,
                    label: <div className="context-menu-item-multiline">
                        <p>Send</p>
                        { (cons.hidden && cons.customerId) && <span>Unhide from client to send</span> }
                        { !cons.customerId && <span>Assign a client to send</span> }
                    </div>,
                    disabled: cons.hidden || !cons.customerId,
                    onClick: onSendClick
                } : null,

                (showDelete && onDeleteClick) ? {
                    key: "delete",
                    icon: <IconDelete/>,
                    label: "Delete",
                    onClick: onDeleteClick
                } : null,
                
                ...(extraMenuItems || [])

            ].filter(m => !!m);

            return { items };
        },
        [ cons, extraMenuItems, replaceMenu,
            onSendClick, onEditClick, onDeleteClick, onDuplicateClick, onAddLookClick,
            showSend, showEdit, showDelete, showDuplicate, showAddLook ]
    );

    if (menu.length === 0) {
        return null;
    }

    return (
        <Dropdown menu={ menu } trigger="click" rootClassName="context-menu">
            <Button 
                className="context-button"
                loading={ loading }
                type="primary" 
                shape="circle"
                size="small"
                Icon={ IconDots }
                { ...rest }/>
        </Dropdown>
    )

}

export default ConsultationContextMenu