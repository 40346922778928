export function validateInstagram(value) {
    const instagramRegex = /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_\.]+\/?$/;

    if (!value) {
        return true;
    }
    return instagramRegex.test(value);
}

export function validateX(value) {
    const xRegex = /^(https?:\/\/)?(www\.)?x\.com\/[a-zA-Z0-9_]+\/?$/;

    if (!value) {
        return true;
    }
    return xRegex.test(value);
}

export function validateFacebook(value) {
    const facebookRegex = /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9\.]+\/?$/;

    if (!value) {
        return true;
    }
    return facebookRegex.test(value);
}

export function validateWebsite(value) {
    const websiteRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}(\/\S*)?$/;
    if (!value) {
        return true;
    }
    return websiteRegex.test(value);
}