import { Drawer, Input, Checkbox } from "antd"
import { useSelector } from "react-redux"
import { useCallback, useMemo, useState } from "react"
import { v4 as uuid } from "uuid"

import Button from "../button/Button"
import NullForm from "common/src/components/NullForm"
import FormInput from "common/src/refactor/components/form/FormInput"
import Dropzone from "common/src/components/Dropzone"
//import Loader from "common/src/components/Loader"

import { ReactComponent as IconAdd } from "common/src/svg/plus.svg"
import { ReactComponent as IconEdit } from "common/src/svg/edit.svg"
import { ReactComponent as IconDelete } from "common/src/svg/delete.svg"

import readInputFile from "common/src/lib/dom/readInputFile"
import preloadImages from "common/src/lib/image/preload"
import { ui } from "common/src/store/dialogs"
import store from "app/store"
import useOnAppEvent from "common/src/refactor/hooks/useOnAppEvent"
import useStateEffect from "common/src/refactor/hooks/useStateEffect"
import { Form, useFormFields } from "common/src/refactor/lib/form/Form"
import required from "common/src/refactor/lib/form/validator/required"
import app from "app/appContext"
import user from "common/src/user"
import useDictRef from "common/src/hooks/useDictRef"
import * as actions from "common/src/actions/moodboard"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"

const DIALOG_NAME = "moodboard-form";
let editorResolve;

const fields = [
    {
        name: "name",
        validator: [
            [ required, "Please enter moodboard's name" ]
        ]
    },
    {
        name: "description",
    },
    {
        name: "isPublic",
        default: false
    },
    {
        name: "pseTemplate",
        default: false
    }
]

function MoodboardForm({ moodboard, dialogName }) {

    const form = useMemo(
        () => new Form(fields, {
            name: moodboard?.name,
            isPublic: moodboard?.public || false,
            pseTemplate: moodboard?.pseTemplate || false,
            description: moodboard?.description
        }), 
        [ moodboard ]
    );
    
    const isAdmin = user.is("Admin");
    const [ saving, setSaving ] = useState(false);
    const [ reading, setReading ] = useState(false);
    const [ image, setImage ] = useStateEffect(() => moodboard?.image || null, [ moodboard ]);
    const {
        name, nameChange, nameError,
        isPublic, isPublicChange,
        pseTemplate, pseTemplateChange,
        description, descriptionChange
    } = useFormFields(["name", "isPublic", "pseTemplate", "description"], form);

    const ref = useDictRef({ saving, name, image, isPublic, pseTemplate, description });

    const onFileChange = useCallback(
        async (file) => {
            setReading(true);
            const { mime, data } = await readInputFile(file);
            const image = {
                name: file.name,
                mime,
                data,
                src: `data:${mime};base64,${data}`,
                localId: uuid(),
                file
            };

            const res = await preloadImages([ image.src ]);
            image.width = res[0].width;
            image.height = res[0].height;

            setImage(image);
            setReading(false);
        },
        []
    );

    const onDeleteCoverClick = useSwallowEventCallback(
        () => {
            setImage(null);
        },
        []
    );

    const imgStyle = useMemo(
        () => image ? { backgroundImage: `url(${ image.src })` } : null,
        [ image ]
    );

    const save = useCallback(
        async () => {

            const valid = await form.validateAll();
            const isAdmin = user.is("Admin");

            if (!valid || ref.saving) {
                return;
            }

            ref.saving = true;
            setSaving(true);

            if (moodboard) {
                await actions.update(moodboard.id, ref.name, ref.image, null, ref.isPublic, ref.description);
                if (isAdmin) {
                    await actions.updatePseTemplate(moodboard.id, ref.pseTemplate);
                }
                editorResolve && editorResolve(moodboard);
            }
            else {
                const res = await actions.create(ref.name, ref.image, null, ref.isPublic, ref.description);
                if (isAdmin) {
                    await actions.updatePseTemplate(res.id, ref.pseTemplate);
                }
                editorResolve && editorResolve(res);
            }

            setSaving(false);
            ref.saving = false;
            MoodboardDrawer.hide(dialogName);
        
        },
        [ form, moodboard, dialogName ]
    );

    const onDoneClick = useCallback(
        () => {
            save();
        },
        [ save ]
    );

    return (
        <NullForm className={ "moodboard-form " + (saving ? "moodboard-form--saving" : "" ) }>

            <div className="moodboard-form-cover">
                <label>Cover</label>
                { saving ? 
                    <div className="moodboard-form-cover-dropzone" style={ imgStyle }/> :
                    !!image ? 
                        <Dropzone 
                            useInputField
                            onChange={ onFileChange }
                            className="moodboard-form-cover-dropzone-wrapper">
                                <div className="moodboard-form-cover-dropzone-actions">
                                    <a 
                                    href="/#"
                                    className="moodboard-form-cover-dropzone-icon">
                                        <IconEdit/>
                                    </a> 

                                    <a 
                                    onClick={ onDeleteCoverClick }
                                    href="/#"
                                    className="moodboard-form-cover-dropzone-icon moodboard-form-cover-delete">
                                        <IconDelete/>
                                    </a> 
                                </div>
                                <div 
                                className="moodboard-form-cover-dropzone" 
                                style={ imgStyle }> 
                                </div>
                        </Dropzone> 
                    : <Dropzone 
                        className="moodboard-form-cover-dropzone" 
                        useInputField
                        onChange={ onFileChange }
                        style={ imgStyle }>
                        <Button
                            loading={ reading }
                            type="primary"
                            shape="circle"
                            size="large"
                            Icon={ IconAdd }/>
                    </Dropzone> }
            </div>

            <FormInput error={ nameError } label="Name">
                <Input value={ name } onChange={ nameChange } placeholder="'Example: Fall in the city'"/>
            </FormInput>

            <FormInput label="Description">
                <Input.TextArea value={ description } onChange={ descriptionChange } 
                size="large"
                placeholder="Tell your audience a little more about this moodboard you've created"/>
            </FormInput>

            <FormInput>
                <Checkbox checked={ isPublic } onChange={ isPublicChange }>
                    Visible to all clients
                </Checkbox>
            </FormInput>

            { isAdmin && 
                <FormInput>
                    <Checkbox checked={ pseTemplate } onChange={ pseTemplateChange }>
                        PSE Template
                    </Checkbox>
                </FormInput> }

            <Button 
                type="primary" 
                text="Done"
                onClick={ onDoneClick }
                loading={ saving }
                disabled={ saving }/>
        </NullForm>
    )
}

function MoodboardDrawer({ name = DIALOG_NAME }) {

    const open = useSelector(s => s.dialogs[name]);
    const [ options, setOptions ] = useState({});
    const onClose = useCallback(
        () => {
            editorResolve && editorResolve(null);
            MoodboardDrawer.hide(name);
        },
        [name]
    );

    useOnAppEvent(`app/${ name }/options`, setOptions);

    const { moodboard } = options;

    return (
        <Drawer 
            className="tf-drawer moodboard-drawer"
            title={ moodboard ? "Edit moodboard" : "Create moodboard" } 
            open={ open }
            destroyOnClose
            onClose={ onClose }>
            { open && <MoodboardForm dialogName={ name } { ...options }/> }
        </Drawer>
    )
}

MoodboardDrawer.show = function(options = {}, name = DIALOG_NAME) {
    app.trigger(`app/${ name }/options`, options);
    store.dispatch(ui.show(name));
    return new Promise(resolve => {
        editorResolve = resolve;
    });
};

MoodboardDrawer.hide = function(name = DIALOG_NAME) {
    store.dispatch(ui.hide(name));
    editorResolve = null;
};


export default MoodboardDrawer