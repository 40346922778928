
import prepare from "common/src/lib/store/prepare"

import * as user from "common/src/store/user"
import * as dialogs from "common/src/store/dialogs"
import * as currencies from "common/src/store/currencies"
import { styles, seasons, locations, 
        designers, occasions, productStyles, hiddenTags } from "common/src/store/tags"
import * as app from "common/src/store/app"

import * as lookEditor from "common/src/store/look/editor"
import * as productEditor from "common/src/store/look/product"
import * as productDrafts from "common/src/store/look/drafts"

import * as lookPage from "./page/look"
import * as looksPage from "./page/looks"
import * as consultationPage from "./page/consultation"
import * as consultationsPage from "./page/consultations"

import * as consultationRequest from "./consultation/request"
import * as userSearch from "./userSearch"

import * as consNotifications from "./notifications"

import * as chats from "common/src/store/chat"
import * as notifications from "common/src/store/notifications"

import * as catalogue from "common/src/store/catalogue"
import * as connections from "common/src/store/connections"


export default prepare({
    app,
    user, 
    dialogs,
    currencies,
    
    styles, 
    seasons, 
    locations,
    designers,
    occasions,
    productStyles,
    hiddenTags,

    lookEditor,
    productEditor,
    productDrafts,

    lookPage,
    looksPage, 
    consultationPage,
    consultationsPage,
    consultationRequest,

    userSearch,
    consNotifications,
    
    chats,
    notifications,
    catalogue,
    connections
});
