
import { useRef, useEffect, useMemo } from "react"
import addListener from "common/src/lib/dom/addListener"
import removeListener from "common/src/lib/dom/removeListener"
import getOffset from "common/src/lib/dom/getOffset"
import getOuterHeight from "common/src/lib/dom/getOuterHeight"
import getOuterWidth from "common/src/lib/dom/getOuterWidth"
import setStyle from "common/src/lib/dom/setStyle"
import cls from "common/src/lib/className"
import Context from "common/src/refactor/lib/Context"

let hidId = 0;

const defaultAccept = ["image/jpeg", "image/png", "image/webp", "image/avif"];

import swallowEvent from "common/src/lib/dom/swallowEvent"
import useStateWithGetter from "common/src/hooks/useStateWithGetter"
import useUpdateEffect from "common/src/hooks/useUpdateEffect"

class DropzoneApi extends Context {

    inputs = []

    constructor(data) {
        super(data)

        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
        this.onMouseOut = this.onMouseOut.bind(this);

        this.onDragOver = this.onDragOver.bind(this);
        this.onDragOut = this.onDragOut.bind(this);
        this.onDrop = this.onDrop.bind(this);

        this.onInputChange = this.onInputChange.bind(this);
    }

    destroy() {
        this.inputs.forEach(i => document.body.removeChild(i));
    }

    toggleListeners(mode) {
        
        const c = this.data.mainRef.current;
        const b = document.body;
        const el = this.data.catchOnBody ? b : c;
        const fn = mode === "on" ? addListener : removeListener;

        if (mode === "off") {
            fn(b, "mousemove", this.onMouseMove);
        }

        c && fn(c, "mouseover", this.onMouseOver);
        c && fn(c, "mouseout", this.onMouseOut);

        if (!this.data.clickOnly) {
            el && fn(el, "dragover", this.onDragOver);
            el && fn(el, "dragleave", this.onDragOut);
            c && fn(c, "drop", this.onDrop);
        }
    }

    onUseInputFieldUpdate(use) {
        this.set({ useInputField: use });
        this.input && (this.input.style.display = use === false ? "none" : "");
    }

    accepts(mime) {
        const accept = this.data.accept || defaultAccept;
        return accept.indexOf(mime) !== -1;
    }

    onInputChange(e) {
        const input = this.releaseInput;
        if (this.data.multiple) {
            const files = Array.from(this.input.files);
            this.data.onChange && this.data.onChange(files, input);
        } else {
            const file = this.input.files[0];
            this.data.onChange && this.data.onChange(file, input);
            this.input.value = '';
        }
    }

    onMouseOver(e) {
        addListener(document.body, "mousemove", this.onMouseMove);
    }

    onMouseOut(e) {
        removeListener(document.body, "mousemove", this.onMouseMove);
    }

    onMouseMove(e) {
        this.setInputPosition(e)
    }

    onDragOver(e) {
        swallowEvent(e);
        if (this.data.catchOnBody) {
            if (!this.isDragActive()) {
                this.setDragActive(true);
            }
        }
        else {
            this.setDragActive(true);
        }
    }

    onDragOut(e) {
        swallowEvent(e);
        if (this.data.catchOnBody) {
            if (!e.fromElement && this.data.isDragActive()) {
                this.data.setDragActive(false);
            }
        }
        else {
            this.data.setDragActive(false);
        }
    }

    onDrop(e) {
        swallowEvent(e);
        this.data.setDragActive(false);

        if (!this.data.onChange) {
            return;
        }

        let i, file, files = [];

        // Use DataTransfer interface to access the file(s)
        if (e.dataTransfer.files && e.dataTransfer.files.length) {
            for (i = 0; i < e.dataTransfer.files.length; i++) {
                if (this.accepts(e.dataTransfer.files[i].type)) {
                    files.push(e.dataTransfer.files[i]);
                }
            }
        }
        else if (e.dataTransfer.items && e.dataTransfer.items.length) {
            // Use DataTransferItemList interface to access the file(s)
            for (i = 0; i < e.dataTransfer.items.length; i++) {
                // If dropped items aren't files, reject them
                if (e.dataTransfer.items[i].kind === 'file') {
                    file = e.dataTransfer.items[i].getAsFile();
                    if (this.accepts(file.type)) {
                        files.push(file);
                    }
                }
            }
        }

        if (files.length > 0) {
            this.data.onChange(files[0]);
        }
    }


    initInput() {
        const accept = this.data.accept || defaultAccept;
        this.input = document.createElement("input");
        this.input.type = "file";
        this.input.accept = Array.isArray(accept) ? accept.join(", ") : accept;
        this.input.style.display = this.useInputField === false ? "none" : "";
        this.data.mainRef.current.appendChild(this.input);
        if (this.data.multiple) {
            this.input.multiple = true;
        }
        addListener(this.input, "change", this.onInputChange);
    }

    releaseInput() {
        const input = this.input,
                id = "hid_" + (++hidId);

        removeListener(input, "change", this.onInputChange);
        this.initInput();

        input.id = id;
        input.style.position = "absolute";
        input.style.opacity = 0;
        input.style.left = "-1000px";
        document.body.appendChild(input);

        this.inputs.push(input);

        return input;
    }

    setInputPosition(e) {

        if (this.data.isDragActive()) {
            setStyle(this.input, {
                left: "-1000px",
                top: "-1000px"
            });
        }
        else {

            if (!this.data.mainRef.current) {
                return;
            }

            const main = this.data.mainRef.current,
                ofs = getOffset(main, false),
                w = getOuterWidth(main),
                h = getOuterHeight(main),
                x = e.clientX - ofs.left,
                y = e.clientY - ofs.top;

            if (this.input.parentNode === null) {
                main.appendChild(this.input);
            }

            if (!(e.clientX < ofs.left || e.clientY < ofs.top ||
                e.clientX > ofs.left + w || e.clientY > ofs.top + h)) {
                setStyle(this.input, {
                    left: x + "px",
                    top: y + "px"
                });
            }
        }
    }

}


function Dropzone(props) {

    const mainRef = useRef(null);
    const [ dragActive, setDragActive, isDragActive ] = useStateWithGetter(false);
    const api = useMemo(() => new DropzoneApi({ ...props, mainRef }), []);
    api.set({
        setDragActive,
        isDragActive,
        onChange: props.onChange
    });

    const className = useMemo(
        () => cls([ "dropzone", props.className, dragActive ? "drag-active" : "" ]),
        [ props.className, dragActive ]
    );

    // dirty hack for Tooltips
    const WrapComponent = useMemo(
        () => props.wrap || null,
        [ props.wrap ]
    );

    useUpdateEffect(
        () => api.onUseInputFieldUpdate(props.useInputField),
        [ props.useInputField, api ]
    );

    useEffect(
        () => {
            api.toggleListeners("on");
            api.initInput(props?.multiple || false);
            return () => {
                api.toggleListeners("off");
                api.$destroy();
            }
        },
        []
    )

    if (WrapComponent) {
        return (
            <WrapComponent { ...props.wrapProps }>
                <div className={ className }
                    ref={ mainRef }
                    children={ props.children } />
            </WrapComponent>
        )
    }

    return (
        <div className={ className }
                ref={ mainRef }
                style={ props.style }
                children={ props.children } />
    )
}

export default Dropzone
