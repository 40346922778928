
import { ApolloClient } from '@apollo/client'
import { HttpLink } from '@apollo/client/link/http'
import { InMemoryCache } from '@apollo/client/cache'
import settings from "common/src/settings"

const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
}

const link = new HttpLink({ uri: settings.graphqlEndpoint });
const cache = new InMemoryCache();
const client = new ApolloClient({ cache, link, defaultOptions });

export default client
