
function required(value) {
    if (value === "" || value === false || value === undefined) {
        return false;
    }
    if (typeof value === "string" && !value.trim()) {
        return false;
    }
    return true;
}

export default required