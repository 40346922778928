import StoreModule from "common/src/lib/store/StoreModule"
import hub from "common/src/hub"

let dialogs = new StoreModule("dialogs");
dialogs.ui.add("show", (s, a) => { 
    const prev = s[a.payload];
    s[a.payload] = true;
    if (!prev) {
        hub.dispatch("dialog", "data-show", a.payload);
    }
});
dialogs.ui.add("hide", (s, a) => { 
    const prev = s[a.payload];
    s[a.payload] = false;
    if (prev) {
        hub.dispatch("dialog", "data-hide", a.payload);
    }
});
dialogs.ui.add("hideAll", (s) => {
    for (let k in s) {
        if (s[k] === true) {
            s[k] = false;
            hub.dispatch("dialog", "data-hide", k);
        }
    }
});

export let { reducer, ui, initialState } = dialogs.export();
