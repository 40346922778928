import { useMemo } from "react"
import user from "common/src/user"
import Loader from "common/src/components/Loader"

function Reactions({ reactions, onClick, loading = false }) {

    const hasMyReaction = useMemo(
        () => reactions.find(r => r.userId === user.id()), 
        [ reactions ]
    );

    const cls = useMemo(
        () =>   [ 
                    "chat-message-reactions", 
                    hasMyReaction ? "chat-message-reactions-ofmine" : "" 
                ].join(" "),
        [ hasMyReaction ]
    );

    if (loading) {
        return (
            <div className="chat-message-reactions">
                <Loader/>
            </div>
        )
    }

    if (reactions.length === 0) {
        return null;
    }

    return (
        <div className={ cls }
            onClick={ hasMyReaction ? onClick : null }>
            { reactions.map(r => <span key={ r.reaction }>{ r.reaction }</span>) }
        </div>
    )
}

export default Reactions