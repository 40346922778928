import { useState, useEffect, useCallback, Children, cloneElement } from 'react';

const BackToTop = ({ elemRef, children }) => {
    const [isActive, setIsActive] = useState(false);

    const toggleVisibility = useCallback(
        () => {
            if (elemRef?.current) {
                const scrollTop = elemRef.current.scrollTop;
                setIsActive(scrollTop > 300);
            } else {
                setIsActive(window.scrollY > 300);
            }
        }, [ elemRef?.current ]);

    useEffect(() => {
        if (elemRef?.current) {
            elemRef.current.addEventListener('scroll', toggleVisibility);
        } else {
            window.addEventListener('scroll', toggleVisibility);
        }
    
        return () => elemRef?.current ? elemRef.current.removeEventListener('scroll', toggleVisibility) : window.removeEventListener('scroll', toggleVisibility);
  }, [ elemRef ]);

  const scrollToTop = useCallback(
    () => {
        if (elemRef?.current) {
            elemRef.current.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        } else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [ elemRef?.current ]);

  if (!isActive) {
    return null;
  }

  return (
    <div className={ `catalogue-back-to-top ${ elemRef?.current ? "modal" : "body" }` }>
        { Children.map(children, child =>
            cloneElement(child, { onClick: scrollToTop, disabled: !isActive })
        ) }
    </div>
  );
};

export default BackToTop;