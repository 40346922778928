import { useSelector } from "react-redux"
import { useCallback, useMemo, useState, useRef } from "react"
import { Drawer, App as AntApp } from "antd"

import Button from "../button/Button"
import Dropzone from "common/src/components/Dropzone"
import { ReactComponent as IconAdd } from "common/src/svg/plus.svg"

import addListener from "common/src/lib/dom/addListener"
import removeListener from "common/src/lib/dom/removeListener"
import getOuterHeight from "common/src/lib/dom/getOuterHeight"
import getOuterWidth from "common/src/lib/dom/getOuterWidth"

import { ui } from "common/src/store/dialogs"
import store from "app/store"
import * as actions from "common/src/actions/user"
import readInputFile from "common/src/lib/dom/readInputFile"
import api from "app/api"
import userSelector from "common/src/selectors/user/current"
import s3url from "common/src/lib/image/s3url"
import useUpdateEffect from "common/src/hooks/useUpdateEffect"

const DIALOG_NAME = "change-avatar";

function EditAvatarForm() {

    const current = useSelector(userSelector);
    const [ showUpload, setShowUpload ] = useState(false);
    const [ uploading, setUploading ] = useState(false);
    const [ imgStyle, setImgStyle ] = useState({});
    const [ imgCls, setImgCls ] = useState("");
    const [ cntStyle, setCntStyle ] = useState(
        () => {
            return current.avatar ? 
                        { backgroundImage: `url(${ s3url(current.avatar) })` } : 
                        null
        }
    );
    const imgRef = useRef();
    const { modal, message } = AntApp.useApp();

    useUpdateEffect(
        () => {
            if (current?.avatar) {
                setCntStyle({
                    backgroundImage: `url(${ s3url(current.avatar) })`
                })
            }
            else {
                setCntStyle(null);
            }
        },
        [ current?.avatar ]
    );

    const ref = useMemo(
        () => {
            return {
                startX: null,
                startY: null,
                offsetX: 0,
                offsetY: null,
                lastPosition: null,
                imgVertical: false,
                imgData: null,
                imgMime: null,
                imgRatio: 1,
                fullImg: null,
                imgRef: null,
                isImgSquare: function() {
                    return Math.abs(1 - this.imgRatio) < 0.1;
                },
                isImgVertical: function() {
                    return this.imgRatio < 1;
                },
                getImgCls: function() {
                    return this.isImgSquare() ? 
                            "square" : 
                            this.isImgVertical() ? 
                                "vertical" :
                                "horizontal"
                },
                getImgCoords: function() {
                    let img = imgRef.current,
                        dz = img.parentNode,
                        dw = getOuterWidth(dz),
                        dh = getOuterHeight(dz),
                        w = parseInt(img.width),
                        h = parseInt(img.height),
                        vertical = this.isImgVertical(),
                        square = this.isImgSquare(),
                        x, y, center, extra,
                        minX, minY,
                        maxX, maxY;
            
                    if (square) {
                        x = "-50%";
                        y = "-50%";
                    }
                    else if (vertical) {
                        center = -h/2;
                        extra = (h - dh) / 2;
                        minY = center - extra;
                        maxY = center + extra;
                        y = this.lastPosition + this.offsetY;
                        y = Math.max(y, minY);
                        y = Math.min(y, maxY);
                        x = "-50%";
                    }
                    else {
                        center = -w/2;
                        extra = (w - dw) / 2;
                        minX = center - extra;
                        maxX = center + extra;
                        x = this.lastPosition + this.offsetX;
                        x = Math.max(x, minX);
                        x = Math.min(x, maxX);
                        y = "-50%";
                    }
            
                    return {x, y}
                },
                setImgPosition: function() {
                    let { x, y } = this.getImgCoords();
                    typeof (x) === "number" && (x += "px");
                    typeof (y) === "number" && (y += "px");
                    const transform = `translate(${x}, ${y})`;
                    setImgStyle({ transform });
                },
                getInitialPosition: function() {
                    let img = imgRef.current,
                        w = parseInt(img.width),
                        h = parseInt(img.height),
                        vertical = this.isImgVertical();
                    return vertical ? -h/2 : -w/2;
                },
                setInitialPosition: function() {
                    ref.lastPosition = this.getInitialPosition();
                    this.setImgPosition();
                }
            }
        },
        []
    );

    const onInputChange = useCallback(
        (file) => {

            if (ref.fullImg) {
                document.body.removeChild(ref.fullImg);
            }
    
            readInputFile(file)
            .then(({mime, data}) => {
                ref.lastPosition = null;
                ref.imgData = data;
                ref.imgMime = mime;
                ref.dataUrl = "data:" + mime + ";base64," + data;
                let img = ref.fullImg = new Image();
                img.style.position = "absolute";
                img.style.right = "150%";
                img.style.top = 0;
                img.style.visibility = "hidden";
                img.style.transform = "translateX(-100%)";
                img.src = ref.dataUrl;
                img.onload = () => {
                    ref.imgRatio = parseInt(img.width) / parseInt(img.height);
                    setShowUpload(true);
                    setImgCls(ref.getImgCls());
                }
                document.body.appendChild(img);
            });
        },
        []
    );

    const onRemoveClick = useCallback(
        () => {
            if (ref.imgData) {
                ref.lastPosition = null;
                ref.imgData = null;
                ref.imgMime = null;
                setShowUpload(false);
            }
            else if (current?.avatar) {
                modal.confirm({
                    icon: null,
                    closable: true,
                    cancelButtonProps: {
                        type: "text"
                    },
                    title: "Remove photo",
                    content: "Are you sure?",
                    onOk: () => {
                        return actions.removeAvatar();
                    }
                })
            }
        },
        [ current?.avatar ]
    );

    const onSaveClick = useCallback(
        async () => {
            let s3key,
                vertical = ref.isImgVertical(),
                square = ref.isImgSquare(),
                { x, y } = ref.getImgCoords(),
                ix, iy,
                img = imgRef.current,
                fullImg = ref.fullImg,
                w = parseInt(img.width),
                h = parseInt(img.height),
                fw = parseInt(fullImg.width),
                fh = parseInt(fullImg.height),
                canvas = document.createElement('canvas'),
                context = canvas.getContext('2d'),
                imgData,
                size,
                csize = 1000;

            if (square) {
                size = fw;
                iy = 0;
                ix = 0;
            }
            else if (vertical) {
                size = fw;
                iy = Math.abs(y) - w/2; // position on visible image
                iy = Math.max(0, iy);
                iy = (fh * iy) / h; // position on full image
                iy = Math.min(iy, fh - size)
                ix = 0;
            }
            else {
                size = fh;
                ix = Math.abs(x) - h/2;
                ix = Math.max(0, ix);
                ix = (fw * ix) / w;
                ix = Math.min(ix, fw - size);
                iy = 0;
            }

            csize = Math.min(csize, size);

            canvas.width = csize;
            canvas.height = csize;
            context.fillStyle = "white";
            context.fillRect(0, 0, csize, csize);
            context.drawImage(fullImg, ix, iy, size, size, 0, 0, csize, csize);
            imgData = canvas.toDataURL('image/jpeg')
                            .replace(/^data:image\/.+;base64,/, '');


            setUploading(true);

            await api.backend.post("/upload/avatar", {
                body: {
                    contentType: "image/jpeg",
                    data: imgData
                }
            })
            .then(resp => {
                if (resp.error) {
                    return Promise.reject(resp.error);
                }
                return resp;
            })
            .then(resp => s3key = resp.key)
            .then(() => actions.removeAvatar())
            .then(() => actions.update({ avatar: s3key }))
            .then(() => {
                /*alert({
                    title: "Your details have been updated.",
                    message: "Thanks for keeping your details on The Floorr up to date."
                })*/
                message.success({
                    content: "Your details are now updated.",
                    icon: <></>
                })
                EditAvatarDialog.hide();
            })
            .finally(() => {
                setUploading(false);
            })
            .catch(err => {
                message.error({
                    content: err.message
                })
                /*alert({
                    title: "User update failed",
                    message: err.message
                })*/
            })
        },
        []
    );


    const onMouseUp = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
    
            let { x, y } = ref.getImgCoords(),
                vertical = ref.isImgVertical();
            
            ref.lastPosition = vertical ? y : x;
            ref.offsetX = 0;
            ref.offsetY = 0;
    
            removeListener(document.body, "mousemove", onMouseMove);
            removeListener(document.body, "mouseup", onMouseUp);
        },
        []
    );

    const onMouseMove = useCallback(
        (e) => {
            ref.offsetX = e.clientX - ref.startX;
            ref.offsetY = e.clientY - ref.startY;
            ref.setImgPosition();
        },
        []
    );

    const onMouseDown = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
    
            if (ref.isImgSquare()) {
                return;
            }
    
            ref.startX = e.clientX;
            ref.startY = e.clientY;
            ref.offsetY = 0;
            ref.offsetX = 0;
    
            if (ref.lastPosition === null) {
                ref.lastPosition = ref.getInitialPosition();
            }
    
            addListener(document.body, "mousemove", onMouseMove);
            addListener(document.body, "mouseup", onMouseUp);
        },
        []
    )



    return (
        <div className="change-avatar-form">
        
            <Dropzone 
                className="change-avatar-form-dropzone" 
                useInputField={ !current?.avatar && !showUpload }
                onChange={ onInputChange }
                style={ cntStyle }>

                { showUpload && 
                    <img src={ ref.dataUrl } 
                        ref={ imgRef }
                        className={ imgCls }
                        onLoad={ () => ref.setInitialPosition() }
                        style={ imgStyle }
                        onMouseDown={ onMouseDown }
                        alt=""/> }

                { (!current?.avatar && !showUpload) && 
                    <div className="change-avatar-form-dropzone-icon">
                        <IconAdd/>
                        <span>Click or drop image file</span>
                    </div> }
            </Dropzone>
        
            <div className="change-avatar-form-actions">
                <Button 
                    disabled={ (!current.avatar && !showUpload) || uploading }
                    text="Remove"
                    onClick={ onRemoveClick }/>
                <Button 
                    loading={ uploading }
                    disabled={ !showUpload || uploading }
                    type="primary" 
                    text="Use photo"
                    onClick={ onSaveClick }/>
            </div>
        </div>
    )
}

function EditAvatarDialog() {
    const open = useSelector(s => s.dialogs[DIALOG_NAME]);

    const onClose = useCallback(
        () => { EditAvatarDialog.hide() },
        []
    );

    return (
        <Drawer 
            className="tf-drawer change-avatar-drawer"
            title="Photo"
            open={ open } 
            onClose={ onClose }
            destroyOnClose>
            { open && <EditAvatarForm/> }
        </Drawer>
    )
}

EditAvatarDialog.show = function() {
    store.dispatch(ui.show(DIALOG_NAME));
}

EditAvatarDialog.hide = function() {
    store.dispatch(ui.hide(DIALOG_NAME));
}


export default EditAvatarDialog