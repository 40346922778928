
const defaultRates = {
    fri: 0.8,
    contributor: 0.8,
    pse: 0.8
};

const getPseCommission = (value, userRate = null, orderRate = null) => {
    const rate = orderRate;//userRate || defaultRates.pse;
    return value * rate;
};

const getFriCommission = (value, userRate = null, orderRate = null) => {
    const rate = orderRate; //userRate || defaultRates.fri;
    return value * rate;
};

const getContributorCommission = (value, userRate = null, orderRate = null) => {
    const rate = orderRate;//userRate || defaultRates.contributor;
    return value * rate;
};

const calculateCommissions = (row, user, gross = false) => {
    const friRate = user?.paymentSettings?.friCommission || null;
    const conRate = user?.paymentSettings?.contributorCommission || null;
    const pseRate = user?.paymentSettings?.pseCommission || null;

    const friCm = row.fri.pseCommissionAmount || getFriCommission(row.fri.commissionAmount, friRate, row.pseCommissionRate);
    const conCm = row.contributor.pseCommissionAmount || getContributorCommission(row.contributor.commissionAmount, conRate, row.pseCommissionRate);
    const pseCm = row.contributor.pseCommissionAmount || getPseCommission(row.contributor.commissionAmount, pseRate, row.pseCommissionRate);

    return {
        total: friCm + conCm,
        fri: friCm,
        contributor: conCm,
        pse: pseCm
    }
}

module.exports = {
    defaultRates,
    getPseCommission,
    getFriCommission,
    getContributorCommission,
    calculateCommissions
}