

export const filterQueryParams = [
    {
        name: "query",
        exports: {
            filters: value => value ? value : undefined
        }
    },
    {
        name: "gender",
        serialize: value => value ? 
                                typeof value === "object" ? value.value : value :
                                undefined,
        exports: {
            filters: value => {
                if (!value) {
                    return undefined;
                }
                const key = typeof value === "object" ? value.value : value;
                if (!key || key === "all") {
                    return undefined;
                }
                return key === "women" ? "female" : "male"
            }
        },
        imports: {
            filters: value => {
                if (!value) {
                    return undefined;
                }
                return value === "female" ? "women" : "men"
            }
        }
    },
    {
        name: "retailer",
        serialize: list => list && list.length > 0 ? list.join(",") : undefined,
        unserialize: str => str ? str.split(",") : [],
        exports: {
            filters: list => list && list.length > 0 ? list : undefined
        }
    },
    {
        name: "designer",
        serialize: list => list && list.length > 0 ? list.join(",") : undefined,
        unserialize: str => str ? str.split(",") : [],
        imports: {
            filters: (value, data) => {
                return value || data.designer_id;
            }
        },
        exports: {
            filters: [
                "designer_id", 
                list => list && list.length > 0 ? list : undefined
            ]
        }
    },
    {
        name: "region",
        serialize: value => value ? 
                                typeof value === "object" ? value.value : value :
                                undefined,
        exports: {
            filters: [
                "_none",
                (value, values) => {
                    value = (value || "").toLowerCase();
                    if (value === "us") {
                        values["currency"] = "USD";
                    }
                    else if (value === "eu") {
                        values["currency"] = "EUR";
                    }
                    else if (value === "gb") {
                        values["currency"] = "GBP";
                    }
                    else {
                        values["currency"] = "USD,GBP,EUR";
                    }
                    // if (value && value !== "random") {
                    //     values["sort_dir"] = value;
                    //     values["sort_by"] = "price_usd";
                    // }
                    // else {
                    //     values["sort_dir"] = undefined;
                    //     values["sort_by"] = undefined;
                    // }
                    return undefined;
                }
            ]
            // filters: value => {
            //     if (!value) {
            //         return undefined;
            //     }
            //     const key = typeof value === "object" ? value.value : value;
            //     if (!key || key === "all") {
            //         return undefined;
            //     }
            //     return key;
            // }
        }
    },
    {
        name: "price",
        serialize: value => value ? 
                                typeof value === "object" ? value.value : value :
                                undefined,
        exports: {
            filters: value => {
                if (!value) {
                    return undefined;
                }
                const key = typeof value === "object" ? value.value : value;
                if (!key || key === "all") {
                    return undefined;
                }
                return key;
            }
        }
    },
    {
        name: "sortdir",
        exports: {
            filters: [
                "_none",
                (value, values) => {
                    if (value && value !== "random") {
                        values["sort_dir"] = value;
                        values["sort_by"] = "price_usd";
                    }
                    else {
                        values["sort_dir"] = undefined;
                        values["sort_by"] = undefined;
                    }
                    return undefined;
                }
            ]
        }
    }
]