import { useCallback, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import Button from "common/src/refactor/components/button/Button"
import Page from "common/src/refactor/components/Page"
import LookCard from "common/src/refactor/components/look/Card"
import LookActions from "app/components/look/Actions"
import ProductCard from "common/src/refactor/components/catalogue/product/Card"
import CatalogueProductDrawer from "common/src/refactor/components/catalogue/product/Drawer"
import ProductReaction from "common/src/refactor/components/look/ProductReaction"
import ProductRetailer from "common/src/refactor/components/look/ProductRetailer"
import ProductContextMenu from "app/components/product/ContextMenu"

import { ReactComponent as IconBack } from "common/src/svg/back.svg"
import useSearchParam from "common/src/refactor/hooks/useSearchParam"
import { lookLoader } from "common/src/actions/looks"
import { consultationsLoader } from "common/src/actions/consultations"
import useQuery from "common/src/refactor/hooks/useQuery"
import { useMemo } from "react"
import useHub from "common/src/hooks/useHub"
import useLookActions from "app/hooks/look/useLookActions"
import useDictRef from "common/src/hooks/useDictRef"
import { LinkSourceContextProvider } from "common/src/refactor/lib/LinkSourceContext"
import { Switch } from "antd"



function LookPageInfo({ look, consultation }) {

    const from = `/styling/look/${ look.id }`;
    const [ isHidden, setIsHidden ] = useState(!look.published);
    const navigate = useNavigate();

    const ref = useDictRef({ isHidden })

    const { onHideToggle, loading } = useLookActions({ look, onHide: setIsHidden })

    const onProductClick = useCallback(
        (product) => {
            CatalogueProductDrawer.show({ 
                product,
                source: {
                    type: "look-product",
                    id: product?.id
                }
            });
        },
        []
    );

    const onDelete = useCallback(
        () => {
            navigate(`/styling/${consultation.id}`);
        },
        //eslint-disable-next-line
        [ ]
    );

    const onLayoutProductClick = useCallback(
        (e) => {
            const pid = e.target.dataset.productId;
            if (pid) {
                const product = look.products.find(p => p.id === pid);
                if (product) {
                    CatalogueProductDrawer.show({ 
                        product,
                        source: {
                            type: "look-product",
                            id: product?.id
                        }
                    });
                }
            }
        },
        [ look ]
    );

    const productProps = useCallback(
        (product, look) => {
            const props = {
                children: (
                    <>
                        <ProductRetailer product={ product }/>
                        <ProductReaction product={ product }/>
                    </>
                )
            };

            if (look.productMode === "tagged") {
                props.onClick = onProductClick;
            }
            else {
                props.attrs = {
                    onClick: onLayoutProductClick
                }
            }
            
            return props;
        },
        [ onProductClick, onLayoutProductClick ]
    );

    const onHiddenChange = useCallback( // needed to update switch state immediately
        (val) => {
            setIsHidden(!val);
            onHideToggle(val);
        },
        [ onHideToggle ]
    );

    return (
        <div className="page-look-info">

            <div className="page-look-header">
                { look.title && 
                    <p className="page-look-title">
                        { look.title }
                    </p> }
                <div className="page-look-builder-form-hide">
                    <Switch
                        checked={ !ref.isHidden }
                        disabled={ !consultation.customerId || consultation.hidden || loading }
                        size="small"
                        onChange={ onHiddenChange }
                    />
                    <span>Visible to your client</span>
                </div>
            </div>

            <div className="page-look-look-wrapper">
                <LookActions 
                    look={ look } 
                    consultation={ consultation }
                    editFrom={ from }
                    onDelete={ onDelete }
                    />
                <div className="page-look-look-container">
                    <LookCard 
                    look={ look }
                    mode="page"
                    productProps={ productProps }/>
                </div>
            </div>
            
        </div>
    )
}

function ProductItem({ product }) {

    const onProductClick = useCallback(
        () => {
            CatalogueProductDrawer.show({ 
                product,
                source: {
                    type: "look-product",
                    id: product?.id
                }
            });
        },
        [ product ]
    );

    return (
        <LinkSourceContextProvider type="look-product" id={ product.id }>
        <ProductCard 
            product={ product } 
            onClick={ onProductClick }>
            <ProductContextMenu product={ product }/>
        </ProductCard>
        </LinkSourceContextProvider>
    )
}

function LookPageProducts({ look }) {

    const products = useMemo(
        () => look.products,
        [ look ]
    );

    return (
        <div className="page-look-products-wrapper">
            <p>{ products.length } { products.length === 1 ? "Product" : "Products" }</p>
            <div className="page-look-products">
            { products.map(p => (
                <ProductItem key={ p.id } product={ p }/>
            ))}
            </div>
        </div>
        
    )
}

function LookPage() {

    const { id } = useParams();

    const { data: look, isLoading: isLookLoading, refetch: refetchLook } = useQuery(
        lookLoader,
        [ id ],
        {
            initialData: null,
            processResponse: response => ({ data: response }),
            prepare: list => list?.[0],
            params: {
                where: {
                    id: {
                        _eq: id 
                    }
                }
            }
        }
    );

    const [ from ] = useSearchParam("from", look ? `/styling/${ look.consultationId }` : 
                                                    "/styling");

    const { data: consultation, isLoading: isConsLoading } = useQuery(
        consultationsLoader,
        [ look?.consultationId ],
        {
            initialData: null,
            enabled: !!look?.consultationId,
            processResponse: response => ({ data: response }),
            prepare: list => list?.[0],
            params: {
                withSizes: true,
                where: {
                    id: {
                        _eq: look?.consultationId 
                    }
                }
            }
        }
    );

    const onLookUpdated = useCallback(
        ({ id }) => {
            if (id === look?.id) {
                refetchLook();
            }
        },
        [ refetchLook, look ]
    );

    useHub("look", "updated", onLookUpdated);

    return (
        <Page className="page-look" loading={ isLookLoading || isConsLoading }>
            <LinkSourceContextProvider type="look" id={ id }>
            <div className="page-look-header">
                <Button
                    className="page-look-back"
                    Component="a"
                    href={ from }
                    type="text"
                    variant="link"
                    size="medium"
                    Icon={ IconBack }
                    iconPosition="before"
                    text="Back"/>
            </div>

            <div className="page-look-columns">
                { (look && consultation) && 
                    <LookPageInfo 
                        look={ look } 
                        consultation={ consultation }/> }
                { (look && consultation) && 
                    <LookPageProducts look={ look } consultation={ consultation }/> }
            </div>
            </LinkSourceContextProvider>
        </Page>
    )
}

export default LookPage