import { useSelector } from "react-redux"
import { useMemo, useState, useCallback, useEffect } from "react"
import { useOn } from "@kuindji/observable-react"

import Contacts from "./Contacts"
import Messages from "./Messages"
import ContactHeader from "./ContactHeader"
import Form from "./Form"
//import ImageViewer from "common/src/components/ImageViewer"
import EmptyMessage from "common/src/refactor/components/EmptyMessage"

import { default as ChatApi } from "common/src/lib/chat/Chat"
import { loadUserConnection } from "common/src/actions/connections"
import ChatContext from "common/src/lib/chat/ChatContext"
//import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import addListener from "common/src/lib/dom/addListener"
import removeListener from "common/src/lib/dom/removeListener"
//import s3url from "common/src/lib/image/s3url"
//import useChatKey from "common/src/hooks/useChatKey"
//import NullForm from "common/src/components/NullForm"
import connectionsSelector from "common/src/selectors/connections"
import settings from "app/settings"
import { setCurrentChatId } from "common/src/actions/online"
import useDictRef from "common/src/hooks/useDictRef"
import useUpdateEffect from "common/src/hooks/useUpdateEffect"
import useHub from "common/src/hooks/useHub"

const WINDOW_BREAKPOINT = 800;

function Chat({
        currentUserRole = null, 
        //noAttachments = false, 
        showEmptyMessage = false,
        singleContact = false, 
        allowCatalogueAttachments = true,
        dialogMode = false,
        contactId = null,
        showContacts: initialShowContacts = false,
        initialAttachment,

        onOpenContact,
        onMessageAction,
        onClose,
        onAttachFromFeed,
        onAttachMoodboard,
        onAttachLook,
        onAttachConsultation,
        onShowImage
    }) {

    const connections = useSelector(connectionsSelector);

    const chatApi = useMemo(
        () => {
            return new ChatApi({ 
                currentUserRole,
                state: {
                    query: "",
                    showContacts: initialShowContacts,
                    showAttachments: false,
                    windowBreakpoint: WINDOW_BREAKPOINT,
                    allowCatalogueAttachments,
                    singleContact,
                    showEmptyMessage
                }
            });
        }, 
        []
    );
    //window.chatApi = chatApi;

    const ref = useDictRef({ contactId, onOpenContact, chatApi });

    const [ activeContact, setActiveContact ] = useState(chatApi.getContact());
    const [ showContacts, setShowContacts ] = useState(chatApi.getState().showContacts);
    const [ loading, setLoading ] = useState(true);

    async function loadContact() {
        if (ref.contactId && ref.contactId !== activeContact?.id && ref.contactId !== "broadcast") {
            const contact = await loadUserConnection(currentUserRole, ref.contactId);
            chatApi.setContact(contact);
        } 
    };

    const onContactChange = useCallback(
        () => { 
            const contact = chatApi.getContact();
            setActiveContact(contact);
            (contact && ref.onOpenContact) && ref.onOpenContact(contact);
        }, 
        []
    );

    const onStateChange = useCallback(
        () => {
            setShowContacts(chatApi.getState().showContacts);
        },
        []
    );

    const onCurrencyChange = useCallback(
        () => {
            ref.chatApi.reloadAttachmentsWithCurrency();
        },
        []
    );

    const onWindowFocus = useCallback(() => chatApi.subscribe(), []);
    const onWindowBlur = useCallback(() => chatApi.unsubscribe(), []);

    function toggleWindowEvents(state) {
        const fn = state === "on" ? addListener : removeListener;
        fn(window, "focus", onWindowFocus);
        fn(window, "blur", onWindowBlur);
    };

    useHub("app", "currency-change", onCurrencyChange);

    useOn(chatApi, {
        messageAction: onMessageAction,
        showImage: onShowImage,
        attachFromFeed: onAttachFromFeed,
        attachMoodboard: onAttachMoodboard,
        attachConsultation: onAttachConsultation,
        attachLook: onAttachLook,
        contact: onContactChange,
        state: onStateChange,
        loadingMessages: setLoading,
    });

    useEffect(() => { loadContact() }, [ contactId, connections ]);
    useEffect(() => {

        if (initialAttachment) {
            const at = initialAttachment;
            const type = at.type;
            chatApi.addAttachment(type, at[type], at[type].id);
        }

        return () => chatApi.destroy()
    }, []);

    useEffect(() => {
        toggleWindowEvents("on");
        return () => {
            toggleWindowEvents("off");
            setCurrentChatId(null);
        };
    }, []);

    useUpdateEffect(
        () => {
            if (ref.contactId) {
                const contact = connections.find(c => c.id === ref.contactId);
                if (contact) {
                    setActiveContact(contact);
                }
            }
        },
        [ connections ]
    )

    const cls = useMemo(
        () => [
            "chat",
            showContacts ? "chat-with-contacts" : ""
        ].filter(c => !!c).join(" "),
        [ showContacts ]
    );


    return (
        <ChatContext.Provider value={ chatApi }>
            <div className={ cls }>
                { showContacts && <Contacts/> }

                <div className="chat-main">
                    <ContactHeader 
                        onClose={ onClose }
                        dialogMode={ dialogMode }/>
                    <Messages/>
                    { connections.length === 0 && !loading && 
                        <EmptyMessage>
                            Invite a client to the app to start a new chat.<br/>
                            Invite your client via THE FLOORR Stylist mobile app on{" "}
                            <a href={ settings.appStoreUrl } 
                                target="_blank" 
                                rel="noreferrer">App Store</a>.
                        </EmptyMessage> }
                    <Form/>
                </div>
            </div>
        </ChatContext.Provider>
    )

}

export default Chat