import { createContext } from "react"
import Context from "common/src/refactor/lib/Context"

const LookBuilderContext = createContext();

export class LookBuilderSearchApi extends Context {

    openPage(page) {
        const currentPage = this.get("searchPage");
        if (currentPage === page) {
            return;
        }
        if (currentPage) {
            const history = [ ...(this.get("searchPageHistory") || []) ];
            history.push(currentPage);
            while (history.length > 10) {
                history.shift();
            }
            this.set("searchPageHistory", history);
        }
        this.set("searchPage", page);
    }

    goBack() {
        const history = [ ...(this.get("searchPageHistory") || []) ];
        const prev = history.pop() || "catalogue";

        if (prev) {
            this.set("searchPage", prev);
            this.set("searchPageHistory", history);
        }
    }

}

export default LookBuilderContext