
import StoreModule from "common/src/lib/store/StoreModule"

let exports = {};

["style", "season", "location", 
    "designer", "occasion", "productStyle",
    "hiddenTag"].forEach(entity => {
    let tags = new StoreModule(entity);
    tags.addList("new");
    tags.addList("recent");
    exports[entity+"s"] = tags.export();
})

export let { styles, seasons, locations, 
            designers, occasions, productStyles, hiddenTags } = exports;