
import StoreModule from "common/src/lib/store/StoreModule"

const cons = new StoreModule("consultations");
cons.addList("consultations");

const { reducer, data, ui, initialState } = cons.export();

function reset(store) {
    store.dispatch(data.consultations.reset());
}

export { reducer, data, ui, initialState, reset };
