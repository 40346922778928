import { useMemo, useRef, useCallback, useState, useContext, useEffect } from "react"
import { Slider, App, Dropdown } from "antd"
import s3url from "common/src/lib/image/s3url"
import { ReactComponent as IconPlay } from "common/src/svg/play.svg"
import { ReactComponent as IconPause } from "common/src/svg/pause.svg"
import { ReactComponent as IconVolume } from "common/src/svg/volume.svg"
import { ReactComponent as IconDelete } from "common/src/svg/delete.svg"
import { ReactComponent as IconDots } from "common/src/svg/dots.svg"

import ChatContext from "common/src/lib/chat/ChatContext"

import Button from "common/src/refactor/components/button/Button";
import user from "common/src/user"
import api from "common/src/api"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"

function pad(num, nulls) {
    return ("" + num).padStart(nulls, '0');
}

function renderDuration(duration) {
    const total = Math.ceil(duration)
    const hours = Math.floor(total / 3600);
    const minutes = Math.floor((total - (hours * 3600)) / 60);
    const seconds = total - (hours * 3600) - (minutes * 60);
    let ts = `${ pad(minutes, 1) }:${ pad(seconds, 2) }`;
    if (hours > 0) {
        ts = `${ pad(hours) }:` + ts;
    }
    return ts;
}

function AudioMenu({ src, onPlaybackRateChange, children }) {
    const menu = useMemo(
        () => {
            const items = [
                {
                    key: "download",
                    label: (
                        <a href={ src }>Download</a>
                    ),
                },
                {
                    key: "playback",
                    label: "Speed",
                    children: [
                        {
                            key: "0.25",
                            label: "0,25",
                            onClick: () => onPlaybackRateChange(0.25)
                        },
                        {
                            key: "0.5",
                            label: "0,5",
                            onClick: () => onPlaybackRateChange(0.5)
                        },
                        {
                            key: "0.75",
                            label: "0,75",
                            onClick: () => onPlaybackRateChange(0.75)
                        },
                        {
                            key: "1",
                            label: "1",
                            onClick: () => onPlaybackRateChange(1)
                        },
                        {
                            key: "1.25",
                            label: "1,25",
                            onClick: () => onPlaybackRateChange(1.25)
                        },
                        {
                            key: "1.5",
                            label: "1,5",
                            onClick: () => onPlaybackRateChange(1.5)
                        },
                        {
                            key: "2",
                            label: "2",
                            onClick: () => onPlaybackRateChange(2)
                        }
                    ]
                }
            ]

            return { items };
        },
        [ src, onPlaybackRateChange ]
    );

    return (
        <Dropdown overlayClassName="chat-message-voicemessage-dropdown" menu={ menu } placement="topRight">
            { children }
        </Dropdown>
    )
}

function VolumeSlider({ value, onValueChange, children }) {
    const menu = useMemo(
        () => {
            const items = [
                {
                    key: "slider",
                    label: (
                        <Slider value={ value } max={ 100 } onChange={ onValueChange }/>
                    )
                }
            ]

            return { items };
        },
        [ value, onValueChange ]
    )

    return (
        <Dropdown overlayClassName="chat-message-voicemessage-dropdown" menu={ menu } placement="topRight">
            { children }
        </Dropdown>
    )
}

function VoiceMessage({ message }) {
    const audioRef = useRef(null);
    const playAnimationRef = useRef(null);

    const { modal, message: ntfmessage } = App.useApp();
    const [ paused, setPaused ] = useState(true);
    const [ progress, setProgress ] = useState(0);
    const [ duration, setDuration ] = useState(0);
    const [ volume, setVolume ] = useState(100);
    const chat = useContext(ChatContext);

    const { isMine, read } = useMemo(
        () => {
            let read = false;
            const isMine = message.userId === user.id();
            if (isMine) {
                read = message.notifications[0]?.read || false;
            }

            return { isMine, read }
        },
        [ message ]
    );

    const src = useMemo(
        () => {
            const vm = message.voiceMessages[0];
            return vm.src || s3url(vm.s3key);
        }, 
        []
    );

    if (!message.voiceMessages[0] ||  message.voiceMessages[0].uploading) {
        return null;
    };

    const repeat = useCallback(
        () => {
            const currentTime = audioRef.current.currentTime;
            setProgress(currentTime);
      
            playAnimationRef.current = requestAnimationFrame(repeat);
        }, 
        [ audioRef.current, playAnimationRef.current ]
    );
      
    useEffect(
        () => {
            if (!paused) {
                audioRef.current.play();
                playAnimationRef.current = requestAnimationFrame(repeat);
            } else {
                audioRef.current.pause();
                cancelAnimationFrame(playAnimationRef.current);
            }
        }, [ paused, audioRef, repeat ]);

    const onDeleteClick = useSwallowEventCallback(
        () => {
            modal.confirm({
                closable: true,
                icon: null,
                cancelButtonProps: {
                    type: "text"
                },
                content: "Are you sure you want to delete this message?",
                onOk: async () => {
                    await api.chatMessage.remove(message.id);
                    chat.removeMessage(message.id);
                    ntfmessage.success({ content: "Message was deleted" });
                }
            })
        },
        [ message, chat ]
    );

    const onLoadedMetadata = useCallback(
        () => setDuration(audioRef.current.duration),
        [ audioRef.current ]
    );

    const onTogglePause = useCallback(
        () => {
            if (audioRef.current) {
                if (paused) {
                    audioRef.current.play();
                } else {
                    audioRef.current.pause();
                }

                setPaused(e => !e);
            }
        },
        [ paused ]
    );

    const onRangeClick = useCallback(
        (val) => {
            setProgress(val);
            audioRef.current.currentTime = val;
        },
        [ audioRef.current ]
    );

    const onAudioEnd = useCallback(
        () => {
            setPaused(true);
            audioRef.current.pause()
        },
        [ ]
    );

    const onVolumeChange = useCallback(
        (val) => {
            setVolume(val);
            audioRef.current.volume = volume / 100;
        },
        [ audioRef.current ]
    );

    const onPlaybackRateChange = useCallback(
        (val) => {
            audioRef.current.playbackRate = val
        },
        [ audioRef.current ]
    );

    return (
        <div className="chat-message-voicemessage">
            <Button
                size="small"
                type="transparent"
                className="chat-form-input-button" 
                Icon={ paused ? IconPlay : IconPause }
                onClick={ onTogglePause } />
            <div className="chat-message-voicemessage-duration">
                { renderDuration(progress) }/{ renderDuration(duration) }
            </div>
            <Slider 
                tooltip={{ formatter: renderDuration }} 
                max={ duration } 
                value={ progress } 
                onChange={ onRangeClick } />

            <VolumeSlider value={ volume } onValueChange={ onVolumeChange }>
                <Button
                    size="small"
                    type="transparent"
                    className="chat-form-input-button" 
                    Icon={ IconVolume }/>
            </VolumeSlider>
            
            { isMine && !read && 
                <Button
                size="small"
                type="transparent"
                className="chat-form-input-button" 
                Icon={ IconDelete }
                onClick={ onDeleteClick } />
            }
            
            <AudioMenu src={ src } onPlaybackRateChange={ onPlaybackRateChange }>
                <Button
                    size="small"
                    type="transparent"
                    className="chat-form-input-button" 
                    Icon={ IconDots }/>
            </AudioMenu>

            <audio controls src={ src } onLoadedMetadata={ onLoadedMetadata } ref={ audioRef } onEnded={onAudioEnd }/>
        </div>
    )
}

export default VoiceMessage