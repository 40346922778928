import * as actions from "common/src/actions/moodboard"
import { App as AntApp, Input } from "antd"
import ConnectionSelector from "common/src/refactor/components/dialog/ConnectionSelector";
import { useCallback, useState } from "react"
import MoodboardDrawer from "common/src/refactor/components/moodboard/Drawer";
import user from "common/src/user"
import { ReactComponent as IconLink } from "common/src/svg/link.svg"
import { ReactComponent as IconTick } from "common/src/svg/tick.svg"
import ModalButton from "common/src/refactor/components/button/ModalButton";
import useDictRef from "common/src/hooks/useDictRef";

function useMoodboardActions({ moodboard, onDelete, onEdit, onDuplicate }) {

    const { message, modal } = AntApp.useApp();
    const [ sending, setSending ] = useState(false);
    const [ generating, setGenerating ] = useState(false);
    const [ editing, setEditing ] = useState(false);
    const [ deleting, setDeleting ] = useState(false);
    const [ duplicating, setDuplicating ] = useState(false);

    const [ currentModal, setCurrentModal ] = useState(null);
    const ref = useDictRef({ currentModal });
    
    const onSendClick = useCallback(
        async () => {
            const conns = await ConnectionSelector.show({
                multiple: true
            });

            if (!conns) {
                return;
            }

            setSending(true);

            for (let i = 0; i < conns.length; i++) {
                await actions.sendMoodboard(moodboard.id, conns[i].id);
            }

            if (conns.length === 1) {
                message.success({
                    content: `Moodboard sent to ${ conns[0].givenName } ${ conns[0].familyName }`,
                    icon: <></>
                });
            }
            else {
                const names = conns.map(c => `${ c.givenName } ${ c.familyName }`).join(", ");

                message.success({
                    content: `Moodboard sent to ${ names }`,
                    icon: <></>
                });
            }

            setSending(false);

        }, 
        [ moodboard, message ]
    );

    const deleteMoodboard = useCallback(
        async () => {
            setDeleting(true);
            await actions.remove(moodboard.id);

            message.success({
                content: `${ moodboard.name || "Moodboard" } has been deleted`,
                icon: <></>
            });

            setDeleting(false);
            onDelete && onDelete();
        },
        [ moodboard, message, onDelete ]
    );

    const onDeleteClick = useCallback(
        async () => {
            modal.confirm({
                closable: true,
                icon: null,
                width: 540,
                title: "Delete moodboard?",
                className: "delete-moodboard-modal",
                content:  <>
                    <p>You can restore this moodboard in </p>
                    <p>'Recently Deleted' from your profile within 30 days.</p>
                    <p>After that, it will be permanently deleted.</p>
                </>,
                okText: "DELETE NOW",
                cancelButtonProps: { 
                    style: { 
                        display: 'none' 
                    } 
                },
                okButtonProps: { 
                    style: {
                        textTransform: "uppercase"
                    }
                },
                onOk: async () => {
                    await deleteMoodboard();
                }
            })
        },
        [ modal, deleteMoodboard ]
    );
    
    const copyLink = useCallback(
        async (link) => {
            await navigator.clipboard.writeText(link);
            ref.currentModal?.update({
                content: 
                <div className="moodboard-share-content">
                    <p>Copy this link to share outside of the app</p>
                    <div>
                        <Input value={ link } disabled/>
                        <ModalButton Icon={ IconTick } disabled >Copied</ModalButton>
                    </div>
                    <p>For the best customer experience, ensure you've <a href="/profile" target="_blank">completed your profile</a></p>
                </div>
            })
        },
        [ ref ]
    )

    const onShareLinkClick = useCallback(
        async () => {
            setGenerating(true);
            const link = await actions.generatePublicLink(moodboard.id, user.geo().currency?.toLowerCase() || "gbp");
            setGenerating(false);

            const md = modal.info({
                closable: true,
                icon: null,
                width: 580,
                className: "moodboard-share-modal",
                title: "Share Moodboard",
                content: 
                <div className="moodboard-share-content">
                    <p>Copy this link to share outside of the app</p>
                    <div>
                        <Input value={ link } disabled/>
                        <ModalButton Icon={ IconLink } onClick={ copyLink.bind(null, link) }>Copy</ModalButton>
                    </div>
                    <p>For the best experience, ensure you've <a href="/profile" target="_blank">completed your profile</a></p>
                </div>
                ,
                okButtonProps: { 
                    style: {
                        display: 'none' 
                    }
                },
            })
            setCurrentModal(md);
        },
        [ modal, copyLink, moodboard ]
    )

    const onEditClick = useCallback(
        () => {
            setEditing(true);
            if (moodboard) {
                MoodboardDrawer.show({ moodboard }).then(() => {
                    setEditing(false);
                    onEdit && onEdit();
                });
            }
        },
        // eslint-disable-next-line
        [ moodboard, onEdit ]
    );

    const onDuplicateClick = useCallback(
        async () => {
            setDuplicating(true);
            await actions.copy(moodboard.id);

            message.success({
                content: "This moodboard has been duplicated, and can be found in your moodboards",
                icon: <></>
            });

            setDuplicating(false);
            onDuplicate && onDuplicate();
        },
        [ moodboard, message, onDuplicate ]
    );

    const moodboardApi = {
        onEditClick,
        onSendClick,
        onDeleteClick,
        onDuplicateClick,
        onShareLinkClick,

        loading: sending || deleting || editing || duplicating || generating
    }

    if (user.isOnly("Admin")) {
        moodboardApi.onSendClick = null;
    }

    if (moodboard?.friId !== user.id()) {
        moodboardApi.onEditClick = null;
        moodboardApi.onSendClick = null;
    }

    return moodboardApi;
}

export default useMoodboardActions;