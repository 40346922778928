import { useMemo } from "react"

import Image from "./Image"
import Footer from "./Footer"
import Retailer from "./Retailer"
import ImageSlider from "common/src/refactor/components/image-slider/ImageSlider"
import Dnd from "common/src/refactor/components/Dnd"

import useSubComponent from "common/src/refactor/hooks/useSubComponent"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import useCatalogueProductUrl from "common/src/refactor/hooks/useCatalogueProductUrl"

function ProductCard({ product, onClick, 
                        imageAsLink = false,
                        fluid = false,
                        size,
                        className, style,
                        slider = false, 
                        image, footer, retailer, 
                        before, children, attrs = {} }) {

    const cls = useMemo(
        () =>   [ 
                    "catalogue-product-card", 
                    className || "",
                    fluid ? "catalogue-product-card-fluid" : "",
                    size ? `catalogue-product-card-${ size }` : "",
                ].join(" "),
        [ className ]
    );

    const images = useMemo(
        () => (product.cdnImages || product.originalImages || product.images || [])
                        .map(i => typeof i === "string" ? i : i.src), 
        [ product ]
    );
    const productUrl = useCatalogueProductUrl(product);
    const onLinkClick = useSwallowEventCallback(
        () => onClick && onClick(product),
        [ product, onClick ]
    );

    const defaultSubProps = useMemo(() => ({ product }), [ product ]);
    const defaultSliderProps = useMemo(
        () => ({ images, className: "catalogue-product-card-image-slider" }),
        [ images ]
    );
    const sliderComponent = useSubComponent(ImageSlider, defaultSliderProps, slider);
    const imageComponent = useSubComponent(Image, defaultSubProps, image);
    const footerComponent = useSubComponent(Footer, defaultSubProps, footer);
    const retailerComponent = useSubComponent(Retailer, defaultSubProps, retailer);

    const imageOrSlider = useMemo(
        () => {
            if (images.length < 2) {
                return imageComponent;
            }
            return sliderComponent || imageComponent;
        },
        [ images, sliderComponent, imageComponent ]
    );

    return (
        <Dnd className={ cls } style={ style } { ...attrs }>
            { before }
            { imageOrSlider }
            { retailerComponent }
            { footerComponent }
            { (onClick || imageAsLink) && 
                <a href={ productUrl } 
                    className="catalogue-product-card-link" 
                    onClick={ onClick ? onLinkClick : null }/>}
            { children }
        </Dnd>
    )
}

export default ProductCard