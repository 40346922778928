import { useMemo } from "react"
import theme from "common/src/refactor/theme.json"

function Spacer({ size = "default" }) {

    const style = useMemo(
        () => {
            let s = theme.spacer[size] || size;
            return {
                width: s,
                height: s
            }
        },
        [ size ]
    );

    return (
        <div className="spacer" style={ style }/>
    )
}

export default Spacer