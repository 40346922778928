
import { useMemo } from "react"

import Select from "common/src/refactor/components/ant/Select"

const options = [
    {
        key: "all",
        value: "all",
        label: "All"
    },
    {
        key: "women",
        value: "women",
        label: "Women"
    },
    {
        key: "men",
        value: "men",
        label: "Men"
    }
]

export function GenderSelect({ className, ...props }) {
    const cls = useMemo(
        () => [ "selector-gender", className || "" ].join(" "),
        [ className ]
    );

    return (
        <Select 
            className={ cls }
            placeholder="Women/Men"
            options={ options } 
            { ...props }/>
    )
}
