
export default function (chat) {

    if (!chat.__normalized) {

        if (chat.lastMessage) {
            chat.lastMessage = chat.lastMessage.pop();
        }
    }

    chat.__normalized = true;
    return chat;
}