import { useMemo, useState } from "react"
import { useSelector } from "react-redux"

import userSelector from "common/src/selectors/user/current"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback";

const MAX_LENGTH = 200;

function ProfileBio() {
    const current = useSelector(userSelector);
    const [ showFull, setShowFull ] = useState(false);
    const long = useMemo(() => (current?.bio || "").length > MAX_LENGTH, [ current?.bio ]);
    const bio = useMemo(
        () => {
            let bio = current?.bio || "";
            if (long && !showFull) {
                bio = bio.substring(0, MAX_LENGTH) + "...";
            }
            return bio.replace(/\n/g, '<br/>')
        },
        [ current?.bio, showFull, long ]
    );

    const toggleMore = useSwallowEventCallback(
        () => setShowFull(prev => !prev),
        []
    );

    return (
        <div className="user-profile-bio">
            <label className="user-profile-title">Bio</label>
            <div className="user-profile-bio-content">
                <div dangerouslySetInnerHTML={{ __html: bio }}/>
                { long && 
                    <a href="/#" onClick={ toggleMore }>
                        { showFull ? "less" : "more" }
                    </a> }
            </div>
        </div>
    )
}

export default ProfileBio