import StoreModule from "common/src/lib/store/StoreModule"

const consPage = new StoreModule("consultation.page")

consPage.addEntity("cons");
consPage.addList("messages");


consPage.data.add("setProductReaction", (s, a) => {
    if (!s.data.cons) {
        return;
    }
    const c = { ...s.data.cons };
    const { productId, reaction } = a.payload;
    if (c) {
        s.data.cons.looks.forEach((l, inx1) => {
            l.products.forEach((p, inx2) => {
                if (p.id === productId) {
                    s.data.cons.looks[inx1].products[inx2].reaction = reaction;
                }
            })
        });
    }
})

consPage.data.add("setProductLastMessage", (s, a) => {
    if (!s.data.cons) {
        return;
    }
    const { productId, message } = a.payload;
    s.data.cons.looks.forEach((l, inx1) => {
        l.products.forEach((p, inx2) => {
            if (p.id === productId) {
                s.data.cons.looks[inx1].products[inx2].myLastMessage = { ...message };
            }
        })
    });
});

export let { initialSet, data, ui, reducer } = consPage.export();