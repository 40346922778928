import { useMemo, useRef, useEffect, useState } from "react"

import Footer from "./ProductFooter"

import { getLayoutClass, getGridStyle, getSlotClass } from "common/src/lib/look/layout2style"

function LookCardProduct({ 
    attrs = {},
    product, look, currentImage = 0, className,
    override = {}, slotIndex, is1x1, bigSlot, imageSize, biggestSlot,
    children }) {

    const [ containerSize, setContainerSize ] = useState({ width: 0, height: 0 });
    const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current) {
          const { width, height } = containerRef.current.getBoundingClientRect();
          setContainerSize({ width, height });
        }
      }, [ containerRef ]);

    const blockCls = useMemo(
        () => {

            if (!look || slotIndex === undefined) {
                return "";
            }

            const { layouts = {}, productMode = "multiple" } = ( look || {} ),
                    template = layouts['template'] || [],
                    order = layouts['order'] || [],
                    inx = order.findIndex(id => id === product.id),
                    templateBlock = productMode === "multiple" ? 
                                        template[ inx ] :
                                        template[ slotIndex ];

            return getLayoutClass(template, templateBlock);
        },
        [ !!look, look, slotIndex, product.id ]
    );

    const slotClasses = useMemo(
        () => {
            const { layouts = {} } = look || {},
            template = layouts['template'],
            slotClass = getSlotClass(slotIndex, template);
            const base = "look-card-product";
            const slotCls1 = slotClass.map(c => base +"-"+ c).join(" ");
            return [ slotCls1 ].join(" ");
        },
        [ slotIndex, look ]
    );

    const cls = useMemo(
        () => {
            const base = "look-card-product";

            return [ 
                base,
                className,
                slotIndex !== undefined ? blockCls : "",
                slotClasses,
            ].join(" ")
        },
        [ slotIndex, slotClasses, blockCls, className ]
    );


    const { slotStyle } = useMemo(
        () =>  {

            const { layouts = {}, productMode = "multiple" } = look,
                    template = layouts['template'],
                    order = layouts['order'] || [],
                    inx = order.findIndex(id => id === product.id),
                    images = product.cdnImages || product.images,
                    templateBlock = productMode === "multiple" || is1x1 ? 
                                        template[ inx ] :
                                        template[ slotIndex ],
                    productImageStyles = layouts[ product.id ],
                    productImageStyle = productMode === "multiple" || is1x1 ?
                                            productImageStyles[ currentImage ] :
                                            productImageStyles[ slotIndex ],

                    layoutConfig = Object.assign({}, templateBlock, override.layout || productImageStyle),
                    layoutOptions = { 
                        img: productMode === "multiple" || is1x1 ? 
                                images[ currentImage ] :
                                images[ slotIndex ], 
                        template,
                        imgSize: imageSize || {},
                        bigSlot: is1x1 || bigSlot,
                        slotIndex,
                    }

                    const slotStyle = getGridStyle(layoutConfig, layoutOptions, containerSize.width, 
                        containerSize.height)

            return { slotStyle };
        },
        [ !!look, currentImage, slotIndex, override, is1x1, look, 
            bigSlot, imageSize,
            product, product.images, product.cdnImages, containerSize ]
    );

    return (
        <div className={ cls } 
            style={ slotStyle } 
            ref={ containerRef }
            id={ `look-card-product-${ product.id }` }
            data-product-id={ product.id }
            { ...attrs }>
            <Footer product={ product } look={ look } biggestSlot={ biggestSlot }/>
            { children }
        </div>
    )
}

export default LookCardProduct