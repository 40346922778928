import { useMemo } from "react";
import { useSearchParams } from "react-router-dom"

function useParams() {

    const [ searchParams ] = useSearchParams();

    const params = useMemo(
        () => {
            return Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
              }, {});
        }, 
        [ searchParams ]
    )

    return params
}

export default useParams