import { useMemo, useCallback, useState, useEffect } from "react"
import { Flex, Dropdown, Badge, App as AntApp } from "antd"
import { NavLink } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { ReactComponent as IconNotification } from "common/src/svg/bell.svg"
import { ReactComponent as IconCurrency } from "common/src/svg/currency.svg"
import { ReactComponent as Logo } from "common/src/svg/full_logo.svg"

import Link from "common/src/components/Link"
import UserAvatar from "common/src/refactor/components/UserAvatar"
import ChangePasswordDialog from "common/src/refactor/components/profile/ChangePasswordDialog"
import EditBankDetailsDialog from "common/src/refactor/components/profile/EditBankDetailsDialog"

import { signOut } from "common/src/actions/user"
import swallowEvent from "common/src/lib/dom/swallowEvent"
import setCookie from "common/src/lib/cookie/set"
import hub from "common/src/hub"
import { data } from "common/src/store/user"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import findParent from "common/src/lib/dom/findParent"
import async from "common/src/lib/js/async"
import api from "app/api"
import NotificationsDialog from "./dialog/NotificationsDialog"
import useConnections from "common/src/refactor/hooks/useConnections"

const currencies = [
    {
        value: "gbp",
        label: "£ GBP"
    },
    {
        value: "usd",
        label: "$ USD"
    },
    {
        value: "eur",
        label: "€ EUR"
    }
]


function HeaderCurrencySelector() {

    const dispatch = useDispatch();
    const geo = useSelector(s => s.user.geo || {});
    const currency = geo.currency;
    const originalCurrency = geo.original || false;

    const setCurrency = useCallback(
        async (e) => {
            e && swallowEvent(e);
            const currency = e.target.dataset.currency;

            if (currency === "original") {
                setCookie("currency-original", "1");
                dispatch(data.geo.update({ original: true }));
                hub.dispatch("app", "currency-change", currency);
            }
            else {
                const geo = await api.unauth.get("/geo", { queryStringParameters: { 
                    currency: currency.toUpperCase() 
                }});
                setCookie("currency", currency);
                setCookie("currency-original", null);
                dispatch(data.geo.update({ currency, original: false, exchange: geo.exchange }));
                hub.dispatch("app", "currency-change", currency);
            }
        },
        []
    );

    const onClick = useSwallowEventCallback(
        () => {},
        []
    );

    const menu = useMemo(
        () => ({
            items: [
                {
                    key: "intro",
                    label: "Show prices in",
                    disabled: true
                },
                ...currencies.map(c => (
                    {
                        key: c.value,
                        label: (
                            <a href="/#" 
                                className={ currency.toLowerCase() === c.value ? "active" : null }
                                data-currency={ c.value } 
                                onClick={ setCurrency }
                                children={ c.label }/>
                        )
                    }
                )),
                {
                    key: "original",
                    label: (
                        <a href="/#" 
                            className={ originalCurrency ? "active" : null }
                            data-currency="original" 
                            onClick={ setCurrency }
                            children="Original currency"/>
                    )
                }
            ]
        }),
        [ setCurrency, currency, originalCurrency ]
    );

    return (
        <Dropdown rootClassName="header-currency-dropdown" menu={ menu }>
            <a href="/#" className="header-currency-selector" onClick={ onClick }>
                <IconCurrency/>
                { (currency && !originalCurrency) ? currency : null }
            </a>
        </Dropdown>
    )
}



function HeaderNotifications() {

    const unreadCount = useSelector(s => s.notifications.ui.unreadCount);
    const [ open, setOpen ] = useState(false);

    const onClick = useSwallowEventCallback(
        () => {
            NotificationsDialog.show();
        },
        []
    );

    const close = useCallback(
        (e) => {
            const parent = findParent(e.target, ".header-notifications-popover");
            if (!parent) {
                setOpen(false)
            }
        },
        []
    );

    useEffect(
        () => {
            document.body.addEventListener("click", close);
            return () => {
                document.body.removeEventListener("click", close);
            }
        },
        []
    );

    return (
        <a href="/#" className="header-notifications-trigger" onClick={ onClick }>
            <Badge size="small" count={ unreadCount.total }>
                <IconNotification/>
            </Badge>
        </a>
    )
}




function Header() {

    const connections = useConnections();
    const current = useSelector(s => s.user.current);
    const { modal } = AntApp.useApp();
    const [ connMenuOpen, setConnMenuOpen ] = useState(undefined);

    const connMenu = useMemo(
        () => {
            return {
                items: connections
                    .map(c => ({
                        key: c.id,
                        label: (
                            <Link to={ `/styling?contact=${ c.id }` }>
                                { c.givenName } { c.familyName }
                            </Link>
                        )
                    }))
            }
        },
        [ connections ]
    );

    const onChangePasswordClick = useSwallowEventCallback(
        () => {
            ChangePasswordDialog.show();
        },
        [],
        {
            stopPropagation: false
        }
    );

    const onEditBankDetailsClick = useSwallowEventCallback(
        () => {
            EditBankDetailsDialog.show()
        },
        [],
        {
            stopPropagation: false
        }
    )

    const onSignOutClick = useSwallowEventCallback(
        () => {
            modal.confirm({
                closable: true,
                icon: null,
                title: null,
                content: "Are you sure you're ready to log out of THE FLOORR?",
                cancelButtonProps: {
                    type: "text"
                },
                okText: "Log out",
                onOk: () => {
                    return signOut();
                }
            });
        },
        [ modal ],
        {
            stopPropagation: false
        }
    );

    const userMenu = useMemo(
        () => {
            return {
                items: [
                    {
                        key: "profile",
                        label: (
                            <NavLink to="/profile" children="Profile" className="dropdown-nav-link"/>
                        )
                    },
                    {
                        key: "deleted",
                        label: (
                            <NavLink to="/recently-deleted"
                            children="Recently deleted"
                            className="dropdown-nav-link"
                            />
                        )
                    },
                    {
                        key: "password",
                        label: (
                            <a href="/#" children="Change password" onClick={ onChangePasswordClick }/>
                        )
                    },
                    {
                        key: "bank",
                        label: (
                            <a href="/#" children="Edit bank details" onClick={ onEditBankDetailsClick }/>
                        )
                    },
                    {
                        key: "signout",
                        label: (
                            <a href="/#" children="Log out" onClick={ onSignOutClick }/>
                        )
                    }
                ]
            }
        },
        [ onSignOutClick ]
    );

    const onStylingMouseOut = useCallback(
        (e) => {
            async(() => setConnMenuOpen(undefined), 1000);
        },
        [ ]
    );

    const onStylingClick = useCallback(
        (e) => {
            setConnMenuOpen(false);
        },
        []
    );

    return (
        <div className="header">
            <Flex className="header-top">
                <Link to="/"><Logo/></Link>
                <Flex className="header-top-right">
                    <Dropdown 
                        rootClassName="header-user-dropdown"
                        menu={ userMenu } 
                        placement="bottom">
                        <NavLink 
                            to="/profile"
                            className="header-username"
                            data-cy="header-username">
                            { current.givenName } { current.familyName }
                            <UserAvatar user={ current }/>
                        </NavLink> 
                    </Dropdown>

                    <HeaderCurrencySelector/>
                    <HeaderNotifications/>
                </Flex>
            </Flex>
            <Flex className="header-menu">
                <NavLink to="/feed" className="header-menu-link">
                    Product Feed
                </NavLink>
                <NavLink to="/chat" className="header-menu-link">
                    Chat
                </NavLink>
                <Dropdown 
                    menu={ connMenuOpen === false ? { items: [] } : connMenu }>
                    <NavLink to="/styling" 
                        className="header-menu-link"
                        onMouseLeave={ onStylingMouseOut }
                        onClick={ onStylingClick }>
                        Styling
                    </NavLink>
                </Dropdown>
                <NavLink to="/moodboards" className="header-menu-link">
                    Moodboards
                </NavLink>
            </Flex>
        </div>
    )
}

export default Header