import { useMemo } from "react"

function ImageSliderIncicator({ current = 0, total = 0, size }) {

    const cls = useMemo(
        () => [ "image-slider-indicator", size ? `image-slider-indicator-${ size }` : "" ].join(" "),
        [ size ]
    );

    const dots = useMemo(
        () => {
            const dots = [];
            for (let i = 0; i < total; i++) {
                dots.push(
                <div 
                    key={ i }
                    className={ `image-slider-indicator-dot ${ current === i ? "active" : "" }` }/>
                );
            }
            return dots;
        },
        [ current, total ]
    );

    return (
        <div className={ cls }>
            { dots }
        </div>
    )
}

export default ImageSliderIncicator
