

export default {
    create: function(entity, graph) {
        return {
            query: `
                mutation Insert${entity}($objects: [${entity}_insert_input!]!) {
                    insert_${entity}(objects: $objects) { 
                        ---graph---
                    }
                }
            `,
            queryWithConstraint: `
                mutation Insert${entity}(
                    $objects: [${entity}_insert_input!]!
                    $conflict: ${entity}_on_conflict
                ) {
                    insert_${entity}(
                        objects: $objects,
                        on_conflict: $conflict
                    ) { 
                        ---graph---
                    }
                }
            `,
            queryType: `mutation Insert${entity}`,
            responseKey: `insert_${entity}`,
            defaultGraph: graph || "returning { id }",
            ignoreConflict: {
                constraint: `${entity}_pkey`,
                update_columns: []
            }
        };
    },

    update: function(entity) {
        return {
            query: `
                mutation Update${entity}(
                    $where: ${entity}_bool_exp!
                    $set: ${entity}_set_input!) {
                    update_${entity}(where: $where, _set: $set) { 
                        ---graph---
                    }
                }
            `,
            queryType: `mutation Update${entity}`,
            responseKey: `update_${entity}`,
            defaultGraph: "affected_rows"
        }
    },

    remove: function(entity) {
        return {
            query: `
                mutation Delete${entity}($where: ${entity}_bool_exp!) {
                    delete_${entity}(where: $where) { 
                        ---graph---
                    }
                }
            `,
            queryType: `mutation Delete${entity}`,
            responseKey: `delete_${entity}`,
            defaultGraph: "affected_rows"
        };
    },

    get: function(entity, graph, { idType = "uuid", subscription = false }) {
        const type = subscription ? "subscription" : "query";
        return {
            query: `
                ${ type } Get${entity} ($id: ${idType}!) {
                    ${entity}_by_pk(id: $id) {
                        ---graph---
                    }
                }
            `,
            queryType: `query Get${entity}`,
            defaultGraph: graph,
            responseKey: `${entity}_by_pk`
        };
    },

    list: function(entity, graph, opt = {}) {
        const type = opt.subscription ? "subscription" : "query";
        return {
            query: `
                ${ type } List${entity}s (
                    $where: ${entity}_bool_exp
                    $order: [${entity}_order_by!]
                    $offset: Int
                    $limit: Int
                ) {
                    ${entity}(where: $where, limit: $limit, 
                                offset: $offset, order_by: $order) {
                        ---graph---
                    }
                }
            `,
            queryWithCount: `
                ${ type } List${entity}s (
                    $where: ${entity}_bool_exp
                    $order: [${entity}_order_by!]
                    $offset: Int
                    $limit: Int
                ) {
                    ${entity}(where: $where, limit: $limit, 
                                offset: $offset, order_by: $order) {
                        ---graph---
                    }
                    ${entity}_aggregate(where: $where) 
                    {
                        aggregate {
                            ---countGraph---
                        }
                    }
                }
            `,
            queryType: `query List${entity}s`,
            defaultGraph: graph,
            responseKey: entity
        };
        //aggregate { count }
    },

    count: function(entity, graph, opt = {}) {
        const type = opt.subscription ? "subscription" : "query";
        let query = `
            ${ type } Count${entity}s (
                $where: ${entity}_bool_exp
                $order: [${entity}_order_by!]
                $distinct: [${entity}_select_column!]) 
            {
                ${entity}_aggregate(where: $where, order_by: $order,
                                    distinct_on: $distinct) 
                {
                    aggregate { ---graph--- }
                }
            }
        `;

        return {
            query,
            queryType: `query Count${entity}s`,
            defaultGraph: graph || "count",
            responseKey: `${entity}_aggregate`
        };
    },

    distinctValue: (entity, column) => {
        const query = `
            query Distinct${entity}_${column}s (
                $where: ${entity}_bool_exp
                $order: [${entity}_order_by!]
                $distinct: [${entity}_select_column!]) 
            {
                ${entity}_aggregate(where: $where, order_by: $order,
                                    distinct_on: $distinct) 
                {
                    nodes { ${ column } }
                }
            }
        `;

        return {
            query,
            queryType: `query Distinct${entity}_${column}s`,
            defaultGraph: "",
            responseKey: `${entity}_aggregate`
        };
    }
}
