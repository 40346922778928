import { useState, useContext, useCallback } from "react"
import ChatContext from "common/src/lib/chat/ChatContext"
import { useOn } from "@kuindji/observable-react"
import ConsultationCard from "common/src/refactor/components/consultation/Card"



function MessageConsultation({ message }) {

    const chat = useContext(ChatContext);
    const [ consultation, setConsultation ] = useState(() => chat.getConsultationsCache().find(c => c.id === message.consultationId));

    const updateConsultation = useCallback(
        () => !consultation && setConsultation(chat.getConsultations().find(c => c.id === message.consultationId)),
        [ consultation ]
    );

    useOn(chat, "consultations", updateConsultation);

    if (!consultation) {
        return null;
    }

    return (
        <ConsultationCard cons={ consultation } href={`/styling/${ consultation.id }`} showDate={ false }/>
    )
}

export default MessageConsultation;