
import { batch } from "react-redux"
import preloadImages from "common/src/lib/image/preload"
import store from "app/store"
import { data, ui, productScheme } from "common/src/store/look/product"
import api from "app/api"
import hub from "common/src/hub"
import user from "common/src/user"
import s3url from "common/src/lib/image/s3url"
import extractCatalogueId from "common/src/lambdalib/extractCatalogueId"
import extractUrlFromClick from "common/src/lambdalib/extractUrlFromClick"
import getClickDomain from "common/src/lib/url/clickDomain"
import preloadImage from "common/src/lib/image/preload"
import { convertCurrency } from "common/src/actions/catalogue"
import getExchangeRates from "common/src/lib/getExchangeRates"


export async function getCatalogueProduct(retailer = null, catalogueId = null, id = null, withRegion = true) {
    const currency = user.geo().currency;
    const payload = {
        region: withRegion ? "GB,UK,US" : null,
        // stage: "live",
        availability: "in-stock",
    }
    if (retailer) {
        payload.retailer = retailer;
    }
    if (catalogueId) {
        payload.product_web_id = catalogueId;
    }
    if (id) {
        payload.id = id;
    }

    const res = await api.backend.post("/catalogue/search", {
        body: payload
    });

    if (res && res.products[0]) {
        let p = res.products.find(p => p.currency === 'GBP');
        !p && (p = res.products[0]);

        const exchangeRates = await getExchangeRates();
        p = convertCurrency(p, currency, exchangeRates);

        return normalizeCatalogueProduct(p);
    }

    return null;
};

export function normalizeCatalogueProduct(p) {

    if (p.__cp_normalized) {
        return p;
    }

    const product = {};

    product.retailer = p.retailer;
    product.catalogueId = p.product_web_id;

    p.brand && (product.designers = [p.brand]);
    p.name && (product.name = p.name);
    p.description && (product.description = p.description);
    p.price && (product.price = p.price);
    p.currency && (product.currency = p.currency);
    p.commissionRate && (product.commissionRate = p.commissionRate)
    if (p.sale_price) {
        product.salePrice = p.sale_price;
    }
    else {
        product.salePrice = 0;
    }

    if (product.salePrice === product.price || product.salePrice > product.price) {
        product.salePrice = 0;
    }

    p.availability && (product.availability = p.availability);
    p.details.sizes && p.details.sizes.length &&
        (product.sizes = p.details.sizes);
    p.images && (product.images = p.images);

    product.url = p.details.product_url || p.details.url || extractUrlFromClick(p.details.click_url);
    product.details = { ...p.details };
    product.__cp_normalized = true;

    return product;
}

export async function preloadProductImages(productImages, sortImages = false) {
    let images = await preloadImages(
        productImages
            .filter((value, index, self) => self.indexOf(value) === index)
            .slice(0, 10)
    );

    if (sortImages) {
        images = images.sort(function (a, b) {
            let s1 = 1, s2 = 1;
            a.width && a.height && (s1 = a.width * a.height);
            b.width && b.height && (s2 = b.width * b.height);
            return s1 < s2 ? -1 : (s1 === s2 ? 0 : 1);
        });

        images = images.reverse();
    }

    /*const downloadable = images.length > 0 ?
                            await api.backend.post("/upload/check", { body: { url: images[0].src }})
                                    .then(resp => resp.success) :
                            false;*/
    const downloadable = true;

    images.forEach(i => i.downloadable = downloadable);

    /*for (let i = 0, l = images.length; i < l; i++) {
        await fetch(images[i].src, { mode: "no-cors" })
                .then(() => images[i].downloadable = true)
                .catch(err => images[i].downloadable = false);
    }*/

    return images;
}


export async function scrapeUrl(url) {

    const isLVR = !!url.match(/luisaviaroma/i);
    let product, cat = extractCatalogueId(url) || {};

    if (isLVR) {
        if (cat.retailer && cat.catalogueId) {
            product = await getCatalogueProduct("luisaviaroma", cat.catalogueId);
            if (product) {
                product.url = url;
            }
        }
        else {
            throw new Error("Failed to identify product");
        }
    }
    else {
        try {
            product = await api.scraper.get({ queryStringParameters: { url } });
            product.retailer = cat.retailer;
            product.catalogueId = cat.catalogueId;
        }
        catch (err) {
            console.error(err);

            if (!cat.retailer || !cat.catalogueId) {
                throw new Error("Failed to scrape url");
            }
            else {
                //product = {};
            }
        }
    }

    if (!product) {
        if (isLVR) {
            throw new Error("Product not found in the catalogue");
        }
        else {
            //throw new Error("Failed to scrape url");
        }
    }

    product && !product.url && (product.url = url);

    if (!isLVR) {
        cat = extractCatalogueId(product ? product.url : url);

        if (cat && cat.retailer && cat.catalogueId) {
            let p = await getCatalogueProduct(cat.retailer, cat.catalogueId, null, false);

            if (p) {
                if (product && product.images && product.images.length && p.images) {
                    p.images = p.images.concat(product.images);
                }
                product = Object.assign(product || {}, p);
            }
            else {
                if (!product) {
                    throw new Error("Failed to scrape url");
                }
                else {
                    // product scraped but not found in the catalogue
                    // we need to reproduce some fields
                    product.product_web_id = product.catalogueId;
                    const clickDomain = getClickDomain();
                    product.trackableUrl = `https://${clickDomain}/?` +
                        `cpid=${product.catalogueId}&` +
                        (user.loggedIn() ? `refid=${user.id()}&` : '') +
                        `url=${product.url}`;
                }
            }
        }
    }

    if (product.salePrice && product.price) {
        if (product.salePrice === product.price || product.salePrice > product.price) {
            product.salePrice = 0;
        }
    }

    if (product.images) {
        product.images = await preloadProductImages(product.images);
    }

    if (!product.designers) {
        product.designers = [];
    }

    return product;
}

export async function scrape(url) {

    try {

        store.dispatch(ui.scraper.running(true));
        const product = await scrapeUrl(url);

        batch(() => {
            store.dispatch(data.scraped.set(product));
            store.dispatch(ui.scraper.running(false));
        });

    }
    catch (err) {
        batch(() => {
            store.dispatch(ui.scraper.error(err.message));
            store.dispatch(ui.scraper.running(false));
        });

        hub.dispatch("error", "product-scrape", err);
    }
}


export const checkProductImages = async (images) => {

    for (let i = 0, l = images.length; i < l; i++) {
        const img = images[i];
        if (!img) {
            continue;
        }
        if (!img.src) {
            images[i] = null;
            continue;
        }
        if (img.src.indexOf("https://www.net-a-porter.com/variants") === 0 ||
            img.src.indexOf("https://net-a-porter.com/variants") === 0) {
            if (images.find(i => i && i.src && i.src.indexOf("https://cache.net-a-porter.com/variants") === 0)) {
                images[i] = null;
                continue;
            }
            let src = img.src;
            if (src.indexOf("https://www.net-a-porter.com/variants") === 0) {
                src = src.replace("www.net-a-porter.com", "cache.net-a-porter.com");
            }
            else if (src.indexOf("https://net-a-porter.com/variants") === 0) {
                src = src.replace("net-a-porter.com", "cache.net-a-porter.com");
            }
            src = src.split("/");
            src.pop();
            src.push("w1000.jpg");
            src = src.join("/");

            const tmp = await preloadImage([src]);
            if (tmp.length === 0) {
                images[i] = null;
                continue;
            }

            images[i].downloadable = false;
        }
    }

    return images.filter(i => !!i);
}


export async function submit(product, lookId, productMode) {

    let editMode = product.id && product.id.indexOf("tmp") !== 0,
        prodId = editMode ? product.id : null;

    try {

        let deletedProductStyles = product.deletedProductStyles,
            deletedDesigners = product.deletedDesigners,
            styleIds = [],
            designerIds = [],
            styles = product.productStyles,
            designers = product.designers,
            now = (new Date()).toISOString(),
            prodData = {},
            key;

        for (key in productScheme) {
            prodData[key] = null; // create writable property

            if (key === "images") {
                prodData[key] = product[key].map(i => ({ ...i }));
            }
            else if (product[key] !== "") {
                prodData[key] = product[key]; // assign value from immutable object
            }
        }

        delete prodData.id;
        delete prodData.productStyles;
        delete prodData.designers;

        prodData.description = prodData.description === null ? "" : prodData.description;
        prodData.image = '';
        //prodData.images = [];

        !editMode && (prodData.createdAt = prodData.createdAt || now);
        prodData.updatedAt = now;
        prodData.price && (prodData.price = parseFloat(prodData.price));
        prodData.lookId = lookId;

        if (prodData.originalImages) {
            prodData.originalImages = JSON.stringify(prodData.originalImages);
        }

        //prodData.position = position;

        // CATALOGUE CONNECTION
        if (!editMode) {
            if (!prodData.catalogueId) {
                const cat = extractCatalogueId(prodData.url);
                if (cat) {
                    prodData.retailer = cat.retailer;
                    prodData.catalogueId = cat.catalogueId;
                }
            }
            if (prodData.sizes) {
                prodData.sizes = JSON.stringify(prodData.sizes);
            }
        }
        else {
            delete prodData.sizes;
            delete prodData.retailer;
            delete prodData.catalogueId;
            delete prodData.availability;
        }


        /// UPLOAD IMAGE / REMOVE BG

        if (!editMode && prodData.images.length > 0) {

            const promises = [];

            for (const imageData of prodData.images) {

                const inx = prodData.images.indexOf(imageData);

                promises.push(new Promise(async (resolve) => {

                    //console.log("start image", imageData.src)

                    let s3key, removeBg;
                    removeBg = imageData.removeBg;

                    if (imageData.local) {
                        removeBg = false;
                        s3key = await api.backend.post("/upload/image", {
                            body: {
                                //name: uuidv4() + "." + mime2ext(product.imageData.mime),
                                contentType: product.imageData.mime,
                                data: product.imageData.data
                            }
                        })
                            .then(resp => resp.key);
                    }
                    else if (imageData.src) {
                        s3key = await api.backend.post("/upload/remote", {
                            body: {
                                url: imageData.src,
                                removeBg
                            }
                        })
                            .then(resp => {
                                removeBg = resp.bgRemoved;
                                return resp.key;
                            });
                    }

                    if (imageData.uploadKey) {
                        await api.backend.post("/upload/delete", {
                            body: { uploadKey: imageData.uploadKey }
                        });
                    }

                    if (imageData.uploadKey) {
                        await api.backend.post("/upload/delete", {
                            body: { uploadKey: imageData.uploadKey }
                        });
                    }

                    try {
                        await preloadImages([s3key ? s3url(s3key) : !imageData.local && imageData.src ? imageData : {}])
                            .then(images => {
                                const image = images[0];
                                image.key = s3key;
                                image.bgRemoved = removeBg;
                                if (typeof prodData.images === "string") {
                                    const parsed = JSON.parse(prodData.images);
                                    parsed[inx] = image;
                                    prodData.images = JSON.stringify(parsed);
                                } else {
                                    prodData.images[inx] = image;
                                }
                            });
                    }
                    catch (err) {
                        console.log(err);
                        delete prodData.images[inx];
                    }
                    resolve();
                }));
            }

            //console.log("awaiting all images", promises.length);
            await Promise.allSettled(promises);
            console.log("all images resolved")
            //console.log("all images done");

            prodData.images = JSON.stringify(prodData.images);
        }
        else if (editMode) {

            const promises = [];

            for (const imageData of product.images) {

                promises.push(new Promise(async (resolve) => {
                    //console.log("start image", imageData.key || imageData.src)
                    const inx = product.images.indexOf(imageData);
                    let bgRemoved = false, newImagesAdded = false, s3key = null;

                    // update existing image
                    if (imageData.key) {

                        if (imageData.removeBg) {
                            try {
                                s3key = await api.backend.post("/removebg/remove", {
                                    body: {
                                        productId: prodId,
                                        index: inx,
                                        key: imageData.key
                                    }
                                })
                                    .then(resp => resp.key);

                                try {
                                    await preloadImages([s3url(s3key, 1000)])
                                        .then(images => {
                                            const image = images[0];
                                            image.key = s3key;
                                            image.bgRemoved = true;
                                            if (typeof prodData.images === "string") {
                                                const parsed = JSON.parse(prodData.images);
                                                parsed[inx] = image;
                                                prodData.images = JSON.stringify(parsed);
                                            } else {
                                                prodData.images[inx] = image;
                                            }
                                        });
                                } catch (err) {
                                    console.log(err)
                                    delete prodData.images[inx];
                                }
                            }
                            catch (err) {
                                console.log(err)
                                //console.log(err, cloneDeep(product.images[i]))
                                //logger.log(err, cloneDeep(product.images[i]));
                            }
                            bgRemoved = true;
                        }
                    }
                    // upload new image
                    else {

                        bgRemoved = false;

                        try {
                            //console.log("upload remote", cloneDeep(product.images[i]))
                            s3key = await api.backend.post("/upload/remote", {
                                body: {
                                    url: imageData.src,
                                    removeBg: imageData.removeBg || false
                                }
                            })
                                .then(resp => {
                                    bgRemoved = resp.bgRemoved;
                                    return resp.key;
                                });
                            console.log(s3key)
                        }
                        catch (err) {
                            s3key = '';
                            console.log(err);
                            //console.log(err, cloneDeep(product.images[i]));
                            //logger.log(err, cloneDeep(product.images[i]));
                        }

                        newImagesAdded = true;

                        if (imageData.uploadKey) {
                            try {
                                await api.backend.post("/upload/delete", {
                                    body: { uploadKey: imageData.uploadKey }
                                });
                            }
                            catch (err) {
                                //console.log(err, cloneDeep(product.images[i]));
                                //logger.log(err, cloneDeep(product.images[i]));
                            }
                        }

                        try {
                            if (s3key) {
                                try {
                                    await preloadImages([s3url(s3key, 1000)])
                                        .then(images => {
                                            const image = images[0];
                                            console.log({ ...image })
                                            image.src = s3url(s3key);
                                            image.key = s3key;
                                            image.bgRemoved = bgRemoved;
                                            console.log({ ...image })
                                            if (typeof prodData.images === "string") {
                                                const parsed = JSON.parse(prodData.images);
                                                parsed[inx] = image;
                                                prodData.images = JSON.stringify(parsed);
                                            } else {
                                                prodData.images[inx] = image;
                                            }
                                        });
                                } catch (err) {
                                    console.log(err)
                                    delete prodData.images[inx];
                                }
                            }
                        }
                        catch (err) {
                            console.log(err)
                            //console.log(err, cloneDeep(product.images[i]));
                            //logger.log(err, cloneDeep(product.images[i]));
                            prodData.images[i].src = '';
                            prodData.images[i].key = '';
                        }
                    }

                    //console.log("end image", imageData.key || imageData.src)
                    resolve();
                }))
            }

            await Promise.allSettled(promises);
            console.log("all images resolved")

            delete prodData.image;

            console.log(prodData.images)
            prodData.images = JSON.stringify(prodData.images);
            console.log(prodData.images)

            /*if (!bgRemoved || newImagesAdded) {
                prodData.images = JSON.stringify(prodData.images);
            }
            else {
                delete prodData.images;
            }*/
        }


        /// CREATE/UPDATE PRODUCT

        if (editMode) {
            //console.log("product data", cloneDeep(prodData));
            //await 
            api.product.update(prodId, prodData);
        }
        else {
            prodId = await api.product.create(prodData).then(r => r.id);
        }

        /// CREATE TAGS

        if (styles.length > 0 || designers.length > 0) {
            const allTags = await api.backend.post("/tag/prepare", {
                body: {
                    ProductStyle: styles,
                    Designer: designers
                }
            });

            styleIds = allTags.ProductStyle.map(t => t.id);
            designerIds = allTags.Designer.map(t => t.id);
        }

        /// DELETE REMOVED TAGS

        if (editMode) {
            deletedProductStyles && deletedProductStyles.forEach(s =>
                s.id.indexOf("tmp_") === -1 &&
                api.productProductStyle.remove({
                    productStyleId: { _eq: s.id },
                    productId: { _eq: prodId }
                })
            );
            deletedDesigners && deletedDesigners.forEach(d =>
                d.id.indexOf("tmp_") === -1 &&
                api.productDesigner.remove({
                    designerId: { _eq: d.id },
                    productId: { _eq: prodId }
                })
            );
        }

        /// ATTACH TAGS

        if (styleIds.length > 0) {
            // we're not using it anymore
            //await 
            api.productProductStyle.create(
                styleIds.map(productStyleId => ({
                    productStyleId,
                    productId: prodId
                })),
                "returning { productStyleId }",
                {
                    constraint: "Product_ProductStyle_pkey",
                    update_columns: []
                }
            );
        }

        if (designerIds.length > 0) {
            await api.productDesigner.create(
                designerIds.map(designerId => ({
                    designerId,
                    productId: prodId
                })),
                "returning { designerId }",
                {
                    constraint: "Product_Designer_pkey",
                    update_columns: []
                }
            );
        }

        hub.dispatch("look", "product-created", { id: prodId });
        return prodId;

    }
    catch (err) {
        hub.dispatch("error", "product-create", err);
        //return Promise.reject(err);
        return prodId;
    }
}

export function remove(id) {

    store.dispatch(ui.deleting.start(id));

    return api.product.remove(id)
        .then(() => {
            hub.dispatch("look", "product-removed", { id });
        })
        .finally(() => {
            store.dispatch(ui.deleting.stop(id))
        })
        .catch(err => {
            hub.dispatch("error", "product-remove", err);
        });
}