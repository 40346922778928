import useConsultationActions from "app/hooks/consultation/useConsultationActions";
import Button from "common/src/refactor/components/button/Button";

import { ReactComponent as IconPlus } from "common/src/svg/add.svg"
import { ReactComponent as IconSend } from "common/src/svg/send.svg"
import { ReactComponent as IconEdit } from "common/src/svg/edit.svg"
import { ReactComponent as IconDuplicate } from "common/src/svg/duplicate.svg"
import { ReactComponent as IconDelete } from "common/src/svg/delete.svg"

function ConsultationActions({ 
    cons, onEdited, onDeleted, createFrom,
    showSend = true, showDelete = true, showEdit = true, showDuplicate = true,
    showAddLook = true
}) {
    const { 
        onAddLookClick, onDeleteClick, 
        onDuplicateClick, onEditClick, onSendClick 
    } = useConsultationActions({ cons, onEdited, fromHref: createFrom, onDeleted });

    return (
        <div className="consultation-actions">
            { (showAddLook && onAddLookClick) && 
            <Button 
                type="secondary"
                shape="circle"
                size="lmedium"
                Icon={ IconPlus }
                onClick={ onAddLookClick }
                text="Add a look"
                iconPosition="before"/> }
        
            { (showSend && onSendClick) && 
            <Button 
                type="secondary"
                shape="circle"
                size="lmedium"
                Icon={ IconSend }
                onClick={ onSendClick }
                disabled={ !cons.customerId || cons.hidden }
                text="Send"
                iconPosition="before"/> }
        
            { (showEdit && onEditClick) &&
            <Button 
                type="secondary"
                shape="circle"
                size="lmedium"
                Icon={ IconEdit }
                onClick={ onEditClick }
                text="Edit"
                iconPosition="before"/> }
        
            { (showDuplicate && onDuplicateClick) && 
            <Button 
                type="secondary"
                shape="circle"
                size="lmedium"
                Icon={ IconDuplicate }
                onClick={ onDuplicateClick }
                text="Duplicate"
                iconPosition="before"/> }
        
            { (showDelete && onDeleteClick) && 
            <Button 
                type="secondary"
                shape="circle"
                size="lmedium"
                Icon={ IconDelete }
                onClick={ onDeleteClick }
                text="Delete"
                iconPosition="before"/> }
        </div>
    )
}

export default ConsultationActions;