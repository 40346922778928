import { useState, useCallback } from "react"

import useStateWithGetter from "common/src/hooks/useStateWithGetter";

function useLocalStorage(key, initialValue) {

    const [storedValue, setStoredValue, getStoredValue] = useStateWithGetter(() => {
        if (typeof window === "undefined") {
            return initialValue;
        }
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // If error also return initialValue
            console.log(error);
            return initialValue;
        }
    });

    const setValue = useCallback(
        (value) => {
            try {
                // Allow value to be a function so we have same API as useState
                const valueToStore = value instanceof Function ? value(getStoredValue()) : value;
                setStoredValue(valueToStore);
                // Save to local storage
                if (typeof window !== "undefined") {
                    window.localStorage.setItem(key, JSON.stringify(valueToStore));
                }
            } catch (error) {
                // A more advanced implementation would handle the error case
                console.log(error);
            }
        },
        [ key, getStoredValue ]
    );

    return [storedValue, setValue];
}

export default useLocalStorage;