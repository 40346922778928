import { useState, useCallback, useMemo, useEffect, Fragment } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Spin, App as AntApp } from "antd"
import { useSelector } from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"

import Button from "common/src/refactor/components/button/Button"
import Spacer from "common/src/refactor/components/Spacer"
import Page from "common/src/refactor/components/Page"
import ProductCard from "common/src/refactor/components/catalogue/product/Card"
import CatalogueProductDrawer from "common/src/refactor/components/catalogue/product/Drawer"
import FeedDialog from "common/src/refactor/components/catalogue/FeedDialog"
import { ReactComponent as IconRearrange } from "common/src/svg/burger2.svg"

import { catalogueProductLoader } from "common/src/actions/catalogueLocal"
import useQuery from "common/src/refactor/hooks/useQuery"
import useDictRef from "common/src/hooks/useDictRef"
import user from "common/src/user"
import api from "common/src/api"
import * as actions from "common/src/actions/moodboard"
import useMoodboardActions from "app/hooks/moodboard/useMoodboardActions"
import ProductContextMenu from "app/components/product/ContextMenu"
import useHub from "common/src/hooks/useHub"
import { LinkSourceContextProvider } from "common/src/refactor/lib/LinkSourceContext"

const footerProps = {
    addNameSpacing: true
}

const PRODUCTS_PER_PAGE = 18;

function RearrangeButton() {
    return (
        <Button type="text" Icon={ IconRearrange } className="moodboard-product-rearrange" />
    )
}

function MoodboardProductCard({ moodboard, product, onMove, ...rest }) {

    const attrs = useMemo(
        () => {
            if (moodboard.friId !== user.id()) {
                return null;
            }

            return {
                id: "catalogue-product-" + product.id,
                draggable: { 
                    data: { id: product.id },
                    imageElId: "catalogue-product-" + product.id,
                },
                droppable: { 
                    data: { id: product.id }, 
                    cardSelector: ".catalogue-product-card",
                    drop: (to, what) => onMove(what.id, to.id)
                    //this.moveBefore(what.id, to.id)
                }
            }
        },
        [ product, moodboard, onMove ]
    );

    return (
        <ProductCard product={ product } attrs={ attrs } { ...rest }/>
    )

}

function PageMoodboard() {

    const { message } = AntApp.useApp();
    const { moodboardId } = useParams();
    const [ moodboard, setMoodboard ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ page, setPage ] = useState(0);
    const [ addingProducts, setAddingProducts ] = useState(false);
    const geo = useSelector(s => s.user.geo || {});
    const currency = geo.currency;
    const originalCurrency = geo.original || false;
    const isOwn = useMemo(() => moodboard?.friId === user.id(), [ moodboard ]);
    const navigate = useNavigate();

    const ref = useDictRef({ moodboardId, moodboard });

    const loadMoodboard = useCallback(
        async () => {
            setLoading(true);
            const moodboard = await api.moodboard.get(ref.moodboardId);
            setMoodboard(moodboard);
            setLoading(false);
            console.log(moodboard);
        },
        // eslint-disable-next-line
        []
    );

    const { data: products, setData: setProducts,
            isLoading: productsLoading, 
            refetch,
            extraData: { hasMore } 
    } = useQuery(
            catalogueProductLoader,
            [ moodboardId, page, currency, originalCurrency ],
            {
                rowKey: "id",
                enabled: !!moodboardId,
                append: page > 0,
                params: {
                    moodboardId,
                    limit: PRODUCTS_PER_PAGE,
                    offset: page * PRODUCTS_PER_PAGE
                },
                processResponse: (response) => {
                    //console.log(response)
                    return { 
                        data: response, 
                        count: response.count,
                        hasMore: response.length >= PRODUCTS_PER_PAGE
                    }
                }
            }
    );

    useHub("moodboard", "products-removed", async () => await refetch());

    const onEdit = useCallback(
        () => {
            loadMoodboard();
        },
        // eslint-disable-next-line
        [ moodboard ]
    );

    const onDelete = useCallback(
        () => {
            navigate("/moodboards");
        },
        // eslint-disable-next-line
        [ ]
    );

    const { onDeleteClick, onEditClick, loading: actionLoading } = useMoodboardActions({ moodboard, onEdit, onDelete });

    const loadMore = useCallback(
        () => setPage(page => page + 1),
        []
    );

    const onAddProductsClick = useCallback(
        () => {
            setAddingProducts(true);
            console.log("add products")
            const p = FeedDialog.show("moodboard-feed-dialog");
            p.then(async (products) => {
                if (products.length > 0) {
                    await actions.addProducts(moodboardId, products);
                    await refetch();

                    message.success({
                        content: 
                            (products.length > 1 ? 'Products' : 'Product') + 
                            ' added to Moodboard',
                        icon: <></>
                    });
                }
                setAddingProducts(false);
            });
        },
        [ moodboardId, refetch, message ]
    );

    const onProductClick = useCallback(
        (p) => CatalogueProductDrawer.show({ 
            product: p,
            source: {
                type: "moodboard",
                id: moodboardId
            }
        }), 
        [ moodboardId ]
    );

    const onProductMove = useCallback(
        async (productId, beforeId) => {
            if (productId === beforeId) {
                return;
            }
    
            const fromInx = products.findIndex(p => p.id === productId);
            const toInx = products.findIndex(p => p.id === beforeId);
            const sourceProduct = products[fromInx];
            const targetProduct = toInx - fromInx === 1 ? products[toInx + 1] : products[toInx];
            const newProducts = [ ...products ];
            if (fromInx < toInx) {
                newProducts.splice(fromInx, 1);
                newProducts.splice(toInx - 1, 0, sourceProduct);
            }
            else {
                newProducts.splice(fromInx, 1);
                newProducts.splice(toInx, 0, sourceProduct);
            }
    
            //this.setState({ products: newProducts });
            setProducts(newProducts);
            await actions.rearrange(moodboardId, sourceProduct, targetProduct);
        },
        [ products, setProducts, moodboardId ]
    );

    useEffect(
        () => { 
            setPage(0);
            loadMoodboard(); 
        },
        // eslint-disable-next-line
        [ moodboardId ]
    );

    useEffect(
        () => {
            if (moodboard) {
                const isRecent = (Date.now() - new Date(moodboard.createdAt).getTime()) < 5000;
                if (isRecent) {
                    onAddProductsClick()
                }
            }
        },
        [ moodboard, onAddProductsClick ]
    )

    return (
        <LinkSourceContextProvider type="moodboard" id={ moodboardId }>
        <Page 
            loading={ loading || (productsLoading && products.length === 0) } 
            className="page-moodboard">
            <Spacer size="xlarge"/>
            <div className="toolbar">
                <div>
                    <h2>{ moodboard?.name }</h2>
                    { moodboard?.description &&
                        <p>{ moodboard?.description }</p> }
                </div>

                { isOwn && 
                    <Fragment>
                        { isOwn && 
                            <Button 
                                text="Add products"
                                type="secondary" 
                                onClick={ onAddProductsClick }
                                loading={ addingProducts }/> }
                        { onEditClick && 
                            <Button 
                                text="Edit" 
                                loading={ actionLoading }
                                onClick={ onEditClick }
                                type="secondary" 
                                /> }
                        <Button text="Delete" type="secondary" loading={ actionLoading }  onClick={ onDeleteClick }/>
                    </Fragment> }
                
            </div>
            <Spacer size="xlarge"/>
            <InfiniteScroll 
                className="page-moodboard-scroller page-feed-scroller-full-page"
                dataLength={ products.length }
                hasMore={ hasMore }
                next={ loadMore }
                loader={ products.length > 0 ? 
                            <Spin spinning className="infinite-scroll-spinner"/> : 
                            null }>
                <div className="catalogue-products">
                    { products.map(p => (
                        <MoodboardProductCard 
                            key={ p.id }
                            moodboard={ moodboard }
                            product={ p }
                            footer={ footerProps }
                            onClick={ onProductClick }
                            onMove={ onProductMove }>
                            <RearrangeButton/>
                            <ProductContextMenu product={ p } showAddToMoodboard={ false } showRemoveFromMoodboard={ true } moodboardId={ moodboard.id }/>
                        </MoodboardProductCard>
                    ))}
                </div>
            </InfiniteScroll>
            <FeedDialog dialogName="moodboard-feed-dialog"/>
        </Page>
        </LinkSourceContextProvider>
    )
}

export default PageMoodboard