import { useState, useCallback, useContext } from "react"
import { Input } from "antd"

import { ReactComponent as IconSearch } from "common/src/svg/search.svg"

import Button from "common/src/refactor/components/button/Button"
import NullForm from "common/src/components/NullForm"
import MoodboardCard from "common/src/refactor/components/moodboard/Card"

import * as actions from "common/src/actions/moodboards"
import useQuery from "common/src/refactor/hooks/useQuery"
import useInputHander from "common/src/refactor/hooks/userInputHandler"
import useDictRef from "common/src/hooks/useDictRef"
import useHub from "common/src/hooks/useHub"
import LookBuilderContext from "../LookBuilderContext"
import EmptyMessage from "common/src/refactor/components/EmptyMessage"
import Spacer from "common/src/refactor/components/Spacer"

const PER_PAGE = 45;

function LookBuilderSearchMoodboards({ open = false }) {

    const builder = useContext(LookBuilderContext);
    const [ page, setPage ] = useState(0);
    const [ query, setQuery ] = useState("");
    const [ appliedQuery, setAppliedQuery ] = useState("");
    const loadMore = useCallback(
        () => setPage(page => page + 1),
        []
    );
    
    const { data: moodboards, 
            isLoading, 
            refetch,
            extraData: { hasMore } } = 
        useQuery(
            actions.loadMoodboards,
            [ page, appliedQuery ],
            {
                params: {
                    // we can take products from all moodboards
                    //mine: true,
                    page,
                    query: appliedQuery,
                    perPage: PER_PAGE
                },
                rowKey: "id",
                append: page > 0,
                processResponse: (response) => {
                    return { 
                        data: response.items, 
                        count: response.count,
                        hasMore: response.items.length >= PER_PAGE
                    }
                },
                initialData: []
            }
        );

    const ref = useDictRef({ loadMore, hasMore, isLoading });

    const handleQueryChange = useInputHander(setQuery);
    const onSearch = useCallback(
        (value, e, { source }) => {
            if (source === "clear") {
                setQuery("");
            }
            setPage(0);
            setAppliedQuery(value);
        },
        [ setQuery ]
    );

    const onMoodboardClick = useCallback(
        (m) => {
            builder.trigger("search-select-moodboard", m);
        },
        [ builder ]
    );

    // const onCreateMoodboardClick = useCallback(
    //     () => {
    //         MoodboardDrawer.show().then(m => {
    //             if (m) {
    //                 refetch();
    //             }
    //         });
    //     },
    //     [ refetch ]
    // );

    const onScroll = useCallback(
        (e) => {
            const el = e.target;
            if (ref.hasMore && !ref.isLoading) {
                if (el.scrollTop / (el.scrollHeight - el.offsetHeight) > 0.75) {
                    ref.loadMore();
                }
            }
        },
        // eslint-disable-next-line
        []
    );

    useHub("moodboard", "created", refetch);
    useHub("moodboard", "updated", refetch);
    useHub("moodboard", "removed", refetch);
    useHub("moodboard", "product-added", refetch);
    useHub("moodboard", "product-removed", refetch);

    if (!open) {
        return null;
    }

    return (
        <div className="look-builder-search-moodboards">
            <Spacer size="0rem"/>
            <NullForm className="toolbar">
                <Input.Search 
                    placeholder="Search moodboards"
                    size="large"
                    enterButton={
                        <Button 
                            Component="a"
                            onClick={ e => e.preventDefault() }
                            Icon={ IconSearch }
                            text="Search" 
                            type="primary"/>
                    }
                    allowClear
                    onChange={ handleQueryChange }
                    value={ query }
                    onSearch={ onSearch }/>
            </NullForm>
            <div className="look-builder-search-moodboards-header">
                <h4>Your moodboards</h4>
                {/*<Button
                    Icon={ IconAdd }
                    text="Create moodboard"
                    onClick={ onCreateMoodboardClick }
                    type="text"/>*/}
            </div>
            <div className="look-builder-search-moodboards-cards" onScroll={ onScroll }>
                { moodboards.map(m => (
                    <MoodboardCard 
                        key={ m.id } 
                        onClick={ onMoodboardClick }
                        moodboard={ m }/>
                ))}

                { (moodboards.length === 0 && query && !isLoading) && 
                    <EmptyMessage size="small" message="No moodboards matched your search"/>
                }
            </div>
        </div>
    )
}

export default LookBuilderSearchMoodboards
