import hub from "common/src/hub"

function _add(cls) {
    document.documentElement.classList.add(cls);
}

function _rm(cls) {
    document.documentElement.classList.remove(cls);
}

function onUserAuth(state) {
    state ? 
        _add("logged-in") :
        _rm("logged-in");
}

function onUserSignOut() {
    _rm("logged-in");
}

function onThemeChange(theme) {
    _rm("theme-dark");
    _rm("theme-light");
    _add("theme-" + theme);
}

//let keepScroll = false;

function onLocationChange() {
    //if (!keepScroll) {
    //    window.scrollTo({top: 0});
    //}
    //keepScroll = false;

    if (window.ga) {
        window.ga('set', 'page', window.location.pathname);
        window.ga('send', 'pageview');
    } 
}

//function onKeepScrollPosition() {
//    keepScroll = true;
//}

function onOverlayShow() {
    _add("modal");
}

function onOverlayHide() {
    _rm("modal");
}

let prevDirection;

function onScroll({ scrollTop, direction }) {
    if (prevDirection !== direction) {
        if (prevDirection) {
            _rm("scrolling-" + prevDirection);
        }
        _add("scrolling-" + direction);
        prevDirection = direction;
    }
}

export default {
    init: function() {
        hub.listen("app-auth", "stateChange", onUserAuth);
        hub.listen("auth", "signOut", onUserSignOut);
        hub.listen("app", "theme", onThemeChange);
        hub.listen("app", "locationChange", onLocationChange);
        //hub.listen("app", "keepScrollPosition", onKeepScrollPosition);
        hub.listen("app", "scroll", onScroll);
        hub.listen("dialog", "overlayShow", onOverlayShow);
        hub.listen("dialog", "overlayHide", onOverlayHide);

        document.body.addEventListener("click", e => {
            hub.dispatch("app", "bodyClick", e);
        })

        //document.addEventListener(
        //    'gesturestart', (e) => e.preventDefault()
        //);
    }
}