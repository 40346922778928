import StoreModule from "common/src/lib/store/StoreModule"

let userSearch = new StoreModule("userSearch");
userSearch.addList("list");

userSearch.state.ui.list.lastQuery = null;
userSearch.ui.children.list.add("lastQuery", (s,a) => {
    s.ui.list.lastQuery = a.payload;
});

export let { reducer, data, ui, initialState } = userSearch.export();
