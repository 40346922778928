import { useRef, useEffect } from "react"


function add(list, c) {
    if (c) {
        const clss = c.split(" ").map(c => c.trim()).filter(c => !!c);
        clss.forEach(c => list.push(c));
    }
}

function useClassEffect(elRef, classes) {

    const clsRef = useRef([]);

    useEffect(
        () => {
            let newcls = [], oldcls = [];
            /*if (clsRef.current === null) {
                clsRef.current = [ ...classes ];
                classes.forEach(c => add(newcls, c));
            }
            else {*/
                clsRef.current.forEach((c) => {
                    if (classes.indexOf(c) === -1) {
                        add(oldcls, c);
                    }
                });
                classes.forEach((c) => {
                    if (clsRef.current.indexOf(c) === -1) {
                        add(newcls, c);
                    }
                });
            //}

            clsRef.current = [ ...classes ];
            oldcls.forEach(cls => elRef.current.classList.remove(cls));
            newcls.forEach(cls => elRef.current.classList.add(cls));
        },
        classes
    );
}

export default useClassEffect