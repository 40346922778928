import upload from "common/src/lib/image/upload"
import preload from "common/src/lib/image/preload"
import readInputFile from "common/src/lib/dom/readInputFile"
//import s3url from "common/src/lib/image/s3url"
//import api from "app/api"
import finalizeUpload from "common/src/lib/image/finalizeUpload"

async function uploadImage(file) {
    const uploadKey = await upload(file);
    return await finalizeUpload(uploadKey);
}

async function getImageProps(file) {
    const { mime, data } = await readInputFile(file);
    const src = `data:${ mime };base64,${ data }`;
    const [ info ] = await preload([ src ]);
    delete info.src;
    return info;
}

export async function prepareLookImage(file) {

    const [ src, info ] = await Promise.all([
        uploadImage(file),
        getImageProps(file)
    ]);

    return { ...src, info };
}