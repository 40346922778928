import api from "common/src/api"

export default async (blob, mime) => {
    const { url, s3key } = await api.backend.post("/upload/url", { body: { ext: "audio" }});
    await fetch(url, { 
        method: "PUT", 
        body: blob,
        headers: {
            "Content-type": mime || blob.type || "audio/m4a"
        }
    });
    return s3key;
}
