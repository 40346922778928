import Message from "./Message";

function ChatMessageReply({ message }) {

    return (
        <div className="chat-message-reply">
            <Message message={ message.replySource } showInfo={ false } showReply={ false }/>
        </div>
    );
}

export default ChatMessageReply