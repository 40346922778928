import { Modal, Spin, Input } from "antd"
import { useCallback, useState, useMemo, useEffect } from "react"
import { useSelector } from "react-redux"

import Button from "../button/Button"
import { ReactComponent as IconSearch } from "common/src/svg/search.svg"
import { ReactComponent as IconClose } from "common/src/svg/close.svg"

import useDualState from "common/src/refactor/hooks/useDualState"
import useInputHander from "common/src/refactor/hooks/userInputHandler"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import store from "app/store"
import { ui } from "common/src/store/dialogs"
import app from "app/appContext"
import useOnAppEvent from "../../hooks/useOnAppEvent"
import Radio from "common/src/refactor/components/form/Radio";
import useSelection from "common/src/refactor/hooks/useSelection"
import { consultationsLoader } from "common/src/actions/consultations"
import ConsultationCard from "./Card"
import user from "common/src/user"
import useUpdateEffect from "common/src/hooks/useUpdateEffect"
import swallowEvent from "common/src/lib/dom/swallowEvent"
import EmptyMessage from "common/src/refactor/components/EmptyMessage"


const selectionPromise = {};

const DIALOG_NAME = "consultations-dialog";

const params = [
    {
        name: "query",
        default: ""
    }
];

function ConsultationSelect({ id, selected, onChange }) {
    const onClick = useSwallowEventCallback(
        () => onChange(id, !selected),
        [ selected, id, onChange ]
    )
    
    return (
        <a href="/#" 
        className="chat-moodboard-card-select"
        onClick={ onClick }>
            <Radio checked={ selected } size="medium"/>
        </a>
    )
}

function Consultations({ dialogName = DIALOG_NAME, contactId = null }) {
    const { query, setQuery } = useDualState({
        params,
        mode: "state"
    });
    const [ cons, setCons ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ search, setSearch ] = useState("")
    const [ status, setStatus ] = useState("all")

    const { selection, map, add, remove } = useSelection({
        mapFn: m => m.id,
        comparisonFn: (a, b) => a.id === b.id
    });
    const selectionCnt = useMemo(() => Object.keys(selection).length, [ selection ]);

    const items = useMemo(() => {
        if (!cons || !cons.length) {
            return [];
        }
        
        const filtered = cons.filter(c => {
            let isMatch = true;

            if (search && !(c.title.toLowerCase().includes(search.toLowerCase()) || c.description.toLowerCase().includes(search.toLowerCase()))) {
                isMatch = false;
            }

            if (status !== "all" && c.status !== status) {
                isMatch = false
            }

            return isMatch;
        });

        return filtered;
    }, 
    [ search, cons, status ]);

    const handleQueryChange = useInputHander(setQuery);
    const onSearch = useCallback(
        (value, e, { source }) => {
            if (source === "clear") {
                setSearch("")
            }
            else {
                setSearch(value)
            }
        },
        [ setSearch, setQuery ]
    );
    const onClose = useSwallowEventCallback(
        () => ConsultationsDialog.hide(dialogName),
        [ dialogName ]
    );

    const loadConsultations = useCallback(
        async () => {
            setLoading(true);
            const where = {
                hidden: { _eq: false },
                status: { _nin: ["cancelled", "deleted"] },
                friId: { _eq: user.id() },
                customerId: { _eq: contactId }
            }
            const res = await consultationsLoader({ limit: 100, where });

            setCons(res);
            setLoading(false);
        },
        [ contactId ]
    );

    useEffect(
        () => {
            loadConsultations()
        },
        [ ]
    );

    useUpdateEffect(
        () => {
            loadConsultations()
        },
        [ dialogName ]
    );

    useEffect(
        () => { // dirty hack to update styles if empty
            if (!loading && cons.length === 0) {
                app.trigger(`app/${ dialogName }/options`, { contactId, empty: true });
            }
        }, 
        [ loading, contactId, cons ]
    );

    const onSelectionChange = useCallback(
        (id, selected) => {
            const con = cons.find(p => p.id === id);
            selected ? add(con) : remove(con);
        },
        [ selection, cons ]
    );

    const onSelectClick = useCallback(
        () => {
            if (selectionPromise[dialogName]) {
                selectionPromise[dialogName](selection);
            }

            ConsultationsDialog.hide(dialogName);
        },
        [ selection, cons, dialogName ]
    );

    const onStatusClick = useCallback(
        (e) => {
            swallowEvent(e);
            setStatus(e.target.dataset.status);
        },
        [ setStatus ]
    );

    const onAddClick = useSwallowEventCallback(
        () => ConsultationsDialog.hide(dialogName),
        [ dialogName ]
    )

    return (
        <div className="consultations-dialog-body">
            <div className="toolbar">
            { cons.length > 0 && 
                <Input.Search 
                    placeholder="Search styling sessions"
                    size="large"
                    enterButton={
                        <Button 
                            Icon={ IconSearch }
                            text="Search" 
                            type="primary"/>
                    }
                    allowClear
                    onChange={ handleQueryChange }
                    value={ query }
                    onSearch={ onSearch }/>
            }
                <a href="/#" 
                    className="feed-dialog-close"
                    onClick={ onClose }>
                    <IconClose/>
                </a>
            </div>
            { cons.length > 0 && 
                <div className="page-styling-menu">
                    <a data-status="all"
                        onClick={ onStatusClick }
                        className={ status === "all" || !status ? "active" : "" }>
                            All requests
                    </a>
                    <a data-status="new"
                        onClick={ onStatusClick }
                        className={ status === "new" ? "active" : "" }>
                            New requests
                    </a>
                    <a data-status="inprogress"
                        onClick={ onStatusClick }
                        className={ status === "inprogress" ? "active" : "" }>
                            In progress
                    </a>
                    <a data-status="completed"
                        onClick={ onStatusClick }
                        className={ status === "completed" ? "active" : "" }>
                            Completed
                    </a>
                </div> }
            { cons.length > 0 && 
                <div className="consultations-cards">
                { items?.map(c => (
                    <ConsultationCard 
                        key={ c.id } 
                        showDate={ false }
                        cons={ c }>
                        <ConsultationSelect id={ c.id } selected={ map[c.id] || false } onChange={ onSelectionChange }/>
                    </ConsultationCard>
                ))}

                { (items.length === 0 && !loading) && 
                    <EmptyMessage size="small" message="No styling sessions matched your search"/>
                 }
                </div>
            }
            { (loading) && <Spin spinning className="infinite-scroll-spinner"/> }
            {
                cons.length === 0 && !loading &&
                <div className="consultations-cards-empty">
                    <div className="consultations-cards-empty-action">
                        You haven't created a styling session for this client yet. 
                        Go to the styling tab, create a styling session and assign to a client before sharing.
                    </div>

                    {/*<div className="consultations-cards-empty-action">
                        Create a styling session here
                        <Button
                            size="large"
                            type="primary"
                            shape="circle"
                            Icon={ IconPlus }
                            href="/styling"
                            onClick={ onAddClick }
                        />
                    </div>*/}
                </div>
            }
            { cons.length > 0 && 
                <div className="feed-dialog-footer">
                    <Button 
                        type="primary" 
                        text={ `Select (${ selectionCnt })` }
                        onClick={ onSelectClick }
                        disabled={ selectionCnt === 0 }/>
                </div>
            }
        </div>
    )

}

function ConsultationsDialog({ name = DIALOG_NAME }) {
    const open = useSelector(s => s.dialogs[name] || false);
    const [ options, setOptions ] = useState({});

    const onClose = useCallback(
        () => ConsultationsDialog.hide(name),
        [ name ]
    );

    useOnAppEvent(`app/${ name }/options`, setOptions);

    return (
        <Modal 
            classNames={{ wrapper: `consultations-dialog ${ options?.empty && "empty" }` }}
            centered    
            width={options?.empty ? "500px" : "680px"}
            open={ open } 
            destroyOnClose 
            closeIcon={ null }
            footer={ null }
            maskClosable={ false }
            onCancel={ onClose }>
            <Consultations dialogName={ name } { ...options }/>
        </Modal>
    )
}

ConsultationsDialog.NAME = DIALOG_NAME;

ConsultationsDialog.show = function(options = {}, name = DIALOG_NAME) {
    app.trigger(`app/${ name }/options`, options);
    store.dispatch(ui.show(name));
    return new Promise((resolve) => {
        selectionPromise[name] = resolve;
    });
}

ConsultationsDialog.hide = function(name = DIALOG_NAME) {
    selectionPromise[name] && selectionPromise[name]([]);
    store.dispatch(ui.hide(name));
    delete selectionPromise[name];
}

export default ConsultationsDialog