import { useCallback, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import useDictRef from "common/src/hooks/useDictRef"
import useUpdateEffect from "common/src/hooks/useUpdateEffect"

function uc(str) {
    return str[0].toUpperCase() + str.substring(1);
}


function useDualState({
    params = [],
    mode = "state",
    imports = null,
    defaults = {},
    overrides = {},
    onApply
}) {

    // eslint-disable-next-line
    const fixedParams = useMemo(() => params, []);
    const [searchParams, setSearchParams] = useSearchParams();
    const api = useDictRef({});
    const values = useDictRef({});

    function getParam(p) {
        if (typeof p === "string") {
            p = fixedParams.find(param => param.name === p);
        }
        return {
            ...p,
            ...overrides[p.name]
        }
    }

    function prepareExports() {
        const exports = {};
        fixedParams.forEach(param => {
            param = getParam(param);
            if (param.exports) {
                let value = values[param.name];
                Object.keys(param.exports).forEach(exportName => {
                    if (!exports[exportName]) {
                        exports[exportName] = {};
                    }
                    const exportConf = param.exports[exportName];
                    if (typeof exportConf === "function") {
                        exports[exportName][param.name] = exportConf(value, exports[exportName]);
                    }
                    else {
                        const [exportParamName, exportFn] = param.exports[exportName];
                        exports[exportName][exportParamName] = exportFn(value, exports[exportName]);
                    }
                })
            }
        });
        return exports;
    }

    const ref = useDictRef({ mode, overrides, getParam, prepareExports, onApply });

    function applySearchParam(name) {
        const sp = new URLSearchParams(searchParams);
        const param = getParam(name);
        let value = values[param.name];
        if (param.serialize) {
            value = param.serialize(value);
        }
        if (value !== null && value !== undefined && value !== "") {
            sp.set(param.name, value);
        }
        else {
            sp.delete(param.name);
        }
        sp.set("_", (new Date()).getTime());
        setSearchParams(sp);
    }

    const applySearchParams = useCallback(
        () => {
            const sp = new URLSearchParams();
            fixedParams.forEach(param => {
                param = ref.getParam(param);
                let value = values[param.name];
                if (param.serialize) {
                    value = param.serialize(value);
                }
                if (value !== null && value !== undefined && value !== "") {
                    sp.set(param.name, value);
                }
                else {
                    sp.delete(param.name);
                }
            });
            sp.set("_", (new Date()).getTime());
            setSearchParams(sp);
        },
        [setSearchParams, fixedParams, values]
    );

    for (let i = 0, l = fixedParams.length; i < l; i++) {
        const param = getParam(fixedParams[i]);
        // eslint-disable-next-line
        const [value, setValue] = useState(() => {

            // initial value 

            let importsValue;

            if (imports) {
                for (const [key, data] of Object.entries(imports)) {
                    let tmp = data[param.name];
                    if (param.imports && param.imports[key]) {
                        tmp = param.imports[key](tmp, data);
                    }
                    if (tmp !== undefined) {
                        importsValue = tmp;
                    }
                }
            }

            let defaultStateValue = importsValue !== undefined ?
                importsValue :
                defaults[param.name] !== undefined ?
                    defaults[param.name] :
                    param.default !== undefined ?
                        param.default :
                        undefined;

            let queryValue = searchParams.get(param.name);
            if (param.unserialize && queryValue !== undefined && queryValue !== null) {
                queryValue = param.unserialize(queryValue);
            }

            return mode === "query" ?
                queryValue :
                mode === "state" ?
                    defaultStateValue :
                    queryValue != undefined ? queryValue : defaultStateValue;
        });

        values[param.name] = value;
        api[param.name] = value;
        api[`set${uc(param.name)}State`] = setValue;

        if (mode === "state" || mode === "both") {
            api[`set${uc(param.name)}`] = function (value) {
                values[param.name] = value;
                if (param.autoApply && mode === "both") {
                    applySearchParam(param.name);
                }
                else {
                    setValue(value);
                    if (param.autoApply) {
                        applyAll();
                    }
                }
            }
        }
        else if (mode === "query") {
            api[`set${uc(param.name)}`] = function (value) {
                values[param.name] = value;
                applySearchParams();
            }
        }
    }

    const [all, setAll] = useState({ ...values });
    const [exports, setExports] = useState(() => prepareExports(values));

    const applyAll = useCallback(
        () => {
            const all = { ...values };
            const exports = ref.prepareExports(values);
            setAll(all);
            setExports(exports);
            ref.onApply && ref.onApply({ all, ...exports })
        },
        [values]
    );

    useUpdateEffect(
        () => {
            if (ref.mode !== "state") {
                for (let i = 0, l = fixedParams.length; i < l; i++) {
                    const param = ref.getParam(fixedParams[i]);
                    const defaultStateValue = defaults[param.name] !== undefined ?
                        defaults[param.name] :
                        param.default !== undefined ?
                            param.default :
                            undefined;
                    let value = searchParams.get(param.name);
                    if (param.unserialize && value !== undefined && value !== null) {
                        value = param.unserialize(value);
                    }
                    if (value === undefined || value === null) {
                        value = defaultStateValue;
                    }
                    values[param.name] = value;
                    api[`set${uc(param.name)}State`](value);
                }

                applyAll();
            }
        },
        [searchParams, fixedParams]
    );

    api.all = all;
    api.applyAll = applyAll;
    api.applySearchParams = applySearchParams;
    api.apply = mode === "state" ? applyAll : applySearchParams;

    Object.assign(api, exports);

    return api;
}

export default useDualState