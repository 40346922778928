


export default {

    init: function(logGroupName) {
        
    },

    log: function(err, data) {
        
    }
}