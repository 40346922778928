
import createValidator from "common/src/lib/form/createValidator"
import required from "common/src/lib/form/validator/required"
import { ibanCountries } from "common/src/lib/countryCodes"

import api from "app/api"
import store from "app/store"
import { data as userData } from "common/src/store/user"


export const defaultContext_v2 = {
    profileType: "individual",
    givenName: "",
    familyName: "",
    companyName: "",
    account: "",
    iban: "",
    bic: "",
    country: "GB",
    currency: "GBP",
    sortCode: "",
    routing: "",
    ifsc: "",
    bsb: "",
    addressLine1: "",
    addressLine2: "",
    addressCountry: "",
    addressCity: "",
    addressPostcode: ""
}

export const defaultContext = {
    ...defaultContext_v2,
    saving: false,
    loading: true,
}


export async function loadCounterparty() {
    const res = await api.revolut.get("/counterparty");
    return res.counterparty || null;
}

export async function deleteCounterparty() {
    const res = await api.revolut.del("/counterparty");
    if (res.success) {
        store.dispatch(userData.current.update({ hasBankDetails: false }));
    }
    return res.success;
}

export function accountSettings(country, currency) {
    const isIFSC = country === "IN" && currency === "INR";
    const isBSB = country === "AU" && currency === "AUD";
    const isSort = country === "GB" && currency === "GBP";
    const isRouting = country === "US" && currency === "USD";
    const isIBAN = ibanCountries.indexOf(country) !== -1;
    const addressRequired = country !== "GB";
    return { isIFSC, isBSB, isSort, isRouting, isIBAN, addressRequired };
}

export async function nameRequired(value, _1, _2, values) {
    const { profileType } = values;
    if (profileType) {
        return profileType === "individual" ? required(value) : true;
    }
    else return true;
}
export function nameRequired_v2(value, _, values) {
    const { profileType } = values;
    if (profileType) {
        return profileType === "individual" ? required(value) : true;
    }
    else return true;
}

export async function companyRequired(value, _1, _2, values) {
    const { profileType } = values;
    if (profileType) {
        return profileType === "business" ? required(value) : true;
    }
    else return true;
}
export function companyRequired_v2(value, _, values) {
    const { profileType } = values;
    if (profileType) {
        return profileType === "business" ? required(value) : true;
    }
    else return true;
}


export async function accRequired(value, _1, _2, values) {
    const { country, currency } = values;
    const ref = accountSettings(country, currency);
    if (!ref.isIBAN || ref.isSort || ref.isRouting || ref.isIFSC || ref.isBSB) {
        return required(value);
    }
    return true;
}
export function accRequired_v2(value, _, values) {
    const { country, currency } = values;
    const ref = accountSettings(country, currency);
    if (!ref.isIBAN || ref.isSort || ref.isRouting || ref.isIFSC || ref.isBSB) {
        return required(value);
    }
    return true;
}


export async function ibanRequired(value, _1, _2, values) {
    const { country, currency } = values;
    const ref = accountSettings(country, currency);
    if (ref.isIBAN && !ref.isSort && !ref.isRouting && !ref.isIFSC && !ref.isBSB) {
        return required(value);
    }
    return true;
}
export function ibanRequired_v2(value, _, values) {
    const { country, currency } = values;
    const ref = accountSettings(country, currency);
    if (ref.isIBAN && !ref.isSort && !ref.isRouting && !ref.isIFSC && !ref.isBSB) {
        return required(value);
    }
    return true;
}

export async function bicRequired(value, _1, _2, values) {
    const { country, currency } = values;
    const ref = accountSettings(country, currency);
    if (!ref.isSort && !ref.isRouting && !ref.isIFSC && !ref.isBSB) {
        return required(value);
    }
    return true;
}
export function bicRequired_v2(value, _, values) {
    const { country, currency } = values;
    const ref = accountSettings(country, currency);
    if (!ref.isSort && !ref.isRouting && !ref.isIFSC && !ref.isBSB) {
        return required(value);
    }
    return true;
}

export async function sortRequired(value, _1, _2, values) {
    const { country, currency } = values;
    const ref = accountSettings(country, currency);
    if (ref.isSort) {
        return required(value);
    }
    return true;
}
export function sortRequired_v2(value, _, values) {
    const { country, currency } = values;
    const ref = accountSettings(country, currency);
    if (ref.isSort) {
        return required(value);
    }
    return true;
}

export async function routingRequired(value, _1, _2, values) {
    const { country, currency } = values;
    const ref = accountSettings(country, currency);
    if (ref.isRouting) {
        return required(value);
    }
    return true;
}
export function routingRequired_v2(value, _, values) {
    const { country, currency } = values;
    const ref = accountSettings(country, currency);
    if (ref.isRouting) {
        return required(value);
    }
    return true;
}

export async function ifscRequired(value, _1, _2, values) {
    const { country, currency } = values;
    const ref = accountSettings(country, currency);
    if (ref.isIFSC) {
        return required(value);
    }
    return true;
}
export function ifscRequired_v2(value, _, values) {
    const { country, currency } = values;
    const ref = accountSettings(country, currency);
    if (ref.isIFSC) {
        return required(value);
    }
    return true;
}

export async function bsbRequired(value, _1, _2, values) {
    const { country, currency } = values;
    const ref = accountSettings(country, currency);
    if (ref.isBSB) {
        return required(value);
    }
    return true;
}
export function bsbRequired_v2(value, _, values) {
    const { country, currency } = values;
    const ref = accountSettings(country, currency);
    if (ref.isBSB) {
        return required(value);
    }
    return true;
}

export async function addressRequired(value, _1, _2, values) {
    const { country, currency } = values;
    const ref = accountSettings(country, currency);
    if (ref.addressRequired) {
        return required(value);
    }
    return true;
}
export function addressRequired_v2(value, _, values) {
    const { country, currency } = values;
    const ref = accountSettings(country, currency);
    if (ref.addressRequired) {
        return required(value);
    }
    return true;
}


export const fields = [
    {
        name: "givenName",
        validator: createValidator(nameRequired, "Please enter your name")
    },
    {
        name: "familyName",
        validator: createValidator(nameRequired, "Please enter your last name")
    },
    {
        name: "companyName",
        validator: createValidator(companyRequired, "Please enter your company name")
    },
    {
        name: "account",
        validator: createValidator(accRequired, "Please enter your account number")
    },
    {
        name: "sortCode",
        validator: createValidator(sortRequired, "Please enter sort code")
    },
    {
        name: "profileType"
    },
    {
        name: "country"
    },
    {
        name: "currency"
    },
    {
        name: "routing",
        validator: createValidator(routingRequired, "Please enter wire routing number")
    },
    {
        name: "iban",
        validator: createValidator(ibanRequired, "Please enter IBAN")
    },
    {
        name: "bic",
        validator: createValidator(bicRequired, "Please enter BIC")
    },
    {
        name: "ifsc",
        validator: createValidator(ifscRequired, "Please enter IFSC")
    },
    {
        name: "bsb",
        validator: createValidator(bsbRequired, "Please enter BSB")
    },
    {
        name: "addressLine1",
        validator: createValidator(addressRequired, "Please enter your address")
    },
    {
        name: "addressLine2"
    },
    {
        name: "region"
    },
    //{
    //    name: "addressCountry"
    //},
    {
        name: "addressCity",
        validator: createValidator(addressRequired, "Please enter your city")
    },
    {
        name: "addressPostcode",
        validator: createValidator(addressRequired, "Please enter your postcode")
    }
];



export const fields_v2 = [
    {
        name: "givenName",
        validator: [
            [nameRequired_v2, "Please enter your name"]
        ]
    },
    {
        name: "familyName",
        validator: [
            [nameRequired_v2, "Please enter your last name"]
        ]
    },
    {
        name: "companyName",
        validator: [
            [companyRequired_v2, "Please enter your company name"]
        ]
    },
    {
        name: "account",
        validator: [
            [accRequired_v2, "Please enter your account number"]
        ]
    },
    {
        name: "sortCode",
        validator: [
            [sortRequired_v2, "Please enter sort code"]
        ]
    },
    {
        name: "profileType"
    },
    {
        name: "country"
    },
    {
        name: "currency"
    },
    {
        name: "routing",
        validator: [
            [routingRequired_v2, "Please enter wire routing number"]
        ]
    },
    {
        name: "iban",
        validator: [
            [ibanRequired_v2, "Please enter IBAN"]
        ]
    },
    {
        name: "bic",
        validator: [
            [bicRequired_v2, "Please enter BIC"]
        ]
    },
    {
        name: "ifsc",
        validator: [
            [ifscRequired_v2, "Please enter IFSC"]
        ]
    },
    {
        name: "bsb",
        validator: [
            [bsbRequired_v2, "Please enter BSB"]
        ]
    },
    {
        name: "addressLine1",
        validator: [
            [addressRequired_v2, "Please enter your address"]
        ]
    },
    {
        name: "addressLine2"
    },
    {
        name: "addressCity",
        validator: [
            [addressRequired_v2, "Please enter your city"]
        ]
    },
    {
        name: "addressPostcode",
        validator: [
            [addressRequired_v2, "Please enter your postcode"]
        ]
    }
];
