import { useMemo } from "react"

import findParent from "common/src/lib/dom/findParent"

function Dnd({ Component = "div", draggable, droppable, ...rest }) {

    const dndProps = useMemo(
        () => {
            const props = {};

            if (draggable) {
                props.draggable = true;
                props.onDragStart = e => {
                    e.dataTransfer.setData(
                        "application/json", 
                        JSON.stringify(draggable.data === undefined ? null : draggable.data)
                    );
                    if (draggable.imageElId) {
                        e.dataTransfer.setDragImage(
                            document.getElementById(draggable.imageElId), 
                            0, 0
                        );
                    }
                    draggable.start && draggable.start(draggable);
                };
                props.onDragEnd = e => {
                    draggable.end && draggable.end(draggable);
                };
            }

            if (droppable) {
                props.onDragEnter = e => {
                    e.preventDefault();
                    e.stopPropagation();
                };
                props.onDragOver = e => {
                    e.preventDefault();
                    e.stopPropagation();
                    const el = findParent(e.target, droppable.cardSelector || ".card");
                    el && el.classList.add("droppable-active");
                };
                props.onDragLeave = e => {
                    e.preventDefault();
                    e.stopPropagation();
                    const el = findParent(e.target, droppable.cardSelector || ".card");
                    el && el.classList.remove("droppable-active");
                };
                props.onDrop = e => {
                    e.preventDefault();
                    e.stopPropagation();
                    const data = JSON.parse(e.dataTransfer.getData("application/json"));
                    const el = findParent(e.target, droppable.cardSelector || ".card");
                    el && el.classList.remove("droppable-active");
                    droppable.drop && droppable.drop(
                        droppable.data === undefined ? null : droppable.data, data
                    );
                };
            }

            return props;
        },
        [ draggable, droppable ]
    );



    return (
        <Component { ...dndProps } { ...rest }/>
    )
}

export default Dnd