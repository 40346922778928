import { useSelector } from "react-redux"
import { useCallback, useMemo, useState } from "react"
import { Drawer, Input, App as AntApp } from "antd"

import Button from "../button/Button"
import NullForm from "common/src/components/NullForm"
import FormInput from "common/src/refactor/components/form/FormInput"


import { ui } from "common/src/store/dialogs"
import store from "app/store"
import { Form, useFormFields } from "common/src/refactor/lib/form/Form"
import required from "common/src/refactor/lib/form/validator/required"
import { resendPhoneVerification, verifyPhone, checkCurrent } from "common/src/actions/user"

                        
const fields = [
    {
        name: "code",
        validator: [
            [ required, "Please enter the verification code" ]
        ]
    }
]

const DIALOG_NAME = "verify-phone";

function VerifyPhoneForm() {

    const { message } = AntApp.useApp();
    const form = useMemo(() => new Form(fields), []);
    const [ codeSent, setCodeSent ] = useState(false);
    const [ sendingCode, setSendingCode ] = useState(false);
    const [ verifying, setVerifying ] = useState(false);

    const { code, codeError, codeChange, codeSetError } = useFormFields(["code"], form);

    const onSendCodeClick = useCallback(
        async () => {
            setSendingCode(true);

            await resendPhoneVerification();

            setCodeSent(true);
            setSendingCode(false);
        },
        []
    );

    const onSubmitClick = useCallback(
        async () => {
            setVerifying(true);

            try {
                await verifyPhone(code);
                message.success({
                    content: "Great! We verified your phone number.",
                    icons: <></>
                });
                checkCurrent();
                VerifyPhoneDialog.hide();
            }
            catch (err) {
                codeSetError("Failed to verify the phone number. Please check that the code is correct.")
            }

            setVerifying(false);
        },
        [ code, codeSetError ]
    );

    if (codeSent) {
        return (
            <NullForm className="verify-email-form">
                <FormInput error={ codeError } label="Verification code *">
                    <Input 
                        disabled={ verifying || sendingCode }
                        value={ code } 
                        onChange={ codeChange }
                        onPressEnter={ onSubmitClick }/>
                </FormInput>
                <Button 
                    type="primary"
                    disabled={ verifying || sendingCode }
                    loading={ verifying }
                    text="Submit" 
                    onClick={ onSubmitClick }/>
                <Button
                    type="text"
                    disabled={ verifying || sendingCode }
                    loading={ sendingCode }
                    text="Resend code" 
                    onClick={ onSendCodeClick }/>
            </NullForm>
        )
    }
    else {
        return (
            <NullForm className="verify-email-form">
                <Button 
                    type="primary"
                    disabled={ sendingCode }
                    loading={ sendingCode }
                    children="Send code" 
                    onClick={ onSendCodeClick }/>
            </NullForm>
        )
    }
}

function VerifyPhoneDialog() {
    const open = useSelector(s => s.dialogs[DIALOG_NAME]);

    const onClose = useCallback(
        () => { VerifyPhoneDialog.hide() },
        []
    );

    return (
        <Drawer 
            className="tf-drawer verify-email-drawer"
            title="Verify phone"
            open={ open } 
            onClose={ onClose }
            destroyOnClose>
            { open && <VerifyPhoneForm/> }
        </Drawer>
    )
}


VerifyPhoneDialog.show = function() {
    store.dispatch(ui.show(DIALOG_NAME));
}

VerifyPhoneDialog.hide = function() {
    store.dispatch(ui.hide(DIALOG_NAME));
}

export default VerifyPhoneDialog