
export default function getSlotClass(slotIndex = 0, template = []) {

    const slot = template[slotIndex];

    if (!slot) {
        return [];
    }

    if (slot.cls) {
        return slot.cls;
    }

    if (template.length === 1) {
        return ["tl","tr","bl","br"];
    }
    if (template.length === 2) {
        if (slotIndex === 0) {
            return ["tl","bl"];
        }
        return ["tr","br"];
    }
    if (slotIndex === 0 && template[0].row === 1) {
        return ["tl"];
    }

    let left = 0, right = 0,
        leftBefore = 0, rightBefore = 0,
        col,
        cls = [];

    for (let i = 0; i < template.length; i++) {
        let block = template[i];

        if (i === slotIndex) {
            col = left <= right ? 0 : 1;
        }

        if (block.col === 2) {
            left += block.row;
            right += block.row;
            if (i < slotIndex) {
                leftBefore += block.row;
                rightBefore += block.row;
            }
        }
        else {
            if (left <= right) {
                left += block.row;
                if (i < slotIndex) {
                    leftBefore += block.row;
                }
            }
            else {
                right += block.row;
                if (i < slotIndex) {
                    rightBefore += block.row;
                }
            }
        }
    }

    if (col === 0) {
        if (leftBefore === 0) {
            cls.push("tl");
        }
        if (leftBefore + slot.row === left) {
            cls.push("bl");
        }
    }
    else {
        if (rightBefore === 0) {
            cls.push("tr");
        }
        if (rightBefore + slot.row === right) {
            cls.push("br");
        }
    }

    return cls;
}