import React, { useContext } from "react"
import s3url from "common/src/lib/image/s3url"

import Loader from "common/src/components/Loader"
import ChatContext from "common/src/lib/chat/ChatContext"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import { useMemo } from "react";

function Image({ image, message }) {

    const chat = useContext(ChatContext);
    const onClick = useSwallowEventCallback(
        () => {
            const srcs = message.images
                            .map(i => i.image)
                            .map(i => typeof i === "string" ? i : i.src || s3url(i.key, 1000))
            chat.trigger("messageAction", "view-image", srcs);
        },
        [ image, message ]
    );

    const { style, children, cls } = useMemo(
        () => {
            let style, cls, children;

            if (image.uploading) {
                return {
                    style: null,
                    children: (<Loader/>),
                    cls: "chat-message-image uploading"
                };
            }

            const img = image.image;
            return {
                style: { backgroundImage: `url(${ img.src || s3url(img.key) })` },
                children: null,
                cls: "chat-message-image"
            };
        },
        [ image, image.uploading ]
    );

    return (
        <div 
            className={ cls }
            onClick={ image.uploading ? null : onClick }
            style={ style }
            children={ children }/>
    )
}

function Images({ message }) {

    return (
        <div className="chat-message-images">
            { message.images.map(i => (
                <Image key={ i.id } image={ i } message={ message }/>
            )) }
        </div>
    )
}

export default Images