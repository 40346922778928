import SignInForm from "./SignIn"
import ForgotPasswordForm from "./ForgotPassword"
import RequireNewPasswordForm from "./RequireNewPassword"
//import SignUpForm from "./SignUp"
import { useCallback, useMemo, useState } from "react"


function Authenticator({    signinProps = null, 
                            forgotProps = null,
                            signupProps = null,
                            requirePasswordProps = null }) {

    const [ authState, setAuthState ] = useState("signIn");
    const [ authData, setAuthData ] = useState(null);

    const onAuthStateChange = useCallback(
        (authState, authData) => {
            setAuthData(authData);
            setAuthState(authState);
        },
        []
    );

    const onError = useCallback(
        (error) => {
            console.error(error);
        },
        []
    );

    const componentProps = useMemo(
        () => {
            let componentProps = signinProps;
            if (authState === "forgotPassword") {
                componentProps = forgotProps;
            }
            else if (authState === "requireNewPassword") {
                componentProps = requirePasswordProps;
            }
            else if (authState === "signUp") {
                componentProps = signinProps;
            }

            const props = {
                authData,
                onAuthStateChange,
                onError,
                ...componentProps
            };

            return props;
        },
        [ authState, authData, onAuthStateChange, onError,
            signinProps, forgotProps, requirePasswordProps, signupProps ]
    );

    if (authState === "signIn") {
        return (
            <SignInForm { ...componentProps }/>
        )
    }
    else if (authState === "forgotPassword") {
        return (
            <ForgotPasswordForm { ...componentProps }/>
        )
    }
    else if (authState === "requireNewPassword") {
        return (
            <RequireNewPasswordForm { ...componentProps }/>
        )
    }
    /*else if (authState === "signUp") {
        return (
            <SignUpForm { ...componentProps }/>
        )
    }*/
}

export default Authenticator
