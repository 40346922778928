import { useCallback, useEffect, useRef } from "react"
import useDictRef from "./useDictRef"


function useInterval(cb, interval, options = {}) {

    const itv = useRef(null);
    const ref = useDictRef({
        cb, 
        interval,
        immediateStart: options.immediateStart || false,
        trailing: options.trailing || false
    });

    const start = useCallback(
        () => {
            if (!itv.current) {
                ref.immediateStart && ref.cb();
                itv.current = setInterval(ref.cb, ref.interval);
            }
        },
        []
    );

    const stop = useCallback(
        () => {
            if (itv.current) {
                clearInterval(itv.current);
                itv.current = null;
                ref.trailing && ref.cb();
            }
        },
        []
    );

    useEffect(() => () => stop(), []);

    return [ start, stop ];
}

export default useInterval