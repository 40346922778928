import { Fragment, useEffect } from "react"
import Authenticator from "common/src/refactor/components/authenticator/Authenticator"
import Footer from "common/src/refactor/components/Footer"
import hub from "common/src/hub"

function onAuth() {
    window.location.reload();
}

function PageSignIn() {

    useEffect(
        () => {
            hub.listen("auth", "signIn", onAuth);
            return () => {
                hub.remove("auth", "signIn", onAuth);
            }
        },
        []
    );

    return (
        <Fragment>
        <div className="page-signin">
            <Authenticator/>
        </div>
        <Footer/>
        </Fragment>
    )
}

export default PageSignIn