

import hub from "common/src/hub"
import store from "app/store"
import { ui } from "common/src/store/chat"
import * as actions from "common/src/actions/chat"


const onUnreadChange = async (count) => {

    let exclude = 0;

    if (count > 0) {
        const load = await actions.loadNotifications();
        exclude = load.exclude;
    }

    count = Math.max(count - exclude, 0);
    const state = store.getState();

    if (state.chats.ui.notificationCount !== count) {
        store.dispatch(ui.setNotificationCount(count));
        hub.dispatch("chat", "notification-count", count); 
    }
}

export default {
    init: () => {
        hub.listen("notifications", "unread-change-chat", onUnreadChange);
    }
}